import { connect } from 'react-redux'
import Dashboard from "components/Dashboard/Dashboard"
import { 
    getDashboardOverview,
    getDashboardApplication,
    getDashboardPortfolio
} from 'actions/dashboard'
import { getFmcsaData } from 'actions/applicants'
import {dispatchProcessing} from 'actions/root'

function mapStateToProps(stateProps) {
    const { clientData } = stateProps?.dashboard
    const { user, globalAccountSelected } = stateProps?.root
    const { fmcsaData, fmcsaParam } = stateProps?.applicant
    const { overview, applicationGrowth, portFolioGrowth } = stateProps?.dashboard
    const hideFmcsa = stateProps?.user?.userDetails?.securityContext?.client?.hideFmcsa || false;
    return {
        ...stateProps,
        clientData,
        user,
        globalAccountSelected,
        fmcsaData,
        fmcsaParam,
        overview,
        applicationGrowth,
        portFolioGrowth,
        hideFmcsa
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        /**
         * action to get dashboard chart data
         */
        getDashboardOverview,
        getDashboardApplication,
        getDashboardPortfolio,
        /**********/
        getFmcsaData, // action to get fmcsa data
        dispatchProcessing // action to show loader
    },
    mergeProps
  )(Dashboard)