import React, { useEffect, useState, useCallback } from "react"
import { Col, Row, Card } from "react-bootstrap"
import { Button } from '@material-ui/core'
import { ApplicantColumns, ApplicantFilters } from 'constants/application'
import FilterBarModule from "components/shared/FilterBarModule"
import TableComponent from 'components/shared/TableComponent'
import FMCSAsearch from "./partial/Modals/FMCSAsearch"
import AuthService from "tools/AuthService"
import Archive from '@material-ui/icons/Archive'
import { Dialog } from 'components/shared/Dialog'
import Tooltip from '@material-ui/core/Tooltip'
import strings from "strings"
import "./Applicants.scss"

function Applicants(props) {
  const {
    applicationList,
    getApplicantsList,
    getFmcsaData,
    getFmcsaDataByDOT,
    archiveApplication,
    fmcsaData,
    fmcsaParam,
    history,
    totalRecords,
    totalPages,
    hideFmcsa
  } = props
  const [columns, setColumns] = useState([...ApplicantColumns])
  const [loading, setLoading] = useState(false)
  const [showModal, setModal] = useState(false)
  const [search, setSearch] = useState('')
  const [pagination, setPagination] = useState('')
  const [filter, setFilter] = useState({})
  const [showDialog, setShowDialog] = useState(false)
  const [applicationUUID, setApplicationUUID] = useState('')
  const [contentMessage, setContentMessage] = useState('')

  const action = {
    formatter: (cell, row, rowIndex) => {
      return (
        <Tooltip title="Archive Application">
          <Archive className='action-icon' onClick={() => handleOnClickArchive(row)} />
        </Tooltip>
      )
    }
  }

  if(AuthService.hasPrivilege('ARCHIVE_APPLICATION')){
    columns.map((item, index) => {
      if (item.dataField === 'action')
        columns[index] = { ...item, ...action }
      return item
    })
  }

  const riskScoreIndex = columns.findIndex((col) => col.dataField === 'riskScore')
  if (riskScoreIndex > -1) {
    columns.splice(riskScoreIndex, 1)
  }

  const getApplicants = useCallback((pagination = {}, filters = {}) => {
    setLoading(true)
    getApplicantsList(pagination, filters).finally(() =>
      setLoading(false)
    )
  }, [getApplicantsList])

  useEffect(() => {
    const filterPayload = { name: search, ...filter }
    getApplicants(pagination, filterPayload)
  }, [getApplicants, search, pagination, filter])

  const handleOnClickArchive = (row) => {
    const message = strings.APPLICANT.CONFIRM_ARCHIVE + " " +  row.applicantName + "?"
    setContentMessage(message)
    setApplicationUUID(row.uuid)
    setShowDialog(true)
  }

  const handleConfirmArchive = () => {
    archiveApplication(applicationUUID, true).then(() => {
      const filterPayload = { name: search, ...filter }
      getApplicants(pagination, filterPayload)
    })
    setShowDialog(false)
  }

  const handleCreateApplicationManually = () => {
    history.push(`/create-applicant`)
  }

  return (
    <div id="applicants">
      <Row className='mb-3'>
        <Col xs={6} className="text-left">
          <h4>{strings.APPLICATIONS}</h4>
        </Col>
        <Col xs={6} className="text-right">
          {!AuthService.isReadOnlyUser() && <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (hideFmcsa) {
                handleCreateApplicationManually()
              } else {
                setModal(true)
              }
            }}
            startIcon={<img src={require(`styles/images/icons/createBtn.svg`)} alt='open' />}
          >
            {strings.CREATE_APPLICATION}
          </Button>}
        </Col>
      </Row>
      <Row className="ml-0 mr-0">
        <Col className="pr-lg-0 pl-lg-0 cardBorder">
          <Card className="border-0">
            <FilterBarModule
              title={strings.APPLICANT.LIST_OF_APPLICATIONS}
              filters={ApplicantFilters}
              textLabel={'Filters'}
              columns={columns}
              receiveCustomColumn={(columns) => setColumns(columns)}
              filterParams={(params) => setFilter(params)}
              placeHolder={strings.APPLICANT.SEARCH_BY_APPLICANT_NAME}
              onSearch={setSearch}
            />
            <TableComponent
              className='application-table'
              data={applicationList}
              columns={columns}
              sort={true}
              loading={loading}
              totalRecords={totalRecords}
              totalPages={totalPages}
              onChangePagination={(pagination) => setPagination(pagination)}
              remoteSorting
            />
          </Card>
        </Col>
      </Row>
      <FMCSAsearch
        show={showModal}
        onHide={() => setModal(false)}
        getFmcsaData={getFmcsaData}
        getFmcsaDataByDOT={getFmcsaDataByDOT}
        fmcsaData={fmcsaData}
        queryUrl={fmcsaParam}
        history={history}
      />
      <Dialog
        fullWidth
        maxWidth='sm'
        type={'warning'}
        open={showDialog}
        contentMessage={ contentMessage }
        confirmButton={{ text: strings.CONFIRM_ARCHIVE }}
        alertTiltle={strings.APPLICANT.ARCHIVE_APPLICATION}
        onSubmit={() => handleConfirmArchive()}
        onClose={() => setShowDialog(false)}
      />
    </div>
  )
}

export default Applicants
