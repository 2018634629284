import React, { useCallback, useEffect, useState } from "react"
import "./Insights.scss"
import { Col, Row } from "react-bootstrap"
import { formatPercentage } from 'tools/formatters'
import TableComponent from 'components/shared/TableComponent'
import { getListOfFilteredBankTransactions } from 'actions/applicants'
import { plaidCategories } from 'constants/constantVariables'
import { format, subMonths, startOfMonth } from 'date-fns'

const Insights = (props) => {

    const {
        insightsData,
        applicationId,
        selectedOwnerUuid,
        selectedAccountId,
        columns
    } = props

    const recordsLimit = 5
    const [selectedInsight, setSelectedInsight] = useState('INSUFFICIENT_FUNDS')
    const [transactionsData, setTransactionsData] = useState([])
    const [loading, setLoading] = useState(false)

    const [totalRecords, setTotalRecords] = useState(0)
    const [totalPages, setTotalPages] = useState(0)

    const insightClickHandler = (insightType) => {
        setSelectedInsight(plaidCategories[insightType].name)
    }

    const getInsights = useCallback((pagination = { limit:recordsLimit }) => {
        setLoading(true)
        let params = { 
            accountIds: selectedAccountId,
            from: format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd'),
            until: format(new Date(), 'yyyy-MM-dd'),
            categoryIds: plaidCategories[selectedInsight].ids || []
        }
        getListOfFilteredBankTransactions(applicationId, selectedOwnerUuid, params, pagination)()
            .then(res => {
                setTransactionsData(res.response)
                setTotalRecords(res.headers['x-pagination-total-records'])
                setTotalPages(res.headers['x-pagination-total-pages'])
            })
            .finally(() => setLoading(false))
    }, [applicationId, selectedOwnerUuid, selectedInsight, selectedAccountId])

    const handlePagination = (pagination) => {
        getInsights(pagination)
    }

    useEffect(() => {
        getInsights()
    }, [getInsights])

    return (
        <div className="insightsContainer">
            <h3>Insights</h3>
            <Row>
                <Col md={3}>
                    {
                        insightsData.map((item, index) => (
                            <div key={item.insightType} className={`insightBox ${item.insightType === selectedInsight ? 'selectedInsight' : ''}`} onClick={() => insightClickHandler(item.insightType)}>
                                <p className="insightTitle"> {item.displayName} </p>
                                <h5> {item.transactionsNo} Transactions</h5>
                                <div style={{ display: 'flex' }}>
                                    <p className={item.variation >= 0 ? 'positiveDiff' : 'negativeDiff'}> {formatPercentage(item.variation)} </p>
                                    <p className="lastMonth"> {' (last month)'}</p>
                                </div>
                            </div>
                        ))
                    }
                </Col>
                <Col md={9}>
                    <div className="insightTableContainer">
                        <h4>{plaidCategories[selectedInsight].displayName}</h4>
                            <TableComponent
                                data={transactionsData}
                                columns={columns}
                                isLoading={loading}
                                keyField='uuid'
                                className='global-table'
                                paginationLimit={recordsLimit}
                                totalRecords={totalRecords}
                                totalPages={totalPages}
                                onChangePagination={(pagination) => handlePagination(pagination)}
                            />
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default Insights;
