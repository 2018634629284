import React from 'react';
import KeyValueAccordionDetails from "./KeyValueAccordionDetails";
import RoxAccordion from '../RoxAccordion';

const KeyValueAccordion = ({ data, title, defaultExpanded }) => {

    return (
        <>
            <RoxAccordion expanded={defaultExpanded} summary={<p className="body-1-semibold" >{title}</p>} 
            details={<KeyValueAccordionDetails data={data}/>}/>

        </>
    );
};

export default KeyValueAccordion;