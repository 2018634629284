import React, { useEffect, useState } from 'react'
import { FieldArray } from 'redux-form'
import {Remove, Add} from '@material-ui/icons'
import { DefaultApplicationForm } from 'constants/application'
import {
    DialogActions,
    DialogTitle,
    DialogContent,
    Tooltip,
} from '@material-ui/core'
import Button from 'components/shared/Button'
import { makeStyles } from '@material-ui/core/styles';
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import {
    InputField,
    DropdownField
} from 'components/forms/reduxForm'
import {
    phoneNumberValidation,
    validateEmail,
    isNotBlank
} from 'tools/forms/validators'
import {
    removeBlanks
} from 'tools/forms/normalizers'
import {
    statesOptions,
    businessTypeOption,
    industryTypeOption,
    countryOptions,
} from "constants/constantVariables"
import { getModalType, getFormName } from 'constants/utilityFunctions'
import strings from 'strings'
import { Dialog } from 'components/shared/Dialog'
import './dialog.scss'
import PrepareTooltip from "../../../../shared/Tooltip";
import StateOfFormationCheckBox from "./StateOfFormationCheckBox";
import StateOfFormationDropDown from "./StateOfFormationDropdown";

const useStyles = makeStyles({
    tooltip: {
        fontSize: "1em",
    },
});

const RenderAddresses = ({ fields, change, nonDeletableAddresses }) => {
    const { push, remove, length } = fields
    const countryCodes = useSelector(state => state?.form?.editForm?.values?.addresses?.map(address => address.countryCode))
    const addressState = useSelector(state => state?.form?.editForm?.values?.addresses[0]?.state)
    const [stateOptions, setStateOptions] = useState(countryCodes ? countryCodes.map(countryCode => statesOptions[countryCode]) : [statesOptions.USA])
    const [hideAddresses, setHideAddresses] = useState([])
    const [stateOfFormationCheckBox, setStateOfFormationCheckBox] = useState(false)
    const [disableStateOfFormation, setDisableStateOfFormation] = useState(false)
    const classes = useStyles();

    const handleUseAddressStateAsStateOfFormation = () =>{
        if (stateOfFormationCheckBox){
            change(`stateOfFormation`, null)
            setStateOfFormationCheckBox(false)
            setDisableStateOfFormation(false)
        } else {
            change(`stateOfFormation`, addressState)
            setStateOfFormationCheckBox(true)
            setDisableStateOfFormation(true)
        }
    }
    const updateStateOfFormation = (state) => {
        if (!stateOfFormationCheckBox && !state) {
            change(`stateOfFormation`, null)
        }
    }
    const handleCountryChange = (selectedCountry, index) => {
        change(`addresses[${index}].state`, '')
        setStateOptions(prev => {
            const newStateOptions = [...prev]
            newStateOptions[index] = statesOptions[selectedCountry];
            return newStateOptions;
        })
    }

    const handleDeleteAddress = (index) => {
        if (fields.get(index).addressId) {
            change(`addresses[${index}].deleteOnUpdate`, true)
            setHideAddresses(prev => [...prev, index])
        }
        else {
            remove(index)
            setStateOptions(prev => {
                const newStateOptions = [...prev]
                newStateOptions.splice(index, 1);
                return newStateOptions;
            })
        }
    }

    const handleAddAddress = (index) => {
        setStateOptions(prev => {
            const newStateOptions = [...prev]
            newStateOptions[index] = statesOptions.USA
            return newStateOptions
        })
        push(DefaultApplicationForm.business.addresses[0])
    }

    const nonDeletableAddressesIncludes = (index) => {
        return nonDeletableAddresses?.includes(fields.get(index).addressId)
    }

    return (
        fields.map((address, index) => (
            <div key={index}>
                {index === 1 && length > hideAddresses.length + 1 && <h5 className='mt-3 addresses-title'> {strings.APPLICANT.ADDITIONAL_ADDRESSES} </h5>}
                {!hideAddresses.includes(index) &&
                    <>
                        <Row>
                            <Col>
                                <InputField
                                    name={`${address}.address1`}
                                    label='Address 1 *'
                                    required
                                    id='address1'
                                    disabled={nonDeletableAddressesIncludes(index)}
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name={`${address}.address2`}
                                    label='Address 2'
                                    id='address2'
                                    disabled={nonDeletableAddressesIncludes(index)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputField
                                    name={`${address}.city`}
                                    label='City *'
                                    required
                                    id='city'
                                    disabled={nonDeletableAddressesIncludes(index)}
                                />
                            </Col>
                            <Col md={2}>
                                <InputField
                                    name={`${address}.zip5`}
                                    label='Zipcode *'
                                    required
                                    id='zip5'
                                    disabled={nonDeletableAddressesIncludes(index)}
                                />
                            </Col>
                            <Col md={2}>
                                <DropdownField
                                    name={`${address}.state`}
                                    label='State'
                                    required
                                    options={stateOptions[index]}
                                    isSearchable
                                    id='state'
                                    disabled={nonDeletableAddressesIncludes(index)}
                                />
                            </Col>
                            <Col md={2}>
                                <Row>
                                    <Col md={9}>
                                        <DropdownField
                                            name={`${address}.countryCode`}
                                            label='Country'
                                            options={countryOptions}
                                            isSearchable
                                            required
                                            onChange={(selectedCountry) => handleCountryChange(selectedCountry, index)}
                                            id='countryCode'
                                            disabled={nonDeletableAddressesIncludes(index)}
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <div className='center-icon'>
                                            {index === 0 && length < hideAddresses.length + 3 && <Add className="icon" onClick={() => handleAddAddress(length)} />}
                                            {index > 0 && (nonDeletableAddressesIncludes(index) ?
                                                <Tooltip
                                                    classes={{ tooltip: classes.tooltip }}
                                                    title="This Address cannot be deleted since it is already associated with a Filing or Report in this Application."
                                                    arrow
                                                >
                                                    <Remove className="icon" color={'disabled'} />
                                                </Tooltip>
                                                :
                                                <Remove className="icon" onClick={() => handleDeleteAddress(index)} />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {index === 0 &&
                            <Row>
                                <Col md={6}/>
                                <Col md={1}>
                                    <PrepareTooltip
                                        title={strings.APPLICANT.USE_ADDRESS_STATE_AS_STATE_OF_FORMATION}
                                        theme={"white"}>
                                        <StateOfFormationCheckBox
                                            checked={stateOfFormationCheckBox}
                                            onChange={() => {handleUseAddressStateAsStateOfFormation(index)}}/>
                                    </PrepareTooltip>
                                </Col>
                                <Col md={5}>
                                    <StateOfFormationDropDown
                                        name={`stateOfFormation`}
                                        stateOfFormationCheckBox={stateOfFormationCheckBox}
                                        disabled={disableStateOfFormation}
                                        stateOptions={stateOptions}
                                        index={index}
                                        onChange={(selectedState) => updateStateOfFormation(selectedState)}/>
                                </Col>
                            </Row>
                        }
                    </>
                }
            </div>
        ))
    )
}

const Business = (props) => {
    const {
        open,
        onClose,
        onSubmit,
        dirty,
        change,
        type,
        modalMode,
        getSalesRepresentatives,
        hideFmcsa,
        addresses,
        loading
    } = props

    const modalType = getModalType(type)
    const formName = getFormName(modalMode, modalType)
    const representativeList = useSelector(state => state.root.salesRepresentative.list).map(data => ({ value: data.uuid, label: `${data.firstName} ${data.lastName}` }));
    const nonDeletableAddresses = addresses?.filter(address => address.nonDeletable === true).map(address => address.addressId)

    useEffect(() => {
        if (open) {
            getSalesRepresentatives({}, true)
        }
        // eslint-disable-next-line
    }, [getSalesRepresentatives, open])

    return (
        <>
            {formName &&
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth='md'
                >
                    <DialogTitle>
                        {formName}
                    </DialogTitle>
                    <DialogContent>
                        <Row>
                            <Col md={3}>
                                <InputField
                                    name='name'
                                    label='Business Name *'
                                    required
                                    id='name'
                                />
                            </Col>
                            <Col md={3}>
                                <InputField
                                    name='dbaName'
                                    label='Doing Business As'
                                    id='dbaName'
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='phones[0].number'
                                    label='Phone'
                                    validate={phoneNumberValidation}
                                    isphoneNumber
                                    id='phone'
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='emails[0].email'
                                    label='Email'
                                    validate={validateEmail}
                                    normalize={removeBlanks}
                                    id='email'
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='website'
                                    label='Website'
                                    id='website'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}>
                                <DropdownField
                                    isSearchable
                                    name='businessType'
                                    label='Business Type'
                                    options={businessTypeOption}
                                    required
                                    id='businessType'
                                />
                            </Col>
                            <Col md={3}>
                                <DropdownField
                                    isSearchable
                                    name='industryType'
                                    label='Industry Type'
                                    options={industryTypeOption}
                                    required
                                    id='industryType'
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='identifications[0].identification'
                                    label='EIN'
                                    id='identification'
                                />
                            </Col>
                            {!hideFmcsa &&
                                <>
                                    <Col>
                                        <InputField
                                            name='mcNumber'
                                            label='MC'
                                            id='mcNumber'
                                        />
                                    </Col>
                                    <Col>
                                        <InputField
                                            name='dotNumber'
                                            label='DOT'
                                            id='dotNumber'
                                        />
                                    </Col>
                                </>
                            }
                        </Row>
                        {type === 'business' &&
                            <Row>
                                <Col md={3}>
                                    <DropdownField
                                        name='salesRepresentative.uuid'
                                        label='Sales Rep.'
                                        options={representativeList}
                                        isSearchable
                                        id='salesRepresentative'
                                    />
                                </Col>
                                <Col md={3}>
                                    <InputField
                                        name='contractAmount'
                                        label='Contract Amount'
                                        numberFormat
                                        id='contractAmount'
                                    />
                                </Col>
                                <Col md={6} >
                                    <InputField
                                        name='fcsClientReference'
                                        label='Client reference'
                                        validate={isNotBlank}
                                        id='fcsClientReference'
                                    />
                                </Col>
                            </Row>
                        }
                        <FieldArray
                            name={`addresses`}
                            component={RenderAddresses}
                            props={{ change, nonDeletableAddresses }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' onClick={onClose}>
                            {strings.CANCEL}
                        </Button>
                        <Button
                            variant="contained"
                            color='primary'
                            onClick={() => onSubmit()}
                            disabled={!dirty}
                            loading={loading}
                            style={{ minWidth: '140px' }}
                        >
                            {formName}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default Business