import LocalizedStrings from 'react-localization'

let strings = new LocalizedStrings({
  en: {
    /* Place global strings */
    CREATE: 'Create',
    RESET: 'Reset',
    CLOSE: 'Close',
    ADD: 'Add',
    SUBMIT: 'Submit',
    CANCEL: 'CANCEL',
    CONFIRM: 'Confirm',
    DETAILS: 'Details',
    VALUE: 'Value',
    OFFLINE: 'Offline',
    ONLINE: 'Online',
    FILING_LIST: 'Filing List',
    DOCUMENT_LIST: 'Document List',
    OR: 'OR',
    ERROR: 'error',
    LOADING: 'Loading',
    UPDATE: 'Update',
    BUSINESS: 'Business',
    OWNER: 'Owner',
    DEBTOR: 'Debtor',
    AUTHORIZING_PARTY: 'Authorizing Party',
    CLIENT: 'Client',
    CUSTOMER: 'Customer',
    USER: 'User',
    USERS: 'Users',
    EDIT: 'Edit',
    DELETE: 'Delete',
    VIEW: 'View',
    APPLY: 'Apply',
    FILTERS: 'Filters',
    HIDDEN: 'Hidden',
    COLUMNS: 'Columns',
    DOWNLOAD_PDF: 'Download Pdf',
    DOWNLOAD_DOCUMENT: 'Download Document',
    ATTACH: 'Attach',
    ATTACH_PDF: 'Attach pdf',
    ATTACH_ITEMS: 'Attach Items',
    ORIGINAL_FILINGS: 'Original Filings',
    DOCUMENT_REQUEST_CONFIRMATION: 'Document Request Confirmation',
    UCC: 'UCC',
    FILING: 'Filing',
    FILINGS: 'Filings',
    NAME: 'Name',
    TITLE: 'Title',
    SEARCH: 'Search',
    SELECT: 'Select',
    Save: 'Save',
    SAVED: 'Saved',
    LOGIN: 'Login',
    PERFORM: 'Perform',
    ATTACHMENTS: 'Attachments',
    SAVED_SEARCH_REPORTS: "Saved Search Reports",
    BUSINESS_INFORMATION: 'Business Information',
    DEBTOR_INFORMATION: 'Debtor Information',
    SECURED_INFORMATION: 'Secured Party Information',
    CREDITOR_INFORMATION: 'Creditor Information',
    OFFENSES_INFORMATION: 'Offenses Information',
    ADD_OWNER: 'Add Owner',
    ADD_USER: 'Add User',
    ADD_DEBTOR: 'Add Debtor',
    ADD_SECURED_PARTY: 'Add Secured Party',
    ADD_CUSTOMER: 'Add Customer',
    CREATE_APPLICATION: 'Create Application',
    CREATE_APPLICATION_MANUALLY: 'Create Application Manually',
    PROFILE_REDIRECT: 'Profile',
    SETTINGS: 'Settings',
    CUSTOMER_PROFILE: 'Customer Profile',
    PROFILE: 'Profile',
    PROFILE_FORM: 'Profile Form',
    PASSWORD_CHANGE_FORM: 'Password Change Form',
    LOG_OUT: 'Log Out',
    ERROR_BOUNDRY_HEADER: 'We ran into a problem.',
    ERROR_BOUNDRY_BODY: 'Try to refresh your browser or use your browser back button to go back to the previous page.',
    INFO_NO_CLIENT_REFERENCE: 'requires a Client Reference configured before doing UCC operations, please go back and open the edit business dialog and enter a Client Reference.',
    INFO_ENTER_CLIENT_REFERENCE: 'requires a Client Reference configured, please enter a Client Reference',
    INFO_FILING_VIEW: 'Debtor and Secured Party details shown here are considering the Party Amendments done to this Filing. For details on other types of Amendments please check the Amendment List at the bottom of this Filing.',
    INFO_FILING_REQUEST: 'Remember that Offline Documents will be sent to your configured FCS email address.',
    DOCUMENT_VIEW: 'Document View',
    DOCUMENT_REQUEST_LOADING: 'Please wait while we receive the documents...',
    ROX_ADMIN: 'ROX_ADMIN',
    CONFIRM_ARCHIVE: 'Yes, Archive',
    CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
    CHANGE_PASSWORD: 'Change Password',
    AUTHORIZATION: 'Authorization',
    ALLOWED_TO_OPERATE: 'Allowed To Operate',
    LAST_DATA_RETRIEVAL: 'Last Data Retrieval: ',
    NO_DOT_WARNING: 'Add a DOT Number to the Business Information to be able to request FMCSA Data',
    FMCSA_VERIFICATION: 'FMCSA Verification',
    REFRESH_DATA_FROM_FMCSA: 'Refresh data from FMCSA',
    FMCSA_DETAILS: 'FMCSA Details',
    FMCSA_REPORT: 'FMCSA Report',
    SEARCH_REPORT: 'Search Report',
    SOMETHING_WENT_WRONG: "Internal Server Error. Please try again or contact Support",
    SUCCESS: 'Success',
    SUCCESSFULL: 'Successful',
    UNSUCCESSFULL: 'UNSUCCESSFUL',
    PREVIEW: 'Preview',
    NO_RECORDS_FOUND: 'No Records Found!',
    DOWNLOAD_REPORT: 'Download Report',
    DOWNLOAD_AS_PDF: 'Download as PDF',
    ATTACH_REPORT: 'Attach Report',
    COLLATERAL: 'Collateral',
    APPLICATION: 'Application',
    APPLICATIONS: 'Applications',
    GET_REPORT: 'Get Report',
    CONFIRM_DELETE: 'Confirm Delete',
    REPORT_GENERATED: 'Report was generated correctly',
    REPORT_SUCCESS_MSG: 'Report was generated and added to attachments',
    UPLOAD_FILE: 'Upload File',
    DOWNLOAD_WILL_START_SOON: 'Download will start soon.',
    ROLES: {
      ROX_ADMIN: 'ROX_ADMIN',
      UNDER_WRITER: 'UNDERWRITER'
    },
    STATUS_NAMES: {
      Unsuccessful: 'Unsuccessful',
      Successful: 'Successful',
      Hit_Completed: 'Hit & Completed',
      Soon_to_Lapse: 'Soon to Lapse',
      Queued: 'Queued',
      Not_Started: 'Not Started',
      Rejected: 'Rejected',
      Pending: 'Pending',
      Preparation: 'Preparation'
    },
    ROLE_NAMES: {
      CUSTOMER_ADMIN: 'Customer Admin',
      ROX_ADMIN: 'Rox Admin'
    },
    SETTINGS_FORM: {
      USER_EDIT_FORM: 'User Edit Form',
      USER_CREATE_FORM: 'User Create Form',
      USER_CREATE_TEMPLATE: 'User Create Template',
      SALES_REPRESENTATIVE: 'Sales Representatives',
      ADD_SALES_REPRESENTATIVE: 'Add Sales Representative',
      EDIT_SALES_REPRESENTATIVE: 'Edit Sales Representative',
      TEMPLATE: {
        DESCRIPTION: 'Description *',
        NAME: 'Name *',
        SUCCESS_MSG: 'Template Created Successfully',
        COLLATERAL_DESCRIPTION_TEMPLATE: 'Collateral Description Template',
        ADD_TEMPLATE: 'Add Template'
      }
    },
    LOGIN_STRING: {
      FORM_ERR_MSG: 'Please Enter your Username and/or Password.',
      API_ERR_MSG: 'Login Error! Incorrect Credentials.',
      FORGOT_PASSWORD: 'FORGOT PASSWORD?',
      READY_TO_ROXWRITE: `Let's get ready to rox!`,
      BACK_TO_LOGIN: 'BACK TO LOGIN',
      HINT: 'Enter the email address and we’ll send you the link to reset password',
      STRONG_PASSWORD: 'Password must contain',
      CHECK_INTERNET_CONNECTION: 'Please check your internet connection',
    },
    CUSTOMER_PROFILE_STRING: {
      CUSTOMER_NAME: 'Customer Name',
      CUSTOMER_BUSINESS_TYPE: 'Customer Business Type',
      CUSTOMER_EMAIL: 'Customer Email',
      CUSTOMER_PHONE: 'Customer Phone',
      NO_OF_USERS: 'No of Users',
      EDIT_PROFILE: 'Edit Profile'
    },
    CSV: {
      CSV_UPLOADS: 'CSV Uploads',
      NEW_CSV: 'Import New CSV ',
      RECENT_UPLOADS: 'Recent Uploads',
      CSV_FILES: 'CSV Files',
      UPLOAD_CSV_FILE: 'Upload CSV File',
      DRAG_AND_DROP: "Drag and Drop or Browse your CSV file",
      MAX_SIZE: 'Maximum file size must be less than 10MB and File type must be CSV',
    },
    DASHBOARD: {
      DASHBOARD: 'Dashboard',
      CREATE_APPLICATION: 'Create Application',
      PORTFOLIO_GROWTH: 'Portfolio Growth',
      OPEN_APPLICATIONS: 'Open Applications',
      APPLICATIONS_VALUE: 'Applications Value',
      ROX_WRITE_OVERVIEW: 'RoxWrite Overview',
      ROX_WRITE_GROWTH: 'RoxWrite Growth',
      APPLICATIONS: 'Applications',
      PORTFOLIO_OVERVIEW: 'Portfolio Overview',
      FMCSA_HINT: 'MC, DOT and Name searches FMCSA for information.',
      SEARCH_FOR_BUSINESS: 'Search for Business',
    },
    LABEL: {
      UCC_FILED_DATE: 'UCC Filed Date',
      ORGANIZATION_NAME: 'Organization Name',
      LAST_NAME: 'Last Name',
      FIRST_NAME: 'First Name',
      MIDDLE_NAME: 'Middle Name',
      PHONE: 'Phone',
      EMAIL: 'Email',
      ADDRESS: 'Address',
      CITY: 'City',
      COUNTRY: 'Country',
      STATE: 'State',
      JURISDICTION_STATE: 'Jurisdiction State',
      US_STATE: 'US State',
      ZIP: 'Zip',
      USER_NAME: 'User Name',
      ROLES: 'Roles',
      CLIENT_ID: 'Client ID',
      TAX_ID: 'Tax ID',
    },
    APPLICANT: {
      DECISIONER: {
        REQUESTING_PULL_REPORTS: 'Requesting Reports',
        PULL_REPORT_REQUESTED: 'Reports Requested, Risk Determination Finished',
      },
      SUMMARY: {
        SUMMARY_TITLE: 'Summary',
        INSIGHTS_TITLE: 'Insights',
        OVERVIEW: 'Overview',
        NO_SUMMARY_DATA: 'No Summary Data',
        NO_INSIGHTS_DATA: 'No Insights Data',
        VIEW_MORE: 'View More',
        LAST_UPDATE: 'Last Update',
        GET_DATA: 'Get Data',
        GET_ALL_DATA: 'Get All Data',
        GET_ALL_DATA_TEXT: 'Click get all data to get the latest information',
        ADD_DEBTOR: 'Add a Debtor to populate this section.',
        OWNERS_LIMIT: 'You’ve reached the limit, you can only add up to 3 owners',
        SOS_COMING_SOON: 'Automation for Secretary of State filings will be available soon. For now, ',
        SOS_COMING_SOON_LINK: 'click here to search for SOS filings.',
        SOS_FILINGS: 'SOS Filings',
      },
      LIST_OF_APPLICATIONS: 'List of Applications',
      PROGRESS_APPLICANT: 'Progress Applicant',
      TERMINATE_BUTTON: 'Terminate',
      DETAIL_FILING_REPORT: 'Detail Filing Report',
      DETAIL_REPORT: 'Detail Report',
      INACTIVE_FILINGS: 'Include Inactive Filings',
      INCLUDE_INACTIVE_FILINGS: 'Includes Inactive Filings',
      SEARCH_PARAMETERS: 'Search Parameters',
      NEW_APPLICATION: 'New Application',
      SEARCH_FROM: 'Search from',
      SEARCH_BY_APPLICANT_NAME: 'Search by Applicant Name',
      USE_BUSINESS_ADDRESS: 'Use Address from Business',
      USE_ADDRESS_STATE_AS_STATE_OF_FORMATION: 'Use Address State as State of Formation',
      ADDITIONAL_ADDRESSES: 'Additional Addresses',
      ARCHIVE_APPLICATION: 'Archive Application',
      CONFIRM_ARCHIVE: 'Are you sure you want to archive',
      YOU_CAN_UNARCHIVE: 'You can unarchive this application in the archive tab any time',
      SOS: {
        AWAITING_RESPONSE: 'Waiting for results, it usually takes 2 minutes.',
        BUSINESS_NAME: 'Business Name',
        BUSINESS_INFORMATION: 'Business Information',
        DOCUMENT: 'Document',
        DOWNLOAD: 'Download',
        ENTITY_TYPE: 'Entity Type',
        FILE: 'File',
        FILING_DATE: 'Filing Date',
        FILING_DOCUMENT: 'Filing Document',
        FILING_HISTORY: 'Filing History',
        FILING_TYPE: 'Filing Type',
        NAME_HISTORY: 'Name History',
        NO_RESULTS_PREFIX: 'No results were found for "',
        NO_RESULTS_SUFFIX: '". Try your search again with different filters or a different search term.',
        NOT_THE_RIGHT_RESULT: 'Not the right result? View other options.',
        OPEN_SOS_WEBSITE: 'Open SOS Website',
        PERFORM_SOS_SEARCH: 'Perform SOS Search',
        PHYSICAL_ADDRESS: 'Physical Address',
        POSSIBLE_ALTERNATIVES_FOUND: 'Possible Alternatives Found',
        POSSIBLE_ALTERNATIVES: 'Possible Alternatives',
        SEARCH_HISTORY: 'Search History',
        RECORD_DETAILS: 'Record Details',
        RECORDS_FOUND: 'Records Found',
        REGISTERED_AGENT_INFORMATION: 'Registered Agent Information',
        REGISTERED_AGENT_NAME: 'Registered Agent Name',
        SAVE_RECORD: 'Save Record',
        RECORD_ALREADY_SAVED: 'Record Already Saved',
        APPLICATION_ALREADY_CREATED: 'Application Already Created',
        SAVED_RECORD_DETAILS: 'Saved Record Details',
        SAVED_RECORDS: 'Saved Records',
        SCREENSHOT_URL: 'Screenshot URL',
        SEARCH_DATE: 'Search Date',
        SEARCH_ERROR: 'There was an error with the search, please try again.',
        RESEARCH: 'Research',
        SEARCH_RESULT: 'Search Result',
        SEARCH_RESULTS: 'Search Results',
        SEARCH_STATUS: 'Search Status',
        SEARCHES: 'Searches',
        SOS: 'Secretary of State',
        SOS_LINK: 'Secretary of State',
        STATE_OF_FORMATION: 'State of Formation',
        STATE_OF_SOS_REGISTRATION: 'State of SOS Registration',
        SOS_REGISTRATION: 'SOS Registration',
        STATE: 'State',
        STATUS: 'Status',
        TIMEOUT: 'This search is taking longer than expected, please come back later. Sorry for the inconvenience.',
        CONTINUE_WAITING: 'Continue Waiting',
        SOS_SEARCH: 'SOS Search',
        NO_PREVIOUS_SEARCHES: 'No Previous Searches',
        NO_RECORDS_SAVED: 'No Records Saved',
        CLOSE_MATCH: 'Close Match',
        VIEW_MORE: 'View More',
        BEST_MATCH: 'Best Match',
        ALTERNATIVE: 'Alternative',
      },
      VERIFICATION: {
        VERIFICATION: 'Verification',
        PERFORM: 'Perform Verification',
        RISK_INDICATORS: 'Risk Indicators',
        WATCH_LISTS: 'Watch Lists',
        RISK_CODE: 'Risk Code',
        NAME: 'Name',
        PROGRAM: 'Program',
      },
      UCC: {
        VIEW_DETAILS: 'View Details',
        TOTAL_PAGES: 'Total Pages',
        READY_TO_REQUEST: 'Ready to Request',
        SELECT_DOCUMENTS_TO_REQUEST: 'Select documents to request',
        OPEN_DOCUMENT: 'Open Document',
        PERFORM_UCC_SEARCH: 'Perform UCC Search',
        FILE_UCC: 'File Ucc',
        SEARCH_RESULTS: 'Search Results',
        ATTACHED_FILINGS: 'Attached Filings',
        PERFORM_SEARCH: 'Perform search to get details',
        UCC_SEARCH: 'UCC Search',
        INDEX_DATE: 'UCC Filings last updated on:',
        FILINGS: 'Filings',
        FILING_NUMBER: 'Filing Number',
        TERMINATION: 'Termination',
        TERMINATE_UCC: 'Terminate UCC',
        IRREVERSIBLE_ACTION_WARNING: 'This action is irreversible.',
        CONFIRM_TERMINATION_TEXT: 'Are you sure you’d like to terminate this UCC Filing?',
        TERMINATE_DOCUMENT: 'Terminate Document',
        FILING_TYPE: 'Filing Type',
        FILING_SELECTED: 'Filings Selected',
        REQUEST_DOCUMENTS: 'Request Documents',
        FILING_DATE: 'Filing Date',
        ACTION: 'Action',
        DOCUMENT: 'Document',
        SEARCH_TYPE: 'Search Type',
        SEARCH_DATE: 'Search Date',
        NO_PREVIOUS_SEARCHES_FOR_OWNER: 'There are no recent searches for this Owner.',
        NO_PREVIOUS_SEARCHES_FOR_BUSINESS: 'There are no recent searches for this Business.',
        NO_ATTACHED_FILINGS_FOR_OWNER: 'There are no attached filings for this Owner.',
        NO_ATTACHED_FILINGS_FOR_BUSINESS: 'There are no attached filings for this Business.',
        UCC_FILING_LAST_UPDATED_ON: 'UCC Filings last updated on',
        INCLUDE_INACTIVE_FILINGS: 'Include Inactive Filings',
        NUMBER_OF_FILINGS: 'Number of Filings',
        LAPSE_DATE: 'Lapse Date',
        DEBTORS: 'Debtors',
        SECUREDS: 'Secureds',
        AMENDMENTS: 'Amendments',
        GET_REPORT_DETAILS: 'Get Filing Details',
        GET_SEARCH_REPORT: 'Get Search Report',
        UCC_DOWNLOAD_MSG: 'UCC Document requested successfully',
        TOOLTIP1: 'Request and Download',
        TOOLTIP2: 'You’ve already requested document for this filing, please check in received documents or in attachments',
        UCC_HELP: 'UCC Search Guide',
        TOOLTIP3: 'Finish',
        TOOLTIP4: 'Re-open',
        UCC_SAVE_MSG: 'UCC search report has been saved',
        UCC_DELTE_MSG: 'UCC search report has been deleted',
        PDF_REPORT: 'Generate report',
        ALREADY_SAVED: 'You already saved this report, check the saved ones in UCC table.',
        DIRECT_AMENDMENTS: 'Direct Amendments',
        REQUEST_UCC_DOCUMENTS: 'Request UCC Documents',
        PARAMS_ERR_MSG: 'Error getting parameters for empty report',
        OFFLINE_DOCUMENTS_REQUEST_SUCCESS: 'Offline Documents Requested Successfully, Offline Documents will be delivered to your email address configured in FCS',
        ALREADY_REQUESTED_OFFLINE_DOCUMENTS: 'Offline Documents already requested, documents will be delivered to your email address configured in FCS',
        ALREADY_REQUESTED_OFFLINE_DOCUMENT: 'This Offline Document was already requested, documents will be delivered to your email address configured in FCS',
        ALREADY_REQUESTED_ONLINE_DOCUMENT: 'This Document was already requested, documents will be delivered in about one minute to ROX. Please check later.',
        ERROR_IN_DOC: 'Error related to this document. Please contact Support indicating Application and Filing Number.',
        SECURED_PARTY_MASKING: 'Secured Party Masking',
        MASKING_MSG: 'When Secured Party Masking is enabled, First Corporate Services provides the secured party information.',
        NO_RESULTS_PREFIX: 'There were no results found for "',
        NO_RESULTS_SUFFIX: '". A Report is available for download.',
        NO_RESULTS: 'There were no results found',
        REQUEST_OFFLINE_DOCUMENTS: 'Request Offline Documents',
        REQUEST_ONLINE_DOCUMENTS: 'Download Online Documents',
        NO_ATTACHED_FILINGS: 'No Filings were selected. Search and Select the business or person associated to this application. If non of the results correspond to what you’re looking for, download a ',
        NO_RESULT_REPORT_LINK: 'no result report here',
        ALL_RESULTS_WERE_ATTACHED: 'All results were attached',
        SELECT_ALL: 'Select All',
        FILING_DETAIL_GENERATED_AND_ATTACHED_SUCCESSFULLY: 'Filing Details Report generated and attached successfully to the application',
        NO_RESULTS_REPORT_GENERATED_AND_ATTACHED_SUCCESSFULLY: 'No Results Report generated and attached successfully to the application',
        NO_RESULTS_REPORT_GENERATED_SUCCESSFULLY: 'No Results Report generated successfully',
      },
      UCCFILING: {
        EDIT_AND_SUBMIT_AMENDMENT: 'Edit and Submit Amendment',
        JURISDICTION_INACTIVE: 'This Filing requires to confirm the Jurisdiction selected ',
        CONFIRM_JURISDICTION: 'Please confirm the Jurisdiction',
        CONFIRM_FILING_JURISDICTION: 'Please confirm the Filing Jurisdiction',
        FILER_REFRENCE: 'Filer Reference',
        CLIENT_REFRENCE: 'Client Reference',
        SELECT_AUTHORIZING_PARTY_HINT: 'Please select authorizing party',
        ENTER_SECURED_PARTY_DETAILS_HINT: 'Please enter Secured Party details',
        COLLATERAL_DESCRIPTION: 'Collateral Description',
        ONLY_TEXT: 'Only Text',
        ONLY_ATTACHMENTS: 'Only Attachments',
        TEXT_ATTACHMENTS: 'Text and Attachments',
        TEXT_HINT: 'Please enter collateral texts to the filing',
        ATT_HINT: 'Please select attachments to the filing',
        TEXT_ATT_HINT: 'Please enter collateral texts and select attachments to the filing',
        UCC_FILE_POSTING: 'UCC File Posting',
        UCC1_FILINGNUMBER: 'UCC1 Filing Number',
        LABEL: {
          COLLATERAL_DESCRIPTION: 'Collateral Description *',
          JURISDICTION_ID: 'Jurisdiction ID',
          FILER_REFRENCE: 'Filer Reference',
          CLIENT_REFERENCE: 'Client Reference',
          SECURED_MASKING: 'Use Secured Party Representative Service from FCS',
          ORG_ID: 'Organization Id'
        },
        NOT_SELECED: '- Not Selected -',
        PREVIEW: 'UCC File Posting',
        SECURED_PARTY: 'Secured Party',
        FILE_POSTING: 'File Posting',
        FILING_IMAGE: 'Filing Image',
        AMENDMENT_HISTORY: 'Amendment History',
        REQUEST_FILING_IMAGE: 'Request Filing Image',
        PERFORM_UCC_AGAIN: 'Perform UCC Filing Again',
        ATTACHMENT: 'Attachment',
        MINIMUM_MSG: 'Select minimum 1 file to continue',
        JURISDICTION: 'Jurisdiction',
        FILING_DETAILS: 'Filing Details',
        FILING_NUMBER: 'Filing Number',
        TERMINATED: 'Terminated',
        TERMINATION_DATE: 'Termination Date',
        LAPSE_DATE: 'Lapse Date',
        LAST_UPDATE: 'Last Update',
        RETRY: 'Retry UCC Posting',
        ERROR_HEADING: 'Reasons reported:',
        PERFORM_SEARCH: 'Perform search to get details',
        STR_HINT: 'Search to Reflect (STR)',
        ADDITIONAL_INFO: 'Additional Information',
        TAX_ID: 'Tax ID',
        TAX_ID_WARNING: 'Tax ID is required for filings in ND or SD. Otherwise it will be ignored.',
        IGNORED: 'ignored',
        STR: 'Search to Reflect',
        MASK: 'When Secured Party Masking is enabled, this information is provided by First Corporate Services after the filing is submitted',
        AMENDMENT_ACTION: 'Amendment Action',
        AMENDMENT_TYPE: 'Type',
        SS: 'Secretary of State',
        FIND_SS: 'Find Secretary of States',
        SS_HEADING: 'Choose the state where the corporation you are researching is registered.',
        REFRESH_FILING_DATA: 'Refresh Filing Data',
      },
      ATTACHMENTS: {
        ADD_ATTACHMENTS: 'Add ATTACHMENTS',
        ATTACHMENTS: 'Attachments',
        UPLOAD_MSG: "File Uploaded Successfully",
        DELETE_MSG: "Deleted Successfully",
        DRAG_AND_DROP: "Drag and Drop or Browse your file",
        SUPPORTED_FILE: 'Supports size under 10MB',
        ADD_COMMENTS: "Add Comments",
        MAX_SIZE: 'Maximum file size must be less than 10MB',
        MAX_FILES: 'Maximum five files can be selected',
        MAX_SUM_SIZE: 'Attachments should be lesser than 4MB of size per filings',
      },
      CREDIT_CHECK: {
        CREDIT_CHECK: 'Credit Check',
        COMPANY_INFORMATION: 'Company Information',
        CURRENT_CREDIT_SCORE: 'Current Credit Score',
        COMPANY_CLASSIFICATION: 'Company Classification',
        BRANCHES: 'Branches',
        CONTACT_INFORMATION: 'Contact Information',
        PREVIOUS_CREDIT_SCORE: 'Previous Credit Score',
        PAYMENT_SUMMARY: 'Payment Summary',
        FILING_SUMMARY: 'Filing Summary',
        TRADE_LINES_INFORMATION: 'Trade Lines Information',
        TRADE_PAYMENTS_BY_CREDIT: 'Trade Payments By Credit',
        LIFE_TIME_HIGH_BALANCE: 'Life Time High Balance',
        CREDIT_RATING_HISTORY: 'Credit Rating History',
        MONTHLY_BALANCE_HISTORY: 'Monthly Balance History',
        DBT_HISTORY: 'DBT History',
        RAW_DATA: 'Raw Data'
      },
      BGV: {
        BACKGROUND_CHECK: 'Background Check',
        CONNECTED_BUSINESS_REPORT: 'Connected Business Report',
        CONNECTED_BUSINESS: 'Connected Business',
        LIST_OF_BUSINESSES: 'List of businesses',
        ABOUT_DEBTOR: 'About Debtor',
        ABOUT_SECURED: 'About Secured',
        ABOUT_CREDITOR: 'About Creditor',
        OFFENCES: 'OFFENSES',
        OFFENCE: 'OFFENSE',
        HINT: 'There’s no PDF Report for this request',
        ATTACH_SUCCESS_MSG: 'Success, report has been added to attachments',
        ATTACH_TO_SAVED_REPORTS: 'Success, your report is attached to saved search reports',
        THERE_WERE_NO_RESULTS: 'There were no results found',
        NO_RESULTS_FOR_OWNER: 'Background Check works best when you provide more details about the Owner, like SSN or DOB. However, please note you may still not find a result after adding those values.',
        VERIFIED: 'Verified',
      },
      BT: {
        BANK_TRANSACTIONS: 'Bank Transactions',
        REQUEST_ACCESS: 'Request bank account access',
        BANK_ACCOUNT_ACCESS: 'Bank Account Access',
        SUCCESS_MSG: 'Email has been sent to owner to request access to the bank account.',
        SUCCESS_RESENT_EMAIL: 'Email has been resent successfully.',
        REQUEST_DATA: 'Request Data',
        REFRESH_DATA: 'Refresh Data',
        TRANSACTIONS: 'Transactions',
        BANK_ACCOUNT_ACCESS_REQUESTS: 'Bank Account Access Requests',
        RECURRENT_TRANSACTIONS: 'Recurrent Transactions',
        INSIGHTS: 'Insights',
        REQUEST_DATA_ERR_MSG: 'Error requesting data',
        SELECT_OWNER: 'Select Owner *',
        SELECT_ACCOUNT: 'Select Account *',
        SELECT_RANGE: 'Select Range *',
        BANK_TRANSACTION_DETAILS: 'Bank Transaction Details',
        RECURRENT_BANK_TRANSACTION_DETAILS: 'Recurrent Bank Transaction Details',
        NEED_TO_REQUEST_BANK_ACCESS: 'First you need to Request Bank Access to an Owner using the button above',
        RECURRING_PAYMENT_DISTRIBUTION: 'Recurring Payment Distribution',
        MONTHLY_STATEMENTS: 'Monthly Statements',
        EXPIRED_ACCESS_PREFIX: 'Access has expired for owners: ',
        EXPIRED_ACCESS_SUFFIX: '. Information may be out of date. Please request access again',
        SOON_TO_EXPIRE_ACCESS_PREFIX: 'Access soon to expire for owners: ',
        SOON_TO_EXPIRE_ACCESS_SUFFIX: '. Please request access again to ensure updates are not interrupted',
      },
      CLIENTS: {
        CONFIRM_TERMINATION: 'Confirm this termination',
        CONFIRM_CONTINUATION: 'Confirm this continuation',
        ALERT_HEADING: ' Are you sure you want to continue?',
        SUCCESS_MSG: 'Continuation Success',
        UCCS: 'UCCs',
        WAITING_RESPONSE: 'Waiting Response',
        SUCCESSFULL_AMENDMENTS: 'Successful Amendments',
        TERMINATION: 'Termination',
        REJECTED: 'Rejected Amendments',
        SUMBIT: 'Submit Continuation',
        CONTINUATION_LISTING: 'Continuation Listing',
        EXPIRED: 'Expired',
        ACTIVE_FILINGS: 'Active Filings',
        SUBMIT_TERMINATION: 'Submit Termination',
        SUBMIT_CONTINUAION: 'Submit Continuation',
        SUBMIT_AMENDMENT: 'Submit Amendment',
        ASSIGN_COLLATERAL: 'Assign Collateral',
        SELECT: 'Please select authorizing party',
        AMENDMENT: 'Amendment',
        DESCRIPTION: 'Description',
        ASSIGN_TO: 'Assign To',
        ENTER_REFERENCE: 'Please enter Filer Reference',
        CLIENT_REFERENCE: 'Please type in the Client Reference',
        JURISDICTION: 'Please select Jurisdiction',
        AUTHORIZING_PARTY: 'Please enter authorizing party',
        NEW_DEBTOR: 'New Debtor',
        EXISTING_DEBTOR: 'Existing Debtor',
        AMENDMENT_FILING_NUMBER: 'Amendment Filing Number',
        UCC_AMENDMENT: 'UCC Amendment',
        PLACEHOLDER: 'Search by filing number'
      }
    },
    ADMIN: {
      CUSTOMER_SETTINGS: 'Customer Settings',
      FEATURES_ENABLED: 'Features Enabled',
    },
    QUICK_SEARCH: {
      GUIDE: 'Guide',
      QUICK_SEARCH: 'Quick Search',
      SAVED_SEARCHES: 'Saved Searches',
      RECENT_SEARCHES: 'Recent Searches',
    },
    PP: {
      PASSWORD: 'Password',
      PLATFORM_PARTNERS: 'Platform Partners',
      ADD_PLATFORM_PARTNER: 'Add Platform Partner',
      PLATFORM_PARTNER: 'Platform Partner',
      ADD_AUTHORIZATION: 'Add Authorization',
      AUTHORIZATION_LIST: 'List of Authorized Clients',
      CONFIGURE: 'Configure Platfrom Partner',
      PLATFORM_PARTNER_NAME: 'Platform Partner Name',
      YOUR_PASSWORD: 'Your Password is',
      COPY_MSG: 'Copied to clipboard',
      ERROR_MSG: 'Please select client to authorize',
      HINT: 'Please select the role for the above selected client',
      PASSWORD_HINT1: 'Please copy the password as you will not be able to recover it again.',
      PASSWORD_HINT2: 'In case of lost password you must reset it.'
    },
    PLAID: {
      TITLE: 'Plaid Quickstart',
      SUB_TITLE: 'A Sample end to end integration with Plaid',
      BRIEF: 'Plaid is used by your loan provider as an underwriting signal to offer you more competitive loans and detect fraud. Once your financial account is connected, Plaid will be able to will display borrower transaction data in a safe and secure way to help in the underwriting process. You are granting Plaid access to retrieve your bank account information straight from your financial institution, including identity, historical and current balance, and historical transactions data. At any time you can review and revoke your information.',
      LEARN_MORE_PLAID: 'To learn more about Plaid please visit: ',
      TEXT_LINK_PLAID: 'plaid.com/how-we-handle-data',
      LEARN_MORE_ROXWRITE: 'To learn more about Roxwrite please visit: ',
      TEXT_LINK_ROXWRITE: 'roxwrite.com/privacy-policy',
      AUTHORIZE: 'Authorize Bank Account',
      LINK_SUCCESS: 'Your account has been linked successfully',
      OAUTH_BAD_REQUEST: 'Error While Doing Authentication',
      BANK_AUTHORIZED: 'Bank Account Authorized',
      PROCEEDING_WITH_AUTH: 'Proceeding with Authorization',
      SYNCING_WITH_ROX: 'Syncing with RoxWrite',
      PLEASE_TRY_AGAIN: 'Please Try Again',
      GO_TO_DASHBOARD: 'Go To Dashboard',
      GREAT_MSG: 'Great! you may close the window now, thanks'
    }
  }
})

export const addStrings = (obj, lang = 'en') => {
  const original = strings.getContent()[lang]
  strings.setContent({
    [lang]: mergeDeep(original, obj)
  })
  return strings
}

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function mergeDeep(source, target) {
  let output = Object.assign({}, target)
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, {
          [key]: source[key]
        })
        else output[key] = mergeDeep(target[key], source[key])
      } else {
        Object.assign(output, {
          [key]: source[key]
        })
      }
    })
  }
  return output
}

export default strings
