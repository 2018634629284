import request, { downloadFile } from 'tools/request'
export const SET_CLIENTS_LIST = 'SET_CLIENTS_LIST'
export const SET_CLIENT = 'SET_CLIENT'
export const RESET_FORM = 'RESET_FORM'

export const dispatchClientsList = (data, headers) => ({
    type: SET_CLIENTS_LIST,
    data,
    headers
})

export const dispatchClientById = (data) => ({
    type: SET_CLIENT,
    data
})

export const resetClientEditForm = () => ({
    type: RESET_FORM
})

export const getClientsList = (filters = {}, pagination = {}, getAllData) => async (dispatch) => {
    const query = {
        ...filters
    }
    const paginationProps = {
        'x-pagination-num': pagination.page || 0,
        'x-pagination-limit': pagination.limit || 10
    }
    const res = await request(
        'clients',
        'GET',
        null,
        true,
        getAllData ? {} : paginationProps,
        query
    )
    dispatch(dispatchClientsList(res.response, res.headers))
    return res
}

export const getClientById = (clientId) => async (dispatch) => {
    const res = await request(`clients/${clientId}`, 'GET')
    dispatch(dispatchClientById(res))
    return res
}

export const createClient = (payLoad) => async () => {
    const res = await request('clients', 'POST', payLoad)
    return res
}

export const updateClient = (payLoad) => async () => {
    const res = await request(`clients/${payLoad.uuid}`, 'PATCH', payLoad)
    return res
}

export const downloadReport = () => async () => {
    const res = await downloadFile(`billing/getReport`, 'GET', null, 'download.csv')
    return res
}