import React, {useEffect, useState} from "react"
import {FormProvider, useForm} from 'react-hook-form'
import { UccAmendmentPartyChange } from "constants/application"
import {TextField} from "@material-ui/core";
import strings from "../../../../../../../strings";
import DropDownPicker from "../../../../../../forms/ReactHookForm/DropDown/DropDownPicker";
import {countryOptions, statesOptions} from "../../../../../../../constants/constantVariables";
import SimpleDropDownPicker from "../../../../../../forms/Dropdown/SimpleDropDownPicker";
import {getJurisdictionList, performAmendmentOperation} from "../../../../../../../actions/applicants";
import SecuredPartySelector from "../../../../../../shared/Accordion/SecuredPartySelector";
import {formatDataForAccordion, getNameFromEntity} from "./utilityFunctions";
import KeyValueAccordion from "../../../../../../shared/Accordion/KeyValueAccordion";
import {DrawerAction} from "../../../../../../shared/Drawer";
import Button from "../../../../../../shared/Button";
import { Toast } from 'components/shared/Toaster';
import styles from './amendmentForm.module.scss'
import ConfirmationModal from "../../../../Drawer/ConfirmationModal";
import warningAlertIcon from '../../../../../../../styles/images/icons/warningAlert.svg'
import { useSelector } from 'react-redux'



const UccSecuredPartyChangeForm = ({ onSubmit, selectedIndex, data, filingNumber, jurisdictionId, jurisdictionState,
                                       action, afterSubmit}) => {

    const selectedParty = data[selectedIndex]
    const methods = useForm(UccAmendmentPartyChange(data, selectedIndex,
        jurisdictionState, filingNumber, jurisdictionId, action, data[0]))
    const { register, handleSubmit, formState, control, watch, setValue, trigger } = methods
    const { errors } = formState

    const [filingJurisdictionState, setFilingJurisdictionState] = useState(jurisdictionState)
    const [jurisdictionOptions, setJurisdictionOptions] = useState([])
    const [jurisdictionOptionsLoading, setJurisdictionOptionsLoading] = useState(true)
    const [authorizingParty, setAuthorizingParty] = useState(null)
    const [visibleConfirmationModal, setVisibleConfirmationModal] = useState(false)
    const [isConfirmationModalButtonLoading, setIsConfirmationModalButtonLoading] = useState(false)

    const clientReferenceRequired = useSelector(state => state.user.userDetails.securityContext.client.clientReferenceRequired)


    useEffect(() => {
        getJurisdictionList({countryCode: 'USA', state: filingJurisdictionState})().then((response) => {
            setJurisdictionOptions(response)
            setJurisdictionOptionsLoading(false)
        })
    }, [filingJurisdictionState]);

    useEffect(() => {
        if (!authorizingParty) {
            return;
        }
        setValue('authorizingParty.organizationName', authorizingParty?.organizationName)
        setValue('authorizingParty.firstName', authorizingParty?.firstName)
        setValue('authorizingParty.lastName', authorizingParty?.lastName)
        setValue('authorizingParty.middleName', authorizingParty?.middleName)
        setValue('authorizingParty.address.streetAddress', authorizingParty?.address?.streetAddress)
        setValue('authorizingParty.address.city', authorizingParty?.address?.city)
        setValue('authorizingParty.address.postalCode', authorizingParty?.address?.postalCode)
        setValue('authorizingParty.address.state', authorizingParty?.address?.state)
        setValue('authorizingParty.address.country', authorizingParty?.address?.country)
    }, [authorizingParty, setValue])

    const performAmendment = (formData) => {
        setIsConfirmationModalButtonLoading(true)
        performAmendmentOperation(formData)().then((res) => {
            Toast({
                type: "success", message: res.message
            })
            afterSubmit()
        }).catch((error) => {
            setVisibleConfirmationModal(false)
            Toast({
                type: "error", message: error.message
            })
        }).finally(() => {
            setVisibleConfirmationModal(false)
            setIsConfirmationModalButtonLoading(false)
        })
    }

    const validateFormAndDisplayConfirmationModal = async () => {
        const isValid = await trigger();
        if (isValid) {
            setIsConfirmationModalButtonLoading(false)
            setVisibleConfirmationModal(true)
        }
    }


    // TO REFACTOR with UccTerminationRequest at a later stage. 
    const validateOrgName = (value, disabled) => {
        if (disabled) {
            return true; // Skip validation if the field is disabled
        }
        const firstName = watch('affectedParty.firstName');
        const lastName = watch('affectedParty.lastName');

        if (!firstName && !lastName && !value) {
            return 'Organization Name or Person Name is required';
        }
        return true;
    }

    const validatePersonName = (value, disabled) => {
        if (disabled) {
            return true; // Skip validation if the field is disabled
        }
        const orgName = watch('affectedParty.organizationName');

        if (!orgName && !value) {
            return 'Organization Name or Person Name is required';
        }
        return true;
    }

    const disableOrganizationName = watch('affectedParty.firstName').length > 0 ||
        watch('affectedParty.middleName').length > 0 ||
        watch('affectedParty.lastName').length > 0
    const disablePersonName = watch('affectedParty.organizationName').length > 0


    // END TO REFACTOR



    return (
        <>
            <KeyValueAccordion data={formatDataForAccordion(selectedParty)}
                               title={getNameFromEntity(selectedParty)}
                               defaultExpanded={true}
            />
            <div className='hint mt-3'>{strings.APPLICANT.UCCFILING.ENTER_SECURED_PARTY_DETAILS_HINT}</div>
            {/* TODO refactor with */}
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <div className={styles.form}>
                        <TextField
                            size={'small'}
                            className={styles.formInputText}
                            label="Organization Name"
                            variant="outlined"
                            {...register('affectedParty.organizationName', {
                                validate: (value) => validateOrgName(value, disableOrganizationName) })}
                            error={!!errors?.affectedParty?.organizationName}
                            helperText={errors?.affectedParty?.organizationName?.message}
                            disabled={disableOrganizationName}
                        />
                        <TextField
                            size={'small'}
                            className={styles.formInputText}
                            label="First Name"
                            variant="outlined"
                            {...register('affectedParty.firstName', 
                                {validate: value => validatePersonName(value, disablePersonName) })}
                            disabled={disablePersonName}
                            error={!!errors.affectedParty?.firstName}
                            helperText={errors.affectedParty?.firstName?.message}
                        />
                        <TextField
                            size={'small'}
                            className={styles.formInputText}
                            label="Middle Name"
                            variant="outlined"
                            {...register('affectedParty.middleName')}
                            error={!!errors.affectedParty?.middleName}
                            helperText={errors.affectedParty?.middleName?.message}
                            disabled={disablePersonName}
                        />
                        <TextField
                            size={'small'}
                            className={styles.formInputText}
                            label="Last Name"
                            variant="outlined"
                            {...register('affectedParty.lastName', {
                                validate: value => validatePersonName(value, disablePersonName) })}
                            error={!!errors.affectedParty?.lastName}
                            helperText={errors.affectedParty?.lastName?.message}
                            disabled={disablePersonName}
                        />
                        <TextField
                            size={'small'}
                            className={styles.formInputText}
                            label="Address"
                            variant="outlined"
                            {...register('affectedParty.address.streetAddress',
                                {required: 'Address is required'})}
                            error={!!errors.affectedParty?.address?.streetAddress}
                            helperText={errors.affectedParty?.address?.streetAddress?.message}
                        />
                        <TextField
                            size={'small'}
                            className={styles.formInputText}
                            label="City"
                            variant="outlined"
                            {...register('affectedParty.address.city',
                               {required: 'City is required'})
                            }
                            error={!!errors.affectedParty?.address?.city}
                            helperText={errors.affectedParty?.address?.city?.message}
                        />
                        <TextField
                            size={'small'}
                            className={styles.formInputText}
                            label="Zip"
                            variant="outlined"
                            {...register('affectedParty.address.postalCode',
                                 {required: 'Zip is required'}
                            )}
                            error={!!errors.affectedParty?.address?.postalCode}
                            helperText={errors.affectedParty?.address?.postalCode?.message}
                        />
                        <DropDownPicker control={control}
                                        className={styles.formInputText}
                                        options={statesOptions[watch('affectedParty.address.country')]}
                                        label={strings.LABEL.STATE}
                                        name={'affectedParty.address.state'}
                                        preSelectedValue={watch('affectedParty.address.state')}
                                        labelField={'label'}
                                        valueField={'value'}
                                        error={!!errors?.affectedParty?.address?.state}
                                        helperText={errors?.affectedParty?.address?.state?.message}
                        />
                        <DropDownPicker control={control}
                                        className={styles.formInputText}
                                        options={countryOptions}
                                        label={strings.LABEL.COUNTRY}
                                        name={'affectedParty.address.country'}
                                        preSelectedValue={watch('affectedParty.address.country')}
                                        labelField={'label'}
                                        valueField={'value'}
                                        error={!!errors?.affectedParty?.address?.country}
                                        helperText={errors?.affectedParty?.address?.country?.message}
                        />
                        <TextField
                            label="Tax ID"
                            size={'small'}
                            className={styles.formInputText}
                            variant="outlined"
                            {...register('affectedParty.taxId',)}
                            error={!!errors.taxId}
                            helperText={errors.taxId?.message}
                        />
                        <DropDownPicker control={control}
                                        className={styles.formInputText}
                                        options={statesOptions['USA']}
                                        label={strings.LABEL.JURISDICTION_STATE}
                                        name={'affectedParty.jurisdictionId'}
                                        preSelectedValue={watch('affectedParty.jurisdictionId')}
                                        labelField={'label'}
                                        valueField={'value'}
                                        setValue={setValue}
                        />
                        <div className={styles.hintFullWidth}>{strings.APPLICANT.CLIENTS.ENTER_REFERENCE}</div>
                        <TextField
                            size={'small'}
                            className={styles.formInputTextFullWidth}
                            label="Filer Reference"
                            variant="outlined"
                            {...register('filerReference',)}
                            error={!!errors.filerReference}
                            helperText={errors.filerReference?.message}
                        />
                        <div className={styles.hintFullWidth}>{strings.APPLICANT.CLIENTS.CLIENT_REFERENCE}</div>
                        <TextField
                            size='small'
                            className={styles.formInputTextFullWidth}
                            label="Client Reference"
                            variant="outlined"
                            {...register('clientReference',
                                {required: clientReferenceRequired ? 'Client Reference is required' : false}
                            )}
                            error={!!errors.clientReference}
                            helperText={errors.clientReference?.message}
                        />
                        <div
                            className={styles.hintFullWidth}>{strings.APPLICANT.UCCFILING.CONFIRM_FILING_JURISDICTION}
                        </div>
                        <SimpleDropDownPicker options={statesOptions['USA']}
                                              label={strings.LABEL.JURISDICTION_STATE}
                                              name={'filingJurisdictionState'}
                                              selectedValue={filingJurisdictionState}
                                              setSelectedValue={setFilingJurisdictionState}
                                              labelField={'label'}
                                              valueField={'value'}
                        />
                        {!jurisdictionOptionsLoading && (
                            <DropDownPicker control={control}
                                            options={jurisdictionOptions}
                                            label={strings.APPLICANT.UCCFILING.JURISDICTION}
                                            name={'jurisdictionId'}
                                            preSelectedValue={watch('jurisdictionId')}
                                            labelField={'name'}
                                            valueField={'id'}
                                            setValue={setValue}
                            />
                        )}
                    </div>
                </form>
                <div className={styles.hintFullWidthOutside}>{strings.APPLICANT.UCCFILING.SELECT_AUTHORIZING_PARTY_HINT}</div>
                <SecuredPartySelector
                    data={data}
                    dataFormatter={formatDataForAccordion}
                    titleFormatter={getNameFromEntity}
                    onEntitySelect={setAuthorizingParty}
                    defaultExpanded={false}
                />
            </FormProvider>
            <DrawerAction>
                <Button onClick={validateFormAndDisplayConfirmationModal}>
                    {strings.APPLICANT.UCCFILING.EDIT_AND_SUBMIT_AMENDMENT}
                </Button>
            </DrawerAction>

            <ConfirmationModal
                    open={visibleConfirmationModal}
                    onClose={() => setVisibleConfirmationModal(false)}
                    onSubmit={handleSubmit(performAmendment)}
                    isLoading={isConfirmationModalButtonLoading}
                    icon={warningAlertIcon}
                    title={strings.APPLICANT.CLIENTS.SUBMIT_AMENDMENT}
                    bodyText={strings.APPLICANT.CLIENTS.ALERT_HEADING}
                    confirmText={strings.CONFIRM}
                    color='primary'
                />
        </>
    )
}

export default UccSecuredPartyChangeForm
