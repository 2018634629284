import { connect } from 'react-redux'
import CreateApplication from 'components/Applicants/CreateApplication'
import { getFmcsaData, getApplicantsById, createApplication } from 'actions/applicants'
import { dispatchNotification, getSalesRepresentatives } from 'actions/root'
import { DefaultApplicationForm } from 'constants/application'
import { updateInitialValuesWithSosData } from 'constants/utilityFunctions'
import { cloneDeep } from 'lodash'

// function to map from 2digit country codes to 3digit country codes 
function mapCountryCode(countryCode) {
    switch (countryCode) {
        case 'US':
            return 'USA'
        case 'CA':
            return 'CAN'
        case 'MX':
            return 'MEX'
        default:
            return ''
    }
}


function mapStateToProps(stateProps, ownProps) {
    const { location } = ownProps
    const { globalAccountSelected, refreshApplicant } = stateProps?.root
    const { applicantById, fmcsaData, extraContent, uuid, sosData } = stateProps?.applicant
    const hideFmcsa = stateProps?.user?.userDetails?.securityContext?.client?.hideFmcsa || false;
    const formValues = stateProps?.form?.applicationForm?.values
    const {
        legalName,
        phyStreet,
        phyCity,
        phyCountry,
        phyState,
        phyZipcode,
        ein,
        dotNumber,
        dbaName
    } = fmcsaData[0]?.carrier || {}
    const search = new URLSearchParams(location.search)
    const codeType = search.get('codeType')
    const codeValue = search.get('codeValue')
    const sosSearch = search.get('sosSearch')
    let initialValues = cloneDeep(DefaultApplicationForm)

    if (sosSearch) {
        initialValues = updateInitialValuesWithSosData(initialValues, sosData);
    }

    if (codeType) {
        let business = initialValues.business
        initialValues.business.name = legalName
        business.dotNumber = dotNumber
        business.dbaName = dbaName || legalName
        business.addresses[0].address1 = phyStreet
        business.addresses[0].countryCode = mapCountryCode(phyCountry)
        business.addresses[0].state = phyState
        business.addresses[0].city = phyCity
        business.addresses[0].zip5 = phyZipcode
        business.identifications[0].identification = ein
        business.mcNumber = extraContent?.mcNumbers && extraContent?.mcNumbers[0]
    }
    initialValues.fmcsaInteractionResponseUuid = uuid
    initialValues.fmcsaBasicUuid = extraContent?.basicSearchUuid
    initialValues.fmcsaAuthorityUuid = extraContent?.authoritySearchUuid
    initialValues.fmcsaMcNumberUuid = extraContent?.mcNumberSearchUuid
    initialValues.fmcsaCargoCarriedUuid = extraContent?.cargoSearchUuid
    initialValues.fmcsaOperationClassificationUuid = extraContent?.operationSearchUuid
    initialValues.fmcsaInteractionResponseUuid = uuid
    // debtor
    let debtor = initialValues.debtor
    debtor.identifications[0].identification = ein
    return {
        initialValues: { ...initialValues },
        globalAccountSelected,
        fmcsaData: fmcsaData[0]?.carrier,
        refreshApplicant,
        applicant: applicantById,
        codeType,
        codeValue,
        formValues,
        ein,
        dbaName,
        hideFmcsa
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        // Add all kind of dispatch functions here
        getFmcsaData, //action to get Fmcsa Data
        getApplicantsById, // action to get the applicants BYID
        dispatchNotification, // action to show notification
        createApplication, // action to create application
        getSalesRepresentatives // action to get list of salesRepresentatives
    },
    mergeProps
)(CreateApplication)