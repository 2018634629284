import React from "react";
import strings from "../../../../../../../../strings";
import moment from "moment/moment";
import '../FilingDetails.scss'


const FilingDetailsContainerHeader = ({item}) => {

    return  (
        <>
            <p className='containerHeaderFirstElement'>
                {strings.APPLICANT.UCC.FILING_NUMBER + ': ' + item.FilingNumber}
            </p>
            <p className={`${item.Status === 'Active' ? 'greenLabel' : 'redLabel'}`}>
                {item.Status}
            </p>
            <p className='containerHeaderThirdElement'>
                {strings.APPLICANT.UCC.LAPSE_DATE + ': ' + moment(item.LapseDate).format('MM/DD/YYYY')}
            </p>
        </>
    )
}

export default FilingDetailsContainerHeader;