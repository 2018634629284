import React, {useState} from 'react'
import Drawer, {DrawerAction} from "../../../../../../../shared/Drawer";
import strings from "../../../../../../../../strings";
import {FormProvider, useForm} from "react-hook-form";
import {uccDirectTerminationForm} from "../../../../../../../../constants/application";
import Grid from "@material-ui/core/Grid";
import styles from "./UccTerminationRequest.module.scss"
import {TextField} from "@material-ui/core";
import JurisdictionPicker from "../../utils/JurisdictionPicker";
import FormCheckBox from "../../../../../../../forms/ReactHookForm/Checkbox/FormCheckBox";
import Button from "../../../../../../../shared/Button";
import { Toast } from 'components/shared/Toaster';
import {terminateUCC, updateDirectAmendments} from "../../../../../../../../actions/applicants";
import {countryOptions, statesOptions} from "../../../../../../../../constants/constantVariables";
import DropDownPicker from "../../../../../../../forms/ReactHookForm/DropDown/DropDownPicker";
import ConfirmationModal from "../../../../../Drawer/ConfirmationModal";
import ConfirmDeleteIcon from 'styles/images/icons/confirmDelete.svg'
import {useDispatch, useSelector} from 'react-redux';


const UccTerminationRequest = (props) => {
    const {
        open,
        onClose,
        onSubmit,
        filingToTerminate,
        applicationId,
        interactionId
    } = props


    const fcsClientReference = useSelector(state => state?.applicant?.applicantById?.business?.fcsClientReference);
    const clientReferenceRequired = useSelector(state => state?.user?.userDetails?.securityContext?.client?.clientReferenceRequired);

    const methods = useForm(uccDirectTerminationForm(filingToTerminate, applicationId, interactionId, fcsClientReference))
    const { register, handleSubmit, trigger,
        formState, control, watch, setValue } = methods
    const { errors } = formState
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [confirmationLoading, setConfirmationLoading] = useState(false)

    const dispatch = useDispatch()
    
    const title = (
        <div>
            <h4 className='mb-0'>{strings.APPLICANT.UCC.TERMINATION}</h4>
            <div>{strings.APPLICANT.UCC.FILING_NUMBER}: {filingToTerminate.FilingNumber}</div>
        </div>
    );

    const hint = (text) => {
        return (
            <Grid item xs={12}>
                <p className={styles.hint}>{text}</p>
            </Grid>
        )
    }

    function performTermination(formData) {
        setConfirmationLoading(true)
        terminateUCC(formData)().then((res) => {
            Toast({
                type: "success", message: res.message
            })
            dispatch(updateDirectAmendments())
            setOpenConfirmation(false)
            onClose()
        })
        .catch((err) => {
            Toast({
                type: "error", message: err.message
            })
        })
        .finally(() => {
            setOpenConfirmation(false)
            setConfirmationLoading(false)
        })
    }

    const confirmBeforeTermination = async () => {
        const isValid = await trigger();
        if (isValid) {
            setConfirmationLoading(false)
            setOpenConfirmation(true)
        }
    }

    const validateOrgName = (value, disabled) => {
        if (disabled) {
            return true; // Skip validation if the field is disabled
        }
        const firstName = watch('authorizingParty.firstName');
        const lastName = watch('authorizingParty.lastName');

        if (!firstName && !lastName && !value) {
            return 'Organization Name or Person Name is required';
        }
        return true;
    }

    const validatePersonName = (value, disabled) => {
        if (disabled) {
            return true; // Skip validation if the field is disabled
        }
        const orgName = watch('authorizingParty.organizationName');

        if (!orgName && !value) {
            return 'Organization Name or Person Name is required';
        }
        return true;
    }

    const disableOrganizationName = watch('authorizingParty.firstName').length > 0 ||
        watch('authorizingParty.middleName').length > 0 ||
        watch('authorizingParty.lastName').length > 0
    
    const disablePersonName = watch('authorizingParty.organizationName').length > 0

    return (
        <Drawer
            open={open}
            onClose={onClose}
            anchor="right"
            title={title}
            rightSideCloseIcon
        >
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <Grid container spacing={4} className={styles.editContainer}>
                        {hint(strings.APPLICANT.CLIENTS.AUTHORIZING_PARTY)}
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                className={styles.formInputText}
                                label={'Organization Name'}
                                variant="outlined"
                                {...register('authorizingParty.organizationName', {
                                    validate: (value) => validateOrgName(value, disableOrganizationName) })}
                                error={!!errors?.authorizingParty?.organizationName}
                                helperText={errors?.authorizingParty?.organizationName?.message}
                                disabled={disableOrganizationName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                className={styles.formInputText}
                                label={'First Name'}
                                variant="outlined"
                                {...register('authorizingParty.firstName', {
                                    validate: value => validatePersonName(value, disablePersonName) })}
                                error={!!errors?.authorizingParty?.firstName}
                                helperText={errors?.authorizingParty?.firstName?.message}
                                disabled={disablePersonName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                className={styles.formInputText}
                                label={'Middle Name'}
                                variant="outlined"
                                {...register('authorizingParty.middleName')}
                                error={!!errors?.authorizingParty?.middleName}
                                helperText={errors?.authorizingParty?.middleName?.message}
                                disabled={disablePersonName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                className={styles.formInputText}
                                label={'Last Name'}
                                variant="outlined"
                                {...register('authorizingParty.lastName', {
                                    validate: value => validatePersonName(value, disablePersonName) })}
                                error={!!errors?.authorizingParty?.lastName}
                                helperText={errors?.authorizingParty?.lastName?.message}
                                disabled={disablePersonName}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                label={'Address'}
                                variant="outlined"
                                {...register('authorizingParty.address.streetAddress', {required: 'Address is required'})}
                                error={!!errors?.authorizingParty?.address?.streetAddress}
                                helperText={errors?.authorizingParty?.address?.streetAddress?.message}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                label={'City'}
                                variant="outlined"
                                {...register('authorizingParty.address.city', {required: 'City is required'})}
                                error={!!errors?.authorizingParty?.address?.city}
                                helperText={errors?.authorizingParty?.address?.city?.message}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                label={'Zip'}
                                variant="outlined"
                                {...register('authorizingParty.address.postalCode', {required: 'Zip is required'})}
                                error={!!errors?.authorizingParty?.address?.postalCode}
                                helperText={errors?.authorizingParty?.address?.postalCode?.message}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DropDownPicker control={control}
                                            className={styles.formInputText}
                                            options={statesOptions[watch('authorizingParty.address.country')] || []}
                                            label={strings.LABEL.STATE}
                                            name={'authorizingParty.address.state'}
                                                preSelectedValue={watch('authorizingParty.address.state')}
                                            labelField={'label'}
                                            valueField={'value'}
                                            error={!!errors?.authorizingParty?.address?.state}
                                            helperText={errors?.authorizingParty?.address?.state?.message}
                                            fullWidth/>
                        </Grid>
                        <Grid item xs={6}>
                            <DropDownPicker control={control}
                                            className={styles.formInputText}
                                            options={countryOptions}
                                            label={strings.LABEL.COUNTRY}
                                            name={'authorizingParty.address.country'}
                                            preSelectedValue={watch('authorizingParty.address.country')}
                                            labelField={'label'}
                                            valueField={'value'}
                                            error={!!errors?.authorizingParty?.address?.country}
                                            helperText={errors?.authorizingParty?.address?.country?.message}
                                            fullWidth/>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                size={'small'}
                                label={'Tax ID'}
                                variant="outlined"
                                {...register('authorizingParty.taxId')}
                                error={!!errors?.authorizingParty?.taxId}
                                helperText={errors?.authorizingParty?.taxId?.message}
                                fullWidth
                            />
                        </Grid>
                        {hint(strings.APPLICANT.CLIENTS.ENTER_REFERENCE)}
                        <Grid item xs={12}>
                            <TextField
                                size={'small'}
                                label={'Filer Reference'}
                                variant="outlined"
                                {...register('filerReference')}
                                error={!!errors?.filerReference}
                                helperText={errors?.filerReference?.message}
                                fullWidth
                            />
                        </Grid>
                        {hint(strings.APPLICANT.CLIENTS.CLIENT_REFERENCE)}
                        <Grid item xs={12}>
                            <TextField
                                size={'small'}
                                label={'Client Reference'}
                                variant="outlined"
                                {...register('clientReference',
                                    {required: clientReferenceRequired ? 'Client Reference is required' : false})}
                                error={!!errors?.clientReference}
                                helperText={errors?.clientReference?.message}
                                fullWidth
                            />
                        </Grid>
                        <FormCheckBox
                            label={strings.APPLICANT.UCCFILING.LABEL.SECURED_MASKING}
                            name="securedPartyMasking"
                            control={control}
                            register={register}
                        />
                        {hint(strings.APPLICANT.CLIENTS.JURISDICTION)}
                        <JurisdictionPicker
                            control={control}
                            state={watch('authorizingParty.address.state')}
                            setValue={setValue}
                            labelField={'name'}
                            valueField={'id'}
                            error={!!errors?.jurisdictionId}
                            helperText={errors?.jurisdictionId?.message}
                            fullWidth/>
                    </Grid>
                </form>
                <ConfirmationModal
                    open={openConfirmation}
                    onClose={() => setOpenConfirmation(false)}
                    onSubmit={handleSubmit(performTermination)}
                    isLoading={confirmationLoading}
                    icon={ConfirmDeleteIcon}
                    title={strings.APPLICANT.UCC.TERMINATE_UCC}
                    bodyText={strings.APPLICANT.UCC.CONFIRM_TERMINATION_TEXT}
                    subText={strings.APPLICANT.UCC.IRREVERSIBLE_ACTION_WARNING}
                    confirmText={strings.APPLICANT.UCC.TERMINATE_DOCUMENT}
                    color='secondary'
                />
                <DrawerAction color='transparent' position={'relative'}>
                    <Button onClick={confirmBeforeTermination}>
                        {strings.APPLICANT.CLIENTS.SUBMIT_TERMINATION}
                    </Button>
                </DrawerAction>
            </FormProvider>
        </Drawer>
)
}

export default UccTerminationRequest