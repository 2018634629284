import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import strings from 'strings';
import './passwordValidator.scss'

const useStyles = makeStyles((theme) => ({
    arrow: {
        color: '#bdbdbd',
        fontSize: 16
    },
    tooltip: {
        backgroundColor: "white",
        color: "black",
        border: "1px solid #C6C6C6",
    },
}));

const MyComponent = forwardRef((props, ref) => {
    return <div {...props} ref={ref}>{props.children}</div>
});

export function PasswordValidator({ password = '', isValid, children, open = false }) {
    const classes = useStyles()
    const [valid, setValid] = useState(false)

    useEffect(() => {
        isValid(valid)
    }, [valid, isValid])

    const passwordChecklist = useMemo(() => {
        const passwordChecklist = {
            containsUL: { msg: "An uppercase letter (A-Z)", valid: false },
            containsLL: { msg: "A lowercase letter (a-z)", valid: false },
            containsSC: { msg: "A special character (!@#$)", valid: false },
            contains8C: { msg: "At least 8 characters", valid: false },
            containsN: { msg: "A number (0-9)", valid: false },
        }
        const { containsUL, containsLL, containsSC, contains8C, containsN } = passwordChecklist

        // has uppercase letter
        if (password.toLowerCase() !== password) containsUL.valid = true
        else containsUL.valid = false

        // has lowercase letter
        if (password.toUpperCase() !== password) containsLL.valid = true
        else containsLL.valid = false

        // has number
        if (/\d/.test(password)) containsN.valid = true
        else containsN.valid = false

        // has special character
        if (/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password)) containsSC.valid = true
        else containsSC.valid = false

        // has 8 characters
        if (password.length >= 8) contains8C.valid = true
        else contains8C.valid = false

        // all validations passed
        if (containsUL.valid && containsLL.valid && containsN.valid && containsSC.valid && contains8C.valid) setValid(true)
        else setValid(false)

        return passwordChecklist
    }, [password]);

    const jsx = (
        <div className='checklist-container'>
            <div className='heading-style-1 ml-1 mb-3 mt-1'>
                {strings.LOGIN_STRING.STRONG_PASSWORD}
            </div>
            {
                Object.values(passwordChecklist).map(pc =>
                    <div className='mt-1 d-flex align-items-center' key={pc.msg}>
                        {pc.valid ? <DoneIcon htmlColor='#3AAA97' /> : <ErrorOutlineIcon color='secondary' />}
                        <span className={`text ml-2 ' ${pc.valid ? 'valid' : 'not-valid'}`}>{pc.msg}</span>
                    </div>
                )
            }
        </div>
    )

    return (
        <Tooltip open={open} arrow classes={classes} title={jsx} placement="right">
            <MyComponent>{children}</MyComponent>
        </Tooltip>
    );
};