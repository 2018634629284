import {
    mapBGVDebtor, mapCriminalDetail, mapParsedParty
} from "constants/application"
import React, {Fragment} from "react";
import strings from "../../../../strings";
import {Grid} from "@material-ui/core";
const bgvString = strings.APPLICANT.BGV

export function BackgroundReportAdditionalDataJsx(props) {
    const {
        openInformation
    } = props


    return (
        <div className="pb-5">
            <div className="box">
                {openInformation.entity === 'criminal' ?
                    <h5 className='mb-4'>{strings.OFFENSES_INFORMATION}</h5> :
                    <h5 className='mb-4'>
                        {openInformation.title === bgvString.ABOUT_DEBTOR ? strings.DEBTOR_INFORMATION :
                            openInformation.title === bgvString.ABOUT_SECURED ? strings.SECURED_INFORMATION :
                                strings.CREDITOR_INFORMATION}
                    </h5>
                }

                <Grid container spacing={2}>
                    {openInformation.data &&
                        (openInformation.entity === 'criminal' ? mapCriminalDetail(openInformation) : mapBGVDebtor(openInformation))
                            .map(data =>
                                <Fragment key={data.title}>
                                    <Grid item sm={3}>{data.title}</Grid>
                                    <Grid item sm={9}>{data.value}</Grid>
                                </Fragment>
                            )}
                </Grid>
            </div>

            {openInformation.data?.ParsedParties?.Party?.length && openInformation.entity === 'owner' ?
                openInformation.data.ParsedParties.Party.map((party, i) => {
                    if (party && (party.CompanyName || party.Name))
                        return (
                            <div className="box" key={i}>
                                <h5 className='mb-4'>Parsed Parties</h5>
                                <Grid container spacing={2}>
                                    {mapParsedParty(party).map(data =>
                                        <Fragment key={data.title}>
                                            <Grid item sm={3}>{data.title}</Grid>
                                            <Grid item sm={9}>{data.value}</Grid>
                                        </Fragment>
                                    )}
                                </Grid>
                            </div>
                        )
                    return null
                })
                : null}
        </div>
    )
}

export default BackgroundReportAdditionalDataJsx