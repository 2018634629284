import { produce } from 'immer'
import { startCase, cloneDeep } from "lodash"
import {
    SET_DASHBOARD_OVERVIEW,
    SET_DASHBOARD_PORTFOLIO_GROWTH,
    SET_DASHBOARD_APPLICATIONS
} from 'actions/dashboard'
import { ApplicationOverview, Status } from 'constants/chartData'

const initialState = {
    overview: [],
    applicationGrowth: {
        applicationsValue: [],
        openApplications: []
    },
    portFolioGrowth: []
}

const buildPortfolioData = (data) => {
    const completed = [], opened = [], months = []
    data.forEach(({ completedApps, newApps, month }, index) => {
        completed.push(completedApps)
        opened.push(newApps)
        months.push(startCase(month))
    });
    return {
        completed,
        new: opened,
        months
    }
}

const buildApplicationData = (data) => {
    let amount = new Array(6).fill(0)
    let application = new Array(6).fill(0)
    const { applicationsValue, openApplications } = data
    const statusBuild = Status.map(status => status.status)
    applicationsValue.forEach(item => {
        let status = Object.keys(item)[0];
        let value = item[status]
        let indexOf = statusBuild.indexOf(status)
        if (indexOf >= 0) {
            amount[indexOf] = value
        }
    })
    openApplications.forEach(item => {
        let status = Object.keys(item)[0];
        let value = item[status]
        let indexOf = statusBuild.indexOf(status)
        if (indexOf >= 0) {
            application[indexOf] = value
        }
    })
    return {
        applicationsValue: amount,
        openApplications: application
    }
}

const buildOverviewData = (data) => {
    let finalData = cloneDeep(ApplicationOverview)
    for (let x in finalData) {
        data.forEach(item => {
            if (item.name === finalData[x].name) {
                finalData[x] = {
                    ...item,
                    value: item.Open || item.value || item.Completed,
                    icon: finalData[x].icon
                }
            }
        })
    }
    data.forEach(item => {
        if (item?.duration >= 0) {
            finalData[4] = {
                ...ApplicationOverview[4],
                value: `${item.duration} D`
            }
        }
    })
    return finalData
}

export const dashboardReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_DASHBOARD_OVERVIEW:
                draft.overview = buildOverviewData(action.data?.overview)
                break;
            case SET_DASHBOARD_PORTFOLIO_GROWTH:
                draft.portFolioGrowth = buildPortfolioData(action.data)
                break;
            case SET_DASHBOARD_APPLICATIONS:
                draft.applicationGrowth = buildApplicationData(action.data?.applications)
                break;
            default:
                break;
        }
    })
}