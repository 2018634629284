import { produce } from 'immer'
import {
    SET_PLATFORM_PARTNER_LIST,
    SET_PLATFORM_PARTNER_FORM_VALUES,
    SET_AUTHORIZATION
} from 'actions/platfromPartner'

const initialState = {
    plaformPartnerList: [],
    plaformPartnerHeaders: {},
    formValues: {},
    authorizationList: []
}

export const platformPartnerReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_PLATFORM_PARTNER_LIST:
                draft.plaformPartnerList = action.data
                draft.plaformPartnerHeaders = action.headers
                break;
            case SET_PLATFORM_PARTNER_FORM_VALUES:
                draft.formValues = action.data
                break;
            case SET_AUTHORIZATION:
                draft.authorizationList = action.data
                break;
            default:
                break;
        }
    })
}