import { connect } from 'react-redux'
import { getToken, exchangeToken } from 'actions/plaid'
import PlaidIntegration from 'components/Integerations/Plaid'

function mapStateToProps(stateProps) {
    const { plaidLinkToken} = stateProps?.plaid
    return {
        ...stateProps,
        plaidLinkToken
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        getToken,
        exchangeToken
    },
    mergeProps
)(PlaidIntegration)