import React, { useState } from 'react'
import Drawer from 'components/shared/Drawer'
import strings from 'strings'
import Button from 'components/shared/Button'
import BusinessInformation from './BusinessInformation';
import RegisteredAgentInformation from './RegisteredAgentInformation';
import FilingHistory from './FilingHistory';
import { useHistory } from 'react-router-dom'

const sosStrings = strings.APPLICANT.SOS

const RecordDetailDrawer = (props) => {

    const history = useHistory()

    const { openDrawer, setOpenDrawer, recordInfo, handleSaveRecord, isQuickSearch } = props
    const [actionLoading, setActionLoading] = useState(false)

    const saveRecordButton = handleSaveRecord &&
        <Button
            onClick={() => {
                setActionLoading(true)
                handleSaveRecord()
            }}
            loading={actionLoading}
            disabled={recordInfo?.applicationId ? true : false}
            id='save-sos-record'
        >
            {recordInfo?.applicationId ? sosStrings.RECORD_ALREADY_SAVED : sosStrings.SAVE_RECORD}
        </Button>

    const createApplicationButton = isQuickSearch &&
        <Button
            onClick={() => history.push('/create-applicant?sosSearch=true')}
            disabled={recordInfo?.applicationId ? true : false}
            id='create-sos-application'
        >
            {recordInfo?.applicationId ? sosStrings.APPLICATION_ALREADY_CREATED : strings.CREATE_APPLICATION}
        </Button>

    // TODO attach to existing application button

    const headerButton = isQuickSearch ? createApplicationButton : saveRecordButton

    return (
        <Drawer
            title={sosStrings.RECORD_DETAILS}
            onClose={() => setOpenDrawer(false)}
            open={openDrawer}
            actions={headerButton}
        >
            <div className='p-3'>
                <BusinessInformation data={recordInfo} />
                <RegisteredAgentInformation data={recordInfo} />
                <FilingHistory data={recordInfo} />
            </div>
        </Drawer>
    )
}

export default RecordDetailDrawer