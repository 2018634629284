import React, {useState} from 'react'
import {attachVerificationReport, downLoadAttachment} from 'actions/applicants'
import Button from 'components/shared/Button'
import Drawer from 'components/shared/Drawer';
import strings from 'strings'
import {Toast} from "../../../../../../shared/Toaster";
import {VerificationReportRisk} from "../../../Verification/VerificationReportRisk";
import {VerificationReportWatchLists} from "../../../Verification/VerificationReportWatchLists";
import {VerificationReportBusinessChart} from "../../../Verification/VerificationReportBusinessChart";
import {VerificationReportDetails} from "../../../Verification/VerificationReportDetails";
const title = strings.APPLICANT.VERIFICATION

const VerificationDrawer = (props) => {

    const { open, onClose, applicationId, data, savedReport = true, handleSave} = props
    const reportData = data?.response?.BIID20ResponseEx?.response?.Result || {}
    const [loading, setLoading] = useState({list: false, report: false, save: false, download: false, attach: false});
    const {
        VerifiedInput,
        VerificationIndicators,
        BusinessVerification,
        RiskIndicators
    } = reportData?.CompanyResults || {}
    const {AuthorizedRepresentativeResult} = reportData?.AuthorizedRepresentativeResults || []
    const watchLists = AuthorizedRepresentativeResult?.map(item => item.WatchLists) || {}

    const handleAttach = () => {
        setLoading(prev => ({...prev, attach: true}))
        attachVerificationReport(applicationId, {pdfReportUuid: data.pdfReportUuid})()
            .then(() => Toast({type: 'success', message: strings.APPLICANT.BGV.ATTACH_SUCCESS_MSG}))
            .finally(() => setLoading(prev => ({...prev, attach: false})))
    }
    const handleDownload = () => {
        setLoading(prev => ({...prev, download: true}))
        downLoadAttachment(applicationId, data.pdfReportUuid, 'verification-report.pdf')()
            .finally(() => setLoading(prev => ({...prev, download: false})))
    }

    function getActions() {

        return (<div>
            {savedReport ?
                <div>
                    <Button variant='outlined' loading={loading.attach} onClick={handleAttach} className='mr-2'>
                        {strings.ATTACH_REPORT}
                    </Button>
                    <Button variant='outlined' loading={loading.download} onClick={() => handleDownload()}>
                        {strings.DOWNLOAD_REPORT}
                    </Button>
                </div> :
                <Button loading={loading.save} onClick={handleSave}>{strings.Save}</Button>}
        </div>)
    }

    return (
        <Drawer
            open={open}
            title={title.VERIFICATION}
            width='small'
            onClose={onClose}
            actions={getActions()}
        >
            <div className='p-3 pb-5'>
                <div className='heading-style-2 mt-2'>{title.VERIFICATION} {strings.DETAILS}</div>
                <hr className='mb-1'/>

                <VerificationReportDetails VerifiedInput={VerifiedInput} VerificationIndicators={VerificationIndicators} />
                <VerificationReportBusinessChart BusinessVerification={BusinessVerification} />
                <VerificationReportRisk riskIndicator={RiskIndicators?.RiskIndicator || []}/>
                <VerificationReportWatchLists watchLists={watchLists} />
            </div>
        </Drawer>
    )
}

export default VerificationDrawer