import React, { useState, useEffect, useCallback } from 'react'
import Button from 'components/shared/Button'
import { reduxForm } from 'redux-form'
import { useDropzone } from 'react-dropzone'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import Drawer, { DrawerAction } from 'components/shared/Drawer'
import FilterBarModule from 'components/shared/FilterBarModule'
import TableComponent from 'components/shared/TableComponent'
import { Toast } from 'components/shared/Toaster'
import { DropdownField } from 'components/forms/reduxForm'
import { CsvColumns } from 'constants/roxAdminSettings'
import strings from 'strings'
import { Icon } from 'styles'

const t = strings.CSV
const CSV = (props) => {
    const {
        handleSubmit,
        reset,
        csvList,
        csvTotalRecords,
        csvTotalPages,
        clientsList,
        getCsvList,
        getClientsList,
        submitFile
    } = props
    const [csvColumn, setCsvColumn] = useState(CsvColumns)
    const [loading, setLoading] = useState({ list: false, submit: false })
    const [open, setOpen] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length)
            Toast({ type: 'warning', message: t.MAX_SIZE })
        setSelectedFiles(acceptedFiles)
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'text/csv',
        maxFiles: 1,
        multiple: false,
        maxSize: 104857600
    });

    const getClientsListData = useCallback(() => {
        getClientsList(null, {}, true)
    }, [getClientsList])

    const getListOfCsv = useCallback(pagination => {
        setLoading(prev => ({ ...prev, list: true }))
        getCsvList(pagination).finally(() => setLoading(prev => ({ ...prev, list: false })))
    }, [getCsvList])

    useEffect(() => {
        getClientsListData()
        getListOfCsv()
    }, [getListOfCsv, getClientsListData])

    const uploadCsv = values => {
        const fd = new FormData();
        fd.append("file", selectedFiles[0])
        fd.append("client-id", values['client-id'])
        setLoading(prev => ({ ...prev, submit: true }))
        submitFile(fd)
            .then(() => {
                getListOfCsv()
                Toast({ type: "success", message: strings.APPLICANT.ATTACHMENTS.UPLOAD_MSG })
            })
            .finally(() => {
                setOpen(false)
                setLoading(prev => ({ ...prev, submit: false }))
            })
    }

    return (
        <div>
            <div className='d-flex justify-content-between mb-3'>
                <h4 className='mb-0'>{t.CSV_UPLOADS}</h4>
                <Button onClick={() => {
                    setOpen(true)
                    reset('csvForm')
                    setSelectedFiles([])
                }}>{t.NEW_CSV}</Button>
            </div>
            <div className='tabPanel'>
                <FilterBarModule
                    title={t.RECENT_UPLOADS}
                    textLabel={'Filters'}
                    columns={csvColumn}
                    receiveCustomColumn={(columns) => setCsvColumn(columns)}
                />
                <TableComponent
                    data={csvList}
                    columns={csvColumn}
                    loading={loading.list}
                    totalRecords={csvTotalRecords}
                    totalPages={csvTotalPages}
                    onChangePagination={getListOfCsv}
                />
            </div>

            <Drawer title={t.CSV_FILES} open={open} onClose={() => setOpen(false)} width='small'>
                <div className='p-3'>
                    <DropdownField
                        name='client-id'
                        options={clientsList.map(list => ({ label: list.name, value: list.clientId }))}
                        label={strings.LABEL.CLIENT_ID}
                        required
                    />

                    <div className='heading-style-1 mt-5 mb-2'>{t.UPLOAD_CSV_FILE}</div>
                    {
                        selectedFiles.length ? <>
                            <div className="files">
                                <div> {selectedFiles[0]?.name}</div>
                                <div>
                                    <span className="fileName">{(selectedFiles[0]?.size / 1024).toFixed(2)}kb</span>

                                    <HighlightOffOutlinedIcon
                                        className="del"
                                        onClick={() => !loading.submit && setSelectedFiles([])}
                                    />
                                </div>
                            </div>
                        </> :
                            <div {...getRootProps({ className: "dropBox" })}>
                                <input {...getInputProps()} />
                                <Icon folder='icons' fileName='fileUpload.svg' />
                                <div className="hint mt-2">{t.DRAG_AND_DROP}</div>
                                <div className="hint">{strings.APPLICANT.ATTACHMENTS.SUPPORTED_FILE}</div>
                            </div>
                    }
                </div>
                <DrawerAction>
                    <Button
                        onClick={handleSubmit(uploadCsv)}
                        loading={loading.submit}
                        disabled={!selectedFiles.length}
                    >
                        {strings.UPLOAD_FILE}
                    </Button>
                </DrawerAction>
            </Drawer>
        </div>
    )
}

export default reduxForm({
    form: 'csvForm',
    enableReinitialize: true
})(CSV)