import React from 'react';
import UccHeaderCard from './UccHeaderCard';
import UccContentCard from './UccContentCard';
import RoxAccordion from 'components/shared/RoxAccordion';

const UccCard = ({ button, entityName, address, arrayOfFilings, indexDate, tableData, column }) => {

    return (
        <RoxAccordion
            expanded={false}
            summary={
                <UccHeaderCard
                    arrayOfFilings={arrayOfFilings}
                    entityName={entityName}
                    address={address}
                    indexDate={indexDate}
                    button={button}
                />
            }
            details={
                <UccContentCard
                    tableData={tableData}
                    column={column}
                />
            }
        />
    )
}

export default UccCard