import React from 'react'
import Popover from '@material-ui/core/Popover'
import PropTypes from 'prop-types'
import './popupMenu.scss'

const PopupMenu = (props) => {
    const {
        children,
        open, // used to handle open or close the menu
        handleClose,
        className,
        anchorOrigin, // This is the point on the anchor where the popover's anchorEl will attach to
        transformOrigin // This is the point on the popover which will attach to the anchor's origin.
    } = props

    return (
        <Popover
            id='customized-menu'
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={anchorOrigin || {
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={transformOrigin || {
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <div className={`filter-menu-list ${className}`}>
                {children}
            </div>
        </Popover>
    )
}

PopupMenu.propTypes = {
    label: PropTypes.string,
    selected: PropTypes.bool,
    handleOpen: PropTypes.func,
    open: PropTypes.object,
    className: PropTypes.string,
    anchorOrigin: PropTypes.shape({ vertical: PropTypes.string, horizontal: PropTypes.string }),
    transformOrigin: PropTypes.shape({ vertical: PropTypes.string, horizontal: PropTypes.string })
}

PopupMenu.defaultProps = {
    label: 'filter',
    selected: false,
    handleOpen: () => { },
    handleClose: () => { },
    open: false,
    className: ''
}

export default PopupMenu
