import React, { useEffect, useState, memo } from 'react'
import { useSelector } from 'react-redux'
import './applicantDetail.scss'
import { getSummaryInfo } from 'actions/applicants'
import emptyIcon from 'styles/images/icons/empty.svg'
import strings from 'strings'
import Loader from 'components/shared/Loader'

const summaryStrings = strings.APPLICANT.SUMMARY

const SummaryCard = memo(function Summary({ applicantId, updates }) {

    const [summaryInfo, setSummaryInfo] = useState([])
    const [additionalSummaryInfo, setAdditionalSummaryInfo] = useState([])
    const updateFromRedux = useSelector(state => state.applicant.updateSummary)

    const [loadingSummary, setLoadingSummary] = useState(false)

    useEffect(() => {
        if (summaryInfo?.length === 0) setLoadingSummary(true);
        getSummaryInfo(applicantId)()
            .then((res) => {
                setSummaryInfo(res?.summary || []);
                setAdditionalSummaryInfo(res?.additionalSummary || []);
            })
            .finally(() => {
                setLoadingSummary(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updates, applicantId, updateFromRedux]);

    const TwoColumnLayout = (mode, leftText, desc, status, index) => {
        return (
            <div className={mode === 'MULTIPLE_ELEMENTS' ? 'd-flex mt-1' : 'd-flex mt-3'} key={index}>
                <div className='d-flex align-items-center flex-fill'>
                    <div className={mode === 'ONE_ELEMENT' ? 'itemName' : 'itemEntityName'}>
                        {leftText}
                    </div>
                </div>
                {DescriptionAndStatus(desc, status)}
            </div>
        )
    }

    const summaryItem = ({ item, index }) => {
        return (
            <div key={index}>
                <hr></hr>

                {item.childs.length === 1 ?
                 TwoColumnLayout('ONE_ELEMENT', item.name, item.childs[0].description, item.childs[0].status, 0) :
                 <>
                    <div className='mt-3'>
                            <div className='itemName mb-2'>
                                {item.name}
                            </div>

                            {item.childs.map((child, childIndex) => (
                                TwoColumnLayout('MULTIPLE_ELEMENTS', child.entityName, child.description, child.status, childIndex)
                        ))}
                    </div>
                 </>
                }

            </div>
        )
    }


    function DescriptionAndStatus(description, status) {
        return <div className='d-flex summaryDescriptionAndStatus'>
            <div className='flex-fill d-flex itemDescriptionContainer align-middle'>
                <p className='itemDescription'>{description}</p>
            </div>

            <div className='statusContainer'>
                <div className={`itemStatus ${status.toLowerCase()}StatusColor`}></div>
            </div>
        </div>
    }



    const NoDataIndication = (props) => {
        return (
            <div className='summaryNoDataIndication mt-5'>
                <img src={emptyIcon} alt='empty' />
                <p className='mt-1'>{props.message}</p>
            </div>
        )
    }

    return (
        <>
            <div className='summary tabPanel' id='summary'>
                <h5 className='mb-4'>{summaryStrings.SUMMARY_TITLE}</h5>
                {
                    loadingSummary
                        ? <div className='mt-5'><Loader /></div>
                        :
                        summaryInfo?.length === 0
                            ? <NoDataIndication message={summaryStrings.NO_SUMMARY_DATA} />
                            :
                            summaryInfo?.map((item, index) => summaryItem({ item, index }))
                }
            </div>
            <div className='summary tabPanel' id='summary2'>
                <h5 className='mb-4'>{summaryStrings.INSIGHTS_TITLE}</h5>
                {
                    loadingSummary
                        ? <div className='mt-5'><Loader /></div>
                        :
                        additionalSummaryInfo?.length === 0
                            ? <NoDataIndication message={summaryStrings.NO_INSIGHTS_DATA} />
                            :
                            additionalSummaryInfo?.map((item, index) => summaryItem({ item, index }))
                }
            </div>
        </>
    )


})

export default SummaryCard