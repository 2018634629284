import React, {useEffect, useRef, useState} from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {Select, MenuItem, InputLabel, FormControl, FormHelperText} from '@material-ui/core'

const DropDownPicker = ({ control, error, helperText, options, label, name, preSelectedValue, labelField, valueField, fullWidth}) => {

    const {setValue} = useFormContext()
    const isFirstRender = useRef(true);
    const [dropDownOptions, setDropDownOptions] = useState(options)

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        setValue(name, '')
        setDropDownOptions(options)
        setValue(name, options[0][valueField])
    }, [options, name, valueField, setValue])

    return (
        <FormControl variant="outlined" fullWidth={fullWidth} error={error}>
            <InputLabel margin='dense' id={`${name}-dropdown-select`}>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                defaultValue={preSelectedValue}
                rules={{ required: 'Please select an option'}}
                error={error}
                helperText={helperText}
                render={({ field }) => (
                    <Select
                        {...field}
                        label={label}
                        labelId={`${label}-dropdown-select`}
                        onChange={(e) => field.onChange(e.target.value)}
                    >
                        {dropDownOptions.map((option, index) => (
                            <MenuItem key={index} value={option[valueField]}>
                                {option[labelField]}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
            {error && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    )
}

export default DropDownPicker