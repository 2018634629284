import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import history from 'tools/history'

import root from './root'
import { dashboardReducer as dashboard } from './dashboard'
import { applicantReducer as applicant } from './applicant'
import { clientReducer as client } from './clients'
import { userReducer as user } from './user'
import { quickSearchReducer as quickSearch } from './quickSearch'
import { platformPartnerReducer as plaformPartner } from './platformPartner'
import { csvManagementReducer as csvManagement } from './csvManagemet'
import { plaidReducer as plaid } from './plaid'

const rootReducer = combineReducers({
  router: connectRouter(history),
  form: formReducer,
  root,
  dashboard,
  applicant,
  client,
  user,
  quickSearch,
  plaformPartner,
  plaid,
  csvManagement
})

const createRootReducer = (state, action) => {
  if (action.type === 'LOGOUT_SUCCESSFUL' || action.type === 'RESET_DATA') {
    state = undefined;
  }
  return rootReducer(state, action);
}

export default createRootReducer