import React, {useEffect, useState} from 'react'
import SimpleDropDownPicker from "../../../../../../forms/Dropdown/SimpleDropDownPicker";
import {statesOptions} from "../../../../../../../constants/constantVariables";
import strings from "../../../../../../../strings";
import DropDownPicker from "../../../../../../forms/ReactHookForm/DropDown/DropDownPicker";
import {getJurisdictionList} from "../../../../../../../actions/applicants";
import Grid from "@material-ui/core/Grid";

const JurisdictionPicker = ({ control, error, helperText, state, setValue, labelField, valueField, fullWidth, preSelectedValue}) => {

    const [filingJurisdictionState, setFilingJurisdictionState] = useState(state)
    const [jurisdictionOptions, setJurisdictionOptions] = useState([])
    const [jurisdictionOptionsLoading, setJurisdictionOptionsLoading] = useState(true)

    useEffect(() => {
        getJurisdictionList({countryCode: 'USA', state: filingJurisdictionState})().then((response) => {
            setJurisdictionOptions(response)
            setJurisdictionOptionsLoading(false)
        })
    }, [filingJurisdictionState]);

    return (
        <>
            <Grid item xs={6}>
                <SimpleDropDownPicker options={statesOptions['USA']}
                                      label={strings.LABEL.JURISDICTION_STATE}
                                      name={'filingJurisdictionState'}
                                      selectedValue={filingJurisdictionState}
                                      setSelectedValue={setFilingJurisdictionState}
                                      labelField={'label'}
                                      valueField={'value'}
                                      fullWidth={fullWidth}
                />
            </Grid>
            <Grid item xs={6}>
                {!jurisdictionOptionsLoading && (
                    <DropDownPicker control={control}
                                    error={error}
                                    helperText={helperText}
                                    options={jurisdictionOptions}
                                    label={strings.APPLICANT.UCCFILING.JURISDICTION}
                                    name={'jurisdictionId'}
                                    preSelectedValue={preSelectedValue}
                                    labelField={labelField}
                                    valueField={valueField}
                                    setValue={setValue}
                                    fullWidth={fullWidth}
                    />
                )}
            </Grid>
        </>
    )
}

export default JurisdictionPicker