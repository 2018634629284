import { connect } from 'react-redux'
import Clients from 'components/Applicants/Clients';
import {
    continueUCC,
    terminateUCC,
    getActiveList,
    getDebtors,
    getUccFilingDetailsByFilingNumber,
    getUccFilingDetailsByFilingId,
    requestUccFilingImage,
    getAttachmentPDF,
    performAmendmentOperation,
    performCollateralOperation,
    getTemplates,
    downLoadAttachment,
    downLoadAttachmentByFileId,
    uploadTempFile,
    getTempFiles,
    dispatchUccFilters,
    getJurisdictionList,
    getJurisdictionsSiblings,
    requestUCCForceUpdate,
    downloadFileByPayload
} from 'actions/applicants'

function mapStateToProps(stateProps) {
    const { clients } = stateProps?.applicant
    const clientConfig = stateProps?.user?.userDetails?.securityContext?.client
    const {
        activeList,
        uccFilters,
        activeHeaders,
        debtors,
        formValues,
        ucc3Preview,
        amendmentPreview,
        tempFileList,
        tempFileheaders
    } = clients
    const activetTotalRecords = activeHeaders['x-pagination-total-records']
    const activeTotalPages = activeHeaders['x-pagination-total-pages']
    const activeCurrentPage = activeHeaders['x-pagination-current-page-num']

    const tempFileTotalRecords = tempFileheaders["x-pagination-total-records"]
    const tempFileTotalPages = tempFileheaders["x-pagination-total-pages"]

    const initialValues = formValues
    const getFilingUrlParams = (appTabValue, fileNumber) => {
        switch (appTabValue) {
            case 0:
                return `statuses=RECORDED&types=INITIAL&excludeExpired=true&fileNumber=${fileNumber}`
            case 1:
                return `statuses=RECORDED&types=INITIAL&soonToLapse=true&fileNumber=${fileNumber}`
            case 2:
                return `types=INITIAL&includeExpired=true&fileNumber=${fileNumber}`
            case 3:
                return `statuses=PENDING&fileNumber=${fileNumber}`
            case 4:
                return `statuses=RECORDED&types=AMENDMENT&fileNumber=${fileNumber}`
            case 5:
                return `statuses=REJECTED&types=AMENDMENT&fileNumber=${fileNumber}`
            case 6:
                return `statuses=TERMINATED&fileNumber=${fileNumber}`
            default:
                break
        }
    }
    return {
        initialValues,
        activeList: activeList || [],
        uccFilters: uccFilters || {},
        activetTotalRecords,
        activeTotalPages,
        activeCurrentPage,
        debtors: debtors || {},
        sprs: clientConfig?.sprsByDefault || false,
        ucc3Preview,
        amendmentPreview,
        tempFileList,
        tempFileTotalRecords,
        tempFileTotalPages,
        getFilingUrlParams
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        continueUCC,
        terminateUCC,
        getActiveList,
        getDebtors,
        getUccFilingDetailsByFilingNumber,
        getUccFilingDetailsByFilingId,
        requestUccFilingImage,
        getAttachmentPDF,
        performAmendmentOperation,
        performCollateralOperation,
        getTemplates,
        downLoadAttachment,
        downLoadAttachmentByFileId,
        uploadTempFile,
        getTempFiles,
        dispatchUccFilters,
        getJurisdictionList,
        getJurisdictionsSiblings,
        requestUCCForceUpdate,
        downloadFileByPayload
    },
    mergeProps
)(Clients)