import React from 'react'
import DataSummary from '../DataSummary/DataSummary'
import MenuOptions from '../Overview/MenuOptions'
import AuthService from 'tools/AuthService'

const EntityInfo = (props) => {

    const { entity, entityType, disableAdd = false, dialogControl } = props
    const hideAdd = entityType !== 'owner'

    return (
        <div className='d-flex justify-content-between'>
            <DataSummary className='mt-3 flex-fill' data={entity?.data} />
            {!AuthService.isReadOnlyUser() &&
                <MenuOptions entityType={entityType} disableAdd={disableAdd} hideAdd={hideAdd} dialogControl={dialogControl} uuid={entity.uuid}></MenuOptions>
            }
        </div>
    )
}

export default EntityInfo