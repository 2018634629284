import React from 'react';
import { Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Dialog } from 'components/shared/Dialog'
import { guideToSearch } from 'constants/constantVariables';
import strings from 'strings';

export function Guide({ open, onClose }) {
    return (
        <Dialog maxWidth='sm' open={open} onClose={() => onClose(false)} fullWidth>
            <DialogTitle>
                {strings.APPLICANT.UCC.UCC_HELP}
            </DialogTitle>
            <DialogContent>
                <div className='heading-style-2'>
                    {guideToSearch.map((text, i) => (
                        <div className={i ? 'mt-3' : ''} key={text}>{text}</div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => onClose(false)}>{strings.CLOSE}</Button>
            </DialogActions>
        </Dialog>
    )
}