import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  Tooltip,
} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import DateTime from 'react-datetime'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import variables from 'tools/variables'
import { debounce, nearestPastMinutes } from 'tools/utils'
import * as validators from 'tools/validators'
import ValidationError from 'components/forms/ValidationError'
import strings from 'strings'
// Yeah, they didn't add styles to datetime's entry file <facepalm>
import styles from './DateTimePicker.module.scss'

class DateTimePickerModule extends React.Component {
  constructor(props) {
    super(props)
    const debounceTime = 125
    let selectedDate = props.selected
    this.temporaryDate = null

    if (!props.allowEmpty) {
      selectedDate = this.isValidDateFormat(props.selected)
        ? props.selected
        : moment()
    }

    this.state = {
      selectedDate,
      error: false,
      datePickerKey: 0
    }

    this.debouncedInputChange = debounce(temporaryDate => {
      this.temporaryDate = temporaryDate
    }, debounceTime)

    this.debouncedBlur = debounce(date => {
      this.onBlur(date)
    }, debounceTime)
  }

  componentDidMount() {
    this.setState({ error: !this.isDateValid(this.props.selected) })
  }

  componentDidUpdate(prevProps, prevState) {
    const { selected } = this.props

    if (
      selected &&
      this.isValidDateFormat(selected) &&
      !this.areDatesEqual(prevState.selectedDate, selected)
    ) {
      this.setState({ selectedDate: selected })
    }

    if (prevState.selectedDate !== selected && selected === null) {
      this.setState({ selectedDate: null })
    }
  }

  onBlur(inputDate) {
    const { selectedDate } = this.state
    const { minuteInterval } = this.props
    let date = this.temporaryDate || inputDate

    if (!date) {
      date = null
    }
    const formattedDateTime = moment(date).format(variables.timeFormat)
    // NOTE: valid times (when using the dt picker as time only) were triggering first condition below, so the second was added to check validity
    // (valid dates are also valid as formatted time format)
    if (
      !this.isValidDateFormat(date) &&
      !this.isValidDateFormat(formattedDateTime)
    ) {
      date = selectedDate
    }

    if (date && minuteInterval) {
      date = nearestPastMinutes(minuteInterval, date)
    }

    this.setState(
      {
        datePickerKey: Math.random(),
        selectedDate: date,
        error: !this.isDateValid(date)
      },
      () => {
        if (!this.areDatesEqual(selectedDate, date)) {
          this.props.onChange(date)
        }
      }
    )
  }

  /**
   * Checks if provided dates are equal
   *
   * @param {String} date Date
   * @param {String} ref Reference date
   */
  areDatesEqual(date, ref) {
    return moment(date).isSame(ref)
  }

  /**
   * Checks if provided string is a valid date format
   *
   * @param {String} date Date
   */
  isValidDateFormat(date) {
    const { dateFormat, timeFormat, dateTimeFormat, allowEmpty } = this.props

    if (!date && allowEmpty) {
      return true
    }

    const dateDefinition = date && (date._i || date)
    const formatDate = moment(dateDefinition).format(dateFormat)
    const timeFormatDate = moment(dateDefinition).format(timeFormat)

    if (timeFormat) {
      if (dateFormat) {
        return moment(formatDate, dateTimeFormat, true).isValid()
      }

      return moment(timeFormatDate, timeFormat, true).isValid()
    }

    return moment(formatDate, dateFormat, true).isValid()
  }

  /**
   * Checks if selected date is valid
   *
   * @param {String} value Date
   */
  isDateValid(value) {
    if (this.props.validators && !this.props.disabled) {
      const inputValidators = Object.keys(this.props.validators)

      for (let i = 0; i < inputValidators.length; i++) {
        const currentValidator = inputValidators[i]

        if (
          !validators[currentValidator].call(
            this,
            value,
            this.props.validators[currentValidator]
          )
        ) {
          return false
        }

        continue
      }
    }

    return true
  }

  /**
   * Formats date to provided format
   *
   * @param {String|Object} date Date
   */
  formatDate(date = moment()) {
    const { dateFormat, timeFormat, dateTimeFormat } = this.props

    if (timeFormat) {
      if (dateFormat) {
        return moment(date, dateTimeFormat).format(dateTimeFormat)
      }

      return moment(date, timeFormat).format(timeFormat)
    }
    return moment(date, dateFormat).format(dateFormat)
  }

  /**
   * Sets `temporaryDate` and calls `onChange` callback
   *
   * @param {String} date Date to parse
   */
  onChange(date) {
    this.debouncedInputChange(date)
  }

  render() {
    const { datePickerKey, selectedDate, error } = this.state
    const {
      className,
      timeFormat,
      dateFormat,
      placeholder,
      disabled,
      label,
      onFocus,
      defaultValue,
      inputProps,
      minuteInterval,
      hideOnDisable,
      readOnly,
      tooltip,
      isFilter,
      size,
      position = 'bottom',
      labelClass,
      showCalendarIcon,
      required,
      description,
      hideValidationText
    } = this.props
    const dateValue = selectedDate && this.formatDate(selectedDate)
    const dateTime = (
      <div
        className={`${isFilter ? styles.dateFilter : ''} ${
          size ? styles[size] : ''
        } ${styles.dateWrapper}`}
      >
        <DateTime
          key={`date-picker-${datePickerKey}`}
          value={disabled && hideOnDisable ? undefined : dateValue}
          defaultValue={defaultValue}
          onChange={date => this.onChange(date)}
          onBlur={date => this.debouncedBlur(date)}
          closeOnSelect={true}
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          timeConstraints={
            minuteInterval && { minutes: { step: minuteInterval } }
          }
          strictParsing={false}
          inputProps={{
            placeholder: placeholder,
            onFocus: event => onFocus(event),
            disabled: disabled,
            readOnly: readOnly,
            ...inputProps
          }}
          disabled={disabled}
          className={styles[position]}
        />
        {showCalendarIcon && (
          <div className={styles.calendarIcon}>
            <Icon>event</Icon>
          </div>
        )}
      </div>
    )
    return (
      // date-time-picker does not allow to specify an 'error' property
      <FormControl
      className={`${styles.formInputGroup} 
      ${className ? className : ''}`}
      disabled={disabled}
      readOnly={readOnly}
      error={!!error}
      required={required}
    >
      {/* <div
        className={`${styles.dateTimePicker} ${error ? 'error' : ''}
        ${className ? className : ''} ${styles.formInputGroup}`}
      > */}
        {label && (
          <label className={`${styles.label} ${labelClass || ''}`}>
            {label}
          </label>
        )}
        {tooltip.title ? (
          <Tooltip
            title={tooltip.title}
            placement={tooltip.placement}
            enterDelay={variables.tooltipDelayMs}
            disableFocusListener
            disableTouchListener
          >
            {dateTime}
          </Tooltip>
        ) : (
          dateTime
        )}
      {!error && description && (
        <p className={styles.description}>{description}</p>
      )}
      {!hideValidationText && <ValidationError error={error} />}
    </FormControl>
    )
  }
}

DateTimePickerModule.defaultProps = {
  selected: moment(),
  defaultValue: '',
  allowEmpty: false,
  placeholder: strings.SELECT,
  timeFormat: false,
  dateFormat: variables.dateFormat,
  disabled: false,
  readOnly: false,
  className: '',
  label: null,
  validators: null,
  onFocus: () => {},
  tooltip: {},
  dateTimeFormat: variables.datePickerTimeFormat
}

DateTimePickerModule.propTypes = {
  className: PropTypes.string,
  onFocus: PropTypes.func,
  inputProps: PropTypes.object,
  minuteInterval: PropTypes.number,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  hideOnDisable: PropTypes.bool,
  label: PropTypes.string,
  allowEmpty: PropTypes.bool,
  defaultValue: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  timeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  validators: PropTypes.object,
  dateTimeFormat: PropTypes.string,
  tooltip: PropTypes.object,
  isFilter: PropTypes.bool,
  size: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom']),
  labelClass: PropTypes.string,
  showCalendarIcon: PropTypes.bool
}

export default DateTimePickerModule
