import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next'
import ToolkitProvider from 'react-bootstrap-table2-toolkit'
import Pagination from '@material-ui/lab/Pagination'
import PropTypes from 'prop-types'
import {
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core'
import './tableStyle.scss'
import emptyIcon from 'styles/images/icons/empty.svg'
import { dispatchProcessing } from 'actions/root'
import Loader from '../Loader/index'

const pageLimitOptions = [
    { label: `Showing 5 Records`, value: 5 },
    { label: 'Showing 10 Records', value: 10 },
    { label: 'Showing 20 Records', value: 20 },
    { label: 'Showing 40 Records', value: 40 },
    { label: 'Showing 50 Records', value: 50 }
]

const NoDataIndication = () => (
    <div>
        <img src={emptyIcon} alt='empty' />
        <p>No Data Available</p>
    </div>
)

const TableComponent = (props) => {
    const {
        data,
        columns,
        sort,
        onRowHover,
        loading,
        isLoading,
        showPagination = true,
        showLimitOptions = true,
        keyField = 'uuid',
        selectRow,
        rowStyle,
        onChangePagination,
        currentPage,
        totalRecords,
        totalPages,
        className,
        paginationLimit,
        useLocalPagination,
        remoteSorting = false
    } = props
    const presentPage = Number(currentPage)
    const [pagination, setPagination] = useState({
        limit: paginationLimit || 10,
        page: 0,
        totalPages,
        totalRecords
    })
    const { page, limit } = pagination
    const [paginatedData, setPaginatedData] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        if (useLocalPagination) {
            const temp = [], max = data.length;
            let index = 0
            if (page) index = (page + 1) * limit - limit;

            for (; index < (page + 1) * limit; index++) {
                if (data[index])
                    temp.push(data[index])

                if (index === max)
                    break;
            }
            setPaginatedData(temp)
            setPagination(prev => ({
                ...prev,
                totalPages: Math.ceil(max / limit),
                totalRecords: max,
            }))
        }
    }, [page, limit, data, useLocalPagination])

    useEffect(() => {
        if (presentPage >= 0)
            setPagination(prev => ({ ...prev, page: presentPage }))
    }, [presentPage]);

    useEffect(() => {
        if (typeof loading === 'boolean') {
            dispatch(dispatchProcessing(loading))
        }
    }, [loading, dispatch])

    const handlePagination = (e, value) => {
        let paginationParam = { ...pagination }
        if (e.target.value) {
            paginationParam.limit = e.target.value
            paginationParam.page = 0
        } else {
            paginationParam.page = value - 1
        }
        setPagination({
            ...paginationParam
        })

        if (!useLocalPagination)
            onChangePagination(paginationParam)
    }

    const handleSort = (field, order) => {
        let paginationParam = { ...pagination }
        paginationParam.orderBy = { field, order }
        setPagination({
            ...paginationParam
        })
        if (!useLocalPagination)
            onChangePagination(paginationParam)
    }

    const rowEvents = {
        onMouseEnter: (e, row, rowIndex) => {
            onRowHover(rowIndex)
        },
        onMouseLeave: () => {
            onRowHover(null)
        }
    }

    if (sort) {
        columns.forEach((item, index) => {
            if (item?.sort === false) {
                return
            }
            item['sort'] = true
            item['onSort'] = (field, order) => {
                handleSort(field, order)
            }
        })
    }

    return (
        <div className={`global-table ${className}`}>
            {isLoading ?
                <div className='emptyTable' ><Loader /></div>
                :
                <ToolkitProvider
                    bootstrap4
                    keyField={keyField}
                    data={useLocalPagination ? paginatedData : data}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <BootstrapTable
                                {...props.baseProps}
                                rowEvents={rowEvents}
                                noDataIndication={() => <NoDataIndication />}
                                selectRow={selectRow}
                                rowStyle={rowStyle}
                                remote={{ sort: remoteSorting }}
                                onTableChange={(type, { sortField, sortOrder, data }) => {}}
                            />
                        )
                    }
                </ToolkitProvider>
            }
            {
                showPagination && data?.length ? (
                    <div className={`pagination-container`}>
                        <div className={`pagination-limit`}>
                            {showLimitOptions && (
                                <>
                                    <div>
                                        <FormControl variant='outlined'>
                                            <Select
                                                className={'page-limit-dropdwon'}
                                                onChange={handlePagination}
                                                value={pagination.limit}
                                                variant='outlined'
                                            >
                                                {
                                                    pageLimitOptions.map((ele, index) => {
                                                        return <MenuItem key={index} value={ele.value}>{ele.label}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <p className='total-records'>{useLocalPagination ? pagination.totalRecords : totalRecords} Total Records</p>
                                </>)
                            }
                        </div>
                        <div className={`pagination-count`}>
                            <Pagination
                                count={Number(useLocalPagination ? pagination.totalPages : totalPages)}
                                onChange={handlePagination}
                                color="primary"
                                page={pagination.page + 1}
                            />
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

TableComponent.defaultProps = {
    totalPages: 1,
    showCheckbox: false,
    onRowHover: () => { }
}

TableComponent.propTypes = {
    totalPages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showCheckbox: PropTypes.bool,
    onRowHover: PropTypes.func
}

export default TableComponent
