import React, { useCallback, useEffect, useState } from "react"
import { Button, CircularProgress } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import clsx from "clsx"
import { Toast } from "components/shared/Toaster"
import InputFieldDetailed from "components/forms/Input"
import { PasswordValidator } from "components/PasswordValidation/PasswordValidator"
import strings from "strings"
import circles from "styles/images/circles.svg"
import logo from "styles/images/logo.svg"
import logo1 from "styles/images/menuIcons/logo.svg"
import Auth from 'tools/AuthService'
import request from "tools/request"
import "./LoginPage.css"
const t = strings.LOGIN_STRING

function LoginPage(props) {
  const { onLogIn, match, history } = props
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loggingIn, setLoggingIn] = useState("login")
  const [view, setView] = useState("login")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})
  const [openTooltip, setOpenTooltip] = useState(false)
  const [valid, setValid] = useState(false)
  const tokenId = match?.params?.tokenId

  const redirectToLogin = useCallback(() => {
    setView("login");
    setLoggingIn("login");
    setPassword("");
    history.push("/login")
  }, [history])

  useEffect(() => {
    if (tokenId) {
      setLoading(true)
      setView("reset password")
      setLoggingIn("reset password")
      request(`user/check-token/${tokenId}`, 'GET').then((res) => {
        if (res.tokenValid === false) {
          Toast({
            type: "error",
            message: "Token Expired"
          })
          redirectToLogin();
        }
      }).catch(e => {
        Toast({
          type: "error",
          message: "Invalid Token"
        })
        redirectToLogin();
      }).finally(() => setLoading(false))
    }

  }, [tokenId, match, redirectToLogin])

  // useCallback(() => redirectToLogin)

  const handleSubmit = (e) => {
    e.preventDefault()
    if (view === "login") {
      // stop here if form is invalid
      setLoggingIn("logging in...")
      if (!(email && password)) {
        setLoggingIn("login")
        Toast({
          type: strings.ERROR,
          message: t.FORM_ERR_MSG
        })
        return
      }
      Auth.login(email, password).then(() => {
        onLogIn()
      }).catch(() => {
        setLoggingIn("login")
        Toast({
          type: strings.ERROR,
          message: t.API_ERR_MSG
        })
      })
    }
    else if (view === "forgot password") {
      setLoading(true)
      request(`user/forgot-password`, 'POST', { email }).then((res) => {
        Toast({
          type: "success",
          message: res.responseMessage
        })
        redirectToLogin();
      }).finally(() => setLoading(false))
    }
    else {
      if (!validate()) {
        setLoading(true)
        const payload = { password, confirmPassword, token: tokenId }
        if (valid)
          request('user/recover-password', 'POST', payload).then((res) => {
            Toast({
              type: "success",
              message: res.message
            })
            redirectToLogin();
          }).catch((e) => {
            console.log("error", e);
          }).finally(() => setLoading(false))
      }
    }
  }

  const validate = () => {
    let isError = false
    const errors = {}

    if (password.trim().length < 8) {
      errors.password = "required minimum 8 characters"
      isError = true
    }
    if (password.trim().length > 20) {
      errors.password = "maximum 20 characters"
      isError = true
    }
    if (confirmPassword !== password) {
      errors.confirmPassword = "password does not match"
      isError = true
    }

    setValidationErrors(errors)
    return isError
  }

  const handleView = () => {
    if (view === "login") {
      setView("forgot password")
      setLoggingIn("Send Email")
    }
    else if (view === "forgot password") {
      setView("login")
      setLoggingIn("login")
    }
  }

  const passwordField = (
    <InputFieldDetailed
      fullWidth
      name="password"
      label={view === "reset password" ? "New Password" : "Password"}
      type="password"
      id="password"
      autoComplete="current-password"
      className="mt-4"
      value={password}
      variant="outlined"
      size="small"
      onChange={(e) => setPassword(e.target.value)}
      disabled={loading}
      error={validationErrors.password}
      onFocus={() => setOpenTooltip(true)}
      onBlur={() => setOpenTooltip(false)}
    />
  )

  return (
    <div className="login-wrapper">
      <Grid container>
        <Grid item xs={false} sm={6} md={6} lg={6} className="d-none d-sm-block">
          <div className="gradient-background">
            <img className="img1" src={circles} alt="Logo Circles" />
            <img className="img2" src={logo} alt="ROX" />
            <span className="ew">Efficient Underwriting</span>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ zIndex: 1 }}>
          <form name="form" onSubmit={handleSubmit} className="login-container">
            <div className="w420">
              <img src={logo1} style={{ width: '130px' }} alt="rox logo" className="position-relative" />

              {view === "login" || view === "forgot password" ?
                <InputFieldDetailed
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  className="mt-4"
                  value={email}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setEmail(e.target.value)}
                /> : null}

              {view === "login" && passwordField}

              {view === "reset password" &&
                <PasswordValidator password={password} isValid={setValid} open={openTooltip} >
                  {passwordField}
                </PasswordValidator>}


              {view === "reset password" &&
                <InputFieldDetailed
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  className="mt-4"
                  value={confirmPassword}
                  variant="outlined"
                  size="small"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  disabled={loading}
                  error={validationErrors.confirmPassword}
                />}
              {view === "forgot password" && <div className="msg">{t.HINT}</div>}

              {view === "login" || view === "forgot password" ?
                <div className={clsx(view === "login" ? "text-right mt-2" : "mt-3", "color-blue", "d-inline-block")}
                  role="button"
                  onClick={handleView}
                >
                  {view === "login" ? t.FORGOT_PASSWORD : t.BACK_TO_LOGIN}
                </div> : null}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className="mt-4"
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={25} color="inherit" /> : loggingIn}
              </Button>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default LoginPage
