import React, { forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import 'App.scss'

const materialStyles = ({ arrowColor, tooltipProps }) =>
  makeStyles(theme => {
    return ({
      customWidth: {
        color: 'white',
        background: 'black',
        borderRadius: '6px',
        fontSize: '10px',
        fontWeight: 500,
        ...(tooltipProps ? tooltipProps : {})
      },
      arrow: {
        fontSize: 20,
        color: arrowColor || 'black',
        '&::before': {
          backgroundColor: arrowColor || 'black'
        }
      },
      sparkClass: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(1)
        }
      }
    })
  })

const MyComponent = forwardRef((props, ref) => {
  return <div {...props} ref={ref}>{props.children}</div>
});

const PrepareTooltip = (props) => {
  let {
    title = '',
    children,
    placement = 'bottom',
    arrowProps,
    tooltipProps,
    open,
    theme = 'black',
  } = props;

  if (theme === 'white') {
    arrowProps = { color: 'white' }
    tooltipProps = {
      boxShadow: '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
      filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08))',
      background: 'white',
      color: 'black',
      fontSize: 14
    }
  }

  const color = arrowProps?.color || null, visible = arrowProps?.visible || true
  const classes = materialStyles({ arrowColor: color, tooltipProps })()

  return (
    <Tooltip
      title={title}
      className={'tooltip-body'}
      placement={placement}
      classes={{ arrow: classes.arrow, tooltip: classes.customWidth }}
      arrow={visible}
      open={open}
    >
      <MyComponent>{children}</MyComponent>
    </Tooltip>
  )
}

PrepareTooltip.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  placement: PropTypes.string,
  arrowProps: PropTypes.shape({ color: PropTypes.string, visible: PropTypes.bool }),
  theme: PropTypes.oneOf(['black', 'white']),
  /**
   * All css properties can be sent here
   */
  tooltipProps: PropTypes.object
}

export default PrepareTooltip