import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoComplete } from "components/forms/Autocomplete";
import { required as requiredValidation } from "tools/forms/validators";
import { useGetErrorMessage } from 'components/forms/reduxForm'


const RenderAutocompleteField = (props) => {
    const {
        input,
        meta,
        ...rest
    } = props
    const error = useGetErrorMessage(meta)
    return (
        <AutoComplete name={input.name} error={error} {...rest} {...input} />
    )
};

const AutoCompleteField = (props) => {
    const {
        name, // acts like name of the input field.
        type, // to show the type of a field.
        label, // to show a label for a input field.
        value, // acts like a value for a input field.
        required,
        validate = [],
        ...rest // list of all values.
    } = props;

    if (required && !validate.some((v) => v === requiredValidation)) {
        validate.unshift(requiredValidation);
    }

    return (
        <Field
            name={name}
            required={required}
            component={RenderAutocompleteField}
            label={label}
            value={value}
            validate={validate}
            {...rest}
        />
    );
};

AutoCompleteField.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string || PropTypes.object,
}

export default AutoCompleteField;
