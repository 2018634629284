import React from "react";
import { Row, Col } from "react-bootstrap";
import Chart from "react-apexcharts";
import { verificationRadialChart } from 'constants/chartData';
import './VerificationSummary.scss'

const VerificationSummary = ({ info }) => {

    const data = info.data
    const dataLists = info.dataLists[0]

    return (
        <Row>
            <Col md={3}>
                <Chart
                    type='radialBar'
                    height='210px'
                    options={verificationRadialChart(data.risk, data.riskScore)}
                    series={[data.riskScore]}
                />
            </Col>
            <Col md={9}>
                <p className='verificationField mb-0'>{dataLists?.field}</p>
                {dataLists?.values.map((item, index) => {
                    return (
                        <p className='verificationValue' key={index}>{item}</p>
                    )
                }
                )}
            </Col>
        </Row>
    );
}

export default VerificationSummary;