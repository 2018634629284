const variable = {
  backendDateFormat: 'YYYYMMDD',
  backendRequestFormat: 'YYYY-MM-DD',
  dateFormat: 'MM/DD/YYYY',
  timeFormat: 'hh:mm A',
  dateTimeFormat: 'MM/DD/YYYY (hh:mm a)',
  datePickerTimeFormat: 'MM/DD/YYYY h:mm a',
  foreverDate: '12-31-9999',
  startOfTime: '01/01/0000',
  startOfTimeBackEndFormat: '0000000',
  timezone: 'America/New_York', //NOTE: change this to convert all "createdAt" times across application
  rangeMinLimit: 0,
  rangeMaxLimit: 999,
  tooltipDelayMs: 750,
  autoRefreshIntervalMs: 30000,
}

export default variable
