import React from 'react'
import { Drawer as MaterialDrawer, AppBar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types'
import styles from './drawer.module.scss'

const Drawer = (props) => {
    const {
        open,
        onClose,
        anchor,
        children,
        title,
        actions,
        width = 'large',
        drawerClass,
        rightSideCloseIcon
    } = props
    const size = width === 'small' ? '600px' : width === 'medium' ? '750px' : '900px'

    return (
        <MaterialDrawer
            anchor={anchor}
            open={open}
            onClose={onClose}
        >
            <div className={rightSideCloseIcon ? styles.drawerTitleRightButton : styles.drawerTitle}>
                {!rightSideCloseIcon && <CloseIcon onClick={onClose}/>}
                {
                    actions ? <div className={drawerClass || 'd-flex justify-content-between w-100 align-items-center'}>
                        <h4 className='mb-0'>{title}</h4>
                        {actions}
                    </div> : typeof title === 'string' ? <h4 className='mb-0'>{title}</h4> : title
                }
                {rightSideCloseIcon && <CloseIcon onClick={onClose}/>}
            </div>
            <div className='h-100' style={{width: size}}>
                {children}
            </div>
        </MaterialDrawer>
)
}

export const DrawerAction = (props) => {
    const { className = '', position, color = 'inherit', children } = props
    return (
        <AppBar
            className={`${styles.defaultActionStyle} ${className}`}
            position={position}
            color={color}
        >
            {children}
        </AppBar>
    )
}

DrawerAction.propTypes = {
    className: PropTypes.string,
    position: PropTypes.oneOf(['absolute', 'fixed', 'relative', 'static', 'sticky']),
}

DrawerAction.defaultProps = {
    position: 'fixed',
}

Drawer.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    anchor: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    width: PropTypes.oneOf(['small', 'medium', 'large']),
    drawerClass: PropTypes.string
}

Drawer.defaultProps = {
    open: false,
    onClose: () => { },
    anchor: 'right',
    title: ''
}

export default Drawer
