import React from 'react'
import TableComponent from 'components/shared/TableComponent'

const UccContentCard = ({ tableData, column }) => {
    return (
            <TableComponent
                keyField='FilingNumber'
                columns={column}
                data={tableData || []}
                showPagination={false}
            />
    )
}

export default UccContentCard