import request from 'tools/request'
import Auth from 'tools/AuthService'
export const SET_USER = 'SET_USER'
export const SET_SHOW_NOTIFICATION = "SET_SHOW_NOTIFICATION"
export const SET_SHOW_PROCESSING = "SET_SHOW_PROCESSING"
export const SET_USER_LIST = 'SET_USER_LIST'
export const SET_TEMPLATES_LIST = 'SET_TEMPLATES_LIST'
export const SET_SALES_REPRESENTATIVE_LIST = 'SET_SALES_REPRESENTATIVE_LIST'
export const SET_ROLES_LIST = 'SET_ROLES_LIST'

export const dispatchUserDetails = (data) => ({
    type: SET_USER,
    user: data,
})

export const dispatchUserList = (data) => ({
    type: SET_USER_LIST,
    data: data
})

export const dispatchrolesList = (data) => ({
    type: SET_ROLES_LIST,
    data
})

export const dispatchsalesRepresentativesList = (data, headers) => ({
    type: SET_SALES_REPRESENTATIVE_LIST,
    data,
    headers
})

export const dispatchTemplateList = (data, headers) => ({
    type: SET_TEMPLATES_LIST,
    data,
    headers
})

export const dispatchNotification = (title, content, success = undefined) => {
    let showNotification = {
        show: true,
        title: title,
        content: content,
        success: success
    }
    if (title === 'error') {
        showNotification.title = "Something went wrong!"
        showNotification.content = "Error Server Response. Please try again later."
        showNotification.success = false
    }
    return {
        type: SET_SHOW_NOTIFICATION,
        showNotification: showNotification,
    }
}

export const dispatchProcessing = (show) => ({
    type: SET_SHOW_PROCESSING,
    showProcessing: {
        show: show,
        title: 'Processing...'
    }
})

// new user me api to get user details
export const getUserDetailByToken = () => async (dispatch) => {
    const res = await request(
        'user/me',
        'GET',
        null,
        true,
        {}
    )
    Auth.selectedClient = res.response?.securityContext?.client?.clientId
    localStorage.setItem('user', JSON.stringify(res.response))
    dispatch(dispatchUserDetails(res.response))
    return res.response
}

export const getRolesList = () => async (dispatch) => {
    const res = await request('roles', 'GET')
    dispatch(dispatchrolesList(res))
    return res
}

export const createSalesRepresentatives = (payLoad) => async () => {
    const res = await request('salesRepresentatives', 'POST', payLoad)
    return res
}

export const updateSalesRepresentatives = (payLoad, id) => async () => {
    const res = await request(`salesRepresentatives/${id}`, 'PATCH', payLoad)
    return res
}

export const deleteSalesRepresentatives = (id) => async () => {
    const res = await request(`salesRepresentatives/${id}`, 'DELETE')
    return res
}

export const getSalesRepresentatives = (pagination = {}, getAll) => async (dispatch) => {
    let res;
    if (getAll) {
        res = await request(`salesRepresentatives`, "GET")
        dispatch(dispatchsalesRepresentativesList(res, {}))
    }
    else {
        res = await request(
            `salesRepresentatives`,
            "GET",
            null,
            true,
            {
                'x-pagination-num': pagination.page || 0,
                'x-pagination-limit': pagination.limit || 10,
                'x-client-Id': Auth.selectedClient
            })
        dispatch(dispatchsalesRepresentativesList(res.response, res.headers))
    }
    return res
}

export const getTemplates = (pagination = {}, clientID) => async (dispatch) => {
    const res = await request(
        `templates`,
        "GET",
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10,
            'x-client-Id': clientID || Auth.selectedClient
        })
    dispatch(dispatchTemplateList(res.response, res.headers))
    return res
}

export const createTemplate = (payLoad) => async () => {
    const res = await request(`templates`, "POST", payLoad)
    return res
}

export const updateTemplate = (payLoad) => async () => {
    const res = await request(`templates`, "PATCH", payLoad)
    return res
}
