import React from 'react'
import PropTypes from 'prop-types'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialCheckbox from '@material-ui/core/Checkbox'

import styles from './Checkbox.module.scss'

const Checkbox = ({
  label,
  checked = false,
  onChange,
  className,
  labelClassName,
  isRelativePosition,
  disabled = false,
  name,
  register = (_) => {}
}) => {
  return (
    <div
      className={`
      ${isRelativePosition ? styles.relative : ''} ${className || ''}`}
    >
      <FormControlLabel
        className={`${styles.label} ${labelClassName || ''} ${styles.centeredCheckbox}`}
        label={label}
        control={
          <MaterialCheckbox
            {...register(name)}
            name={name}
            checked={checked}
            onChange={e => (onChange ? onChange(e.target.checked) : null)}
            color="primary"
            disabled={disabled}
          />
        }
      />
    </div>
  )
}
Checkbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  isRelativePosition: PropTypes.bool // when the checkbox is beside form inputs
}

export default Checkbox
