import React, { useState } from "react";
import moment from 'moment'
import { PDFDownloadLink, BlobProvider, Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import Button from 'components/shared/Button'
import GetAppIcon from '@material-ui/icons/GetApp';
import { Toast } from "components/shared/Toaster"
import { mapFmcsaData } from 'constants/fmcsa'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import strings from "strings";

const styles = StyleSheet.create({
    page: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 30
    },
    container: {
        backgroundColor: 'white',
    },
    imageContainer: {
        width: 100,
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headLine3: {
        fontSize: 24,
        marginBottom: 5,
        textAlign: 'end',
        fontFamily: 'Helvetica'
    },
    headerLine5: {
        fontSize: 18,
        marginBottom: 5,
        fontFamily: 'Helvetica'
    },
    headerLine6: {
        fontSize: 16,
        fontFamily: 'Helvetica'
    },
    borderLineContainer: {
        fontSize: 18,
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        fontFamily: 'Helvetica'
    },
    section1: {
        marginTop: 16,
        marginBottom: 16
    },
    resultText: {
        fontSize: 10,
        marginTop: 16,
        fontFamily: 'Helvetica'
    },

    // font sizes
    fs10: {
        fontSize: 10,
        fontFamily: 'Helvetica'
    },
    fs12: {
        fontSize: 13,
        fontWeight: 300,
        fontFamily: 'Helvetica'
    },
    fs14: {
        fontSize: 14,
        fontFamily: 'Helvetica'
    },

    //width
    w150: {
        width: 150
    },

    // bootstrap classes
    mb5: {
        marginBottom: 5 * 3
    },
    mt5: {
        marginTop: 5 * 3,
    },
    mt2: {
        marginTop: 2 * 3
    },
    ml6: {
        marginLeft: 6 * 3
    },
    mr1: {
        marginRight: 6
    },
    mt1: {
        marginTop: 10
    },
    mr4: {
        marginRight: 4 * 3
    },
    dFlex: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    dFlexStart: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    boldFont: {
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'Helvetica'
    },
    label: {
        width: 200,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    addressContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    textCenter: {
        textAlign: 'center'
    },
    textEnd: {
        textAlign: 'end'
    },
    endReportMargin: {
        marginTop: 60,
        marginBottom: 45
    }
});

const ComponentToPrint = (props) => {
    const { data } = props || {}
    const searchDate = moment().format('MM-DD-YYYY')
    const retrievalDate = moment(data.retrievalDate).format('MM-DD-YYYY');
    return (
        <Document>
            <Page size="A4" style={styles.page} title="rox write ucc search report">
                <View>
                    <View style={styles.container}>
                        <Text style={[styles.fs10, styles.textEnd, styles.mb5]}>Report Generation Date: {searchDate}</Text>
                        <Text style={[styles.fs10, styles.textEnd, styles.mb5]}>Data Retrieval Date: {retrievalDate}</Text>
                        <View style={styles.headerContainer}>
                            <View>
                                <Image style={styles.imageContainer} src={`${window.location.origin}/roxLogo.jpg`} />
                            </View>
                            <View>
                                <Text style={styles.headerLine3}>{strings.FMCSA_REPORT}</Text>
                            </View>
                        </View>
                        <View style={styles.bodyContainer}>
                            <View style={styles.section1}>
                                <Text style={styles.borderLineContainer}>Results</Text>
                            </View>
                            <View>
                                <View style={[styles.mb5, styles.mt5]}>
                                    {mapFmcsaData(data).map((d, index) => (
                                        <View style={[styles.dFlex, styles.mt1]} key={index}>
                                            <View style={styles.label}>
                                                <Text style={[styles.boldFont, styles.mr1]}>{d.title}</Text>
                                            </View>
                                            <Text style={styles.fs12}>{d.value}</Text>
                                        </View>
                                    ))
                                    }
                                </View>
                            </View>
                            <Text style={[styles.endReportMargin, styles.textCenter, styles.fs14]}>
                                ---------- END OF REPORT ----------
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}


const FmcsaReport = (props) => {

    //props
    const { data, mode, onSubmit, applicationId} = props

    //consts
    const [attachLoader, setAttachLoader] = useState(false)

    //functions
    const handleAttachment = (blob) => {
        setAttachLoader(true)

        const fd = new FormData();
        fd.append("file", blob, `fmcsa-report-${data.dotNumber}.pdf`)
        fd.append("purpose", `fmcsa-report-${data.dotNumber}`)
        fd.append("roxFileType", "USER_FILE")
        fd.append("applicationId", applicationId)

        onSubmit(fd).then((res) => {
            Toast({
                type: 'success',
                icon: false,
                autoClose: false,
                message: (
                    <div className='d-flex'>
                        <CheckCircleIcon htmlColor='green' />
                        <div className='ml-2'>
                            <div className='heading-style-2'>{strings.SUCCESS}</div>
                            <div className='mt-1'>{strings.REPORT_SUCCESS_MSG}</div>
                            <PDFDownloadLink document={<ComponentToPrint data={data} />} fileName={`fmcsa-report-${data.dotNumber}.pdf`}>
                            <div className='download-file mt-3'>
                                    {strings.DOWNLOAD_REPORT}
                                </div>
                            </PDFDownloadLink>
                        </div>
                    </div>
                )
            })
        }).finally(() => {
            setAttachLoader(false)
        })
    }

    //return
    return (
        <BlobProvider document={<ComponentToPrint data={data} />} fileName={`fmcsa-report-${data.dotNumber}.pdf`}>
            {({ blob, url, loading, error }) => {
                if (mode === 'button') {
                    return (
                        <Button loading={loading || attachLoader} disabled={!blob} onClick={() => handleAttachment(blob)}>{strings.DOWNLOAD_REPORT}</Button>
                    )
                } else {
                    return (
                        <GetAppIcon className='mt-3p ml-3' color='primary' onClick={() => handleAttachment(blob)} />
                    )
                }
            }}
        </BlobProvider>
    );
}

export default FmcsaReport;
