import React, { useContext, useEffect, useRef, useState } from 'react'
import strings from 'strings'
import { cloneDeep } from 'lodash'
import { DownloadUccEmptyReport } from 'components/PdfTemplates/index'
import { UccSearchContext } from './UccSearchContent'
import { dispatchUpdatedUccResponse } from 'actions/applicants'
import { useDispatch } from 'react-redux'
import UccAttachedFilingsCard from "../UccAttachedFilingsTab/UccAttachedFilingsCard";
import UccSearchCard from "../UccSearchCard";

const uccStrings = strings.APPLICANT.UCC


const UccSearchResults = ({ results }) => {

    const { applicationId, searchUuid, uccResponse, indexDate } = useContext(UccSearchContext)
    const dispatch = useDispatch()

    const [uccResults, setUccResults] = useState(results)
    const availableFilings = uccResults.filter(filing => !filing.Saved)
    const attachedFilings = uccResults.filter(filing => filing.Saved)

    const uccResultRef = useRef()
    uccResultRef.current = uccResults

    useEffect(() => {
        // when UccSearchResults is unmounted, update the ucc response with the updated results
        return () => {
            const uccResponseCloned = cloneDeep(uccResponse)
            uccResponseCloned.response.GroupedResults = uccResultRef.current
            dispatchUpdatedUccResponse(uccResponseCloned, dispatch)
        }
        // eslint-disable-next-line
    }, [])

    const handleUpdateUccResults = (updatedSearchResponse) => {
        setUccResults(updatedSearchResponse?.response?.GroupedResults)
    }

    // results change when the user performs a new search
    useEffect(() => {
        setUccResults(results)
    }, [results])

    return (
        <>
            <h5> {uccStrings.ATTACHED_FILINGS} </h5>
            {attachedFilings.length > 0 ?
                attachedFilings.map((filing, index) => {
                    return <UccAttachedFilingsCard
                        filing={filing}
                        key={index}
                        applicationId={applicationId}
                        indexDate={indexDate}
                    />})
                :
                <p> {uccStrings.NO_ATTACHED_FILINGS}
                    <DownloadUccEmptyReport
                        applicationId={applicationId}
                        interactionId={searchUuid}
                        linkText={uccStrings.NO_RESULT_REPORT_LINK}
                    /> </p>
            }

            <h5 className='mt-4'> {uccStrings.SEARCH_RESULTS} </h5>
            {availableFilings.length > 0 ?
                (availableFilings.map((result, index) => (
                    <UccSearchCard
                        key={result?.Group[0]?.FilingNumber}
                        result={result}
                        updateUccResults={handleUpdateUccResults}
                    />
                )))
                :
                <p> {uccStrings.ALL_RESULTS_WERE_ATTACHED} </p>
            }
        </>
    )
}

export default UccSearchResults