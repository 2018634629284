import React, { createContext } from 'react'
import strings from 'strings'
import NoDataIndication from 'components/shared/NoDataIndication/index'
import './UccSearchTab.scss'
import UccSearchNoResults from './UccSearchNoResults'
import { getEntityNameFromUccResponse } from 'constants/utilityFunctions'
import UccSearchResults from './UccSearchResults'
const uccStrings = strings.APPLICANT.UCC

export const UccSearchContext = createContext()

const UccSearchContent = ({ uccResponse, applicationId }) => {

    const results = uccResponse?.response?.GroupedResults
    const indexDate = uccResponse?.response?.Header?.IndexDate
    const searchUuid = uccResponse?.uuid
    const name = getEntityNameFromUccResponse(uccResponse)

    return (
        <div className='mt-3 flex-grow-1'>
            {/* // uccResponse false means no search has been performed */}
            {uccResponse === false ?
                <NoDataIndication message={uccStrings.PERFORM_SEARCH} waitingAction className='vh-60' />
                :
                results?.length > 0 ?
                    <UccSearchContext.Provider value={{ applicationId, indexDate, searchUuid, uccResponse }}>
                        <UccSearchResults results={results} />
                    </UccSearchContext.Provider>
                    :
                    <UccSearchNoResults searchUuid={searchUuid} applicationId={applicationId} name={name} indexDate={indexDate} />
            }
        </div>
    )
}

export default UccSearchContent