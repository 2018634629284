import React from 'react';
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { Dialog } from 'components/shared/Dialog';
import strings from 'strings';
import styles from './ConfirmationModal.module.scss';


const ConfirmationModal = (props) => {
    const { open, onClose, onSubmit, isLoading, icon, title, bodyText, subText, confirmText, color} = props

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <div className='text-center'>
                    <img src={icon} alt='icon' />
                    <p className={styles.bodyText}>{bodyText}</p>
                    <p className={styles.subText}>{subText}</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                >
                    <p>
                        {strings.CANCEL}
                    </p>
                </Button>
                <Button
                    variant='contained'
                    color={color}
                    onClick={onSubmit}
                    // color + disabled is not playing right, the disabled class from material ui 
                    //is overwriting with the light-blue color for a primary disabled button and this is not working for the case when you have a secondary disabled button.
                    disabled={isLoading} 
                    >
                    {isLoading ? <CircularProgress size={24} color='inherit' /> :
                    <p className={styles.confirmButtonText}>
                        {confirmText}
                    </p>
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmationModal;