import { produce } from 'immer'
import { SET_CSV_LIST } from 'actions/csvManagemet'

const initialState = {
    csvList: [],
    csvHeaders: {}
}

export const csvManagementReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_CSV_LIST:
                draft.csvList = action.data
                draft.csvHeaders = action.headers
                break;
            default:
                break;
        }
    })
}