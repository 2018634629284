import React, { useEffect, useState } from 'react'
import Drawer from 'components/shared/Drawer'
import strings from 'strings'
import { Tabs, Tab } from 'components/shared/Tabs'
import UccSearchTab  from './UccSearchTab/UccSearchTab'
import UccSearchHistoryTabContent  from './UccSearchTab/UccSearchHistoryTabContent'
import { uccSearchDrawerTabs } from 'constants/constantVariables'
import { useDispatch } from 'react-redux'
import { dispatchUpdatedUccResponse, dispatchUccSearchParameters } from 'actions/applicants'
import UccAuditTab from './UccAuditTab/UccAuditTab'
import UccAttachedFilingsTab from "./UccAttachedFilingsTab/UccAttachedFilingsTab";
const uccStrings = strings.APPLICANT.UCC

const UccSearchDrawer = ({ selectedEntity, applicationId, open, handleOnClose, initialTab }) => {

    const [initialDrawerTabValue, setInitialDrawerTabValue] = useState(initialTab || 0)

    const dispatch = useDispatch()

    useEffect(() => {
        // when the drawer mounts, uccResponse and uccSearchParameters are reset
        dispatchUccSearchParameters(false, dispatch)
        dispatchUpdatedUccResponse(false, dispatch)
        // eslint-disable-next-line
    }, [])

    return (
        <Drawer
            title={uccStrings.UCC_SEARCH}
            onClose={handleOnClose}
            open={open}
        >
            <div className='p-3'>
                <Tabs
                    data={uccSearchDrawerTabs}
                    value={initialDrawerTabValue}
                    onChange={(e, value) => setInitialDrawerTabValue(value)}
                />
                <Tab value={initialDrawerTabValue} index={0}>
                    <UccSearchTab
                        selectedEntity={selectedEntity}
                        applicationId={applicationId}
                    />
                </Tab>
                <Tab value={initialDrawerTabValue} index={1}>
                    <UccAttachedFilingsTab
                        selectedEntity={selectedEntity}
                        applicationId={applicationId}
                    />
                </Tab>
                <Tab value={initialDrawerTabValue} index={2}>
                    <UccSearchHistoryTabContent
                        selectedEntity={selectedEntity}
                        applicationId={applicationId}
                    />
                </Tab>
                <Tab value={initialDrawerTabValue} index={3}>
                    <UccAuditTab
                        selectedEntity={selectedEntity}
                        applicationId={applicationId}
                    />
                </Tab>
            </div>
        </Drawer >
    )
}

export default UccSearchDrawer