import { DEBOUNCE_TIME } from 'constants/debounce'

// export const apiURL = "https://devapi.roxwrite.us";
// export const apiURL = "http://localhost:8080";
export const apiURL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://apidev.roxwrite.us' 

export const defaultHeaders = { 
    'Content-Type': 'application/json',
    'Auth-SystemKey': '1',
    'Auth-ApiKey': '!@#ROXCLAPP!@#',
}

export const matchInArray = (string, expressions) => {
    let len = expressions.length
    let i = 0
  
    for (i; i < len; i++) {
      if (string.match(expressions[i])) {
        return true
      }
    }
  
    return false
}

export const debounce = (fn, time = DEBOUNCE_TIME) => {
  let timeout

  return function() {
    const functionCall = () => fn.apply(this, arguments)

    clearTimeout(timeout)
    timeout = setTimeout(functionCall, time)
  }
}

export const nearestPastMinutes = (interval, someMoment) => {
  if (!someMoment || !someMoment.isValid()) {
    return
  }
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval
  return someMoment
    .clone()
    .minute(roundedMinutes)
    .second(0)
}

export const isScreenSmall = () => {
  return window.innerWidth <= 1600
}