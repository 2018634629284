import React from 'react'
import FmcsaDrawer from './FmcsaDrawer'
import BackgroundReportDrawer from 'components/Applicants/ApplicationDetail/Report/BackgroundReportDrawer'
import VerificationDrawer from "./VerificationDrawer";

const ReportDrawer = (props) => {

    const { onClose, applicantId, info } = props

    const data = info.data || {}
    const entityType = info.entityType || ''
    const reportType = info.reportType || ''

    // Drawers for the different report types

    return (
        <>
            {
                reportType === 'FMCSA' &&
                <FmcsaDrawer
                    open={reportType === 'FMCSA'}
                    onClose={onClose}
                    applicantId={applicantId}
                    data={data}
                />
            }

            {
                reportType === 'LEXIS_BACKGROUND' && entityType === 'OWNER' &&
                <BackgroundReportDrawer
                    open={reportType === 'LEXIS_BACKGROUND' && entityType === 'OWNER'}
                    onClose={onClose}
                    applicationId={applicantId}
                    data={data}
                />
            }

            {
                reportType === 'LEXIS_VERIFICATION' && entityType === 'BUSINESS' &&
                <VerificationDrawer
                    open={reportType === 'LEXIS_VERIFICATION' && entityType === 'BUSINESS'}
                    onClose={onClose}
                    applicationId={applicantId}
                    //Current endpoint returns list with only business report
                    data={data[0]}
                />
            }
        </>
    )
}

export default ReportDrawer