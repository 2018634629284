import React, { useState } from 'react'
import './SummaryHeader.scss'
// Hiding risk score badge from summary right panel
// import { Risk } from 'constants/chartData'
import Button from 'components/shared/Button'
import strings from 'strings'
import { getTabByReportType } from 'constants/constantVariables'
import AuthService from 'tools/AuthService'
import { reportDrawerSupported } from 'constants/utilityFunctions'
import SosSearchDrawer from './Components/SosSearchDrawer'

const summaryStrings = strings.APPLICANT.SUMMARY

const SummaryHeader = (props) => {
    const {
        lastUpdate,
        // Hiding risk score badge from summary right panel
        // risk,
        summaryTabChange,
        showReportDrawer,
        applicantId,
        reportId,
        loading,
        requestDecision,
        reportInfo,
        getDataDisabled
    } = props

    const { title, entityName, status, type, entityId, entityType } = reportInfo || {}
    const [openSearchDrawer, setOpenSearchDrawer] = useState(false)

    // Hiding risk score badge from summary right panel
    // const colorCodes = Risk.find(st => st.value === risk) || {};
    const tabIndex = getTabByReportType(type)

    const reportDrawerOrChangeTab = () => {
        if (type === 'SOS') {
            setOpenSearchDrawer(true)
            return
        }
        if (status?.value === 'FOUND' && reportDrawerSupported(type, entityType)) {
            showReportDrawer(applicantId, entityId, type, entityType)
            return
        }
        summaryTabChange(tabIndex)       
    }

    return (
        <>
            <hr></hr>
            <div className='summaryHeader mb-3'>
                <div>
                    <h5 className='mb-2'> {title} </h5>
                    {(type !== 'FMCSA' && type !== 'SOS') && <p className='mb-0 entityName'> {entityName} </p>}
                </div>
                <div className='summaryHeaderInfo'>
                    {lastUpdate &&
                        <p className='summaryLastUpdate mb-0'> {summaryStrings.LAST_UPDATE} {lastUpdate} </p>
                    }

                    {/*Hiding risk score
                    badge from summary
                    right panel, to
                    revert uncomment
                    the section below*/}

                    {/*{risk &&*/}
                    {/*    <span className='summaryRisk ml-3' style={{*/}
                    {/*        background: colorCodes.code || '#808080FF',*/}
                    {/*        color: colorCodes.textCode || '#ffffff'*/}
                    {/*    }}>{risk}</span>*/}
                    {/*}*/}

                    <Button
                        variant="outlined"
                        color="primary"
                        className='summaryHeaderButton ml-3'
                        onClick={() => reportDrawerOrChangeTab()}
                        loading={loading}
                        id={'view-more-' + reportId}
                    >
                        {summaryStrings.VIEW_MORE}
                    </Button>
                    {!AuthService.isReadOnlyUser() &&
                        <Button
                            variant="outlined"
                            color="primary"
                            className='summaryHeaderButton ml-3'
                            onClick={requestDecision}
                            id={'get-data-' + reportId}
                            loading={loading}
                            disabled={getDataDisabled}
                        >
                            {summaryStrings.GET_DATA}
                        </Button>
                    }
                </div>
            </div>
            <SosSearchDrawer
                open={openSearchDrawer}
                onClose={() => setOpenSearchDrawer(false)}
                requestDecision={requestDecision}
                applicantId={applicantId}
            />
        </>
    )
}

export default SummaryHeader
