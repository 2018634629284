import React from "react";
import * as PropTypes from "prop-types";
import {DropdownField} from "../../../../forms/reduxForm";

class StateOfFormationDropDown extends React.Component {
    render() {
        return <DropdownField
            name={this.props.name}
            isClearable={!this.props.stateOfFormationCheckBox}
            disabled={this.props.disabled}
            label="State of Formation"
            options={this.props.stateOptions[this.props.index]}
            onChange={this.props.onChange}
            required={false}
            isSearchable
            id="StateOfFormation"
        />;
    }
}

StateOfFormationDropDown.propTypes = {
    stateOfFormationCheckBox: PropTypes.bool,
    disabled: PropTypes.bool,
    stateOptions: PropTypes.any,
    index: PropTypes.any,
    onChange: PropTypes.func
};

export default StateOfFormationDropDown;