import React, { useState } from "react";
import Button from 'components/shared/Button'
import { Toast } from "components/shared/Toaster"
import strings from 'strings'
import { attachNoResultsReport, downloadFileByPayload } from 'actions/applicants'
import { generateNoResultsReport } from 'actions/quickSearch'

const uccStrings = strings.APPLICANT.UCC

const UccEmptyReport = (props) => {

    //props
    const {
        applicationId,
        interactionId,
        isQuickSearch,
        buttonText, // To display a button you must pass buttonText
        linkText,
        disabled,
        variant
    } = props

    //consts
    const [attachLoader, setAttachLoader] = useState(false)

    const handleNoResultReports = () => {
        let endpointToGenerateReport, payload, successMessage

        if (isQuickSearch) {
            endpointToGenerateReport = generateNoResultsReport
            payload = { interactionId }
            successMessage = uccStrings.NO_RESULTS_REPORT_GENERATED_SUCCESSFULLY
        } else {
            endpointToGenerateReport = attachNoResultsReport
            payload = { applicationId, payload: { interactionId } }
            successMessage = uccStrings.NO_RESULTS_REPORT_GENERATED_AND_ATTACHED_SUCCESSFULLY
        }

        endpointToGenerateReport(payload)()
            .then((res) => {
                const payload = { fileId: res.uuid, fileName: res.fileName, applicantionId: applicationId }
                Toast(({ type: 'success', message: successMessage + '. ' + strings.DOWNLOAD_WILL_START_SOON }))
                downloadFileByPayload(payload)()
            })
            .catch((err) => Toast(({ type: 'error', message: strings.SOMETHING_WENT_WRONG })))
            .finally(() => setAttachLoader(false))
    }

    //return
    return (
        <>
            {buttonText ?
                <Button
                    className="actionButton"
                    loading={attachLoader}
                    disabled={disabled}
                    onClick={handleNoResultReports}
                    variant={variant}
                >
                    {buttonText}
                </Button>
                :
                <u onClick={handleNoResultReports} className='downloadText'>{linkText || strings.DOWNLOAD_REPORT}</u>
            }
        </>
    );
}

export default UccEmptyReport;
