import React from 'react'
import { Col, Row } from "react-bootstrap"
import { mapSearchResultInfo } from 'constants/application';

const SearchResultInfo = (props) => {

    const { searchResult, possibleAlternatives } = props

    return (
        <Row className='pt-3'>
        {mapSearchResultInfo(searchResult, possibleAlternatives).map((item, index) => {
            return (
                <Col md={2} key={index}>
                    <p className='fieldTitle mb-0'>{item.title}</p>
                    <p className='fieldValue'>{item.value}</p>
                </Col>
            )
        })}
    </Row>
    )
}

export default SearchResultInfo