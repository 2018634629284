import React, { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { Alert } from '@material-ui/lab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Checkbox from 'components/forms/Checkbox';
import Button from 'components/shared/Button';
import Loader from 'components/shared/Loader';
import strings from 'strings';
import { Icon } from 'styles';
import './index.scss'
import { cleanPlaidLocalStorage } from "../../../actions/plaid";
import { useHistory } from 'react-router-dom';


const t = strings.PLAID
export default function PlaidIntegration(props) {
    const {
        match: { params: { applicantUUID: aid, ownerId: oid, custom: customParam } },
        location,
        plaidLinkToken: token,
        getToken,
        exchangeToken
    } = props
    const history = useHistory();
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState({ authorize: false, success: false })
    const config = {
        onSuccess: (public_token, metadata) => {
            localStorage.setItem('aid', aid);
            setLoading(prev => ({ ...prev, success: true }))
            exchangeToken({ aid, oid, public_token })
                .then(() => {
                    //Cleaning local storage after auth
                    cleanPlaidLocalStorage();
                    redirectToAuthSuccess();
                })
                .finally(() => setLoading(prev => ({ ...prev, success: false })))
        },
        onExit: (err, metadata) => { console.log(err); },
        onEvent: (eventName, metadata) => { console.log(eventName); },
        token,
    };
    const { open, exit, ready } = usePlaidLink(config);
    const customFlag = customParam === 'custom-J8kS2pL5q'
    const redirectToAuthSuccess = () => {
        if (customFlag){
            history.push('/plaid-auth-success?custom-J8kS2pL5q');
        } else {
            history.push('/plaid-auth-success');
        }
    };

    useEffect(() => {
        if (token && ready)
            open()

        return () => exit()
    }, [token, ready, exit, open])

    const handleGetToken = () => {
        setLoading(prev => ({ ...prev, authorize: true }))
        localStorage.setItem('aid', aid);
        localStorage.setItem('oid', oid);
        getToken({ aid, oid })
            .finally(() => setLoading(prev => ({ ...prev, authorize: false })))
    }

    const success = location.pathname.includes('success')

    return (
        <div className='d-flex align-items-center justify-content-center vh-100'>
            {loading.success ? <Loader /> :
                <div className='text-center p-5 plaid-wrapper'>
                    {success ?
                        <>
                            <Icon className='d-block' width={166} height={63} folder='menuIcons' fileName='logo.svg' />
                            <Icon className='d-block mt-3' folder='icons' fileName='success.svg' />
                            <h5 className='mt-3 mb-0'>{t.LINK_SUCCESS}</h5>
                            <Alert className='mt-4' icon={<CheckCircleIcon fontSize="inherit" />} severity="success">
                                {t.GREAT_MSG}
                            </Alert>
                        </> :
                        <>
                            <div className='d-flex align-items-center justify-content-center gap-55'>
                                {customFlag ?
                                    <>
                                        <img width={166} height={63}
                                             src={"https://roxwrite-public-assets.s3.amazonaws.com/thrivory-logo.svg"}
                                             alt={'Custom SVG'}/>
                                    </>
                                    :
                                    <>
                                        <Icon width={166} height={63} folder='menuIcons' fileName='logo.svg' />
                                    </>
                                }
                                <div className='circles bl' />
                                <div className='circles gr' />
                                <Icon folder='root' fileName='plaid.svg' />
                            </div>
                            <h3 className='mt-5 mb-0'>{t.TITLE}</h3>
                            <div className='sub-title'>{t.SUB_TITLE}</div>
                            <div className='brief'>{t.BRIEF}</div>
                            <div className='brief'>
                                {t.LEARN_MORE_PLAID}
                                <span onClick={() => window.open('https://plaid.com/how-we-handle-data/', '_blank')} className='linkLearnMore'>
                                    {t.TEXT_LINK_PLAID} </span>
                            </div>
                            <div className='brief'>
                                {t.LEARN_MORE_ROXWRITE}
                                <span onClick={() => window.open('https://www.roxwrite.com/privacy-policy', '_blank')} className='linkLearnMore'>
                                    {t.TEXT_LINK_ROXWRITE}
                                </span>
                            </div>
                            <Checkbox
                                className='checkBox'
                                labelClassName='anchor'
                                label='Terms and Conditions'
                                checked={checked}
                                onChange={setChecked}
                            />
                            <Button loading={loading.authorize} disabled={!checked} onClick={handleGetToken}>{t.AUTHORIZE}</Button>
                        </>}
                </div>}
        </div>
    )
}