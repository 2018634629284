import React, {useEffect, useState} from 'react'
import {getSavedDetail} from "../../../../../../../../actions/applicants";
import UccAttachedFilingsCard from "./UccAttachedFilingsCard";
import NoDataIndication from "../../../../../../../shared/NoDataIndication";
import strings from "../../../../../../../../strings";
import Loader from "../../../../../../../shared/Loader";

const UccAttachedFilingsTab = ({ selectedEntity, applicationId }) => {

    const [attachedFilings, setAttachedFilings] = useState([])
    const [loadingTabContent, setLoadingTabContent] = useState(true)

    useEffect(() => {
        setLoadingTabContent(true)
        getSavedDetail(applicationId, selectedEntity?.type, selectedEntity?.uuid, true)().then(res => {
            setAttachedFilings(res)
        }).finally(() => setLoadingTabContent(false))
    }, [applicationId, selectedEntity]);

    let noDataMessage = '';
    if (selectedEntity.type === 'BUSINESS') {
        noDataMessage = strings.APPLICANT.UCC.NO_ATTACHED_FILINGS_FOR_BUSINESS;
    } else if (selectedEntity.type === 'OWNER') {
        noDataMessage = strings.APPLICANT.UCC.NO_ATTACHED_FILINGS_FOR_OWNER;
    }

    return (
        <div className='mt-3'>
            {loadingTabContent ?
                <Loader/>
                :
                <>
                    {attachedFilings.length > 0 ? attachedFilings.map((filing, index) => {
                            return <UccAttachedFilingsCard filing={filing} key={index} applicationId={applicationId}/>
                        })
                        :
                        <NoDataIndication message={noDataMessage}/>}
                </>
            }
        </div>
    )
}

export default UccAttachedFilingsTab