import React, { Fragment, useCallback, useEffect, useState, useRef } from "react"
import { Button, CircularProgress, Grid } from "@material-ui/core"
import VisibilityIcon from '@material-ui/icons/Visibility'
import ReactJson from 'react-json-view'
import { useDispatch, useSelector } from "react-redux"
import {
    CreditCheckColumn as bgvColumns,
    mapDataToBgvReportTable,
    mapBusinessDataToBgvReportTable,
    buisnessBgvColumns,
    mapBGVDebtor,
    mapParsedParty,
    mapCriminalDetail
} from "constants/application"
import { dispatchBgvSearchResult, dispatchDetailedBusinessReport } from "actions/applicants"
import TableComponent from "components/shared/TableComponent"
import { Toast } from "components/shared/Toaster"
import Drawer from 'components/shared/Drawer'
import AuthService from "tools/AuthService"
import strings from "strings"
import emptyUCC from "styles/images/emptyUCC.svg"
import '../UCC/ucc.scss'

const bgvString = strings.APPLICANT.BGV
export function Bgv(props) {
    const isMounted = useRef(true);
    const {
        getBgvList,
        bgvList,
        getConnectedBuisnessList,
        connectedBuisnessList,
        applicationId,
        performBgvSearch,
        searchResult: resData,
        saveBgvReport,
        getDetailedBusinessReport,
        detailedBusinessReport: businessReport,
        saveBuisnessReport,
        getConnectedBuisnessReport,
        saveConnectedBuisnessReport,
        downloadFile,
        attachFile,
        updateSummaryCard
    } = props
    const [selectedRow, setSelectedRow] = useState([])
    const [loading, setLoading] = useState({ list: false, connectedBuisnessList: false, search: false, report: false, save: false, download: false, attach: false })
    const [openReport, setOpenReport] = useState({ business: false, owner: false, detailed: false })
    const [openConnectedBusinessReport, setOpenConnectedBusinessReport] = useState({})
    const [connectedBuisnessLoading, setConnectedBuisnessLoading] = useState({ get: undefined, save: undefined })
    const [openInformation, setOpenInformation] = useState({ open: false, title: '', data: null, entity: '' })
    const [isSavedReport, setIsSavedReport] = useState(false)
    const [saveDetails, setSaveDetails] = useState({ rowData: undefined, latestId: undefined })
    const [pdfReportUuid, setPdfReportUuid] = useState(null)
    const dispatch = useDispatch();
    const isReadOnlyUser = AuthService.isReadOnlyUser()
    const { connectedBusinessReport } = useSelector(state => state.applicant.bgv)
    const {
        CriminalRecords,
        BpsReportAddresses,
        Imposters,
        Bankruptcies,
        UCCFilings,
        BestInfo,
        LiensJudgments,
        Verification
    } = resData || {}

    //Type business or connected business, key to identify which connected business, getFullData for printing raw data
    const getBuinessData = (type, key, getfullData) => {
        const detailedBusinessReport = type === 'business' ? businessReport : connectedBusinessReport.find(cbr => cbr.key === key)
        if (getfullData)
            return detailedBusinessReport

        return {
            buisnessDetails: [detailedBusinessReport?.BestSection || {}],
            otherCompanyTin: detailedBusinessReport?.BestSection?.OtherCompanyTins?.OtherCompanyTin || [],
            finance: detailedBusinessReport?.FinanceSection?.Finances?.Finance || [],
            industry: detailedBusinessReport?.IndustrySection?.IndustryRecords?.IndustryRecord || [],
            corpFiling: detailedBusinessReport?.IncorporationSection?.CorpFilings?.CorpFiling || [],
            currentIndividuals: detailedBusinessReport?.ContactSection?.CurrentIndividuals?.Individual || [],
            currentExecutives: detailedBusinessReport?.ContactSection?.CurrentExecutives?.Individual || [],
            priorExecutives: detailedBusinessReport?.ContactSection?.PriorExecutives?.Individual || [],
            bankruptcy: [detailedBusinessReport?.BankruptcySection || {}],
            buisnessLiensJudgements: detailedBusinessReport?.LienSection?.JudgmentsLiens?.JudgmentLien || [],
            ucc: detailedBusinessReport?.UCCSection?.AsDebtor?.ActiveUCCs?.ActiveUCC || [],
            connectedBusiness: detailedBusinessReport?.ConnectedBusinessSection?.ConnectedBusinessRecords?.ConnectedBusinessRecord || [],
            exparian: detailedBusinessReport?.ExperianBusinessReportSection?.ExperianBusinessReports?.ExperianBusinessReport || [],
            buisnessVerification: [detailedBusinessReport?.CompanyVerificationSection?.CompanyVerification?.VerifiedIndicators || {}]
        }
    }
    const
        criminalRecords = CriminalRecords?.Criminal || [],
        bpsReportAddress = BpsReportAddresses?.BpsReportAddress || [],
        imposters = Imposters?.Imposter[0]?.AKAs?.Identity || [],
        bankruptcies = Bankruptcies?.Bankruptcy || [],
        uccFiling = UCCFilings?.UCCFiling || [],
        info = [BestInfo || {}],
        liensJudgments = LiensJudgments?.LienJudgment || [],
        verification = [Verification?.VerifiedIndicators || {}]

    const paginationProps = (length) => ({
        useLocalPagination: length > 5,
        showPagination: length > 5,
        paginationLimit: 5,
    })

    const safeListLoadingSetter = (value) => {
        if (isMounted.current) {
            setLoading(prev => ({ ...prev, list: value }))
        }
    }

    const getList = useCallback((clearSelected = false) => {
        if (isMounted.current) {
            setLoading(prev => ({ ...prev, list: true }))
            if (clearSelected)
                setSelectedRow([])
        }
        // resetting data
        getBgvList(null, true)
        getConnectedBuisnessList(null, true)
        getBgvList(applicationId).finally(() => {
            safeListLoadingSetter(false)
        })
        getConnectedBuisnessList(applicationId).finally(() => {
            safeListLoadingSetter(false)
        })
    }, [getBgvList, getConnectedBuisnessList, applicationId])

    useEffect(() => {
        getList(true)
        return () => {
            isMounted.current = false;
        };
    }, [getList])

    const nonSelectable = bgvList
        ?.filter(item => item.status === strings.STATUS_NAMES.Hit_Completed)
        .map(item => item.refId);

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedRow([row])
            setPdfReportUuid(row.pdfReportUuid)
        }
    }

    const handleSearch = () => {
        setLoading(prev => ({ ...prev, search: true }))
        setIsSavedReport(false)
        const payLoad = {
            [selectedRow[0].entityType === 'OWNER' ? 'ownerId' : 'businessId']: selectedRow[0].entityUUID,
            searchByDBA: selectedRow[0].entityType === 'BUSINESS_DBA'
        }
        performBgvSearch(applicationId, payLoad).then((res) => {
            switch (res?.productName) {
                case 'BPS_REPORT':
                    setOpenReport(prev => ({ ...prev, owner: true }))
                    break;
                case 'TOP_BIZ_SEARCH':
                    setOpenReport(prev => ({ ...prev, business: true }))
                    break;
                case 'TOP_BIZ_REPORT':
                    setOpenReport(prev => ({ ...prev, detailed: true }))
                    break;
                default:
                    break;
            }
            setPdfReportUuid(res?.pdfReportUuid)
            getList()
        }).finally(() => {
            setLoading(prev => ({ ...prev, search: false }))
        })
    }

    const handleDetailedReport = (row) => {
        setLoading(prev => ({ ...prev, search: true }))
        const payLoad = { businessId: applicationId, lexisBusinessIds: row.BusinessIds }
        getDetailedBusinessReport(applicationId, payLoad).then(
            (res) => {
                setPdfReportUuid(res?.pdfReportUuid)
            }
        ).finally(() => {
            setLoading(prev => ({ ...prev, search: false }))
        })
        setOpenReport(prev => ({ ...prev, detailed: true }))
    }

    const viewSavedReport = (row) => {
        // Hack to re render on each state update
        Promise.resolve().then(() => setIsSavedReport(true))
        setOpenConnectedBusinessReport({})
        getConnectedBuisnessReport(null, null, true)
        if (row.entityType === 'BUSINESS' || row.entityType === 'CONNECTED_BUSINESS' || row.entityType === 'BUSINESS_DBA') {
            const rowData = row.response?.TopBusinessReportResponseEx?.response?.Businesses?.Business[0]
            if (rowData) {
                dispatch(dispatchDetailedBusinessReport(rowData))
                setOpenReport(prev => ({ ...prev, detailed: true }))
            }
        }
        else {
            const rowData = row.response?.BpsReportResponseEx?.response?.Individual
            if (rowData) {
                dispatch(dispatchBgvSearchResult(rowData))
                setOpenReport(prev => ({ ...prev, owner: true }))
            }
        }
    }

    const handleOwnerSave = () => {
        setLoading(prev => ({ ...prev, save: true }))
        const payLoad = { [selectedRow[0].entityType === 'OWNER' ? 'ownerId' : 'businessId']: selectedRow[0].entityUUID }
        saveBgvReport(applicationId, payLoad).then(() => {
            Toast({ type: 'success', message: strings.SUCCESS });
            getList(true)
            setOpenReport(prev => ({ ...prev, owner: false }));
        }).finally(() => {
            updateSummaryCard()
            setLoading(prev => ({ ...prev, save: false }));
        })
    }

    const handleBusinessSave = (row) => {
        setLoading(prev => ({ ...prev, save: true }))
        const payLoad = { businessId: applicationId, lexisBusinessIds: row.BusinessIds, searchByDBA: selectedRow[0].entityType === 'BUSINESS_DBA' }
        saveBuisnessReport(applicationId, payLoad).then(() => {
            Toast({ type: 'success', message: strings.SUCCESS });
            setOpenReport(prev => ({ ...prev, business: false, detailed: false }))
            getList(true)
        }).finally(() => {
            updateSummaryCard()
            setLoading(prev => ({ ...prev, save: false }))
        })
    }

    const handleConnectedBusinessReport = (row, rowIndex) => {
        setOpenConnectedBusinessReport(prev => ({ ...prev, [row?.BusinessIds?.OrgID]: false }))
        setConnectedBuisnessLoading(prev => ({ ...prev, get: rowIndex }))
        setSaveDetails({ rowData: row, latestId: row?.BusinessIds?.OrgID })
        getConnectedBuisnessReport(applicationId, row)
            .then(setTimeout(() => setOpenConnectedBusinessReport(prev => ({ ...prev, [row?.BusinessIds?.OrgID]: true })), 600))
            .finally(() => setConnectedBuisnessLoading(prev => ({ ...prev, get: undefined })))
    }

    const handleConnectedBusinessSave = (row, rowIndex) => {
        setConnectedBuisnessLoading(prev => ({ ...prev, save: row ? rowIndex : true }))
        saveConnectedBuisnessReport(applicationId, row || saveDetails.rowData)
            .then(() => {
                Toast({ type: 'success', message: strings.SUCCESS })
                setOpenConnectedBusinessReport(prev => ({ ...prev, [saveDetails.latestId]: false }))
                getList()
            })
            .finally(() => setConnectedBuisnessLoading(prev => ({ ...prev, save: undefined })))
    }

    const handleDownload = () => {
        setLoading(prev => ({ ...prev, download: true }))
        downloadFile(applicationId, pdfReportUuid, 'download.pdf')
            .finally(() => setLoading(prev => ({ ...prev, download: false })))
    }

    const handleAttach = () => {
        setLoading(prev => ({ ...prev, attach: true }))
        attachFile(applicationId, { pdfReportUuid })
            .then(() => Toast({ type: 'success', message: bgvString.ATTACH_SUCCESS_MSG }))
            .finally(() => setLoading(prev => ({ ...prev, attach: false })))
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: selectedRow.map(r => r.refId),
        onSelect: handleOnSelect,
        nonSelectable,
        hideSelectAll: true
    }

    const action = {
        formatter: (cell, row, rowIndex) => {
            if (row.status === strings.STATUS_NAMES.Hit_Completed)
                return (
                    <VisibilityIcon color='primary' onClick={() => {
                        viewSavedReport(row)
                        setPdfReportUuid(row.pdfReportUuid)
                    }} />
                )
        }
    }
    bgvColumns.map((item, index) => {
        if (item.dataField === 'action') {
            bgvColumns[index] = { ...item, ...action }
        }
        return item
    })

    const actionForBgv = {
        formatter: (cell, row, rowIndex) => {
            return (
                <>
                    <Button className="mr-2" variant="outlined" color="primary" size="small" onClick={() => handleDetailedReport(row)}>
                        {strings.APPLICANT.DETAIL_REPORT}
                    </Button>
                    <Button variant="contained" color="primary" size="small" onClick={() => handleBusinessSave(row)} disabled={loading.save}>
                        {strings.Save}
                    </Button>
                </>
            )
        }
    }

    buisnessBgvColumns.map((item, index) => {
        if (item.dataField === 'action') {
            buisnessBgvColumns[index] = { ...item, ...actionForBgv }
        }
        return item
    })

    const actionDetails = (columns, actionFor, entity) => {
        const actions = (dataField) => {
            return {
                formatter: (cell, row, rowIndex) => {
                    const temp = entity === 'business' && actionFor === 'UCC Filings' ? row?.Filings?.Filing[0] : row
                    let rowData = [], title = ''
                    if (dataField === 'Action1') {
                        rowData = temp?.Debtors?.Debtor
                        title = bgvString.ABOUT_DEBTOR
                    }
                    else if (actionFor === 'UCC Filings') {
                        rowData = temp?.Secureds?.Secured
                        title = bgvString.ABOUT_SECURED
                    }
                    else {
                        rowData = temp?.Creditors?.Creditor
                        title = bgvString.ABOUT_CREDITOR
                    }
                    if (rowData?.length)
                        return (
                            rowData?.map((data, i) => {
                                let name = data.CompanyName || data.OriginName || data.Name
                                if (typeof name === 'object')
                                    name = `${name.First} ${name.Last}`

                                return (
                                    <div
                                        key={i}
                                        className='div-button'
                                        title={name}
                                        onClick={() => setOpenInformation({ open: true, title, data, entity })}
                                    >
                                        {name}
                                    </div>
                                )
                            })
                        )
                    return <>---</>
                }
            }
        }

        columns.map((item, index) => {
            if (['Action1', 'Action2'].includes(item.dataField)) {
                columns[index] = { ...item, ...actions(item.dataField) }
            }
            return item
        })

        return columns
    }

    const criminalActionDetails = columns => {
        const actions = {
            formatter: (cell, row, rowIndex) => {
                if (row?.Offenses?.Offense?.length)
                    return row.Offenses.Offense.map((data, i) => (
                        <div
                            key={i}
                            className='div-button'
                            onClick={() => setOpenInformation({ open: true, title: bgvString.OFFENCES, data, entity: 'criminal' })}
                        >
                            {bgvString.OFFENCE} {i + 1}
                        </div>
                    ))

                return <>---</>
            }
        }

        columns.map((item, index) => {
            if (item.dataField === 'Action1') {
                columns[index] = { ...item, ...actions }
            }
            return item
        })

        return columns
    }

    const connectedBusinessAction = columns => {
        const actions = {
            formatter: (cell, row, rowIndex, extraData) => (
                <>
                    <Button
                        className="mr-2 loader-width-108"
                        variant="outlined"
                        color='primary'
                        size='small'
                        disabled={extraData.get === rowIndex}
                        onClick={() => handleConnectedBusinessReport(row, rowIndex)}
                    >
                        {extraData.get === rowIndex ? <CircularProgress size={24} color='inherit' /> : strings.GET_REPORT}
                    </Button>
                    <Button
                        className='loader-width-64'
                        variant="contained"
                        color='primary'
                        size='small'
                        disabled={extraData.save === rowIndex}
                        onClick={() => handleConnectedBusinessSave(row, rowIndex)}
                    >
                        {extraData.save === rowIndex ? <CircularProgress size={24} color='inherit' /> : strings.Save}
                    </Button>
                </>
            ),
            formatExtraData: connectedBuisnessLoading
        }

        return columns.map((item, index) => {
            if (item.dataField === 'action') {
                columns[index] = { ...item, ...actions }
            }
            return item
        })
    }

    const buinessReportJSX = (type, key) => (
        loading.search ? (
            <div className='d-flex align-items-center justify-content-center h-100'>
                <CircularProgress size={48} color='primary' />
            </div>
        ) : (
            < div className="pb-5" >
                {
                    mapBusinessDataToBgvReportTable(getBuinessData(type, key)).map((d, i) => {
                        const isScrollNeeded = d.scrollNeeded
                        const columns = d.title === 'Connected Business' ? connectedBusinessAction(d.columns) :
                            isScrollNeeded ? actionDetails(d.columns, d.title, 'business') :
                                d.columns

                        return (
                            <div className="box" key={i}>
                                <h5>{d.title}</h5>
                                {
                                    d.title === 'Raw Data' ?
                                        <ReactJson
                                            src={getBuinessData(type, key, true)}
                                            name={false}
                                            collapsed={1}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                            enableClipboard={false}
                                        />
                                        :
                                        <div style={isScrollNeeded ? { overflowX: 'scroll' } : {}}>
                                            <TableComponent
                                                columns={columns}
                                                data={d.data}
                                                {...paginationProps(d.data.length)}
                                                className={`customTable ${isScrollNeeded ? 'w135' : ''}`}
                                            />
                                        </div>
                                }
                            </div>
                        )
                    }
                    )
                }
            </div >
        )
    )

    const detailedReportActions = (<div>
        <span title={!pdfReportUuid ? bgvString.HINT : ''}>
            <Button color='primary' variant='outlined' disabled={loading.download || !pdfReportUuid} onClick={handleDownload} className='mr-2'>
                {loading.download ? <CircularProgress size={24} color='inherit' /> : strings.DOWNLOAD_PDF}
            </Button>
            <Button color='primary' variant='outlined' disabled={loading.attach || !pdfReportUuid} onClick={handleAttach} className='mr-2'>
                {loading.attach ? <CircularProgress size={24} color='inherit' /> : strings.ATTACH_PDF}
            </Button>
            {selectedRow[0]?.entityType !== 'OWNER'  && !isSavedReport &&
                <Button color='primary' variant='contained' disabled={loading.save} onClick={() => handleBusinessSave(businessReport?.BestSection)} className='mr-2'>
                    {loading.save ? <CircularProgress size={24} color='inherit' /> : strings.Save}
                </Button>
            }
        </span>
    </div>)

    return (
        <div className="uccContainer">
            <div className='uccTitleContainer'>
                <h5>{bgvString.BACKGROUND_CHECK}</h5>
            </div>
            <TableComponent
                keyField='refId'
                columns={bgvColumns}
                data={bgvList}
                showPagination={false}
                selectRow={!isReadOnlyUser ? selectRow : undefined}
                loading={loading.list}
            />
            <div className='filterContainer'>
                <div className='uccBtnContainer'>
                    {!isReadOnlyUser && <Button
                        variant='contained'
                        color='primary'
                        disabled={selectedRow?.length === 0 || loading.search}
                        onClick={handleSearch}
                    >
                        {
                            loading.search ? <CircularProgress size={24} color='inherit' /> : strings.SEARCH
                        }
                    </Button>}
                </div>
            </div>

            <div className='uccTitleContainer'>
                <h5>{bgvString.CONNECTED_BUSINESS}</h5>
            </div>
            <TableComponent
                className='mb-5'
                keyField='entityUUID'
                columns={bgvColumns}
                data={connectedBuisnessList.filter(data => data.entityType === 'CONNECTED_BUSINESS' && data.status === strings.STATUS_NAMES.Hit_Completed)}
                showPagination={false}
            />

            {/* Drawer to show list of businesses  */}
            <Drawer
                open={openReport.business}
                onClose={() => setOpenReport(prev => ({ ...prev, business: false }))}
                title={`${strings.SEARCH} ${strings.DETAILS}`}
            >
                <div className="box pb-5">
                    <h5>{bgvString.LIST_OF_BUSINESSES}</h5>
                    <TableComponent
                        columns={buisnessBgvColumns}
                        data={resData}
                        showPagination={false}
                        className="customTable"
                    />
                </div>
            </Drawer>

            {/* Drawer to show detailed business report */}
            <Drawer
                open={openReport.detailed}
                onClose={() => setOpenReport(prev => ({ ...prev, detailed: false }))}
                title={strings.APPLICANT.DETAIL_REPORT}
                actions={detailedReportActions}
            >
                {buinessReportJSX('business')}
            </Drawer>

            {/* Drawer to show detailed owner report */}
            <Drawer
                open={openReport.owner}
                onClose={() => setOpenReport(false)}
                title={strings.APPLICANT.DETAIL_REPORT}
                actions={
                    <div className="d-flex">
                        {detailedReportActions}
                        {!isSavedReport && Object.entries(resData).length ?
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={handleOwnerSave}
                                disabled={loading.save}
                            >
                                {loading.save ? <CircularProgress size={24} color='inherit' /> : strings.Save}
                            </Button> : null}
                    </div>
                }
            >
                {Object.entries(resData).length ?
                    <div className="pb-5">
                        {
                            mapDataToBgvReportTable({
                                criminalRecords,
                                bpsReportAddress,
                                imposters,
                                bankruptcies,
                                uccFiling,
                                info,
                                liensJudgments,
                                verification,
                            }).map((d, i) => {
                                const isScrollNeeded = d.scrollNeeded
                                const columns = d.title === 'Criminal Records' ? criminalActionDetails(d.columns) :
                                    isScrollNeeded ? actionDetails(d.columns, d.title, 'owner') :
                                        d.columns

                                return (
                                    <div className="box" key={i}>
                                        <h5>{d.title}</h5>
                                        <div>{d.subtext}</div>
                                        {
                                            d.title === 'Raw Data' ?
                                                <ReactJson
                                                    src={resData}
                                                    name={false}
                                                    collapsed={1}
                                                    displayObjectSize={false}
                                                    displayDataTypes={false}
                                                    enableClipboard={false}
                                                />
                                                :
                                                <div style={isScrollNeeded ? { overflowX: 'scroll' } : {}}>
                                                    <TableComponent
                                                        keyField='index'
                                                        columns={columns}
                                                        data={d.data}
                                                        {...paginationProps(d.data.length)}
                                                        className={`customTable ${isScrollNeeded && (liensJudgments.length || uccFiling.length) ? 'w135' : ''}`}
                                                    />
                                                </div>
                                        }
                                    </div>
                                )
                            }
                            )
                        }
                    </div>
                    : (
                        <div className='emptyIcon'>
                            <img src={emptyUCC} alt="no data icon" />
                            <div className="noResultsMessageDiv">
                                <p className="noResultsText boldText">{bgvString.THERE_WERE_NO_RESULTS}</p>
                                <p className="noResultsText">{bgvString.NO_RESULTS_FOR_OWNER}</p>
                            </div>
                        </div>
                    )}

            </Drawer>

            {/* Drawer to show connected business report */}
            {connectedBusinessReport.map(({ key }, index) =>
                <Drawer
                    key={index}
                    open={Boolean(openConnectedBusinessReport[key])}
                    onClose={() => setOpenConnectedBusinessReport(prev => ({ ...prev, [key]: false }))}
                    title={bgvString.CONNECTED_BUSINESS_REPORT}
                    actions={
                        <Button
                            className='loader-width-64'
                            variant="contained"
                            color='primary'
                            size='small'
                            disabled={connectedBuisnessLoading.save}
                            onClick={() => handleConnectedBusinessSave()}
                        >
                            {connectedBuisnessLoading.save ? <CircularProgress size={24} color='inherit' /> : strings.Save}
                        </Button>
                    }
                >
                    {buinessReportJSX('connectedBusiness', key)}
                </Drawer>
            )}

            {/* Drawer to show additional information of the particular data */}
            <Drawer
                open={openInformation.open}
                onClose={() => setOpenInformation({ open: false })}
                title={openInformation.title}
            >
                <div className="pb-5">
                    <div className="box">
                        {openInformation.entity === 'criminal' ?
                            <h5 className='mb-4'>{strings.OFFENSES_INFORMATION}</h5> :
                            <h5 className='mb-4'>
                                {openInformation.title === bgvString.ABOUT_DEBTOR ? strings.DEBTOR_INFORMATION :
                                    openInformation.title === bgvString.ABOUT_SECURED ? strings.SECURED_INFORMATION :
                                        strings.CREDITOR_INFORMATION}
                            </h5>
                        }

                        <Grid container spacing={2}>
                            {openInformation.data &&
                                (openInformation.entity === 'criminal' ? mapCriminalDetail(openInformation) : mapBGVDebtor(openInformation))
                                    .map(data =>
                                        <Fragment key={data.title}>
                                            <Grid item sm={3}>{data.title}</Grid>
                                            <Grid item sm={9}>{data.value}</Grid>
                                        </Fragment>
                                    )}
                        </Grid>
                    </div>

                    {openInformation.data?.ParsedParties?.Party?.length && openInformation.entity === 'owner' ?
                        openInformation.data.ParsedParties.Party.map((party, i) => {
                            if (party && (party.CompanyName || party.Name))
                                return (
                                    <div className="box" key={i}>
                                        <h5 className='mb-4'>Parsed Parties</h5>
                                        <Grid container spacing={2}>
                                            {mapParsedParty(party).map(data =>
                                                <Fragment key={data.title}>
                                                    <Grid item sm={3}>{data.title}</Grid>
                                                    <Grid item sm={9}>{data.value}</Grid>
                                                </Fragment>
                                            )}
                                        </Grid>
                                    </div>
                                )
                            return null
                        })
                        : null}
                </div>
            </Drawer>
        </div>
    )
}
