export function formatDataForAccordion (entity) {
    return [
        {label: 'Organization', value: entity?.organizationName || '-'},
        {label: 'Last Name', value: entity?.lastName || '-'},
        {label: 'First Name', value: entity?.firstName || '-'},
        {label: 'Suffix', value: entity?.suffix || '-'},
        {label: 'Address', value: entity?.address?.streetAddress || '-'},
        {label: 'City', value: entity?.address?.city || '-'},
        {label: 'State', value: entity?.address?.state || '-'},
        {label: 'Postal Code ', value: entity?.address?.postalCode || '-'},
        {label: 'Country', value: entity?.address?.country || '-'}]
}

export function getNameFromEntity (entity) {
    return entity.organizationName ? entity.organizationName : entity.lastName + ', ' + entity.firstName
}