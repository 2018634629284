import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { cloneDeep } from "lodash"
import queryString from 'query-string';
import { useHistory } from 'react-router-dom'
import { Button, CircularProgress } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DoneIcon from '@material-ui/icons/Done';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { Toast } from 'components/shared/Toaster'
import TableComponent from 'components/shared/TableComponent'
import { UccSearchColums, UccFilingColums, DirectAmendmentColums } from 'constants/application'
import { UccPosting } from './uccPosting'
import ReportDrawer from './reports/report'
import DetailReportDrawer from './reports/detailReport'
import { InfoView } from './views/infoView'
import { DiffView } from '../../Drawer/DiffView'
import AuthService from 'tools/AuthService'
import { statesOptions } from 'constants/constantVariables'
import strings from 'strings'
import './ucc.scss'

const status = strings.STATUS_NAMES
const t = strings.APPLICANT.UCC
const Ucc = (props) => {
    const {
        requestUccFilingImage, //action to request filing image
        getUccList, //action to get interaction list
        uccList, // list of ucc interactions
        getUccData, // action to get ucc search parametrs
        uccFilingList, // list of ucc filing
        directAmendmentList, // list of direct amendments
        applicationId, // application ID
        uccSearch, // action to ucc search by business or owner
        finishSearch, // action to finish the ucc
        reopenSearch, // action to reopen the ucc
        filingDetails, //action to get filing details
        getTerminationPreview, // action get the termination details
        terminateUCC, // action to terminate ucc
        savefiling, // action to save Filing
        getSavedDetail, // action to get saved Detail
        deleteReport, // action to delete report
        retrieveDocument,
        getAttachmentPDF,
        downloadFileByPayload, // action to download file in PDF modal
        getRetrieveDocuments,
        getUccFilingList, // action to get list of owners and business to be filed
        postFiling, // action to file UCC
        getJurisdictionList, // action to get jurisdiction list
        getdirectAmendmentsList, // action to get direct amendment list
        getTemplates, // action to get template list
        filingPreview, // action to get filing preview
        handleSubmit, // redux onSubmit prop to submit the form
        resetForm, // reset forms to it's intial value
        getUccFilingDetailsByEntity,
        getUccFilingDetailsByFilingNumber,
        getUccFilingDetailsByFilingId,
        tabChanged,
        change, // redux change function
        // Attachments Props
        onSubmit,
        uploadRoxFiles,
        getFiles,
        attachmentList,
        downLoadAttachment,
        requestUCCForceUpdate,
        getFilingReportsAttachments,
        downLoadAttachmentByFileId,
        totalPages,
        totalRecords,
        dispatchProcessing,
        updateSummaryCard,
    } = props
    const [loading, setLoading] =
        useState({ ucc: false, uccSearch: false, detail: false, save: false, report: false, filing: false, filingList: false, amendmentList: false, viewAmendment: false })
    const [openReport, setOpenReport] = useState(false)
    const [openDetailReport, setOpenDetailReport] = useState(false)
    const [selectedRow, setSelectedRow] = useState([])
    const [filingSelectedRow, setFilingSelectedRow] = useState([])
    const [selectedFilingIndex, setSelectedFilingIndex] = useState([])
    const [openPosting, setOpenPosting] = useState(false)
    const [openFiledInfo, setOpenFiledInfo] = useState({ open: false, data: null, hideDetails: false, ucc3: false })
    const [searchId, setSearchId] = useState({ id: '', entityName: '' })
    const [UccSearchId, setUccSearchId] = useState([])
    const [transactionID, setTransactionID] = useState()
    const [reportData, setReportData] = useState()
    const [interactionId, setInteractionId] = useState("")
    const [intIdSearch, setIntIdSearch] = useState("")
    const [nonSelectable, setNonSelectable] = useState([])
    const [nonSelectableFiling, setNonSelectableFiling] = useState([])
    const [reportDetails, setReportDetails] = useState({})
    const [selectedEntity, setSelectedEntity] = useState({})
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [savedReport, setSavedReport] = useState({ report: false, detailedReport: false })
    const [rowDetails, setRowDetails] = useState(null)
    const [lastSearchedDetails, setlastSearchedDetails] = useState({ filters: {}, data: {} })
    const uccSearchData = useSelector(state => state.applicant.ucc.uccSearchData)
    const terminationPreviewData = useSelector(state => state.applicant.clients.debtors)
    const ucc3Data = useSelector(state => state.applicant.clients.ucc3Preview) || {}
    const [stateUccSearch, setStateUccSearch] = useState('')
    const isReadOnlyUser = AuthService.isReadOnlyUser()
    const { location, replace } = useHistory()
    const tab = queryString.parse(location.search).tab

    useEffect(() => {
        // To select the first occurence state
        const filingData = filingSelectedRow[0]
        setState(filingData?.state)
        setCountry(filingData?.countryCode)

    }, [filingSelectedRow, uccFilingList])

    /**
     * 
     * @param {object} row selected row data
     * @param {bool} isSelect bool to check row selected or unselected
     */
    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedRow([row.uuid])
            setSelectedEntity({
                entityType: row.type,
                entityUuid: row.uuid
            })
            setSearchId({
                ...searchId,
                id: row.uuid,
                entityName: row.type,
                state: row.state,
                countryCode: row.countryCode
            })

            // Backend needs to send the data to interaction api for now using ucc/data api
            const payLoad = { [row.type === 'BUSINESS' ? 'businessId' : 'ownerId']: row.uuid }
            setLoading(prev => ({ ...prev, ucc: true }))
            getUccData(applicationId, payLoad)
                .then((res) => Object.entries(res || {}).map(data => data[1] && change(data[0], data[1])))
                .finally(() => setLoading(prev => ({ ...prev, ucc: false })))
        }
    }

    const handleFilingOnSelect = (row, isSelect) => {
        if (isSelect) {
            const temp = []
            // maintaing the order of list with selected ones
            uccFilingList.forEach(list => {
                const element = [...filingSelectedRow, row].find(t => list.refId === t.refId)
                if (element)
                    temp.push(element)
            })
            setFilingSelectedRow(temp)
            setUccSearchId(prev => ([...prev, { id: row.entityUuid, entityType: row.entityType, addressId: row.addressId }]))
        }
        else {
            setFilingSelectedRow(filingSelectedRow.filter(r => r.refId !== row.refId))
            setUccSearchId(UccSearchId.filter(r => r.id !== row.entityUuid))
        }
    }

    const handleFilingSelectAll = (isSelect, rows) => {
        if (isSelect && rows?.length) {
            const { state, countryCode } = rows[0]
            const validRows = rows.filter(row => row.state === state && row.countryCode === countryCode)
            setFilingSelectedRow(validRows)
            setUccSearchId(validRows.map(r => ({ id: r.entityUuid, entityType: r.entityType, addressId: r.addressId })))
        }
        else {
            setFilingSelectedRow([])
            setUccSearchId([])
        }
    }

    /**
     * Row selection configuration for UCC search
     */
    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: selectedRow,
        onSelect: handleOnSelect,
        nonSelectable,
        hideSelectAll: true
    }

    const filingSelectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: filingSelectedRow.map(row => row.refId),
        onSelect: handleFilingOnSelect,
        onSelectAll: handleFilingSelectAll,
        nonSelectable: nonSelectableFiling,
    }

    const getListOfUCC = useCallback(() => {
        setLoading(prev => ({ ...prev, ucc: true }))
        return getUccList(applicationId).finally(() => {
            setLoading(prev => ({ ...prev, ucc: false }))
        })
    }, [getUccList, applicationId])

    const getListOfDirectAmendments = useCallback(() => {
        setLoading(prev => ({ ...prev, amendmentList: true }))
        return getdirectAmendmentsList(applicationId).finally(() => {
            setLoading(prev => ({ ...prev, amendmentList: false }))
        })
    }, [getdirectAmendmentsList, applicationId])

    /**
     * To get the UCC interactions list to update the ucc table
     */
    useEffect(() => {
        if (tabChanged.changed) {
            setLoading(prev => ({ ...prev, filingList: true }))
            getListOfUCC()
            getUccFilingList(applicationId, true).finally(() => {
                setLoading(prev => ({ ...prev, filingList: false }))
            })
            getListOfDirectAmendments()
            setSelectedRow([])
            tabChanged.setChanged()
        }
    }, [getListOfUCC, getUccFilingList, applicationId, getListOfDirectAmendments, tabChanged])

    useEffect(() => {
        let disabledRadio = []
        disabledRadio = uccList?.map(item => {
            if (item.status === status.Hit_Completed) {
                return item.uuid
            }
            return undefined
        })
        setNonSelectable(disabledRadio)
    }, [uccList])

    const updateDisabledFilings = () => {
        let disabledRadio = []
        disabledRadio = uccFilingList?.map((item, index) => {
            if (![status.Not_Started, status.Rejected, status.Preparation].includes(item.status)) {
                return index+1;
            }
            if (state && country){
                if (item.state !== state || item.countryCode !== country) {
                    return index+1;
                }
            }
            return undefined
        })
        setNonSelectableFiling(disabledRadio)
    };
    useEffect(updateDisabledFilings , [state, country])

    useEffect(() => {
        updateDisabledFilings()

        const { entityUUID } = queryString.parse(location.search);
        if (entityUUID) {
            const row = uccFilingList.find(list => list.entityUuid === entityUUID)
            const rowIndex = uccFilingList.findIndex(list => list.entityUuid === entityUUID)
            if ([status.Rejected, status.Preparation].includes(row?.status)) {
                setFilingSelectedRow([row])
                setSelectedFilingIndex([rowIndex])
                setUccSearchId([{ id: row.entityUuid, entityType: row.entityType, addressId: row.addressId }])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uccFilingList, location.search])

    useEffect(() => {
        const { drawer, filingNumber, filingId, entityUUID, isDba } = queryString.parse(location.search);
        if (drawer === 'amendment') {
            setLoading(prev => ({ ...prev, viewAmendment: true }))
            setOpenFiledInfo({ open: true });
            (filingNumber ? getUccFilingDetailsByFilingNumber(filingNumber) : getUccFilingDetailsByFilingId(filingId))
                .then(() => setOpenFiledInfo({ open: true, hideDetails: !ucc3Data?.amendmentType?.includes('NO'), ucc3: true }))
                .catch(() => setOpenFiledInfo({ open: false }))
                .finally(() => setLoading(prev => ({ ...prev, viewAmendment: false })))
        }
        else if (drawer === 'filing') {
            getUccFilingDetailsByEntity(applicationId, entityUUID, null, { isDba })
                .then(() => setOpenFiledInfo({ open: true, ucc3: false }));
        }
        // eslint-disable-next-line
    }, [location.search, applicationId, getUccFilingDetailsByFilingNumber, getUccFilingDetailsByFilingId, getUccFilingDetailsByEntity])

    /**
     * Deep cloning for ucc columns
     * Adding action to columns
     */
    let uccSearchColums = cloneDeep(UccSearchColums).filter(item => item.dataField !== 'county')
    const action = {
        formatter: (cell, row, rowIndex) => {
            if (row.uuid) {
                return (
                    <>
                        {row.status !== status.Not_Started ?
                            <VisibilityIcon className='mr-3' color='primary' onClick={() => {
                                handleSavedDetail(row)
                                setRowDetails(row)
                                setSearchId({
                                    ...searchId,
                                    id: row.uuid,
                                    entityName: row.type,
                                    state: row.state,
                                    countryCode: row.countryCode
                                })
                            }} /> : null}
                        {row.status === status.Hit_Completed ?
                            <RotateLeftIcon titleAccess={t.TOOLTIP4} htmlColor='#E99B3E' onClick={() => handleStatus(row, 'reopen')} /> :
                            row.status !== status.Not_Started ?
                                <DoneIcon titleAccess={t.TOOLTIP3} htmlColor='#57C281' onClick={() => handleStatus(row, 'finish')} /> :
                                null
                        }
                    </>
                )
            }
        }
    }
    uccSearchColums.map((item, index) => {
        if (item.dataField === 'action') {
            uccSearchColums[index] = { ...item, ...action }
        }
        return item
    })

    const filingAction = {
        formatter: (cell, row, rowIndex) => {
            if (row.status !== status.Not_Started)
                return (
                    <VisibilityIcon color='primary' onClick={() => {
                        const qs = { tab, drawer: 'filing', entityUUID: row.entityUuid, isDba: row.entityType === 'BUSINESS_DBA' };
                        replace(`?${queryString.stringify(qs)}`, 'filing')
                    }} />
                )
        }
    }
    UccFilingColums.map((item, index) => {
        if (item.dataField === 'action') {
            UccFilingColums[index] = { ...item, ...filingAction }
        }
        return item
    })

    const directAmendmentColums = DirectAmendmentColums.map(item => {
        if (item.dataField === 'action')
            item = {
                ...item, formatter: (cell, row) => {
                    return (
                        <VisibilityIcon color='primary' title='View Filing' onClick={() => handleView(row)} />
                    )
                }
            }
        return item
    })

    const handleView = (row) => {
        const { filingNumber, filingId } = row;
        const qs = { tab, filingNumber, filingId, drawer: 'amendment' };
        if (qs.filingNumber) delete qs.filingId
        replace(`?${queryString.stringify(qs)}`, 'amendment')
    }

    const performUccSearch = (filters = {}, data, hasFilters) => {
        let requestData, searchFilters = {};
        if (hasFilters) {
            setlastSearchedDetails({ filters, data })
            requestData = Object.entries(data).length ? data : undefined
            searchFilters = filters.search
            setStateUccSearch(getStateName(data.stateCode));
        } else {
            requestData = Object.entries(lastSearchedDetails.data).length ? lastSearchedDetails.data : undefined
            searchFilters = lastSearchedDetails.filters.search
        }
        setLoading(prev => ({ ...prev, uccSearch: true }))
        setSavedReport(prev => ({ ...prev, report: false }))
        const payLoad = {
            [searchId.entityName === 'BUSINESS' ? 'businessId' : 'ownerId']: searchId.id,
            requestData,
            ...searchFilters,
        }
        if (searchId.entityName === 'OWNER') delete payLoad.requestData.searchOrganizationName
        else {
            delete payLoad.requestData.searchFirstName
            delete payLoad.requestData.searchLastName
        }
        uccSearch(applicationId, payLoad).then((res) => {
            setTransactionID(res?.response?.Header.TransactionID)
            setInteractionId(res.uuid)
            setIntIdSearch(res.uuid)
        }).finally(() => setLoading(prev => ({ ...prev, uccSearch: false })))
    }

    const handleStatus = ({ type, uuid }, actionType) => {
        const action = actionType === 'finish' ? finishSearch : reopenSearch
        const payLoad = {
            [type === 'BUSINESS' ? 'businessId' : 'ownerId']: uuid,
        }
        action(applicationId, payLoad)
            .then(handleStatusChanged)
    }
    const getStateName = (stateCode) => {
        const stateName = statesOptions['USA'].find(state => state.value === stateCode)?.label
        return stateName;
    }

    const handleStatusChanged = () => {
        setSelectedRow([])
        getListOfUCC()
    }

    const handleSavedDetail = (row) => {
        const { type, uuid } = row || rowDetails
        setSelectedEntity({
            entityType: type,
            entityUuid: uuid
        })
        setLoading(prev => ({ ...prev, report: true }))
        getSavedDetail(applicationId, type, uuid)
            .then((res) => {
                setSavedReport(prev => ({ ...prev, report: true, detailedReport: true }))
                setOpenReport(true)
                setInteractionId(res.uuid)
            })
            .finally(() => setLoading(prev => ({ ...prev, report: false })))
    }


    const handleOpenPosting = () => {
        const getFirstRejectedFiling = filingSelectedRow.find(row => (row.status === strings.STATUS_NAMES.Rejected) || (row.status === strings.STATUS_NAMES.Preparation ))
        getUccFilingDetailsByEntity(null, null, true)
        if (getFirstRejectedFiling) {
            const index = []
            filingSelectedRow.forEach((row, i) => ((row.status === strings.STATUS_NAMES.Rejected) || (row.status === strings.STATUS_NAMES.Preparation )) && index.push(i))
            setSelectedFilingIndex(index)
            setLoading(prev => ({ ...prev, filingList: true }))
            getUccFilingDetailsByEntity(applicationId, getFirstRejectedFiling.entityUuid, false, {isDba: getFirstRejectedFiling?.entityType === 'BUSINESS_DBA'})
                .then(() => setOpenPosting(true))
                .finally(() => setLoading(prev => ({ ...prev, filingList: false })))
        }
        else setOpenPosting(true)
    }

    const performPostFiling = async (payLoad) => {
        setLoading(prev => ({ ...prev, filing: true, filingList: true }))
        postFiling(applicationId, payLoad).then((res) => {
            Toast({ type: "success", message: "Filing success" })
            setFilingSelectedRow([])
            setUccSearchId([])
            getUccFilingList(applicationId).finally(() => {
                setLoading(prev => ({ ...prev, filingList: false }))
            })
        }).finally(() => {
            setOpenPosting(false)
            setLoading(prev => ({ ...prev, filing: false, filingList: false }))
            replace(`?tab=${tab}`, 'reset')
            resetForm()
        })
    }

    const getFilingDetails = (filingNumbers, state, isSavedReports = true) => {
        const payLoad = {
            [searchId.entityName === 'BUSINESS' ? 'businessId' : 'ownerId']: searchId.id,
            transactionID,
            filingNumbers,
            state
        }
        setLoading(prev => ({ ...prev, detail: true }))
        return filingDetails(applicationId, payLoad).then((res) => {
            setLoading(prev => ({ ...prev, detail: false }))
            setInteractionId(res.uuid)
            setReportDetails(res)
            setSavedReport((prev) => ({ ...prev, report: isSavedReports, detailedReport: isSavedReports }))
            if (res?.response?.Results?.length) {
                setReportData(res.response.Results)
                setOpenDetailReport(true)
                if (interactionId) {
                    getRetrieveDocuments(interactionId)
                }
            }
        }).finally(() => {
            setLoading(prev => ({ ...prev, detail: false }))
        })
    }

    const saveFiling = (filingNumbers, state) => {
        const payLoad = {
            [searchId.entityName === 'BUSINESS' ? 'businessId' : 'ownerId']: searchId.id,
            transactionID,
            filingNumbers,
            state
        }
        setLoading(prev => ({ ...prev, save: true }))
        savefiling(applicationId, payLoad)
            .then(() => {
                getListOfUCC().then((res) => {
                    setLoading(prev => ({ ...prev, save: false }))
                })
                setOpenDetailReport(false)
                Toast({ type: 'success', message: t.UCC_SAVE_MSG })
                performUccSearch()
                updateSummaryCard()
            })
            .catch(() => setLoading(prev => ({ ...prev, save: false })))
    }

    const closeReportDrawer = () => {
        setOpenReport(false)
        resetForm()
        setSelectedRow([])
        uccSearch(null, null, true)
        getListOfUCC()
    }

    return (
        <div className='uccContainer'>
            {
                loading.ucc && (
                    <div className='tableLoader'>
                        <CircularProgress />
                    </div>
                )
            }
            <div className='uccTitleContainer'>
                <h5>{strings.UCC} {strings.SEARCH}</h5>
            </div>
            <TableComponent
                keyField='uuid'
                columns={uccSearchColums}
                data={uccList}
                showPagination={false}
                selectRow={!isReadOnlyUser ? selectRow : undefined}
            />
            {!isReadOnlyUser &&
                <div className='uccBtnContainer'>
                    <Button
                        variant='contained'
                        color='primary'
                        disabled={selectedRow?.length === 0 || loading.ucc}
                        onClick={() => {
                            setOpenReport(true)
                            setSavedReport(prev => ({ ...prev, report: false }))
                        }}
                    >
                        {
                            loading.ucc ? <CircularProgress size={24} color='inherit' /> : `${strings.PERFORM} ${strings.UCC} ${strings.SEARCH}`
                        }
                    </Button>
                </div>}

            <div className='uccTitleContainer'>
                <h5>{strings.UCC} {strings.FILING}</h5>
            </div>
            <TableComponent
                className='custom-filing-table'
                keyField='refId'
                columns={UccFilingColums}
                data={uccFilingList}
                showPagination={false}
                selectRow={!isReadOnlyUser ? filingSelectRow : undefined}
            />
            <div className='uccBtnContainer'>
                {!isReadOnlyUser && <Button
                    variant='contained'
                    color='primary'
                    onClick={handleOpenPosting}
                    disabled={filingSelectedRow.length === 0 || loading.filingList}
                >
                    {loading.filingList ? <CircularProgress size={24} color='inherit' /> : `${strings.PERFORM} ${strings.UCC} ${strings.FILING}`}
                </Button>}
            </div>

            <div className='uccTitleContainer'>
                <h5>{t.DIRECT_AMENDMENTS}</h5>
            </div>
            <TableComponent
                className='custom-filing-table mb-5'
                keyField='entityUuid'
                columns={directAmendmentColums}
                data={directAmendmentList}
                showPagination={false}
                loading={loading.amendmentList}
            />

            {searchId.id &&
                <ReportDrawer
                    open={openReport}
                    onClose={closeReportDrawer}
                    performUccSearch={(filters, data) => performUccSearch(filters, data, true)}
                    loading={loading.uccSearch}
                    getFilingDetails={getFilingDetails}
                    saveFiling={saveFiling}
                    savedReport={savedReport.report}
                    deleteReport={deleteReport}
                    detailLoading={loading.detail}
                    reportLoading={loading.report}
                    saveLoading={loading.save}
                    handleSubmit={handleSubmit}
                    entity={searchId.entityName}
                    data={uccSearchData}
                    terminationPreviewData={terminationPreviewData}
                    applicationId={applicationId}
                    handleSavedDetail={handleSavedDetail}
                    interactionId={intIdSearch}
                    downLoadAttachment={downLoadAttachment}
                    getTerminationPreview={getTerminationPreview}
                    terminateUCC={terminateUCC}
                    getJurisdictionList={getJurisdictionList}
                    getListOfDirectAmendments={getListOfDirectAmendments}
                    directAmendmentList={directAmendmentList}
                    getFilingReportsAttachments={getFilingReportsAttachments}
                    change={change}
                    selectedEntity={selectedEntity}
                    attachmentList={attachmentList}
                    totalPages={totalPages}
                    totalRecords={totalRecords}
                    dispatchProcessing={dispatchProcessing}
                    updateSummaryCard={updateSummaryCard}
                />}
            <DetailReportDrawer
                open={openDetailReport}
                onClose={() => {
                    setOpenDetailReport(false)
                }}
                data={reportData}
                stateUccSearch={stateUccSearch}
                applicationId={applicationId}
                reportDetails={reportDetails}
                retrieveDocument={retrieveDocument}
                getRetrieveDocuments={getRetrieveDocuments}
                interactionId={interactionId}
                savedReport={savedReport.detailedReport}
                downLoadAttachment={downLoadAttachment}
                downLoadAttachmentByFileId={downLoadAttachmentByFileId}
                getFilingReportsAttachments={getFilingReportsAttachments}
                selectedEntity={selectedEntity}
                uploadRoxFiles={uploadRoxFiles}
                dispatchProcessing={dispatchProcessing}
            />
            <UccPosting
                open={openPosting}
                onClose={() => {
                    resetForm()
                    setOpenPosting(false)
                    setTimeout(() => replace(`?tab=${tab}`, 'reset'), 100);
                }}
                state={state}
                countryCode={country}
                setState={setState}
                postFiling={(bodyData) => performPostFiling(bodyData)}
                UCCFileLoading={loading.filing}
                getJurisdictionList={getJurisdictionList}
                getTemplates={getTemplates}
                filingPreview={filingPreview}
                handleSubmit={handleSubmit}
                applicationId={applicationId}
                UccSearchId={UccSearchId}
                filingSelectedRow={filingSelectedRow}
                selectedFilingIndex={selectedFilingIndex}
                change={change}
                // Attachment props
                onSubmit={onSubmit}
                getFiles={getFiles}
                attachmentList={attachmentList}
                downLoadAttachment={downLoadAttachment}
                totalPages={totalPages}
                totalRecords={totalRecords}
                getAttachmentPDF={getAttachmentPDF}
            />
            <InfoView
                state={state}
                open={openFiledInfo.open}
                onClose={() => {
                    setOpenFiledInfo({ open: false })
                    setTimeout(() => replace(`?tab=${tab}`, 'reset'), 100);
                }}
                retryFiling={() => {
                    setOpenPosting(true)
                    setOpenFiledInfo({ open: false })
                }}
                requestUccFilingImage={requestUccFilingImage}
                downloadFileByPayload={downloadFileByPayload}
                downLoadAttachment={downLoadAttachment}
                applicationId={applicationId}
                filingSelectedRow={filingSelectedRow}
                requestUCCForceUpdate={requestUCCForceUpdate}
                getUccFilingDetailsByFilingId={getUccFilingDetailsByFilingId}
                getUccFilingDetailsByFilingNumber={getUccFilingDetailsByFilingNumber}
                getAttachmentPDF={getAttachmentPDF}
                isUCC3={openFiledInfo.ucc3}
                ucc3Data={ucc3Data}
                viewMode={'fromUcc'}
                hideAmendmentHistory
                hideDetails={openFiledInfo.hideDetails}
                loading={loading.viewAmendment}
                children={openFiledInfo.hideDetails &&
                    <DiffView
                        data={openFiledInfo.data}
                        ucc3Preview={ucc3Data}
                    />}
            />
        </div>
    )
}

export default Ucc