import React from 'react'
import PropTypes from 'prop-types'

export const ValidationContext = React.createContext()

const ShowFormValidation = ({ children, on }) => {
  return (
    <ValidationContext.Provider value={on}>
      {children}
    </ValidationContext.Provider>
  )
}
ShowFormValidation.propTypes = {
  children: PropTypes.node,
  on: PropTypes.oneOf(['submit', 'change']) // defaults to 'change'
}

export default ShowFormValidation
