import React, { useCallback, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InfoIcon from '@material-ui/icons/Info';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import FilterBarModule from 'components/shared/FilterBarModule';
import TableComponent from 'components/shared/TableComponent';
import { InputField, DropdownField } from 'components/forms/reduxForm';
import Drawer, { DrawerAction } from 'components/shared/Drawer';
import { Toast } from 'components/shared/Toaster';
import { Dialog } from 'components/shared/Dialog';
import Loader from 'components/shared/Loader';
import { Icon } from 'styles';
import { authorizationColumns, customerColumns, platformPartnersColumns } from 'constants/roxAdminSettings';
import strings from 'strings';

const t = strings.PP
function PlatformPartner(props) {
    const {
        handleSubmit,
        getRolesList,
        getClientsList,
        getPlatformPartners,
        createPlatformPartner,
        togglePlatformPartner,
        getAuthorizations,
        createAuthorization,
        toggleAuthorizations,
        resetPassword,
        dispatchPlatformPartnerFormValues,

        userRoles,
        plaformPartnerList,
        totalRecords,
        totalPages,
        currentPage,
        clientsList,
        clientsTotalPages,
        clientsTotalRecords,
        authorizationList
    } = props
    const [drawer, setDrawer] = useState({ open: false, type: '', selectedId: null, name: '' })
    const [loading, setLoading] = useState({ list: false, submit: false, clientsList: false, authorization: false, reset: false })
    const [selectedRow, setSelectedRow] = useState([])
    const [expand, setExpand] = useState(false)
    const [dialog, setDialog] = useState({ open: false, password: '' })
    const [platformPartnersColumn, setPlatformPartnersColumn] = useState(() => {
        const action = {
            formatter: (cell, row) => (
                <>
                    <span className='rounded-icons mr-1'>
                        <SettingsIcon
                            color='primary'
                            titleAccess='Configure'
                            onClick={() => {
                                setDrawer({ open: true, type: 'authorization', selectedId: row.uuid, name: row.name })
                                dispatchPlatformPartnerFormValues(row)
                                setLoading(prev => ({ ...prev, authorization: true }))
                                getAuthorizations(row.uuid)
                                    .finally(() => setLoading(prev => ({ ...prev, authorization: false })))
                            }} />
                    </span>
                    <span className='rounded-icons'>
                        {row.active ?
                            <NotInterestedOutlinedIcon titleAccess='Disable' color='secondary' onClick={() => enableOrDisablePartner(row)} />
                            :
                            <PersonOutlinedIcon titleAccess='Enable' onClick={() => enableOrDisablePartner(row)} />}
                    </span>
                </>
            )
        }
        platformPartnersColumns.map((item, index) => {
            if (item.dataField === 'action')
                platformPartnersColumns[index] = { ...item, ...action }
            return item
        })
        return platformPartnersColumns
    })

    const getList = useCallback((pagination) => {
        setLoading(prev => ({ ...prev, list: true }))
        getPlatformPartners(pagination).finally(() => setLoading(prev => ({ ...prev, list: false })))
    }, [getPlatformPartners])

    const getListOfClients = useCallback((pagination) => {
        setLoading(prev => ({ ...prev, clientsList: true }))
        getClientsList({}, { ...pagination, limit: 5 }).finally(() => setLoading(prev => ({ ...prev, clientsList: false })))
    }, [getClientsList])

    useEffect(() => {
        getList()
        getListOfClients()
        getRolesList()
    }, [getList, getListOfClients, getRolesList]);

    useEffect(() => {
        if (drawer.open) {
            setSelectedRow([])
            dispatchPlatformPartnerFormValues({})
            getListOfClients()
            setExpand(false)
            setDialog({ open: false, password: '' })
        }
    }, [drawer.open, dispatchPlatformPartnerFormValues, getListOfClients]);

    const enableOrDisablePartner = ({ uuid, active }) => {
        setLoading(prev => ({ ...prev, list: true }))
        togglePlatformPartner(uuid, active)
            .then(() => getList())
            .catch(() => setLoading(prev => ({ ...prev, list: false })))
    }

    const enableOrDisableauthorization = ({ uuid, active }) => {
        setLoading(prev => ({ ...prev, authorization: true }))
        toggleAuthorizations(drawer.selectedId, uuid, active)
            .then(() => getAuthorizations(drawer.selectedId))
            .finally(() => setLoading(prev => ({ ...prev, authorization: false })))
    }

    const handleReset = ({ uuid }) => {
        setLoading(prev => ({ ...prev, reset: true }))
        setDialog(prev => ({ ...prev, open: true }))
        resetPassword(drawer.selectedId, uuid)
            .then(res => setDialog({ open: true, password: res.serviceAccount.password }))
            .finally(() => setLoading(prev => ({ ...prev, reset: false })))
    }

    const handlePlatformPartner = (values) => {
        const payLoad = { ...values }
        let action = createPlatformPartner

        if (drawer.type === 'authorization') {
            payLoad.roles = [values.roles]
            payLoad.clientId = selectedRow[0]
            action = createAuthorization
            if (!values?.roles)
                return Toast({ type: 'error', message: t.ERROR_MSG })
        }

        setLoading(prev => ({ ...prev, submit: true }))
        action(payLoad, drawer.selectedId)
            .then(res => {
                setDrawer({ open: false, type: '' })
                if (drawer.type === 'authorization')
                    setDialog({ open: true, password: res.serviceAccount.password })
                else
                    getList()
            })
            .finally(() => setLoading(prev => ({ ...prev, submit: false })))
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect)
            setSelectedRow([row.clientId])
        else
            setSelectedRow([])
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: selectedRow,
        onSelect: handleOnSelect,
        hideSelectAll: true,
        nonSelectable: authorizationList.map(({ client }) => client.clientId),
    }

    const columns = customerColumns(true).filter(col => !['createdAt', 'updatedAt', 'action'].includes(col.dataField))
    const clientColumns = () => {
        const action = {
            formatter: (cell, row) => (
                <>
                    <span className='rounded-icons mr-1'>
                        {row.active ?
                            <NotInterestedOutlinedIcon titleAccess='Disable' color='secondary' onClick={() => enableOrDisableauthorization(row)} /> :
                            <PersonOutlinedIcon titleAccess='Enable' color='primary' onClick={() => enableOrDisableauthorization(row)} />}
                    </span>
                    <span className='rounded-icons'>
                        <RotateLeftIcon titleAccess='Reset Password' onClick={() => handleReset(row)} />
                    </span>
                </>
            )
        }

        return authorizationColumns.map((item, index) => {
            if (item.dataField === 'action')
                item = { ...item, ...action }
            return item
        })
    }

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <h4 className='mb-0'>{t.PLATFORM_PARTNERS}</h4>
                <Button
                    color='primary'
                    variant='contained'
                    onClick={() => setDrawer({ open: true, type: 'add' })}
                >
                    {t.ADD_PLATFORM_PARTNER}
                </Button>
            </div>

            <div className='tabPanel'>
                <FilterBarModule
                    title={t.PLATFORM_PARTNERS}
                    // TODO add filter
                    // filters={savedFilter}
                    columns={platformPartnersColumn}
                    receiveCustomColumn={(columns) => setPlatformPartnersColumn(columns)}
                    filterParams={(params) => console.log('filter params', params)}
                />
                <TableComponent
                    className='custom-table'
                    data={plaformPartnerList}
                    columns={platformPartnersColumn}
                    keyField='uuid'
                    totalRecords={totalRecords}
                    totalPages={totalPages}
                    onChangePagination={getList}
                    loading={loading.list}
                    currentPage={currentPage}
                />
            </div>

            <Drawer
                open={drawer.open}
                onClose={() => setDrawer({ open: false, type: '' })}
                width={drawer.type === 'add' ? 'small' : 'large'}
                title={
                    <div className='ml-2'>
                        {drawer.type === 'authorization' ? <>
                            <h5 className='mb-0'>{t.CONFIGURE}</h5>
                            <span>{t.PLATFORM_PARTNER_NAME} : </span>
                            <span className='font-weight-bold'>{drawer.name}</span>
                        </> :
                            <h5 className='mb-0'>{t.ADD_PLATFORM_PARTNER}</h5>}
                    </div>
                }
            >
                {loading.authorization ?
                    <Loader />
                    :
                    <>
                        <div className='p-3 mb-5 pb-3'>
                            {drawer.type === 'authorization' ? <>
                                <div className='d-flex justify-content-end'>
                                    <Button
                                        variant='outlined'
                                        color='primary'
                                        onClick={() => setExpand(prev => !prev)}
                                        endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        className='mb-1'
                                    >
                                        {t.ADD_AUTHORIZATION}
                                    </Button>
                                </div>
                                {expand && <TableComponent
                                    className='custom-table custom-table-checkbox'
                                    keyField='clientId'
                                    selectRow={selectRow}
                                    data={clientsList}
                                    columns={columns}
                                    totalRecords={clientsTotalRecords}
                                    totalPages={clientsTotalPages}
                                    onChangePagination={getListOfClients}
                                    loading={loading.clientsList}
                                    paginationLimit={5}
                                    showLimitOptions={false}
                                />}

                                {selectedRow.length ?
                                    <div className='mb-4'>
                                        <div className='hint mb-1'>{t.HINT}</div>
                                        <DropdownField
                                            name="roles"
                                            label={strings.LABEL.ROLES}
                                            options={userRoles}
                                            required
                                            menuPlacement='top'
                                        />
                                    </div> : null}

                                <div className='mb-1 heading-style-1'>{t.AUTHORIZATION_LIST}</div>
                                <TableComponent
                                    className='custom-table mb-5 pb-5'
                                    keyField='uuid'
                                    data={authorizationList}
                                    columns={clientColumns()}
                                    showPagination={false}
                                />
                            </> :
                                <InputField name='name' label={strings.NAME} required />
                            }
                        </div>

                        {selectedRow.length || drawer.type !== 'authorization' ? <DrawerAction>
                            <Button
                                onClick={handleSubmit(handlePlatformPartner)}
                                color='primary'
                                variant='contained'
                                disabled={loading.submit}
                            >
                                {loading.submit ?
                                    <CircularProgress size={24} color='inherit' /> :
                                    `${drawer.type === 'authorization' ? 'update' : 'add'} ${t.PLATFORM_PARTNER}`}
                            </Button>
                        </DrawerAction> : null}
                    </>}
            </Drawer>

            <Dialog fullWidth maxWidth='sm' open={dialog.open} onClose={() => setDialog({ open: false, password: '' })}>
                <DialogTitle>
                    {!loading.reset && t.PASSWORD}
                </DialogTitle>
                <DialogContent className='d-flex align-items-center justify-content-center flex-column'>
                    {loading.reset ?
                        <Loader /> :
                        <>
                            <Icon folder='icons' fileName='revealPassword.svg' />
                            <div className='text-center'>
                                <InfoIcon color='primary' />
                                <span>{t.PASSWORD_HINT1}</span>
                                <div>{t.PASSWORD_HINT2}</div>
                            </div>
                            <div className='mt-4'>
                                <span className='preview-label'>{t.YOUR_PASSWORD} : </span>
                                <span className='preview-value font-weight-bold'>{dialog.password}</span>
                                <FileCopyIcon
                                    className='ml-1 cursor-pointer'
                                    color='primary'
                                    titleAccess='Copy to clipboard'
                                    fontSize='small'
                                    onClick={() => {
                                        navigator.clipboard.writeText(dialog.password);
                                        Toast({ type: 'success', message: t.COPY_MSG })
                                    }}
                                />
                            </div>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => setDialog({ open: false, password: '' })}>{strings.CLOSE}</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default reduxForm({
    form: 'platformPartner',
    enableReinitialize: true
})(PlatformPartner)