import React, { useCallback, useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import queryString from 'query-string';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {useDispatch, useSelector} from 'react-redux';
import {SET_FORM_VALUES} from 'actions/applicants';
import { InfoView } from '../partial/Tabs/UCC/views/infoView';
import FilterBarModule from 'components/shared/FilterBarModule';
import TableComponent from 'components/shared/TableComponent';
import Loader from 'components/shared/Loader';
import Drawer from 'components/shared/Drawer';
import { Tab, Tabs } from 'components/shared/Tabs';
import { clientabs } from 'constants/constantVariables';
import { clientFilter, activeColumns } from 'constants/application';
import { UccOperations } from '../partial/Drawer/UccEdit';
import { DiffView } from '../partial/Drawer/DiffView';
import { Icon } from 'styles';
import strings from 'strings';
import AuthService from 'tools/AuthService';
import './client.scss'
import {Button} from "@material-ui/core";
import {Toast} from "../../shared/Toaster";

const CLIENT_STRING = strings.APPLICANT.CLIENTS
let renderOnLoad = true
function Clients(props) {
    const {
        getFilingUrlParams,
        continueUCC,
        terminateUCC,
        activeList,
        uccFilters,
        getActiveList,
        activetTotalRecords,
        activeTotalPages,
        activeCurrentPage,
        ucc3Preview,
        amendmentPreview,
        requestUccFilingImage,
        getAttachmentPDF,
        downloadFileByPayload,
        getDebtors,
        getUccFilingDetailsByFilingNumber,
        getUccFilingDetailsByFilingId,
        debtors: { debtors, securedParties, collateral, jurisdictionId, jurisdiction },
        handleSubmit,
        reset,
        history,
        location,
        performAmendmentOperation,
        performCollateralOperation,
        getTemplates,
        change,
        // Attachments Props
        uploadTempFile,
        getTempFiles,
        tempFileList,
        downLoadAttachment,
        downLoadAttachmentByFileId,
        tempFileTotalRecords,
        requestUCCForceUpdate,
        tempFileTotalPages,
        getJurisdictionList,
        getJurisdictionsSiblings,
        dispatchUccFilters, // To set ucc filters in redux
        sprs // secured party masking retrieved from client configuration
    } = props
    const currentIndex = clientabs.findIndex(t => t.url === `?tab=${queryString.parse(location.search).tab}`)
    const [appTabValue, setAppTabValue] = useState(currentIndex > 0 ? currentIndex : 0);
    const [loading, setLoading] = useState({
        continuation: false,
        amendments: false,
        active: false,
        debtors: false,
        submitTermination: false,
        preview: false,
        ucc1Preview: false,
        amendmentView: false
    })
    const [uccThreeSelectOpDrawer, setUccThreeSelectOpDrawer] = useState({ open: false, operation: '' })
    const [filingNumber, setFilingNumber] = useState(null)
    const [state, setState] = useState('')
    const uccFilingSelected = useSelector(state => state.applicant.clients.ucc3Preview);
    const [openPreview, setOpenPreview] = useState({ open: false })
    const [openUcc1View, setOpenUcc1View] = useState(false)
    const [openAmendmentView, setOpenAmendmentView] = useState({ open: false })
    const dispatch = useDispatch();
    const { tab } = queryString.parse(location.search)
    const isReadOnlyUser = AuthService.isReadOnlyUser();
    const hideDetails = !ucc3Preview?.amendmentType?.includes('NO')
    const action = (hidecontinuation) => {
        return {
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className='d-flex'>
                        {!isReadOnlyUser &&
                            <Icon
                                className='icons red mr-3'
                                title='Termination'
                                folder='icons'
                                fileName='stop.svg'
                                onClick={() => handleOnClick(row, 'termination')}
                            />}
                        {(!isReadOnlyUser && tab !== 'expired') &&
                            <Icon
                                className='icons yellow mr-3'
                                title='Amendment'
                                folder='icons'
                                fileName='editform.svg'
                                onClick={() => handleOnClick(row, 'amendment')}
                            />}
                        {!isReadOnlyUser && !hidecontinuation &&
                            <Icon
                                className='icons green mr-3'
                                folder='icons'
                                fileName='check-double.svg'
                                onClick={() => {
                                    handleOnClick(row, 'continuation')
                                }}
                                title='Continuation'
                            />
                        }
                        <VisibilityOutlinedIcon className='icons rounded-icons' color='primary' title='View Filing' onClick={() => handleView(row)} />
                    </div>
                )
            }
        }
    }
    const amendmentAction = {
        formatter: (cell, row, rowIndex) => {
            return (
                <div className='d-flex'>
                    <VisibilityOutlinedIcon color='primary' title='View Filing' onClick={() => handleView(row)} />
                </div>
            )
        }
    }
    const [columns, setColumns] = useState(() => {
        return activeColumns().map((item, index) => {
            if (item.dataField === 'action')
                item = { ...item, headerStyle: { width: '12rem' }, ...action(false) }
            return item
        }).filter(({ dataField }) => dataField !== 'amendmentAction' && dataField !== 'creationDate' && dataField !== 'filingType')
    })
    const [activeColumn, setActiveColumn] = useState(() => {
        return activeColumns().map((item, index) => {
            if (item.dataField === 'action')
                item = { ...item, ...action(true) }
            return item
        }).filter(({ dataField }) => dataField !== 'amendmentAction' && dataField !== 'creationDate' && dataField !== 'filingType')
    })
    const awaiting_successfull_columns = (hideToolTip = true) => {
        return activeColumns(hideToolTip).map((item, index) => {
            if (item.dataField === 'action')
                item = { ...item, ...amendmentAction }
            return item
        })
    }
    const [amendmentColumn, setAmendmentColumn] = useState(awaiting_successfull_columns(false).filter(({ dataField }) => dataField !== 'amendmentAction' && dataField !== 'fileDate' && dataField !== 'filingNumber'))
    const [successColumn, setSuccessColumn] = useState(awaiting_successfull_columns().filter(({ dataField }) => dataField !== 'debtorName' && dataField !== 'creationDate' && dataField !== 'filingType' && dataField !== 'filingType'))
    const [terminatedColumn, setTerminatedColumn] = useState(awaiting_successfull_columns().filter(({ dataField }) => dataField !== 'amendmentAction' && dataField !== 'creationDate' && dataField !== 'filingType'))
    const [rejectedColumn, setRejectedColumn] = useState(awaiting_successfull_columns().filter(({ dataField }) => dataField !== 'filingNumber' && dataField !== 'lapseDate' && dataField !== 'fileDate' && dataField !== 'debtorName' && dataField !== 'filingType'))

    const getActiveApplications = useCallback((pagination, filters = {}) => {
        setLoading(prev => ({ ...prev, active: true }))
        getActiveList(pagination, filters).finally(() => setLoading(prev => ({ ...prev, active: false })))
    }, [getActiveList])

    const callApiBasedOnTab = useCallback((pagination = {}, currentIndex, filters = {}, searchValue = '') => {
        dispatchUccFilters(filters, searchValue)
        const urlParam = getFilingUrlParams(currentIndex, searchValue)
        const params = new URLSearchParams(`?${urlParam}`);
        const obj = Object.fromEntries(params.entries());
        const filterPayload = { ...obj, ...filters }
        getActiveApplications(pagination, filterPayload)
        // eslint-disable-next-line
    }, [getActiveApplications])

    // clears the quick search data from redux and callApiBasedOnTab will trigger based on the freeSearch dependency
    const handleClearQuickSearch = () => {
        dispatchUccFilters(uccFilters?.filterObject, '')
    }

    const handleQuickSearch = (searchValue) => {
        callApiBasedOnTab({}, appTabValue, uccFilters?.filterObject, searchValue)
    }

    useEffect(() => {
        const { filingNumber, filingId } = queryString.parse(location.search)

        // if filingNumber or filingId is present in the url, then open the preview drawer
        if (filingNumber || filingId) {
            setLoading(prev => ({ ...prev, preview: true }))
            setOpenPreview({ open: true });
            (filingNumber ? getUccFilingDetailsByFilingNumber(filingNumber) : getUccFilingDetailsByFilingId(filingId))
                .catch(() => setOpenPreview({ open: false }))
                .finally(() => setLoading(prev => ({ ...prev, preview: false })))
        }

        if (renderOnLoad || location.state === 'menu') {
            callApiBasedOnTab({}, appTabValue)
            renderOnLoad = false
        }
    }, [location, callApiBasedOnTab, appTabValue, getUccFilingDetailsByFilingNumber, getUccFilingDetailsByFilingId]);
    
    const handleOnClick = (row, operation) => {
        if (operation === 'continuation') {
            dispatch({ type: SET_FORM_VALUES, data: { securedPartyMasking: sprs } })
        }
        setUccThreeSelectOpDrawer({ open: true, operation })
        setLoading(prev => ({ ...prev, debtors: true, termination: true }))
        setFilingNumber(row.filingNumber)
        setState(row.state)
        getDebtors(row.filingNumber)
            .catch(() => setUccThreeSelectOpDrawer({ open: false, operation: '' }))
            .finally(() => setLoading(prev => ({ ...prev, debtors: false, termination: false })))
    }

    const handleView = (row) => {
        const { filingNumber, filingId } = row
        //Why qs?
        const qs = { tab, filingNumber, filingId };
        if (filingNumber) delete qs.filingId
        history.replace(`?${queryString.stringify(qs)}`, 'view')
    }

    const requestUCCUpdate = () => {
        requestUCCForceUpdate(uccFilingSelected.filingId).then((res) => {
            Toast({ type: "info", message: res.message })
        })
    }

    const getDetails = rowData => {
        const { fileNumber, filingId } = rowData
        setLoading(prev => ({ ...prev, amendmentView: true }));
        setOpenAmendmentView({ open: true });
        (fileNumber ? getUccFilingDetailsByFilingNumber(fileNumber, 'amendment') : getUccFilingDetailsByFilingId(filingId, 'amendment'))
            .catch(() => setOpenAmendmentView({ open: false }))
            .finally(() => setLoading(prev => ({ ...prev, amendmentView: false })))
    }

    const showUCC1View = initialFilingNumber => {
        setLoading(prev => ({ ...prev, ucc1Preview: true }))
        setOpenUcc1View(true)
        getUccFilingDetailsByFilingNumber(initialFilingNumber, 'ucc1')
            .catch(() => setOpenUcc1View(false))
            .finally(() => setLoading(prev => ({ ...prev, ucc1Preview: false })))
    }

    const handleChange = (event, newValue) => {
        setAppTabValue(newValue);
        history.replace(clientabs[newValue].url, 'tab');
        callApiBasedOnTab({}, newValue, uccFilters.filterObject, '');
        renderOnLoad = true;
    };

    return (
        <>
            <h4>{CLIENT_STRING.UCCS}</h4>

            <Tabs
                data={clientabs}
                value={appTabValue}
                onChange={handleChange}
            />
            <Tab value={appTabValue} index={0}>
                <div className='tabPanel'>
                    <FilterBarModule
                        title={CLIENT_STRING.ACTIVE_FILINGS}
                        filters={clientFilter}
                        columns={activeColumn}
                        receiveCustomColumn={(columns) => setActiveColumn(columns)}
                        filterParams={(params) => callApiBasedOnTab({}, appTabValue, params, uccFilters.freeSearch)}
                        placeHolder={CLIENT_STRING.PLACEHOLDER}
                        onSearch={handleQuickSearch}
                        clearSearch={handleClearQuickSearch}
                        defaultFilterValues={uccFilters}
                    />
                    <TableComponent
                        className='custom-table'
                        data={activeList}
                        keyField='filingId'
                        sort={true}
                        columns={activeColumn}
                        totalRecords={activetTotalRecords}
                        totalPages={activeTotalPages}
                        onChangePagination={(pagination) => callApiBasedOnTab(pagination, appTabValue, uccFilters.filterObject, uccFilters.freeSearch)}
                        loading={loading.active}
                        currentPage={activeCurrentPage}
                        remoteSorting
                    />
                </div>
            </Tab>
            <Tab value={appTabValue} index={1}>
                <div className='tabPanel'>
                    <FilterBarModule
                        title={CLIENT_STRING.CONTINUATION_LISTING}
                        filters={clientFilter}
                        columns={columns}
                        receiveCustomColumn={(columns) => setColumns(columns)}
                        filterParams={(params) => callApiBasedOnTab({}, appTabValue, params, uccFilters.freeSearch)}
                        placeHolder={CLIENT_STRING.PLACEHOLDER}
                        defaultFilterValues={uccFilters}
                        onSearch={handleQuickSearch}
                        clearSearch={handleClearQuickSearch}
                    />
                    <TableComponent
                        className='custom-table'
                        data={activeList}
                        keyField='filingId'
                        sort={true}
                        columns={columns}
                        totalRecords={activetTotalRecords}
                        totalPages={activeTotalPages}
                        onChangePagination={(pagination) => callApiBasedOnTab(pagination, appTabValue, uccFilters.filterObject, uccFilters.freeSearch)}
                        loading={loading.active}
                        currentPage={activeCurrentPage}
                        remoteSorting
                    />
                </div>
            </Tab>
            <Tab value={appTabValue} index={2}>
                <div className='tabPanel'>
                    <FilterBarModule
                        title={CLIENT_STRING.EXPIRED}
                        filters={clientFilter}
                        columns={columns}
                        receiveCustomColumn={(columns) => setColumns(columns)}
                        filterParams={(params) => callApiBasedOnTab({}, appTabValue, params, uccFilters.freeSearch)}
                        placeHolder={CLIENT_STRING.PLACEHOLDER}
                        defaultFilterValues={uccFilters}
                        onSearch={handleQuickSearch}
                        clearSearch={handleClearQuickSearch}
                    />
                    <TableComponent
                        className='custom-table'
                        data={activeList}
                        keyField='filingId'
                        sort={true}
                        columns={columns}
                        totalRecords={activetTotalRecords}
                        totalPages={activeTotalPages}
                        onChangePagination={(pagination) => callApiBasedOnTab(pagination, appTabValue, uccFilters.filterObject, uccFilters.freeSearch)}
                        loading={loading.active}
                        currentPage={activeCurrentPage}
                        remoteSorting
                    />
                </div>
            </Tab>
            <Tab value={appTabValue} index={3}>
                <div className='tabPanel'>
                    <FilterBarModule
                        title={CLIENT_STRING.WAITING_RESPONSE}
                        filters={clientFilter}
                        columns={amendmentColumn}
                        receiveCustomColumn={(columns) => setAmendmentColumn(columns)}
                        filterParams={(params) => callApiBasedOnTab({}, appTabValue, params, uccFilters.freeSearch)}
                        placeHolder={CLIENT_STRING.PLACEHOLDER}
                        defaultFilterValues={uccFilters}
                        onSearch={handleQuickSearch}
                        clearSearch={handleClearQuickSearch}
                    />
                    <TableComponent
                        data={activeList}
                        sort={true}
                        keyField='filingId'
                        columns={amendmentColumn}
                        totalRecords={activetTotalRecords}
                        totalPages={activeTotalPages}
                        onChangePagination={(pagination) => callApiBasedOnTab(pagination, appTabValue, uccFilters.filterObject, uccFilters.freeSearch)}
                        loading={loading.active}
                        currentPage={activeCurrentPage}
                        remoteSorting
                    />
                </div>
            </Tab>
            <Tab value={appTabValue} index={4}>
                <div className='tabPanel'>
                    <FilterBarModule
                        title={CLIENT_STRING.SUCCESSFULL_AMENDMENTS}
                        filters={clientFilter}
                        columns={successColumn}
                        receiveCustomColumn={(columns) => setSuccessColumn(columns)}
                        filterParams={(params) => callApiBasedOnTab({}, appTabValue, params, uccFilters.freeSearch)}
                        placeHolder={CLIENT_STRING.PLACEHOLDER}
                        defaultFilterValues={uccFilters}
                        onSearch={handleQuickSearch}
                        clearSearch={handleClearQuickSearch}
                    />
                    <TableComponent
                        data={activeList}
                        sort={true}
                        keyField='filingId'
                        columns={successColumn}
                        totalRecords={activetTotalRecords}
                        totalPages={activeTotalPages}
                        onChangePagination={(pagination) => callApiBasedOnTab(pagination, appTabValue, uccFilters.filterObject, uccFilters.freeSearch)}
                        loading={loading.active}
                        currentPage={activeCurrentPage}
                        remoteSorting
                    />
                </div>
            </Tab>
            <Tab value={appTabValue} index={5}>
                <div className='tabPanel'>
                    <FilterBarModule
                        title={CLIENT_STRING.REJECTED}
                        filters={clientFilter}
                        columns={rejectedColumn}
                        receiveCustomColumn={(columns) => setRejectedColumn(columns)}
                        filterParams={(params) => callApiBasedOnTab({}, appTabValue, params, uccFilters.freeSearch)}
                        placeHolder={CLIENT_STRING.PLACEHOLDER}
                        defaultFilterValues={uccFilters}
                        onSearch={handleQuickSearch}
                        clearSearch={handleClearQuickSearch}
                    />

                    <TableComponent
                        data={activeList}
                        sort={true}
                        columns={rejectedColumn}
                        keyField='filingId'
                        totalRecords={activetTotalRecords}
                        totalPages={activeTotalPages}
                        onChangePagination={(pagination) => callApiBasedOnTab(pagination, appTabValue, uccFilters.filterObject, uccFilters.freeSearch)}
                        loading={loading.active}
                        currentPage={activeCurrentPage}
                        remoteSorting
                    />
                </div>
            </Tab>
            <Tab value={appTabValue} index={6}>
                <div className='tabPanel'>
                    <FilterBarModule
                        title={CLIENT_STRING.TERMINATION}
                        filters={clientFilter}
                        columns={terminatedColumn}
                        receiveCustomColumn={(columns) => setTerminatedColumn(columns)}
                        filterParams={(params) => callApiBasedOnTab({}, appTabValue, params, uccFilters.freeSearch)}
                        placeHolder={CLIENT_STRING.PLACEHOLDER}
                        defaultFilterValues={uccFilters}
                        onSearch={handleQuickSearch}
                        clearSearch={handleClearQuickSearch}
                    />
                    <TableComponent
                        className='custom-table'
                        data={activeList}
                        keyField='filingId'
                        sort={true}
                        columns={terminatedColumn}
                        totalRecords={activetTotalRecords}
                        totalPages={activeTotalPages}
                        onChangePagination={(pagination) => callApiBasedOnTab(pagination, appTabValue, uccFilters.filterObject, uccFilters.freeSearch)}
                        loading={loading.active}
                        currentPage={activeCurrentPage}
                        remoteSorting
                    />
                </div>
            </Tab>
            <UccOperations
                getJurisdictionList={getJurisdictionList}
                getJurisdictionsSiblings={getJurisdictionsSiblings}
                debtors={debtors}
                change={change}
                jurisdictionState={state}
                securedParties={securedParties}
                jurisdiction={jurisdiction}
                jurisdictionId={jurisdictionId}
                filingNumber={filingNumber}
                collateral={collateral}
                loading={loading.debtors}
                uccFilingsData={props.debtors}
                closeDrawer={() => setUccThreeSelectOpDrawer({ open: false })}
                handleSubmit={handleSubmit}
                resetForm={() => reset('ClientForm')}
                performAmendmentOperation={performAmendmentOperation}
                performCollateralOperation={performCollateralOperation}
                operationDrawer={uccThreeSelectOpDrawer}
                terminateUCC={terminateUCC}
                refreshList={() => callApiBasedOnTab({}, appTabValue)}
                continueUCC={continueUCC}
                getTemplates={getTemplates}
                sprs={sprs}
                // Attachment props
                onSubmit={uploadTempFile}
                getFiles={getTempFiles}
                downLoadAttachment={downLoadAttachment}
                tempFileList={tempFileList}
                tempFileTotalRecords={tempFileTotalRecords}
                tempFileTotalPages={tempFileTotalPages}
            />

            <Drawer
                open={openPreview.open}
                onClose={() => {
                    setOpenPreview({ open: false })
                    setTimeout(() => history.replace(clientabs[appTabValue].url, 'reset'), 100);
                }}
                title={
                <div className='ucc-drawer-upper-container'>
                    <h4 className='ucc-drawer-title'>{strings.APPLICANT.UCCFILING.FILING_DETAILS}</h4>
                    {!AuthService.isReadOnlyUser() &&
                        <div className='ucc-drawer-right-side'>
                            <Button className='ucc-drawer-update-button'
                                    onClick={() => requestUCCUpdate()}
                                    color='primary'
                                    variant='contained'>Request Update</Button>
                        </div>
                    }
                </div>
                }

            >
                {loading.preview ? <Loader /> :
                    <>
                        {hideDetails &&
                            <DiffView
                                data={openPreview.data}
                                filingNumber={filingNumber}
                                ucc3Preview={ucc3Preview}
                                lastUpdated={uccFilingSelected?.lastUpdated}
                                downLoadAttachmentByFileId={downLoadAttachmentByFileId}
                            />}
                            {/* This Info View is open when view button is clicked*/}
                        <InfoView
                            component='preview'
                            downloadFileByPayload={downloadFileByPayload}
                            downLoadAttachment={downLoadAttachment}
                            downLoadAttachmentByFileId={downLoadAttachmentByFileId}
                            isUCC3
                            getUccFilingDetailsByFilingNumber={getUccFilingDetailsByFilingNumber}
                            hideDetails={hideDetails}
                            getUccFilingDetailsByFilingId={getUccFilingDetailsByFilingId}
                            requestUccFilingImage={requestUccFilingImage}
                            ucc3Data={ucc3Preview}
                            getAttachmentPDF={getAttachmentPDF}
                            lastUpdated={uccFilingSelected?.lastUpdated}
                            openUcc1View={showUCC1View}
                            viewMode={'fromClients'}
                            hideAmendmentHistory={appTabValue > 1}
                            showAmendment={getDetails}
                        />
                    </>}
            </Drawer>

            <Drawer
                open={openAmendmentView.open}
                onClose={() => setOpenAmendmentView({ open: false })}
                title={strings.APPLICANT.UCCFILING.PREVIEW}
            >
                {loading.amendmentView ? <Loader /> : <>
                    <DiffView
                        data={openAmendmentView.data}
                        lastUpdated={uccFilingSelected?.lastUpdated}
                        filingNumber={filingNumber}
                        ucc3Preview={amendmentPreview}
                    />
                    {/* This Info View is open inside the amendment view */}
                    <InfoView
                        component='preview'
                        downloadFileByPayload={downloadFileByPayload}
                        getUccFilingDetailsByFilingNumber={getUccFilingDetailsByFilingNumber}
                        isUCC3
                        requestUccFilingImage={requestUccFilingImage}
                        getUccFilingDetailsByFilingId={getUccFilingDetailsByFilingId}
                        ucc3Data={amendmentPreview}
                        getAttachmentPDF={getAttachmentPDF}
                        hideAmendmentHistory
                        openUcc1View={showUCC1View}
                        hideDetails
                    />
                </>}
            </Drawer>

            <Drawer
                open={openUcc1View}
                onClose={() => setOpenUcc1View(false)}
                title={strings.APPLICANT.UCCFILING.PREVIEW}
            >
                {loading.ucc1Preview ? <Loader /> :
                // This info view is open inside info views of preview and amendment view
                    <InfoView component='preview'
                              getUccFilingDetailsByFilingNumber={getUccFilingDetailsByFilingNumber}
                              downloadFileByPayload={downloadFileByPayload}
                              getAttachmentPDF={getAttachmentPDF}
                              downLoadAttachment={downLoadAttachment} />}
            </Drawer>
        </>
    )
}

export default reduxForm({
    form: 'ClientForm',
    enableReinitialize: true
})(Clients)