import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import strings from 'strings';
import './index.scss'

const t = strings.APPLICANT.VERIFICATION

export function VerificationReportWatchLists(props) {
    const {watchLists} = props

    return (<>
            {watchLists.length &&
                <>
                    <div className='heading-style-2 mt-5'>{t.WATCH_LISTS}</div>
                    <hr className='mb-1'/>

                    <Grid container spacing={2}>
                        <Grid className='verification-value' item sm={4}>{t.NAME}</Grid>
                        <Grid className='verification-value' item sm={6}>{t.PROGRAM}</Grid>
                        {watchLists?.map((watchList, i) =>
                            watchList?.WatchList?.map((watch, j) =>
                                <Fragment key={j}>
                                    <Grid item sm={4}>{watch.EntityName}</Grid>
                                    <Grid item sm={6}>{watch.Program}</Grid>
                                </Fragment>
                            )
                        )}
                    </Grid>
                </>}
        </>
    );
}