// for validation function used with redux-forms, if you return undefined that
// means the validation passed, if you return a string, then that validation is broken and that is the error message
import get from 'lodash/get'
import moment from 'moment'

import { DATE_FORMAT, DATE_FORMAT_BACKEND_REQUEST } from 'constants/date'
import { isEmpty, isNumber } from 'tools/objectHelper'

export const required = value => (isEmpty(value) ? 'Field Required' : undefined)
export const fileRequired = (value) => !value ? 'File Required' : undefined

export const moreThan0 = num => moreThan(0)(num)
export const lessThan151 = num => lessThan(151)(num)
export const lessThanEqualTo100 = num => lessThanEqualTo(100)(num)
export const lessThan1billion = num => lessThan(1000000000)(num)
export const lessThan10Digits = num => lessThan(10000000)(num)
export const lessThan7Digits = num => lessThan(1000000)(num)
export const lessThan5Digits = num => lessThan(10000)(num)
export const lessThan4Digits = num => lessThan(1000)(num)
export const lessThan3Digits = num => lessThan(100)(num)

export const moreThanEqualTo0 = num => moreThanEqualTo(0)(num)

export const exactLength9 = value => exactLength(9)(value)

export const maxLength1 = length => maxLength(1)(length)
export const maxLength2 = length => maxLength(2)(length)
export const maxLength3 = length => maxLength(3)(length)
export const maxLength4 = length => maxLength(4)(length)
export const maxLength5 = length => maxLength(5)(length)
export const maxLength6 = length => maxLength(6)(length)
export const maxLength7 = length => maxLength(7)(length)
export const maxLength8 = length => maxLength(8)(length)
export const maxLength9 = length => maxLength(9)(length)
export const maxLength10 = length => maxLength(10)(length)
export const maxLength11 = length => maxLength(11)(length)
export const maxLength12 = length => maxLength(12)(length)
export const maxLength14 = length => maxLength(14)(length)
export const maxLength15 = length => maxLength(15)(length)
export const maxLength17 = length => maxLength(17)(length)
export const maxLength19 = length => maxLength(19)(length)
export const maxLength20 = length => maxLength(20)(length)
export const maxLength25 = length => maxLength(25)(length)
export const maxLength30 = length => maxLength(30)(length)
export const maxLength32 = length => maxLength(32)(length)
export const maxLength35 = length => maxLength(35)(length)
export const maxLength45 = length => maxLength(45)(length)
export const maxLength50 = length => maxLength(50)(length)
export const maxLength55 = length => maxLength(55)(length)
export const maxLength60 = length => maxLength(60)(length)
export const maxLength70 = length => maxLength(70)(length)
export const maxLength80 = length => maxLength(80)(length)
export const maxLength100 = length => maxLength(100)(length)
export const maxLength200 = length => maxLength(200)(length)
export const maxLength250 = length => maxLength(250)(length)
export const maxLength1000 = length => maxLength(1000)(length)
export const maxLength2000 = length => maxLength(2000)(length)

export const minLength1 = length => minLength(1)(length)
export const minLength5 = length => minLength(5)(length)
export const minLength8 = length => minLength(8)(length)
export const minLength9 = length => minLength(9)(length)
export const minLength10 = length => minLength(10)(length)

export const twoDecimalsLimit = decimals => decimalsLimit(2)(decimals)
export const threeDecimalsLimit = decimals => decimalsLimit(3)(decimals)
export const fourDecimalsLimit = decimals => decimalsLimit(4)(decimals)

export const numbersOnly = value =>
  value && /[^0-9]/i.test(value) ? 'Numbers only allowed' : undefined

// for functions that returns functions, the parent function needs to be
// executed once in a component (never put it in a render function) you can
// define them at the beginning of the file for the component
export const lessThanField = (otherField, otherFieldLabel, isArray = false) => (
  value,
  allFields,
  form,
  name
) => {
  // @TODO this validation only works for 1 level of nested forms: ${name.match(/\d+/g)[0]}
  // Will need to add another custom validation for 1 level of nesting.
  const field = isArray ? `${name.split('.')[0]}.${otherField}` : otherField

  return lessThan(get(allFields, field))(value)
    ? `Field must be less than ${otherFieldLabel}`
    : undefined
}

export const moreThanField = (otherField, otherFieldLabel, isArray = false) => (
  value,
  allFields,
  form,
  name
) => {
  // @TODO this validation only works for 1 level of nested forms: ${name.match(/\d+/g)[0]}
  // Will need to add another custom validation for 2 level of nesting.
  const field = isArray ? `${name.split('.')[0]}.${otherField}` : otherField

  return moreThan(get(allFields, field))(value)
    ? `Field must be more than ${otherFieldLabel}`
    : undefined
}

export const lessThan = max => value => {
  const areNumbers = isNumber(max) && isNumber(value)
  if (!areNumbers) return undefined
  return parseFloat(value) < parseFloat(max)
    ? undefined
    : `Field must be less than ${max}`
}

export const lessThanEqualTo = max => value => {
  const areNumbers = isNumber(max) && isNumber(value)
  if (!areNumbers) return undefined
  return parseFloat(value) <= parseFloat(max)
    ? undefined
    : `Field must be less or equal to ${max}`
}

export const moreThan = min => value => {
  const areNumbers = isNumber(min) && isNumber(value)
  if (!areNumbers) return undefined
  return parseFloat(value) > parseFloat(min)
    ? undefined
    : `Field must be more than ${min}`
}

export const moreThanEqualTo = min => value => {
  const areNumbers = isNumber(min) && isNumber(value)
  if (!areNumbers) return undefined
  return parseFloat(value) >= parseFloat(min)
    ? undefined
    : `Field must be more than or equal to ${min}`
}

export const decimalsLimit = maxNumberOfDecimals => value => {
  if (!isNumber(value)) return undefined
  var e = 1
  while (Math.round(value * e) / e !== value) e *= 10
  const numberOfDecimals = Math.round(Math.log(e) / Math.LN10)
  return numberOfDecimals <= maxNumberOfDecimals
    ? undefined
    : `${maxNumberOfDecimals} decimals max.`
}

export const isNotBlank = value => {
  if (!value) return undefined
  if (!value.trim()) {
    return 'Please enter a valid Client Reference'
  }
}

export const minLength = minLength => value => {
  if (!value) return undefined
  return String(value).length >= minLength
    ? undefined
    : `Minimum length allowed is ${minLength} characters.`
}

export const maxLength = maxLength => value => {
  if (!value) return undefined
  return String(value).length <= maxLength
    ? undefined
    : `Maximum length allowed is ${maxLength} character${maxLength > 1 ? 's' : ''
    }.`
}

export const exactLength = length => value => {
  if (!value) return undefined
  return String(value).length === length
    ? undefined
    : `Length should be ${length} character${maxLength > 1 ? 's' : ''
    }.`
}

export const multiSelectMaxLength10 = (value) => {
  return (value && value.length && value.length > 10) ? `Selected items maximum allowed is 10` : undefined;
}

/**
 * Check if two form fields have the same value. The otherField is a full field
 * name like foo.bar[1].attr etc.
 *
 * @param {*} otherField
 * @param {*} otherLeafFieldLabel
 */
export const sameAsField = (otherField, otherLeafFieldLabel) => (
  value,
  allFields
) => {
  if (!value) return undefined
  var otherFieldValue = get(allFields, otherField)
  return value === otherFieldValue
    ? `Same value as field ${otherLeafFieldLabel}`
    : undefined
}

// DATE VALIDATIONS

export const isDateBefore = (
  max,
  format = DATE_FORMAT_BACKEND_REQUEST
) => value => {
  if (!max || !value) return undefined
  const maxDate = moment(max, format)
  const valueDate = moment(value, format)
  return valueDate.isBefore(maxDate)
    ? undefined
    : `The date selected must be earlier than ${maxDate.format(DATE_FORMAT)}.`
}
export const isRestrictCurrentDate = (
  maxField,
  format = DATE_FORMAT_BACKEND_REQUEST
) => (value, allFields) => {
  const maxValue = get(allFields, maxField)
  return isRestrictCurrent(maxValue, format)(value)
}


//To Restrict Current Date

export const isRestrictCurrent = (
  max,
  format = DATE_FORMAT_BACKEND_REQUEST
) => value => {
  if (!max || !value) return undefined
  const maxDate = moment(max, format)
  const todayDate = moment()
  const today = moment().subtract(1, 'day')
  return today.isSameOrAfter(maxDate)
    ? undefined
    : `The date selected must be less than ${todayDate.format(DATE_FORMAT)}.`
}
export const isRestrictFutureDate = (
  maxField,
  format = DATE_FORMAT_BACKEND_REQUEST
) => (value, allFields) => {
  const maxValue = get(allFields, maxField)
  return isRestrictFuture(maxValue, format)(value)
}

export const isRestrictFuture = (
  max,
  format = DATE_FORMAT_BACKEND_REQUEST
) => value => {
  if (!max || !value) return undefined
  const maxDate = moment(max, format)
  const today = moment()
  return today.isSameOrAfter(maxDate)
    ? undefined
    : `The date selected must be equal to or earlier than ${today.format(DATE_FORMAT)}.`
}
export const isDateBeforeField = (
  maxField,
  format = DATE_FORMAT_BACKEND_REQUEST
) => (value, allFields) => {
  const maxValue = get(allFields, maxField)
  return isDateBefore(maxValue, format)(value)
}

export const isDateAfter = (
  min,
  format = DATE_FORMAT_BACKEND_REQUEST
) => value => {
  if (!min || !value) return undefined
  const minDate = moment(min, format)
  const valueDate = moment(value, format)
  return valueDate.isAfter(minDate)
    ? undefined
    : `The date selected must be later than ${minDate.format(DATE_FORMAT)}.`
}

export const isDateAfterField = (
  minField,
  format = DATE_FORMAT_BACKEND_REQUEST
) => (value, allFields) => {
  const minValue = get(allFields, minField)
  return isDateAfter(minValue, format)(value)
}

export const isDateEqualOrBefore = (
  max,
  format = DATE_FORMAT_BACKEND_REQUEST
) => value => {
  if (!max || !value) return undefined
  const maxDate = moment(max, format)
  const valueDate = moment(value, format)
  return valueDate.isSameOrBefore(maxDate)
    ? undefined
    : `The date selected must be equal to or earlier than ${maxDate.format(
      DATE_FORMAT
    )}.`
}

export const isDateEqualOrBeforeField = (
  maxField,
  format = DATE_FORMAT_BACKEND_REQUEST
) => (value, allFields) => {
  const maxValue = get(allFields, maxField)
  return isDateEqualOrBefore(maxValue, format)(value)
}

//To Restrict Past Date

export const isRestrictPastDate = (
  min,
  format = DATE_FORMAT_BACKEND_REQUEST
) => value => {
  if (!min || !value) return undefined
  const minDate = moment().subtract(1, 'day')
  const valueDate = moment(min, format)
  return valueDate.isSameOrAfter(minDate)
    ? undefined
    : `The date selected must be equal to or later than ${moment().format(
      DATE_FORMAT
    )}.`
}

export const isRestrictPastDateField = (
  minField,
  format = DATE_FORMAT_BACKEND_REQUEST
) => (value, allFields) => {
  const minValue = get(allFields, minField)
  return isRestrictPastDate(minValue, format)(value)
}

export const isDateEqualOrAfter = (
  min,
  format = DATE_FORMAT_BACKEND_REQUEST
) => value => {
  if (!min || !value) return undefined
  const minDate = moment(min, format)
  const valueDate = moment(value, format)
  return valueDate.isSameOrAfter(minDate)
    ? undefined
    : `The date selected must be equal to or later than ${minDate.format(
      DATE_FORMAT
    )}.`
}

export const isDateEqualOrAfterField = (
  minField,
  format = DATE_FORMAT_BACKEND_REQUEST
) => (value, allFields) => {
  const minValue = get(allFields, minField)
  return isDateEqualOrAfter(minValue, format)(value)
}



// VALUE TYPE VALIDATIONS

export const startsWithAlphabet = value =>
  value && /^[0-9]$/i.test(value[0])
    ? 'Only start with Alphabet'
    : undefined

export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined

export const alphaOnly = value =>
  value && /[^a-zA-Z ]/i.test(value) ? 'Only alpha characters' : undefined

export const noDecimals = value =>
  value && /[,.]/i.test(value) ? 'No Decimals allowed' : undefined

export const validateEmail = value => {
  if (!value) return undefined
  return /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,8}$/i.test(value) ? undefined : 'Invalid email address' // eslint-disable-line
}

export const phoneNumberValidation = value => {
  if (!value) return undefined
  return String(value).length >= 12
    ? undefined
    : `Minimum length allowed is 10 numbers.`
}

export const requiredIf = (otherField, otherFieldValue) => (
  value,
  allFields
) => {
  if (get(allFields, otherField) !== otherFieldValue) {
    return undefined
  }
  return required(value)
}

export const validateIf = (otherField, otherFieldValue, validations) => (
  value,
  allFields
) => {
  if (get(allFields, otherField) !== otherFieldValue) {
    return undefined
  }
  const validationResults = []
  validations.forEach(validate => {
    const results = validate(value)
    if (results) validationResults.push(results)
  })
  if (validationResults.length === 0) {
    return undefined
  }
  return validationResults[0]
}
