import { connect } from 'react-redux'
import Settings from 'components/RoxAdmin/Settings'
import {
    getClientsList,
    createClient,
    updateClient,
    getClientById,
    resetClientEditForm,
    downloadReport,
} from 'actions/client'
import { getRolesList } from 'actions/root'
import strings from 'strings'

const defaultInitialValue = {
    client: {
        code: '321',
        businessType: 'Corporations',
        county: 'county',
        phoneType: 'Home',
    },
    user: {
        userRol: strings.ROLE_NAMES.CUSTOMER_ADMIN
    }
}

function mapStateToProps(stateProps) {
    const { clients, clientById } = stateProps?.client
    const headers = clients.headers
    const totalRecords = headers['x-pagination-total-records']
    const totalPages = headers['x-pagination-total-pages']
    let intialValue = Object.keys(clientById)?.length > 0 ? { client: clientById } : defaultInitialValue

    return {
        initialValues: intialValue,
        clientsList: clients?.clientsList,
        totalRecords,
        totalPages
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        // add actions here
        resetClientEditForm, //action to reset client edit form
        getClientsList, // get the list of clients
        createClient, // action to create clients
        updateClient, // action to update clients
        getClientById, // action to get clients by ID
        downloadReport, // action to download report
        getRolesList // action get list of roles
    },
    mergeProps
)(Settings)