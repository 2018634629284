import React, { useState, useEffect, useCallback } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import {
    Button,
    InputBase,
    Popover,
    Switch,
    FormControlLabel
} from '@material-ui/core'
import { debounce } from 'lodash'
import ClearIcon from '@material-ui/icons/Clear';
import InputFieldDetailed from 'components/forms/Input'
import NormalDropdown from 'components/forms/Dropdown/NormalDropdown'
import DateRangePicker from 'components/forms/DateRangePicker'
import strings from 'strings'
import PropTypes from 'prop-types'
import filterIcon from 'styles/images/icons/filter.svg'
import columnIcon from 'styles/images/icons/column.svg'
import './filters.scss'

// const url = process.env.PUBLIC_URL

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(1),
        minWidth: '14rem',
        // minHeight: '25rem'
    },
    search: {
        position: 'relative',
        // borderRadius: theme.shape.borderRadius,
        backgroundColor: 'transparent',
        borderBottom: '1px solid black',
        '&:hover': {
            backgroundColor: 'white',
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            marginRight: '8px',
        },
    },
    searchIcon: {
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(2px + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                search: {
                    backgroundColor: 'white'
                },
                width: '20ch',
            },
        },
    },
}));

const GreenSwitch = withStyles({
    switchBase: {
        color: '#3f51b5',
        '&$checked': {
            color: '#3f51b5',
        },
        '&$checked + $track': {
            backgroundColor: '#3f51b5',
        },
    },
    checked: {},
    track: {},
})(Switch);

const filterSeletedColumn = (column) => {
    const filteredColumn = []
    column.forEach(item => {
        if (item.show === undefined || item.show) {
            filteredColumn.push(item)
        }
    })
    return filteredColumn
}

const FilterBarModule = (props) => {
    const {
        title,
        columns,
        receiveCustomColumn,
        filters,
        filterParams,
        onBtnChange,
        btnText,
        placeHolder,
        onSearch,
        selectedRowsLength,
        selectedRowsComponent,
        clearSearch,
        defaultFilterValues
    } = props
    const classes = useStyles()
    const [filterObject, setFilterObject] = useState({})
    const [appliedFilters, setAppliedFilters] = useState({})
    const [anchorEl, setAnchorEl] = useState(null)
    const [type, setType] = useState('')
    const [copyColumns, setCopyColumns] = useState([...columns])
    const [filterCount, setFilterCount] = useState(0)
    const [columnApplied, setColumnApplied] = useState(false)
    const [value, setValue] = useState('')

    useEffect(() => {
        filters.forEach(item => {
            if (item.defaultValue) {
                setFilterObject({ [item.id]: item.defaultValue })
            }
        })
    }, [filters])

    useEffect(() => {
        if (defaultFilterValues) {
            setFilterObject(defaultFilterValues?.filterObject)
            setValue(defaultFilterValues?.freeSearch)
            setFilterCount(Object.keys(defaultFilterValues?.filterObject).length)
        }
    }, [defaultFilterValues])

    const handlePopOver = (e, type) => {
        setAnchorEl(e.currentTarget)
        setType(type)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const applyFilters = () => {
        setFilterCount(Object.keys(filterObject).length)
        setAppliedFilters({ ...filterObject })
        setAnchorEl(null);
        filterParams(filterObject)
    }

    const checkAppliedFilter = () => {
        setFilterObject({ ...appliedFilters })
        handleClose()
    }

    const applyColumn = () => {
        receiveCustomColumn(filterSeletedColumn(copyColumns))
        setColumnApplied(true)
        setAnchorEl(null)
    }

    const handleColumn = (e, field) => {
        let column = []
        let checked = e.target.checked
        copyColumns.forEach(item => {
            if (item.dataField === field) {
                item.show = checked
                column.push(item)
            } else {
                column.push(item)
            }
        })
        setCopyColumns(column)
    }

    const onChange = (id, value) => {
        const filters = { ...filterObject }
        if (!value) {
            Object.keys(filters).forEach(keys => {
                if (id === keys) {
                    delete filters[keys]
                }
            })
            setFilterObject({ ...filters })
        } else {
            setFilterObject({ ...filters, [id]: value })
        }
    }

    const renderInputField = (props, index) => {
        const {
            label,
            id
        } = props
        return (
            <InputFieldDetailed
                label={label}
                value={filterObject[id]}
                onChange={(e) => onChange(id, e.target.value)}
                key={index}
            />
        )
    }

    const renderDropdownField = (props) => {
        const {
            id,
            label,
            options,
            isMulti
        } = props
        return (
            <NormalDropdown
                label={label}
                value={filterObject[id]}
                options={options}
                isClearable={true}
                onChange={(val) => onChange(id, val?.value)}
                isMulti={isMulti}
            />
        )
    }

    /**
     * 
     * @param {id, label} props 
     * @param {number} index 
     * @returns Datepicker Field
     */
    const renderDatePicker = (props, index) => {
        const {
            id,
            label
        } = props
        return (
            <InputFieldDetailed
                label={label}
                type='date'
                InputLabelProps={{
                    shrink: true,
                }}
                value={filterObject[id]}
                onChange={(e) => onChange(id, e.target.value)}
                key={index}
            />
        )
    }

    const renderDateRangeField = (props) => {
        const {
            id,
            label
        } = props
        return (
            <DateRangePicker
                label={label}
                value={filterObject[id]}
                onChange={(val) => onChange(id, val)}
            />
        )
    }

    const renderFilters = () => {
        return (
            <div className={`filter-popover-container`}>
                <div className={`filter-field-container`}>
                    <h5>{strings.FILTERS}</h5>
                    {
                        filters.map((item, index) => {
                            let filterElement
                            switch (item.type) {
                                case 'text':
                                    filterElement = renderInputField(item, index)
                                    break
                                case 'dropdown':
                                    filterElement = renderDropdownField(item, index)
                                    break
                                case 'datePicker':
                                    filterElement = renderDatePicker(item, index)
                                    break;
                                case 'dateRange':
                                    filterElement = renderDateRangeField(item, index)
                                    break
                                default:
                                    break
                            }
                            return filterElement
                        })
                    }
                </div>
                <div className={`btn-container`}>
                    <Button className={`btn reset-btn`} onClick={() => checkAppliedFilter()}>{strings.CLOSE}</Button>
                    <Button variant='contained' color='primary' onClick={() => applyFilters()}>{strings.APPLY}</Button>
                </div>
            </div>
        )
    }

    const renderColumns = () => {
        return (
            <div className={`filter-popover-container`}>
                <div className={`column-container`}>
                    {
                        copyColumns.map((item, index) => (
                            !item.hideColumn && (<FormControlLabel
                                control={<GreenSwitch name={item.dataField} checked={item.show === undefined ? true : item.show} onChange={(e) => handleColumn(e, item.dataField)} />}
                                label={item.text}
                                key={index}
                                disabled={item.checkboxDisable || index === 0}
                            />)
                        ))
                    }
                </div>
                <div className={`btn-container`}>
                    <Button className={`btn reset-btn`} onClick={handleClose}>{strings.CLOSE}</Button>
                    <Button className={`btn apply-btn`} onClick={() => applyColumn()}>{strings.APPLY}</Button>
                </div>
            </div>
        )
    }

    const open = Boolean(anchorEl)
    const id = open ? 'jobs-popover' : undefined

    const handleChange = useCallback(debounce(e => {
        onSearch && onSearch(e)
    }, 500), [])

    return (
        <div className={`table-list-container`}>
            <div className={`header-container ${selectedRowsLength > 0 && `activeRowSelection`}`}>
                <div>
                    <h5>{title}</h5>
                </div>
                {
                    selectedRowsLength > 0 ? (
                        <>{selectedRowsComponent && selectedRowsComponent}</>
                    ) : (
                        <div className={`header-actions`}>
                            <div className={classes.search}>
                                <div className={classes.searchIcon}>
                                    <SearchIcon />
                                </div>
                                <InputBase
                                    placeholder={placeHolder || 'Search…'}
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={(e) => {
                                        handleChange(e.target.value)
                                        setValue(e.target.value)
                                    }}
                                    endAdornment={value && <ClearIcon className='cursor-pointer' onClick={() => {
                                        onSearch && onSearch('');
                                        clearSearch && clearSearch();
                                        setValue('')
                                    }} />}
                                    value={value}
                                />
                            </div>
                            {
                                filters.length !== 0 && (
                                    <div className={`filter-btn filters-container ${filterCount && 'selected'}`} onClick={(e) => handlePopOver(e, 'filter')}>
                                        {filterCount !== 0 && <span className={`filter-patch`}>{filterCount}</span>}
                                        <img className={Boolean(anchorEl) && type === "filter" ? `filter-icon` : ''} src={filterIcon} alt='filter-icon' />
                                    </div>
                                )
                            }
                            {
                                columns.length !== 0 && (
                                    <div className={`filter-btn columns-container ${columnApplied && 'selected'}`} onClick={(e) => handlePopOver(e, 'column')}>
                                        <img className={`${'column-icon'} ${Boolean(anchorEl) && type === "column" && `filter-icon`}`} src={columnIcon} alt='filter-icon' />
                                    </div>
                                )
                            }
                            {btnText && (
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={onBtnChange}
                                >
                                    {btnText}
                                </Button>
                            )
                            }
                        </div>
                    )
                }
            </div>
            <div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    classes={{
                        paper: classes.paper,
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    {type === 'filter' ? renderFilters() : renderColumns()}
                </Popover>
            </div>
        </div>
    )
}

FilterBarModule.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    placeHolder: PropTypes.string
}

FilterBarModule.defaultProps = {
    filters: [],
    columns: []
}

export default FilterBarModule

