import { produce } from 'immer'
import {
    SET_USER_LIST,
    SET_USER_ById,
    SET_FORM_INITIALVALUE,
    SET_USER
} from 'actions/user'

const initialState = {
    users: {
        data: [],
        headers: {}
    },
    userById: {},
    formConfig: {},
    userDetails: {}
}

export const userReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_USER_LIST:
                draft.users.data = action.data
                draft.users.headers = action.headers
                break;
            case SET_USER_ById:
                draft.userById = action.data
                break;
            case SET_USER:
                draft.userDetails = action.user
                break;
            case SET_FORM_INITIALVALUE:
                draft.formConfig = action.data
                break;
            default:
                break;
        }
    })
}