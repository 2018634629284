import React, { useEffect, useState } from "react"
import TableComponent from 'components/shared/TableComponent'
import { getUccSearchTableData } from 'actions/applicants'
import { uccSearchTableColumns } from 'constants/application'
import Button from 'components/shared/Button'
import strings from 'strings'
import UccSearchDrawer from "./UccSearchDrawer"

const UccSearch = ({ applicationId }) => {

    const [selectedRow, setSelectedRow] = useState(null)
    const [openUccSearchDrawer, setOpenUccSearchDrawer] = useState(false)
    const [uccList, setUccList] = useState([])
    const [loadingTableData, setLoadingTableData] = useState(false)
    const [tabValue, setTabValue] = useState(0)

    useEffect(() => {
        setLoadingTableData(true)
        updateUccSearchTableData()
        // eslint-disable-next-line
    }, [])

    // update ucc search table data
    const updateUccSearchTableData = () => {
        getUccSearchTableData({ applicationId })
            .then(setUccList)
            .finally(() => { setLoadingTableData(false) })
    }

    // handle onClose drawer
    const handleOnClose = () => {
        setOpenUccSearchDrawer(false)
        updateUccSearchTableData()
    }

    const handleButtonClick = (row) => {
        const status = row?.statusLabel?.status
        setSelectedRow(row)
        if (status === 'Not Started') {
            setTabValue(0)
        } else if (row?.numberOfFilingsSaved > 0){
            setTabValue(1)
        } else {
            setTabValue(2)
        }
        setOpenUccSearchDrawer(true)
    }

    const openSearchAction = {
            formatter: (cell, row) => {
                const buttonText = row?.statusLabel?.status === 'Not Started' ? strings.SEARCH : strings.VIEW
                const variant = row?.statusLabel?.status === 'Not Started' ? 'contained' : 'outlined'
                return <Button
                    className={'minWidthButton'}
                    color='primary'
                    size={'small'}
                    variant={variant}
                    onClick={()=> handleButtonClick(row)}>
                    <p className='buttonText'>{buttonText}</p>
                </Button>
            }
        }

    uccSearchTableColumns.map((item, index) => {
        if (item.dataField === 'action') {
            uccSearchTableColumns[index] = {...item, ...openSearchAction}
        }
        return item
    })

    return (
        <>
            <h5>{strings.UCC} {strings.SEARCH}</h5>
            <TableComponent
                keyField='uuid'
                columns={uccSearchTableColumns}
                data={uccList}
                showPagination={false}
                isLoading={loadingTableData}
            />
            {
                openUccSearchDrawer &&
                <UccSearchDrawer
                    open={openUccSearchDrawer}
                    selectedEntity={selectedRow}
                    handleOnClose={handleOnClose}
                    applicationId={applicationId}
                    initialTab={tabValue}
                />
            }
        </>
    )
}

export default UccSearch