import strings from "../../../../strings";
import {mapDataToBgvReportTable} from "../../../../constants/application";
import TableComponent from "../../../shared/TableComponent";
import emptyUCC from "../../../../styles/images/emptyUCC.svg";
import Drawer from "../../../shared/Drawer";
import React, {useState} from "react";
import BackgroundReportAdditionalDataJsx from "./BackgroundReportAdditionalDataJsx";
const bgvString = strings.APPLICANT.BGV

const BackgroundReportOwnerJsx = (props) => {
    const {
        data
    } = props
    const reportData = data?.response?.response?.BpsReportResponseEx?.response?.Individual
    const [additionalInformation, setAdditionalInformation] = useState({ open: false, title: '', data: null, entity: '' })
    const {
        CriminalRecords,
        BpsReportAddresses,
        Imposters,
        Bankruptcies,
        UCCFilings,
        BestInfo,
        LiensJudgments,
        Verification
    } = reportData || {}
    //TODO Improve the way to get the data
    const
        criminalRecords = CriminalRecords?.Criminal || [],
        bpsReportAddress = BpsReportAddresses?.BpsReportAddress || [],
        imposters = Imposters?.Imposter[0]?.AKAs?.Identity || [],
        bankruptcies = Bankruptcies?.Bankruptcy || [],
        uccFiling = UCCFilings?.UCCFiling || [],
        info = [BestInfo || {}],
        liensJudgments = LiensJudgments?.LienJudgment || [],
        verification = [Verification?.VerifiedIndicators || {}]
    const paginationProps = (length) => ({
        useLocalPagination: length > 5,
        showPagination: length > 5,
        paginationLimit: 5,
    })

    function getBackgroundReportAdditionalData() {
        return <Drawer
            open={additionalInformation.open}
            onClose={() => setAdditionalInformation(prev => ({...prev, open: false}))}
            title={additionalInformation.title}
        >
            {BackgroundReportAdditionalDataJsx({openInformation: additionalInformation})}
        </Drawer>
    }

    const criminalActionDetails = columns => {
        const actions = {
            formatter: (cell, row, rowIndex) => {
                if (row?.Offenses?.Offense?.length)
                    return row.Offenses.Offense.map((data, i) => (
                        <div
                            key={i}
                            className='div-button'
                            onClick={() => setAdditionalInformation({ open: true, title: bgvString.OFFENCES, data, entity: 'criminal' })}
                        >
                            {bgvString.OFFENCE} {i + 1}
                        </div>
                    ))

                return <>---</>
            }
        }

        columns.map((item, index) => {
            if (item.dataField === 'Action1') {
                columns[index] = { ...item, ...actions }
            }
            return item
        })

        return columns
    }

    const actionDetails = (columns, actionFor, entity) => {
        const actions = (dataField) => {
            return {
                formatter: (cell, row, rowIndex) => {
                    let rowData = [], title = ''
                    if (dataField === 'Action1') {
                        rowData = row?.Debtors?.Debtor
                        title = bgvString.ABOUT_DEBTOR
                    }
                    else if (actionFor === 'UCC Filings') {
                        rowData = row?.Secureds?.Secured
                        title = bgvString.ABOUT_SECURED
                    }
                    else {
                        rowData = row?.Creditors?.Creditor
                        title = bgvString.ABOUT_CREDITOR
                    }
                    if (rowData?.length)
                        return (
                            rowData?.map((data, i) => {
                                let name = data.CompanyName || data.OriginName || data.Name
                                if (typeof name === 'object')
                                    name = `${name.First} ${name.Last}`

                                return (
                                    <div
                                        key={i}
                                        className='div-button'
                                        title={name}
                                        onClick={() => setAdditionalInformation({ open: true, title, data, entity })}
                                    >
                                        {name}
                                    </div>
                                )
                            })
                        )
                    return <>---</>
                }
            }
        }

        columns.map((item, index) => {
            if (['Action1', 'Action2'].includes(item.dataField)) {
                columns[index] = { ...item, ...actions(item.dataField) }
            }
            return item
        })

        return columns
    }

    return (
        < div className="pb-5">
                {Object.entries(reportData).length ?
                    <div className="pb-5">
                        {
                            mapDataToBgvReportTable({
                                criminalRecords,
                                bpsReportAddress,
                                imposters,
                                bankruptcies,
                                uccFiling,
                                info,
                                liensJudgments,
                                verification,
                            }).map((d, i) => {
                                    const isScrollNeeded = d.scrollNeeded
                                    const columns = d.title === 'Criminal Records' ? criminalActionDetails(d.columns) :
                                        isScrollNeeded ? actionDetails(d.columns, d.title, 'owner') :
                                            d.columns

                                    return (
                                        <div className="box" key={i}>
                                            <h5>{d.title}</h5>
                                            <div>{d.subtext}</div>
                                            {
                                                <div style={isScrollNeeded ? { overflowX: 'scroll' } : {}}>
                                                    <TableComponent
                                                        keyField='index'
                                                        columns={columns}
                                                        data={d.data}
                                                        {...paginationProps(d.data.length)}
                                                        className={`customTable ${isScrollNeeded && (liensJudgments.length || uccFiling.length) ? 'w135' : ''}`}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                    : (
                        <div className='emptyIcon'>
                            <img src={emptyUCC} alt="no data icon" />
                            <div className="noResultsMessageDiv">
                                <p className="noResultsText boldText">{bgvString.THERE_WERE_NO_RESULTS}</p>
                                <p className="noResultsText">{bgvString.NO_RESULTS_FOR_OWNER}</p>
                            </div>
                        </div>
                    )}
            {getBackgroundReportAdditionalData()}
        </div>
    )
}

export default BackgroundReportOwnerJsx