import React from 'react'
import { isScreenSmall } from 'tools/utils'
import PrepareTooltip from 'components/shared/Tooltip'

const fmcsaDetailColumnLargeScreen = [
  {
    dataField: 'businessName',
    text: 'Business Name',
    headerStyle: { width: '13rem' },
    formatter: (cell, row) => {
      if (cell)
        return <div title={cell}>{cell}</div>
      return '---'
    }
  },
  {
    dataField: 'address',
    text: 'Address',
    headerStyle: { width: '14rem' }
  },
  {
    dataField: 'city',
    text: 'City'
  },
  {
    dataField: 'state',
    text: 'State',
    headerStyle: { width: '4rem' }
  },
  {
    dataField: 'zip',
    text: 'Zip',
    headerStyle: { width: '7rem' }
  },
  {
    dataField: 'ein',
    text: 'EIN',
    headerStyle: { width: '7rem' }
  },
  {
    dataField: 'mc',
    text: 'MC',
    formatter: (cell) => {
      return cell || '---'
    },
    headerStyle: { width: '7rem' }
  },
  {
    dataField: 'dot',
    text: 'DOT',
    headerStyle: { width: '7rem' }
  },
  {
    dataField: 'action',
    headerStyle: {
      width: '7rem',
      cursor: 'pointer'
    },
    text: ''
  }
]

const fmcsaDetailColumnSmallScreen = [
  {
    dataField: 'businessName',
    text: 'Business Name',
    headerStyle: { width: '10rem' }
  },
  {
    dataField: 'address',
    text: 'Address',
    formatter: (cell, row) => {
      const address = createAddress(row);
      if (address)
        return (
          <PrepareTooltip
            theme='white'
            title={address + (row?.state ? `, ${row.state}` : '')}
            placement='bottom'
          >
            <div style={{ display: 'flex' }}>
              <div style={{ maxWidth: '11rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} >
                {address}
              </div>
              {row?.state && <div> {row.state}</div>}
            </div>
          </PrepareTooltip>
        )
    }
  },
  {
    dataField: 'ein',
    text: 'EIN',
    headerStyle: { width: '7rem' }
  },
  {
    dataField: 'mc',
    text: 'MC',
    headerStyle: { width: '7rem' },
    formatter: (cell) => {
      return cell || '---'
    }
  },
  {
    dataField: 'dot',
    text: 'DOT',
    headerStyle: { width: '7rem' }
  },
  {
    dataField: 'action',
    headerStyle: {
      width: '7rem',
      cursor: 'pointer'
    },
    text: ''
  }
]

const fmcsaDetailColumn = isScreenSmall() ? fmcsaDetailColumnSmallScreen : fmcsaDetailColumnLargeScreen

const createAddress = (row) => {
  const address = [];
  if (row?.address) address.push(row.address);
  if (row?.city) address.push(row.city);
  if (row?.zip) address.push(row.zip);
  return address.join(', ');
}

const getValue = (type, key) => {
  if (type !== null && type !== undefined) {
    if (key === 'mcNumber') {
      if (Array.isArray(type[key])) {
        return type[key].join(', ');
      }
    }
    return type[key] || '-';
  }
  return '-';
}

function mapFmcsaData(data) {
  return [
    {
      title: 'Name :',
      value: getValue(data, 'legalName')
    },
    {
      title: 'Address :',
      value: getValue(data, 'phyStreet')
    },
    {
      title: 'City :',
      value: getValue(data, 'phyCity')
    },
    {
      title: 'State :',
      value: getValue(data, 'phyState')
    },
    {
      title: 'Zip :',
      value: getValue(data, 'phyZipcode')
    },
    {
      title: 'Dba Name :',
      value: getValue(data, 'dbaName')
    },
    {
      title: 'EIN :',
      value: getValue(data, 'ein')
    },
    {
      title: 'MC :',
      value: getValue(data, 'mcNumber')
    },
    {
      title: 'DOT :',
      value: getValue(data, 'dotNumber')
    },
    {
      title: 'Safety rating :',
      value: getValue(data, 'safetyRating')
    },
    {
      title: 'Safety rating date :',
      value: getValue(data, 'safetyRatingDate')
    },
    {
      title: 'Safety review date :',
      value: getValue(data, 'safetyReviewDate')
    },
    {
      title: 'Safety review type :',
      value: getValue(data, 'safetyReviewType')
    },
    {
      title: 'Allowed to Operate :',
      value: getValue(data, 'allowedToOperate')
    },
    {
      title: 'BIPD insurance on file :',
      value: getValue(data, 'bipdInsuranceOnFile')
    },
    {
      title: 'BIPD insurance required :',
      value: getValue(data, 'bipdInsuranceRequired')
    },
    {
      title: 'BIPD required amount :',
      value: getValue(data, 'bipdRequiredAmount')
    },
    {
      title: 'Bond insurance file :',
      value: getValue(data, 'bondInsuranceOnFile')
    },
    {
      title: 'Bond insurance required :',
      value: getValue(data, 'bondInsuranceRequired')
    },
    {
      title: 'Broker authority status :',
      value: getValue(data, 'brokerAuthorityStatus')
    },
    {
      title: 'Cargo insurance file :',
      value: getValue(data, 'cargoInsuranceOnFile')
    },
    {
      title: 'Cargo insurance required :',
      value: getValue(data, 'cargoInsuranceRequired')
    },
    {
      title: 'Carrier operation code :',
      value: getValue(data.carrierOperation, 'carrierOperationCode')
    },
    {
      title: 'Carrier operations description :',
      value: getValue(data.carrierOperation, 'carrierOperationDesc')
    },
    {
      title: 'Common authority status :',
      value: getValue(data, 'commonAuthorityStatus')
    },
    {
      title: 'Contract authority status :',
      value: getValue(data, 'contractAuthorityStatus')
    },
    {
      title: 'Fatal crash :',
      value: getValue(data, 'fatalCrash')
    },
    {
      title: 'Inj crash :',
      value: getValue(data, 'injCrash')
    },
    {
      title: 'Total crash :',
      value: getValue(data, 'crashTotal')
    },
    {
      title: 'Hazmat National Average Rate:',
      value: getValue(data, 'hazmatOosRateNationalAverage')
    },
    {
      title: 'Total drivers:',
      value: getValue(data, 'totalDrivers')
    },
    {
      title: 'Total power units:',
      value: getValue(data, 'totalPowerUnits')
    },
  ]
}

export {
  fmcsaDetailColumn,
  mapFmcsaData
}