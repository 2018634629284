import React, {Fragment} from "react"
import DataSummary from "../DataSummary/DataSummary";

const SosFilingsTabContent = (props) => {

    const { recordsByState } = props

    return (
        <div>
            {recordsByState.subTabs.map((record, index) => {
                return (
                    <Fragment key={index}>
                        {index !== 0 && <hr />}
                        <div className="d-flex">
                            <h5 className='summaryTitle mb-3 mt-3'> {record.header.title} </h5>
                            {record.header?.status &&
                                <span className={`ml-3 ${record.header.status === 'Active' ? 'greenLabel' : 'redLabel'}`}>
                                    {record.header.status}
                                </span>
                            }
                        </div>
                        <DataSummary data={record.data}/>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default SosFilingsTabContent