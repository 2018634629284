import React from 'react';
import emptyIcon from 'styles/images/icons/empty.svg'
import emptyState from 'styles/images/icons/emptyState.svg'
import './NoDataIndication.scss'

const NoDataIndication = (props) => {
    const { 
        message = 'No Data Available',
        className = '',
        waitingAction = false,
    } = props

    const srcIcon = waitingAction ? emptyState : emptyIcon

    return (
        <div className={`indicationContainer ${className}`}>
            <img src={srcIcon} alt='empty' />
            <p className='mt-1 mb-0'>{message}</p>
        </div>
    )
}

export default NoDataIndication