import React from 'react'
import { Switch, withRouter, Route } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'
import Dashboard from 'container/Dashboard'
import SearchApplicants from 'container/Applicant/searchApplicant'
import CreateApplicant from 'container/Applicant/createApplicationContainer'
import ApplicantDetail from 'container/Applicant/applicantDetail'
import client from 'container/Applicant/client'
import QuickSearch from 'container/QuickSearch'
import ClientProfileContainer from 'container/RoxAdminContainer/ClientProfileContainer'
import Profile from 'container/Profile'
import Page404 from 'components/Page404'
import { Notification } from "components/Notification"

const RoutesRoot = () => {
    return (
        <Switch>
            <ProtectedRoute
                exact
                isAuth={true}
                path='/dashboard'
                component={Dashboard}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/applicants/:clientUUID?'
                component={SearchApplicants}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/applicant/:applicantUUID?'
                component={ApplicantDetail}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/create-applicant/:clientUUID?'
                component={CreateApplicant}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/settings'
                component={ClientProfileContainer}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/profile'
                component={Profile}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path="/ucc"
                component={client}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path="/quick-search"
                component={QuickSearch}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path="/notifications"
                component={Notification}
            />
            <Route render={props => <Page404 {...props} />} />
        </Switch>
    )
}

export default withRouter(RoutesRoot)
