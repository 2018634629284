import React from 'react'
import { Switch, withRouter, Route } from 'react-router-dom'
import ProtectedRoute from 'routes/ProtectedRoute'
import SettingsContainer from 'container/RoxAdminContainer/SettingsContainer'
import ClientContainer from 'container/RoxAdminContainer/ClientProfileContainer'
import PlatformPartnerContainer from 'container/RoxAdminContainer/PlatformPartnerContainer'
import ProfileContainer from 'container/Profile'
import CsvContainer from 'container/RoxAdminContainer/CsvContainer'
import Page404 from 'components/Page404'
import DashboardContainer from 'container/Dashboard'
import Menu from 'components/RoxAdmin/Settings/menu'

const RoutesRoot = () => {
    return (
        <Switch>
            <ProtectedRoute
                exact
                isAuth={true}
                path='/dashboard'
                component={DashboardContainer}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/settings'
                component={Menu}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/settings/clients'
                component={SettingsContainer}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path="/settings/client/:clientId"
                component={ClientContainer}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path='/settings/platform-partners'
                component={PlatformPartnerContainer}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path="/manage-csv"
                component={CsvContainer}
            />
            <ProtectedRoute
                exact
                isAuth={true}
                path="/profile"
                component={ProfileContainer}
            />
            <Route render={(props) => <Page404 {...props} />} />
        </Switch>
    );
};

export default withRouter(RoutesRoot);
