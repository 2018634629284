import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton, CircularProgress } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const buttonTheme = createTheme({
    overrides: {
      MuiButton: {
        outlined: {
          borderRadius: 20,
        },
      },
    },
  });
  

const Button = (props) => {
    const {
        color = 'primary',
        variant = 'contained',
        children,
        loading,
        disabled,
        ...otherProps
    } = props
    return (
        <ThemeProvider theme={buttonTheme}>
            <MuiButton
                color={color}
                variant={variant}
                disabled={loading || disabled || false}
                {...otherProps}
            >
                {loading ? <CircularProgress size={24} color='inherit' /> : children}
            </MuiButton>
        </ThemeProvider>
    );
};

Button.propTypes = {
    color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    variant: PropTypes.oneOf(['contained', 'outlined', 'text'])
};

export default Button;