import React, { useState, useEffect, useCallback } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import Input from 'components/forms/Input'
import Moment from "react-moment"
import AuthService from 'tools/AuthService'
import emptyIcon from 'styles/images/icons/empty.svg'
import './notes.scss'

const NoDataIndication = () => (
    <div className='noDataAvailable'>
        <img src={emptyIcon} alt='empty' />
        <p>No Notes Available.</p>
    </div>
)

const NotesList = ({ data }) => {
    const {
        notes,
        updatedAt,
        createdBy
    } = data
    const splitName = createdBy.name.split(' ')
    const avatarName = splitName[0][0] + splitName[1][0]
    return (
        <div className='notesListContainer'>
            <div className='userName'>
                {avatarName}
            </div>
            <div className='notesContent'>
                <div className='title'>
                    <p>{createdBy.name}</p>
                </div>
                <div>
                    <p>{notes}</p>
                </div>
                <div className='createdDate'>
                    <Moment format="DD-MM-YYYY">{updatedAt}</Moment>
                </div>
            </div>
        </div>
    )
}

const Notes = (props) => {
    const {
        createNotes,
        getNotes,
        applicantId,
        notesList
    } = props
    const [loading, setLoading] = useState(false)
    const [notes, setNotes] = useState('')

    const getNotesList = useCallback(() => {
        setLoading(true)
        getNotes(applicantId).then(() => {
            setLoading(false)
        })
    }, [applicantId, getNotes])

    useEffect(() => {
        getNotesList()
    }, [getNotesList])

    const handleChange = (e) => {
        setNotes(e.target.value)
    }

    const handleSubmit = () => {
        createNotes(applicantId, { notes: notes }).finally(() => {
            getNotesList()
            setNotes('')
        })
    }

    return (
        <div className='notesContainer'>
            <p>Notes</p>
            {!AuthService.isReadOnlyUser() &&
                <div className='fieldContainer'>
                    <Input
                        name='notes'
                        value={notes}
                        placeholder='Enter Your Notes Here'
                        multiline={true}
                        className='notesInput'
                        onChange={(e) => handleChange(e)}
                    />
                    <div className='btnContainer'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => handleSubmit()}
                            disabled={loading || notes.length === 0}
                        >
                            Add Notes
                        </Button>
                    </div>
                </div>}
            {
                loading ? <CircularProgress className='circularLoader' /> : (
                    notesList.map((item) => (
                        <NotesList
                            data={item}
                        />
                    ))
                )
            }
            {
                (!loading && notesList?.length === 0) && (
                    <NoDataIndication />
                )
            }
        </div>
    )
}

export default Notes