import React from 'react'
import PropTypes from 'prop-types'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Tooltip from '@material-ui/core/Tooltip'

import variables from 'tools/variables'

import ValidationError from 'components/forms/ValidationError'

import styles from './BaseDropdown.module.scss'

const BaseDropdown = ({
  label,
  name,
  id,
  className,
  disabled,
  required,
  error,
  tooltip,
  selectedOption,
  children
}) => {
  return (
    <FormControl
      className={`${styles.baseDropdownRoot} ${className}`}
      disabled={disabled}
      required={required}
      error={!!error}
    >
      {
        selectedOption && (
          <InputLabel
          className={`${styles.inputLabel} ${error ? styles.labelError : ''} ${selectedOption ? styles.materialLabel : ''}`}
          htmlFor={id || name}
        >
          {label}
        </InputLabel>
        )
      }
      {tooltip ? (
        <Tooltip
          title={tooltip.title}
          placement={tooltip.placement}
          enterDelay={variables.tooltipDelayMs}
          disableFocusListener
          disableTouchListener
        >
          {children}
        </Tooltip>
      ) : (
        children
      )}
      <ValidationError error={error} />
    </FormControl>
  )
}

BaseDropdown.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  tooltip: PropTypes.object,
  children: PropTypes.node // react-select Dropdown or AsyncDropdown
}

export default BaseDropdown
