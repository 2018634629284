import { isNumber } from 'tools/objectHelper'

export const number = value => (isNumber(value) ? parseFloat(value, 10) : (value===''?null:value))
export const bool = value => {
  if (value === 'true') {
    return true
  }
  if (value === 'false') {
    return false
  }
  return null
}
