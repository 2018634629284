import React, { useState, useEffect } from "react"
import { Card, Col, Row } from "react-bootstrap"
import { Button } from "@material-ui/core"
import Chart from "react-apexcharts"
import FMCSAsearch from "components/Applicants/partial/Modals/FMCSAsearch"
import {
  DonutChart,
  BarChart,
  Status,
  ApplicationValueChart,
  currency
} from 'constants/chartData'
import { currencyFormatter } from 'tools/helper'
import strings from "strings"
import "./Dashboard.scss"
import AuthService from "tools/AuthService"

const t = strings.DASHBOARD

/**
 * @param {Array} data Application overview data 
 * @returns Stateless Component
 */
const Overview = (props) => {
  const {
    data
  } = props

  return (
    <div className="overViewCardContainer">
      {
        data.map((item, index) => {
          return (
            <div key={index} className='cards'>
              <h5>
                {
                  currency.includes(item.name) && typeof item.value === "number" ?
                    `$${currencyFormatter(item.value)}`
                    : item.value || 0
                }
              </h5>
              <div className="imgContainer">
                <img src={require(`styles/images/icons/${item.icon}.svg`)} alt='icons' />
              </div>
              <p>{item.name}</p>
            </div>
          )
        })
      }
    </div>
  )
}


const Dashboard = (props) => {
  const {
    /* actions dispatching from the container 
     to handle dashboard and fmcsa API */
    getFmcsaData,
    getDashboardOverview,
    getDashboardApplication,
    getDashboardPortfolio,
    /* dashboard charts, overview and fmcsa data 
    fetched from redux state and 
    passed through container as prop */
    dispatchProcessing,
    fmcsaData,
    overview,
    applicationGrowth,
    portFolioGrowth,
    fmcsaParam,
    history,
    hideFmcsa
  } = props

  const [showModal, setModal] = useState(false)
  const isPlatformAdmin = AuthService.hasRole(strings.ROX_ADMIN)

  useEffect(() => {
    dispatchProcessing(true)
    getDashboardOverview(isPlatformAdmin).then(res => {
      getDashboardApplication(isPlatformAdmin).then(res => {
        getDashboardPortfolio(isPlatformAdmin).finally(() => {
          dispatchProcessing(false)
        })
      })
    })
  }, [getDashboardOverview, getDashboardApplication, getDashboardPortfolio, dispatchProcessing, isPlatformAdmin])

  const applicationDonut = {
    ...DonutChart,
    series: applicationGrowth?.openApplications
  }

  const applicationValueDonut = {
    ...ApplicationValueChart,
    series: applicationGrowth?.applicationsValue
  }

  const portfolioOptions = {
    ...BarChart(portFolioGrowth?.months),
    series: [{
      name: 'New Applications',
      data: portFolioGrowth?.new
    }, {
      name: 'Completed Applications',
      data: portFolioGrowth?.completed
    }]
  }

  const handleCreateApplicationManually = () => {
    history.push(`/create-applicant`)
  }

  return (
    <div className="dashboard">
      <Row>
        <Col md={6} className="text-left">
          <h4>{t.DASHBOARD}</h4>
        </Col>
        {
          !isPlatformAdmin && <Col xs={12} md={6} className="text-right">
            {!AuthService.isReadOnlyUser() && <Button
              variant='contained'
              color='primary'
              onClick={() => {
                if (hideFmcsa) {
                  handleCreateApplicationManually()
                } else {
                  setModal(true)
                }
              }}
              startIcon={<img src={require(`styles/images/icons/createBtn.svg`)} alt='open' />}
            >
              {t.CREATE_APPLICATION}
            </Button>}
          </Col>
        }
      </Row>
      <Row className="mt-4">
        <Col className="mb-3 pl-3 pr-0" xl={6}>
          <Card>
            <Card.Body className='chartHeight'>
              <Card.Title className='cardTitle'>{isPlatformAdmin ? t.ROX_WRITE_GROWTH : t.PORTFOLIO_GROWTH}</Card.Title>
              <div className="barContainer">
                {portfolioOptions?.series[0]?.data && portfolioOptions?.series[1]?.data &&
                  <div className="barChart">
                    <Chart
                      options={portfolioOptions.options}
                      series={portfolioOptions.series}
                      type='bar'
                    />
                  </div>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mb-3 pl-3 pr-3" xl={6}>
          <Card>
            <Card.Body className='chartHeight'>
              <div className="applicationTitle">
                <Card.Title className='cardTitle'>
                  {t.APPLICATIONS}
                </Card.Title>
                <Card.Title className='cardTitle'>
                  {t.APPLICATIONS_VALUE}
                </Card.Title>
              </div>
              {applicationDonut.series && applicationValueDonut.series && (
                <div className="donutContainer">
                  <Chart options={applicationDonut} series={applicationDonut.series} type="donut" />
                  <Chart options={applicationValueDonut} series={applicationValueDonut.series} type="donut" />
                </div>
              )}
              <div className="colorCodeContainer">
                {
                  Status.map(item => (
                    <div key={item.status}>
                      <div style={{ backgroundColor: item.code }} className='colorCode'></div>
                      <span>{item.status}</span>
                    </div>
                  ))
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body className='cardBodyAutoHeight'>
          <Card.Title className='cardTitle'>{isPlatformAdmin ? t.ROX_WRITE_OVERVIEW : t.PORTFOLIO_OVERVIEW}</Card.Title>
          <Overview
            data={overview}
          />
        </Card.Body>
      </Card>
      {
        !isPlatformAdmin && <FMCSAsearch
          show={showModal}
          onHide={() => setModal(false)}
          getFmcsaData={getFmcsaData}
          fmcsaData={fmcsaData}
          queryUrl={fmcsaParam}
          history={history}
        />
      }
    </div>
  );
}

export default Dashboard;
