import Drawer from "../../../shared/Drawer";
import React, {useState} from "react";
import BackgroundReportOwnerJsx from "./BackgroundReportOwnerJsx";
import strings from "../../../../strings";
import { Toast } from "components/shared/Toaster"
import { Button, CircularProgress } from "@material-ui/core"
import {attachFile, downLoadAttachment} from "../../../../actions/applicants";

const bgvString = strings.APPLICANT.BGV
const title = strings.APPLICANT.DETAIL_REPORT

const BackgroundReportDrawer = (props) => {
    const {
        applicationId,
        open,
        onClose,
        data
    } = props

    const [loading, setLoading] = useState({download: false, attach: false})
    const pdfReportUuid = data?.response?.pdfReportUuid

    const handleDownload = () => {
        setLoading(prev => ({ ...prev, download: true }))
        downLoadAttachment(applicationId, pdfReportUuid, 'download.pdf')()
            .finally(() => setLoading(prev => ({ ...prev, download: false })))
    }

    const handleAttach = () => {
        setLoading(prev => ({ ...prev, attach: true }))
        attachFile(applicationId, { pdfReportUuid })()
            .then(() => Toast({ type: 'success', message: bgvString.ATTACH_SUCCESS_MSG }))
            .finally(() => setLoading(prev => ({ ...prev, attach: false })))
    }

    const detailedReportActions = (<div>
        <span title={!pdfReportUuid ? bgvString.HINT : ''}>
            <Button color='primary' variant='outlined' disabled={loading.download || !pdfReportUuid} onClick={handleDownload} className='mr-2'>
                {loading.download ? <CircularProgress size={24} color='inherit' /> : strings.DOWNLOAD_PDF}
            </Button>
            <Button color='primary' variant='outlined' disabled={loading.attach || !pdfReportUuid} onClick={handleAttach} className='mr-2'>
                {loading.attach ? <CircularProgress size={24} color='inherit' /> : strings.ATTACH_PDF}
            </Button>
        </span>
    </div>)

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title={title}
            actions={detailedReportActions}
        >
            {data?.response?.entityType === 'OWNER' && BackgroundReportOwnerJsx({data})
            }
        </Drawer>
    )
}

export default BackgroundReportDrawer