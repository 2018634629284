import React from 'react';
import strings from 'strings';
import { Dialog } from 'components/shared/Dialog';
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import { maxLength50, phoneNumberValidation, validateEmail } from 'tools/forms/validators';
import { InputField } from 'components/forms/reduxForm';

export default function SalesRepresentativeForm(props) {
    const {
        open,
        onClose,
        onSubmit,
        mode = "Create",
        loading,
        submitting,
        pristine,
    } = props;

    return (
        <Dialog open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>
                {mode} {strings.SETTINGS_FORM.SALES_REPRESENTATIVE}
            </DialogTitle>
            <DialogContent>
                <InputField
                    name='firstName'
                    label={strings.LABEL.FIRST_NAME}
                    required
                    validate={maxLength50}
                    indicateRequired
                />
                <InputField
                    name='lastName'
                    label={strings.LABEL.LAST_NAME}
                    required
                    validate={maxLength50}
                    indicateRequired
                />
                <InputField
                    name='phoneNumber'
                    label={strings.LABEL.PHONE}
                    validate={phoneNumberValidation}
                    required
                    isphoneNumber
                    indicateRequired
                />
                <InputField
                    name='email'
                    label={strings.LABEL.EMAIL}
                    validate={validateEmail}
                    required
                    indicateRequired
                />
            </DialogContent>
            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {strings.CANCEL}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    disabled={loading || submitting || pristine}
                >
                    {loading ? <CircularProgress color='inherit' size={24}/> : strings.Save}
                </Button>
            </DialogActions>
        </Dialog>
    );
};