import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import PropTypes from 'prop-types'
import styles from './dateRange.module.scss'

const DateRangePicker = (props) => {
  const {
    // startDate,
    // endDate,
    // onChange,
    // showCalendarIcon,
    label,
    disabled,
    className,
    onChange,
    value=[null, null]
  } = props
  const [dateRange, setDateRange] = useState(value);
  const [startDate, endDate] = dateRange;

  const handleDateChange = (date) => {
    const [start, end] = date
    setDateRange(date);
    if(start && end) {
      onChange(date)
    } else {
      onChange(null)
    }
  }

  return (
    <FormControl className={styles.formControl}>
      <InputLabel
        className={`${styles.inputLabel}`}
        shrink={true}
      >
        {label}
      </InputLabel>
      <div
        className={`${styles.dateTimePicker} ${className ? className : ''}`}
      >
        <div
          className={`${styles.dateWrapper}`}
        >
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            disabled={disabled}
            onChange={(update) => {
              handleDateChange(update);
            }}
            // withPortal
          />
          {/* {showCalendarIcon && (
            <div className={styles.calendarIcon}>
              <CalendarTodayIcon />
            </div>
          )} */}
        </div>
      </div>
    </FormControl>
  )
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

DateRangePicker.defaultProps = {
  startDate: new Date(),
  endDate: null,
  disabled: false,
  onChange: () => { },
  showCalendarIcon: true,
  label: 'Start - End'
}

export default DateRangePicker

