import React, { useState } from 'react';
import SosSearchFields from './SearchViewComponents/SosSearchFields';
import CurrentSearch from './SearchViewComponents/CurrentSearch';
import PreviousSearches from './SearchViewComponents/PreviousSearches';
import strings from 'strings';
import { Toast } from 'components/shared/Toaster'
import { sosQuickSearch, requestSosSearch } from 'actions/applicants'
import SavedRecords from './SearchViewComponents/SavedRecords';

const sosStrings = strings.APPLICANT.SOS

const SearchView = (props) => {

    const { viewResult, isQuickSearch, applicantId } = props
    const performSearch = isQuickSearch ? sosQuickSearch : requestSosSearch

    // data
    const [currentSearch, setCurrentSearch] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)

    // control variables
    const [update, setUpdate] = useState(false) // update previous searches

    //functions 
    const handleSosSearch = (payload) => {
        setSearchLoading(true)
        setCurrentSearch(false)
        let info = { payload }
        if (!isQuickSearch) info.applicationId = applicantId
        performSearch(info)()
            .then(res => {
                setCurrentSearch(res)
                setUpdate(prev => !prev)
            })
            .catch(err => Toast({ type: 'error', message: sosStrings.SEARCH_ERROR }))
            .finally(() => setSearchLoading(false))
    }

    return (
        <>
            <div className='cardStyle mb-3'>
                <h5 className='mb-0'>{sosStrings.SOS}</h5>
                <SosSearchFields loading={searchLoading} handleSosSearch={handleSosSearch} isQuickSearch={isQuickSearch} />
                {
                    currentSearch &&
                    <CurrentSearch
                        currentSearch={currentSearch}
                        viewResult={viewResult}
                        search={handleSosSearch}
                        isQuickSearch={isQuickSearch}
                        applicantId={applicantId}
                    />
                }
            </div>
            <PreviousSearches update={update} viewResult={viewResult} search={handleSosSearch} searchLoading={searchLoading} isQuickSearch={isQuickSearch} applicantId={applicantId} />
            {applicantId && <SavedRecords applicantId={applicantId} viewResult={viewResult} />}
        </>
    )
}

export default SearchView