import React from 'react'
import InlineSvg from 'react-inlinesvg'
import PropTypes from 'prop-types';

export function Icon({ folder = 'root', fileName, onClick, title, className, width, height }) {
    let src = './images'
    if (folder !== 'root')
        src = `${src}/${folder}/${fileName}`
    else src = `${src}/${fileName}`

    const fileType = fileName.split('.')[1] || ''

    const additionalProps = {}
    if (width) additionalProps.width = width
    if (height) additionalProps.height = height

    return (
        <span
            className={`${className} justify-content-center`}
            onClick={onClick}
            title={title}
        >

            {fileType.toLowerCase() === 'svg' ?
                <InlineSvg
                    src={require(`${src}`)}
                    alt={fileName.split('.')[0]}
                    className='align-self-center'
                    cacheRequests={true}
                    {...additionalProps}
                /> :
                <img
                    src={require(`${src}`)}
                    alt={fileName.split('.')[0]}
                />
            }
        </ span>
    )
}

Icon.propTypes = {
    /**
     * Folder you want to look into
     */
    folder: PropTypes.string,
    fileName: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
};