import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    CircularProgress,
} from "@material-ui/core"
import { VisibilityOutlined } from '@material-ui/icons'
import InfoIcon from '@material-ui/icons/Info';
import { Radio } from 'components/forms/Radio'
import {
    FmcsaColumns
} from 'constants/application'
import {
    resetFmcsa, resetFmcsaData
} from 'actions/applicants'
import Drawer from 'components/shared/Drawer'
import FmcsaDrawer from '../Tabs/Summary/Summaries/SummaryDrawers/FmcsaDrawer'
import Input from 'components/forms/Input'
import emptyIcon from 'styles/images/icons/empty.svg'
import strings from 'strings'
import './fmcsaSearch.scss'

const NoDataIndication = () => (
    <div className='noDataAvailable'>
        <img src={emptyIcon} alt='empty' />
        <p>No Data Available</p>
    </div>
)

const FMCSAsearch = (props) => {
    const {
        show,
        onHide,
        getFmcsaData,
        getFmcsaDataByDOT,
        fmcsaData = [],
        history,
        type = '',
        showDetailedReport,
        closeDetailReport,
        detailData,
    } = props
    const [loading, setLoading] = useState(false)
    const [showDetailModal, setShowDetailModal] = useState(false)
    const [fmcsaDetail, setFmcsaDetail] = useState({})
    const [selectedValue, setSelectedValue] = useState('dot')
    const [inputValue, setInputValue] = useState({ carrierName: "", MC: "", DOT: "" })
    const [dotNumber, setDotNumber] = useState(detailData?.dotNumber)
    const dispatch = useDispatch()
    const fmcsaDrawerData = { content: { carrier: fmcsaDetail } }

    useEffect(() => {
        dispatch(resetFmcsa())
    }, [show, dispatch])

    useEffect(() => {
        setInputValue({ carrierName: "", MC: "", DOT: "" })
    }, [selectedValue, show])

    useEffect(() => {
        if (showDetailedReport !== undefined)
            setShowDetailModal(showDetailedReport)

        if (showDetailedReport !== undefined && detailData)
            setFmcsaDetail(detailData)

    }, [showDetailedReport, detailData])

    const handleChange = (e) => {
        const { id, value } = e.target
        setInputValue(val => ({ ...val, [id]: value })) // set carrierName or MC or DOT a value 
    }

    const handleSearch = () => {
        const {
            carrierName,
            MC,
            DOT
        } = inputValue

        if (carrierName || MC || DOT) {
            let params = {
                'carrierName:fuzzy': carrierName.trim()
            }
            if (DOT || MC) {
                params = {
                    ...params,
                    codeType: DOT ? 'DOT' : 'MC',
                    codeValue: DOT ? DOT : MC
                }
            }
            setLoading(true)
            getFmcsaData(params)
                .finally(() => setLoading(false))
        }
    }

    const handleCreateApplicationManually = () => {
        dispatch(resetFmcsaData())
        history.push(`/create-applicant`)
    }

    const handleRadioChange = (value) => {
        setSelectedValue(value);
    };

    const handleViewClick = (item) => {
        setLoading(true)
        let params = {
            codeType: 'DOT',
            codeValue: item.dotNumber
        }
        let mcNumbersArray = [];

        getFmcsaDataByDOT(params)
            .then(res => {
                if (res?.response?.extraContent?.mcNumbers) {
                    for (let i = 0; i < res.response.extraContent.mcNumbers.length; i++) {
                        mcNumbersArray.push(res.response.extraContent.mcNumbers[i]);
                    }
                }
                setFmcsaDetail({
                    ...res.response.content?.[0]?.carrier,
                    mcNumber: mcNumbersArray
                })
                setShowDetailModal(true)
                setDotNumber(item.dotNumber)
            })
            .finally(() => setLoading(false))
    }

    const fmcsaModal = (
        <FmcsaDrawer
            open={showDetailModal}
            onClose={() => {
                setShowDetailModal(false)
                if (closeDetailReport)
                    closeDetailReport()
            }}
            data={fmcsaDrawerData}
            fmcsaSearch
            dotNumber={dotNumber}
        />
    )

    const content = (
        <>
            <div className='p-3 pb-5'>
                {
                    loading && <CircularProgress className='circularLoader' />
                }
                <div>
                    <InfoIcon className="mr-2 mb-1" color="primary" />
                    <span>{strings.DASHBOARD.FMCSA_HINT}</span>
                </div>
                <div className='searchFieldContainer mb-2'>
                    <div className='d-flex align-items-end'>
                        <Radio
                            checked={selectedValue}
                            onChange={handleRadioChange}
                            value="bn"
                            size="small"
                            id="bname-radio"
                        />
                        <Input
                            id="carrierName"
                            label="Business Name"
                            onChange={(e) => handleChange(e)}
                            disabled={selectedValue !== "bn"}
                            value={inputValue.carrierName}
                        />
                    </div>

                    <div className='d-flex align-items-end'>
                        <Radio
                            checked={selectedValue}
                            onChange={handleRadioChange}
                            value="mc"
                            size="small"
                            id="mc-radio"
                        />
                        <Input
                            id="MC"
                            label="MC"
                            onChange={(e) => handleChange(e)}
                            disabled={selectedValue !== "mc"}
                            value={inputValue.MC}
                            type="number"
                        />
                    </div>

                    <div className='d-flex align-items-end'>
                        <Radio
                            checked={selectedValue}
                            onChange={handleRadioChange}
                            value="dot"
                            size="small"
                            id="dot-radio"
                        />
                        <Input
                            id="DOT"
                            label="DOT"
                            onChange={(e) => handleChange(e)}
                            disabled={selectedValue !== "dot"}
                            value={inputValue.DOT}
                            type="number"
                        />
                    </div>


                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearch}
                        disabled={!Boolean(inputValue.DOT.length || inputValue.MC.length || inputValue.carrierName.length)}
                    >
                        {strings.SEARCH}
                    </Button>
                </div>

                {
                    !type && <>
                        <div className='mt-3 mb-3 text-center'>{strings.OR}</div>

                        <div className='d-flex justify-content-center'>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleCreateApplicationManually()}
                            >
                                {strings.CREATE_APPLICATION_MANUALLY}
                            </Button>
                        </div>
                    </>
                }

                <div className='tableContainer'>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {
                                        FmcsaColumns.map((item, index) => (
                                            <TableCell key={index}>{item}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    fmcsaData.map((data, index) => {
                                        const item = data?.carrier
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{item.legalName}</TableCell>
                                                <TableCell>{item.dbaName}</TableCell>
                                                <TableCell>{item.phyStreet}{item.phyCity && `, ${item.phyCity}`}{item.phyState && `, ${item.phyState}`}</TableCell>
                                                <TableCell>{item.ein}</TableCell>
                                                <TableCell>{item.dotNumber}</TableCell>
                                                <TableCell>{item.statusCode}</TableCell>
                                                <TableCell><VisibilityOutlined onClick={() => handleViewClick(item)} /></TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className="useBtn"
                                                        onClick={() => history.push(`/create-applicant?codeType=DOT&codeValue=${item.dotNumber}`)}
                                                    >
                                                        {strings.SELECT}
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {
                        (fmcsaData.length === 0 && !loading) && (
                            <NoDataIndication />
                        )
                    }
                </div>
            </div>
            {fmcsaModal}
        </>
    )

    if (type === 'quick search' && showDetailedReport)
        return fmcsaModal

    if (type === 'quick search')
        return content

    return (
        <>
            <Drawer
                open={show}
                onClose={onHide}
                title={strings.DASHBOARD.SEARCH_FOR_BUSINESS}
            >
                {content}
                {fmcsaModal}
            </Drawer>
        </>
    )
}

export default FMCSAsearch
