import React from "react"
import "./AccountSummaryChart.scss"
import { mapAccountSummary } from 'constants/application'
import { formatCurrency, formatPercentage } from 'tools/formatters'

const AccountSummaryChart = (props) => {

    const { accountSummaryValues } = props

    return (
        <div>
            {
                mapAccountSummary(accountSummaryValues).map(data => (
                    <div key={data.title} className='boxSummary'>
                        <h5 className="summaryTitle"> {data.title} </h5>
                        <h5> {formatCurrency(data.current)} </h5>
                        <div style={{ display: 'flex' }}>
                            <p className={data.diff >= 0 ? 'positiveDiff' : 'negativeDiff'}> {formatPercentage(data.diff)} </p>
                            <p className="lastMonth"> {' (last month)'}</p>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default AccountSummaryChart;
