import { connect } from 'react-redux'
import ApplicantDetail from 'components/Applicants/ApplicationDetail/ApplicantDetail'
import {
    requestFmcsaDataByApplication,
    getFmcsaDataByApplication,
    downloadFMCSAReport,
    getApplicantsById,
    updateApplicationStatus,
    requestReports,
    getVerifications,
    getVerificationData,
    saveVerificationReport,
    attachVerificationReport,
    createNotes,
    getNotes,
    getUccList,
    getUccData,
    uccSearch,
    finishSearch,
    reopenSearch,
    attachFile,
    uploadFile,
    getFiles,
    deleteFile,
    downLoadAttachment,
    downloadAttachedFiles,
    getAttachmentPDF,
    getFilingDetails,
    getTerminationPreview,
    terminateUCC,
    savefiling,
    requestUccFilingImage,
    getSavedDetail,
    deleteReport,
    retrieveDocument,
    getDocumentStatus,
    getRetrieveDocuments,
    postFiling,
    getUccFilingDetailsByEntity,
    getUccFilingDetailsByFilingNumber,
    getUccFilingDetailsByFilingId,
    getUccFilingList,
    getJurisdictionList,
    getdirectAmendmentsList,
    getTemplates,
    filingPreview,
    performCreditSearch,
    getCompanyReport,
    saveReport,
    getCreditList,
    getBgvList,
    getConnectedBuisnessList,
    performBgvSearch,
    saveBgvReport,
    getDetailedBusinessReport,
    saveBuisnessReport,
    getConnectedBuisnessReport,
    saveConnectedBuisnessReport,
    requestBankDataAccess,
    downLoadAttachmentByFileId,
    uploadRoxFiles,
    getFilingReportsAttachments,
    requestAccounts,
    requestBankTransactions,
    requestRecurrentBankTransactions,
    getListOfAccounts,
    getListOfBankTransactions,
    getDataForInsights,
    getListOfRecurrentBankTransactions,
    getBankDataRequests,
    sendEmailToOwnerDueCurrentStatus,
    getRecurrentTransactionData,
    requestUCCForceUpdate,
    downloadFileByPayload,
    // Old Header ==========
    dispatchFormMode,
    createOwner,
    updateOwner,
    updateDebtor,
    updateBusiness,
    // =====================
} from 'actions/applicants'
import {
    dispatchNotification,
    dispatchProcessing,
    getSalesRepresentatives
} from 'actions/root'
import { OwnerDefaultForm, DefaultApplicationForm } from 'constants/application'

function mapStateToProps(stateProps, ownProps) {
    const { match } = ownProps
    const { applicantUUID } = match.params
    const { globalAccountSelected, refreshApplicant } = stateProps?.root
    const { applicantById, fmcsaData, extraContent, formMode, notesList, ucc, attachments, bgv, verifications, bankTransactions } = stateProps?.applicant
    const { attachmentList, headers } = attachments
    const { bankTransactionsList, headersBT, recurrentBankTransactionsList, headersRBT } = bankTransactions
    const { verificationList, verificationData } = verifications
    const { bgvList, connectedBuisnessList, searchResult, detailedBusinessReport } = bgv
    const modalMode = formMode?.mode
    const { business = {}, debtor = {}, owners = [], salesRepresentative = {} } = applicantById
    const ownerInfo = owners.filter(item => item.uuid === formMode.id)
    let initialValue = {}

    //Old Header ================================================
    const {
        dbaName,
        mcNumber,
        identifications = []
    } = applicantById?.business || {}
    const formValues = stateProps?.form?.editForm?.values || {}
    // ==========================================================

    switch (formMode.type) {
        case 'business':
            initialValue = { ...business, salesRepresentative: { uuid: salesRepresentative?.uuid } }
            break;
        case 'owner':
            initialValue = { ...ownerInfo[0] }
            break;
        case 'debtor':
            initialValue = { ...debtor }
            break;
        default:
            break;
    }

    if (modalMode === 'Create' ) {
        initialValue = formMode.type === 'owner' ? OwnerDefaultForm : DefaultApplicationForm.debtor
    }
    
    return {
        initialValues: {
            ...initialValue
        },
        applicationData: applicantById,
        uccList: ucc?.uccList,
        uccFilingList: ucc?.uccFilingList,
        directAmendmentList: ucc?.directAmendmentList,
        globalAccountSelected,
        fmcsaData,
        extraContent,
        refreshApplicant,
        applicantUUID,
        formMode,
        modalMode,
        notesList,
        attachmentList,
        totalRecords: headers["x-pagination-total-records"],
        totalPages: headers["x-pagination-total-pages"],
        bankTransactionsList,
        totalRecordsBT: headersBT?.["x-pagination-total-records"],
        totalPagesBT: headersBT?.["x-pagination-total-pages"],
        recurrentBankTransactionsList,
        totalRecordsRBT: headersRBT?.["x-pagination-total-records"],
        totalPagesRBT: headersRBT?.["x-pagination-total-pages"],
        bgvList,
        connectedBuisnessList,
        searchResult,
        detailedBusinessReport,
        verificationList,
        verificationData,
        // Old Header ========
        formValues,
        dbaName,
        mcNumber,
        identifications,
        // ===================
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        // Add all kind of dispatch functions here
        requestFmcsaDataByApplication: requestFmcsaDataByApplication, //action to request Data from FMCSA by application
        getFmcsaDataByApplication: getFmcsaDataByApplication, // action to get fmcsa data by applicationId from Rox DB
        downloadFMCSAReport, // action to download fmcsa report
        getApplicantsById, // action to get the applicants BYID
        dispatchNotification, // action to show notification
        requestUccFilingImage, // action to request ucc filing image
        dispatchProcessing,
        updateApplicationStatus, // action to update application status
        requestReports, // action to request reports and decisioner
        createNotes, // action to add notes
        getNotes, // action to get notes
        getVerifications, // action to get verification list
        getVerificationData, // action to get verification report
        saveVerificationReport, // action to save verification report
        attachVerificationReport, // action to attach report
        getUccList, // action to get ucc list
        getUccData, // action to get ucc search parameters
        uccSearch, // action to ucc search for business / owner
        finishSearch, // action to finish the ucc
        reopenSearch, // action to reopen the ucc
        attachFile, // action to attach pdf report to attachments
        uploadFile, // action to upload file
        getFiles, // action to get list of files
        deleteFile, // action to delete a file
        downLoadAttachment, // action to download file
        downloadAttachedFiles, // action to download zip file
        getAttachmentPDF, // action to get a attachment pdf
        getFilingDetails, // action to get filing details
        getTerminationPreview, // action to download report when there is no result
        terminateUCC, // action to terminate ucc
        savefiling, // action to save file
        getSavedDetail, // action to get saved detail
        deleteReport, // action to delete saved report
        retrieveDocument, // action to retrieve document
        getDocumentStatus, // action to get the document status
        getRetrieveDocuments, // action to get list of retrieve documents
        postFiling, // action to file UCC
        getUccFilingList, // action to get list of owners and business to be filed
        getJurisdictionList, // action to get Jurisdiction List
        getdirectAmendmentsList, // action to get direct Amendments
        getTemplates, // action to get template list
        filingPreview, // action to get filing preview
        getUccFilingDetailsByEntity, // action to get posted Filing info
        getUccFilingDetailsByFilingNumber, // action to get posted Filing info by filing number
        getUccFilingDetailsByFilingId, // action to get posted Filing info by filing id
        performCreditSearch, // action to perfrom credit search
        getCompanyReport, // action to get report
        saveReport, // action to perfrm save credit report
        getCreditList, // action to get credit list
        getBgvList, // action to get bgv list
        getConnectedBuisnessList, // action to get connected buisness list
        performBgvSearch, // action to search bgv
        saveBgvReport, // action to save bgv report
        getDetailedBusinessReport, // action to get detailed bgv report for business
        saveBuisnessReport, // action to save bgv busniess report
        getConnectedBuisnessReport, // action to get connected buisness report
        saveConnectedBuisnessReport, // action to save connected buisness report
        getSalesRepresentatives, // action to get list of salesRepresentatives
        downLoadAttachmentByFileId, // action to download single file
        requestUCCForceUpdate, //action to request UCC Force Update
        uploadRoxFiles, // action to upload filing reports
        getFilingReportsAttachments, // action to get filing reports
        requestBankDataAccess, // action to request bank transactions
        requestAccounts, // action to request owner accounts
        requestBankTransactions, // action to request bank transactions
        requestRecurrentBankTransactions, // action to request recurrent bank transactions
        getListOfAccounts, // action to get list of owner accounts
        getListOfBankTransactions, // action to get list of bank transactions
        getDataForInsights, // action to get data for insights
        getListOfRecurrentBankTransactions, // action to get list of recurrent bank transactions
        getBankDataRequests, // action to get list of bank data requests status
        sendEmailToOwnerDueCurrentStatus, // action to send email to owner due current status
        getRecurrentTransactionData, // action to get recurrent transaction data
        downloadFileByPayload, // action to download file by payload (used in PdfModal)
        // Old Header ================================================
        createOwner, // action to create owner
        updateOwner, // action to udpate owner info
        updateDebtor, // action to update debtor info
        updateBusiness, // action to update business info
        dispatchFormMode, // action to dispatch form mode
        // ===========================================================
    },
    mergeProps
)(ApplicantDetail)