import React from 'react'
import {momentDateFormatter} from '../../../../../../../../constants/application'
import RoxAccordion from '../../../../../../../shared/RoxAccordion'
import UccSearchContent from './UccSearchContent'

const UccSearchResultWrapperWithParams = ({ selectedEntity, applicationId, children, uccResponse }) => {


    return (
        <RoxAccordion
            summary= {
                <>
                    <div className="d-flex flex-grow-1 body-1-semibold">
                        <div className="mr-3">{uccResponse.searchOrganizationName ? "Business" :"Individual"}</div>
                        <div className="mr-3">{uccResponse.searchOrganizationName ? uccResponse.searchOrganizationName : uccResponse.searchFirstName + " " + uccResponse.searchLastName}</div>
                        <div className="mr-3">{uccResponse.searchState}</div>
                    </div>
                    <div className="body-1-semibold">Search Date: {momentDateFormatter(uccResponse.response.Header.OrderDate)}</div>
                </>
              }
            details={<UccSearchContent uccResponse={uccResponse} applicationId={applicationId} selectedEntity={selectedEntity} />}
            expanded={false}
        ></RoxAccordion>
    )
}

export default UccSearchResultWrapperWithParams
