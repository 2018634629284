import React from 'react'
import { Col, Row } from "react-bootstrap"
import './DataSummary.scss'
import PrepareTooltip from 'components/shared/Tooltip'


const DataSummary = (props) => {

    const { data } = props

    const tooltipDataGenerator = (item) => item.values?.join("&#10;");

    return (
        <Row className={props?.className}>
            {
                data.map((item, index) => {

                    var tooltipData = tooltipDataGenerator(item);
                    
                    return (
                        <Col key={index} md={4}>
                            {tooltipData ? 
                                < PrepareTooltip title={tooltipData} placement="top-start" theme="white" arrow>
                                    <p className='dataSummaryField mb-0'>{item.field}</p>
                                    <p className='dataSummaryValue'>{item.value}</p>
                                </PrepareTooltip> : 
                                <><p className='dataSummaryField mb-0'>{item.field}</p>
                                <p className='dataSummaryValue'>{item.value}</p>
                                </>
                            }

                        </Col>
                    )
                })
            }
        </Row>
    )
}

export default DataSummary