import React from "react"
import Chart from "react-apexcharts"
import strings from "strings"
import "./MonthlyStatementsChart.scss"
import { monthlyStatementsChart } from 'constants/chartData'
import NoDataIndication from 'components/shared/NoDataIndication'

const t = strings.APPLICANT.BT

const MonthlyStatementsChart = (props) => {

    const { monthlyStatementsValues } = props

    const monthlyStatementsLine = {
        ...monthlyStatementsChart(monthlyStatementsValues.monthNames),
        series: monthlyStatementsValues.series,
    }

    return (
        <div className="chartContainer">
            <h5 className='cardTitle'>
                {t.MONTHLY_STATEMENTS}
            </h5>
            {monthlyStatementsValues && monthlyStatementsValues.series.length > 0 ?
                <Chart options={monthlyStatementsLine.options} series={monthlyStatementsLine.series} type="line" />
                :
                <NoDataIndication className='mt-5' />
            }
        </div>
    );
}

export default MonthlyStatementsChart;
