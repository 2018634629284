import strings from 'strings'
import { statesOptions } from "constants/constantVariables"
import {fcsDatesFormatter} from "./application";

const getDataId = (reportType, entity) => {
    const type = reportType.replace('_', '-').replace('LEXIS-', '')
    return (type + '-' + entity).toLowerCase()
}

const getType = (tabValue) => {
    switch (tabValue) {
        case 0:
            return 'business'
        case 1:
            return 'owner'
        case 2:
            return 'debtor'
        default:
            return 'business'
    }
}

const getModalType = (type) => {
    switch (type) {
        case 'business':
            return strings.BUSINESS
        case 'debtor':
            return strings.DEBTOR
        default:
            return ''
    }
}

const getFormName = (formMode, modalType) => {
    switch (formMode) {
        case 'Create':
            return strings.CREATE + ' ' + modalType
        case 'Edit':
            return strings.EDIT + ' ' + modalType
        default:
            return ''
    }
}

const getOwnerFormName = (formMode) => {
    switch (formMode) {
        case 'Create':
            return strings.CREATE + ' ' + strings.OWNER
        case 'Edit':
            return strings.EDIT + ' ' + strings.OWNER
        default:
            return ''
    }
}

const getFormModePayload = (mode, type, uuid) => {
    const payload = { mode, type }
    if (uuid) {
        payload.id = uuid
    }
    return payload
}

const processStarted = (reports) => {
    const validReports = getValidReports(reports)
    return validReports.some(report => reportIndicatesProcessStarted(report))
}

const reportIndicatesProcessStarted = (report) => {
    // if there is at least one valid report with status different than NOT_DONE, the process is considered started
    return isValidReport(report) && report.status.value !== 'NOT_DONE'
}

const getValidReports = (reports) => {
    return reports.filter(report => isValidReport(report))
}

const isValidReport = (report) => {
    // FMCSA reports are not valid to check for process started
    return report.type !== 'FMCSA'
}

const getPaginationHeaders = (response) => {
    return {
        totalRecords: response?.headers?.['x-pagination-total-records'] || 0,
        totalPages: response?.headers?.['x-pagination-total-pages'] || 0,
    }
}

const reportDrawerSupported = (type, entityType) => {
    return (type === 'LEXIS_BACKGROUND' && entityType === 'OWNER') || type === 'FMCSA'
    || (type === 'LEXIS_VERIFICATION' && entityType === 'BUSINESS')
}

const getFmcsaPdfInfo = (infoFMCSA, extraContent, retrievalDate) => {
    let pdfInfo = { ...infoFMCSA, retrievalDate: retrievalDate}
    if (extraContent) {
        pdfInfo = { ...pdfInfo, mcNumber: extraContent?.mcNumbers }
    }
    return pdfInfo
}

const updateInitialValuesWithSosData = (initialValues, sosData) => {
    if (initialValues && sosData) {
        // business
        initialValues.business.name = sosData?.title
        initialValues.business.dbaName = sosData?.title
        initialValues.business.addresses[0].address1 = sosData?.physicalAddressStreet
        initialValues.business.addresses[0].countryCode = 'USA'
        initialValues.business.addresses[0].state = getSosState(sosData?.physicalAddressState)
        initialValues.business.addresses[0].city = sosData?.physicalAddressCity
        initialValues.business.addresses[0].zip5 = sosData?.physicalAddressZip
        initialValues.business.stateOfFormation = sosData?.stateOfFormation
        // owner
        initialValues.owners[0].address.address1 = sosData?.agentStreet
        initialValues.owners[0].address.countryCode = 'USA'
        initialValues.owners[0].address.state = sosData?.agentState
        initialValues.owners[0].address.city = sosData?.agentCity
        initialValues.owners[0].address.zip5 = sosData?.agentZip
        //sosId
        initialValues.sosInfo = {}
        initialValues.sosInfo.sosId = sosData?.sosId
        initialValues.sosInfo.sosSearchId = sosData?.sosSearchId
    }
    return initialValues;
}

const getSosState = (state) => {
    if (!state) return ''
    if (state.length === 2) return state

    const stateCode = statesOptions['USA'].find((s) => s.label === state)
    return stateCode ? stateCode.value : ''
}

const getQsTabByIndex = (index) => {
    switch (index) {
        case 0:
            return 'fmcsa'
        case 1:
            return 'ucc'
        case 2:
            return 'sos'
        default:
            return 'fmcsa'
    }
}

const getTabValue = (tab, hideFmcsa) => {
    let tabValue = 0
    if (tab) {
        switch (tab) {
            case 'fmcsa':
                tabValue = 0
                break
            case 'ucc':
                tabValue = 1
                break
            case 'sos':
                tabValue = 2
                break
            default:
                return 0
        }
    }
    if (tabValue === 0 && hideFmcsa) return 1
    return tabValue
}

const getClassNameForPreviousSearchStatus = (status) => {
    switch (status) {
        case 'Search Completed':
            return 'greenLabel'
        case 'Search requested, waiting for response...':
            return 'blueLabel'
        default:
            return 'redLabel'
    }
}

const removeParamsBasedOnEntityType = ({ data, entityType }) => {
    if (entityType === 'BUSINESS') {
        delete data.searchFirstName
        delete data.searchLastName
    } else {
        delete data.searchOrganizationName
    }
    return data
}

const buildUccSearchPayload = ({ data, entityType, entityId }) => {
    data = removeParamsBasedOnEntityType({ data, entityType })

    const payload = {
        includeInactiveFilings: data.includeInactiveFilings,
        date: data.date,
    }

    payload[entityType === 'BUSINESS' ? 'businessId' : 'ownerId'] = entityId
    payload.requestData = buildUccRequestData({ data, entityType })

    return payload
}

const buildUccRequestData = ({ data, entityType }) => {
    const requestData = {
        stateCode: data.stateCode,
        securedPartyMasking: data?.securedPartyMasking || false,
    }

    if (entityType === 'BUSINESS') {
        requestData.searchOrganizationName = data.searchOrganizationName
    } else {
        requestData.searchFirstName = data.searchFirstName
        requestData.searchLastName = data.searchLastName
    }

    return requestData
}

const getEntityNameFromUccResponse = (uccResponse) => {
    if (uccResponse.entityType === 'BUSINESS') {
        return uccResponse?.searchOrganizationName || ''
    }
    const name = uccResponse?.searchFirstName + ' ' + uccResponse?.searchLastName
    return name.trim()
}

const getIndexDateText = (indexDate) => {
    return strings.APPLICANT.UCC.INDEX_DATE + ' ' + (fcsDatesFormatter(indexDate) || '-' )
}

export {
    getDataId,
    getType,
    getModalType,
    getFormName,
    getOwnerFormName,
    getFormModePayload,
    processStarted,
    reportIndicatesProcessStarted,
    getPaginationHeaders,
    reportDrawerSupported,
    getFmcsaPdfInfo,
    updateInitialValuesWithSosData,
    getQsTabByIndex,
    getTabValue,
    getClassNameForPreviousSearchStatus,
    removeParamsBasedOnEntityType,
    buildUccSearchPayload,
    getEntityNameFromUccResponse,
    getIndexDateText
}