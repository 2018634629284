import moment from 'moment'

const equals = (value, reference) => {
  return value === reference
}

/** **** Less than *****/
const lt = (value, reference) => {
  if (
    typeof value === 'undefined' ||
    typeof reference === 'undefined' ||
    isNaN(value) ||
    isNaN(reference) ||
    value === null
  ) {
    return true
  }

  if (parseFloat(value) <= parseFloat(reference)) {
    return true
  }
  return false
}

/** **** Less than not equal *****/
const ltne = (value, reference) => {
  if (
    typeof value === 'undefined' ||
    typeof reference === 'undefined' ||
    isNaN(value) ||
    isNaN(reference) ||
    value === null
  ) {
    return true
  }

  if (parseFloat(value) < parseFloat(reference)) {
    return true
  }
  return false
}

/** **** Greater than *****/
const gt = (value, reference) => {
  if (
    typeof value === 'undefined' ||
    typeof reference === 'undefined' ||
    isNaN(value) ||
    isNaN(reference) ||
    value === null
  ) {
    return true
  }

  if (parseFloat(value) >= parseFloat(reference)) {
    return true
  }
  return false
}

/** **** Greater than not equal *****/
const gtne = (value, reference) => {
  if (
    typeof value === 'undefined' ||
    typeof reference === 'undefined' ||
    isNaN(value) ||
    isNaN(reference) ||
    value === null
  ) {
    return true
  }

  if (parseFloat(value) > parseFloat(reference)) {
    return true
  }
  return false
}

/** ***** Has Length *****/
const hasLength = (value, reference) => {
  if (!reference) {
    return true
  }

  if (!value) {
    return false
  }

  if (
    value.toString().length >= parseInt(reference) &&
    value.toString().length <= parseInt(reference)
  ) {
    return true
  }

  return false
}

/** ***** Shorter Than *****/
const shorterThan = (value, reference) => {
  if (!value || !reference) {
    return true
  }

  if (value.toString().length < parseInt(reference)) {
    return true
  }
  return false
}

/** ***** Longer Than *****/
const longerThan = (value, reference) => {
  if (!value || !reference) {
    return true
  }

  if (value.toString().length > parseInt(reference)) {
    return true
  }
  return false
}

/** ***** After Date *****/
const afterDate = (value, reference) => {
  if (!value || !reference) {
    return true
  }

  if (moment(value).isAfter(moment(reference))) {
    return true
  }
  return false
}

/** ***** Before Date *****/
const beforeDate = (value, reference) => {
  if (!value || !reference) {
    return true
  }

  if (moment(value).isBefore(moment(reference))) {
    return true
  }
  return false
}

/** ***** Meets given condition *****/
const meetsCondition = (value, condition) => {
  return condition
}

/** ***** Meets given regex expression *****/
const meetsRegex = (value, regExp) => {
  return regExp.test(String(value))
}

/** ***** Is Date Same Or After *****/
const isDateSameOrAfter = (referenceDate, date) => {
  return moment(date).isSameOrAfter(referenceDate) ? false : true
}

/** ***** Validates Email Formatting *****/
const validateEmail = (value, validate) => {
  const emailRegEx = /^[A-Za-z0-9._+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,8}$/i
  if (validate) {
    return value.length > 0
      ? emailRegEx.test(String(value).toLowerCase())
      : true
  }
  return true
}

/** Validates asending and decending order and returns the field */
const sortTypes = {
  up: { // Descending Order
    fun: (a, b, field = null) => {
      return (a[field] < b[field]) ? 1 : -1;
    }
  },
  down: { // Ascending Order
    fun: (a, b, field = null) => {
      return (a[field] < b[field]) ? -1 : 1;
    }
  }
};

export {
  equals,
  lt,
  ltne,
  gt,
  gtne,
  hasLength,
  shorterThan,
  longerThan,
  afterDate,
  beforeDate,
  meetsCondition,
  isDateSameOrAfter,
  validateEmail,
  meetsRegex,
  sortTypes,
}
