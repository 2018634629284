import React from "react"
import NotificationIcon from "styles/images/notificationicon.svg"
import "./notification.scss"

export function Notification() {

    return <div className="notificationContainer">
        <img src={NotificationIcon} alt="no data icon"/>
        <div>Stay tuned! Notification about your activity will
            show up here.</div>
    </div>
}