import request from 'tools/request'
import Auth from 'tools/AuthService'
export const SET_USER_LIST = 'SET_USER_LIST'
export const SET_USER_ById = 'SET_USER_ById'
export const SET_FORM_INITIALVALUE = 'SET_FORM_INITIALVALUE'
export const SET_USER = 'SET_USER'
export const SET_PROFILE_FORM_CONFIG = 'SET_PROFILE_FORM_CONFIG'

export const dispatchUsersList = (data, headers) => ({
    type: SET_USER_LIST,
    data,
    headers
})

export const dispatchUserById = (data) => ({
    type: SET_USER_ById,
    data
})

export const dispatchFormConfig = (data) => ({
    type: SET_FORM_INITIALVALUE,
    data
})

export const getUsersList = (filters = {}, pagination = {}, clientId = Auth.selectedClient, clientUuid) => async (dispatch) => {
    const query = {
        ...filters
    }
    const url = clientUuid ? `clients/${clientUuid}/users` : 'users'
    const res = await request(
        url,
        'GET',
        null,
        true,
        {
            'x-client-Id': clientId,
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10
        },
        query
    )
    dispatch(dispatchUsersList(res.response, res.headers))
    return res
}

export const getUserById = (clientId) => async (dispatch) => {
    const res = await request(`users/${clientId}`, 'GET')
    dispatch(dispatchUserById(res))
    return res
}

export const createUser = (formData, clientId = Auth.selectedClient) => async () => {
    const payLoad = {
        ...formData,
        userRoles: formData?.roles?.map(role => role.systemName)
    }
    const res = await request(
        'users',
        'POST',
        payLoad,
        true,
        {
            'x-client-Id': clientId,
        }
    )
    return res
}

export const updateUser = (formData, clientId = Auth.selectedClient) => async () => {
    const payLoad = {
        ...formData,
        userRoles: formData?.roles?.map(role => role.systemName)
    }
    delete payLoad.roles
    const res = await request(
        `users/${payLoad.userId}`,
        'PATCH',
        payLoad,
        true,
        {
            'x-client-Id': clientId,
        }
    )
    return res
}

export const updateProfile = (payLoad) => async () => {
    const res = await request(`user/update-me`, 'PATCH', payLoad)
    return res
}

export const updatePassword = (payLoad) => async () => {
    const res = await request(`user/change-password`, 'POST', payLoad)
    return res
}

export const toggleUser = (userId, active) => async () => {
    const endpoint = active ? 'disable' : 'enable'
    const res = await request(`users/${userId}/${endpoint}`, 'POST')
    return res
}