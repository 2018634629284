import React, {useCallback, useEffect, useState} from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility'
import GetAppIcon from '@material-ui/icons/GetApp';
import TableComponent from 'components/shared/TableComponent';
import Button from 'components/shared/Button';
import {CreditCheckColumn} from 'constants/application';
import strings from 'strings';
import './index.scss'
import VerificationDrawer from "../Summary/Summaries/SummaryDrawers/VerificationDrawer";

const t = strings.APPLICANT.VERIFICATION

export function Verification(props) {
    const {
        applicationId,
        verificationList,
        verificationData,
        getVerifications,
        getVerificationData,
        saveVerificationReport,
        downloadFile,
        updateSummaryCard
    } = props
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState({list: false, report: false, save: false, download: false, attach: false});
    const [selectedRow, setSelectedRow] = useState([])
    const [nonSelectable, setNonSelectable] = useState([])
    const [savedReport, setSavedReport] = useState(false)

    const getList = useCallback(() => {
        setLoading(prev => ({...prev, list: true}))
        getVerifications(applicationId)
            .finally(() => setLoading(prev => ({...prev, list: false})))
    }, [getVerifications, applicationId])

    useEffect(() => {
        getList()
    }, [getList])

    useEffect(() => {
        const disableRadio = []
        verificationList.forEach(data => {
            if (data.status === strings.STATUS_NAMES.Hit_Completed)
                disableRadio.push(data.entityUUID)
        })
        setNonSelectable(disableRadio)
    }, [verificationList])

    const fetchVerificationData = () => {
        setLoading(prev => ({...prev, report: true}))
        setOpen(true)
        const payLoad = {businessId: selectedRow[0]}
        getVerificationData(applicationId, payLoad)
            .then(getList)
            .finally(() => setLoading(prev => ({...prev, report: false})))
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect)
            setSelectedRow([row.entityUUID])
    }

    const handleSave = () => {
        setLoading(prev => ({...prev, save: true}))
        const payLoad = {
            businessId: selectedRow[0],
            reportUuid: verificationData.uuid
        }
        saveVerificationReport(applicationId, payLoad)
            .then(() => {
                getList()
                onClose()
            })
            .finally(() => 
            {
                setLoading(prev => ({...prev, save: false}))
                updateSummaryCard()
            })
    }

    const onClose = () => {
        setOpen(false)
        setSelectedRow([])
        setSavedReport(false)
    }

    const handleDownload = pdfReportUuid => {
        setLoading(prev => ({...prev, download: true}))
        downloadFile(applicationId, pdfReportUuid || verificationData.pdfReportUuid, 'verification-report.pdf')
            .finally(() => setLoading(prev => ({...prev, download: false})))
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: selectedRow,
        onSelect: handleOnSelect,
        hideSelectAll: true,
        nonSelectable
    }

    const columns = CreditCheckColumn.map((item, index) => {
        if (item.dataField === 'action')
            item = {
                ...item, formatter: (cell, row, rowIndex) => {
                    if (row.response?.BIID20ResponseEx?.response && row.status === strings.STATUS_NAMES.Hit_Completed)
                        return (
                            <>
                                <VisibilityIcon color='primary' title='View Changes' onClick={() => {
                                    setOpen(true)
                                    getVerificationData(null, null, row)
                                    setSavedReport(true)
                                }}/>
                                <GetAppIcon className='mt-3p ml-3' color='primary'
                                            onClick={() => !loading.download && handleDownload(row.pdfReportUuid)}/>
                            </>
                        )
                }
            }
        return item
    })

    return (
        <div className="uccContainer">
            <div className='uccTitleContainer'>
                <h5>{t.VERIFICATION}</h5>
            </div>

            <TableComponent
                data={verificationList}
                keyField='entityUUID'
                columns={columns}
                showPagination={false}
                loading={loading.list}
                selectRow={selectRow}
            />
            <div className='uccBtnContainer'>
                <Button onClick={fetchVerificationData} disabled={!selectedRow.length}>{t.PERFORM}</Button>
            </div>

            <VerificationDrawer
                open={open}
                onClose={onClose}
                applicationId={applicationId}
                data={verificationData}
                savedReport={savedReport}
                handleSave={handleSave}
                >
            </VerificationDrawer>
        </div>
    );
};