
export const offlineDocuments = (selectedFilings) => {
    const offlineFilings = selectedFilings.filter(filing => filing.numberOfPages === 0);
    if (offlineFilings.length === 0) {
        return [];
    }
    return mappedList(offlineFilings);
}

export const onlineDocuments = (selectedFilings) => {
    const onlineFilings = selectedFilings.filter(filing => filing.numberOfPages > 0);
    if (onlineFilings.length === 0) {
        return [];
    }
    return mappedList(onlineFilings);
}

function mappedList(listOfFilings) {
    let list = [];
    const initialFilingNumbers = [...new Set(listOfFilings
        .filter(filing => filing.initialFiling)
        .map(filing => filing.initialFiling)
        .sort())];

    initialFilingNumbers.forEach(initialFiling => {
        list.push({initialFilingNumber: initialFiling});
        let filings = listOfFilings.filter(filing => filing.initialFiling === initialFiling);
        list.push(...filings);
    })

    return list;
}