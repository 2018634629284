import React, { useEffect, useState } from 'react'
import './SosSearch.scss'
import SearchView from './SearchView'
import RecordDetailDrawer from '../../Applicants/partial/Tabs/SecretaryOfState/RecordDetailDrawer';
import { reset } from 'redux-form'
import { useDispatch } from 'react-redux'
import { dispatchSosRecord } from 'actions/applicants'
import { saveSosRecord } from 'actions/applicants'
import { Toast } from 'components/shared/Toaster'

const SosSearch = ({ isQuickSearch, applicantId = '', requestDecision, closeDrawer }) => {

    const [result, setResult] = useState({}) // search result
    const [isRecordDrawerVisible, setIsRecordDrawerVisible] = useState(false) // record drawer visibility
    const dispatch = useDispatch()

    const handleViewResult = (data) => {
        if (isQuickSearch) {
            dispatchSosRecord(data)(dispatch)
            dispatch(reset('QuickSearch'))
        }
        setResult(data)
        setIsRecordDrawerVisible(true)
    }

    const handleSaveRecord = () => {
        const payload = { sosSearchId: result.sosSearchId, sosId: result.sosId }
        saveSosRecord({ applicationId: applicantId, payload })()
            .then(res => {
                Toast({ type: "success", message: res?.message })
                requestDecision()
                closeDrawer()
            })
            .finally(() => {
                setIsRecordDrawerVisible(false)
            })
    }

    useEffect(() => {
        return () => {
            if (isQuickSearch) dispatch(reset('QuickSearch'))
        }
        // eslint-disable-next-line
    }, [])

    return (
        <div className={`${!isQuickSearch ? 'm-3' : ''}`}>
            <SearchView viewResult={handleViewResult} isQuickSearch={isQuickSearch} applicantId={applicantId} />
            {/* Drawer to show record details */}
            <RecordDetailDrawer
                openDrawer={isRecordDrawerVisible}
                setOpenDrawer={setIsRecordDrawerVisible}
                recordInfo={result}
                isQuickSearch={isQuickSearch}
                handleSaveRecord={!isQuickSearch ? handleSaveRecord : null}
            />
        </div>
    )
}

export default SosSearch