import React, { useState } from "react"
import { Tabs, Tab } from 'components/shared/Tabs'
import SosFilingsTabContent from "./SosFilingsTabContent";

const SosFilings = (props) => {

    const { tabsData } = props
    const [sosTabValue, setSosTabValue] = useState(0)

    const sosTabs = tabsData.map(item => ({ label: item.tabName }))
    const handleSosTabChange = (e, newValue) => { setSosTabValue(newValue) }

    return (
        <div>
            <Tabs
                data={sosTabs}
                value={sosTabValue}
                onChange={handleSosTabChange}
            />
            {
                tabsData.map((recordsByState, index) => {
                    return (
                        <Tab value={sosTabValue} index={index} key={index}>
                            <SosFilingsTabContent recordsByState={recordsByState} />
                        </Tab>
                    )
                })
            }
        </div>
    )
}

export default SosFilings