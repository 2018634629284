import React, { useEffect, useState } from 'react'
import SimplePagination from 'components/shared/SimplePagination.js/index'
import { getPaginationHeaders } from 'constants/utilityFunctions'
import { getSosSavedRecords } from 'actions/applicants'
import strings from 'strings'
import SavedRecordsColumns from './SavedRecordsColumns'
import SavedRecordCard from './SavedRecordCard'
import { Alert } from '@material-ui/lab'
import Loader from 'components/shared/Loader'

const sosStrings = strings.APPLICANT.SOS

const SavedRecords = (props) => {

    const { viewResult, applicantId } = props

    const [savedRecords, setSavedRecords] = useState([])

    const savedRecordsData = savedRecords?.response || []
    const savedRecordsPagination = getPaginationHeaders(savedRecords)

    const [paginationParams, setPaginationParams] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getSosSavedRecords(applicantId, paginationParams)()
            .then(res => {
                setSavedRecords(res)
            })
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationParams])

    return (
        <div className='cardStyle mt-3 mb-3'>
            <div className='uccTitleContainer mt-3'><h5 >{sosStrings.SAVED_RECORDS}</h5> </div>
            {loading ?
                <Loader />
                :
                (savedRecordsData?.length > 0 ?
                    <>
                        <SavedRecordsColumns />
                        {savedRecordsData.map((item) => (
                            <SavedRecordCard
                                key={item.uuid}
                                savedRecord={item}
                                applicantId={applicantId}
                                viewResult={viewResult}
                            />
                        ))}
                    </>
                    :
                    <Alert severity="warning">{sosStrings.NO_RECORDS_SAVED}</Alert>
                )
            }
            {savedRecordsPagination?.totalRecords > 0 &&
                <SimplePagination
                    onChangePagination={setPaginationParams}
                    totalRecords={savedRecordsPagination?.totalRecords}
                    totalPages={savedRecordsPagination?.totalPages}
                />
            }
        </div >
    )
}

export default SavedRecords