import React, {useEffect, useRef, useState} from "react"
import {cleanPlaidLocalStorage, exchangeToken} from "../../../../actions/plaid";
import Loader from "../../../shared/Loader";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {Alert} from "@material-ui/lab";
import strings from "../../../../strings";
import {usePlaidLink} from "react-plaid-link";
import {Icon} from "../../../../styles";
import PlaidError from "../utils/PlaidError";

const t = strings.PLAID
export function PlaidOAuthSuccess() {
    const redirect_url = window.location.href;
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    const appId = localStorage.getItem('aid');
    const ownerId = localStorage.getItem('oid');
    const linkToken = localStorage.getItem('rox_plaid_link_token');

    // TODO Change to Function
    const isDataStored = appId !== null && ownerId !== null && linkToken !== null;

    const config = {
        onSuccess: (public_token, metadata) => {
            exchangeToken({ aid: appId, oid: ownerId, public_token :public_token})()
                .then(() => {
                    //Cleaning local storage after auth
                    cleanPlaidLocalStorage();
                })
                .finally(() => {
                    setSuccess( true)
                })
        },
        onExit: (err, metadata) => {
            setError(true)},
        onEvent: (eventName, metadata) => {
            // console.log(eventName);
            },
        token : linkToken,
        receivedRedirectUri: redirect_url
    };

    const { open, ready } = usePlaidLink(config);

    const isDataStoredRef = useRef(isDataStored)

    useEffect(()=> {
        if (isDataStoredRef.current)
            open();
    }, [open, ready, isDataStoredRef])

    return <div className='d-flex align-items-center justify-content-center vh-100'>
        <div className='text-center p-5 plaid-wrapper'>
            <Icon className='d-block' width={166} height={63} folder='menuIcons' fileName='logo.svg' />
            {!isDataStored ?
                <PlaidError/> :
                <div className='text-center p-5 '>
                    {error ? <PlaidError/>  :
                        <div>
                            <Icon className='d-block mt-3' folder='icons' fileName='success.svg' />
                            {!success ?
                                <div>
                                    <h5 className='mt-3 mb-0'>{t.PROCEEDING_WITH_AUTH}</h5>
                                    <h5 className='mt-3 mb-0'>{t.SYNCING_WITH_ROX}</h5>
                                </div> :
                                <h5 className='mt-3 mb-0'>{t.LINK_SUCCESS}</h5>}
                            {!success ? <Loader/> :
                                <div>
                                    <h5 className='mt-3 mb-0'>{t.BANK_AUTHORIZED}</h5>
                                    <Alert className='mt-4' icon={<CheckCircleIcon fontSize="inherit"/>} severity="success">
                                        {t.GREAT_MSG}
                                    </Alert>
                                </div>
                            }
                        </div>
                    }
                </div>}
        </div>
    </div>
}

export default PlaidOAuthSuccess;