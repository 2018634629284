import React from 'react'
import Icon from '@material-ui/core/Icon'
import styles from './BaseDropdown/BaseDropdown.module.scss'

/**
 * Common UI options used for react-select dropdown
 * will be used in both Normal and Async dropdowns
 * @param {object} props the props passed to the component (don't forget to
 * update prop-types for both Normal and Async dropdowns when this changes)
 * @returns {object} options used in the react-select dropdown after being
 * processed
 */
export default function getCommonSelectOptions({
  className,
  size,
  disabled,
  error,
  placeholder,
  customSelect
}) {
  const DropdownIndicator = () => {
    return <Icon className={styles.dropdownIndicator}>arrow_drop_down</Icon>
  }

    const getClassName = (styles, className, disabled, error, size, customSelect) => {
        if (customSelect){
            return `${styles.custom_select} ${className} ${disabled ? styles.disabled : ''} 
            ${error ? styles.error : ''} ${size ? styles[size] : ''}`;
        }
        return `${styles.select} ${className} ${
            disabled ? styles.disabled : ''
        } ${error ? styles.error : ''} ${size ? styles[size] : ''}`;
    };

  return {
    className: getClassName(styles, className, disabled, error, size, customSelect),
    component: [{ DropdownIndicator }],
    placeholder,
    isDisabled: disabled,
    classNamePrefix: 'searchable-select',
    menuPlacement: 'auto'
  }
}
