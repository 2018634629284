import React, {Fragment} from 'react';
import {Grid} from '@material-ui/core';
import strings from 'strings';
import './index.scss'

const t = strings.APPLICANT.VERIFICATION

export function VerificationReportRisk(props) {
    const {riskIndicator} = props

    return (<>
            {riskIndicator.length &&
                <>
                    <div className='heading-style-2 mt-5'>{t.RISK_INDICATORS}</div>
                    <hr className='mb-1'/>

                    <Grid container spacing={2}>
                        <Grid className='verification-value' item sm={2}>{t.RISK_CODE}</Grid>
                        <Grid className='verification-value' item
                              sm={10}>{strings.APPLICANT.CLIENTS.DESCRIPTION}</Grid>
                        {riskIndicator?.map((risk, i) => <Fragment key={i}>
                                <Grid item sm={2}>{risk.RiskCode}</Grid>
                                <Grid item sm={10}>{risk.Description}</Grid>
                            </Fragment>
                        )}
                    </Grid>
                </>
            }
        </>
    );
}