import request from "tools/request"
import {dispatchUCCSearchId} from './applicants'

export const SET_UCC_SEARCH_RESULT = 'SET_UCC_SEARCH_RESULT'
export const SET_UCC_DETAILED_REPORT = 'SET_UCC_DETAILED_REPORT'

const dispatchUccSearchResult = data => ({
    type: SET_UCC_SEARCH_RESULT,
    data
})

const dispatchUccDetailedReport = data => ({
    type: SET_UCC_DETAILED_REPORT,
    data
})

export const performUccSearch = (payLoad, reset) => async (dispatch) => {
    if (reset) {
        dispatch(dispatchUCCSearchId(null))
        return dispatch(dispatchUccSearchResult(null))
    }

    const res = await request('quicksearch/ucc/search', 'POST', payLoad)
    dispatch(dispatchUccSearchResult(res?.response?.GroupedResults || []))
    dispatch(dispatchUCCSearchId(res?.uuid || []))
    return res
}

export const generateNoResultsReport = (payload) => async () => {
    return await request(`quicksearch/ucc/report/no-results`, 'POST', payload)
}

export const getFilingDetails = (payLoad, reset, data) => async (dispatch) => {
    if (reset || data)
        return dispatch(dispatchUccDetailedReport(data || {}))

    const res = await request('quicksearch/ucc/filingdetails', 'POST', payLoad)
    dispatch(dispatchUccDetailedReport(res || {}))
    return res
}