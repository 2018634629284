import { produce } from 'immer'
import { camelCase, cloneDeep, upperFirst } from 'lodash'
import {
    SET_APPLICANTS_LIST,
    SET_APPLICANT_BYID,
    SET_FMCSA_DATA,
    RESET_FMCSA_DATA,
    FORM_MODE,
    RESET_FMCSA,
    NOTES_LIST,
    UCC_INTERACTION_LIST,
    SET_ATTACHMENTS_LIST,
    SET_BANK_TRANSACTIONS_LIST,
    SET_RECURRENT_BANK_TRANSACTIONS_LIST,
    SET_CREDIT_SEARCH_RESULT,
    SET_CREDIT_REPORT_RESULT,
    SET_CREDIT_LIST,
    SET_UCC_FILING_LIST,
    SET_TEMPLATES_LIST,
    SET_UCC_PREVIEW,
    SET_BGV_LIST,
    SET_BGV_SEARCH_RESULT,
    SET_ACTIVE_LIST,
    SET_UCC_FILTERS,
    SET_DEBTORS,
    SET_DETAILED_BUSINESS_REPORT,
    SET_FORM_VALUES,
    SET_UCC_SEARCH_DATA,
    SET_UCC_RESPONSE,
    SET_UCC_SEARCH_PARAMETERS,
    SET_UCC_SEARCH_ID,
    SET_DOCUMENT_STATUS,
    SET_RETRIEVE_DOCUMENTS,
    SET_CONNECTED_BUSINESS_REPORT,
    SET_CONNECTED_BUISNESS_LIST,
    SET_VERIFICATION_LIST,
    SET_VERIFICATION_REPORT,
    SET_UCC3_PREVIEW,
    SET_TEMP_FILES_LIST,
    SET_DIRECT_AMENDMENT_LIST,
    SET_AMENDMENT_PREVIEW, 
    SET_UCC_SEARCH_INDEX_DATE, 
    SET_SOS_DATA,
    UPDATE_SUMMARY_CARD,
    UPDATE_DIRECT_AMENDMENTS,
} from 'actions/applicants'

const initialState = {
    applications: {
        applicationList: [],
        headers: {}
    },
    applicantById: {},
    fmcsaData: [],
    sosData: {},
    updateSummary: 0,
    fmcsaParam: {},
    extraContent: {},
    formMode: {},
    notesList: [],
    attachments: {
        attachmentList: [],
        headers: {}
    },
    bankTransactions: {
        bankTransactionsList: [],
        headersBT: {},
        recurrentBankTransactionsList: [],
        headersRBT: {}
    },
    verifications: {
        verificationList: [],
        verificationData: {}
    },
    ucc: {
        uccList: [],
        documentStatus: [],
        retrieveDocuments: [],
        uccFilingList: [],
        templateList: [],
        preview: {},
        uccSearchData: null,
        response: false,
        searchParameters: false,
        updateDirectAmendments: 0,
        uccSearchIndexDate: null,
        directAmendmentList: []
    },
    creditCheck: {
        creditList: [],
        searchResult: {},
        reportResult: {}
    },
    bgv: {
        bgvList: [],
        connectedBuisnessList: [],
        searchResult: {},
        detailedBusinessReport: {},
        connectedBusinessReport: []
    },
    clients: {
        activeList: [],
        activeHeaders: {},
        uccFilters: {
            filterObject: {},
            freeSearch: ''
        },
        debtors: {},
        formValues: {},
        ucc3Preview: {},
        amendmentPreview: {},
        tempFileList: [],
        tempFileheaders: {}
    }
}

const formatCreditReport = (reportResult) => {
    if (Object.entries(reportResult).length) {
        const clonedResult = cloneDeep(reportResult)
        delete clonedResult.negativeInformation.possibleOfac

        if (clonedResult?.additionalInformation?.extendedPaymentData?.tradePaymentsByCreditExtended)
            clonedResult.additionalInformation.extendedPaymentData.tradePaymentsByCreditExtended =
                Object.entries(clonedResult.additionalInformation.extendedPaymentData.tradePaymentsByCreditExtended).map(d => ({ ...d[1], credit: d[0] }))

        if (clonedResult?.negativeInformation)
            clonedResult.negativeInformation = Object.entries(clonedResult.negativeInformation).map(d => ({ ...d[1], type: d[0] }))

        if (clonedResult?.additionalInformation?.extendedPaymentData?.lifetimeHighBalanceChart)
            clonedResult.additionalInformation.extendedPaymentData.lifetimeHighBalanceChart =
                Object.entries(clonedResult.additionalInformation.extendedPaymentData.lifetimeHighBalanceChart).map(d => ({ ...d[1], duration: d[0] }))

        return clonedResult
    }
}

const formatAmendmentData = (data) => {
    if (data?.collateral?.description && data?.collateral?.attachments?.length) {
        const collateral = data.collateral.description
        const attachments = data.collateral.attachments
        data.collateral = { data: { collateral, attachments }, collateralType: 'both' }
        return data
    }
    if (data?.collateral?.description) {
        const collateral = data.collateral.description
        data.collateral = { data: { collateral }, collateralType: 'text' }
        return data
    }
    if (data?.collateral?.attachments?.length) {
        const attachments = data.collateral.attachments
        data.collateral = { data: { attachments }, collateralType: 'attachments' }
        return data
    }
    return data
}

const mergeConnectedBusnessReport = (data, action) => {
    if (typeof action === 'boolean')
        return []

    if (!data.find(d => d.key === action.key))
        data.push(action)
    return data
}

const formatUccSearchData = ({ data, savedData }) => {
    if (!savedData)
        return data

    const modifiedData = []
    if (data?.length) {
        data.forEach(({ uccGroupInformation, entityType, entityUUID, uuid, response }) => {
            let obj = {}
            Object.entries(uccGroupInformation || {}).map(entries => obj[upperFirst(camelCase(entries[0]))] = entries[1])
            obj = { ...obj, entityType, entityUUID, uuid }
            modifiedData.push({ Group: response.Results, ...obj })
        })
    }
    return modifiedData
}

const formatBgvList = data => data.map((d, i) => ({ ...d, refId: i + 1 }))

export const applicantReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_APPLICANTS_LIST:
                draft.applications.applicationList = action.data
                draft.applications.headers = action.headers
                break;
            case SET_APPLICANT_BYID:
                draft.applicantById = action.data
                break;
            case SET_VERIFICATION_LIST:
                draft.verifications.verificationList = action.data
                break;
            case SET_VERIFICATION_REPORT:
                draft.verifications.verificationData = action.data
                break;
            case UCC_INTERACTION_LIST:
                draft.ucc.uccList = action.data
                break;
            case SET_UCC_SEARCH_DATA:
                draft.ucc.uccSearchData = formatUccSearchData(action)
                break;
            case SET_UCC_RESPONSE:
                draft.ucc.response = action.data
                break;
            case SET_UCC_SEARCH_PARAMETERS:
                draft.ucc.searchParameters = action.data
                break;
            case SET_UCC_SEARCH_ID:
                draft.ucc.uccSearchId = action.data
                break;
            case SET_UCC_SEARCH_INDEX_DATE:
                draft.ucc.uccSearchIndexDate = action.data
                break;
            case SET_DOCUMENT_STATUS:
                draft.ucc.documentStatus = action.data
                break;
            case SET_RETRIEVE_DOCUMENTS:
                draft.ucc.retrieveDocuments = action.data
                break;
            case SET_UCC_FILING_LIST:
                draft.ucc.uccFilingList = formatBgvList(action.data)
                break;
            case SET_DIRECT_AMENDMENT_LIST:
                draft.ucc.directAmendmentList = action.data
                break;
            case SET_TEMPLATES_LIST:
                draft.ucc.templateList = action.data
                break;
            case SET_UCC_PREVIEW:
                draft.ucc.preview = action.data
                break;
            case SET_FMCSA_DATA:
                draft.fmcsaData = !(action.data && action.data[0]) ? [] : action.data
                draft.fmcsaParam = action.param
                draft.extraContent = !action.extraContent ? {} : action.extraContent;
                draft.uuid = action.uuid;
                break;
            case RESET_FMCSA_DATA:
                draft.fmcsaData = []
                draft.fmcsaParam = {}
                draft.extraContent = {}
                draft.uuid = null
                break;
            case NOTES_LIST:
                draft.notesList = action.data
                break;
            case FORM_MODE:
                draft.formMode = action.data
                break;
            case RESET_FMCSA:
                draft.fmcsaData = []
                break;
            case SET_ATTACHMENTS_LIST:
                draft.attachments.attachmentList = action.data
                draft.attachments.headers = action.headers
                break;
            case SET_BANK_TRANSACTIONS_LIST:
                draft.bankTransactions.bankTransactionsList = action.data
                draft.bankTransactions.headersBT = action.headers
                break;
            case SET_RECURRENT_BANK_TRANSACTIONS_LIST:
                draft.bankTransactions.recurrentBankTransactionsList = action.data
                draft.bankTransactions.headersRBT = action.headers
                break;
            case SET_CREDIT_SEARCH_RESULT:
                draft.creditCheck.searchResult = action.data
                break;
            case SET_CREDIT_REPORT_RESULT:
                draft.creditCheck.reportResult = formatCreditReport(action.data?.response?.report)
                break;
            case SET_CREDIT_LIST:
                draft.creditCheck.creditList = action.data
                break;
            case SET_BGV_LIST:
                draft.bgv.bgvList = formatBgvList(action.data)
                break;
            case SET_CONNECTED_BUISNESS_LIST:
                draft.bgv.connectedBuisnessList = action.data
                break;
            case SET_BGV_SEARCH_RESULT:
                draft.bgv.searchResult = action.data
                break;
            case SET_DETAILED_BUSINESS_REPORT:
                draft.bgv.detailedBusinessReport = action.data
                break;
            case SET_CONNECTED_BUSINESS_REPORT:
                draft.bgv.connectedBusinessReport = mergeConnectedBusnessReport(state.bgv.connectedBusinessReport, action.data)
                break;
            case SET_ACTIVE_LIST:
                draft.clients.activeList = action.data
                draft.clients.activeHeaders = action.headers
                break;
            case SET_UCC_FILTERS:
                draft.clients.uccFilters.filterObject = action.filters
                draft.clients.uccFilters.freeSearch = action.freeSearch
                break;
            case SET_DEBTORS:
                draft.clients.debtors = formatAmendmentData(action.data)
                break;
            case SET_TEMP_FILES_LIST:
                draft.clients.tempFileList = action.data
                draft.clients.tempFileheaders = action.headers
                break;
            case SET_FORM_VALUES:
                draft.clients.formValues = action.data
                break;
            case SET_UCC3_PREVIEW:
                draft.clients.ucc3Preview = action.data
                break;
            case SET_AMENDMENT_PREVIEW:
                draft.clients.amendmentPreview = action.data
                break;
            case SET_SOS_DATA:
                draft.sosData = action.data
                break;
            case UPDATE_SUMMARY_CARD:
                draft.updateSummary = state.updateSummary + 1
                break;
            case UPDATE_DIRECT_AMENDMENTS:
                draft.ucc.updateDirectAmendments = state.ucc.updateDirectAmendments + 1
                break;
            default:
                break;
        }
    })
}