import React, { useEffect, useState } from "react";
import {
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    CircularProgress,
} from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import AuthService from "tools/AuthService";
import {
    phoneNumberValidation,
    validateEmail,
    maxLength20
} from 'tools/forms/validators'
import { InputField, DropdownField, CheckboxField } from "components/forms/reduxForm";
import { statesOptions, businessTypeOption } from "constants/constantVariables";
import { Dialog } from "components/shared/Dialog";
import { PasswordValidator } from "components/PasswordValidation/PasswordValidator";
import strings from "strings";

const passwordValidation = (values, allValues) => {
    const newPassword = allValues["user"]["password"];
    if (newPassword === values) {
        return undefined;
    } else {
        return "Passwords dont match";
    }
};

const ClientForm = (props) => {
    const {
        open,
        onClose,
        onSubmit,
        mode = "Create",
        loading,
        submitting,
        pristine,
    } = props;
    const [openTooltip, setOpenTooltip] = useState(false)
    const [valid, setValid] = useState(false)
    const gs = useSelector(state => state)
    const { password } = gs.form?.clientForm?.values?.user || {};
    const userRoles = gs.root.rolesList.map(data => ({ label: data.name, value: data.roleId }))
    const isPlatformAdmin = AuthService.hasRole(strings.ROX_ADMIN)
    let title = `${mode} ${strings.CLIENT}`, clientPrefix = "";

    useEffect(() => {
        if (mode !== 'Create')
            setValid(true)
    }, [mode]);

    if (isPlatformAdmin) {
        title = `${mode === strings.EDIT ? strings.EDIT : ''} ${strings.CUSTOMER} ${strings.DETAILS}`
        clientPrefix = "client."
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth="md">
            <DialogTitle>
                {title}
            </DialogTitle>
            {loading ? (
                <div className="loaderContainer">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <DialogContent>
                        <Row>
                            <Col md={6}>
                                <InputField name={`${clientPrefix}name`}
                                    label="Name *"
                                    required
                                />
                            </Col>
                            <Col>
                                <DropdownField
                                    name={`${clientPrefix}businessType`}
                                    label="Business Type"
                                    options={businessTypeOption}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <InputField
                                    name={`${clientPrefix}address1`}
                                    label="Address *"
                                    required
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name={`${clientPrefix}city`}
                                    label="City *"
                                    required
                                />
                            </Col>
                            <Col>
                                <DropdownField
                                    name={`${clientPrefix}state`}
                                    label="State"
                                    options={statesOptions.USA}
                                    required
                                />
                            </Col>
                            <Col>
                                <InputField name={`${clientPrefix}zip5`}
                                    label="Zipcode *"
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <InputField
                                    name={`${clientPrefix}phone`}
                                    label="Phone *"
                                    validate={phoneNumberValidation}
                                    required
                                    isphoneNumber
                                />
                            </Col>
                            <Col md={6}>
                                <InputField
                                    name={`${clientPrefix}website`}
                                    label="Website"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <InputField
                                    name={`${clientPrefix}customFCSUsername`}
                                    label="FCS User Name"
                                />
                            </Col>
                            <Col md={6}>
                                <InputField
                                    name={`${clientPrefix}customFCSPassword`}
                                    label="FCS Password"
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}sprsByDefault`}
                                    label='Secured Party Masked'
                                />
                            </Col>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}clientReferenceRequired`}
                                    label='Client Reference Required'
                                />
                            </Col>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}clientReferenceUnique`}
                                    label='Client Reference Unique'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className='heading-style-2 font-weight-bold mt-2 ml-3'>
                                {strings.HIDDEN} {strings.APPLICATION} {strings.COLUMNS}:
                            </div>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}hideFmcsa`}
                                    label='FMCSA'
                                />
                            </Col>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}hideDecisioning`}
                                    label='Decisioning'
                                />
                            </Col>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}hideVerification`}
                                    label='Verification'
                                />
                            </Col>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}hideCreditCheck`}
                                    label='Credit Check'
                                />
                            </Col>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}hideBankTransactions`}
                                    label='Bank Transactions'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <div className='heading-style-2 font-weight-bold mt-2 ml-3'>
                                {strings.ADMIN.FEATURES_ENABLED}:
                            </div>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}sosSearchEnabled`}
                                    label='SOS Search'
                                />
                            </Col>
                            <Col md={4}>
                                <CheckboxField
                                    name={`${clientPrefix}newUccEnabled`}
                                    label='New UCC'
                                />
                            </Col>
                        </Row>
                    </DialogContent>

                    {mode === "Create" && isPlatformAdmin && (
                        <>
                            <DialogTitle>
                                {strings.USER} {strings.DETAILS}
                            </DialogTitle>

                            <DialogContent>
                                <Row>
                                    <Col md={3}>
                                        <InputField
                                            name="user.firstName"
                                            label="First Name *"
                                            required
                                        />
                                    </Col>
                                    <Col md={3}>
                                        <InputField
                                            name="user.lastName"
                                            label="Last Name *"
                                            required
                                        />
                                    </Col>
                                    <Col md={6}>
                                        <DropdownField
                                            name="roles[0]"
                                            label="Role"
                                            options={userRoles}
                                            getFullValue
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <InputField
                                            name="user.phone"
                                            label="Phone *"
                                            validate={phoneNumberValidation}
                                            isphoneNumber
                                            required
                                        />
                                    </Col>
                                    <Col>
                                        <InputField
                                            name="user.email"
                                            label="Email *"
                                            validate={validateEmail}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <PasswordValidator password={password} isValid={setValid} open={openTooltip} >
                                            <InputField
                                                name="user.password"
                                                label="New Password *"
                                                type="password"
                                                validate={maxLength20}
                                                required
                                                onFocus={() => setOpenTooltip(true)}
                                                onBlur={() => setOpenTooltip(false)}
                                            />
                                        </PasswordValidator>
                                    </Col>
                                    <Col>
                                        <InputField
                                            name="user.confirmPassword"
                                            label="Confirm Password *"
                                            type="password"
                                            validate={passwordValidation}
                                            required
                                        />
                                    </Col>
                                </Row>
                            </DialogContent>
                        </>
                    )}
                </>
            )}

            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {strings.CANCEL}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={valid ? onSubmit : null}
                    disabled={loading || submitting || pristine || !valid}
                >
                    {mode} {strings.CUSTOMER}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ClientForm;
