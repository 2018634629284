import React, { useState, useEffect, useCallback } from 'react'
import { reduxForm } from 'redux-form'
import { useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import { Col, Row, Card } from 'react-bootstrap'
import {
  Tabs,
  Tab
} from 'components/shared/Tabs'
import FilterBarModule from "components/shared/FilterBarModule"
import TableComponent from 'components/shared/TableComponent'
import { settingsTabs, customerColumns, customerFilters } from 'constants/roxAdminSettings'
import { ClientForm } from '../DialogForms'
import styles from '../ClientProfile/profile.module.scss'
import strings from 'strings'

const Settings = (props) => {
  const {
    getClientsList,
    handleSubmit,
    reset,
    clientsList, //action to get list of clients
    createClient, // action to create client
    resetClientEditForm, // action to reset client form
    updateClient, // action to update client by ID
    getClientById, // action to get clinet by ID
    totalRecords,
    totalPages,
    downloadReport, // action to download report
    getRolesList
  } = props
  const [tabValue, setTabValue] = useState(0)
  const [loading, setLoading] = useState(false)
  const [formLoader, setFormLoader] = useState(false)
  const [modal, setModal] = useState(false)
  const [formMode, setFormMode] = useState(strings.CREATE)
  const [columns, setColumns] = useState(() => {
    const action = {
      formatter: (cell, row, rowIndex) => (<img src={require('styles/images/icons/tableedit.svg')} onClick={() => handleUserEditForm(row)} className={styles.tableEditIcon} alt='edit' />)
    }
    const cols = customerColumns()
    cols.map((item, index) => {
      if (item.dataField === 'action') {
        cols[index] = { ...item, ...action }
      }
      return item
    })
    return cols
  })
  const { form } = useSelector(state => state);
  const { clientForm: { initial: { client } } } = form || {}

  /**
   * action to get list of clients for platform admin
   */
  const getClientsListData = useCallback((filters = {}, pagination = {}) => {
    setLoading(true)
    getClientsList(filters, pagination).finally(() => {
      setLoading(false)
    })
  }, [getClientsList])

  useEffect(() => {
    if (!modal) {
      reset('clientForm')
      resetClientEditForm()
    }
  }, [modal, reset, resetClientEditForm])

  useEffect(() => {
    getClientsListData()
    getRolesList()
  }, [getClientsListData, getRolesList])

  const handleUserEditForm = (row) => {
    setModal(true)
    setFormMode(strings.EDIT)
    setFormLoader(true)
    getClientById(row?.uuid).finally(() => {
      setFormLoader(false)
    })
  }

  const handlePagination = (pagination) => {
    getClientsListData({}, pagination)
  }

  const handleChange = (e, val) => {
    setTabValue(val)
  }

  const handleCustomer = () => {
    setModal(true)
    setFormMode(strings.CREATE)
  }

  const handleClientForm = (formData) => {
    setFormLoader(true)
    const formAction = formMode === strings.EDIT ? updateClient : createClient
    let data = { ...formData }

    if (formMode === strings.EDIT) {
      data = {}
      Object.entries(formData.client).forEach(entries => {
        data[entries[0]] = entries[1]
      })

      if (client.customFCSUsername === formData.client.customFCSUsername)
        delete data.customFCSUsername

      if (client.customFCSPassword === formData.client.customFCSPassword)
        delete data.customFCSPassword

    }
    formAction(data).finally(() => {
      setFormLoader(false)
      setModal(false)
      getClientsListData()
    })
  }

  return (
    <div>
      <Row>
        <Col md={6} className="text-left">
          <h4>{strings.SETTINGS}</h4>
        </Col>
        <Col md={6} className="text-right">
          <Button variant='contained' onClick={downloadReport} color='primary'>{strings.DOWNLOAD_REPORT}</Button>
        </Col>
      </Row>
      <Tabs
        data={settingsTabs}
        value={tabValue}
        onChange={handleChange}
      />
      <Tab value={tabValue} index={0}>
        <Card>
          <FilterBarModule
            title={strings.ADMIN.CUSTOMER_SETTINGS}
            filters={customerFilters}
            textLabel={'Filters'}
            columns={columns}
            receiveCustomColumn={(columns) => setColumns(columns)}
            filterParams={(params) => console.log('filter params', params)}
            btnText={strings.ADD_CUSTOMER}
            onBtnChange={handleCustomer}
          />
          <TableComponent
            data={clientsList || []}
            columns={columns}
            sort={true}
            loading={loading}
            totalRecords={totalRecords}
            totalPages={totalPages}
            onChangePagination={(pagination) => handlePagination(pagination)}
          />
        </Card>
      </Tab>
      <ClientForm
        open={modal}
        onClose={() => setModal(false)}
        onSubmit={handleSubmit(formData => handleClientForm(formData))}
        mode={formMode}
        loading={formLoader}
        {...props}
      />
    </div>
  )
}

export default reduxForm({
  form: 'clientForm',
  enableReinitialize: true
})(Settings)
