import React, { memo, useEffect, useState } from 'react'
import SummaryComponent from './Summaries/SummaryComponent/SummaryComponent'
import './Summary.scss'
import {getAvailableSummary, getSummaryReportByEntity} from 'actions/applicants'
import SosComingSoon from './Summaries/Components/SosComingSoon/SosComingSoon'
import { processStarted } from 'constants/utilityFunctions'
import ReportDrawer from './Summaries/SummaryDrawers/ReportDrawer'
import ScrollToTopButton from './Summaries/Components/ScrollToTopButton/ScrollToTopButton'

const Summary = memo(function Summary({ applicantId, updates, changeTab, isGetAllData,
                                          handleGetAllData, updateSummaryCard, setDisplayGAD,
                                          getRetrieveDocuments, getFilingReportsAttachments, dispatchProcessing, sosSearchEnabled}) {

    const summaryTabChange = (tabNumber) => changeTab(null, tabNumber);
    const [reportsAvailable, setReportsAvailable] = useState([])

    const [openDrawer, setOpenDrawer] = useState(false);
    const [infoForReportDrawer, setInfoForReportDrawer] = useState({});

    //useEffect to get available reports
    useEffect(() => {
        getAvailableSummary(applicantId)()
            .then((res) => {
                const filteredReports = res.filter(item => item?.status?.value !== 'NOT_IMPLEMENTED') || [];
                setReportsAvailable(filteredReports)
                setDisplayGAD(!processStarted(filteredReports))
            });
        // eslint-disable-next-line
    }, [applicantId, updates]);

    const showReportDrawer = (applicantId, entityId, type, entityType) => {
        getSummaryReportByEntity(applicantId, entityId, type)()
            .then((res) => {
                setInfoForReportDrawer({ data: res, reportType: type, entityType: entityType })
                setOpenDrawer(true)
            });
    }

    return (
        <div className='m-3'>
            {!sosSearchEnabled && <SosComingSoon summaryTabChange={summaryTabChange}/>}
            {
                applicantId && (reportsAvailable?.length > 0) && (
                    reportsAvailable.map((report, index) => {
                        return (
                            <SummaryComponent
                                key={index}
                                summaryTabChange={summaryTabChange}
                                showReportDrawer={showReportDrawer}
                                report={report}
                                applicantId={applicantId}
                                handleGetAllData={handleGetAllData}
                                isGetAllData={isGetAllData}
                                updateSummaryCard={updateSummaryCard}
                                setDisplayGAD={setDisplayGAD}
                                getRetrieveDocuments={getRetrieveDocuments}
                                getFilingReportsAttachments={getFilingReportsAttachments}
                                dispatchProcessing={dispatchProcessing}
                            />
                        )
                    }
                    )
                )
            }
            <ScrollToTopButton />
            {
                openDrawer &&
                <ReportDrawer
                    onClose={() => setOpenDrawer(false)}
                    applicantId={applicantId}
                    info={infoForReportDrawer}
                />
            }
        </div>
    )
}
)

export default Summary