import React from 'react'
import { Button } from "@material-ui/core"
import strings from 'strings'
import { joinAttributes } from 'tools/formatters';
import SearchResultInfo from './SearchResultInfo';
import PossibleAlternativeCard from './PossibleAlternativeCard';
import StatusIndicator from 'components/shared/StatusIndicator/index';

const sosStrings = strings.APPLICANT.SOS

const SearchResultCard = (props) => {

    const { searchResult, possibleAlternatives, viewResult, searchAlternative, searchLoading, state, isQuickSearch } = props

    const address = joinAttributes({ street: searchResult?.physicalAddressStreet, city: searchResult?.physicalAddressCity, state: searchResult?.physicalAddressState, zip: searchResult?.physicalAddressZip })
    const color = searchResult?.normalizedStatus === 'Active' ? 'green' : 'gray'

    return (
        <>
            <div className='d-flex justify-content-between'>
                <div className='flex-fill'>
                    <div className='d-flex'>
                        <p className='bold-font mb-0 mr-3'>{searchResult?.title}</p>
                        {searchResult?.normalizedStatus && <StatusIndicator color={color} text={searchResult?.normalizedStatus} side='left' />}
                    </div>
                    <p className='mb-0'>{address}</p>
                </div>
                <div className='d-flex justify-content-end align-items-center'>
                    <div className='greenLabel mr-2'> {sosStrings.BEST_MATCH} </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        className='mr-2'
                        onClick={() => viewResult(searchResult)}
                    >
                        {sosStrings.VIEW_MORE}
                    </Button>
                </div>
            </div>
            <SearchResultInfo searchResult={searchResult} possibleAlternatives={possibleAlternatives} />
            {
                possibleAlternatives?.length > 0 &&
                possibleAlternatives.map((item) => {
                    return (<PossibleAlternativeCard
                        key={item?.sosId}
                        alternative={item}
                        actionToPerform={searchAlternative}
                        actionText={sosStrings.RESEARCH}
                        state={state}
                        isQuickSearch={isQuickSearch}
                        actionLoading={searchLoading}
                    />)
                })
            }
        </>
    )
}

export default SearchResultCard