import React from 'react'
import { Select, MenuItem, InputLabel, FormControl } from '@material-ui/core'

const SimpleDropDownPicker = ({ options, label, selectedValue, setSelectedValue, labelField, valueField, fullWidth}) => {

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <FormControl variant="outlined" fullWidth={fullWidth}>
            <InputLabel id={`${label}-dropdown-select`}>{label}</InputLabel>
            <Select
                labelId={`${label}-dropdown-select`}
                value={selectedValue}
                label={label}
                onChange={handleChange}
            >
                {options.map((option, index) => (
                    <MenuItem key={index} value={option[valueField]}>
                        {option[labelField]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SimpleDropDownPicker