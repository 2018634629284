import React from "react";
import styles from "../../../../UCC/reports/detailReport/detailReport.module.scss";
import strings from "../../../../../../../../strings";
import Button from "../../../../../../../shared/Button";
import PrepareTooltip from "components/shared/Tooltip/index";


const FilingDetailsContainerTitle = ({selectedFilings, setDocumentRequest}) => {

    const requestDocumentsDisabled = selectedFilings.length === 0;

    return (
        <div className={styles.filingsContainer}>
            <h4 className='mb-0'>{strings.APPLICANT.UCC.FILINGS}</h4>
            <PrepareTooltip title={requestDocumentsDisabled ? strings.APPLICANT.UCC.SELECT_DOCUMENTS_TO_REQUEST : ''} theme='white'>
                <Button
                    variant="outlined"
                    color="primary"
                    className='summaryHeaderButton ml-3'
                    onClick={() => setDocumentRequest(true)}
                    disabled={requestDocumentsDisabled}
                >
                    {strings.APPLICANT.UCC.REQUEST_DOCUMENTS}
                </Button>
            </PrepareTooltip>
        </div>
    )
}

export default FilingDetailsContainerTitle;