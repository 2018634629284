import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { InputField } from 'components/forms/reduxForm'
import { maxLength20 } from 'tools/forms/validators'
import { PasswordValidator } from 'components/PasswordValidation/PasswordValidator'
import strings from 'strings'

const passwordValidation = (values, allValues) => {
    const newPassword = allValues['newPassword']
    if (newPassword === values) {
        return undefined
    } else {
        return 'Passwords dont match'
    }
}

const PasswordField = (props) => {
    const [valid, setValid] = useState(false)
    const [open, setOpen] = useState(false)
    const { newPassword } = useSelector(state => state?.form?.userProfileForm?.values) || {};

    const {
        onSubmit
    } = props
    return (
        <div>
            <Col md={6} className="text-left">
                <h6>{strings.CHANGE_PASSWORD}</h6>
                <InputField
                    name='oldPassword'
                    type='password'
                    label='Current Password *'
                    required
                />
                <PasswordValidator password={newPassword} isValid={setValid} open={open} >
                    <InputField
                        name='newPassword'
                        type='password'
                        label='New Password *'
                        validate={maxLength20}
                        required
                        onFocus={() => setOpen(true)}
                        onBlur={() => setOpen(false)}
                    />
                </PasswordValidator>
                <InputField
                    name='confirmPassword'
                    type='password'
                    label='Confirm Password *'
                    required
                    validate={passwordValidation}
                />
            </Col>
            <Row className='p-2'>
                <Col md={6} className="text-right">
                    <Button
                        variant="contained"
                        color='primary'
                        disabled={!valid}
                        onClick={valid ? onSubmit : null}
                    >
                        {strings.CHANGE_PASSWORD}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default PasswordField