import { connect } from 'react-redux'
import QuickSearch from 'components/QuickSearch';
import {
    performUccSearch,
    getFilingDetails,
} from 'actions/quickSearch'
import { getFmcsaData, getFmcsaDataByDOT, retrieveDocument, getRetrieveDocuments, downLoadAttachmentByFileId, getFilingReportsAttachments, uploadRoxFiles, } from 'actions/applicants';
import { dispatchProcessing } from 'actions/root'

function mapStateToProps(stateProps) {
    const { quickSearch, applicant, user } = stateProps
    const { fmcsaData } = applicant
    const { uccSearchResult, uccDetailedReport } = quickSearch.ucc
    const hideFmcsa = user?.userDetails?.securityContext?.client?.hideFmcsa || false;

    return {
        uccSearchResult,
        uccDetailedReport,
        fmcsaData,
        hideFmcsa
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        getFmcsaData,
        getFmcsaDataByDOT,
        performUccSearch,
        getFilingDetails,
        retrieveDocument,
        getRetrieveDocuments,
        downLoadAttachmentByFileId,
        getFilingReportsAttachments,
        uploadRoxFiles,
        dispatchProcessing
    },
    mergeProps
)(QuickSearch)