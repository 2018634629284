import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility'
import Moment from 'react-moment';
import clsx from 'clsx'
import ReactShowMoreText from 'react-show-more-text';
import {camelCase, startCase, upperFirst} from 'lodash';
import TableComponent from 'components/shared/TableComponent';
import Drawer, { DrawerAction } from 'components/shared/Drawer'
import Loader from 'components/shared/Loader';
import PrepareTooltip from 'components/shared/Tooltip';
import { Tab, Tabs } from 'components/shared/Tabs';
import { collateralRadios, mapPreviewContent, strOptions, statesRequiringTaxId } from 'constants/constantVariables';
import { AmendmentHistoryColumns, FilingImageColumns } from 'constants/application';
import strings from 'strings';
import './view.scss'
import '../ucc.scss'
import AuthService from "../../../../../../tools/AuthService";
import {Toast} from "../../../../../shared/Toaster";
import PdfModal from 'components/Applicants/partial/Dialog/PdfModal'

const statusName = strings.STATUS_NAMES
export function InfoView(props) {
    const {
        open,
        onClose,
        component = 'info',
        retryFiling,
        downLoadAttachment,
        downLoadAttachmentByFileId,
        downloadFileByPayload,
        getUccFilingDetailsByFilingId,
        getUccFilingDetailsByFilingNumber,
        requestUccFilingImage,
        applicationId,
        state,
        filingSelectedRow,
        requestUCCForceUpdate,
        getAttachmentPDF,
        isUCC3,
        hideDetails,
        ucc3Data = {},
        openUcc1View,
        hideAmendmentHistory,
        showAmendment,
        viewMode,
        loading
    } = props
    const [appTabValue, setAppTabValue] = useState(0);
    const uccFilingSelected = useSelector(state => state.applicant.ucc.preview);
    const {
        lapseDate,
        debtors = [],
        securedParties = [],
        collateral,
        filerReference,
        filingNumber,
        lastUpdatedDate,
        jurisdiction,
        altFilingType,
        altNameDesignation,
        maskSecuredParty,
        clientReference,
        initialFilingNumber,
        status,
        filingId,
        terminationDate
    } = (isUCC3 ? ucc3Data : uccFilingSelected || {})
    const { description, designation, attachments } = collateral || {}
    const allErrors = [];
    filingSelectedRow && filingSelectedRow.forEach(row => row.errors?.forEach(e => allErrors.push(e)));
    const errors = filingSelectedRow?.some(row => row?.errors?.length) ? allErrors : uccFilingSelected?.errors;
    const [openPDF, setOpenPDF] = useState(false)
    const [filingImage, setFilingImage] = useState((isUCC3 ? ucc3Data.filingImage : uccFilingSelected.filingImage))
    const [amendments, setAmendments] = useState((isUCC3 ? ucc3Data.amendments : uccFilingSelected.amendments || {}))

    const handleApplicationTabChange = (event, newValue) => {
        setAppTabValue(newValue)
    }

    const pdf = {
        uuid: filingImage?.uuid,
        fileName: filingImage?.fileName,
    }

    const formatText = (text) => {
        if (text) {
            if (text.toLowerCase().substring(0, 2) === 'no' && text !== 'NonUccFiling')
                return strings.APPLICANT.UCCFILING.NOT_SELECED
            return startCase(text)
        }
        return strings.APPLICANT.UCCFILING.NOT_SELECED
    }

    useEffect(() => {
        setAppTabValue(0)
    }, [debtors]);

    useEffect(() => {
        if (!isUCC3){
            setFilingImage(uccFilingSelected.filingImage)
        }
    }, [isUCC3, uccFilingSelected]);

    const amendmentHistoryColumns = AmendmentHistoryColumns.map(item => {
        if (item.dataField === 'action')
            item = {
                ...item, formatter: (cell, row) => {
                    return (
                        <VisibilityIcon color='primary' title='View Amendment' onClick={() => showAmendment && showAmendment(row)} />
                    )
                }
            }
        return item
    })

    const filingImageColumn = FilingImageColumns.map(item => {
        if (item.dataField === 'action')
            item = {
                ...item, formatter: (cell, row) => {
                    return (
                        <VisibilityIcon color='primary' title='View Filing Image' onClick={() => {
                            setOpenPDF(true)
                        }}/>
                    )
                }
            }
        return item
    })

    const filingImageTable = () => {
        return <div className='info mr-3'>

            <div className='heading'>{strings.APPLICANT.UCCFILING.FILING_IMAGE}</div>
            {filingImage &&
                <>
                    <TableComponent
                        keyField='uuid'
                        columns={filingImageColumn}
                        data={filingImage ? [filingImage] : []}
                        showPagination={false}
                    />
                    <PdfModal
                        open={openPDF}
                        onClose={() => setOpenPDF(false)}
                        getAttachmentPDF={getAttachmentPDF}
                        pdf={pdf}
                        onDownloadFile={downloadFileByPayload}
                    ></PdfModal>
                </>
            }
            {!AuthService.isReadOnlyUser() &&
                <div className='requestFilingImageButton'>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() =>
                            requestFilingImage(filingId)}
                    >
                        {strings.APPLICANT.UCCFILING.REQUEST_FILING_IMAGE}
                    </Button>
                </div>
            }
        </div>
    }

    function requestFilingImage(filingId) {
        requestUccFilingImage(filingId).then((res) => {
            Toast({ type: "info", message: res.message })
            getFilingData().then((res) => {
                setFilingImage(res?.filingImage)
                setAmendments(res?.amendments)
            })
        })
    }

    function getFilingData() {
        if (filingNumber){
            return getUccFilingDetailsByFilingNumber(filingNumber)
        } else {
            return getUccFilingDetailsByFilingId(filingId)
        }
    }

    const content = (
        <>
            <div className='preview-container'>
                {errors?.length && !isUCC3 ? < div className='m-3'>{errorView(errors)}</div> : null}
                {!hideDetails &&
                    <>
                        <div className='d-flex align-items-end mt-3 ml-4'>
                            <div className='preview-filing-upper-data'>
                                {
                                    filingNumber && <>
                                        <span className='heading-style-2 mr-2'>{strings.APPLICANT.UCCFILING.FILING_NUMBER} : {filingNumber}</span>
                                    </>
                                }
                                {
                                    lastUpdatedDate && <>
                                        <span className='heading-style-2 mr-2'>{strings.APPLICANT.UCCFILING.LAST_UPDATE} : <Moment format="MM/DD/YYYY">{lastUpdatedDate}</Moment></span>
                                    </>
                                }
                            </div>
                            <div className='preview-filing-upper-data'>
                                {
                                    lapseDate && <>
                                        <span className='heading-style-2 mr-2'>{strings.APPLICANT.UCCFILING.LAPSE_DATE} : <Moment format="MM/DD/YYYY">{lapseDate}</Moment></span>
                                    </>
                                }
                                {
                                    terminationDate && <>
                                        <span className='heading-style-2 mr-2'>{strings.APPLICANT.UCCFILING.TERMINATION_DATE} : <Moment format="MM/DD/YYYY">{terminationDate}</Moment></span>
                                    </>
                                }
                            </div>
                        </div>
                        {filingNumber && viewMode !== 'fromUcc' ?
                            <Alert severity="info" className='mx-3 my-1 mt-2'>
                                {strings.INFO_FILING_VIEW}
                            </Alert> : null}
                    </>}
                {!hideDetails && <div className='d-flex'>
                    <div className='info mw-47 pt-0'>
                        <Tabs
                            data={debtors?.map((debtor, i) => ({ label: `${strings.DEBTOR} ${i + 1}` }))}
                            value={appTabValue}
                            onChange={handleApplicationTabChange}
                        />

                        {debtors?.map((debtor, i) =>
                            <Tab value={appTabValue} index={i} key={i}>
                                <div className='details pt-2'>
                                    {mapPreviewContent(debtor, true).map((data, i) => (<Fragment key={i}>
                                        <div className='preview-label'>{data.title}</div>
                                        <div className='preview-value'>{data.value}</div>
                                    </Fragment>))}
                                </div>
                            </Tab>
                        )}
                    </div>

                    <div className='info mw-47'>
                        <div className='heading d-flex align-items-center'>
                            <span className={clsx(maskSecuredParty && component === 'preview' && 'opacity-5')}>{strings.APPLICANT.UCCFILING.SECURED_PARTY}</span>
                            {maskSecuredParty ?
                                <PrepareTooltip title={strings.APPLICANT.UCCFILING.MASK} theme='white'>
                                    <InfoOutlinedIcon className='ml-2' color='primary' />
                                </PrepareTooltip> : null}
                        </div>

                        <div className={clsx('details', maskSecuredParty && component === 'preview' && 'opacity-5')}>
                            {mapPreviewContent(securedParties[0] || {}, false).map((data, i) => (<Fragment key={i}>
                                <div className='preview-label'>{data.title}</div>
                                <div className='preview-value'>{data.value}</div>
                            </Fragment>))}
                        </div>

                    </div>
                </div>}

                <div className='info mr-3'>
                    <div className='heading'>{strings.APPLICANT.UCCFILING.FILE_POSTING}</div>

                    <div className='details'>
                        <div className='preview-label'>{strings.APPLICANT.UCCFILING.JURISDICTION} :</div>
                        <div className='preview-value'>{jurisdiction || '-'}</div>
                        {!hideDetails && <>
                            {attachments?.length ?
                                <>
                                    <div>{strings.APPLICANT.ATTACHMENTS.ATTACHMENTS} :</div>
                                    <div>
                                        {attachments.map(({ uuid, fileName }, i) => (
                                            <div
                                                key={i}
                                                className={clsx('preview-value mb-1', 'download-file')}
                                                onClick={() => applicationId ? downLoadAttachment(applicationId, uuid, fileName) : downLoadAttachmentByFileId(uuid, fileName)}
                                            >
                                                {fileName}
                                            </div>
                                        ))}
                                    </div>
                                </> : null}
                            {description && <>
                                <div className='preview-label'>{strings.APPLICANT.UCCFILING.COLLATERAL_DESCRIPTION} :</div>
                                <div className='preview-value'>
                                    <ReactShowMoreText
                                        lines={2}
                                        more="Show more"
                                        less="Show less"
                                        width={600}
                                        truncatedEndingComponent={"... "}
                                    >
                                        {description}
                                    </ReactShowMoreText>
                                </div>
                            </>
                            }
                        </>}

                        <div className='preview-label'>{collateralRadios[0].title} :</div>
                        <div className='preview-value'>{formatText(designation)}</div>

                        <div className='preview-label'>{strings.APPLICANT.UCCFILING.FILER_REFRENCE} :</div>
                        <div className='preview-value'>{filerReference || '-'}</div>

                        <div className='preview-label'>{strings.APPLICANT.UCCFILING.CLIENT_REFRENCE} :</div>
                        <div className='preview-value'>{clientReference || '-'}</div>

                        {isUCC3 && initialFilingNumber && openUcc1View ?
                            <>
                                <div className='preview-label'>{strings.APPLICANT.UCCFILING.UCC1_FILINGNUMBER} :</div>
                                <span className='preview-value link-text' onClick={() => openUcc1View(initialFilingNumber)}>{initialFilingNumber}</span>
                            </> : null}

                        <div className='preview-label'>{collateralRadios[1].title} :</div>
                        <div className='preview-value'>{formatText(altFilingType)}</div>

                        <div className='preview-label'>{collateralRadios[2].title} :</div>
                        <div className='preview-value'>{formatText(altNameDesignation)}</div>

                    </div>

                    {!hideDetails && <div className='mt-3 heading'>{strings.APPLICANT.UCCFILING.STR_HINT}</div>}

                    {!hideDetails && debtors.map((debtor, i) =>
                        <div className='details pt-2' key={i}>
                            <div className='preview-label'>
                                {strOptions.find(({ value }) => upperFirst(camelCase(value)) === debtor.searchToReflect)?.label || strOptions[0].label} :
                            </div>
                            <div className='preview-value'>
                                {debtor.organizationName || `${debtor.firstName} ${debtor.lastName}`}
                            </div>
                        </div>
                    )}

                    {!hideDetails && debtors.some(debtor => debtor.hasOwnProperty('taxId')) &&
                        <>
                            <div className='mt-3 heading'>{strings.APPLICANT.UCCFILING.TAX_ID}</div>
                            {debtors.map((debtor, i) =>
                                <div className='details pt-2' key={i}>
                                    <div className='preview-label'>
                                        {debtor.organizationName || `${debtor.firstName} ${debtor.lastName}`}:
                                    </div>
                                    <div className='preview-value'>
                                        {state ?
                                            (statesRequiringTaxId.includes(state) ? debtor.taxId || '---'
                                                :
                                                <p style={{ fontStyle: 'italic', marginBottom: '0px' }}>{strings.APPLICANT.UCCFILING.IGNORED}</p>
                                            )
                                            :
                                            debtor.taxId || '---'
                                        }
                                    </div>
                                </div>
                            )}
                        </>
                    }
                </div>

                {viewMode !== 'fromUccPosting' &&
                    filingImageTable()
                }

                {!hideAmendmentHistory && isUCC3 && <div className='info mr-3'>
                    <div className='heading'>{strings.APPLICANT.UCCFILING.AMENDMENT_HISTORY}</div>
                    <TableComponent
                        className='custom-filing-table mb-5'
                        keyField='filingId'
                        columns={amendmentHistoryColumns}
                        data={amendments || []}
                        showPagination={false}
                    />
                </div>}
            </div>
        </>
    )

    if (component === 'preview')
        return content

    const requestUCCUpdate = () => {
        requestUCCForceUpdate(filingId).then((res) => {
            Toast({ type: "info", message: res.message })
        })
    }

    return (
        <>
            <Drawer
                open={open}
                onClose={onClose}
                title={
                    <div className='ucc-drawer-upper-container'>
                        <div className='ucc-drawer-left-side'>
                            <h4 className='ucc-drawer-title'>{strings.APPLICANT.UCCFILING.PREVIEW}</h4>
                            {!loading && <div
                                className={clsx([statusName.Rejected, statusName.Preparation].includes(status) && 'filing-error', status === statusName.Pending && 'pending-state', 'success-info ml-3')}
                            >
                                {status === statusName.Rejected ? strings.UNSUCCESSFULL :
                                    status === statusName.Pending ? statusName.Pending :
                                        status === statusName.Preparation ? statusName.Preparation :
                                            statusName.Successful}
                            </div>}
                        </div>
                        {!AuthService.isReadOnlyUser() &&
                            <div className='ucc-drawer-right-side'>
                                <Button className='ucc-drawer-update-button'
                                        onClick={() => requestUCCUpdate()}
                                        color='primary'
                                        variant='contained'>
                                    {strings.APPLICANT.UCCFILING.REFRESH_FILING_DATA}
                                </Button>
                            </div>
                        }
                    </div>}
            >
                {loading ? <Loader /> :
                    <>
                        {props.children}
                        {content}
                        {[statusName.Rejected, statusName.Preparation].includes(status) && !isUCC3 &&
                            <DrawerAction>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={retryFiling}
                                >
                                    {strings.APPLICANT.UCCFILING.RETRY}
                                </Button>
                            </DrawerAction>
                        }
                    </>
                }
            </Drawer>

        </>
    )
}

export const errorView = (filingSelectedRow = []) => (
    <Alert severity="error">
        <AlertTitle className='heading-style-1'>{strings.APPLICANT.UCCFILING.ERROR_HEADING}</AlertTitle>
        <ol className='pl-4'>
            {filingSelectedRow.map((errors, i) => <li key={i} className='mt-1'>{errors}</li>)}
        </ol>
    </Alert>
)


