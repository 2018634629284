import React from 'react'
import strings from 'strings'

const sosStrings = strings.APPLICANT.SOS

const PreviousSearchesColumns = () => {
    return (
        <div className='searchCard mb-3 pl-3 pr-3'>
            <div className='bold-font'> {strings.BUSINESS} {strings.NAME} </div>
            <div className='bold-font'> {sosStrings.STATE} </div>
            <div className='bold-font'> {sosStrings.SEARCH_DATE} </div>
            <div className='bold-font'> {sosStrings.SEARCH_STATUS} </div>
        </div>
    )
}

export default PreviousSearchesColumns