import React, { useEffect } from 'react'
import strings from './page404Strings'
import { dispatchProcessing } from 'actions/root'
import { useDispatch } from 'react-redux'
import './page-404.scss'

const Page404 = () => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(dispatchProcessing(false))
  }, [dispatch])

  return (
    <div className="page-404">
      <div className="card">
        <h1>{strings.PAGE_404_TITLE}</h1>
        <h4>{strings.PAGE_404_SUBHEADER}</h4>
        <p>{strings.PAGE_404_MESSAGE}</p>
      </div>
    </div>
  )
}

export default Page404
