import React, { useState } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { ToastContainer } from 'components/shared/Toaster'
import LoginPage from 'components/Login/LoginPage'
import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css'
import HomeComponent from 'components/Home'
import ErrorBoundary from 'ErrorBoundary'
import { useDispatch } from 'react-redux'
import Processing from './components/shared/Processing/Processing'
import { getUserDetailByToken } from './actions/root'
import plaid from 'container/integerations/plaid'
import Auth from 'tools/AuthService'
import './App.scss'
import PlaidOAuthSuccess from "./components/Integerations/Plaid/Success/PlaidOAuthSuccess";
import PlaidSuccess from "./components/Integerations/Plaid/Success/PlaidSuccess";

const pendoIntegeration = ({ userId, email, firstName = '', lastName = '', securityContext }) => {
  if (runningInProd()) {
    window.pendo.initialize({
      visitor: {
        id: email,
        email,
        userName: `${firstName} ${lastName}`
      },
      account: {
        id: securityContext.client.clientId,
        name: securityContext.client.clientName
      }
    });
  }
}


const bootIntercomLogIn = ({ userId, email, firstName = '', lastName = '', securityContext }) => {
  if (runningInProd()) {
    window.Intercom('boot', {
      api_base: "https://api-iam.intercom.io",
      app_id: "i65u08km",
      email: email,
      userName: `${firstName} ${lastName}`,
      clientId: securityContext.client.clientId,
      clientName: securityContext.client.clientName
    });
  }
}

function hotjarIntegration({ userId, email, firstName = '', lastName = '', securityContext }) {
  if (runningInProd()) {
    window.hj('identify', userId, {
      name: `${firstName} ${lastName}`,
      email: email,
      client_id: securityContext.client.clientId,
      client_name: securityContext.client.clientName,
    });
  }
}

function runningInProd() {
  const currentUrl = window.location.href; 
  return currentUrl.startsWith("https://app.roxwrite.com") && process.env.NODE_ENV === 'production'
}

function mazeIntegration() {
  if (runningInProd()) {
    window.roxMazeScript(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '6827ffe6-2817-4e87-8424-dd8c9259a726')
  }
}

function App(props) {
  const {
    history
  } = props

  const [userLoggedIn, setUserLoggedIn] = useState(Auth.isLoggedIn())
  const [userInfo, setUserInfo] = useState(null)
  const dispatch = useDispatch()

  const initUser = (res) => {
    pendoIntegeration(res)
    bootIntercomLogIn(res)
    hotjarIntegration(res)
    mazeIntegration()
    Auth.loggedInUser = res
    setUserInfo(res)
    setUserLoggedIn(true)
  }

  const handleLogin = () => {
    const redirectUrl = localStorage.getItem('redirectUrl') || '/dashboard'
    dispatch(getUserDetailByToken()).then(res => {
      initUser(res)
      history.push(redirectUrl)
      localStorage.removeItem('redirectUrl')
    })
  }

  const pathName = window.location.pathname
  if (pathName !== '/login') {
    localStorage.setItem('redirectUrl', pathName)
  }

  if (!userInfo && Auth.isLoggedIn()) {
    dispatch(getUserDetailByToken()).then(res => {
      initUser(res)
    })
    return null
  }

  const redirectTOLogin = !['passwordRecovery', 'plaid'].some(path => history.location.pathname.includes(path))

  return (
    <ErrorBoundary>
      <div className='app'>
        <ToastContainer />
        <Processing />
        {redirectTOLogin && !userLoggedIn ? <Redirect {...props} to={{ pathname: '/login' }} /> : null}
        <Switch>
          <Route
              exact
              path={['/applications/:applicantUUID/owners/:ownerId/plaid']}
              component={plaid}
          />
          <Route
              exact
              path={['/applications/:applicantUUID/owners/:ownerId/plaid/:custom']}
              component={plaid}
          />
          <Route
              exact
              path={['/plaid-success']}
              component={PlaidOAuthSuccess}
          />
          <Route
              exact
              path={['/plaid-auth-success']}
              component={PlaidSuccess}
          />
          <Route
              exact
              path={['/plaid-auth-success/:custom']}
              component={PlaidSuccess}
          />
          {!userLoggedIn ? (
            <Route
              exact
              path={['/login', '/passwordRecovery/:email/:tokenId']}
              render={(props) => <LoginPage onLogIn={() => handleLogin()} {...props} />}
            />
          ) : (
            <>
              <Route
                exact
                path='/'
                render={props => (
                  <Redirect {...props} to={{ pathname: '/dashboard' }} />
                )}
              />
              <HomeComponent />
            </>
          )}
        </Switch>
      </div>
    </ErrorBoundary>
  )
}

export default withRouter(App)
