import React, {Fragment, useState} from 'react';
import KeyValueAccordionDetails from "./KeyValueAccordionDetails";
import RoxAccordion from '../RoxAccordion';
import {Radio} from '../../forms/Radio';

const SecuredPartySelector = ({ data, dataFormatter, titleFormatter, onEntitySelect, defaultExpanded }) => {
    const [selectedEntity, setSelectedEntity] = useState(data[0]);

    const generateKey = (entity) => {
        return entity.organizationName + entity.firstName + entity.lastName;
    }

    const handleCheckboxChange = (entity) => {
        if (selectedEntity === entity) return;
        setSelectedEntity(entity);
        onEntitySelect(entity);
    };

    return (
        <div>
            {data.map((entity, index) => (
                <Fragment key={index}>
                    <RoxAccordion
                        expanded={defaultExpanded}
                        summary={
                            <>
                            <Radio
                                    checked={generateKey(selectedEntity)}
                                    value={generateKey(entity)}
                                    onChange={(_) => handleCheckboxChange(entity)}
                                    isClearable={false}
                                    size='small'
                                />
                                <p className="body-1-semibold">
                                    {titleFormatter(entity)}
                                </p>
                            </>
                        }
                        details={<KeyValueAccordionDetails data={dataFormatter(entity)}/>} >
                    </RoxAccordion>
                </Fragment>
            ))}
        </div>
    );
};

export default SecuredPartySelector;