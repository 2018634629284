import React, {  useState } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux'
import { Tab, Tabs } from 'components/shared/Tabs';
import FMCSAsearch from 'components/Applicants/partial/Modals/FMCSAsearch';
import { Ucc } from './UCC';
import { quickSearchTabs } from 'constants/quickSearch';
import strings from 'strings';
import SosSearch from './SOS/SosSearch';
import SosLinks from 'components/Applicants/partial/Tabs/SecretaryOfState/SosLinks';
import { getQsTabByIndex, getTabValue } from 'constants/utilityFunctions';
import queryString from 'query-string';

const QS_STRING = strings.QUICK_SEARCH
function QuickSearch(props) {
    const {
        handleSubmit,
        getFmcsaData,
        getFmcsaDataByDOT,
        fmcsaData,
        performUccSearch,
        uccSearchResult,
        getFilingDetails,
        uccDetailedReport,
        reset,
        history,
        location,
        retrieveDocument,
        getRetrieveDocuments,
        downLoadAttachmentByFileId,
        getFilingReportsAttachments,
        uploadRoxFiles,
        dispatchProcessing,
        hideFmcsa
    } = props

    const searchAppTabValue = getTabValue(queryString.parse(location.search).tab, hideFmcsa)
    const [openDrawer, setOpenDrawer] = useState({ qs: false, ucc: false, fmcsa: false })
    const sosSearchEnabled = useSelector(state => state.user.userDetails.securityContext.client.sosSearchEnabled)

    const handleSearchTabChange = (event, newValue) => {
        history.push('?tab=' + getQsTabByIndex(newValue));
    }

    const handleClose = (refreshTable) => {
        setOpenDrawer(prev => ({ ...prev, qs: false }))
        performUccSearch(null, true)
        history.push('?tab=fmcsa');
        reset('QuickSearch')
    }

    const ucc = (
        <Ucc
            performUccSearch={performUccSearch}
            handleSubmit={handleSubmit}
            uccSearchResult={uccSearchResult}
            getFilingDetails={getFilingDetails}
            uccDetailedReport={uccDetailedReport}
            closeDrawer={handleClose}
            showDetailedReport={openDrawer.ucc}
            closeDetailReport={() => setOpenDrawer(prev => ({ ...prev, ucc: false }))}
            retrieveDocument={retrieveDocument}
            getRetrieveDocuments={getRetrieveDocuments}
            downLoadAttachmentByFileId={downLoadAttachmentByFileId}
            getFilingReportsAttachments={getFilingReportsAttachments}
            uploadRoxFiles={uploadRoxFiles}
            dispatchProcessing={dispatchProcessing}
        />
    )

    const fmcsa = (
        <FMCSAsearch
            type='quick search'
            getFmcsaData={getFmcsaData}
            getFmcsaDataByDOT = {getFmcsaDataByDOT}
            fmcsaData={fmcsaData}
            history={history}
            // show={openDrawer.fmcsa}
            showDetailedReport={openDrawer.fmcsa}
            closeDetailReport={() => setOpenDrawer(prev => ({ ...prev, fmcsa: false }))}
        />
    )

    const sos = (
        sosSearchEnabled ?
            <SosSearch isQuickSearch />
            :
            <div className='tabPanel min-height-75' >
                <SosLinks />
            </div>
    )

    return (
        <>
            <div className='d-flex justify-content-between'>
                <h4>{QS_STRING.QUICK_SEARCH}</h4>
            </div>

            <Tabs
                data={quickSearchTabs(hideFmcsa)}
                value={searchAppTabValue}
                onChange={handleSearchTabChange}
            />
            <Tab value={searchAppTabValue} index={0}>
                <div className='tabPanel min-height-75'>
                    {fmcsa}
                </div>
            </Tab>
            <Tab value={searchAppTabValue} index={1}>
                <div className='tabPanel min-height-75'>
                    {ucc}
                </div>
            </Tab>
            <Tab value={searchAppTabValue} index={2}>
                {sos}
            </Tab>
        </>
    );
};

export default reduxForm({
    form: 'QuickSearch',
    enableReinitialize: true
})(QuickSearch)
