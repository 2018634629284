import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import strings from 'strings';
import Tooltip from '@material-ui/core/Tooltip'

const MenuOptions = (props) => {
    const { entityType, disableAdd, hideAdd, dialogControl, uuid } = props
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleEditClick = () => {
        dialogControl('Edit', uuid)
        handleClose()
    }

    const handleAddClick = () => {
        dialogControl('Create')
        handleClose()
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem id='edit-option' onClick={handleEditClick}> {strings.EDIT + ' ' + entityType} </MenuItem>
                {!hideAdd &&
                    (disableAdd ?
                        <Tooltip title={strings.APPLICANT.SUMMARY.OWNERS_LIMIT}>
                            <MenuItem id='new-owner-disabled-option'>
                                <p style={{ color: 'grey' }}>
                                    {strings.ADD + ' ' + entityType}
                                </p>
                            </MenuItem>
                        </Tooltip>
                        :
                        <MenuItem id='add-option' onClick={handleAddClick}>
                            <p> {strings.ADD + ' ' + entityType} </p>
                        </MenuItem>)
                }
            </Menu>
        </div>
    );
}

export default MenuOptions
