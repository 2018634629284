import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio as MuiRadio } from '@material-ui/core';
import './radio.scss'

export function Radio(props) {
    const {
        label,
        checked,
        color,
        labelPlacement,
        isClearable,
        value,
        onChange,
        size,
        clearDefualtValue,
        className,
    } = props

    const radio = (
        <MuiRadio
            color={color}
            size={size}
            onClick={(event) => {
                event.stopPropagation()
                if (isClearable)
                    return onChange(checked === value ? clearDefualtValue || null : value)
                return onChange(value)
            }}
            value={value}
            checked={checked === value}
            className={className}
        />
    )

    if (!label)
        return radio

    return (
        <FormControlLabel
            className='custom-text'
            control={radio}
            label={label}
            labelPlacement={labelPlacement}
        />
    );
};

Radio.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.any,
    labelPlacement: PropTypes.oneOf(['bottom', 'end', 'start', 'top']),
    isClearable: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    size: PropTypes.oneOf(['small', 'medium']),
    onChange: PropTypes.func,
    /**
    * This only works when isClearable prop is true
    * Instead of null you can set defaut value
    */
    clearDefualtValue: PropTypes.string
};

Radio.defaultProps = {
    color: 'primary'
}