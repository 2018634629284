import React from 'react'
import { Alert } from '@material-ui/lab'
import Button from 'components/shared/Button'
import strings from 'strings'
const summaryStrings = strings.APPLICANT.SUMMARY

const AddDebtorAlert = (props) => {

    const { dialogControl } = props
    
    return (
        <Alert severity='info' className='mt-3 pt-3 pb-3'
            action={
                <Button
                    onClick={() => dialogControl('Create')}
                >
                    {strings.ADD_DEBTOR}
                </Button>
            }>
            {summaryStrings.ADD_DEBTOR}
        </Alert>
    )
}

export default AddDebtorAlert