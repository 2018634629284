import React, { useState, useEffect } from 'react'
import './ScrollToTopButton.scss'
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop'

const ScrollToTopButton = () => {

    const [isVisible, setIsVisible] = useState(false)

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        };
    }, []);

    return (
        <div className={`scroll-to-top-button ${isVisible ? 'show' : 'hide'}`} onClick={scrollToTop}>
            <VerticalAlignTopIcon fontSize='large' />
        </div>
    )
};

export default ScrollToTopButton
