import React, { useEffect, useState } from 'react';
import HelpIcon from '@material-ui/icons/Help';
import Report from 'components/Applicants/partial/Tabs/UCC/reports/report';
import DetailReport from 'components/Applicants/partial/Tabs/UCC/reports/detailReport';
import { Guide } from 'components/Applicants/partial/Tabs/UCC/reports/report/guide';
import { Radio } from 'components/forms/Radio';
import { statesOptions } from 'constants/constantVariables'
import strings from 'strings';
import './index.scss'

export function Ucc(props) {
    const {
        performUccSearch,
        handleSubmit,
        getFilingDetails,
        uccSearchResult,
        uccDetailedReport,
        showDetailedReport,
        closeDetailReport,
        retrieveDocument,
        getRetrieveDocuments,
        downLoadAttachmentByFileId,
        getFilingReportsAttachments,
        uploadRoxFiles,
        dispatchProcessing
    } = props
    const [entity, setEntity] = useState('BUSINESS');
    const [loading, setLoading] = useState({ search: false, detail: false, save: false })
    const [openDetailedReport, setOpenDetailedReport] = useState(false)
    const [openTooltip, setOpen] = useState(false)
    const [transactionID, setTransactionID] = useState()
    const [interactionId, setInteractionId] = useState("")
    const [searchInteractionId, setSearchInteractionId] = useState("")
    const [stateUccSearch, setStateUccSearch] = useState('')
    const [stateCodeUccSearch, setStateCodeUccSearch] = useState('')

    // useEffect triggers on first render to reset the ucc list from redux
    useEffect(() => {
        performUccSearch(null, true)
    }, [performUccSearch])

    useEffect(() => {
        setOpenDetailedReport(showDetailedReport)
    }, [showDetailedReport]);

    const handleRadioChange = value => setEntity(value)

    const handleSearch = (filter, requestData) => {
        if (entity === 'BUSINESS') {
            delete requestData.searchFirstName
            delete requestData.searchLastName
        }
        else delete requestData.searchOrganizationName

        const payLoad = { ...filter.search, requestData }
        setLoading(prev => ({ ...prev, search: true }))
        setStateUccSearch(getStateName(requestData.stateCode))
        setStateCodeUccSearch(requestData.stateCode)
        performUccSearch(payLoad)
            .then((res) => {
                setTransactionID(res?.response?.Header.TransactionID)
                setInteractionId(res?.uuid)
                setSearchInteractionId(res?.uuid)
            })
            .finally(() => setLoading(prev => ({ ...prev, search: false })))
    }

    const getStateName = (stateCode) => {
        const stateName = statesOptions['USA'].find(state => state.value === stateCode)?.label
        return stateName;
    }

    const handleFilingDetails = (filingNumbers) => {
        getFilingDetails(null, true)
        setLoading(prev => ({ ...prev, detail: true }))
        const payLoad = { filingNumbers, transactionID, state: stateCodeUccSearch }
        return getFilingDetails(payLoad)
            .then((res) => {
                setInteractionId(res?.uuid)
                setOpenDetailedReport(true)
            })
            .finally(() => setLoading(prev => ({ ...prev, detail: false })))
    }

    const detailReport = (
        <DetailReport
            open={openDetailedReport}
            onClose={() => {
                setOpenDetailedReport(false)
                setTimeout(() => {
                    closeDetailReport()
                }, 200);
            }}
            data={uccDetailedReport?.response?.Results}
            savedReport={false}
            reportDetails={uccDetailedReport}
            retrieveDocument={retrieveDocument}
            getRetrieveDocuments={getRetrieveDocuments}
            downLoadAttachmentByFileId={downLoadAttachmentByFileId}
            getFilingReportsAttachments={getFilingReportsAttachments}
            uploadRoxFiles={uploadRoxFiles}
            dispatchProcessing={dispatchProcessing}
            interactionId={interactionId}
            type='quick search'
            stateUccSearch={stateUccSearch}
        />
    )

    if (showDetailedReport)
        return detailReport

    return (
        <>
            <div className='h-100 qs-ucc-container'>
                <div className='ml-3 mt-2'>
                    <div className='d-flex align-items-center mb-2'>
                        <HelpIcon className='mr-1 cursor-pointer' color='primary' onClick={() => setOpen(true)} />
                        <span>{strings.QUICK_SEARCH.GUIDE}</span>
                    </div>

                    <Radio className='mb-0' label={strings.BUSINESS} value='BUSINESS' checked={entity} onChange={handleRadioChange} />
                    <Radio className='mb-0' label={strings.OWNER} value='OWNER' checked={entity} onChange={handleRadioChange} />
                </div>

                <Report
                    performUccSearch={handleSearch}
                    interactionId={searchInteractionId}
                    loading={loading.search}
                    getFilingDetails={handleFilingDetails}
                    detailLoading={loading.detail}
                    handleSubmit={handleSubmit}
                    uploadRoxFiles={uploadRoxFiles}
                    entity={entity}
                    type='quick search'
                    data={uccSearchResult}
                    isQuickSearch={true}
                    saveLoading={loading.save}
                />

                {detailReport}
            </div>

            <Guide open={openTooltip} onClose={setOpen} />
        </>
    );
};