import request from "tools/request"
export const SET_PLATFORM_PARTNER_LIST = 'SET_PLATFORM_PARTNER_LIST'
export const SET_PLATFORM_PARTNER_FORM_VALUES = 'SET_PLATFORM_PARTNER_FORM_VALUES'
export const SET_AUTHORIZATION = 'SET_AUTHORIZATION'

const dispatchPlatformPartnerlist = (data, headers) => ({
    type: SET_PLATFORM_PARTNER_LIST,
    data,
    headers
})

export const dispatchPlatformPartnerFormValues = (data) => ({
    type: SET_PLATFORM_PARTNER_FORM_VALUES,
    data
})

const dispatchAuthorization = (data) => ({
    type: SET_AUTHORIZATION,
    data
})

export const getPlatformPartners = (pagination = {}) => async (dispatch) => {
    const res = await request(
        `platform-partners/`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10
        }
    )
    dispatch(dispatchPlatformPartnerlist(res.response, res.headers))
    return res
}

export const createPlatformPartner = (payLoad) => async () => {
    const res = await request(`platform-partners/`, 'POST', payLoad)
    return res
}

export const togglePlatformPartner = (uuid, active) => async () => {
    const endpoint = active ? 'disable' : 'enable'
    const res = await request(`platform-partners/${uuid}/${endpoint}`, 'POST')
    return res
}

export const getAuthorizations = uuid => async (dispatch) => {
    const res = await request(`platform-partners/${uuid}/authorizations`, 'GET')
    dispatch(dispatchAuthorization(res))
    return res
}

export const createAuthorization = (payLoad, uuid) => async () => {
    const res = await request(`platform-partners/${uuid}/authorizations`, 'POST', payLoad)
    return res
}

export const toggleAuthorizations = (uuid, authorizationId, active) => async () => {
    const endpoint = active ? 'disable' : 'enable'
    const res = await request(`platform-partners/${uuid}/authorizations/${authorizationId}/${endpoint}`, 'POST')
    return res
}

export const resetPassword = (uuid, authorizationId) => async () => {
    const res = await request(`platform-partners/${uuid}/authorizations/${authorizationId}/reset-password`, 'POST')
    return res
}