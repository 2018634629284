import React, { Fragment } from 'react';
import { Icon } from 'styles';
import { secretaryOfStates } from 'constants/constantVariables';
import strings from 'strings';
import './index.scss'

const sosStrings = strings.APPLICANT.SOS

const SosLinks = () => {

    return (
        <div className="uccContainer">
            <div className='uccTitleContainer d-block'>
                <h5>{sosStrings.SOS}</h5>

                <div className='state-container'>
                    {Object.entries(secretaryOfStates).map(st =>
                        <Fragment key={st[0]}>
                            <div className='state-item' onClick={() => window.open(st[1], '_blank')}>
                                <div>{st[0]}</div>
                                <Icon folder='icons' fileName='link.svg' />
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SosLinks