import {
    Button,
    CircularProgress,
    DialogActions, DialogContent, DialogTitle
} from '@material-ui/core'
import moment from 'moment'
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import InputFieldDetailed from "components/forms/Input"
import { Dialog } from 'components/shared/Dialog'
import FilterBarModule from "components/shared/FilterBarModule"
import TableComponent from "components/shared/TableComponent"
import { Toast } from "components/shared/Toaster"
import { AttachmentsColumn } from "constants/application"
import { acceptable_files } from 'constants/constantVariables'
import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from 'react-dropzone'
import AuthService from 'tools/AuthService';
import VisibilityIcon from '@material-ui/icons/Visibility'
import PdfModal from 'components/Applicants/partial/Dialog/PdfModal'
import strings from "strings"
import AttachmentIcon from 'styles/images/icons/fileUpload.svg'
import './attachments.scss'

export function Attachments(props) {
    const {
        onSubmit,
        getFiles,
        deleteFile,
        downloadFile,
        downloadFileByPayload,
        downloadAttachedFiles,
        businessName,
        getAttachmentPDF,
        attachmentList,
        applicationId,
        totalPages,
        totalRecords,
        forFiling,
        files,
        preSeletedFiles = [],
        selectionLimit
    } = props;
    const [open, setOpen] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedAttachments, setSelectedAttachments] = useState([])
    const [notes, setNotes] = useState("")
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false)
    const [expand, setExpand] = useState(false)
    const [selectedRow, setSelectedRow] = useState(preSeletedFiles)
    const [showAlertDilaog, setShowAlertDilaog] = useState({ open: false, rowData: '' })
    const isReadOnlyUser = AuthService.isReadOnlyUser()
    const [openPDF, setOpenPDF] = useState(false)
    const [pdf, setPdf] = useState({ applicationId: '', uuid: '', fileName: '' })
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length)
            Toast({ type: 'warning', message: strings.APPLICANT.ATTACHMENTS.MAX_SIZE })
        setSelectedFiles(acceptedFiles)
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: acceptable_files,
        maxFiles: 1,
        multiple: false,
        maxSize: 10485760
    });

    const getList = useCallback(() => {
        setLoading(true)
        const pagination = forFiling ? { limit: 5 } : {}
        const query = forFiling ? { fileType: 'application/pdf' } : {}
        getFiles(applicationId, pagination, query).finally(() => setLoading(false))
    }, [getFiles, applicationId, forFiling])

    useEffect(() => {
        getList()
    }, [getList])

    const submit = useCallback(() => {
        const fd = new FormData();
        fd.append("file", selectedFiles[0])
        fd.append("purpose", notes)
        setSubmitting(true)
        onSubmit(applicationId, fd).then(() => {
            Toast({ type: "success", message: strings.APPLICANT.ATTACHMENTS.UPLOAD_MSG })
            getList();
            setSelectedFiles([]);
            setNotes("")
            setOpen(false)
            setExpand(false)
        }).finally(() => setSubmitting(false))
    }, [applicationId, getList, notes, onSubmit, selectedFiles])

    useEffect(() => {
        if (forFiling && selectedFiles.length)
            submit()
    }, [selectedFiles, forFiling, submit])

    const handlePagination = (pagination) => {
        setLoading(true)
        getFiles(applicationId, pagination).finally(() => setLoading(false))
    }

    const giveIdsTofiling = (Files) => {
        // checking the component has files prop
        // files prop will only available if forFiling prop is true
        if (files)
            files(Files)
    }

    const handleOnSelect = (row, isSelect) => {
        let selected = selectedRow
        if (isSelect)
            if (selected.length < 5) {
                if (selectionLimit) {
                    const maximumSize = selectionLimit * 1024
                    let selectedFileSize = 0;
                    [...selected, row].forEach(({ fileSize }) => selectedFileSize += (fileSize / 1024))
                    if (selectedFileSize > maximumSize) {
                        Toast({ type: 'info', message: strings.APPLICANT.ATTACHMENTS.MAX_SUM_SIZE })
                        return false;
                    }
                }
                selected.push(row)
                setSelectedRow(selected)
            }
            else {
                Toast({ type: 'info', message: strings.APPLICANT.ATTACHMENTS.MAX_FILES })
                return false;
            }
        else {
            selected = selected.filter(list => list.uuid !== row.uuid)
            setSelectedRow(selected)
        }

        giveIdsTofiling(selected)
    }

    const handleDelete = () => {
        deleteFile(applicationId, showAlertDilaog.rowData).then(() => {
            Toast({ type: "success", message: strings.APPLICANT.ATTACHMENTS.DELETE_MSG })
            getList()
        })
        setShowAlertDilaog({ open: false, rowData: '' })
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: false,
        selected: selectedRow.map(l => l.uuid),
        onSelect: handleOnSelect,
        hideSelectAll: true
    }

    const handleAttachmenttSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedAttachments((prev) => {
                return prev ? [...prev, row] : [row]
            })
        } else {
            setSelectedAttachments((prev) => {
                return prev.filter(list => list.uuid !== row.uuid)
            })
        }
    }

    const handleAttachmenttSelectAll = (isSelect, row) => {
        if (isSelect) setSelectedAttachments(row)
        else setSelectedAttachments([])
    }

    const handleDownloadAttachedFiles = () => {
        if (selectedAttachments.length === 1){
            downloadFile(applicationId, selectedAttachments[0].uuid, selectedAttachments[0].fileName)
        } else {
            const date = moment().format('MM-DD-YYYY')
            const bName = businessName.replace(/\s+/g, '-');
    
            const zipName = `${date}-${bName}-files` 
    
            const body = {
                applicationId: applicationId,
                fileUuids: selectedAttachments.map(file => file.uuid)
            };

            downloadAttachedFiles(body, zipName);
        }  
    }

    const downloadAllOptions = {
        mode: 'checkbox',
        onSelect: (row, isSelect) => handleAttachmenttSelect(row, isSelect),
        onSelectAll: (isSelect, row) => handleAttachmenttSelectAll(isSelect, row),
        selected: selectedAttachments?.map(l => l.uuid) || [],
    }

    const action = {
        formatter: (cell, row, rowIndex) => {
            return (
                <>
                    <div className='iconTableContainer justify-content-end'>
                        {
                            row.fileType === 'application/pdf' &&
                            <VisibilityIcon color='primary' className='viewIcon' onClick={() => {
                                setPdf({ applicationId: applicationId, uuid: row.uuid, fileName: row.fileName })
                                setOpenPDF(true)
                            }} />
                        }
                        <img
                            src={require('styles/images/icons/download.svg')}
                            onClick={() => downloadFile(applicationId, row.uuid, row.fileName).then(() => getList())}
                            alt='download'
                        />
                        <img
                            src={require('styles/images/icons/tableDelete.svg')}
                            onClick={() => setShowAlertDilaog({ open: true, rowData: row.uuid })}
                            alt='delete'
                        />
                    </div>
                </>
            )
        }
    }

    const fileAction = {
        formatter: (cell, row, rowIndex) => {
            return (
                <div className='download-file' onClick={() => downloadFile(applicationId, row.uuid, row.fileName)}>{cell}</div>
            )
        }
    }

    AttachmentsColumn.map((item, index) => {
        if (item.dataField === 'action' && !isReadOnlyUser)
            AttachmentsColumn[index] = { ...item, ...action }

        if (forFiling && item.dataField === 'fileName')
            AttachmentsColumn[index] = { ...item, ...fileAction }

        if (forFiling && ['purpose', 'action'].includes(item.dataField))
            return item
        return item
    })

    const renderFileUploader = (
        <div {...getRootProps({ className: "dropBox" })}>
            <input {...getInputProps()} />
            <img src={AttachmentIcon} alt='upload icon' />
            <div className="hint mt-2">{strings.APPLICANT.ATTACHMENTS.DRAG_AND_DROP}</div>
            <div className="hint">{strings.APPLICANT.ATTACHMENTS.SUPPORTED_FILE}</div>
        </div>
    )

    const DownloadFileComponent = (
        <div className='rowSelections'>
            {
                selectedAttachments?.length > 0 && (<p className='selectedRecords'>{selectedAttachments?.length} Records Selected</p>)
            }
            <Button
                variant='contained'
                color='primary'
                onClick={() => handleDownloadAttachedFiles()}
            >Download Selected Files</Button>
        </div>
    )

    return (
        <>
            {forFiling ? <>
                <div className='text-right mb-2'>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setExpand(prev => !prev)}
                        endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    >
                        {strings.APPLICANT.ATTACHMENTS.ADD_ATTACHMENTS}
                    </Button>
                </div>
                {expand && renderFileUploader}
            </> :
                <FilterBarModule
                    title={strings.APPLICANT.ATTACHMENTS.ATTACHMENTS}
                    btnText={!isReadOnlyUser && strings.APPLICANT.ATTACHMENTS.ADD_ATTACHMENTS}
                    selectedRowsLength={selectedAttachments?.length}
                    selectedRowsComponent={DownloadFileComponent}
                    onBtnChange={() => setOpen(true)}
                />
            }
            <TableComponent
                data={attachmentList}
                keyField='uuid'
                columns={AttachmentsColumn}
                totalRecords={totalRecords}
                totalPages={totalPages}
                showLimitOptions={!forFiling}
                onChangePagination={(pagination) => handlePagination(pagination)}
                selectRow={forFiling ? selectRow : downloadAllOptions}
                className={forFiling ? 'custom-table-checkbox' : ''}
                paginationLimit={forFiling ? 5 : undefined}
                loading={Boolean(loading || submitting)}
            />
            <Dialog
                open={open}
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle>
                    {strings.APPLICANT.ATTACHMENTS.ADD_COMMENTS}
                </DialogTitle>
                <DialogContent>
                    <InputFieldDetailed placeholder="Add notes here.." onChange={(e) => setNotes(e.target.value)} value={notes} />
                    <div className="attachment">{strings.APPLICANT.ATTACHMENTS.ATTACHMENTS}</div>
                    <img
                        src={require('styles/images/icons/attachment.svg')}
                        alt='attachment icon'
                    />
                    {
                        selectedFiles.length ? <>
                            <div className="files">
                                <div> {selectedFiles[0]?.name}</div>
                                <div>
                                    <span className="fileName">{(selectedFiles[0]?.size / 1024).toFixed(2)}kb</span>

                                    <HighlightOffOutlinedIcon
                                        className="del"
                                        onClick={() => setSelectedFiles([])}
                                    />
                                </div>
                            </div>
                        </> :
                            renderFileUploader
                    }
                </DialogContent>
                <DialogActions>
                    <Button
                        color='primary'
                        onClick={() => {
                            setSelectedFiles([])
                            setOpen(false)
                        }}
                    >
                        {strings.CANCEL}
                    </Button>
                    <Button
                        variant="contained"
                        color='primary'
                        disabled={selectedFiles.length === 0 || submitting}
                        onClick={() => submit()}
                    >
                        {submitting ?
                            <CircularProgress size={25} color='inherit' /> :
                            strings.APPLICANT.ATTACHMENTS.ADD_ATTACHMENTS}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                fullWidth
                maxWidth='sm'
                type='delete'
                open={showAlertDilaog.open}
                confirmButton={{ text: strings.DELETE, color: 'secondary' }}
                alertTiltle={strings.CONFIRM_DELETE}
                onSubmit={handleDelete}
                onClose={() => setShowAlertDilaog({ open: false, rowData: '' })}
            />
            <PdfModal
                open={openPDF}
                onClose={() => setOpenPDF(false)}
                getAttachmentPDF={getAttachmentPDF}
                pdf={pdf}
                onDownloadFile={downloadFileByPayload}
            ></PdfModal>
        </>
    )
}