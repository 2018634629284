import React, {useState} from 'react'
import { Row, Col } from "react-bootstrap"
import TableComponent from 'components/shared/TableComponent/index'
import {uccSummaryColumns} from "../../../../../../../../constants/application";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
    dispatchRetrieveDocuments,
    getFilingDetails,
    getDocumentsFromDatabase
} from "../../../../../../../../actions/applicants";
import UccFilingDetailView from "../../../../NewUCC/Components/UccFilingDetail/UccFilingDetailView";

const TableSummary = ({ rows, applicantId, entityId, entityType, getRetrieveDocuments}) => {
    const [openFilingDetails, setOpenFilingDetails] = useState(false)

    const [filingDetails, setFilingDetails] = useState(false)
    const [documents, setDocuments] = useState([])

    function openFilingDrawer(row) {
        const payload = {
            [entityType === 'BUSINESS' ? 'businessId' : 'ownerId']: entityId,
            filingNumbers:  [row.filingNumber],
            filingDetailsId: row.filingDetailsId
        }
        //Since this retrieves a saved filing it doesn't require to add state to the payload
        getFilingDetails(applicantId, payload)().then((res) => {
            setFilingDetails(res)
            getDocumentsFromDatabase(res?.uuid)().then((res) => {
                dispatchRetrieveDocuments(res);
                setDocuments(res)
                setOpenFilingDetails(true)
            })
        })
    }

    const action = {
        formatter: (cell, row, rowIndex) => {
            return (
                <VisibilityIcon color='primary' onClick={()=> openFilingDrawer(row)}/>
            )
        }
    }

    uccSummaryColumns.map((item, index) => {
        if (item.dataField === 'action')
            uccSummaryColumns[index] = {...item, ...action}
        return item
    })

    return (
        <>
            <Row>
                <Col>
                    <TableComponent
                        columns={uccSummaryColumns}
                        data={rows}
                        keyField={'filingNumber'}
                        showPagination={false}
                    >
                    </TableComponent>
                </Col>
            </Row>
            {openFilingDetails &&
                <UccFilingDetailView
                    open={openFilingDetails}
                    onClose={() => {
                        setOpenFilingDetails(false)
                    }}
                    applicationId={applicantId}
                    data={filingDetails}
                    documents={documents}
                    getRetrieveDocuments={getRetrieveDocuments}
                    id='open-summary-filing-details-drawer'
                />
            }
        </>
    )
}

export default TableSummary