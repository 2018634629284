import { produce } from 'immer'
import { camelCase, upperFirst } from 'lodash'
import {
    SET_UCC_SEARCH_RESULT,
    SET_UCC_DETAILED_REPORT,
} from 'actions/quickSearch'

const initialState = {
    ucc: {
        uccSearchResult: null,
        uccDetailedReport: {}
    },
}

const formatUccSearchData = ({ data, savedData }) => {
    if (!savedData)
        return data

    const modifiedData = []
    if (data?.length) {
        data.forEach(({ uccGroupInformation, entityType, entityUUID, uuid, response }) => {
            let obj = {}
            Object.entries(uccGroupInformation || {}).map(entries => obj[upperFirst(camelCase(entries[0]))] = entries[1])
            obj = { ...obj, entityType, entityUUID, uuid }
            modifiedData.push({ Group: response.Results, ...obj })
        })
    }
    return modifiedData
}

export const quickSearchReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_UCC_SEARCH_RESULT:
                draft.ucc.uccSearchResult = formatUccSearchData(action)
                break;
            case SET_UCC_DETAILED_REPORT:
                draft.ucc.uccDetailedReport = action.data
                break;
            default:
                break;
        }
    })
}