import React from "react"
import Chart from "react-apexcharts"
import strings from "strings"
import "./RecurringPaymentChart.scss"
import { recurringPaymentChart, detection } from 'constants/chartData'
import NoDataIndication from 'components/shared/NoDataIndication'

const t = strings.APPLICANT.BT

const RecurringPaymentChart = (props) => {

    const { recurringPaymentValues } = props

    const isData = recurringPaymentValues.some(value => value > 0);

    const recurringPaymentDonut = {
        ...recurringPaymentChart,
        series: recurringPaymentValues
    }

    return (
        <div className='chartHeight'>
            <div className="chartTitle">
                <h5 className='cardTitle'>
                    {t.RECURRING_PAYMENT_DISTRIBUTION}
                </h5>
            </div>
            <div className="donutContainer">
                {recurringPaymentValues && recurringPaymentValues.length > 0 && isData ?
                    <Chart options={recurringPaymentDonut.options} series={recurringPaymentDonut.series} type="donut" />
                    :
                    <NoDataIndication message="Awaiting Information, Check Back Later" className='mt-3 mb-3' />
                }
            </div>
            <div className="grid-container colorCodeContainer mt-3">
                {detection.map((item, index) => (
                    <div key={item.status} className={`grid-item ${index < 2 ? 'top-row' : 'bottom-row'}`}>
                        <div style={{ backgroundColor: item.code }} className='colorCode'></div>
                        <span>{item.status}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RecurringPaymentChart;
