import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import './SolveBgResults.scss'
import { bgvSummaryColums } from "constants/application"
import { Button } from "@material-ui/core"
import TableComponent from "components/shared/TableComponent"
import Loader from 'components/shared/Loader'
import strings from "strings"
import { performBgvSearchSummary } from "actions/applicants"
import { saveBuisnessReport } from "actions/applicants"

const SolveBgResults = ({ applicationId, requestDecision, handleGetAllData, isGetAllData = false }) => {

    const [backgroundSearchData, setBackgroundSearchData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const payLoad = {
            businessId: applicationId,
            searchByDBA: false
        }
        performBgvSearchSummary(applicationId, payLoad)()
            .then((res) => {
                setBackgroundSearchData(res?.response?.TopBusinessSearchResponseEx?.response?.Records?.Record || [])
            }).finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectBusiness = (row) => {
        setLoading(true)
        const payLoad = { businessId: applicationId, lexisBusinessIds: row.BusinessIds, searchByDBA: false }
        saveBuisnessReport(applicationId, payLoad)()
            .then(() => {
                if (isGetAllData) {
                    handleGetAllData(applicationId)
                } else {
                    requestDecision()
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // table columns
    const actionForBgv = {
        formatter: (cell, row, rowIndex) => {
            return (
                <Button variant="outlined" color="primary" onClick={() => handleSelectBusiness(row)} disabled={loading}>
                    {strings.SELECT}
                </Button>
            )
        }
    }

    bgvSummaryColums.map((item, index) => {
        if (item.dataField === 'action') {
            bgvSummaryColums[index] = { ...item, ...actionForBgv }
        }
        return item
    })

    return (
        <Row>
            <Col>
                {
                    loading ?
                        <div className='summaryLoader'>
                            <Loader />
                        </div>
                        :
                        <>
                            <TableComponent
                                keyField="BusinessId"
                                columns={bgvSummaryColums}
                                data={backgroundSearchData}
                                showPagination={false}
                                className="customTable mt-3"
                            />
                        </>
                }
            </Col>
        </Row>
    );
}

export default SolveBgResults;