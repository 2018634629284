import React, {Fragment} from 'react';
import './index.scss'
import {mapVerificationdata} from "../../../../../constants/application";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

export function VerificationReportDetails(props) {
    const {VerifiedInput, VerificationIndicators} = props

    return (<div className='verfication-container'>
            {mapVerificationdata(VerifiedInput, VerificationIndicators).map(item =>
                <Fragment key={item.title}>
                    <div className='item'>
                        {item.verified ? <CheckCircleIcon htmlColor='green'/> :
                            <CancelIcon color='secondary'/>}
                        <span className='verification-heading'>{item.title}</span>

                        <div className='item'>
                            <div/>
                            <div title={item.value}
                                 className='verification-value verification-overflow'>{item.value}</div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
}