import React, { useState } from 'react'
import UccSearchContent from './UccSearchContent'
import { performUccSearch, dispatchUccSearchParameters, dispatchUpdatedUccResponse } from 'actions/applicants'
import { buildUccSearchPayload } from "constants/utilityFunctions"
import UccSearchForm from './UccSearchForm'
import { useDispatch, useSelector } from 'react-redux'

const UccSearchTab = ({ selectedEntity, applicationId }) => {

    const uccResponseFromStore = useSelector(state => state.applicant.ucc.response)
    const [uccResponse, setUccResponse] = useState(uccResponseFromStore)
    const [loading, setLoading] = useState(false)
    const { type: entityType, uuid: entityId } = selectedEntity

    const dispatch = useDispatch()
    // submit form
    const handleUccSearch = (data) => {
        setLoading(true)
        const payload = buildUccSearchPayload({ data, entityType, entityId })

        performUccSearch(applicationId, payload)()
            .then(response => {
                setUccResponse(response)
                dispatchUccSearchParameters(data, dispatch)
                dispatchUpdatedUccResponse(response, dispatch)
            })
            .finally(() => { setLoading(false) })
    }

    return (
        <div className='mt-3'>
            <UccSearchForm selectedEntity={selectedEntity} onSubmit={handleUccSearch} loading={loading} />
            <UccSearchContent uccResponse={uccResponse} applicationId={applicationId} />
        </div>
    )
}

export default UccSearchTab