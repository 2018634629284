import React from 'react'
import strings from 'strings'
import { Alert } from '@material-ui/lab'
import { Button } from "@material-ui/core"
import './SosComingSoon.scss'
import { getTabByReportType } from 'constants/constantVariables'
const summaryStrings = strings.APPLICANT.SUMMARY

//TODO: Ask if we can remove this component

const SosComingSoon = (props) => {

    const { summaryTabChange } = props
    const tabIndex = getTabByReportType('SOS')

    return (
        <div id={'summary-report-sos-coming-soon'}>
            <hr></hr>
            <div className='summaryHeader mb-3'>
                <h5 className='summaryTitle mb-0'> {summaryStrings.SOS_FILINGS} </h5>
                <div className='summaryHeaderInfo'>
                    <Button
                        variant="outlined"
                        color="primary"
                        className='summaryHeaderButton ml-3'
                        disabled
                    >
                        {summaryStrings.VIEW_MORE}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        className='summaryHeaderButton ml-3'
                        disabled
                        id={'get-data-sos-coming-soon'}
                    >
                        {summaryStrings.GET_DATA}
                    </Button>
                </div>
            </div>
            <Alert severity={'info'}>
                <p className='mb-0'>
                    {summaryStrings.SOS_COMING_SOON}
                    <span id='sos-coming-soon' onClick={() => summaryTabChange(tabIndex)} className='sosComingSoonLink'>
                        {summaryStrings.SOS_COMING_SOON_LINK}
                    </span>
                </p>
            </Alert>
        </div>
    )
}

export default SosComingSoon