import Drawer, { DrawerAction } from 'components/shared/Drawer'
import { Box, Grid } from '@material-ui/core'
import TableComponent from 'components/shared/TableComponent'
import Button from 'components/shared/Button'
import { BTOwnersColumn, transactionColumn, recurrentTransactionColumn, transRTDataColumn, mapBankTransactionData, mapRecurrentBankTransactionData } from 'constants/application'
import { dateRange, transactionTabs } from 'constants/constantVariables'
import React, { useEffect, useState, Fragment } from 'react'
import strings from 'strings'
import { format, subMonths, startOfMonth } from 'date-fns'
import { Tabs, Tab } from 'components/shared/Tabs'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { DropdownField } from 'components/forms/reduxForm'
import { Icon } from 'styles'
import { Toast } from 'components/shared/Toaster'
import { Alert } from '@material-ui/lab'
import Loader from 'components/shared/Loader'
import TransactionsChartsGroup from 'components/Chart/TransactionsChartsGroup/index'
import Insights from './Insights/index'

const t = strings.APPLICANT.BT
export function BankTransactions(props) {
    const {
        change,
        applicationId,
        owners,
        requestBankDataAccess,
        sendEmailToOwnerDueCurrentStatus,
        // requestAccounts, in the future we need to use this
        requestBankTransactions,
        requestRecurrentBankTransactions,
        getListOfAccounts,
        getListOfBankTransactions,
        getDataForInsights,
        getListOfRecurrentBankTransactions,
        getBankDataRequests,
        getRecurrentTransactionData,
        bankTransactionsList,
        totalRecordsBT, // Total records Bank Transactions
        totalPagesBT, // Total pages Bank Transactions
        recurrentBankTransactionsList,
        totalRecordsRBT, // Total records Recurrent Bank Transactions
        totalPagesRBT, // Total pages Recurrent Bank Transactions
    } = props

    const today = new Date()

    const [openDrawer, setOpenDrawer] = useState(false)
    const [openTransactionDrawer, setOpenTransactionDrawer] = useState(false)
    const [openRecurrentTransactionDrawer, setOpenRecurrentTransactionDrawer] = useState(false)
    const [appTabValue, setAppTabValue] = useState(0);

    const [selectedOwnerUuid, setSelectedOwnerUuid] = useState('')
    const [selectedAccountId, setSelectedAccountId] = useState('')
    const [selectedFromDate, setSelectedFromDate] = useState(startOfMonth(subMonths(today, 6)))

    const [bankTransactionSelected, setBankTransactionSelected] = useState({})
    const [recurrentBankTransactionSelected, setRecurrentBankTransactionSelected] = useState({})
    const [recurrentTransactionData, setRecurrentTransactionData] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    const [selectedRowStatus, setSelectedRowStatus] = useState([])

    const [bankRequestsStatus, setBankRequestsStatus] = useState({})
    const [ownerAccounts, setOwnerAccounts] = useState([])
    const [ownersWithAccounts, setOwnersWithAccounts] = useState([])
    const [ownersExpired, setOwnersExpired] = useState([])
    const ownersExpiredNames = ownersExpired.map(owner => owner.firstName + ' ' + owner.lastName).join(', ')
    const [ownersPendingExp, setOwnersPendingExp] = useState([])
    const ownersPendingExpNames = ownersPendingExp.map(owner => owner.firstName + ' ' + owner.lastName).join(', ')

    const [insightsData, setInsightsData] = useState([])

    const paginationLimit = 5
    const [loading, setLoading] = useState(false)
    const [loadingDataRequests, setLoadingDataRequests] = useState({ transactions: false, recurrentTransactions: false })
    const [loadingTransactionsData, setLoadingTransactionsData] = useState({
        loadingInitialData: true,
        loadingBankTransactions: false,
        loadingRecurrentBankTransactions: false,
        loadingAccounts: false,
    })

    useEffect(() => {
        if (applicationId && owners.length > 0) {
            change('dateRange', 6)
            getBankDataRequests(applicationId)
                .then((res) => {
                    setBankRequestsStatus(res)
                    const ownersWithAcc = []
                    res.forEach(access => {
                        const owner = owners.find(owner => owner.uuid === access.uuid)
                        if (isOwnerInSelectorList(owner, access.status)) {
                            ownersWithAcc.push(owner)
                        }
                    })
                    if (ownersWithAcc.length > 0) {
                        const firstOwnerId = ownersWithAcc[0].uuid
                        setOwnersWithAccounts(ownersWithAcc)
                        change('owner', firstOwnerId)
                        setSelectedOwnerUuid(firstOwnerId);
                        getListOfAccounts(applicationId, firstOwnerId)
                            .then((res) => {
                                if (res?.response[0]?.accountId) {
                                    const firstAccountId = res.response[0].accountId
                                    setOwnerAccounts(res.response)
                                    change('accountId', firstAccountId)
                                    setSelectedAccountId(firstAccountId)
                                } else {
                                    setOwnerAccounts([])
                                    setSelectedAccountId('')
                                }
                            })
                    } else {
                        setOwnersWithAccounts([])
                        setSelectedOwnerUuid('')
                    }
                }).finally(() => {
                    setLoadingTransactionsData(prevState => ({ ...prevState, loadingInitialData: false }))
                })
        }
        // eslint-disable-next-line
    }, [applicationId, owners])

    useEffect(() => {
        if (selectedOwnerUuid && selectedAccountId) {
            refreshInsightsHandler();
            if (appTabValue !== 0) {
                setAppTabValue(0)
            }
        }
        // eslint-disable-next-line
    }, [selectedOwnerUuid, selectedAccountId, selectedFromDate]);

    const isOwnerInSelectorList = (owner, status) => {
        if (status === 'Granted' || status === 'Pending Expiration' || status === 'Expired') {
            switch (status) {
                case 'Pending Expiration':
                    setOwnersPendingExp(prevState => [...prevState, owner]);
                    break;
                case 'Expired':
                    setOwnersExpired(prevState => [...prevState, owner]);
                    break;
                default:
                    break;
            }
            return true
        }
        return false
    }

    const requestDataHandler = () => {
        requestBankTransactionsHandler()
        requestRecurrentBankTransactionsHandler()
    }

    const requestBankTransactionsHandler = () => {
        setLoadingDataRequests(prevState => ({ ...prevState, transactions: true }))
        const payLoad = {
            from: format(subMonths(today, 24), 'yyyy-MM-dd'),
            until: format(today, 'yyyy-MM-dd')
        }
        requestBankTransactions(applicationId, selectedOwnerUuid, payLoad)
            .then((res) => {
                Toast({ type: 'success', message: res.message })
            })
            .catch((err) => {
                Toast({ type: 'error', message: t.REQUEST_DATA_ERR_MSG })
            })
            .finally(() => {
                setLoadingDataRequests(prevState => ({ ...prevState, transactions: false }))
            })
    }

    const requestRecurrentBankTransactionsHandler = () => {
        setLoadingDataRequests(prevState => ({ ...prevState, recurrentTransactions: true }))
        const payLoad = {
            "accountIds": [selectedAccountId],
            // "includePersonalFinanceCategory": true
        }
        requestRecurrentBankTransactions(applicationId, selectedOwnerUuid, payLoad)
            .then((res) => {
                Toast({ type: 'success', message: res.message })
            })
            .catch((err) => {
                Toast({ type: 'error', message: t.REQUEST_DATA_ERR_MSG })
            })
            .finally(() => {
                setLoadingDataRequests(prevState => ({ ...prevState, recurrentTransactions: false }))
            })
    }

    const refreshInsightsHandler = () => {
        getDataForInsights(applicationId, selectedOwnerUuid, selectedAccountId)
            .then((res) => {
                setInsightsData(res)
            })
    }


    const refreshBankTransactionsHandler = (pagination = { limit: 5 }) => {
        setLoadingTransactionsData(prevState => ({ ...prevState, loadingBankTransactions: true }))
        const params = getParams();
        getListOfBankTransactions(applicationId, selectedOwnerUuid, params, pagination)
            .finally(() => {
                setLoadingTransactionsData((prevState) => ({ ...prevState, loadingBankTransactions: false }))
            })

    }

    const refreshRecurrentBankTransactionsHandler = (pagination = {}) => {
        setLoadingTransactionsData(prevState => ({ ...prevState, loadingRecurrentBankTransactions: true }));
        const params = getParams() //params.flow = 'OUTFLOW'
        getListOfRecurrentBankTransactions(applicationId, selectedOwnerUuid, params, pagination)
            .finally(() => {
                setLoadingTransactionsData(prevState => ({ ...prevState, loadingRecurrentBankTransactions: false }))
            })
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect)
            setSelectedRow([row.uuid])
            setSelectedRowStatus([row.status])
    }

    const handleRequestAccess = () => {
        setLoading(true)
        requestBankDataAccess(applicationId, selectedRow[0])
            .then(() => {
                Toast({ type: 'success', message: t.SUCCESS_MSG })
                getBankDataRequests(applicationId)
                    .then((res) => setBankRequestsStatus(res))
            }).finally(() => {
            setLoading(false)
            setOpenDrawer(false)
        })
    }

    const handleSendEmail = () => {
        setLoading(true)
        sendEmailToOwnerDueCurrentStatus(applicationId, selectedRow[0])
            .then(() => {
                Toast({ type: 'success', message: t.SUCCESS_RESENT_EMAIL })
                getBankDataRequests(applicationId)
                    .then((res) => setBankRequestsStatus(res))
            }).finally(() => {
            setLoading(false)
            setOpenDrawer(false)
        })
    }

    const handleSelectedOwnerChange = (ownerUuid) => {
        setLoadingTransactionsData(prevState => ({ ...prevState, loadingAccounts: true }));
        setSelectedOwnerUuid(ownerUuid);
        setSelectedAccountId('');
        //This endpoint let us send pagination params, we need discuss if we need to use pagination
        getListOfAccounts(applicationId, ownerUuid)
            .then((res) => {
                if (res?.response[0]?.accountId) {
                    const firstAccountId = res.response[0].accountId
                    setOwnerAccounts(res.response)
                    change('accountId', firstAccountId)
                    setSelectedAccountId(firstAccountId)
                } else {
                    setOwnerAccounts([])
                    setSelectedAccountId('')
                }
            })
            .finally(() => {
                setLoadingTransactionsData(prevState => ({ ...prevState, loadingAccounts: false }));
            })
    }

    const handleSelectedAccountChange = (accountId) => {
        setSelectedAccountId(accountId)
    }

    const handleDateRangeChange = (monthsDifference) => {
        if (monthsDifference === 0) {
            setSelectedFromDate('')
        } else {
            const fromDate = startOfMonth(subMonths(today, monthsDifference))
            setSelectedFromDate(fromDate)
        }
    }

    const onOpenRequestStatusDrawer = () => {
        setSelectedRow([])
        setSelectedRowStatus([])
        setOpenDrawer(true)
    }

    const handleTransactionTabChange = (event, newValue) => {
        setAppTabValue(newValue)
        switch (newValue) {
            case 0:
                refreshInsightsHandler()
                break;
            case 1:
                refreshRecurrentBankTransactionsHandler()
                break;
            case 2:
                refreshBankTransactionsHandler()
                break;
            default:
                break;
        }
    }

    const handleOpenViewBT = (row) => {
        setBankTransactionSelected(row)
        setOpenTransactionDrawer(true)
    }

    const handleOpenViewRBT = (row) => {
        setRecurrentBankTransactionSelected(row)
        getRecurrentTransactionData(applicationId, selectedOwnerUuid, row.uuid)
            .then((res) => {
                setRecurrentTransactionData(res?.transactions || [])
            })
            .finally(() => {
                setOpenRecurrentTransactionDrawer(true)
            })
    }

    const handlePaginationBT = (pagination) => {
        refreshBankTransactionsHandler(pagination)
    }

    const handlePaginationRBT = (pagination) => {
        refreshRecurrentBankTransactionsHandler(pagination)
    }

    const handleCloseTransactionDrawer = () => {
        setOpenTransactionDrawer(false)
        setBankTransactionSelected({})
    }

    const handleCloseRecTransactionDrawer = () => {
        setOpenRecurrentTransactionDrawer(false)
        setRecurrentBankTransactionSelected({})
        setRecurrentTransactionData([])
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: selectedRow,
        onSelect: handleOnSelect,
        hideSelectAll: true,
        nonSelectable: ownersWithAccounts.filter(owner => owner.status === "Granted" || owner.status === "Requested").map(owner => owner.uuid)
    }

    const actionBT = {
        formatter: (cell, row, rowIndex) => {
            return (
                <VisibilityIcon
                    color='primary'
                    className='viewIcon'
                    onClick={() => handleOpenViewBT(row)}
                />
            )
        }
    }

    const actionRBT = {
        formatter: (cell, row, rowIndex) => {
            return (
                <VisibilityIcon
                    color='primary'
                    className='viewIcon'
                    onClick={() => handleOpenViewRBT(row)}
                />
            )
        }
    }

    transactionColumn.map((item, index) => {
        if (item.dataField === 'action')
            transactionColumn[index] = { ...item, ...actionBT }
        return item
    })

    transRTDataColumn.map((item, index) => {
        if (item.dataField === 'action')
            transRTDataColumn[index] = { ...item, ...actionBT }
        return item
    })

    recurrentTransactionColumn.map((item, index) => {
        if (item.dataField === 'action')
            recurrentTransactionColumn[index] = { ...item, ...actionRBT }
        return item
    })

    const getParams = () => {
        let params = { accountIds: selectedAccountId }
        if (selectedFromDate) {
            params.from = format(selectedFromDate, 'yyyy-MM-dd')
            params.until = format(today, 'yyyy-MM-dd')
        }
        return params
    }

    const sendEmailButton =
        <Button disabled={!selectedRow.length} loading={loading}
                onClick={selectedRowStatus[0] === 'Not Requested' ? handleRequestAccess : handleSendEmail}>
            {t.REQUEST_ACCESS}
        </Button>;

    //TODO rename class name to generic name since its being used in components other than UCC
    return <div className='uccContainer'>
        {loadingTransactionsData.loadingInitialData ?
            <div className='mt-5 mb-5' ><Loader /></div>
            :
            <>
                <div className='uccTitleContainer'>
                    <h5>{t.BANK_TRANSACTIONS}</h5>
                    <Button disabled={!process.env.NODE_ENV === 'development'}
                        onClick={() => onOpenRequestStatusDrawer()} >{t.REQUEST_ACCESS}</Button>
                </div>
                {
                    ownersWithAccounts.length > 0 ?
                        <>
                            <div className='bankTransactionActions' >
                                <DropdownField
                                    name={'owner'}
                                    placeholder={t.SELECT_OWNER}
                                    options={ownersWithAccounts.map(owner => ({ label: owner.firstName + " " + owner.lastName, value: owner.uuid }))}
                                    isSearchable
                                    onChange={ownerUuid => handleSelectedOwnerChange(ownerUuid)}
                                />
                                <DropdownField
                                    name={'accountId'}
                                    placeholder={t.SELECT_ACCOUNT}
                                    className='dropBankTransaction'
                                    disabled={!selectedOwnerUuid || loadingTransactionsData.loadingAccounts}
                                    isLoading={loadingTransactionsData.loadingAccounts}
                                    options={ownerAccounts.map(account => ({ label: account.name, value: account.accountId }))}
                                    isSearchable
                                    onChange={accountId => handleSelectedAccountChange(accountId)}
                                />
                                <DropdownField
                                    name={'dateRange'}
                                    placeholder={t.SELECT_RANGE}
                                    className='dropBankTransaction'
                                    options={dateRange}
                                    onChange={dateRange => handleDateRangeChange(dateRange)}
                                />
                                <Button onClick={() => requestDataHandler()}
                                    className='btnBankTransaction'
                                    loading={loadingDataRequests.transactions || loadingDataRequests.recurrentTransactions}
                                    disabled={!selectedOwnerUuid || !selectedAccountId}>
                                    {t.REQUEST_DATA}
                                </Button>
                            </div>
                            {
                                ownersExpired.length > 0
                                &&
                                <Alert severity="error" className={'mt-3'} onClose={() => setOwnersExpired([])}>
                                    {t.EXPIRED_ACCESS_PREFIX + ownersExpiredNames + t.EXPIRED_ACCESS_SUFFIX}
                                </Alert>
                            }
                            {
                                ownersPendingExp.length > 0
                                &&
                                <Alert severity="warning" className={'mt-3'} onClose={() => setOwnersPendingExp([])}>
                                    {t.SOON_TO_EXPIRE_ACCESS_PREFIX + ownersPendingExpNames + t.SOON_TO_EXPIRE_ACCESS_SUFFIX}
                                </Alert>
                            }
                            <TransactionsChartsGroup
                                applicantId={applicationId}
                                ownerId={selectedOwnerUuid}
                                accountId={selectedAccountId}
                                selectedFromDate={selectedFromDate}
                            />
                            <div className='mt-3 mb-5'>
                                <Tabs
                                    data={transactionTabs}
                                    value={appTabValue}
                                    onChange={handleTransactionTabChange}
                                />
                                <Tab value={appTabValue} index={0}>
                                    <Insights
                                        selectedAccountId={selectedAccountId}
                                        insightsData={insightsData}
                                        applicationId={applicationId}
                                        selectedOwnerUuid={selectedOwnerUuid}
                                        columns={transactionColumn}
                                    />
                                </Tab>
                                <Tab value={appTabValue} index={1}>
                                    <TableComponent
                                        data={recurrentBankTransactionsList}
                                        key='uuid'
                                        columns={recurrentTransactionColumn}
                                        totalRecords={totalRecordsRBT}
                                        totalPages={totalPagesRBT}
                                        isLoading={loadingTransactionsData.loadingRecurrentBankTransactions || loadingTransactionsData.loadingBankTransactions}
                                        paginationLimit={paginationLimit}
                                        onChangePagination={(pagination) => handlePaginationRBT(pagination)}
                                        className='mt-3 global-table'
                                    />
                                </Tab>
                                <Tab value={appTabValue} index={2}>
                                    <TableComponent
                                        data={bankTransactionsList}
                                        keyField='uuid'
                                        columns={transactionColumn}
                                        totalRecords={totalRecordsBT}
                                        totalPages={totalPagesBT}
                                        isLoading={loadingTransactionsData.loadingBankTransactions || loadingTransactionsData.loadingRecurrentBankTransactions}
                                        paginationLimit={paginationLimit}
                                        onChangePagination={(pagination) => handlePaginationBT(pagination)}
                                        className='mt-3 global-table'
                                    />
                                </Tab>
                            </div>
                        </>
                        :
                        <div className='emptyIcon pt-5 pb-5'>
                            <Icon folder='root' fileName='emptyUCC.svg' />
                            <div>{t.NEED_TO_REQUEST_BANK_ACCESS}</div>
                        </div>
                }
            </>
        }

        <Drawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            title={t.BANK_ACCOUNT_ACCESS}
        >
            <Box pl='15px' pr='15px'>
                <TableComponent
                    columns={BTOwnersColumn}
                    data={bankRequestsStatus}
                    showPagination={false}
                    className='mt-3 customTable'
                    selectRow={selectRow}
                    key='uuid'
                />
            </Box>
            <DrawerAction>
                {sendEmailButton}
            </DrawerAction>
        </Drawer>

        <Drawer
            open={openTransactionDrawer}
            onClose={() => {
                handleCloseTransactionDrawer()
            }}
            title={t.BANK_TRANSACTION_DETAILS}
        >
            <div className='p-4 box'>
                <Grid container spacing={2}>
                    {mapBankTransactionData(bankTransactionSelected).map(data => <Fragment key={data.title}>
                        <Grid className='preview-label' item xs={4}>{data.title}</Grid>
                        <Grid className='preview-value' item xs={8}>{data.value}</Grid>
                    </Fragment>)}
                </Grid>
            </div>
        </Drawer>

        <Drawer
            open={openRecurrentTransactionDrawer}
            onClose={() => {
                handleCloseRecTransactionDrawer()
            }}
            title={t.RECURRENT_BANK_TRANSACTION_DETAILS}
        >
            <div className='p-4 box'>
                <Grid container spacing={2}>
                    {mapRecurrentBankTransactionData(recurrentBankTransactionSelected).map(data =>
                        <Fragment key={data.title}>
                            <Grid className='preview-label' item xs={4}>{data.title}</Grid>
                            <Grid className='preview-value' item xs={8}>{data.value}</Grid>
                        </Fragment>
                    )}
                </Grid>
            </div>

            <div className='p-4 box'>
                <h5>{t.TRANSACTIONS}</h5>
                <TableComponent
                    columns={transRTDataColumn}
                    data={recurrentTransactionData}
                    useLocalPagination={recurrentTransactionData.length > 5}
                    showPagination={recurrentTransactionData.length > 5}
                    paginationLimit={5}
                    className={'customTable'}
                />
            </div>

        </Drawer>
    </div>
}