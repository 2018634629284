import React from 'react'
import { 
    ToastContainer as Container,
    toast as event
 } from 'react-toastify'
import PropTypes from 'prop-types'

export const ToastContainer = (props) => {
    const {
        position = 'bottom-right',
        autoClose = 5000, // default autoclose is 5 sec
    } = props
  return (
      <Container 
        position={position}
        autoClose={autoClose}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
  )
}


export const Toast = (props) => {
    const {
        type,
        message,
        position = 'bottom-right',
        autoClose = 5000,
        ...otherProps
    } = props
    return (
        event[type](message, {
            position: position,
            autoClose: autoClose,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            ...otherProps
        })
    )
}

ToastContainer.propTypes = {
    position: PropTypes.string,
    autoClose: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number
    ]),
}

Toast.propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error', 'default']),
    message: PropTypes.string,
    position: PropTypes.string,
    autoClose: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.number
    ])
}