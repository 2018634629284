import React, { useEffect, useState } from 'react'
import { getSosSearchAutoreport, saveSosRecord, requestSosSearch } from 'actions/applicants'
import AutoreportSearchResultCard from './AutoreportSearchResultCard'

const SolveSosSearch = ({ applicationId, requestDecision }) => {

    const [sosSearchAutoreportData, setSosSearchAutoreportData] = useState([])

    const sosSearchAutoreport = sosSearchAutoreportData?.response?.results?.[0]
    const possibleAlternatives = sosSearchAutoreportData?.response?.possibleAlternatives
    const sosSearchId = sosSearchAutoreportData?.sosSearchId
    const state = sosSearchAutoreportData?.state

    useEffect(() => {
        getSosSearchAutoreport(applicationId)()
            .then((res) => {
                setSosSearchAutoreportData(res)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSaveSosRecord = (mode, payload) => {
        const saveEndpoint = mode === 'closeMatch' ? saveSosRecord : requestSosSearch

        if (mode === 'closeMatch') {
            payload.sosSearchId = sosSearchId
        } else {
            payload.state = state
            payload.autoReportRequest = true
        }

        saveEndpoint({ applicationId, payload })()
            .then(() => requestDecision())
    }

    return (
        <div className='mt-3'>
            {
                sosSearchAutoreport &&
                <AutoreportSearchResultCard
                    searchResult={sosSearchAutoreport}
                    possibleAlternatives={possibleAlternatives}
                    saveSosRecord={handleSaveSosRecord}
                    state={state}
                />
            }
        </div>
    )
}

export default SolveSosSearch