import React, { useState } from 'react'
import Pagination from '@material-ui/lab/Pagination'
import PropTypes from 'prop-types'
import {
    FormControl,
    Select,
    MenuItem
} from '@material-ui/core'
import './simplePagination.scss'

const pageLimitOptions = [
    { label: `Showing 5 Records`, value: 5 },
    { label: 'Showing 10 Records', value: 10 },
    { label: 'Showing 20 Records', value: 20 },
    { label: 'Showing 40 Records', value: 40 },
    { label: 'Showing 50 Records', value: 50 }
]

// Use this component checking if you have pagination props
// PaginationProps && <SimplePagination />
const SimplePagination = (props) => {
    const {
        onChangePagination,
        totalRecords,
        totalPages,
        className,
        paginationLimit,
    } = props

    const [pagination, setPagination] = useState({
        limit: paginationLimit || 5,
        page: 0,
        totalPages,
        totalRecords
    })

    const handlePagination = (e, value) => {
        let paginationParam = { ...pagination }
        if (e.target.value) {
            paginationParam.limit = e.target.value
            paginationParam.page = 0
        } else {
            paginationParam.page = value - 1
        }
        setPagination({
            ...paginationParam
        })
        onChangePagination(paginationParam)
    }

    return (
        <div className={`global-table ${className}`}>
            {
                <div className={`pagination-container`}>
                    <div className={`pagination-limit`}>
                        <>
                            <div>
                                <FormControl variant='outlined'>
                                    <Select
                                        className={'page-limit-dropdwon'}
                                        onChange={handlePagination}
                                        value={pagination.limit}
                                        variant='outlined'
                                    >
                                        {
                                            pageLimitOptions.map((ele, index) => {
                                                return <MenuItem key={index} value={ele.value}>{ele.label}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <p className='total-records'>{totalRecords} Total Records</p>
                        </>
                    </div>
                    <div className={`pagination-count`}>
                        <Pagination
                            count={Number(totalPages)}
                            onChange={handlePagination}
                            color="primary"
                            page={pagination.page + 1}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

SimplePagination.defaultProps = {
    totalPages: 1,
    showCheckbox: false,
}

SimplePagination.propTypes = {
    totalPages: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showCheckbox: PropTypes.bool,
}

export default SimplePagination
