import React from 'react'
import { getSavedRecord } from 'actions/applicants'
import Moment from "react-moment"
import Button from 'components/shared/Button/index'
import strings from 'strings'

const SavedRecordCard = (props) => {

    const { savedRecord, applicantId, viewResult } = props

    const recordUuid = savedRecord?.uuid

    const handleActionClick = () => {
        getSavedRecord(applicantId, recordUuid)()
            .then(res => {
                viewResult(res)
            })
    }

    return (
        <div className='cardStyle mt-3 mb-3'>
            <div className='savedRecordGrid'>
                <div className='bold-font'> {savedRecord?.title} </div>
                <Moment className='bold-font' format="MM/DD/YYYY">{savedRecord?.filingDate}</Moment>
                <div className={savedRecord?.normalizedStatus === 'Active' ? 'greenLabel' : 'redLabel'}> {savedRecord?.normalizedStatus} </div>
                <div className='bold-font text-uppercase'> {savedRecord?.stateOfSosRegistration} </div>
                <Button onClick={() => handleActionClick()} variant="outlined" color="primary" className='summaryHeaderButton mr-3' >
                    {strings.VIEW}
                </Button>
            </div>
        </div >
    )
}

export default SavedRecordCard