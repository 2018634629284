import React from 'react';
import { CircularProgress } from '@material-ui/core';

export default function Loader() {
    return (
        <div className='d-flex align-items-center justify-content-center h-100'>
            <CircularProgress size={45} color='primary' />
        </div>
    );
};

