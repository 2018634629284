import React from 'react'
import {Grid} from '@material-ui/core'

const KeyValueAccordionDetails = (props) => {
    const {data} = props


    const gridElement = (label, value, index) => {

        return (
            <React.Fragment key={index}>
                <Grid className='leftGridElement' item sm={6}>{label + ':'}</Grid>
                <Grid className='rightGridElement' item sm={6}>{value}</Grid>
            </React.Fragment>
        )
    }

    return (
            <Grid container spacing={2}>
                {(data && data.map((row, index) => (
                gridElement(row.label, row.value || '-', index)))
                )}
            </Grid>
            
        )
}

export default KeyValueAccordionDetails