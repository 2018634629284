import React, { Fragment, memo, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { snakeCase } from 'lodash';
import { useSelector } from 'react-redux'
import {
    DropdownField,
    InputField,
    AutoCompleteField,
} from "components/forms/reduxForm";
import InputFieldDetailed from "components/forms/Input"
import { minLength10, maxLength2000 } from "tools/forms/validators";
import { Radio } from 'components/forms/Radio';
import Checkbox from 'components/forms/Checkbox';
import { Toast } from 'components/shared/Toaster';
import Drawer, { DrawerAction } from 'components/shared/Drawer';
import Dropdown from 'components/forms/Dropdown';
import Button from 'components/shared/Button';
import { Attachments } from '../Attachments';
import { InfoView, errorView } from './views/infoView';
import { collateralRadios, statesOptions, strOptions } from 'constants/constantVariables';
import { Alert } from '@material-ui/lab'
import strings from 'strings';
import './ucc.scss';

const labelString = strings.APPLICANT.UCCFILING.LABEL

export const UccPosting = memo((props) => {
    const {
        open,
        onClose,
        state,
        countryCode,
        setState,
        postFiling,
        UCCFileLoading,
        getJurisdictionList,
        getTemplates,
        filingPreview,
        handleSubmit,
        applicationId,
        UccSearchId,
        filingSelectedRow,
        selectedFilingIndex,
        change,
        // Attachments Props
        onSubmit,
        getFiles,
        attachmentList,
        downLoadAttachment,
        getAttachmentPDF,
        totalPages,
        totalRecords
    } = props
    const defaultRadioValues = {
        type: 'description',
        collateralDesignation: 'NO_DESIGNATION',
        altNameDesignation: 'NO_ALT_NAME',
        altFilingType: 'NO_ALT_TYPE'
    }
    const clientState = useSelector(state => state?.user?.userDetails?.securityContext?.client);
    const CLIENT_NAME = clientState.clientName;
    const clientReferenceRequired = clientState.clientReferenceRequired;
    const businessState = useSelector(state => state?.applicant?.applicantById?.business);
    const fcsClientReference = businessState?.fcsClientReference;
    const taxId = businessState?.identifications[0]?.identification || '';
    const hasFcsClientReference = fcsClientReference !== null ? true : false;
    const sprsByDefault = clientState.sprsByDefault;
    const { preview, templateList } = useSelector(state => state.applicant.ucc)
    const list = templateList.map(d => ({ label: d.description, value: d.templateText }))
    const [options, setOptions] = useState([])
    const [openDrawer, setOpenDrawer] = useState({ preview: false })
    const [previewLoading, setPreviewLoading] = useState(false)
    const [data, setData] = useState({})
    const [selectedFiles, setSelectedFiles] = useState([])
    const [preSelectedFiles, setPreSelectedFiles] = useState([])
    const [value, setValue] = useState(defaultRadioValues);
    const [searchToReflectType, setSearchToReflectType] = useState({})
    const [masking, setMasking] = useState(sprsByDefault)
    const allErrors = []
    filingSelectedRow.map(row => row.errors?.forEach(e => allErrors.push(e)))
    const errors = filingSelectedRow.some(row => row?.errors?.length) ? allErrors : preview?.errors
    const [taxIds, setTaxIds] = useState({})

    useEffect(() => {
        if (state && open) {
            const params = { state, countryCode }
            getJurisdictionList(params).then((res) =>
                setOptions(res.map(({ name, id }) => ({ label: name, value: id })))
            )
        }
        if (!open) {
            const str = {}
            filingSelectedRow.forEach((row) => { str[calculateDebtorKey(row)] = 'NO_SEARCH' })
            setSearchToReflectType(str)
            const tax = {}
            const defaultTaxId = taxId === '0' ? '' : taxId
            filingSelectedRow.forEach((row) => { tax[calculateDebtorKey(row)] = defaultTaxId || '' })
            setTaxIds(tax)
            setValue(defaultRadioValues)
            setSelectedFiles([])
            setPreSelectedFiles([])
        } else {
            getTemplates()
            setMasking(sprsByDefault);
        }
        // eslint-disable-next-line
    }, [state, open, getJurisdictionList, filingSelectedRow, getTemplates])

    useEffect(() => {
        const {
            debtors = [],
            collateral: { description, designation } = {},
            filerReference,
            attachments = [],
            altFilingType,
            altNameDesignation,
            jurisdictionId,
            maskSecuredParty,
        } = preview || {}

        if (preview?.errors && debtors[0]?.jurisdictionId && open)
            setState(preview.debtors[0].jurisdictionId)

        if (filingSelectedRow.some(row => row.status === strings.STATUS_NAMES.Rejected || row.status === strings.STATUS_NAMES.Preparation) && open) {
            change('collateralDescription', description)
            change('filerReference', filerReference)
            // need jurisdictionId and organizationId from api
            change('jurisdictionId', options.find(opt => opt.value === jurisdictionId)?.value)
            // change('organizationId', organizationId)
            setMasking(maskSecuredParty)
            setValue({
                collateralDesignation: snakeCase(designation).toUpperCase(),
                altNameDesignation: snakeCase(altNameDesignation).toUpperCase(),
                altFilingType: snakeCase(altFilingType).toUpperCase(),
                type: description ? 'description' : 'attachment'
            })

            if (attachments.length) {
                setPreSelectedFiles(attachments)
                setSelectedFiles(attachments)
            }

            const temp = {}
            filingSelectedRow.forEach((row, i) => {
                try {
                    const key = calculateDebtorKey(row)
                    if (debtors[selectedFilingIndex[i]] !== undefined)
                        temp[key] = snakeCase(debtors[selectedFilingIndex[i]].searchToReflect).toUpperCase()
                    else temp[key] = 'NO_SEARCH'
                } catch (error) { }
            })
            if (Object.entries(temp).length)
                setSearchToReflectType(temp)
        }
        // eslint-disable-next-line
    }, [filingSelectedRow, preview, selectedFilingIndex, open, options])

    const handleRadioChange = (value, type) => {
        setValue((prev) => ({ ...prev, [type]: value }))
    };

    const handlePreview = (data) => {
        if (['attachment', 'both'].includes(value.type) && !selectedFiles.length) {
            Toast({ type: 'warning', message: strings.APPLICANT.UCCFILING.MINIMUM_MSG })
            return
        }

        setPreviewLoading(true)
        const { jurisdictionId, collateralDescription, filerReference, organizationId } = data || {};
        let businesses = [], businessesDBA = [], owners = [];
        UccSearchId.forEach((row) => {
            const key = calculateDebtorKey(row)
            if (row.entityType === 'BUSINESS'){
                const businessObj = {
                    uuid: row.id,
                    searchToReflectType: searchToReflectType[key],
                    taxId: taxIds[key],
                    addressId: row?.addressId || null
                }
                businesses.push(businessObj)
            }  
            else if (row.entityType === 'BUSINESS_DBA'){                
                const businessDBAObj = {
                    uuid: row.id,
                    searchToReflectType: searchToReflectType[key],
                    taxId: taxIds[key],
                    addressId: row?.addressId || null

                }
                businessesDBA.push(businessDBAObj)
            }
            else if (row.entityType === 'OWNER')
                owners.push({ uuid: row.id, searchToReflectType: searchToReflectType[key], taxId: taxIds[key] })

        })
        const payLoad = {
            businesses: businesses.length ? businesses : undefined,
            businessesDBA: businessesDBA.length ? businessesDBA : undefined,
            owners: owners.length ? owners : undefined,
            jurisdictionId,
            collateralDescription,
            filerReference,
            attachments: ['attachment', 'both'].includes(value.type) ? selectedFiles.map(file => file.uuid) : undefined,
            maskSecuredParty: masking,
            organizationId,
            ...value
        };
        delete payLoad.type
        if (value.type === 'attachment')
            delete payLoad.collateralDescription

        setData(payLoad)
        const details = {
            jurisdiction: options?.find(option => option.value === jurisdictionId)?.label,
            attachments: selectedFiles,
        }
        filingPreview(applicationId, payLoad, details)
            .then(() => setOpenDrawer(prev => ({ ...prev, preview: true })))
            .finally(() => setPreviewLoading(false))
    }

    const handleFormSubmission = () => {
        let payload = { ...data, filerReference: data?.filerReference || '' }

        postFiling(payload).finally(() => {
            onClose()
            setOpenDrawer(prev => ({ ...prev, preview: false }))
            setSelectedFiles([])
        })
    }

    const autoCompleteFieldJsx = <AutoCompleteField
        className='mt-0 collateral-desc'
        name="collateralDescription"
        freeSolo
        label={strings.APPLICANT.UCCFILING.COLLATERAL_DESCRIPTION}
        options={list}
        getOptionLabel='value'
        renderOption={(option) => (<div>
            <div className='optionHeading'>{option.label}</div>
            <div className='optionValue'>{option.value}</div>
        </div>)}
        multiline
        minRows={3}
        maxRows={15}
        validate={[minLength10, maxLength2000]}
        required
    />

    const attachmentJsx = <Attachments
        onSubmit={onSubmit}
        getFiles={getFiles}
        attachmentList={attachmentList}
        getAttachmentPDF={getAttachmentPDF}
        downloadFile={downLoadAttachment}
        totalPages={totalPages}
        totalRecords={totalRecords}
        applicationId={applicationId}
        forFiling
        files={(ids) => setSelectedFiles(ids)}
        preSeletedFiles={preSelectedFiles}
        selectionLimit={4}
    />

    const warningClientReference = () => (
        <Alert severity="info">
            {CLIENT_NAME} {strings.INFO_NO_CLIENT_REFERENCE}
        </Alert>
    )


    const infoTaxId = () => (
        <Alert severity="info">
            {strings.APPLICANT.UCCFILING.TAX_ID_WARNING}
        </Alert>
    )


    const handleChangeTaxId = (value, row) => {
        setTaxIds(prev => ({ ...prev, [calculateDebtorKey(row)]: value }))
    }

    const calculateDebtorKey = (row) => {
        return (row?.entityUuid || row?.id) + (row?.addressId + row?.entityType)
    }
    return <>
        <Drawer
            open={open}
            onClose={onClose}
            title={strings.APPLICANT.UCCFILING.UCC_FILE_POSTING}
        >
            <div className='posting-container'>
                {clientReferenceRequired && !hasFcsClientReference && (warningClientReference())}
                {errors?.length ? errorView(errors) : null}
                <div className='d-flex'>
                    <DropdownField
                        name="jurisdictionId"
                        label={labelString.JURISDICTION_ID}
                        placeholder={`Jurisdiction for ${state}`}
                        options={options}
                        isClearable={true}
                        required
                    />
                    <InputField
                        name="filerReference"
                        label={labelString.FILER_REFRENCE}
                        className='ml-2'
                    />
                    {UccSearchId?.some(({ entityType }) => entityType === 'BUSINESS') && <InputField
                        name="organizationId"
                        label={labelString.ORG_ID}
                        className='ml-2'
                    />}
                </div>
                <hr className='mt-3 mb-0' />
                <div className='mt-2'>{value.type === 'description' ? strings.APPLICANT.UCCFILING.TEXT_HINT :
                    value.type === 'attachment' ? strings.APPLICANT.UCCFILING.ATT_HINT :
                        strings.APPLICANT.UCCFILING.TEXT_ATT_HINT}</div>
                <Radio
                    checked={value.type}
                    value='description'
                    label={strings.APPLICANT.UCCFILING.ONLY_TEXT}
                    onChange={(value) => handleRadioChange(value, 'type')}
                />
                <Radio
                    checked={value.type}
                    value='attachment'
                    label={strings.APPLICANT.UCCFILING.ONLY_ATTACHMENTS}
                    onChange={(value) => handleRadioChange(value, 'type')}
                />
                <Radio
                    checked={value.type}
                    value='both'
                    label={strings.APPLICANT.UCCFILING.TEXT_ATTACHMENTS}
                    onChange={(value) => handleRadioChange(value, 'type')}
                />
                {value.type === 'description' ?
                    <>
                        <div className='heading-style-2 font-weight-bold mb-3'>{strings.APPLICANT.UCCFILING.COLLATERAL_DESCRIPTION} :</div>
                        {autoCompleteFieldJsx}
                    </> :
                    value.type === 'attachment' ?
                        <>
                            <div className='heading-style-2 font-weight-bold '>{strings.APPLICANT.UCCFILING.ATTACHMENT} :</div>
                            {attachmentJsx}
                        </> :
                        <>
                            <div className='heading-style-2 font-weight-bold mb-3'>{strings.APPLICANT.UCCFILING.COLLATERAL_DESCRIPTION} :</div>
                            {autoCompleteFieldJsx}
                            <div className='heading-style-2 font-weight-bold mt-3'>{strings.APPLICANT.UCCFILING.ATTACHMENT} :</div>
                            {attachmentJsx}
                        </>}

                <Checkbox
                    label={strings.APPLICANT.UCCFILING.LABEL.SECURED_MASKING}
                    className='custom-text'
                    onChange={checked => setMasking(checked)}
                    checked={masking}
                />

                {collateralRadios.map((field =>
                    <Fragment key={field.title}>
                        <div className='heading-style-2 font-weight-bold mt-2'>
                            {field.title}
                        </div>
                        {field.buttons.map(button =>
                            <Radio
                                key={button.value}
                                checked={value[field.checked]}
                                value={button.value}
                                label={button.label}
                                onChange={(value) => handleRadioChange(value, field.checked)}
                                clearDefualtValue={field.clearDefualtValue}
                                isClearable
                            />
                        )}
                    </Fragment>
                ))}

                <div className='ucc-search-reflect mt-2'>
                    <span className='heading-style-2 font-weight-bold hint-text'>{strings.APPLICANT.UCCFILING.ADDITIONAL_INFO}</span>
                    <span className='hr' />
                </div>
                {infoTaxId()}
                {filingSelectedRow.map((row, i) =>
                    <Grid container key={row.entityUuid + i} className='align-items-end'>
                        <Grid item sm={6}>
                            <div>{row.debtorName}</div>
                            <div style={{ height: '20px' }}>{statesOptions[row.countryCode ? row.countryCode : 'USA'].find(st => st.value === row.state)?.label}</div>
                        </Grid>
                        <Grid item sm={3}>
                            <Dropdown
                                options={strOptions}
                                placeholder={strings.APPLICANT.UCCFILING.STR}
                                onChange={({ value }) => setSearchToReflectType(prev => ({ ...prev, [calculateDebtorKey(row)]: value }))}
                                value={searchToReflectType[calculateDebtorKey(row)]}
                                menuPlacement='top'
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <InputFieldDetailed
                                value={taxIds[calculateDebtorKey(row)]}
                                onChange={(e) => handleChangeTaxId(e.target.value, row)}
                                className='ml-2'
                                label={strings.APPLICANT.UCCFILING.TAX_ID}
                            />
                        </Grid>
                    </Grid>
                )}
            </div>
            <DrawerAction>
                <Button
                    variant='text'
                    onClick={onClose}
                >
                    {strings.CANCEL}
                </Button>
                <Button
                    onClick={handleSubmit(data => handlePreview(data))}
                    loading={previewLoading}
                    disabled={clientReferenceRequired && !hasFcsClientReference}
                >
                    {strings.PREVIEW}
                </Button>
            </DrawerAction>
        </Drawer>

        <Drawer
            open={openDrawer.preview}
            onClose={() => setOpenDrawer(prev => ({ ...prev, preview: false }))}
            title={`${strings.APPLICANT.UCCFILING.PREVIEW} ${strings.PREVIEW}`}
        >
            <InfoView
                component='preview'
                state={state}
                downLoadAttachment={downLoadAttachment}
                applicationId={applicationId}
                viewMode={'fromUccPosting'}
            />
            <DrawerAction>
                <Button
                    onClick={handleFormSubmission}
                    loading={UCCFileLoading}
                >
                    {strings.SUBMIT}
                </Button>
            </DrawerAction>
        </Drawer>
    </>
})