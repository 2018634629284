import { createBrowserHistory } from 'history'
const history = createBrowserHistory()

if (
  history.location &&
  history.location.state &&
  history.location.state.newStatusId
) {
  const state = { ...history.location.state }
  delete state.newStatusId
  delete state.newStartDate
  delete state.providerType
  history.replace({ ...history.location, state })
}

if (
  history.location &&
  history.location.state &&
  history.location.state.dateOfService
) {
  const state = { ...history.location.state }
  delete state.dateOfService
  delete state.status
  history.replace({ ...history.location, state })
}

export default history
