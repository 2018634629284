export const removeBlanksStart = (value) => {
    // Removes blanks at the beginning of the entered value
    return value.trimStart();
};

export const removeBlanks = (value) => {
    // Removes blanks at the beginning and at the end of the entered value
    return value.trim();
};

