import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { reduxForm } from 'redux-form'
import { Col, Row } from "react-bootstrap"
import queryString from 'query-string';
import { Tabs, Tab } from 'components/shared/Tabs'
import { getApplicationDetailTabsFcn } from 'constants/constantVariables'
import FmcsaVerification from '../partial/Tabs/Fmcsa'
import Ucc from 'components/Applicants/partial/Tabs/UCC'
import Notes from 'components/Applicants/partial/Tabs/Notes'
import SosLinks from '../partial/Tabs/SecretaryOfState/SosLinks'
import { Verification } from '../partial/Tabs/Verification'
import { Attachments } from '../partial/Tabs/Attachments'
import { CreditCheck } from '../partial/Tabs/CreditCheck'
import { Bgv } from '../partial/Tabs/Bgv'
import Summary from '../partial/Tabs/Summary/Summary'
import { BankTransactions } from '../partial/Tabs/BankTransactions'
import {Status} from 'constants/chartData'
import AuthService from 'tools/AuthService'
import './applicantDetail.scss'
import ApplicationStatusDropdown from "./ApplicationStatusDropdown";
// Hiding application risk badge, to revert uncomment the section below
// import ApplicationRiskBadge from "./ApplicationRiskBadge";
import SummaryCard from "./SummaryCard";
import Overview from '../partial/Tabs/Summary/Overview'
import { Toast } from 'components/shared/Toaster'
import strings from 'strings'
import GetAllDataAlert from '../partial/Tabs/Summary/GetAllDataAlert';

// Old Header ===================================================================
import { cloneDeep } from 'lodash'
import { applicationTabs } from 'constants/constantVariables'
import { BuisnessColumns, OwnersColumn } from 'constants/application'
import TableComponent from 'components/shared/TableComponent'
import BusinessModal from 'components/Applicants/partial/Dialog/BusinessModal'
import OwnerModal from 'components/Applicants/partial/Dialog/OwnerModal'
import EditIcon from 'styles/images/icons/edit.svg'
import AddIcon from 'styles/images/icons/add.svg'
import NewUCCTabContent from '../partial/Tabs/NewUCC/index';
// ==============================================================================

/**
 *
 * @param {String} name business name
 * @param {String} status application status
 * @param {Function} setChangeStatus function to change the status value in dropdown
 * @param {Function} updateStatus function to call API to update the status of application
 * @param {String} selectedStatus dropdown selected value
 * @returns
 */
const ApplicantHeader = (props) => {
    const {
        name,
        status,
        updateStatus,
        dispatchProcessing,
        applicantUUID,
        // Hiding application risk badge, to revert uncomment the section below
        // riskScore,
        // showDecisioningFeatures
    } = props

    return (
        <div className='applicantHeader'>
            <div className='applicantTitle'>
                <h2 id={'applicant_name'} >{name}</h2>
                {AuthService.isReadOnlyUser() &&
                    <>
                        <span className='applicationStatus' style={{
                            background: Status.find(st => st.status === status)?.code,
                            color: '#ffffff'
                        }}>{status}</span>
                    </>
                }
                {/*Hiding application
                risk badge, to revert
                uncomment the section below*/}

                {/*{showDecisioningFeatures &&*/}
                {/*    <ApplicationRiskBadge*/}
                {/*        riskScore={riskScore}*/}
                {/*    />*/}
                {/*}*/}
            </div>
            <div className='applicant_header_options'>
                {!AuthService.isReadOnlyUser() &&
                    <div className='applicantChangeStatus'>
                        <ApplicationStatusDropdown
                            status={status}
                            updateApplicationStatus={updateStatus}
                            dispatchProcessing={dispatchProcessing}
                            updateStatus={updateStatus}
                            applicantUUID={applicantUUID}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

let tabChanged = true;
const ApplicantDetail = (props) => {
    const {
        dispatch,
        reset,
        dirty,
        history,
        location: { search, state },
        applicantUUID, // applicant id from params
        applicationData,
        formMode,
        modalMode,
        dispatchProcessing,
        requestFmcsaDataByApplication,
        getFmcsaDataByApplication,
        downloadFMCSAReport,
        getVerifications,
        getVerificationData,
        saveVerificationReport,
        attachVerificationReport,
        verificationList,
        verificationData,
        handleSubmit,
        change,
        updateApplicationStatus,
        requestReports, // action to request reports
        getApplicantsById, // action to get applicant details
        createNotes,
        getNotes,
        notesList,
        getUccList,
        uccList,
        getUccData,
        uccFilingList,
        uccSearch,
        requestUccFilingImage,
        finishSearch,
        reopenSearch,
        getCreditList,
        getFilingDetails,
        getTerminationPreview,
        terminateUCC,
        savefiling,
        getSavedDetail,
        deleteReport,
        retrieveDocument,
        getRetrieveDocuments,
        postFiling,
        getBgvList,
        searchResult,
        performBgvSearch,
        bgvList,
        getConnectedBuisnessList,
        connectedBuisnessList,
        saveBgvReport,
        getDetailedBusinessReport,
        detailedBusinessReport,
        saveBuisnessReport,
        getConnectedBuisnessReport,
        saveConnectedBuisnessReport,
        attachFile,
        getUccFilingList,
        getJurisdictionList,
        getdirectAmendmentsList,
        directAmendmentList,
        getTemplates,
        filingPreview,
        performCreditSearch,
        getCompanyReport,
        saveReport,
        getUccFilingDetailsByEntity,
        getUccFilingDetailsByFilingNumber,
        getUccFilingDetailsByFilingId,
        uploadFile,
        uploadRoxFiles,
        getFilingReportsAttachments,
        getFiles,
        deleteFile,
        downLoadAttachment,
        downloadFileByPayload,
        downloadAttachedFiles,
        getAttachmentPDF,
        downLoadAttachmentByFileId,
        requestUCCForceUpdate,
        attachmentList,
        totalPages,
        totalRecords,
        bankTransactionsList, // List of Bank Transactions
        totalRecordsBT, // Total records Bank Transactions
        totalPagesBT, // Total pages Bank Transactions
        recurrentBankTransactionsList, // List of Recurrent Bank Transactions
        totalRecordsRBT, // Total records Recurrent Bank Transactions
        totalPagesRBT, // Total pages Recurrent Bank Transactions
        getSalesRepresentatives,
        requestBankDataAccess,
        sendEmailToOwnerDueCurrentStatus,
        requestAccounts,
        requestBankTransactions,
        requestRecurrentBankTransactions,
        getListOfAccounts,
        getListOfBankTransactions,
        getDataForInsights,
        getListOfRecurrentBankTransactions,
        getBankDataRequests,
        getRecurrentTransactionData,
        // Old Header ================
        dispatchFormMode,
        formValues,
        updateBusiness,
        updateDebtor,
        updateOwner,
        createOwner,
        dbaName,
        mcNumber,
        identifications,
        // ===========================
    } = props
    const {
        status,
        business,
        indexName,
        owners = [],
        debtor,
        riskScore,
        // Old Header ================
        salesRepresentative,
        // ===========================
    } = applicationData

    const getFirstTab = (client) => {
        if (client.hideDecisioning) {
            return client.hideFmcsa ? 2 : 1;
        }
        return 0;
    }
    const generateHiddenElementsList = (clientState) => {
        const elementsToHide = [];
        if (clientState.hideVerification) {
            elementsToHide.push('Verification')
        }
        if (clientState.hideCreditCheck) {
            elementsToHide.push('Credit Check')
        }
        if (clientState.hideBankTransactions) {
            elementsToHide.push('Bank Transactions')
        }
        if (clientState.hideFmcsa) {
            elementsToHide.push('FMCSA')
        }
        if (clientState.hideDecisioning) {
            elementsToHide.push('Overview')
        }
        if (clientState.newUccEnabled) {
            elementsToHide.push('UCC')
        } else {
            elementsToHide.push('New UCC')
        }
        return elementsToHide;
    }

    const client = useSelector(state => state.user.userDetails.securityContext.client);
    const hiddenElements = generateHiddenElementsList(client);
    const ApplicationDetailTabs = getApplicationDetailTabsFcn(hiddenElements);
    const currentIndex = ApplicationDetailTabs.findIndex(tab => tab.url === `?tab=${queryString.parse(search).tab}` && tab.hidden !== true)
    const [tabValue, setTabValue] = useState(currentIndex > 0 ? currentIndex : getFirstTab(client));

    const [updateSummaryCard, setUpdateSummaryCard] = useState(0)
    const [updateSummary, setUpdateSummary] = useState(0)
    const [loadedApp, setLoadedApp] = useState(false)
    const [isGetAllData, setIsGetAllData] = useState(false);
    const [displayGAD, setDisplayGAD] = useState(false)
    const ref = useRef();

    // Old Header =============================================================================================
    const [appTabValue, setAppTabValue] = useState(0);
    const [modal, setModal] = useState({
        business: false,
        owner: false,
        debtor: false
    })

    const ownerSubmit = (formData) => {
        const callApi = formMode.mode === 'Create' ? createOwner : updateOwner

        if (!formData?.identifications[0]?.identification)
            delete formData.identifications

        if (formMode.mode === "Edit" && formData?.identifications?.length)
            formData.identifications = formData.identifications.map(data => ({
                ...data,
                identificationType: 'SSN',
                'primary': true
            }))

        callApi(applicantUUID, formData).then(() => {
            getApplication()
            window.location.reload()
        }).finally(() => {
            setModal({
                ...modal,
                owner: false
            })
        })
    }

    const editBusiness = (formData) => {
        if (typeof formData?.contractAmount === "string") {
            formData.contractAmount = Number(formData.contractAmount.split(",").join(""))
        }
        if (formData.hasOwnProperty('identifications') && Array.isArray(formData.identifications)
            && formData.identifications.length > 0) {
            if (typeof formData?.identifications[0].identification === "string") {
                if (formData.identifications[0].identification.length > 0) {
                    formData.identifications[0].identificationType = 'EIN'
                    formData.identifications[0].primary = true
                } else {
                    delete formData.identifications;
                }
            }
        }

        updateBusiness(applicantUUID, formData).then(() => {
            getApplication()
            updateUccData()
        }).finally(() => {
            setModal({
                ...modal,
                business: false
            })
        })
    }

    const editDebtor = (formData) => {
        const type = formMode.mode === 'Create' ? 'POST' : 'PATCH'

        if (!formData.emails || !formData.emails[0]?.email) {
            formData.emails = null
        } else {
            formData.emails[0].emailType = 'Work'
            formData.emails[0].primary = true
        }

        if (!formData.phones[0] || !formData.phones[0]?.number) {
            formData.phones = null;
        } else {
            formData.phones[0].phoneType = 'Work';
            formData.phones[0].primary = true;
        }

        if (!formData.identifications || !formData.identifications[0]?.identification) {
            formData.identifications = null
        } else {
            formData.identifications[0].identificationType = 'EIN'
        }

        updateDebtor(applicantUUID, formData, type).then(() => {
            getApplication()
        }).finally(() => {
            setModal({
                ...modal,
                debtor: false
            })
        })
    }

    const updateUccData = () => {
        if (tabValue === 4) {
            setLoadedApp(false)
            Promise.all([
                getUccList(applicantUUID),
                getUccFilingList(applicantUUID)
            ]).finally(() => {
                setLoadedApp(true)
            })
        }
    }

    const handleApplicationTabChange = (event, newValue) => {
        setAppTabValue(newValue)
    }

    const dialogControl = (key, mode, id) => {
        reset('editForm')
        dispatchFormMode({
            mode: mode,
            type: key,
            id
        })
        setModal({
            ...modal,
            [key]: !modal[key]
        })
    }

    const renderApplicationTab = (column, data, keyField) => (
        <TableComponent
            columns={column}
            data={data}
            keyField={keyField}
            showPagination={false}
            className='mt-3 customTable'
        />
    )

    const action = {
        formatter: (cell, row, rowIndex) => {
            let type = 'business'
            if (appTabValue === 1)
                type = 'owner'
            else if (appTabValue === 2)
                type = 'debtor'

            return (
                <img src={EditIcon} alt="Edit Icon" onClick={() => dialogControl(type, 'Edit', row.uuid)}/>
            )
        }
    }

    const headerFormatter = () => {
        if (owners.length < 3)
            return (
                <img src={AddIcon} alt="Add Icon" title='Add Owner' onClick={() => {
                    dialogControl("owner", 'Create', Math.random())
                }} />
            );
    }

    const DebtorHeaderFormatter = () => {
        if (!Object.keys(debtor || {}).length)
            return (
                <img src={AddIcon} alt="Add Icon" title='Add Debtor' onClick={() => {
                    dialogControl("debtor", 'Create', Math.random())
                }}/>
            );
    }

    const buisnessColumns = cloneDeep(BuisnessColumns)
    const debtorColumns = cloneDeep(BuisnessColumns)
    if (!AuthService.isReadOnlyUser()) {
        buisnessColumns.map((item, index) => {
            if (item.dataField === 'action')
                buisnessColumns[index] = {...item, ...action }
            return item
        })

        debtorColumns.map((item, index) => {
            if (item.dataField === 'action')
                debtorColumns[index] = {...item, ...action, headerFormatter: DebtorHeaderFormatter }
            return item
        })

        OwnersColumn.map((item, index) => {
            if (item.dataField === 'action')
                OwnersColumn[index] = {...item, ...action, headerFormatter }
            return item
        })
    }
    // ========================================================================================================

    // handle get all data button click
    const handleGetAllData = (applicantId) => {
        Toast({ type: "info", message: strings.APPLICANT.DECISIONER.REQUESTING_PULL_REPORTS })
        return requestReports(applicantId)
            .then(() => {
                handleUpdateSummaryCard()
                setUpdateSummary(updateSummary => updateSummary + 1)
                Toast({ type: "success", message: strings.APPLICANT.DECISIONER.PULL_REPORT_REQUESTED })
                getApplicantsById(applicantId)
                setIsGetAllData(true)
            });
    }

    const handleUpdateSummaryCard = () => {
        setUpdateSummaryCard(updateSummaryCard => updateSummaryCard + 1)
    }

    const getApplication = useCallback(() => {
        dispatchProcessing(true)
        setLoadedApp(false)
        getApplicantsById(applicantUUID).finally(() => {
            dispatchProcessing(false)
            setLoadedApp(true)
        })
    }, [applicantUUID, getApplicantsById, dispatchProcessing])

    useEffect(() => {
        //When Opening App Detail First Time, requesting App Data
        if ((!business || !state) && !ref.current) {
            getApplication()
        }
        //If just tab change, don't request App Data again
        if (business && state){
            setLoadedApp(true)
        }
    }, [getApplication, business, state])

    useEffect(() => {
        ref.current = true
    }, [])

    const handleDetailTabChange = useCallback((event, newValue) => {
        if (tabValue !== newValue){
            setTabValue(newValue)
            history.replace(ApplicationDetailTabs[newValue].url, true)
            tabChanged = true
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabValue]);

    return (
        <div className='applicant'>
            {loadedApp &&
                <div>
                    <ApplicantHeader
                        name={business?.name}
                        status={status}
                        updateStatus={updateApplicationStatus}
                        dispatchProcessing={dispatchProcessing}
                        applicantUUID={applicantUUID}
                        riskScore={riskScore}
                        showDecisioningFeatures={!client?.hideDecisioning}
                    />
                    {/* Old Header ================================================================================= */}
                    {client.hideDecisioning &&
                        <div className='ownerTabpanel'>
                            <Tabs
                                data={applicationTabs}
                                value={appTabValue}
                                onChange={handleApplicationTabChange}
                            />
                            <Tab value={appTabValue} index={0}>
                                {renderApplicationTab(buisnessColumns, [{ ...business, salesRepresentative }], 'name')}
                            </Tab>
                            <Tab value={appTabValue} index={1}>
                                {renderApplicationTab(OwnersColumn, owners, 'uuid')}
                            </Tab>
                            <Tab value={appTabValue} index={2}>
                                {renderApplicationTab(debtorColumns, [debtor || {}], 'name')}
                            </Tab>
                        </div>
                    }
                    {/* ============================================================================================ */}
                    <Row>
                        {!client?.hideDecisioning &&
                            <Col md={3} className='pl-3 pr-0'>
                                <SummaryCard applicantId={applicantUUID} updates={updateSummaryCard} ></SummaryCard>
                            </Col>
                        }
                        <Col md={!client?.hideDecisioning ? 9 : 12} className='pl-3 pr-3'>
                            <div className='tabPanel mt-3'>
                                <Tabs scrollable={true}
                                    data={ApplicationDetailTabs}
                                    value={tabValue}
                                    onChange={handleDetailTabChange}
                                />
                                <Tab value={tabValue} index={0} className='appDetailTab'>
                                    <GetAllDataAlert display={displayGAD} handleGetAllData={handleGetAllData} applicantId={applicantUUID} />
                                    <Overview
                                        applicantId={applicantUUID}
                                        formMode={formMode}
                                        modalMode={modalMode}
                                        businessData={business}
                                        debtorData={debtor}
                                        hideFmcsa={client?.hideFmcsa}
                                        getSalesRepresentatives={getSalesRepresentatives}
                                        reset={reset}
                                        dispatch={dispatch}
                                        handleSubmit={handleSubmit}
                                        dirty={dirty}
                                        change={change}
                                    />
                                    <Summary
                                        applicantId={applicantUUID}
                                        updates={updateSummary}
                                        changeTab={handleDetailTabChange}
                                        isGetAllData={isGetAllData}
                                        handleGetAllData={handleGetAllData}
                                        updateSummaryCard={handleUpdateSummaryCard}
                                        setDisplayGAD={setDisplayGAD}
                                        getRetrieveDocuments={getRetrieveDocuments}
                                        getFilingReportsAttachments={getFilingReportsAttachments}
                                        dispatchProcessing={dispatchProcessing}
                                        sosSearchEnabled={client.sosSearchEnabled}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={1} className='appDetailTab'>
                                    <FmcsaVerification
                                        getFmcsaDataByApplication={getFmcsaDataByApplication}
                                        requestFmcsaDataByApplication={requestFmcsaDataByApplication}
                                        dot={business?.dotNumber}
                                        downloadFMCSAReport={downloadFMCSAReport}
                                        applicationId={applicantUUID}
                                        uploadRoxFiles={uploadRoxFiles}
                                        updateSummaryCard={handleUpdateSummaryCard}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={2} className='appDetailTab'>
                                    <SosLinks />
                                </Tab>
                                <Tab value={tabValue} index={3} className='appDetailTab'>
                                    <Verification
                                        applicationId={applicantUUID}
                                        getVerifications={getVerifications}
                                        verificationList={verificationList}
                                        getVerificationData={getVerificationData}
                                        verificationData={verificationData}
                                        saveVerificationReport={saveVerificationReport}
                                        downloadFile={downLoadAttachment}
                                        attachVerificationReport={attachVerificationReport}
                                        updateSummaryCard={handleUpdateSummaryCard}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={4} className='appDetailTab'>
                                    <Bgv
                                        getBgvList={getBgvList}
                                        applicationId={applicantUUID}
                                        performBgvSearch={performBgvSearch}
                                        saveBgvReport={saveBgvReport}
                                        bgvList={bgvList}
                                        getConnectedBuisnessList={getConnectedBuisnessList}
                                        connectedBuisnessList={connectedBuisnessList}
                                        searchResult={searchResult}
                                        getDetailedBusinessReport={getDetailedBusinessReport}
                                        detailedBusinessReport={detailedBusinessReport}
                                        saveBuisnessReport={saveBuisnessReport}
                                        getConnectedBuisnessReport={getConnectedBuisnessReport}
                                        saveConnectedBuisnessReport={saveConnectedBuisnessReport}
                                        downloadFile={downLoadAttachment}
                                        attachFile={attachFile}
                                        updateSummaryCard={handleUpdateSummaryCard}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={5} className='appDetailTab'>
                                    <Ucc
                                        requestUccFilingImage={requestUccFilingImage}
                                        getUccList={getUccList}
                                        getUccData={getUccData}
                                        getUccFilingList={getUccFilingList}
                                        applicationId={applicantUUID}
                                        uccList={uccList}
                                        uccFilingList={uccFilingList}
                                        uccSearch={uccSearch}
                                        finishSearch={finishSearch}
                                        reopenSearch={reopenSearch}
                                        filingDetails={getFilingDetails}
                                        getTerminationPreview={getTerminationPreview}
                                        terminateUCC={terminateUCC}
                                        savefiling={savefiling}
                                        getSavedDetail={getSavedDetail}
                                        deleteReport={deleteReport}
                                        retrieveDocument={retrieveDocument}
                                        getRetrieveDocuments={getRetrieveDocuments}
                                        postFiling={postFiling}
                                        getJurisdictionList={getJurisdictionList}
                                        getdirectAmendmentsList={getdirectAmendmentsList}
                                        directAmendmentList={directAmendmentList}
                                        getTemplates={getTemplates}
                                        filingPreview={filingPreview}
                                        handleSubmit={handleSubmit}
                                        change={change}
                                        resetForm={() => reset('editForm')}
                                        getUccFilingDetailsByEntity={getUccFilingDetailsByEntity}
                                        getUccFilingDetailsByFilingNumber={getUccFilingDetailsByFilingNumber}
                                        getUccFilingDetailsByFilingId={getUccFilingDetailsByFilingId}
                                        tabChanged={{ changed: tabChanged, setChanged: () => tabChanged = false }}
                                        // Attachment props
                                        onSubmit={uploadFile}
                                        uploadRoxFiles={uploadRoxFiles}
                                        getFilingReportsAttachments={getFilingReportsAttachments}
                                        getFiles={getFiles}
                                        attachmentList={attachmentList}
                                        downLoadAttachment={downLoadAttachment}
                                        downLoadAttachmentByFileId={downLoadAttachmentByFileId}
                                        requestUCCForceUpdate={requestUCCForceUpdate}
                                        totalPages={totalPages}
                                        totalRecords={totalRecords}
                                        getAttachmentPDF={getAttachmentPDF}
                                        dispatchProcessing={dispatchProcessing}
                                        updateSummaryCard={handleUpdateSummaryCard}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={6} className='appDetailTab'>
                                    <NewUCCTabContent applicationId={applicantUUID} handleSubmitUccPosting={handleSubmit} />
                                </Tab>
                                <Tab value={tabValue} index={7} className='appDetailTab'>
                                    <CreditCheck
                                        getCreditList={getCreditList}
                                        applicationId={applicantUUID}
                                        performCreditSearch={performCreditSearch}
                                        getCompanyReport={getCompanyReport}
                                        saveReport={saveReport}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={8} className='appDetailTab'>
                                    <BankTransactions
                                        change={change}
                                        applicationId={applicantUUID}
                                        owners={owners}
                                        requestBankDataAccess={requestBankDataAccess}
                                        sendEmailToOwnerDueCurrentStatus={sendEmailToOwnerDueCurrentStatus}
                                        requestAccounts={requestAccounts}
                                        requestBankTransactions={requestBankTransactions}
                                        requestRecurrentBankTransactions={requestRecurrentBankTransactions}
                                        getListOfAccounts={getListOfAccounts}
                                        getListOfBankTransactions={getListOfBankTransactions}
                                        getDataForInsights={getDataForInsights}
                                        getListOfRecurrentBankTransactions={getListOfRecurrentBankTransactions}
                                        getBankDataRequests={getBankDataRequests}
                                        getRecurrentTransactionData={getRecurrentTransactionData}
                                        bankTransactionsList={bankTransactionsList}
                                        totalRecordsBT={totalRecordsBT}
                                        totalPagesBT={totalPagesBT}
                                        recurrentBankTransactionsList={recurrentBankTransactionsList}
                                        totalRecordsRBT={totalRecordsRBT}
                                        totalPagesRBT={totalPagesRBT}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={9} className='appDetailTab'>
                                    <Notes
                                        createNotes={createNotes}
                                        getNotes={getNotes}
                                        applicantId={applicantUUID}
                                        notesList={notesList}
                                    />
                                </Tab>
                                <Tab value={tabValue} index={10} className='appDetailTab'>
                                    <Attachments
                                        onSubmit={uploadFile}
                                        getFiles={getFiles}
                                        deleteFile={deleteFile}
                                        downloadFileByPayload={downloadFileByPayload}
                                        downloadFile={downLoadAttachment}
                                        downloadAttachedFiles={downloadAttachedFiles}
                                        businessName={indexName}
                                        getAttachmentPDF={getAttachmentPDF}
                                        attachmentList={attachmentList}
                                        totalPages={totalPages}
                                        totalRecords={totalRecords}
                                        applicationId={applicantUUID}
                                    />
                                </Tab>
                            </div>
                        </Col>
                    </Row>
                    {/* Old Header ====================================================================================== */}
                    <BusinessModal
                        open={modal.business || modal.debtor}
                        onClose={() => {
                            setModal({ ...modal, business: false, debtor: false })
                            dispatchFormMode({
                                mode: 'Default',
                                type: ''
                            })
                        }}
                        formValues={formValues}
                        onSubmit={handleSubmit(formData => appTabValue ? editDebtor(formData) : editBusiness(formData))}
                        ein={identifications}
                        mcNumber={mcNumber}
                        dbaName={dbaName}
                        dirty={dirty}
                        change={change}
                        modalMode={modalMode}
                        type={appTabValue ? 'debtor' : 'business'}
                        getSalesRepresentatives={getSalesRepresentatives}
                        hideFmcsa={client?.hideFmcsa}
                        addresses={appTabValue ? debtor?.addresses : business?.addresses}
                    />
                    <OwnerModal
                        open={modal.owner}
                        onClose={() => {
                            dispatchFormMode({
                                mode: 'Default',
                                type: ''
                            })
                            setModal({ ...modal, owner: false })
                        }}
                        onSubmit={handleSubmit(formData => ownerSubmit(formData))}
                        modalMode={modalMode}
                        change={change}
                        dirty={dirty}
                    />
                    {/* ================================================================================================= */}
                </div>
            }
        </div>
    )
}

export default reduxForm({
    form: 'editForm',
    enableReinitialize: true,
    destroyOnUnmount: false
})(ApplicantDetail)