import strings from 'strings'

const page404Strings = {
  PAGE_404_TITLE: '404',
  PAGE_404_SUBHEADER: 'This page cannot be found',
  PAGE_404_MESSAGE:
    'The page you are looking for might have been removed, ' +
    'had its name changed, or is temporarily unavailable.'
}

strings.setContent(
  Object.assign({}, strings.getContent(), {
    en: page404Strings
  })
)

export default strings
