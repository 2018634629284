import React from 'react';
import {Grid} from '@material-ui/core';
import strings from 'strings';
import './index.scss'
import Chart from "react-apexcharts";
import {creditRatingRadialChart} from "../../../../../constants/chartData";

const t = strings.APPLICANT.VERIFICATION

export function VerificationReportBusinessChart(props) {
    const {BusinessVerification} = props

    function getRisk(index) {
        const number = Number(index);
        if (number >= 40){
            return 'Low Risk';
        }
        return number >= 30 ? 'Medium Risk' : 'High Risk';
    }

    return (<>
            {BusinessVerification?.Index !== null && BusinessVerification?.Index !== undefined ?
                <>
                    <div className='heading-style-2 mt-5'>{strings.BUSINESS} {t.VERIFICATION}</div>
                    <hr className='mb-1'/>
                    <Grid container spacing={2}>
                        <Grid item sm={4}>
                            <Chart
                                type='radialBar'
                                height='210px'
                                options={creditRatingRadialChart(getRisk(BusinessVerification.Index), BusinessVerification?.Index)}
                                series={[BusinessVerification.Index * 2]}
                            />
                        </Grid>

                        <Grid className='d-flex flex-column' item sm={8}>
                            <div className='verification-heading'>
                                {strings.VALUE}
                            </div>
                            <div className='verification-value'>
                                {BusinessVerification.Index} out of 50
                            </div>

                            <div className='verification-heading mt-3'>
                                {strings.APPLICANT.CLIENTS.DESCRIPTION}
                            </div>
                            <div className='verification-value'>
                                {BusinessVerification.Description}
                            </div>
                        </Grid>
                    </Grid>
                </> : null}
        </>
    );
}