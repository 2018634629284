import React from 'react'
import './StatusIndicator.scss'
import PropTypes from 'prop-types';

const StatusIndicator = (props) => {

    const { color, text, side = 'right' } = props

    return (
        <div className='d-flex'>
            {
                side === 'left' &&
                <div className='align-items-center align-content-center mr-1'>
                    <div className={`statusIndicatorStatus ${color.toLowerCase()}StatusColor`}></div>
                </div>
            }
            <div className='statusIndicatorTextContainer'>
                <p className={`statusIndicatorText m-0 text-align-${side}`}>{text}</p>
            </div>
            {
                side === 'right' &&
                <div className='align-items-center align-content-center ml-1'>
                    <div className={`statusIndicatorStatus ${color.toLowerCase()}StatusColor`}></div>
                </div>
            }
        </div>
    )
}

StatusIndicator.propTypes = {
    color: PropTypes.string.isRequired, // color is the color of the status indicator
    text: PropTypes.string.isRequired, // text is the text to display
    side: PropTypes.oneOf(['left', 'right']), // side is the side of the status indicator
};

export default StatusIndicator