import AuthService from "tools/AuthService"
import request from "tools/request"

export const SET_PLAID_ACCESS_TOKEN = 'SET_PLAID_ACCESS_TOKEN'
export const SET_PLAID_LINK_TOKEN = 'SET_PLAID_LINK_TOKEN'

const dispatchPlaidLinkToken = (data) => ({
    type: SET_PLAID_LINK_TOKEN,
    data,
})

/* Action to get token from plaid to open plaid integration panel*/
export const getToken = (payLoad, clearToken) => async (dispatch) => {
    if (clearToken)
        return dispatch(dispatchPlaidLinkToken(''))

    const res = await request(`plaid/getToken`, 'POST', payLoad)
    AuthService.setPlaidLinkToken(res.linkToken)
    dispatch(dispatchPlaidLinkToken(res.linkToken))
    return res
}

export const cleanPlaidLocalStorage = () => async () => {
    localStorage.removeItem('aid');
    localStorage.removeItem('oid');
    localStorage.removeItem('rox_plaid_public_token');
    localStorage.removeItem('rox_plaid_link_token');
    localStorage.removeItem('rox_plaid_access_token');
}

// Action to exchange token once integration is successful
export const exchangeToken = (payLoad) => async (dispatch) => {
    const res = await request(`plaid/exchangeToken`, 'POST', payLoad)
    return res
}
