import React, { Fragment } from 'react'
import { uploadRoxFiles } from 'actions/applicants'
import Drawer, { DrawerAction } from 'components/shared/Drawer'
import Button from 'components/shared/Button'
import strings from 'strings'
import { DownloadFMCSAReport } from 'components/PdfTemplates'
import { getFmcsaPdfInfo } from 'constants/utilityFunctions'
import { mapFmcsaData } from 'constants/fmcsa'
import { Grid } from "@material-ui/core"
import { useHistory } from 'react-router-dom'

const FmcsaDrawer = (props) => {

    const { open, onClose, applicantId, data, fmcsaSearch, dotNumber } = props

    const extraContent = data?.extraContent
    const infoFMCSA = data?.content?.carrier
    const retrievalDate = data?.retrievalDate
    const infoToShow = getFmcsaPdfInfo(infoFMCSA, extraContent, retrievalDate)

    const handleUploadRoxFiles = async (data) => { return await uploadRoxFiles(data)() }
    const history = useHistory()

    return (
        <Drawer
            open={open}
            title={strings.FMCSA_DETAILS}
            onClose={onClose}
            actions={!fmcsaSearch &&
                <DownloadFMCSAReport
                    data={infoToShow}
                    onSubmit={handleUploadRoxFiles}
                    applicationId={applicantId}
                    mode='button'
                />}
        >
            <div className='p-3 pb-5'>
                <h5 className='mb-4'>{strings.BUSINESS_INFORMATION}</h5>

                <Grid container spacing={2}>
                    {mapFmcsaData(infoToShow).map(data => (
                        <Fragment key={data.title}>
                            <Grid className='preview-label' item xs={4}>{data.title}</Grid>
                            <Grid className='preview-value' item xs={8}>{data.value}</Grid>
                        </Fragment>))}
                </Grid>
            </div>

            {fmcsaSearch &&
                <DrawerAction position='sticky'>
                    <Button color="primary" variant='contained' onClick={() => history.push(`/create-applicant?codeType=DOT&codeValue=${dotNumber}`)}>
                        {strings.CREATE_APPLICATION}
                    </Button>
                </DrawerAction>}
        </Drawer>
    )
}

export default FmcsaDrawer