import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { useSelector, useDispatch } from 'react-redux'
import { actionTypes } from 'reducers/root'

function SnackbarNotification() {
  const { showNotification } = useSelector(state => state.root)
  const [alertTitle, setAlertTitle] = useState();
  const [alertContent, setAlertContent] = useState();
  const dispatch = useDispatch()

  const closeNotification = () => {
    dispatch({
      type: actionTypes.SET_SHOW_NOTIFICATION,
      showNotification: { show: false, notification: "" },
    });
  };

  useEffect(() => {
      setAlertTitle(showNotification?.title);
      setAlertContent(showNotification?.content);
  }, [showNotification]);

  return (
    <div id="notifications">
      {showNotification.success === undefined && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={showNotification.show}
          autoHideDuration={6000}
          onClose={closeNotification}
        >
          <MuiAlert onClose={closeNotification} severity="success">
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertContent}
          </MuiAlert>
        </Snackbar>
      )}
      {showNotification.success === false && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={showNotification.show}
          autoHideDuration={6000}
          onClose={closeNotification}
        >
          <MuiAlert className="notifications__error" onClose={closeNotification} severity="error">
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertContent}
          </MuiAlert>
        </Snackbar>
      )}
    </div>
  );
}

export default SnackbarNotification;
