import React from 'react'
import Moment from "react-moment"
import { Link } from 'react-router-dom'

const settingsTabs = [
    { label: 'Customers' },
    { label: 'Tab', disabled: true }
]

const clientSettingsTab = [
    { label: 'User' },
    { label: 'Sales Representative' },
    { label: 'Templates' }
]

const cardMapping = [
    {
        url: '/settings/platform-partners',
        icon: 'handShake.svg',
        heading: 'Platform Partners',
        bodyText: 'Add, update, and delete partners',
        buttonText: 'Manage Partners',
    },
    {
        url: '/settings/clients',
        icon: 'users.svg',
        heading: 'Customer',
        bodyText: 'Add, update, and delete customers',
        buttonText: 'Manage Customers',
    },
]

const customerColumns = (plaformPartner) => [
    {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
            width: plaformPartner ? 'auto' : '16rem'
        },
        formatter: (cell, row) => {
            return (
                <Link className={'id-hyper-link'}
                    to={`/settings/client/${row.uuid}`}
                >
                    {cell}
                </Link>
            )
        }
    },
    {
        dataField: 'phone',
        text: 'Phone'
    },
    {
        dataField: 'businessType',
        text: 'Business Type'
    },
    {
        dataField: 'createdBy.name',
        text: 'Created By'
    },
    {
        dataField: 'updatedBy.name',
        text: 'Updated By'
    },
    {
        dataField: 'createdAt',
        text: 'Created At',
        formatter: (cell) => {
            return <Moment format="MM/DD/YYYY">{cell}</Moment>
        }
    },
    {
        dataField: 'updatedAt',
        text: 'Updated At',
        formatter: (cell) => {
            return <Moment format="MM/DD/YYYY">{cell}</Moment>
        },
        headerStyle: {
            width: '9rem'
        },
    },
    {
        dataField: 'action',
        headerStyle: {
            width: '70px',
            cursor: 'pointer'
        },
        sort: false,
        hideColumn: true
    }
]

const customerList = [
    {
        uuid: '4eaaldjf223l324l2342342',
        name: 'Alladin',
        email: 'alladin@gmail.com',
        domain: 'Carrier',
        createdAt: new Date()
    },
    {
        uuid: '4eaaldjf223l324l2342342',
        name: 'Shiva',
        email: 'shiva@gmail.com',
        domain: 'Transport',
        createdAt: new Date()
    },
    {
        uuid: '4eaaldjf223l324l2342342',
        name: 'James',
        email: 'james@gmail.com',
        domain: 'Carrier',
        createdAt: new Date()
    },
]

const customerFilters = [
    {
        id: 'name',
        type: 'text',
        label: 'Customer Name',
        defaultValue: ''
    },
]

const userColumns = [
    {
        dataField: 'username',
        text: 'User Name',
        headerStyle: {
            width: '16rem'
        },
        formatter: (cell, row) => {
            return <div>{`${row.firstName} ${row.lastName}`}</div>
        }
    },
    {
        dataField: 'email',
        text: 'Email'
    },
    {
        dataField: 'phone',
        text: 'Phone'
    },
    {
        dataField: 'roles',
        text: 'Role',
        formatter: (cell) => {
            return cell?.map(role => role.name).join(', ')
        }
    },
    {
        dataField: 'status',
        text: 'Status',
        headerStyle: {
            textAlign: 'center',
            width: '11rem'
        },
        checkboxDisable: true,
        formatter: (cell) => (
            <div><div className={`table-label ${cell?.toLowerCase()}`}>{cell}</div></div>
        )
    },
    {
        dataField: 'action',
        headerStyle: {
            width: '8rem',
            cursor: 'pointer'
        },
        sort: false,
        show: false,
        hideColumn: true,
        text: '' 
    }
]

const templateColumns = [
    {
        dataField: 'description',
        text: 'Name',
        style: {
            whiteSpace: 'initial'
        }
    },
    {
        dataField: 'createdBy',
        text: 'Created By',
        formatter: (cell) => (
            <div>{cell.firstName} {cell.lastName}</div>
        ),
    },
    {
        dataField: 'templateText',
        text: 'Description',
        style: {
            display: '-webkit-box',
            overflow: 'hidden',
            height: '52px',
            whiteSpace: 'initial',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
        }
    },
]

const salesRepresentativeColumns = [
    {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
            width: '16rem'
        },
        formatter: (cell, row) => {
            return <div>{`${row.firstName} ${row.lastName}`}</div>
        }
    },
    {
        dataField: 'email',
        text: 'Email'
    },
    {
        dataField: 'phoneNumber',
        text: 'Phone'
    },
    {
        dataField: 'createdBy.name',
        text: 'Created By',
    },
    {
        dataField: 'action',
        text: 'Actions',
        headerStyle: {
            width: '7rem',
        },
    }
]

const platformPartnersColumns = [
    {
        dataField: 'name',
        text: 'Name',
    },
    {
        dataField: 'username',
        text: 'Username'
    },
    {
        dataField: 'active',
        text: 'Status',
        formatter: (cell) => (<div>{cell ? 'Enabled' : 'Disabled'}</div>)
    },
    {
        dataField: 'action',
        text: 'Actions',
        headerStyle: { width: '9rem' }
    }
]

const CsvColumns = [
    {
        dataField: 'uuid',
        text: 'Ingestion Id',
        headerStyle: { width: '25rem' }
    },
    {
        dataField: 'numberOfRows',
        text: 'Number of Record'
    },
    {
        dataField: 'status',
        text: 'Status',
    },
    {
        dataField: 'importDate',
        text: 'Created At',
        formatter: cell => <Moment format="Do MMM YY">{cell}</Moment>
    }
]

const authorizationColumns = [
    {
        dataField: 'client.name',
        text: 'Client Name',
    },
    {
        dataField: 'serviceAccount.username',
        text: 'Service Account Name',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' }
    },
    {
        dataField: 'serviceAccount.roles',
        text: 'Role',
        formatter: (cell, row) => {
            if (cell?.length)
                return cell.map((role, i) => <div key={i}>{role}</div>)
        }
    },
    {
        dataField: 'active',
        text: 'Status',
        formatter: (cell) => (<div>{cell ? 'Enabled' : 'Disabled'}</div>)
    },
    {
        dataField: 'action',
        text: 'Action',
        headerStyle: { width: '7rem' }
    }
]

const userList = [
    {
        uuid: '4eaaldjf223l324l2342342',
        name: 'Alladin',
        email: 'alladin@gmail.com',
        role: 'Admin',
        createdAt: new Date(),
        status: 'Active'
    },
    {
        uuid: '4eaaldjf223l324l2342342',
        name: 'Shiva',
        email: 'shiva@gmail.com',
        role: 'Admin',
        createdAt: new Date(),
        status: 'Active'
    },
    {
        uuid: '4eaaldjf223l324l2342342',
        name: 'James',
        email: 'james@gmail.com',
        role: 'Admin',
        createdAt: new Date(),
        status: 'Active'
    },
]
const userFilter = [
    {
        id: 'name',
        type: 'text',
        label: 'User Name',
        defaultValue: ''
    },
]

const templateFilter = [
    {
        id: 'name',
        type: 'text',
        label: 'Name',
        defaultValue: ''
    },
    {
        id: 'createdBy',
        type: 'dropdown',
        label: 'Created By',
        options: []
    },
]

export {
    settingsTabs,
    clientSettingsTab,
    customerColumns,
    customerList,
    customerFilters,
    userColumns,
    userList,
    userFilter,
    templateFilter,
    templateColumns,
    salesRepresentativeColumns,
    cardMapping,
    platformPartnersColumns,
    CsvColumns,
    authorizationColumns
}