import React from 'react'
import Grid from "@material-ui/core/Grid";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "../../Checkbox/Checkbox.module.scss";

const FormCheckBox = ({label, name, register}) => {
    return (
        <Grid item xs={12}>
            <FormControlLabel
                className={`${styles.centeredCheckbox}`}
                control={
                    <Checkbox
                        {...register(name)}
                        defaultChecked={false}
                        color="primary"
                    />
                }
                label={label}
            />
        </Grid>
    )
}

export default FormCheckBox