import React from 'react'
import { Button } from '@material-ui/core'
import { Col, Row } from 'react-bootstrap'
import { InputField } from 'components/forms/reduxForm'
import {
    phoneNumberValidation,
    validateEmail
} from 'tools/forms/validators'
import { useSelector } from 'react-redux'
import strings from 'strings'

const ProfileDetailForm = (props) => {
    const {
        onSubmit
    } = props
    const { email } = useSelector(state => state.form?.userProfileForm?.initial) || {}
    return (
        <div>
            <Col md={6} className="text-left">
                <h6>{strings.PROFILE} {strings.DETAILS}</h6>
                <InputField
                    name='firstName'
                    label='First Name *'
                    required
                />
                <InputField
                    name='lastName'
                    label='Last Name *'
                    required
                />
                <InputField
                    name='phone'
                    label='Phone *'
                    validate={phoneNumberValidation}
                    isphoneNumber
                    required
                />
                <InputField
                    name='email'
                    label='Email *'
                    validate={validateEmail}
                    required
                    disabled={Boolean(email) }
                />
            </Col>
            <Row className='p-2'>
                <Col md={6} className="text-right">
                    <Button
                        variant="contained"
                        color='primary'
                        onClick={onSubmit}
                    >
                        {strings.UPDATE}
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default ProfileDetailForm