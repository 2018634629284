import React, { useState } from 'react'
import { Alert } from '@material-ui/lab'
import Button from 'components/shared/Button'
import strings from 'strings'

const summaryStrings = strings.APPLICANT.SUMMARY

const GetAllDataAlert = (props) => {

    const { display, handleGetAllData, applicantId } = props

    const [loading, setLoading] = useState(false)

    const handleGetAllDataButton = () => {
        setLoading(true)
        handleGetAllData(applicantId)
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            {display &&
                <div className='d-flex justify-content-between mb-4 m-3'>
                    <Alert severity='info' className='flex-fill mr-2' >{summaryStrings.GET_ALL_DATA_TEXT}</Alert>
                    <div className='align-self-center'>
                        <Button
                            onClick={() => handleGetAllDataButton()}
                            loading={loading}
                            style={{ minWidth: '150px' }}
                            id='get-all-data'
                        >
                            {summaryStrings.GET_ALL_DATA}
                        </Button>
                    </div>
                </div>
            }
        </>
    )
}

export default GetAllDataAlert