import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { Tab, Tabs } from 'components/shared/Tabs';
import { errorView } from '../Tabs/UCC/views/infoView';
import { Preview } from './Preview';
import { changeTypeLabels } from 'constants/constantVariables';
import strings from 'strings';
import Moment from "react-moment";

export function DiffView(props) {
    const { filingNumber, ucc3Preview, downLoadAttachmentByFileId } = props
    const [tabValue, setTabValue] = useState(0);
    const [tabs, setTabs] = useState([])
    const {
        authorizingParty,
        currentName,
        collateral = {},
        changeAddName,
        assigneeName,
        lastUpdatedDate,
        errors,
        amendmentAction,
        amendmentType
    } = ucc3Preview
    const { description, attachments } = collateral
    const history = useHistory()
    const FilingNumber = queryString.parse(history.location.search).filingNumber || filingNumber

    useEffect(() => {
        const uccViewTabs = []
        const getDynamicTab = (data, label) => {
            if (Array.isArray(data) || (typeof data === 'object' && Object.keys(data).length)) {
                const formatedData = Array.isArray(data) ? data : [data]
                if (label === 'Collateral' && (!description && !attachments?.length)) return;
                const collateralData = []
                if (description && attachments.length)
                    collateralData.push({ data: { collateral: description, attachments }, collateralType: 'both' })
                else if (description)
                    collateralData.push({ data: { collateral: description }, collateralType: 'text' })
                else if (attachments.length)
                    collateralData.push({ data: { attachments }, collateralType: 'attachments' })
                uccViewTabs.push({ label, data: label === 'Collateral' ? collateralData : formatedData })
            }
        }

        getDynamicTab(authorizingParty, 'Authorizing Party')
        amendmentType !== 'Continuation' && getDynamicTab(currentName, 'Current Party')
        getDynamicTab(collateral, 'Collateral')
        getDynamicTab(changeAddName, 'Changes')
        getDynamicTab(assigneeName, 'Assignee')
        setTabs(uccViewTabs)
    }, [currentName, authorizingParty, collateral, changeAddName, assigneeName, amendmentType, description, attachments]);

    return (
        <div className='pl-3 pr-3 pt-2'>
            <div className="d-flex align-items-end block mt-3 ml-4">
                {FilingNumber && <>
                    <span className='heading-style-2 mr-2'>{strings.APPLICANT.CLIENTS.AMENDMENT_FILING_NUMBER} :</span>
                    <span className='mr-3'>{FilingNumber}</span>
                </>}
                <span className='heading-style-2 mr-2'>{strings.APPLICANT.UCCFILING.LAST_UPDATE} :</span>
                <span className='ml-1 mr-3'><Moment format="MM/DD/YYYY">{lastUpdatedDate}</Moment></span>
                <span className="heading-style-2 mr-2">{strings.APPLICANT.UCCFILING.AMENDMENT_TYPE} :</span>
                <span className='ml-1 mr-3'>{changeTypeLabels[amendmentAction.toLowerCase().includes('no') ? amendmentType : amendmentAction]}</span>
            </div>
            {errors?.length ? errorView(errors) : null}
            <Tabs
                data={tabs}
                value={tabValue}
                onChange={(event, newValue) => setTabValue(newValue)}
            />
            {tabs.map((tab, index) =>
                <Tab className='pt-3' value={tabValue} index={index} key={tab.label}>
                    <Preview data={tab.data} type={tab.label.toLowerCase()} downLoadAttachmentByFileId={downLoadAttachmentByFileId} />
                </Tab>
            )}
        </div>
    );
}