import React, { useState, useEffect, Fragment } from 'react'
import { reduxForm, FieldArray } from 'redux-form'
import { useSelector } from 'react-redux'
import { Card, Col, Row } from 'react-bootstrap'
import { Button, CircularProgress } from "@material-ui/core"
import { Remove, Add } from '@material-ui/icons'
import moment from 'moment'
import clsx from 'clsx'
import { InputField, DropdownField } from 'components/forms/reduxForm'
import CheckBox from 'components/forms/Checkbox'
import {
  statesOptions,
  countryOptions,
  businessTypeOption,
  industryTypeOption,
  namedOwner
} from 'constants/constantVariables'
import { DefaultApplicationForm, DefaultAddress } from 'constants/application'
import {
  phoneNumberValidation,
  maxLength9,
  minLength9,
  validateEmail,
  numbersOnly,
  isNotBlank,
} from 'tools/forms/validators'
import {
  removeBlanks
} from 'tools/forms/normalizers'
import strings from 'strings'
import './Applicants.scss'
import StateOfFormationCheckBox from "./partial/Dialog/BusinessModal/StateOfFormationCheckBox";
import StateOfFormationDropDown from "./partial/Dialog/BusinessModal/StateOfFormationDropdown";

const RenderOwners = ({ fields, addresses, owners, change }) => {
  const { push, length, remove } = fields
  const [stateOptions, setStateOptions] = useState([statesOptions.USA]);

  const changeAddress = (index, owner) => {

    const newCheckedValue = !owners[index]?.checked
    const newAddress = newCheckedValue ? addresses[0] : DefaultApplicationForm.owners[0].address

    change(`${owner}.checked`, newCheckedValue)

    setStateOptions(prev => {
      const newStateOptions = [...prev]
      newStateOptions[index] = newCheckedValue ? statesOptions[addresses[0].countryCode] : statesOptions.USA;
      return newStateOptions;
    })

    change(`owners[${index}].address`, newAddress)
  }

  const handleCountryChange = (selectedCountry, owner, index) => {
    change(`${owner}.address.state`, '')
    setStateOptions(prev => {
      const newStateOptions = [...prev]
      newStateOptions[index] = statesOptions[selectedCountry];
      return newStateOptions;
    })
  };

  const handleRemoveOwner = (index) => {
    setStateOptions(prev => {
      const newStateOptions = [...prev]
      newStateOptions.splice(index, 1);
      return newStateOptions;
    })
    remove(index)
  }

  const handleAddOwner = () => {
    setStateOptions(prev => [...prev, statesOptions.USA])
    push(DefaultApplicationForm.owners[0])
  }
  return (
    fields.map((owner, index) => (
      <Fragment key={index}>
        <div className={clsx('formContainer', index > 0 && 'pt-4')}>
          <Card.Title>
            {namedOwner[String(index)]}
          </Card.Title>
          <div className='d-flex align-items-center'>
            <CheckBox
              checked={owners[index]?.checked}
              onChange={() => {
                changeAddress(index, owner)
              }}
              label={strings.APPLICANT.USE_BUSINESS_ADDRESS}
            />
            {index ? <Remove className="mr-3 icon" onClick={() => handleRemoveOwner(index)} /> : null}
            {index < 2 && length === index + 1 ?
              <Add className="icon" onClick={() => {
                if (length < 3)
                  handleAddOwner()
              }} /> : null}
          </div>
        </div>
        <Row>
          <Col>
            <InputField
              name={`${owner}.firstName`}
              label='First Name'
              required
              indicateRequired
            />
          </Col>
          <Col>
            <InputField
              name={`${owner}.lastName`}
              label='Last Name'
              required
              indicateRequired
            />
          </Col>
          <Col>
            <InputField
              name={`${owner}.identifications[0].identification`}
              label='SSN'
              validate={[minLength9, maxLength9]}
              otherProps={{ maxLength: 9 }}
            />
          </Col>
          <Col>
            <InputField
              name={`${owner}.dateOfBirth`}
              label='DOB'
              type='date'
              InputLabelProps={{
                shrink: true,
              }}
              otherProps={{ max: moment().format("YYYY-MM-DD") }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <InputField
              name={`${owner}.address.address1`}
              label='Address 1'
              required
              indicateRequired
            />
          </Col>
          <Col>
            <InputField
              name={`${owner}.address.address2`}
              label='Address 2'
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DropdownField
              name={`${owner}.address.countryCode`}
              label='Country'
              options={countryOptions}
              required
              isSearchable
              onChange={country => handleCountryChange(country, owner, index)}
              id='ownerCountryCode'
            />
          </Col>
          <Col>
            <DropdownField
              name={`${owner}.address.state`}
              label='State'
              options={stateOptions[index]}
              required
              isSearchable
              id='ownerState'
            />
          </Col>
          <Col>
            <InputField
              name={`${owner}.address.city`}
              label='City'
              required
              indicateRequired
            />
          </Col>
          <Col>
            <InputField
              name={`${owner}.address.zip5`}
              label='Zipcode'
              required
              indicateRequired
            />
          </Col>
          <Col md={3}>
            <InputField
              name={`${owner}.phones[0].number`}
              label='Phone'
              validate={phoneNumberValidation}
              required
              isphoneNumber
              indicateRequired
            />
          </Col>
          <Col md={3}>
            <InputField
              name={`${owner}.emails[0].email`}
              label='Email'
              validate={validateEmail}
              normalize={removeBlanks}
              required
              indicateRequired
            />
          </Col>
        </Row>
      </Fragment>
    ))
  )
}

const RenderAddresses = ({ fields, change, name, disabled, required }) => {
  const { push, remove, length } = fields
  const [stateOptions, setStateOptions] = useState([statesOptions.USA]);
  const [addressState, setAddressState] = useState(DefaultApplicationForm?.business?.addresses[0]?.state)
  const [stateOfFormationCheckBox, setStateOfFormationCheckBox] = useState(false)
  const [disableStateOfFormation, setDisableStateOfFormation] = useState(false)

  const handleUseAddressStateAsStateOfFormation = () =>{
    if (stateOfFormationCheckBox){
      change(`${name}.stateOfFormation`, null)
      setStateOfFormationCheckBox(false)
      setDisableStateOfFormation(false)
    } else {
      change(`${name}.stateOfFormation`, addressState)
      setStateOfFormationCheckBox(true)
      setDisableStateOfFormation(true)
    }
  }

  const updateStateOfFormation = (state) => {
    if (!stateOfFormationCheckBox && !state) {
      change(`${name}.stateOfFormation`, null)
    }
  }

  const handleCountryChange = (selectedCountry, name, index) => {
    change(`${name}.addresses[${index}].state`, '')
    setStateOptions(prev => {
      const newStateOptions = [...prev]
      newStateOptions[index] = statesOptions[selectedCountry];
      return newStateOptions;
    })
  }

  const handleAddAddress = () => {
    push(DefaultAddress)
    setStateOptions(prev => [...prev, statesOptions.USA])
  }

  const handleRemoveAddress = (index) => {
    setStateOptions(prev => {
      const newStateOptions = [...prev]
      newStateOptions.splice(index, 1);
      return newStateOptions;
    })
    remove(index)
  }

  function updateAddressState(index) {
    if (index === 0) {
      return state => {
        if (stateOfFormationCheckBox) {
          change(`${name}.stateOfFormation`, state)
        }
        setAddressState(state);
      }
    }
  }

  return (
    fields.map((address, index) => (
      <div key={index}>
        {index === 1 && <h5 className='mt-3 addresses-title'> {strings.APPLICANT.ADDITIONAL_ADDRESSES} </h5>}
        <Row>
          <Col md={6}>
            <Row>
              <Col md={4}>
                <InputField
                    name={`${address}.address1`}
                    label='Address 1'
                    disabled={index === 0 ? disabled : false}
                    required={required}
                    indicateRequired={required}
                    id='address1'
                />
              </Col>
              <Col md={4}>
                <InputField
                    name={`${address}.address2`}
                    label='Address 2'
                    id='address2'
                />
              </Col>
              <Col md={4}>
                <InputField
                    name={`${address}.city`}
                    label='City'
                    disabled={index === 0 ? disabled : false}
                    required={required}
                    indicateRequired={required}
                    id='city'
                />
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col md={4}>
                <InputField
                    name={`${address}.zip5`}
                    label='Zipcode'
                    disabled={index === 0 ? disabled : false}
                    required={required}
                    indicateRequired={required}
                    id='zip5'
                />
              </Col>
              <Col md={4}>
                <DropdownField
                    name={`${address}.state`}
                    label='State'
                    options={stateOptions[index]}
                    onChange={updateAddressState(index)}
                    disabled={index === 0 ? disabled : false}
                    required={required}
                    isSearchable
                    id='state'
                />
              </Col>
              <Col md={3}>
                <DropdownField
                    name={`${address}.countryCode`}
                    label='Country'
                    options={countryOptions}
                    onChange={country => handleCountryChange(country, name, index)}
                    disabled={index === 0 ? disabled : false}
                    required={required}
                    isSearchable
                    id='countryCode'
                />
              </Col>
              <Col md={1}>
                <div className='center-icon'>
                  {index === 0 && length < 3 && <Add className="icon" onClick={() => handleAddAddress()}/>}
                  {index > 0 && <Remove className="icon" onClick={() => {
                    handleRemoveAddress(index)
                  }}/>}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {(index === 0 && name === 'business') &&
            <>
              <Col md={6}/>
              <Row className="state-of-formation-row">
                <Col md={2}>
                  <StateOfFormationCheckBox checked={stateOfFormationCheckBox}
                                            label={true}
                                            onChange={() => {handleUseAddressStateAsStateOfFormation(index)}}/>
                </Col>
                <Col md={2}>
                  <StateOfFormationDropDown
                      name={`${name}.stateOfFormation`}
                      stateOfFormationCheckBox={stateOfFormationCheckBox}
                      disabled={disableStateOfFormation}
                      stateOptions={stateOptions}
                      index={index}
                      onChange={updateStateOfFormation}/>
                </Col>
                <Col md={2}>
                </Col>
              </Row>
            </>
        }
      </div>
    ))
  )
}

const RenderBuisnessAndDebtors = (props) => {
  const {
    name,
    addDebtor,
    codeType,
    codeValue,
    change,
    hideFmcsa
  } = props

  const required = name === 'business' || (name === 'debtor' && addDebtor)
  const disabled = name === 'business' && codeType && codeValue ? true : false

  const representativeList = useSelector(state => state.root.salesRepresentative.list).map(
    data => ({ value: data.uuid, label: `${data.firstName} ${data.lastName}` }));

  return (
    <>
      <Row>
        <Col md={3}>
          <InputField
            name={`${name}.name`}
            label='Business Name'
            disabled={disabled}
            required={required}
            indicateRequired={required}
            id='businessName'
          />
        </Col>
        <Col md={3}>
          <InputField
            name={`${name}.dbaName`}
            label='Doing Business As'
            disabled={disabled}
            required={required}
            indicateRequired={required}
            id='dbaName'
          />
        </Col>
        <Col >
          <InputField
            name={`${name}.phones[0].number`}
            label='Phone'
            isphoneNumber
            validate={phoneNumberValidation}
            id='phone'
          />
        </Col>
        <Col>
          <InputField
            name={`${name}.emails[0].email`}
            label='Email'
            validate={validateEmail}
            normalize={removeBlanks}
            id='email'
          />
        </Col>
        <Col>
          <InputField
            name={`${name}.website`}
            label='Website'
            id='website'
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <DropdownField
            name={`${name}.businessType`}
            label='Business Type'
            isSearchable
            options={businessTypeOption}
            required={required}
            id='businessType'
          />
        </Col>
        <Col md={3}>
          <DropdownField
            name={`${name}.industryType`}
            label='Industry Type'
            isSearchable
            options={industryTypeOption}
            required={required}
            id='industryType'
          />
        </Col>
        <Col>
          <InputField
            name={`${name}.identifications[0].identification`}
            label='EIN'
            validate={[maxLength9, numbersOnly]}
            otherProps={{ maxLength: 9 }}
            id='identification'
          />
        </Col>
        {!hideFmcsa &&
          <>
            <Col>
              <InputField
                name={`${name}.mcNumber`}
                label='MC'
                id='mcNumber'
              />
            </Col>
            <Col>
              <InputField
                name={`${name}.dotNumber`}
                label='DOT'
                disabled={disabled}
                id='dotNumber'
              />
            </Col>
          </>
        }
      </Row>
      {name !== 'debtor' &&
        <Row>
          <Col md={3}>
            <DropdownField
              name='salesRepresentative.uuid'
              label='Sales Rep.'
              options={representativeList}
              isSearchable
              id='salesRepresentative'
            />
          </Col>
          <Col md={3}>
            <InputField
              name={`${name}.contractAmount`}
              label='Contract Amount'
              numberFormat
              id='contractAmount'
            />
          </Col>
          <Col>
            <InputField
              name='fcsClientReference'
              label='Client Reference'
              validate={isNotBlank}
              id='fcsClientReference'
            />
          </Col>
        </Row>
      }

      <FieldArray
          name={`${name}.addresses`}
          component={RenderAddresses}
          props={{ change, name, disabled, required }}
      />

    </>
  )
}

const CreateApplication = (props) => {
  const {
    getFmcsaData,
    createApplication,
    history,
    handleSubmit,
    codeType,
    codeValue,
    getSalesRepresentatives,
    change,
    hideFmcsa
  } = props
  const [loading, setLoading] = useState(false)
  const [formLoader, setFormLoader] = useState(false)
  const [addDebtor, setAddDebtor] = useState(false)
  const { values: { owners, business: { addresses } } } = useSelector(state => state?.form?.applicationForm) || {}

  useEffect(() => {
    if (codeType && codeValue) {
      setFormLoader(true)
      getFmcsaData({ codeType, codeValue }).finally(() => {
        setFormLoader(false)
      })
    }
  }, [getFmcsaData, codeType, codeValue])

  useEffect(() => {
    getSalesRepresentatives({}, true)
  }, [getSalesRepresentatives])

  const createNewApplicant = (data) => {
    setLoading(true)

    if (data?.business?.addresses) {
      data.business.addresses = data.business.addresses.map(address => ({
        ...address,
        addressType: "Work",
      }));
    }

    if (typeof data.business?.contractAmount === "string")
      data.business.contractAmount = Number(data.business.contractAmount.split(",").join(""));

    if (data.business.identifications[0].identification.length === 0) {
      delete data.business.identifications
    }

    if (data.owners)
      data.owners.forEach(owner => {
        if (owner?.identifications?.length && !owner.identifications[0].identification)
          delete owner.identifications
      })

    if (!data.salesRepresentative?.uuid)
      delete data.salesRepresentative

    if (!addDebtor)
      delete data.debtor

    createApplication(data).then(res => {
      history.push(`/applicant/${res.uuid}`)
    }).finally(() => {
      setLoading(false)
    })
  }

  if (formLoader)
    return <CircularProgress className='circularLoader' />

  return (
    <div id='newApplicant'>
      <h5 className='business-title'>{strings.APPLICANT.NEW_APPLICATION}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(data => createNewApplicant(data))}
          disabled={loading}
        >
          {strings.CREATE_APPLICATION}
        </Button>
      </h5>
      <Row className='mb-3'>
        <Col>
          <>
            <form onSubmit={handleSubmit(data => createNewApplicant(data))}>
              <Card className='border-0 shadow'>
                <Card.Body>
                  <Card.Title className='business-title'>
                    {strings.BUSINESS_INFORMATION}
                  </Card.Title>
                  <RenderBuisnessAndDebtors
                    name='business'
                    addDebtor={addDebtor}
                    codeType={codeType}
                    codeValue={codeValue}
                    change={change}
                    hideFmcsa={hideFmcsa}
                  />
                </Card.Body>
              </Card>

              <Card className='border-0 shadow mt-4'>
                <Card.Body>
                  <FieldArray
                    name="owners"
                    component={RenderOwners}
                    props={{ addresses, owners, change }}
                  />
                </Card.Body>
              </Card>

              <Card className='border-0 shadow mt-4'>
                <Card.Body>
                  <Card.Title className='business-title d-flex'>
                    {strings.DEBTOR_INFORMATION}
                    <div>
                      {addDebtor ?
                        <Remove className="icon" onClick={() => setAddDebtor(false)} />
                        :
                        <Add className="icon" onClick={() => setAddDebtor(true)} />}
                    </div>
                  </Card.Title>

                  {addDebtor && <RenderBuisnessAndDebtors
                    name='debtor'
                    addDebtor={addDebtor}
                    codeType={codeType}
                    codeValue={codeValue}
                    change={change}
                    hideFmcsa={hideFmcsa}
                  />}
                </Card.Body>
              </Card>
            </form>
          </>
        </Col>
      </Row>
    </div>
  )
}

export default reduxForm({
  form: 'applicationForm',
  enableReinitialize: true
})(CreateApplication)
