import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
    DialogTitle,
    DialogContent, Button, CircularProgress, Typography,
    DialogActions,
    Backdrop
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { DocumentRequestColumn } from 'constants/application'
import TableComponent from 'components/shared/TableComponent'
import { Dialog } from 'components/shared/Dialog'
import { Toast } from 'components/shared/Toaster'
import styles from './documentReq.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab'
import strings from 'strings'
import {retrieveDocument, downLoadAttachmentByFileId} from "../../../../../actions/applicants";

const uccString = strings.APPLICANT.UCC

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#ffff',
        position: "absolute",
        backgroundColor: 'rgb(255 255 255 / 80%)',
        display: 'flex',
        flexDirection: 'column'
    },
    parent: {
        position: "relative",
        zIndex: 0
    }
}));

const DocumentRequest = (props) => {
    const {
        open,
        onClose,
        interactionId,
        getRetrieveDocuments,
        selectedReports, // selected filing list
        entityUUID,
        entityType,
        transactionID,
        listData,
        stateCode
    } = props
    const classes = useStyles();
    const [filingNumberList, setFilingNumberList] = useState({
        filingNumbersWithPages: [],
        filingNumbersWithoutPages: []
    })
    const [totalPages, setTotalPages] = useState(0)
    const [loading, setLoading] = useState(false)
    const [disableOffline, setDisableOffline] = useState(false)
    const searchId = useSelector(state => state?.applicant?.ucc?.uccSearchId);
    const timerRef = useRef(null); // variable used to clean up the setTimeOut on component unmount

    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
      }, []);

    /**
     * 1. The code maps over selectedReports to create two new arrays: filingNumbersWithPages 
     * and filingNumbersWithoutPages.
     * 2. For each report, it first finds the filingNumber with originalFiling set to true.
     * 3. Then, it sorts the remaining filingNumbers by originalFiling (with true coming first) 
     * and filters by noOfPages.
     */
    useEffect(() => {
        const getFilingNumbersByPages = (withPages) => selectedReports.map(item => {
            let initialFiling = item.filingNumbers.find(filing => filing.originalFiling)?.filingNumber || null;
            if (initialFiling === null && item.filingNumbers?.length > 0) {
                const match = listData.find((list) => {
                    return list.Amendments.some((amendment) => {
                        return item.filingNumbers.some((filter) => {
                            if (withPages) {
                                return amendment.FilingNumber === filter.filingNumber && filter.noOfPages > 0
                            } else {
                                return amendment.FilingNumber === filter.filingNumber && filter.noOfPages === 0
                            }
                        });
                    });
                });
                if (match) {
                    initialFiling = match?.FilingNumber || ''
                }
            }
            const filingNumbers = item.filingNumbers
                .sort((a, b) => b.originalFiling - a.originalFiling)
                .filter(filing => {
                    if (withPages) {
                        return filing.noOfPages > 0
                    } else {
                        return filing.noOfPages === 0
                    }
                });
            if (filingNumbers?.length > 0) {
                return [{ initialFiling }, ...filingNumbers];
            }
            return []
        });

        const filingNumbersWithPages = getFilingNumbersByPages(true)
        const filingNumbersWithoutPages = getFilingNumbersByPages(false)

        const withPages = filingNumbersWithPages.flat().map((item, index) => ({ ...item, index }))
        const withoutPages = filingNumbersWithoutPages.flat().map((item, index) => ({ ...item, index }))

        //setting up the total pages of online filings
        const totalPages = withPages.reduce((total, filing) => {
            if (filing.noOfPages) {
                total += filing.noOfPages;
            }
            return total;
        }, 0);
        setTotalPages(totalPages)
        setFilingNumberList({
            filingNumbersWithoutPages: withoutPages,
            filingNumbersWithPages: withPages
        })
    }, [selectedReports, listData])


    const fetchDocuments = (interactionId, maxCount) => {
        return getRetrieveDocuments(interactionId).then((res) => {
            const { fileId, fileName, status } = res?.documents[0] || {};
            if (status !== 'Ready To Download' && maxCount > 1) {
                // Call the function recursively with the same interactionId
                return timerRef.current = setTimeout(() => {
                    fetchDocuments(interactionId, maxCount - 1)
                }, 3000);
            } else if(maxCount > 1) {
                setLoading(true)
                downLoadAttachmentByFileId(fileId, fileName)().finally(() => { setLoading(false) })
            } else {
                setLoading(false)
            }
            return res;
        });
    }

    const getFilingNumbers = (filingList) => {
        return filingList?.map(item => item.filingNumber).filter(item => item !== undefined)
    }

    /**
     * 
     * @param {number} type 
     * type 0 is online
     * type 1 is offline
     */
    const handleDownload = (type) => {
        setLoading(true)
        const onlineFilings = getFilingNumbers(filingNumberList.filingNumbersWithPages)
        const offlineFilings = getFilingNumbers(filingNumberList.filingNumbersWithoutPages)
        const showToast = () => {
            Toast({
                type: "success", message: uccString.UCC_DOWNLOAD_MSG
            })
            fetchDocuments(interactionId, 20)
        }
        const payLoad = {
            [entityType === 'OWNER' ? 'ownerId' : 'businessId']: entityUUID,
            filingNumbers: type === 0 ? onlineFilings : offlineFilings,
            transactionID,
            interactionId,
            searchId
        }
        if (type === 1) {
            payLoad['offlineRequest'] = true
            retrieveDocument(payLoad, stateCode)().finally(() => {
                setDisableOffline(true)
                setLoading(false)
                Toast({
                    type: "success", message: uccString.OFFLINE_DOCUMENTS_REQUEST_SUCCESS
                })
            })
        } else {
            retrieveDocument(payLoad, stateCode)().then(showToast).catch(() => setLoading(false))
        }
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.initialFiling) {
            style.backgroundColor = '#D0D0D0';
        }
        return style;
    };

    return (

        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='lg'
            onClose={onClose}
            scroll="paper"
        >
            <div>
                <div className={styles.drawerTitle}>
                    <div className={styles.titleContainer}>
                        <DialogTitle>{strings.DOCUMENT_REQUEST_CONFIRMATION}</DialogTitle>
                    </div>
                    <div className={styles.actionContainer}>
                        <CloseIcon onClick={onClose} />
                    </div>
                </div>
                <DialogContent className={classes.parent}>
                    <div className={`${styles.listContainer}`}>
                        <Backdrop className={classes.backdrop} open={loading} >
                            <CircularProgress color="primary" />
                            <Typography variant="body1" className={styles.loadingText}>{strings.DOCUMENT_REQUEST_LOADING}</Typography>
                        </Backdrop>
                        <div className={styles.boxMargin}>
                            <Alert severity="info">{strings.INFO_FILING_REQUEST}</Alert>
                        </div>
                        <div className={styles.docListContainer}>
                            <div className={`${styles.list} box`}>
                                <div className={styles.titleBtnContainer}>
                                    <div>
                                        <Typography variant="h6">{strings.ONLINE} {strings.DOCUMENT_LIST}</Typography>
                                        <Typography variant="body2">Total Pages: {totalPages}</Typography>
                                    </div>
                                    <Button color='primary' variant='contained' onClick={() => handleDownload(0)} disabled={filingNumberList.filingNumbersWithPages?.length === 0}>Download Online Documents</Button>
                                </div>
                                <TableComponent
                                    keyField={'index'}
                                    columns={DocumentRequestColumn}
                                    data={filingNumberList.filingNumbersWithPages}
                                    showPagination={false}
                                    rowStyle={rowStyle}
                                />
                            </div>
                            <div className={`${styles.list} box`}>
                                {
                                    disableOffline && (<Alert severity="info">{uccString.ALREADY_REQUESTED_OFFLINE_DOCUMENTS}</Alert>)
                                }
                                <div className={styles.titleBtnContainer}>
                                    <div>
                                        <Typography variant="h6">{strings.OFFLINE} {strings.DOCUMENT_LIST}</Typography>
                                        <Typography variant="body2">Total Pages: 0</Typography>
                                    </div>
                                    <Button color='primary' variant='contained' onClick={() => handleDownload(1)} disabled={filingNumberList.filingNumbersWithoutPages.length === 0 || disableOffline}>Request Offline Documents</Button>
                                </div>
                                <TableComponent
                                    keyField={'index'}
                                    columns={DocumentRequestColumn}
                                    data={filingNumberList.filingNumbersWithoutPages}
                                    showPagination={false}
                                    rowStyle={rowStyle}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button color='primary' variant='outlined' onClick={onClose} className='mr-2'>
                            {strings.CLOSE}
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </Dialog >
    )
}

export default DocumentRequest