import { connect } from 'react-redux'
import ClientProfile from "components/RoxAdmin/ClientProfile"
import { getClientById, updateClient } from 'actions/client'
import { getUsersList, dispatchFormConfig, createUser, updateUser, toggleUser } from 'actions/user'
import {
    createTemplate,
    updateTemplate,
    dispatchProcessing,
    getRolesList,
    createSalesRepresentatives,
    updateSalesRepresentatives,
    deleteSalesRepresentatives,
    getSalesRepresentatives,
    getTemplates
} from 'actions/root'
import strings from 'strings'

const defaultUserFormValue = {
    is_superuser: false,
}

function mapStateToProps(stateProps, ownProps) {
    const { match } = ownProps
    const { clientId } = match.params
    const { clientById } = stateProps?.client
    const { users, formConfig } = stateProps?.user
    const headers = users.headers
    const { templates, salesRepresentative } = stateProps?.root
    const { list: salesRepresentativeList = [], headers: salesRepresentativeHeaders = {} } = salesRepresentative
    const { list: templateList = [], headers: templateHeaders = {} } = templates
    const totalRecords = headers['x-pagination-total-records']
    const totalPages = headers['x-pagination-total-pages']
    const salesRepresentativeTotalRecords = salesRepresentativeHeaders['x-pagination-total-records']
    const salesRepresentativeTotalPages = salesRepresentativeHeaders['x-pagination-total-pages']
    const templateTotalRecords = templateHeaders['x-pagination-total-records']
    const templateTotalPages = templateHeaders['x-pagination-total-pages']

    let initialValue = {}
    switch (formConfig?.type) {
        case strings.CUSTOMER:
            initialValue = { client: clientById }
            break;
        case strings.SETTINGS_FORM.USER_EDIT_FORM:
            initialValue = users.data.filter(item => item.userId === formConfig?.id)[0]
            initialValue = { ...initialValue, roles: initialValue?.roles?.map(role => ({ label: role.name, value: role.roleId })) || [] }
            break;
        case strings.SETTINGS_FORM.USER_CREATE_FORM:
            initialValue = defaultUserFormValue
            break;
        case strings.SETTINGS_FORM.ADD_SALES_REPRESENTATIVE:
            initialValue = defaultUserFormValue
            break;
        case strings.SETTINGS_FORM.EDIT_SALES_REPRESENTATIVE:
            initialValue = salesRepresentativeList.find(item => item.uuid === formConfig?.id)
            break;
        case strings.SETTINGS_FORM.USER_CREATE_TEMPLATE:
            initialValue = defaultUserFormValue
            break;
        default:
            initialValue = defaultUserFormValue
            break;
    }
    return {
        initialValues: initialValue,
        customerData: clientById,
        usersList: users?.data,
        clientId,
        totalRecords,
        totalPages,
        salesRepresentativeList,
        salesRepresentativeTotalPages,
        salesRepresentativeTotalRecords,
        templateList,
        templateTotalRecords,
        templateTotalPages
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        // add actions file
        getClientById, //action to get clients by id
        dispatchProcessing, //action to show loader
        getUsersList, // action to get users list
        updateClient, // action to update client
        createUser, // action to create user
        updateUser, // action to update User
        toggleUser, // action to enable or disable a user
        dispatchFormConfig, // action to set default form value
        getRolesList, //actiom to get list of roles
        getSalesRepresentatives, // action to get list of salesRepresentatives
        createSalesRepresentatives, // action to create salesRepresentatives
        updateSalesRepresentatives, // action to update salesRepresentatives
        deleteSalesRepresentatives, // action to delete salesRepresentatives
        getTemplates, //action to get list of templates
        createTemplate, //actin to create template
        updateTemplate, //action to update template
    },
    mergeProps
)(ClientProfile)