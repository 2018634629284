import moment from "moment"

const ClientAdminMenuBarList = [
  { id: 'dashboard', label: 'Dashboard', link: '/dashboard', icon: 'dashboard', type: 'parent' },
  { id: 'quick-search', label: 'Quick Search', link: '/quick-search', icon: 'quickSearch', type: 'parent' },
  { id: 'applicants', label: 'Applications', link: '/applicants', icon: 'applications', type: 'parent' },
  { id: 'ucc', label: 'UCCs', link: '/ucc', icon: 'clients', type: 'parent' },
  { id: 'settings', label: 'Settings', link: '/settings', icon: 'settings', type: 'children' },
  { id: 'help', label: 'Help', link: 'https://support.roxwrite.com/hc/en-us', icon: 'help', type: 'children' },
  { id: 'profile', label: 'Profile', link: '/profile', icon: 'profile', type: 'children' }
]

const RoxAdminMenuBarList = [
  { id: 'dashboard', label: 'Dashboard', link: '/dashboard', icon: 'dashboard', type: 'parent' },
  { id: 'settings', label: 'Settings', link: '/settings', icon: 'settings', type: 'parent' },
  { id: 'manage-csv', label: 'CSV Uploads', link: '/manage-csv', icon: 'csv', type: 'parent' },
  // { id: 'notifications', label: 'Notifications', link: '/notifications', icon: 'notifications', className: 'mt-20' },
  { id: 'profile', label: 'Profile', link: '/profile', icon: 'profile', className: 'parentMenuItem', type: 'children' }
]

const profileTabs = [
  { label: 'Details' },
  { label: 'Password' }
]

const applicationTabs = [
  { label: 'Business Information' },
  { label: 'Owners' },
  { label: 'Debtors' }
]

const uccSearchDrawerTabs = [
  { label: 'Search' },
  { label: 'Attached Filings' },
  { label: 'Search History' },
  { label: 'Audit' }
]

const transactionTabs = [
  { label: 'Insights' },
  { label: 'Recurring Transactions' },
  { label: 'All Transactions' }
]

const tabs = [
  { id: 'Overview', label: 'Overview', url: "?tab=overview" },
  { id: 'FMCSA', label: 'FMCSA', url: "?tab=fmcsa" },
  { id: 'SOS Links', label: 'SOS Links', url: "?tab=sos" },
  { id: 'Verification', label: 'Verification', url: "?tab=verification" },
  { id: 'Background', label: 'Background', url: "?tab=bgv" },
  { id: 'UCC', label: 'UCC', url: '?tab=ucc' },
  { id: 'New UCC', label: 'UCC', url: '?tab=ucc'},
  { id: 'Credit Check', label: 'Credit Check', url: '?tab=ucc' },
  { id: 'Bank Transactions', label: 'Bank Transactions', url: '?tab=transactions', disabled: !process.env.NODE_ENV === 'development' },
  { id: 'Notes', label: 'Notes', url: '?tab=notes' },
  { id: 'Attachments', label: 'Attachments', url: '?tab=attachments' }]
  

const getApplicationDetailTabsFcn = (hiddenElements) => {
  tabs.forEach((tab) => {
    if (hiddenElements.includes(tab.id)) { tab.hidden = true };
  });
  return tabs;
}

const getTabByReportType = (reportType) => {
  switch (reportType) {
    case 'FMCSA':
      return getTabIndex('FMCSA');
    case 'SOS':
      return getTabIndex('SOS Links');
    case 'LEXIS_VERIFICATION':
      return getTabIndex('Verification');
    case 'UCC':
      return getTabIndex('UCC');
    case 'LEXIS_BACKGROUND':
      return getTabIndex('Background');
    default:
      return getTabIndex('Overview');
  }
}

const getTabIndex = (tab) => {
  return tabs.findIndex((item) => item.label === tab);
}

const loginTabs = [
  { label: "Login" },
  { label: "Register" }
]

const clientabs = [
  { label: "Active", url: '?tab=active' },
  { label: "Expiring", url: '?tab=expiring' },
  { label: "Expired", url: '?tab=expired' },
  { label: "Awaiting Response", url: '?tab=awaiting' },
  { label: "Successful Amendments", url: '?tab=success' },
  { label: "Rejected Amendments", url: '?tab=rejected' },
  { label: "Terminated", url: '?tab=terminated' },
]

const uccOperationTabs = [
  { label: "Debtors" },
  { label: "Collateral" },
  { label: "Secured Party" },
]

const statesOptions = {
  USA: [
    { label: "Alabama", value: "AL" },
    { label: "Alaska", value: "AK" },
    { label: "Arizona", value: "AZ" },
    { label: "Arkansas", value: "AR" },
    { label: "California", value: "CA" },
    { label: "Colorado", value: "CO" },
    { label: "Connecticut", value: "CT" },
    { label: "Delaware", value: "DE" },
    { label: "Florida", value: "FL" },
    { label: "Georgia", value: "GA" },
    { label: "Hawaii", value: "HI" },
    { label: "Idaho", value: "ID" },
    { label: "Illinois", value: "IL" },
    { label: "Indiana", value: "IN" },
    { label: "Iowa", value: "IA" },
    { label: "Kansas", value: "KS" },
    { label: "Kentucky", value: "KY" },
    { label: "Louisiana", value: "LA" },
    { label: "Maryland", value: "MD" },
    { label: "Maine", value: "ME" },
    { label: "Massachusetts", value: "MA" },
    { label: "Michigan", value: "MI" },
    { label: "Minnesota", value: "MN" },
    { label: "Mississippi", value: "MS" },
    { label: "Missouri", value: "MO" },
    { label: "Montana", value: "MT" },
    { label: "Nebraska", value: "NE" },
    { label: "Nevada", value: "NV" },
    { label: "New Hampshire", value: "NH" },
    { label: "New Jersey", value: "NJ" },
    { label: "New Mexico", value: "NM" },
    { label: "New York", value: "NY" },
    { label: "North Carolina", value: "NC" },
    { label: "North Dakota", value: "ND" },
    { label: "Ohio", value: "OH" },
    { label: "Oklahoma", value: "OK" },
    { label: "Oregon", value: "OR" },
    { label: "Pennsylvania", value: "PA" },
    { label: "Rhode Island", value: "RI" },
    { label: "South Carolina", value: "SC" },
    { label: "South Dakota", value: "SD" },
    { label: "Tennessee", value: "TN" },
    { label: "Texas", value: "TX" },
    { label: "Utah", value: "UT" },
    { label: "Vermont", value: "VT" },
    { label: "Virginia", value: "VA" },
    { label: "Washington", value: "WA" },
    { label: "West Virginia", value: "WV" },
    { label: "Wisconsin", value: "WI" },
    { label: "Wyoming", value: "WY" },
  ],
  MEX: [
    { label: "Aguascalientes", value: "AG" },
    { label: "Baja California", value: "BC" },
    { label: "Baja California Sur", value: "BS" },
    { label: "Campeche", value: "CM" },
    { label: "Chiapas", value: "CS" },
    { label: "Chihuahua", value: "CH" },
    { label: "Coahuila", value: "CO" },
    { label: "Colima", value: "CL" },
    { label: "Mexico City", value: "DF" },
    { label: "Durango", value: "DG" },
    { label: "Guanajuato", value: "GT" },
    { label: "Guerrero", value: "GR" },
    { label: "Hidalgo", value: "HG" },
    { label: "Jalisco", value: "JA" },
    { label: "Mexico", value: "EM" },
    { label: "Michoacan", value: "MI" },
    { label: "Morelos", value: "MO" },
    { label: "Nayarit", value: "NA" },
    { label: "Nuevo Leon", value: "NL" },
    { label: "Oaxaca", value: "OA" },
    { label: "Puebla", value: "PU" },
    { label: "Queretaro", value: "QT" },
    { label: "Quintana Roo", value: "QR" },
    { label: "San Luis Potosi", value: "SL" },
    { label: "Sinaloa", value: "SI" },
    { label: "Sonora", value: "SO" },
    { label: "Tabasco", value: "TB" },
    { label: "Tamaulipas", value: "TM" },
    { label: "Tlaxcala", value: "TL" },
    { label: "Veracruz", value: "VE" },
    { label: "Yucatan", value: "YU" },
    { label: "Zacatecas", value: "ZA" },
  ],
  CAN: [
    { label: "Alberta", value: "AB" },
    { label: "British Columbia", value: "BC" },
    { label: "Manitoba", value: "MB" },
    { label: "New Brunswick", value: "NB" },
    { label: "Newfoundland and Labrador", value: "NL" },
    { label: "Nova Scotia", value: "NS" },
    { label: "Ontario", value: "ON" },
    { label: "Prince Edward Island", value: "PE" },
    { label: "Quebec", value: "QC" },
    { label: "Saskatchewan", value: "SK" },
    { label: "Yukon", value: "YK" },
    { label: "Northwest Territories", value: "NT" },
    { label: "Nunavut", value: "NU" },
  ],
};

const statesGroupedByCountry = [
  {
    label: 'USA',
    value: 'USA',
    options: [
      { label: "Alabama", value: "AL" },
      { label: "Alaska", value: "AK" },
      { label: "Arizona", value: "AZ" },
      { label: "Arkansas", value: "AR" },
      { label: "California", value: "CA" },
      { label: "Colorado", value: "CO" },
      { label: "Connecticut", value: "CT" },
      { label: "Delaware", value: "DE" },
      { label: "Florida", value: "FL" },
      { label: "Georgia", value: "GA" },
      { label: "Hawaii", value: "HI" },
      { label: "Idaho", value: "ID" },
      { label: "Illinois", value: "IL" },
      { label: "Indiana", value: "IN" },
      { label: "Iowa", value: "IA" },
      { label: "Kansas", value: "KS" },
      { label: "Kentucky", value: "KY" },
      { label: "Louisiana", value: "LA" },
      { label: "Maryland", value: "MD" },
      { label: "Maine", value: "ME" },
      { label: "Massachusetts", value: "MA" },
      { label: "Michigan", value: "MI" },
      { label: "Minnesota", value: "MN" },
      { label: "Mississippi", value: "MS" },
      { label: "Missouri", value: "MO" },
      { label: "Montana", value: "MT" },
      { label: "Nebraska", value: "NE" },
      { label: "Nevada", value: "NV" },
      { label: "New Hampshire", value: "NH" },
      { label: "New Jersey", value: "NJ" },
      { label: "New Mexico", value: "NM" },
      { label: "New York", value: "NY" },
      { label: "North Carolina", value: "NC" },
      { label: "North Dakota", value: "ND" },
      { label: "Ohio", value: "OH" },
      { label: "Oklahoma", value: "OK" },
      { label: "Oregon", value: "OR" },
      { label: "Pennsylvania", value: "PA" },
      { label: "Rhode Island", value: "RI" },
      { label: "South Carolina", value: "SC" },
      { label: "South Dakota", value: "SD" },
      { label: "Tennessee", value: "TN" },
      { label: "Texas", value: "TX" },
      { label: "Utah", value: "UT" },
      { label: "Vermont", value: "VT" },
      { label: "Virginia", value: "VA" },
      { label: "Washington", value: "WA" },
      { label: "West Virginia", value: "WV" },
      { label: "Wisconsin", value: "WI" },
      { label: "Wyoming", value: "WY" },
    ]
  },
  {
    label: 'MEXICO',
    value: 'MEX',
    options: [
      { label: "Aguascalientes", value: "AG" },
      { label: "Baja California", value: "BC" },
      { label: "Baja California Sur", value: "BS" },
      { label: "Campeche", value: "CM" },
      { label: "Chiapas", value: "CS" },
      { label: "Chihuahua", value: "CH" },
      { label: "Coahuila", value: "CO" },
      { label: "Colima", value: "CL" },
      { label: "Mexico City", value: "DF" },
      { label: "Durango", value: "DG" },
      { label: "Guanajuato", value: "GT" },
      { label: "Guerrero", value: "GR" },
      { label: "Hidalgo", value: "HG" },
      { label: "Jalisco", value: "JA" },
      { label: "Mexico", value: "EM" },
      { label: "Michoacan", value: "MI" },
      { label: "Morelos", value: "MO" },
      { label: "Nayarit", value: "NA" },
      { label: "Nuevo Leon", value: "NL" },
      { label: "Oaxaca", value: "OA" },
      { label: "Puebla", value: "PU" },
      { label: "Queretaro", value: "QT" },
      { label: "Quintana Roo", value: "QR" },
      { label: "San Luis Potosi", value: "SL" },
      { label: "Sinaloa", value: "SI" },
      { label: "Sonora", value: "SO" },
      { label: "Tabasco", value: "TB" },
      { label: "Tamaulipas", value: "TM" },
      { label: "Tlaxcala", value: "TL" },
      { label: "Veracruz", value: "VE" },
      { label: "Yucatan", value: "YU" },
      { label: "Zacatecas", value: "ZA" },
    ]
  },
  {
    label: 'CANADA',
    value: 'CAN',
    options: [
      { label: "Alberta", value: "AB" },
      { label: "British Columbia", value: "BC" },
      { label: "Manitoba", value: "MB" },
      { label: "New Brunswick", value: "NB" },
      { label: "Newfoundland and Labrador", value: "NL" },
      { label: "Nova Scotia", value: "NS" },
      { label: "Ontario", value: "ON" },
      { label: "Prince Edward Island", value: "PE" },
      { label: "Quebec", value: "QC" },
      { label: "Saskatchewan", value: "SK" },
      { label: "Yukon", value: "YK" },
      { label: "Northwest Territories", value: "NT" },
      { label: "Nunavut", value: "NU" },
    ]
  }
];

const getStateCode = (state, country) => {
  if (state.length === 2)return state;
  if (state.length > 2) {
      const code = statesOptions[country].find((item) => item.label === state).value;
      return code ? code : "";
  }
  return "";
}

const countryOptions = [
  { label: "USA", value: "USA" },
  { label: "MEXICO", value: "MEX" },
  { label: "CANADA", value: "CAN" }
];

const businessTypeOption = [
  { label: "Sole Proprietorships", value: "Sole Proprietorships" },
  { label: "Corporations", value: "Corporations" },
  { label: "S Corporations", value: "S Corporations" },
  { label: "Partnerships", value: "Partnerships" },
  { label: "Limited Liability Company (LLC)", value: "Limited Liability Company (LLC)" },
  { label: "Limited Liability Corporation", value: "Limited Liability Corporation" }
];

const industryTypeOption = [
  { label: "Transportation", value: "Transportation" },
  { label: "Trucking and Freight", value: "Trucking and Freight" },
  { label: "General Factoring", value: "General Factoring" },
  { label: "Construction", value: "Construction" },
  { label: "Staffing", value: "Staffing" },
  { label: "Oil and Gas", value: "Oil and Gas" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Food and Beverage", value: "Food and Beverage" },
  { label: "General", value: "General" },
  { label: "Healthcare", value: "Healthcare" }
];

const statusOptions = [
  { label: 'Under Review', value: 'Under Review', color: '#775dd0'},
  { label: 'New', value: 'New', color: '#3F51B5'},
  { label: 'Pending', value: 'Pending', color: '#feb019'},
  { label: 'Approved', value: 'Approved', color: '#00e396'},
  { label: 'Declined', value: 'Declined', color: '#ff4560'},
  { label: 'Cancelled', value: 'Cancelled', color: '#FF4D00E8'},
  { label: 'Cautionary Approval', value: 'Cautionary Approval', color: '#065640'},
]

const uccStatusOptions = [
  { label: 'Not Started', value: 'In-Progress' },
  { label: 'Inprogress', value: 'Pre-Approved' },
  { label: 'Completed', value: 'Declined' }
]

const strOptions = [
  { label: 'No Search To Reflect', value: 'NO_SEARCH' },
  { label: 'Plain STR', value: 'PLAIN_LISTING' },
  { label: 'Certified STR', value: 'CERTIFIED_LISTING' }
]

const acceptable_files = "image/png, image/jpg, image/jpeg, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow"

const namedOwner = {
  '0': 'Owner Information',
  '1': 'Second Owner',
  '2': 'Third Owner'
}

const secretaryOfStates = {
  Alabama: 'https://arc-sos.state.al.us/CGI/CORPNAME.MBR/INPUT',
  Alaska: 'https://www.commerce.alaska.gov/cbp/main/Search/Entities',
  Arizona: 'https://ecorp.azcc.gov/EntitySearch/Index',
  Arkansas: 'http://www.sos.arkansas.gov/corps/search_all.php',
  California: 'https://bizfileonline.sos.ca.gov/search/business',
  Colorado: 'http://www.sos.state.co.us/biz/BusinessEntityCriteriaExt.do',
  Connecticut: 'https://service.ct.gov/business/s/onlinebusinesssearch?language=en_US',
  Delaware: 'https://icis.corp.delaware.gov/Ecorp/EntitySearch/NameSearch.aspx',
  'Dist. of Columbia': 'https://corponline.dcra.dc.gov/Home.aspx',
  Florida: 'http://search.sunbiz.org/Inquiry/CorporationSearch/ByName',
  Georgia: 'https://ecorp.sos.ga.gov/BusinessSearch',
  Hawaii: 'http://hbe.ehawaii.gov/documents/search.html',
  Idaho: 'https://sosbiz.idaho.gov/search/business',
  Illinois: 'http://www.ilsos.gov/corporatellc',
  Indiana: 'https://bsd.sos.in.gov/publicbusinesssearch',
  Iowa: 'https://sos.iowa.gov/search/business/search.aspx',
  Kansas: 'https://www.kansas.gov/bess/flow/main',
  Kentucky: 'https://web.sos.ky.gov/bussearchnprofile/search',
  Louisiana: 'https://coraweb.sos.la.gov/CommercialSearch/CommercialSearch.aspx',
  Maine: 'https://icrs.informe.org/nei-sos-icrs/ICRS?MainPage=x',
  Maryland: 'https://egov.maryland.gov/BusinessExpress/EntitySearch',
  Massachusetts: 'http://corp.sec.state.ma.us/corpweb/corpsearch/CorpSearch.aspx',
  Michigan: 'https://cofs.lara.state.mi.us/corpweb/CorpSearch/CorpSearch.aspx',
  Minnesota: 'https://mblsportal.sos.state.mn.us/Business/Search',
  Mississippi: 'https://corp.sos.ms.gov/corp/portal/c/page/corpBusinessIdSearch/portal.aspx',
  Missouri: 'https://bsd.sos.mo.gov/BusinessEntity/BESearch.aspx?SearchType=0',
  Montana: 'https://biz.sosmt.gov/search/business',
  Nebraska: 'https://www.nebraska.gov/sos/corp/corpsearch.cgi',
  Nevada: 'https://esos.nv.gov/EntitySearch/OnlineEntitySearch',
  'New Hampshire': 'https://quickstart.sos.nh.gov/online/BusinessInquire',
  'New Jersey': 'https://www.njportal.com/DOR/businessrecords/EntityDocs/BusinessStatCopies.aspx',
  'New Mexico': 'https://portal.sos.state.nm.us/BFS/online/CorporationBusinessSearch',
  'New York': 'https://dos.ny.gov/corporation-and-business-entity-database',
  'North Carolina': 'https://www.sosnc.gov/search/index/corp',
  'North Dakota': 'https://firststop.sos.nd.gov/search/business',
  Ohio: 'https://businesssearch.ohiosos.gov/',
  Oklahoma: 'https://www.sos.ok.gov/corp/corpInquiryFind.aspx',
  Oregon: 'http://egov.sos.state.or.us/br/pkg_web_name_srch_inq.login',
  Pennsylvania: 'https://www.corporations.pa.gov/Search/CorpSearch',
  'Puerto Rico': 'https://prcorpfiling.f1hst.com/CorporationSearch.aspx',
  'Rhode Island': 'http://business.sos.ri.gov/CorpWeb/CorpSearch/CorpSearch.aspx',
  'South Carolina': 'https://businessfilings.sc.gov/BusinessFiling/Entity/Search',
  'South Dakota': 'https://sosenterprise.sd.gov/BusinessServices/Business/FilingSearch.aspx',
  Tennessee: 'http://tnbear.tn.gov/ECommerce/FilingSearch.aspx',
  Texas: 'https://mycpa.cpa.state.tx.us/coa/Index.html',
  Utah: 'https://secure.utah.gov/bes/action/index',
  Vermont: 'https://www.vtsosonline.com/online/BusinessInquire',
  'Virgin Islands': 'https://corporationsandtrademarks.vi.gov/',
  Virginia: 'https://cis.scc.virginia.gov/EntitySearch/Index',
  Washington: 'https://www.sos.wa.gov/corps/corps_search.aspx',
  'West Virginia': 'https://apps.wv.gov/SOS/BusinessEntitySearch/',
  Wisconsin: 'http://www.wdfi.org/apps/CorpSearch/Search.aspx',
  Wyoming: 'https://wyobiz.wyo.gov/Business/FilingSearch.aspx'
}

const getValue = (type, key) => {
  if (type)
    return type[key] || '-'
  return '-'
}

function mapPreviewContent(data, isDebtor) {
  const temp = [
    {
      title: isDebtor ? "1a. Organization's name :" : "3a. Organization's name :",
      value: getValue(data, 'organizationName')
    },
    {
      title: isDebtor ? '1b. Last Name :' : '3b. Last Name :',
      value: getValue(data, 'lastName')
    },
    {
      title: 'First Name :',
      value: getValue(data, 'firstName')
    },
    {
      title: 'Mid Name :',
      value: getValue(data, 'middleName')
    },
    {
      title: 'Suffix :',
      value: getValue(data, 'suffix')
    },
    {
      title: 'Address :',
      value: getValue(data['address'], 'streetAddress')
    },
    {
      title: 'City :',
      value: getValue(data['address'], 'city')
    },
    {
      title: 'State :',
      value: getValue(data['address'], 'state')
    },
    {
      title: 'Postal Code :',
      value: getValue(data['address'], 'postalCode')
    },
    {
      title: 'Country :',
      value: getValue(data['address'], 'country')
    },
  ]

  if (data.organizationName && data.organizationId && isDebtor)
    temp.unshift({
      title: 'Organization Id :',
      value: getValue(data, 'organizationId')
    })
  return temp
}

function mapDebtorPreview(data) {
  return [
    {
      title: 'Organization :',
      value: getValue(data, 'organizationName')?.toUpperCase()
    },
    {
      title: 'Last Name :',
      value: getValue(data, 'lastName')
    },
    {
      title: 'First Name',
      value: getValue(data, 'firstName')
    },
    {
      title: 'Suffix :',
      value: getValue(data, 'suffix')
    },
    {
      title: 'Address :',
      value: getValue(data['address'], 'streetAddress')
    },
    {
      title: 'City :',
      value: getValue(data['address'], 'city')
    },
    {
      title: 'State :',
      value: getValue(data['address'], 'state')
    },
    {
      title: 'Postal Code :',
      value: getValue(data['address'], 'postalCode')
    },
    {
      title: 'Country :',
      value: getValue(data['address'], 'country')
    },
  ]
}

function mapContinuationPreview(data) {
  return [
    {
      title: 'Name :',
      value: getValue(data, 'organizationName')
    },
    {
      title: 'Filing Number :',
      value: getValue(data, 'filingNumber')
    },
    {
      title: 'Status :',
      value: getValue(data, 'status')
    },
    {
      title: 'Due Date :',
      value: data.lapseDate ? moment(getValue(data, 'lapseDate')).format("MMM Do YY") : '-'
    }
  ]
}

function integerToWords(n = 0) {
  const Ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"],
    Tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety", "Hundred"],
    Scale = ["", "Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion", "Sextillion"];

  if (n === 0) return "Zero";
  n = ("0".repeat(2 * (n += "").length % 3) + n).match(/.{3}/g);
  if (n.length > Scale.length) return "Too Large";

  let out = "";
  n.forEach((Triplet, pos) => {
    if (+Triplet) {
      out += ' ' + (+Triplet[0] ? Ones[+Triplet[0]] + ' ' + Tens[10] : "") +
        ' ' + (+Triplet.substr(1) < 20 ? Ones[+Triplet.substr(1)] :
          Tens[+Triplet[1]] + (+Triplet[2] ? "-" : "") + Ones[+Triplet[2]]) +
        ' ' + Scale[n.length - pos - 1];
    }
  })
  out.replace(/\s+/g, ' ').trim();
  return out
};

const buttonName = {
  add: 'Add & submit Amendment',
  edit: 'Edit & submit Amendment',
  delete: 'Delete & submit Amendment'
}

const actionButtonName = [
  'Add Debtor',
  'Add Collateral',
  'Add Secured Party',
]

const collateralRadios = [
  {
    title: 'Collateral is',
    clearDefualtValue: 'NO_DESIGNATION',
    checked: 'collateralDesignation',
    buttons: [
      {
        value: 'TRUST',
        label: 'Held in a trust',
      },
      {
        value: 'PERSONAL_REPRESENTATIVE',
        label: 'Being administered by a decedent’s personal representative',
      }
    ]
  },
  {
    title: 'Financial statement is related to',
    clearDefualtValue: 'NO_ALT_TYPE',
    checked: 'altFilingType',
    buttons: [
      {
        value: 'PUBLIC_FINANCE',
        label: 'Public finance transaction',
      },
      {
        value: 'MANUFACTURED_HOME',
        label: 'Manufactured home transaction',
      },
      {
        value: 'TRANSMITTING_UTILITY',
        label: 'A debtor is a transmitting utility',
      }
    ]
  },
  {
    title: 'Alternative designation',
    clearDefualtValue: 'NO_ALT_NAME',
    checked: 'altNameDesignation',
    buttons: [
      {
        value: 'LESSEE_LESSOR',
        label: 'Lessee/lessor',
      },
      {
        value: 'CONSIGNEE_CONSIGNOR',
        label: 'Consignee/consignor',
      },
      {
        value: 'SELLER_BUYER',
        label: 'Seller/Buyer',
      },
      {
        value: 'BAILEE_BAILOR',
        label: 'Bailee/bailor',
      },
      {
        value: 'LICENSEE',
        label: 'Licensee/licensor',
      },
      {
        value: 'AG_LIEN',
        label: 'Ag/Lien',
      },
      {
        value: 'NON_UCC_FILING',
        label: 'Non UCC Filing',
      }
    ]
  },
]

const changeTypeLabels = {
  Continuation: 'Continuation',
  DebtorAdd: 'Added Debtor',
  DebtorChange: 'Changed Debtor',
  DebtorDelete: 'Deleted Debtor',
  CollateralAdd: 'Added Collateral',
  CollateralDelete: 'Deleted Collateral',
  CollateralAssign: 'Assigned Collateral',
  CollateralRestate: 'Changed Collateral',
  TerminationSecuredParty: 'Termination',
  Assignment: 'Assignation',
  SecuredPartyAdd: 'Added Secured Party',
  SecuredPartyDelete: 'Deleted Secured Party',
  SecuredPartyChange: 'Changed Secured Party',
}

const amendmentTitle = {
  'Add Debtor': 'Please enter Debtor details',
  'Assign Collateral': 'Please enter Debtor details',
  'attachments': 'Please select attachments to the filing',
  'text': 'Please enter Collateral Description',
  'Add Secured Party': 'Please enter Secured Party details',
  'Edit Secured Party': 'Please enter Secured Party details',
}

const ucc3DrawerTitle = {
  termination: 'Termination',
  amendment: 'Amendment',
  continuation: 'Continuation'
}

const guideToSearch = [
  'The name search allows two wildcards to be used.  Any number of wildcards can appear in the search string, but neither may appear at beginning',
  '“*” (asterisk) indicates any number of characters may appear in the location including zero characters',
  '“?” (question mark) indicates exactly one character must be present in that location',
  'Examples: “Jo*ns?n?ill*” would include JohnsonBill, JohnsenBill, JohnsonWilliam, JonsanGilligan, and others',
  'The search system uses a starts with search methodology, meaning a * is implied at the end of the search string',
]

const dateRange = [
  { label: "All", value: 0 },
  { label: "6 Months", value: 6 },
  { label: "3 Months", value: 3 },
  { label: "1 Month", value: 1 },
]

const plaidCategories = {
  INSUFFICIENT_FUNDS: {
    name: "INSUFFICIENT_FUNDS",
    displayName: "Insufficient Funds",
    ids: "10007000",
  },
  LATE_PAYMENTS: {
    name: "LATE_PAYMENTS",
    displayName: "Late Payments",
    ids: "10003000",
  },
  CASH_ADVANCE_FEES: {
    name: "CASH_ADVANCE_FEES",
    displayName: "Cash Advance Fees",
    ids: ["10008000", "11000000"],
  },
  PAYMENT_LOANS: {
    name: "PAYMENT_LOANS",
    displayName: "Payment Loans",
    ids: "16003000",
  },
}

const statesRequiringTaxId = ['ND', 'SD']

export {
  ClientAdminMenuBarList,
  RoxAdminMenuBarList,
  statesOptions,
  statesGroupedByCountry,
  countryOptions,
  uccStatusOptions,
  strOptions,
  statusOptions,
  industryTypeOption,
  businessTypeOption,
  applicationTabs,
  uccSearchDrawerTabs,
  transactionTabs,
  getStateCode,
  getApplicationDetailTabsFcn,
  getTabByReportType,
  profileTabs,
  loginTabs,
  clientabs,
  uccOperationTabs,
  acceptable_files,
  namedOwner,
  mapPreviewContent,
  mapDebtorPreview,
  mapContinuationPreview,
  integerToWords,
  buttonName,
  actionButtonName,
  collateralRadios,
  changeTypeLabels,
  amendmentTitle,
  ucc3DrawerTitle,
  guideToSearch,
  secretaryOfStates,
  dateRange,
  plaidCategories,
  statesRequiringTaxId
}
