import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import "./TransactionsChartsGroup.scss"
import { format, subMonths, startOfMonth } from 'date-fns'
import { getDataForTransactionsCharts } from 'actions/applicants'
import RecurringPaymentChart from '../RecurringPaymentChart/index'
import MonthlyStatementsChart from "../MonthlyStatementsChart/index"
import AccountSummaryChart from "../AccountSummaryChart/index"
import Loader from 'components/shared/Loader';

const TransactionsChartsGroup = (props) => {

    const {
        applicantId,
        ownerId,
        accountId,
        selectedFromDate,
    } = props

    const [recurringPaymentValues, setRecurringPaymentValues] = useState([])
    const [monthlyStatementsValues, setMonthlyStatementsValues] = useState({ series: [], monthNames: [] })
    const [accountSummaryValues, setAccountSummaryValues] = useState({ accountBalance: {}, totalRecurringTransactions: {}, totalOutgoingPayments: {} })
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (applicantId && ownerId && accountId) {
            setLoading(true)
            let params = {
                until: format(new Date(), 'yyyy-MM-dd'),
                from: format(selectedFromDate ? selectedFromDate : startOfMonth(subMonths(new Date(), 24)), 'yyyy-MM-dd')
            }
            getDataForTransactionsCharts(applicantId, ownerId, accountId, params)()
                .then(res => {
                    setRecurringPaymentValues(res?.statusDistribution ? getRecurringPaymentFromRes(res?.statusDistribution) : [])
                    setMonthlyStatementsValues(res?.monthlyStatements ? getMonthlyStatementsFromRes(res?.monthlyStatements) : { series: [], monthNames: [] })
                    setAccountSummaryValues(prevState => ({ ...prevState, ...getAccountSummaryFromRes(res) }))
                })
                .finally(() => setLoading(false))
        }
        // eslint-disable-next-line
    }, [applicantId, ownerId, accountId, selectedFromDate])

    const getAccountSummaryFromRes = (res) => {
        let summary = { accountBalance: res.accountBalance, totalRecurringTransactions: res.totalRecurringTransactions, totalOutgoingPayments: res.totalOutgoingPayments }
        return summary
    }

    const getMonthlyStatementsFromRes = (statements) => {
        const income = {
            name: 'Income',
            data: statements.map(item => item.income)
        }
        const expenses = {
            name: 'Expenses',
            data: statements.map(item => item.expense)
        }
        const monthNames = statements.map(item => item.monthName)
        return { series: [income, expenses], monthNames }
    }

    const getRecurringPaymentFromRes = (statusDistribution) => {
        return statusDistribution.map(item => item.total)
    }

    return (
        <div className="box" >
            {loading ?
                <div className="m-5 p-5"><Loader /></div>
                :
                <Row>
                    <Col md={6}>
                        <MonthlyStatementsChart
                            monthlyStatementsValues={monthlyStatementsValues}
                        />
                    </Col>
                    <Col md={3}>
                        <AccountSummaryChart
                            accountSummaryValues={accountSummaryValues}
                        />
                    </Col>
                    <Col md={3}>
                        <RecurringPaymentChart
                            recurringPaymentValues={recurringPaymentValues}
                        />
                    </Col>
                </Row>
            }
        </div>
    );
}

export default TransactionsChartsGroup;
