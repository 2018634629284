import React from 'react'
import { Button } from "@material-ui/core"
import { joinAttributes } from 'tools/formatters'
import strings from 'strings'
import StatusIndicator from 'components/shared/StatusIndicator/index'

const PossibleAlternativeCard = (props) => {

    const { alternative, actionToPerform, actionText, state, actionLoading, isQuickSearch } = props
    const address = joinAttributes({ street: alternative?.street, city: alternative?.city, state, zip: alternative?.zip })
    const color = alternative?.status === 'Active' ? 'green' : 'gray'

    return (
        <>
            <hr></hr>
            <div className='d-flex justify-content-between mb-3 mt-3'>
                <div>
                    <div className='d-flex align-items-center'>
                        <p className='possibleAlternativeTitle mb-0 mr-3'>{alternative.businessName}</p>
                        {alternative?.status && <StatusIndicator color={color} text={alternative.status} side='left' />}
                    </div>
                    <p className='mb-0 mt-3'> {address} </p>
                </div>
                <div className='d-flex'>
                    <div className='yellowLabel mr-2'> {strings.APPLICANT.SOS.ALTERNATIVE} </div>
                    <Button
                        variant="outlined"
                        color='primary'
                        onClick={() => actionToPerform(alternative.sosId, alternative.businessName)}
                        className={`align-self-center search-for-alternative-${isQuickSearch ? 'qs' : 'app'}`}
                        disabled={actionLoading}
                    >
                        {actionText}
                    </Button>
                </div>
            </div>
        </>
    )
}

export default PossibleAlternativeCard