import React from 'react';
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@material-ui/core';
import { Dialog } from 'components/shared/Dialog';
import strings from 'strings';
import ConfirmAlertIcon from 'styles/images/icons/confirmAlert.svg'
import InfoAlertIcon from 'styles/images/icons/infoAlert.svg'
import warningAlertIcon from 'styles/images/icons/warningAlert.svg'
import ConfirmDeleteIcon from 'styles/images/icons/confirmDelete.svg'

const icons = {
    continuation: InfoAlertIcon,
    termination: ConfirmAlertIcon,
    editAmendment: warningAlertIcon,
    deleteAmendment: ConfirmDeleteIcon,
    addAmendment: InfoAlertIcon,
    assignAmendment: InfoAlertIcon,
}

// From now on try to use ConfirmationModal as it is more flexible
export function AlertDialog(props) {
    const { openAlert = {}, onClose, onSubmit, isloading } = props
    return (
        <Dialog
            open={openAlert.open}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>
                {openAlert.title}
            </DialogTitle>
            <DialogContent>
                <div className='text-center'>
                    <img src={icons[openAlert.type]} alt='icon' />
                    <div className='mt-2'>{strings.APPLICANT.CLIENTS.ALERT_HEADING}</div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                >
                    {strings.CANCEL}
                </Button>
                <Button
                    variant='contained'
                    color={'primary'}
                    onClick={onSubmit}
                    disabled={isloading}
                >
                    {isloading ? <CircularProgress size={24} color='inherit' /> : strings.CONFIRM}
                </Button>
            </DialogActions>
        </Dialog>
    )
}