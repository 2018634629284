import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete as DropDown } from '@material-ui/lab'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';
import ValidationError from 'components/forms/ValidationError'

const useStyles = makeStyles({
    option: {
        '&[data-focus="true"]': {
            backgroundColor: '#F1F5FF',
        },
    }
})

export function AutoComplete(props) {
    const {
        freeSolo = true,
        options,
        getOptionLabel = 'value',
        renderOption,
        value,
        onChange,
        error,
        disabled,
        label,
        input,
        className,
        multiline,
        rows,
        rowsMax
    } = props
    const classes = useStyles()
    const ref = useRef()
    const [optionVal, setOptionVal] = useState({
        label: value?.label || '',
        value: value?.value || value,
    })

    const handleOnInputChange = (inputVal) => {
        !input ? onChange(inputVal) : input.onChange(inputVal)
    }

    return (
        <>
            <DropDown
                id='Autocomplte-Dropdown'
                ref={ref}
                classes={{ option: classes.option }}
                className={className}
                freeSolo={freeSolo}
                options={options}
                getOptionLabel={(option) => option[getOptionLabel] || ''}
                renderOption={(option) => {
                    if (typeof renderOption === 'string')
                        return <>{option[renderOption] || ''}</>
                    if (typeof renderOption === 'function')
                        return renderOption(option)
                    return undefined
                }}
                value={optionVal}
                onChange={(event, newValue) => setOptionVal(newValue)}
                onInputChange={(e, value) => handleOnInputChange(value)}
                disabled={disabled}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label={label}
                            margin='normal'
                            variant='outlined'
                            multiline={multiline}
                            rows={rows}
                            rowsMax={rowsMax}
                            error={Boolean(error)}
                            onKeyDown={(e) => {
                                if (ref?.current?.ariaExpanded === 'false' && freeSolo && multiline)
                                    e.stopPropagation()
                            }}
                        />
                    )
                }}
            />
            <ValidationError error={error} />
        </>
    )
}

AutoComplete.propTypes = {
    freeSolo: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    /** 
     * The string should be the same key which is passed in options array of object
     */
    getOptionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    /** 
     * The string should be the same key which is passed in options array of object OR it can be function
     */
    renderOption: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
    label: PropTypes.string,
}

AutoComplete.defaultProps = {
    options: [],
}
