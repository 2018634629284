import React, { useState, useEffect, useCallback } from 'react'
import { reduxForm, reset } from 'redux-form'
import { Card } from 'react-bootstrap'
import Button from '@material-ui/core/Button'
import NotInterestedOutlinedIcon from '@material-ui/icons/NotInterestedOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { cloneDeep } from 'lodash'
import FilterBarModule from "components/shared/FilterBarModule"
import TableComponent from 'components/shared/TableComponent'
import { Tabs, Tab } from 'components/shared/Tabs'
import { Toast } from 'components/shared/Toaster'
import {
  clientSettingsTab,
  templateFilter,
  userColumns,
  templateColumns as template_columns,
  salesRepresentativeColumns,
  userFilter
} from 'constants/roxAdminSettings'
import { ClientForm, UserForm, TemplateForm, SalesRepresentativeForm } from '../DialogForms'
import { getValue } from 'tools/helper'
import Auth from 'tools/AuthService'
import strings from 'strings'
import EditIcon from 'styles/images/icons/tableedit.svg'
import DeleteIcon from 'styles/images/icons/tableDelete.svg'
import styles from './profile.module.scss'

const t = strings.CUSTOMER_PROFILE_STRING

const ClientProfile = (props) => {
  const {
    initialValues,
    handleSubmit, // redux prop passed from container to handle submit form
    customerData, // customer data passed from container
    usersList, // user list data passed from container
    clientId, // action to get clientId from params
    getClientById, //action to get client data by ID
    updateClient, //action to update client by ID
    getUsersList, // action to get users list
    createUser, // action to create user
    updateUser,
    toggleUser, // action to enable or disable a user
    dispatchProcessing, // action to show loader
    dispatchFormConfig, // action to set default form value
    totalRecords,
    totalPages,
    dispatch,
    getTemplates,
    getRolesList,
    getSalesRepresentatives,
    createSalesRepresentatives,
    updateSalesRepresentatives,
    deleteSalesRepresentatives,
    salesRepresentativeList,
    salesRepresentativeTotalPages,
    salesRepresentativeTotalRecords,
    createTemplate,
    updateTemplate,
    templateList, // template list and headers
    templateTotalRecords,
    templateTotalPages
  } = props
  const isReadOnlyuser = Auth.isReadOnlyUser()
  const [columns, setColumns] = useState(() => {
    const action = {
      formatter: (cell, row, rowIndex) => (
        <>
          <span className='rounded-icons mr-2'>
            {row.status === 'Active' ?
              <NotInterestedOutlinedIcon titleAccess='Disable' color='secondary' onClick={() => enableOrDisableUser(row)} />
              :
              <PersonOutlinedIcon titleAccess='Enable' onClick={() => enableOrDisableUser(row)} />}
          </span>
          <span className='rounded-icons'>
            <SettingsIcon
              color='primary'
              titleAccess='edit'
              onClick={() => handleUserEditForm(row)} />
          </span>
        </>
      )
    }
    if (!isReadOnlyuser)
      userColumns.map((item, index) => {
        if (item.dataField === 'action') {
          userColumns[index] = { ...item, ...action }
        }
        return item
      })
    return userColumns
  })

  const [representativeColumns, setRepresentativeColumns] = useState(() => {
    const action = {
      formatter: (cell, row, rowIndex) => (
        <>
          <img src={EditIcon} onClick={() => handleRepresentativeEditForm(row)} className={styles.tableEditIcon} title='edit' alt='edit' />
          <img src={DeleteIcon} onClick={() => handleRepresentativeDeleteForm(row)} className={styles.tableDeleteIcon} title='Delete' alt='delete' />
        </>
      )
    }
    if (!isReadOnlyuser)
      cloneDeep(salesRepresentativeColumns).map((item, index) => {
        if (item.dataField === 'action') {
          salesRepresentativeColumns[index] = { ...item, ...action }
        }
        return item
      })
    return salesRepresentativeColumns
  })
  const [templateColumns, setTemplateColumns] = useState(() => {
    const filingAction = {
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            {/* TODO Need API to perform tis action */}
            {/* <img src={EditIcon} style={{ height: '40px' }} title='Edit' alt='edit' />
            <img src={DeleteIcon} title='Delete' alt='delete' /> */}
          </>
        )
      }
    }
    if (!isReadOnlyuser)
      template_columns.map((item, index) => {
        if (item.dataField === 'action') {
          template_columns[index] = { ...item, ...filingAction }
        }
        return item
      })
    return template_columns
  })
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({
    clientForm: false,
    userForm: false,
    templateForm: false,
    salesRepresentativeForm: false
  })
  const [formMode, setFormMode] = useState(strings.CREATE)
  const [formLoader, setFormLoader] = useState(false)
  const [tabValue, setTabValue] = useState(0)
  const [clientID, setClientID] = useState()
  const hasRoxAdminRole = Auth.hasRole(strings.ROX_ADMIN) // checks whether user has ROX_ADMIN Role

  /**
   * function to call client API by ID and users list API
   * For platform admin it will call both getClientById and getUsersList API but for customer admin it will call only usersList API
   */
  const loadData = useCallback((filters = {}, pagination = {}) => {
    dispatchProcessing(true)
    if (hasRoxAdminRole) {
      getClientById(clientId).then((res) => {
        if (res?.clientId >= 0) {
          setClientID(res.clientId)
          getUsersList(filters, pagination, res.clientId, res.uuid).finally(() => {
            dispatchProcessing(false)
            setLoading(false)
          })
        }
      })
    } else {
      getUsersList(filters, pagination).finally(() => {
        dispatchProcessing(false)
        setLoading(false)
      })
    }
  }, [getClientById, dispatchProcessing, clientId, getUsersList, hasRoxAdminRole])

  const getRepresentativeList = useCallback((pagination) => {
    dispatchProcessing(true)
    getSalesRepresentatives(pagination).finally(() => dispatchProcessing(false))
  }, [dispatchProcessing, getSalesRepresentatives])

  const getTemplateList = useCallback((pagination) => {
    dispatchProcessing(true)
    getTemplates(pagination, clientID).finally(() => dispatchProcessing(false))
    // eslint-disable-next-line
  }, [getTemplates, dispatchProcessing])


  useEffect(() => {
    switch (tabValue) {
      case 0:
        loadData()
        break;
      case 1:
        getRepresentativeList()
        break;
      case 2:
        getTemplateList()
        break;
      default:
        break;
    }
  }, [loadData, getRepresentativeList, getTemplateList, tabValue])

  useEffect(() => {
    if (!isReadOnlyuser)
      getRolesList()
  }, [getRolesList, isReadOnlyuser])

  const handleUserEditForm = (row) => {
    dispatchFormConfig({
      type: strings.SETTINGS_FORM.USER_EDIT_FORM,
      id: row?.userId
    })
    setModal({
      ...modal,
      userForm: true
    })
    setFormMode(strings.EDIT)
  }

  const handlePagination = (pagination) => {
    loadData({}, pagination)
  }

  const handleUser = () => {
    dispatchFormConfig({
      type: strings.SETTINGS_FORM.USER_CREATE_FORM
    })
    setModal({ ...modal, userForm: true })
    setFormMode(strings.CREATE)
  }

  const handleSalesRepresentative = () => {
    dispatchFormConfig({ type: strings.SETTINGS_FORM.ADD_SALES_REPRESENTATIVE })
    setModal({ ...modal, salesRepresentativeForm: true })
    setFormMode(strings.CREATE)
  }

  const handleTemplate = () => {
    dispatchFormConfig({
      type: strings.SETTINGS_FORM.USER_CREATE_TEMPLATE
    })
    setModal({ ...modal, templateForm: true })
    setFormMode(strings.CREATE)
  }

  const handleClientUpdateForm = (formData) => {
    let data = {}
    Object.entries(formData.client).forEach(entries => {
      data[entries[0]] = entries[1]
    })
    setFormLoader(true)
    updateClient(data).finally(() => {
      setFormLoader(false)
      setModal({ ...modal, clientForm: false })
      loadData()
    })
  }

  const handleUserForm = (formData) => {
    const formAction = formMode === strings.CREATE ? createUser : updateUser // TODO need to implement update API for now using createAPI
    setFormLoader(true)
    formAction({ ...formData, clientId }, customerData?.clientId).finally(() => {
      loadData()
      dispatch(reset('profileForm'))
      setModal({ ...modal, userForm: false })
      setLoading(false)
      setFormLoader(false)
    })
  }

  const handleSalesRepresentativeForm = (formData = {}) => {
    setFormLoader(true)
    delete formData.is_superuser
    delete formData.userRol
    delete formData.uuid
    delete formData.createdBy

    const formAction = formMode === strings.CREATE ? createSalesRepresentatives :
      formMode === strings.EDIT ? updateSalesRepresentatives :
        deleteSalesRepresentatives
    formAction(formData, initialValues?.uuid).then(() => {
      getRepresentativeList();
      Toast({ type: 'success', message: formMode === strings.DELETE ? strings.APPLICANT.ATTACHMENTS.DELETE_MSG : strings.SUCCESS })
    }).finally(() => {
      dispatch(reset('profileForm'))
      setModal({ ...modal, salesRepresentativeForm: false })
      setFormLoader(false)
    })
  }

  const handleRepresentativeEditForm = (row) => {
    setModal({ ...modal, salesRepresentativeForm: true })
    setFormMode(strings.EDIT)
    dispatchFormConfig({
      id: row?.uuid,
      type: strings.SETTINGS_FORM.EDIT_SALES_REPRESENTATIVE,
    })
  }

  const handleRepresentativeDeleteForm = (row) => {
    // TODO: Add confirm dialog before delete
    // setModal({ ...modal, salesRepresentativeForm: true })
    // setFormMode(strings.DELETE)
    // dispatchFormConfig({
    //   id: row?.uuid,
    //   type: strings.SETTINGS_FORM.EDIT_SALES_REPRESENTATIVE,
    // })

    // Can be deleted once confirmation dialog is implemeted
    deleteSalesRepresentatives(row.uuid).then(() => {
      getRepresentativeList();
      Toast({ type: 'success', message: strings.APPLICANT.ATTACHMENTS.DELETE_MSG })
    })
  }

  const handlTemplateForm = (formData) => {
    const formAction = formMode === strings.CREATE ? createTemplate : updateTemplate // TODO need to implement update API
    setFormLoader(true)
    formAction(formData).then(() => {
      getTemplateList();
      Toast({ type: 'success', message: strings.SETTINGS_FORM.TEMPLATE.SUCCESS_MSG })
    }).finally(() => {
      dispatch(reset('profileForm'))
      setModal({ ...modal, templateForm: false })
      setFormLoader(false)
    })
  }

  const handleChange = (e, val) => {
    setTabValue(val)
  }

  const enableOrDisableUser = ({ userId, status }) => {
    const active = status === 'Active' ? true : false
    setLoading(true)
    toggleUser(userId, active)
      .then(() => {
        loadData()
        setLoading(false)
      })
      .catch(() => {
        setLoading(false);
      })
  }


  /**
   * customer profile data build with API response
   */
  const customerProfileData = {
    [t.CUSTOMER_NAME]: getValue(customerData?.name),
    [t.CUSTOMER_BUSINESS_TYPE]: getValue(customerData?.businessType),
    [t.CUSTOMER_PHONE]: getValue(customerData?.phone),
    [t.NO_OF_USERS]: `${usersList?.length} ${strings.USER}`
  }

  return (
    <div>
      <h4>{strings.SETTINGS}</h4>
      <Tabs
        data={clientSettingsTab}
        value={tabValue}
        onChange={handleChange}
      />
      {
        hasRoxAdminRole && (
          <Card>
            <div className={styles.profileContainer}>
              <div className={styles.profileHeader}>
                <h5>{strings.CUSTOMER_PROFILE}</h5>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    dispatchFormConfig({ type: strings.CUSTOMER })
                    setModal({ ...modal, clientForm: true })
                  }}
                >
                  {t.EDIT_PROFILE}
                </Button>
              </div>
              <div className={styles.profileDetailContainer}>
                {
                  Object.keys(customerProfileData).map((key, index) => (
                    <div key={index.toString()}>
                      <p>{key}</p>
                      <p>{customerProfileData[key]}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </Card>
        )
      }
      <Tab value={tabValue} index={0}>
        <Card>
          <FilterBarModule
            title={strings.USERS}
            filters={userFilter}
            textLabel={'Filters'}
            columns={columns}
            receiveCustomColumn={(columns) => setColumns(columns)}
            filterParams={(params) => console.log('filter params', params)}
            btnText={!isReadOnlyuser && strings.ADD_USER}
            onBtnChange={handleUser}
          />
          <TableComponent
            data={usersList}
            keyField='userId'
            columns={columns}
            sort={true}
            loading={loading}
            totalRecords={totalRecords}
            totalPages={totalPages}
            onChangePagination={(pagination) => handlePagination(pagination)}
          />
        </Card>
      </Tab>
      <Tab value={tabValue} index={1}>
        <Card>
          <FilterBarModule
            title={strings.SETTINGS_FORM.SALES_REPRESENTATIVE}
            filters={templateFilter}
            textLabel={'Filters'}
            columns={representativeColumns}
            receiveCustomColumn={(columns) => setRepresentativeColumns(columns)}
            filterParams={(params) => console.log('filter params', params)}
            btnText={!isReadOnlyuser && strings.SETTINGS_FORM.ADD_SALES_REPRESENTATIVE}
            onBtnChange={handleSalesRepresentative}
          />
          <TableComponent
            data={salesRepresentativeList}
            columns={representativeColumns}
            loading={loading}
            totalRecords={salesRepresentativeTotalRecords}
            totalPages={salesRepresentativeTotalPages}
            onChangePagination={(pagination) => getRepresentativeList(pagination)}
          />
        </Card>
      </Tab>
      <Tab value={tabValue} index={2}>
        <Card>
          <FilterBarModule
            title={strings.SETTINGS_FORM.TEMPLATE.COLLATERAL_DESCRIPTION_TEMPLATE}
            filters={templateFilter}
            textLabel={'Filters'}
            columns={templateColumns}
            receiveCustomColumn={(columns) => setTemplateColumns(columns)}
            filterParams={(params) => console.log('filter params', params)}
            btnText={!isReadOnlyuser && strings.SETTINGS_FORM.TEMPLATE.ADD_TEMPLATE}
            onBtnChange={handleTemplate}
          />
          <TableComponent
            data={templateList}
            columns={templateColumns}
            loading={loading}
            totalRecords={templateTotalRecords}
            totalPages={templateTotalPages}
            onChangePagination={(pagination) => getTemplateList(pagination)}
          />
        </Card>
      </Tab>

      <ClientForm
        open={modal.clientForm}
        onClose={() => {
          setModal({ ...modal, clientForm: false })
        }}
        onSubmit={handleSubmit(formData => handleClientUpdateForm(formData))}
        mode={strings.EDIT}
        loading={formLoader}
        {...props}
      />
      <UserForm
        open={modal.userForm}
        onClose={() => {
          setModal({ ...modal, userForm: false })
          dispatch(reset('profileForm'));
        }}
        onSubmit={handleSubmit(formData => handleUserForm(formData))}
        mode={formMode}
        loading={formLoader}
        {...props}
      />
      <SalesRepresentativeForm
        open={modal.salesRepresentativeForm}
        onClose={() => {
          setModal({ ...modal, salesRepresentativeForm: false })
          dispatch(reset('profileForm'));
        }}
        onSubmit={handleSubmit(formData => handleSalesRepresentativeForm(formData))}
        mode={formMode}
        loading={formLoader}
        {...props}
      />
      <TemplateForm
        open={modal.templateForm}
        onClose={() => {
          setModal({ ...modal, templateForm: false })
          dispatch(reset('profileForm'));
        }}
        onSubmit={handleSubmit(formData => handlTemplateForm(formData))}
        mode={formMode}
        loading={formLoader}
        {...props}
      />
    </div>
  )
}

export default reduxForm({
  form: 'profileForm',
  enableReinitialize: true
})(ClientProfile)