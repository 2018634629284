import React, {useContext, useState} from 'react';
import {joinAttributes} from "../../../../../../../tools/formatters";
import {UccSearchContext} from "./UccSearchTab/UccSearchContent";
import strings from "../../../../../../../strings";
import Button from "../../../../../../shared/Button";
import {attachFiling, updateSummary} from "../../../../../../../actions/applicants";
import {useDispatch} from "react-redux";
import {uccSearchResultColumns} from "../../../../../../../constants/application";
import { Toast } from 'components/shared/Toaster'
import UccCard from "./UccCard/UccCard";



const UccSearchCard = ({result, updateUccResults}) => {

    const {applicationId, indexDate, searchUuid} = useContext(UccSearchContext)
    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false)

    const entityName = result?.EntityName
    const address = joinAttributes({Address: result?.Address, City: result?.City, State: result?.State})

    const builtAttachPayload = () => {
        return {
            searchUuid: searchUuid,
            filingNumbers: result?.Group.map(filing => filing.FilingNumber),
        }
    }

    const handleAttach = (event) => {
        event.stopPropagation()
        setLoading(true)

        const payload = builtAttachPayload()

        attachFiling({applicationId, payload})()
            .then((res) => {
                Toast({type: 'success', message: strings.APPLICANT.UCC.UCC_SAVE_MSG})
                updateSummary()(dispatch)
                updateUccResults(res)
            })
            .catch(() => setLoading(false))
    }

    const button = <Button
        onClick={handleAttach}
        className='attachItemButton'
        loading={loading}
        variant='outlined'
        color='primary'
    >
        {strings.ATTACH}
    </Button>;


    return (
        <UccCard
            arrayOfFilings={result?.Group}
            entityName={entityName}
            address={address}
            indexDate={indexDate}
            button={button}
            tableData={result?.Group}
            column={uccSearchResultColumns}
        />
    )
}

export default UccSearchCard