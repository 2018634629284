import React, {useEffect, useState} from 'react'
import Drawer from "../../../../../../shared/Drawer";
import strings from "../../../../../../../strings";
import styles from "../../../UCC/reports/detailReport/detailReport.module.scss";
import FilingDetailsGroupDataAccordion from "./FilingDetailsGroupDataAccordion";
import Button from "../../../../../../shared/Button";
import {Icon} from 'styles'
import {AmendmentDetailsColumns, FilingDetailsColumns} from 'constants/application'
import {downloadFileByPayload, getAttachmentPDF, dispatchRetrieveDocuments, getDocumentsFromDatabase, attachFilingDetailsReport } from "../../../../../../../actions/applicants";
import PdfModal from "../../../../Dialog/PdfModal";
import UccDocumentRequest from "./Documents/UccDocumentRequest";
import {cloneDeep} from "lodash";
import FilingDetailsContainerTitle from "./Container/FilingDetailsContainerTitle";
import FilingDetailsContainerAccordion from "./Container/FilingDetailsContainerAccordion";
import PrepareTooltip from "../../../../../../shared/Tooltip";
import UccTerminationRequest from "./Termination/UccTerminationRequest";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { DrawerAction } from 'components/shared/Drawer/index';
import { Toast } from 'components/shared/Toaster/index';

const UccFilingDetailView = (props) => {
    const {
        open,
        onClose,
        applicationId,
        data,
    } = props
    const [pdf, setPdf] = useState({ applicationId: '', uuid: '', fileName: '' })

    const [selectedFilings, setSelectedFilings] = useState([]);
    const [mappedSelectedFilings, setMappedSelectedFilings] = useState([]);
    const [openPDF, setOpenPDF] = useState(false);
    const [openDocumentRequest, setOpenDocumentRequest] = useState(false);
    const [openTerminationRequest, setOpenTerminationRequest] = useState(false);
    const [filings, setFilings] = useState([]);
    const [documentData, setDocumentData] = useState([]);
    const [filingToTerminate, setFilingToTerminate] = useState(null);
    const [attachFilingDetailsReportLoading, setAttachFilingDetailsReportLoading] = useState(false);

    //Used to Update the document status icons after requesting documents
    useEffect(() => {
        initializeDrawer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (data) {
            const allDocStatus = documentData?.documents || []

            const updatedData = cloneDeep(data?.response?.Results)?.map(d => {
                d.documentStatus = allDocStatus.find(doc => doc.requestedFilingNumber === d.FilingNumber)
                d.originalFilingNumber = d.FilingNumber

                if (d.Amendments)
                    d.Amendments = d.Amendments.map(am => ({ ...am,
                        documentStatus: allDocStatus.find(status => status.requestedFilingNumber === am.FilingNumber),
                        originalFilingNumber: d.FilingNumber}))
                return d
            })
            setFilings(updatedData)
        }
    }, [documentData, data])

    const handleDownloadFilingDetail = () => {
        setAttachFilingDetailsReportLoading(true)
        attachFilingDetailsReport({applicationId, interactionId: data.uuid})()
            .then((res) => {
                const payload = { applicantionId: applicationId, fileId: res.uuid, fileName: res.fileName }
                downloadFileByPayload(payload)()
                Toast({ type: 'success', message: strings.APPLICANT.UCC.FILING_DETAIL_GENERATED_AND_ATTACHED_SUCCESSFULLY + '. ' + strings.DOWNLOAD_WILL_START_SOON })
            })
            .catch((err) => Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG }))
            .finally(() => setAttachFilingDetailsReportLoading(false))
    }
    
    const updateDocumentState = () => {
        getDocumentsFromDatabase(data?.uuid)().then((res) => {
            dispatchRetrieveDocuments(res);
            setDocumentData(res)
        })
    }

    const terminateAction = {
        formatter: (cell, row) => {
            return (
                <div>
                    {(row?.Status === 'Inactive') ?
                        <span>
                            -
                        </span>
                        :
                        <Button
                            color='primary'
                            variant="outlined"
                            onClick={()=> {
                                setFilingToTerminate(row)
                                setOpenTerminationRequest(true);
                            }}
                            style={{ backgroundColor: '#F55151', color: 'white', textTransform: 'none' }}>
                            {strings.APPLICANT.TERMINATE_BUTTON}
                        </Button>
                    }
                </div>
            )
        }
    }

    //Methods to load action buttons into tables
    function ReadyToRequestIcon(row) {
        return (
            <PrepareTooltip title={strings.APPLICANT.UCC.READY_TO_REQUEST} theme='white'>
                <Icon
                    folder='icons'
                    fileName='document.svg'
                    onClick={() => handleReportSelect(row, true)}
                />
            </PrepareTooltip>
        );
    }
    function OfflineDocAlreadyRequestedIndication() {
        return (
            <PrepareTooltip title={strings.APPLICANT.UCC.ALREADY_REQUESTED_OFFLINE_DOCUMENT} theme='white'>
                <CheckCircleOutlineIcon color='primary' />
            </PrepareTooltip>
        );
    }
    function OnlineDocAlreadyRequestedIndication() {
        return (
            <PrepareTooltip title={strings.APPLICANT.UCC.ALREADY_REQUESTED_ONLINE_DOCUMENT} theme='white'>
                <AccessTimeIcon color='primary' />
            </PrepareTooltip>
        );
    };
    function ErrorInDocIndication() {
        return (
            <PrepareTooltip title={strings.APPLICANT.UCC.ERROR_IN_DOC} theme='white'>
                <ErrorOutlineIcon color='primary' />
            </PrepareTooltip>
        );
    };

    function openDocumentIcon(document) {
        return (
            <PrepareTooltip title={strings.APPLICANT.UCC.OPEN_DOCUMENT} theme='white'>
                <Icon
                    folder='icons'
                    fileName='view.svg'
                    onClick={() => {
                        setPdf({ applicationId: applicationId, uuid: document.fileId})
                        setOpenPDF(true)
                    }}
                />
            </PrepareTooltip>
        );
    }
    const documentAction = {
        formatter: (cell, row) => {
            if (!row.documentStatus){
                return ReadyToRequestIcon(row)
            }
            // TODO add case when it is zero pages, it assumes in all cases this is an offline doc and it is not.
            // TODO maybe we need also to modify the backend to address for this case.
            if(row?.documentStatus?.status === 'Document Requested'){
                if(row?.NumberOfPages === 0) // is offline doc
                {
                    return OfflineDocAlreadyRequestedIndication()
                } else {
                    return OnlineDocAlreadyRequestedIndication()
                }
            } else if (row?.documentStatus?.status === 'Ready To Download') {
                return openDocumentIcon(row.documentStatus)
            } else if (row?.documentStatus?.status === 'Error') {
                return ErrorInDocIndication()
            }
        }
    }
    FilingDetailsColumns.map((item, index) => {
        if (item.dataField === 'docAction')
            FilingDetailsColumns[index] = {...item, ...documentAction}
        if (item.dataField === 'action') {
            FilingDetailsColumns[index] = {...item, ...terminateAction}
        }
        return item
    })
    AmendmentDetailsColumns.map((item, index) => {
        if (item.dataField === 'docAction')
            AmendmentDetailsColumns[index] = {...item, ...documentAction}
        return item
    })

    //Handle Checkbox Selection
    const handleReportSelect = (row, isSelect) => {
        const data = {
            filingNumber: row.FilingNumber,
            initialFiling: row.originalFilingNumber,
            numberOfPages: row.NumberOfPages
        }
        if (isSelect) {
            // Prevents adding a repeated filing number to the state
            setSelectedFilings(prevList => {
                    if (!prevList.includes(row.FilingNumber)) {
                        return [...prevList, row.FilingNumber];
                    }
                    return prevList;
                });
            // Prevents adding a repeated filing number
            setMappedSelectedFilings(prevList => {
                    if (!prevList.some(item => item.filingNumber === data.filingNumber)) {
                        return [...prevList, data];
                    }
                    return prevList;
                });
            } else {
                // filtering is idempotent so we don't check for presence
                setSelectedFilings(prevList => prevList.filter(item => item !== row.FilingNumber));
                setMappedSelectedFilings(prevList => prevList.filter(item => item.filingNumber !== row.FilingNumber));
            }
    }

    const clearReportSelect = () => {
        setSelectedFilings(() => []);
        setMappedSelectedFilings(() => []);
    }

    const initializeDrawer = () => {
        updateDocumentState()
    }
    
    return (
        <Drawer open={open}
                onClose={onClose}
                title={strings.APPLICANT.DETAIL_FILING_REPORT}
                leftSideCloseIcon
        >
            <div className={styles.detailContainer}>
                <FilingDetailsGroupDataAccordion data={data}/>

                <FilingDetailsContainerTitle
                    selectedFilings={selectedFilings}
                    setDocumentRequest={setOpenDocumentRequest}
                />
                {filings.map((item) => (
                    <FilingDetailsContainerAccordion
                        key={item.FilingNumber}
                        item={item}
                        selectedFilings={selectedFilings}
                        handleReportSelect={handleReportSelect}
                        nonSelectableRows={documentData?.documents?.map(item => item.requestedFilingNumber)}
                    />
                ))}
            </div>
            <DrawerAction className={styles.borderTop}>
                <Button
                    color='primary'
                    variant="outlined"
                    onClick={() => handleDownloadFilingDetail()}
                    loading={attachFilingDetailsReportLoading}
                >
                    {strings.DOWNLOAD_REPORT}
                </Button>
            </DrawerAction>
            <PdfModal
                open={openPDF}
                onClose={() => setOpenPDF(false)}
                getAttachmentPDF={getAttachmentPDF}
                pdf={pdf}
                onDownloadFile={downloadFileByPayload}
            />
            {openDocumentRequest && <UccDocumentRequest
                open={openDocumentRequest}
                onClose={() => {
                    setOpenDocumentRequest(false)
                    updateDocumentState()
                    clearReportSelect()
                }
                }
                interactionData={data}
                selectedFilings={mappedSelectedFilings}
                updateUccDocuments={setDocumentData}
            />}
            {filingToTerminate &&
                <UccTerminationRequest
                    open={openTerminationRequest}
                    onClose={() => setOpenTerminationRequest(false)}
                    filingToTerminate={filingToTerminate}
                    applicationId={applicationId}
                    interactionId={data.uuid}
                />
            }
        </Drawer>
    )
}

export default UccFilingDetailView