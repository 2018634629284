import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {
    Backdrop,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles';
import {UccDocumentRequestColumn} from 'constants/application'
import TableComponent from 'components/shared/TableComponent'
import {Dialog} from 'components/shared/Dialog'
import {Toast} from 'components/shared/Toaster'
import '../FilingDetails.scss'
import CloseIcon from '@material-ui/icons/Close';
import {Alert} from '@material-ui/lab'
import strings from 'strings'
import {
    dispatchRetrieveDocuments,
    retrieveDocument,
    getDocumentsFromDatabase, downLoadAttachmentByFileId
} from "../../../../../../../../actions/applicants";
import {offlineDocuments, onlineDocuments} from "./utilityFunctions";

const uccString = strings.APPLICANT.UCC

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#ffff',
        position: "absolute",
        backgroundColor: 'rgb(255 255 255 / 80%)',
        display: 'flex',
        flexDirection: 'column'
    },
    parent: {
        position: "relative",
        zIndex: 0
    }
}));

const UccDocumentRequest = (props) => {
    const {
        open,
        onClose,
        interactionData,
        selectedFilings,
        updateUccDocuments
    } = props

    const interactionId = interactionData?.uuid
    const stateCode= interactionData?.uccGroupInformation?.state
    const [loading, setLoading] = useState(false)
    const [disableOffline, setDisableOffline] = useState(false)
    const searchId = useSelector(state => state?.applicant?.ucc?.uccSearchId);
    const timerRef = useRef(null); // variable used to clean up the setTimeOut on component unmount

    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);

    const downloadOnlineDocuments = (interactionId, maxCount) => {
        return getDocumentsFromDatabase(interactionId)().then((res) => {
            dispatchRetrieveDocuments(res);
            updateUccDocuments(res);
            const { fileId, fileName, status } = res?.documents[0] || {};
            if (status !== 'Ready To Download' && maxCount > 1) {
                // Call the function recursively with the same interactionId
                return timerRef.current = setTimeout(() => {
                    downloadOnlineDocuments(interactionId, maxCount - 1)
                }, 3000);
            } else {
                downLoadAttachmentByFileId(fileId, fileName)().finally(() => { setLoading(false) })
            }
            setLoading(false)
            Toast({
                type: "success", message: uccString.UCC_DOWNLOAD_MSG
            })
            return res;
        });
    }

    const getFilingNumbers = (filingList) => {
        return filingList?.map(item => item.filingNumber).filter(item => item !== undefined)
    }

    /**
     * 
     * @param {number} type 
     * type 0 is online
     * type 1 is offline
     */
    const handleDownload = (type) => {
        setLoading(true)
        const onlineFilings = getFilingNumbers(onlineDocuments(selectedFilings))
        const offlineFilings = getFilingNumbers(offlineDocuments(selectedFilings))
        const payLoad = {
            [interactionData?.entityType === 'OWNER' ? 'ownerId' : 'businessId']: interactionData?.entityUUID,
            filingNumbers: type === 0 ? onlineFilings : offlineFilings,
            transactionID : interactionData?.response?.Header?.TransactionID,
            interactionId,
            searchId
        }
        if (type === 1) {
            payLoad['offlineRequest'] = true
            retrieveDocument(payLoad, stateCode)().finally(() => {
                setDisableOffline(true)
                setLoading(false)
                Toast({
                    type: "success", message: uccString.OFFLINE_DOCUMENTS_REQUEST_SUCCESS
                })
                onClose()
            })
        } else {
            retrieveDocument(payLoad, stateCode)().then(() => {
                downloadOnlineDocuments(interactionId)
                onClose()
            }).catch(() => setLoading(false))
        }
    }

    const rowStyle = (row, rowIndex) => {
        const style = {};
        if (row.initialFilingNumber) {
            style.backgroundColor = '#D0D0D0';
        }
        return style;
    };


    return (

        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='lg'
            onClose={onClose}
            scroll="paper"
        >
            <div>
                <div className={'drawerTitle'}>
                    <div className={'titleContainer'}>
                        <DialogTitle>{strings.DOCUMENT_REQUEST_CONFIRMATION}</DialogTitle>
                    </div>
                    <div className={'actionContainer'}>
                        <CloseIcon onClick={onClose} />
                    </div>
                </div>
                <DialogContent className={useStyles().parent}>
                    <div className={`${'listContainer'}`}>
                        <Backdrop className={useStyles().backdrop} open={loading} >
                            <CircularProgress color="primary" />
                            <Typography variant="body1" className={'loadingText'}>{strings.DOCUMENT_REQUEST_LOADING}</Typography>
                        </Backdrop>
                        <div className={'boxMargin'}>
                            <Alert severity="info">{strings.INFO_FILING_REQUEST}</Alert>
                        </div>
                        <div className={'docListContainer'}>
                            <div className={`${'list'} box`}>
                                <div className={'titleBtnContainer'}>
                                    <div>
                                        <Typography variant="h6">{strings.ONLINE} {strings.DOCUMENT_LIST}</Typography>
                                        <Typography variant="body2">{strings.APPLICANT.UCC.TOTAL_PAGES}: {onlineDocuments(selectedFilings).reduce((total, filing) => total + (filing.numberOfPages || 0), 0)}</Typography>
                                    </div>
                                    <Button color='primary' variant='contained' onClick={() => handleDownload(0)} disabled={onlineDocuments(selectedFilings)?.length === 0}>
                                        {strings.APPLICANT.UCC.REQUEST_ONLINE_DOCUMENTS}
                                    </Button>
                                </div>
                                <TableComponent
                                    keyField={'index'}
                                    columns={UccDocumentRequestColumn}
                                    data={onlineDocuments(selectedFilings)}
                                    showPagination={false}
                                    rowStyle={rowStyle}
                                />
                            </div>
                            <div className={`${'list'} box`}>
                                {
                                    disableOffline && (<Alert severity="info">{uccString.ALREADY_REQUESTED_OFFLINE_DOCUMENTS}</Alert>)
                                }
                                <div className={'titleBtnContainer'}>
                                    <div>
                                        <Typography variant="h6">{strings.OFFLINE} {strings.DOCUMENT_LIST}</Typography>
                                        <Typography variant="body2">Total Pages: 0</Typography>
                                    </div>
                                    <Button color='primary' variant='contained' onClick={() => handleDownload(1)} disabled={offlineDocuments(selectedFilings).length === 0 || disableOffline}>
                                        {strings.APPLICANT.UCC.REQUEST_OFFLINE_DOCUMENTS}
                                    </Button>
                                </div>
                                <TableComponent
                                    keyField={'index'}
                                    columns={UccDocumentRequestColumn}
                                    data={offlineDocuments(selectedFilings)}
                                    showPagination={false}
                                    rowStyle={rowStyle}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button color='primary' variant='outlined' onClick={onClose} className='mr-2'>
                            {strings.CLOSE}
                        </Button>
                    </div>
                </DialogActions>
            </div>
        </Dialog >
    )
}

export default UccDocumentRequest