import React, { useCallback, useEffect, useState } from "react";
import {
    getUccFilingsForApplication, postFiling, getUccFilingList, getJurisdictionList,
    getTemplates, filingPreview, uploadFile, getFiles, downLoadAttachment, getAttachmentPDF,
    requestUccFilingImage, getUccFilingDetailsByEntity, requestUCCForceUpdate, getUccFilingDetailsByFilingId,
    getUccFilingDetailsByFilingNumber
} from "../../../../../../../actions/applicants";
import VisibilityIcon from '@material-ui/icons/Visibility'
import { uccFilingsTableColumns } from "../../../../../../../constants/application";
import strings from "../../../../../../../strings";
import TableComponent from "../../../../../../shared/TableComponent";
import AuthService from "../../../../../../../tools/AuthService";
import Button from "../../../../../../shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { UccPosting } from "../../../UCC/uccPosting";
import { reset, change } from "redux-form";
import { useHistory } from 'react-router-dom'
import { Toast } from 'components/shared/Toaster'
import queryString from 'query-string';
import { DiffView } from "../../../../Drawer/DiffView";
import { InfoView } from "../../../UCC/views/infoView";

const status = strings.STATUS_NAMES

const UccFilings = ({ applicationId, handleSubmitUccPosting }) => {

    const isReadOnlyUser = AuthService.isReadOnlyUser()
    const dispatch = useDispatch()
    const { location, replace } = useHistory()
    const tab = queryString.parse(location.search).tab

    const [uccFilingsList, setUccFilingsList] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [nonSelectableRows, setNonSelectableRows] = useState([])
    const [selectedFilingIndex, setSelectedFilingIndex] = useState([])
    const [uccSearchId, setUccSearchId] = useState([])
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const { attachmentList, headers } = useSelector(state => state.applicant.attachments)
    const ucc3Data = useSelector(state => state.applicant.clients.ucc3Preview) || {}

    const [openPostingDrawer, setOpenPostingDrawer] = useState(false)
    const [filingInfoDrawer, setFilingInfo] = useState({ open: false, ucc3: false })
    const [loadingFilingTableData, setLoadingFilingTableData] = useState(false)
    const [loading, setLoading] = useState({ filingList: false, filing: false, filingInfoDrawer: false })

    useEffect(() => {
        const { drawerMode, entityUUID, isDba } = queryString.parse(location.search);
        if (drawerMode === 'filing') {
            setLoading(prev => ({ ...prev, filingInfoDrawer: true }))
            const row = uccFilingsList.find(row => row.entityUuid === entityUUID)
            if ([status.Rejected, status.Preparation].includes(row?.status)) { changeSelectedRowInfo(row) }
            dispatch(getUccFilingDetailsByEntity(applicationId, entityUUID, null, { isDba }))
                .then(() => setFilingInfo({ open: true, ucc3: false }))
                .catch(() => setFilingInfo({ open: false }))
                .finally(() => setLoading(prev => ({ ...prev, filingInfoDrawer: false })))
        }
        // eslint-disable-next-line
    }, [location.search, applicationId, getUccFilingDetailsByFilingNumber, getUccFilingDetailsByFilingId, getUccFilingDetailsByEntity])

    const changeSelectedRowInfo = (row) => {
        setSelectedRows([row])
        setSelectedFilingIndex([uccFilingsList.findIndex(item => item.entityUuid === row.entityUuid)])
        setUccSearchId([{ id: row.entityUuid, entityType: row.entityType, addressId: row.addressId }])
    }

    useEffect(() => {
        setLoadingFilingTableData(true)
        updateUccFilingsTableData()
        // eslint-disable-next-line
    }, [])

    /**
     * Update Non-Selectable Rows based on filing status and if there is a selected row, selected state and country
     */
    useEffect(() => {
        const nonSelectableByStatus = uccFilingsList.filter(
            row => ![status.Not_Started, status.Rejected, status.Preparation].includes(row.status));

        let nonSelectable = []

        if (selectedRows.length > 0) {
            const { state: selectedState, countryCode: selectedCountry } = selectedRows[0] || {}
            setState(selectedState)
            setCountry(selectedCountry)
            nonSelectable = [...nonSelectable, ...uccFilingsList.filter(row => row.state !== selectedState || row.countryCode !== selectedCountry)];
        }
        setNonSelectableRows([...nonSelectable, ...nonSelectableByStatus])
    }, [selectedRows, uccFilingsList]);

    const handleOpenPosting = () => {
        const getFirstRejectedFiling = selectedRows.find(row => (row.status === status.Rejected) || (row.status === status.Preparation))
        dispatch(getUccFilingDetailsByEntity(null, null, true))
        if (getFirstRejectedFiling) {
            const index = []
            selectedRows.forEach((row, i) => { if ((row.status === status.Rejected) || (row.status === status.Preparation)) index.push(i) })
            setSelectedFilingIndex(index)
            setLoading(prev => ({ ...prev, filingList: true }))
            dispatch(getUccFilingDetailsByEntity(applicationId, getFirstRejectedFiling.entityUuid, false, { isDba: getFirstRejectedFiling?.entityType === 'BUSINESS_DBA' }))
                .then(() => setOpenPostingDrawer(true))
                .finally(() => setLoading(prev => ({ ...prev, filingList: false })))
        }
        else setOpenPostingDrawer(true)
    }

    const performPostFiling = async (payLoad) => {
        setLoading(prev => ({ ...prev, filing: true, filingList: true }))
        postFiling(applicationId, payLoad)().then((res) => {
            Toast({ type: "success", message: "Filing success" })
            updateUccFilingsTableData()
            setSelectedRows([])
            setUccSearchId([])
            dispatch(getUccFilingList(applicationId)).finally(() => {
                setLoading(prev => ({ ...prev, filingList: false }))
            })
        }).finally(() => {
            setOpenPostingDrawer(false)
            setLoading(prev => ({ ...prev, filing: false, filingList: false }))
            replace(`?tab=${tab}`, 'reset')
            dispatch(reset('editForm'))
        })
    }

    /**
     * Get UCC Filings for Application and update the table data adding keyField
     */
    const updateUccFilingsTableData = () => {
        getUccFilingsForApplication({applicationId})
            .then(data => data.map((item) => ({ ...item, keyField: item.entityUuid + item.entityType + item.addressId })))
            .then(setUccFilingsList)
            .finally(() => { setLoadingFilingTableData(false) })
    }

    /**
     * Handle Row Selection
     * */
    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            setSelectedRows(prevRows => [...prevRows, row]);
            setUccSearchId(prev => ([...prev, { id: row.entityUuid, entityType: row.entityType, addressId: row.addressId }]))
        } else {
            setSelectedRows(prevRows => prevRows.filter(x => x.keyField !== row.keyField));
            setUccSearchId(prev => prev.filter(x => x.id !== row.entityUuid))
        }
        sortSelectedRows();
    }

    const handleFilingSelectAll = (isSelect, rows) => {
        if (isSelect && rows?.length) {
            const { state, countryCode } = rows[0]
            const validRows = rows.filter(row => row.state === state && row.countryCode === countryCode)
            setSelectedRows(validRows)
            setUccSearchId(validRows.map(r => ({ id: r.entityUuid, entityType: r.entityType, addressId: r.addressId })))
        }
        else {
            setSelectedRows([])
            setUccSearchId([])
        }
        sortSelectedRows();
    }

    /**
     * Sort Selected Rows based on the order of the table
     */
    const sortSelectedRows = () => {
        setSelectedRows(prevRows => [...prevRows].sort((a, b) => {
            const indexA = uccFilingsList.findIndex(item => item.keyField === a.keyField);
            const indexB = uccFilingsList.findIndex(item => item.keyField === b.keyField);
            return indexA - indexB;
        }));
    }

    /**
     * Filing Select Row Configuration
     */
    const filingSelectRow = {
        mode: 'checkbox',
        selected: selectedRows.map(row => row.keyField),
        clickToSelect: true,
        onSelect: (row, isSelect) => handleOnSelect(row, isSelect),
        onSelectAll: handleFilingSelectAll,
        nonSelectable: nonSelectableRows.map(item => item.keyField),
    }

    const handleOnClosePostingDrawer = () => {
        dispatch(reset('editForm'))
        setOpenPostingDrawer(false)
        setTimeout(() => replace(`?tab=${tab}`, 'reset'), 100);
    }

    const uccFilingColumnsWithAction = uccFilingsTableColumns.map((item, index) => {
        if (item.dataField === 'action') {
            item.formatter = (cell, row, rowIndex) => {
                if (row.status !== status.Not_Started)
                    return (
                        <VisibilityIcon color='primary' onClick={() => {
                            const qs = { tab, drawerMode: 'filing', entityUUID: row.entityUuid, isDba: row.entityType === 'BUSINESS_DBA' };
                            replace(`?${queryString.stringify(qs)}`, 'filing')
                        }} />
                    )
            }
        }
        return item
    })

    const handleGetJurisdictionList = useCallback((params) => getJurisdictionList(params)(), [])
    const handleGetTemplates = useCallback(() => dispatch(getTemplates()), [dispatch])
    const handleGetFiles = useCallback((applicantId, pagination = {}, query = {}) => dispatch(getFiles(applicantId, pagination, query)), [dispatch])
    const handleFilingPreview = useCallback((applicationId, payLoad, details) => dispatch(filingPreview(applicationId, payLoad, details)), [dispatch])
    const handleRequestUCCForceUpdate = useCallback((filingId) => dispatch(requestUCCForceUpdate(filingId)), [dispatch])
    const handleRequestUccFilingImage = (filingId) => requestUccFilingImage(filingId)()

    return (
        <>
            <h5>{strings.UCC} {strings.FILING}</h5>
            <TableComponent
                keyField='keyField'
                columns={uccFilingColumnsWithAction}
                data={uccFilingsList}
                selectRow={!isReadOnlyUser ? filingSelectRow : undefined}
                showPagination={false}
                isLoading={loadingFilingTableData}
            />

            {!isReadOnlyUser &&
                <div className='d-flex justify-content-end mt-3'>
                    <Button variant='contained' color='primary' disabled={selectedRows.length === 0} onClick={handleOpenPosting}>
                        {strings.APPLICANT.UCC.FILE_UCC}
                    </Button>
                </div>
            }

            <UccPosting
                open={openPostingDrawer}
                onClose={handleOnClosePostingDrawer}
                state={state}
                countryCode={country}
                setState={setState}
                postFiling={(bodyData) => performPostFiling(bodyData)}
                UCCFileLoading={loading.filing}
                getJurisdictionList={handleGetJurisdictionList}
                getTemplates={handleGetTemplates}
                filingPreview={handleFilingPreview}
                handleSubmit={handleSubmitUccPosting}
                applicationId={applicationId}
                UccSearchId={uccSearchId}
                filingSelectedRow={selectedRows}
                selectedFilingIndex={selectedFilingIndex}
                change={(field, value) => dispatch(change('editForm', field, value))}
                // Attachment props
                onSubmit={uploadFile}
                getFiles={handleGetFiles}
                attachmentList={attachmentList}
                downLoadAttachment={downLoadAttachment}
                totalPages={headers["x-pagination-total-pages"]}
                totalRecords={headers["x-pagination-total-records"]}
                getAttachmentPDF={getAttachmentPDF}
            />

            <InfoView
                state={state}
                open={filingInfoDrawer.open}
                onClose={() => {
                    setFilingInfo({ open: false })
                    setTimeout(() => replace(`?tab=${tab}`, 'reset'), 100);
                }}
                retryFiling={() => {
                    setOpenPostingDrawer(true)
                    setFilingInfo({ open: false })
                }}
                requestUccFilingImage={handleRequestUccFilingImage}
                downLoadAttachment={downLoadAttachment}
                applicationId={applicationId}
                filingSelectedRow={selectedRows}
                requestUCCForceUpdate={handleRequestUCCForceUpdate}
                getUccFilingDetailsByFilingId={(filingId) => dispatch(getUccFilingDetailsByFilingId(filingId))}
                getUccFilingDetailsByFilingNumber={(filingNumber) => dispatch(getUccFilingDetailsByFilingNumber(filingNumber))}
                isUCC3={filingInfoDrawer.ucc3}
                ucc3Data={ucc3Data}
                viewMode={'fromUcc'}
                hideAmendmentHistory
                hideDetails={filingInfoDrawer.hideDetails}
                loading={loading.filingInfoDrawer}
                children={filingInfoDrawer.hideDetails &&
                    <DiffView
                        data={filingInfoDrawer.data}
                        ucc3Preview={ucc3Data}
                    />}
            />
        </>
    )
}

export default UccFilings;