import React from 'react';
import strings from 'strings';
import { Dialog } from 'components/shared/Dialog';
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core';
import { maxLength2000, maxLength50, minLength10, minLength5 } from 'tools/forms/validators';
import { InputField } from 'components/forms/reduxForm';

function TemplateForm(props) {
    const {
        open,
        onClose,
        onSubmit,
        mode = "Create",
        loading,
        submitting,
        pristine,
    } = props;

    return (
        <Dialog open={open} fullWidth={true} maxWidth="sm">
            <DialogTitle>
                {mode} {strings.APPLICANT.UCCFILING.COLLATERAL_DESCRIPTION}
            </DialogTitle>
            {loading ? (
                <div className="loaderContainer">
                    <CircularProgress />
                </div>
            ) : (
                <>
                    <DialogContent>
                        <InputField
                            name='description'
                            label={strings.SETTINGS_FORM.TEMPLATE.NAME}
                            required
                            validate={[minLength5, maxLength50]}
                        />
                        <InputField
                            name='templateText'
                            label={strings.SETTINGS_FORM.TEMPLATE.DESCRIPTION}
                            required
                            rows={4}
                            rowsMax={20}
                            multiline
                            validate={[minLength10, maxLength2000]}
                        />
                    </DialogContent>
                </>
            )}

            <DialogActions>
                <Button color="primary" onClick={onClose}>
                    {strings.CANCEL}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSubmit}
                    disabled={loading || submitting || pristine}
                >
                    {strings.Save}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TemplateForm