import React, { useEffect, useState } from 'react'
import { uccSearchHistory } from 'actions/applicants'
import UccSearchResultWrapperWithParams from './UccSearchResultWrapperWithParams'
import NoDataIndication from '../../../../../../../shared/NoDataIndication'
import Loader from '../../../../../../../shared/Loader'
import { getPaginationHeaders } from '../../../../../../../../constants/utilityFunctions'
import SimplePagination from '../../../../../../../../components/shared/SimplePagination.js/index'
import strings from '../../../../../../../../strings'

const UccSearchHistoryTabContent = ({ selectedEntity, applicationId }) => {
    
    const [loading, setLoading] = useState(false)
    const [historyResponse, setHistoryResponse] = useState([])


    const [paginationParamHeaders, setPaginationParamHeaders] = useState({})
    const [paginationInfo, setPaginationInfo] = useState({})

    let noDataMessage = '';
    if (selectedEntity.type === 'BUSINESS') {
        noDataMessage = strings.APPLICANT.UCC.NO_PREVIOUS_SEARCHES_FOR_BUSINESS;
    } else if (selectedEntity.type === 'OWNER') {
        noDataMessage = strings.APPLICANT.UCC.NO_PREVIOUS_SEARCHES_FOR_OWNER;
    }

    useEffect(() => {
        setLoading(true)

        uccSearchHistory(applicationId, {uuid: selectedEntity.uuid, type: selectedEntity.type}, paginationParamHeaders)()
            .then((r) => {
                setHistoryResponse(r.response);
                setPaginationInfo(getPaginationHeaders(r));
            })
            .finally(() => setLoading(false))
            // eslint-disable-next-line
    }, [paginationParamHeaders])

    return (
        <div className='mt-3'>
            {loading ? <Loader /> : 
                historyResponse?.length > 0 ?
                    <>
                        {
                            historyResponse?.map((hist, index) =>
                                <UccSearchResultWrapperWithParams key={index} uccResponse={hist} applicationId={applicationId} selectedEntity={selectedEntity} /> 
                            )
                        }
                    </>
                    : <NoDataIndication message={noDataMessage}/>
            }
            {paginationInfo?.totalRecords > 0 &&
                <SimplePagination
                    onChangePagination={setPaginationParamHeaders}
                    totalRecords={paginationInfo?.totalRecords}
                    totalPages={paginationInfo?.totalPages}
                />
            }
        </div>
    )
}

export default UccSearchHistoryTabContent