import React from "react"
import './newUcc.scss'
import UccSearch from "./Components/UccSearch/UccSearch"
import UccFilings from "./Components/UccFIlings/UccFilings";
import UccDirectAmendments from "./Components/UccDirectAmendments/UccDirectAmendments";
import { Grid } from "@material-ui/core";

const NewUCCTabContent = ({ applicationId, handleSubmitUccPosting }) => {

    return (
        <Grid container>
            <Grid item xs={12} className='m-3'>
                <UccSearch applicationId={applicationId} />
            </Grid>
            <Grid item xs={12} className='m-3'>
                <UccFilings applicationId={applicationId} handleSubmitUccPosting={handleSubmitUccPosting} />
            </Grid>
            <Grid item xs={12} className='m-3'>
                <UccDirectAmendments applicationId={applicationId} />
            </Grid>
        </Grid >
    )
}

export default NewUCCTabContent