import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Button } from "@material-ui/core"
import strings from 'strings'
import { joinAttributes } from 'tools/formatters';
import SearchResultInfo from 'components/QuickSearch/SOS/SearchViewComponents/SearchResultInfo';
import PossibleAlternativeCard from 'components/QuickSearch/SOS/SearchViewComponents/PossibleAlternativeCard';

const sosStrings = strings.APPLICANT.SOS

const AutoreportSearchResultCard = (props) => {

    const { searchResult, possibleAlternatives, saveSosRecord, state } = props

    const [open, setOpen] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const address = joinAttributes({ street: searchResult?.physicalAddressStreet, city: searchResult?.physicalAddressCity, state: searchResult?.physicalAddressState, zip: searchResult?.physicalAddressZip })

    const handleSaveCloseMatch = (searchResult) => {
        setSaveLoading(true)
        const payload = { sosId: searchResult?.sosId }
        saveSosRecord('closeMatch', payload)
    }

    const handleSaveAlternative = (sosId, businessName) => {
        setSaveLoading(true)
        const payload = { sosId, businessName }
        saveSosRecord('alternative', payload)
    }

    return (
        <div className={'cardStyle'}>
            <div className='d-flex justify-content-between'>
                <div className='flex-fill'>
                    <p className='bold-font mb-0'>{searchResult?.title}</p>
                    <p className='mb-0'>{address}</p>
                </div>
                <div className='d-flex justify-content-end align-items-center'>
                    <div className='blueLabel mr-3'> {sosStrings.CLOSE_MATCH} </div>
                    <Button
                        variant="outlined"
                        color="primary"
                        className='summaryHeaderButton mr-3'
                        onClick={() => handleSaveCloseMatch(searchResult)}
                        disabled={saveLoading}
                    >
                        {strings.ATTACH}
                    </Button>
                    <div className='align-self-center' onClick={() => setOpen(prev => !prev)} > {open ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}</div>
                </div>
            </div>
            {open && <SearchResultInfo searchResult={searchResult} possibleAlternatives={possibleAlternatives} />}
            {
                possibleAlternatives?.length > 0 &&
                possibleAlternatives.map((item) => {
                    return (<PossibleAlternativeCard
                        key={item?.sosId}
                        alternative={item}
                        actionToPerform={handleSaveAlternative}
                        actionText={strings.ATTACH}
                        state={state}
                        actionLoading={saveLoading}
                    />)
                })
            }
        </div>
    )
}

export default AutoreportSearchResultCard