import React, {useState} from 'react'
import Button from 'components/shared/Button/index';
import {uccAttachedFilingColumns} from "../../../../../../../../constants/application";
import strings from "../../../../../../../../strings";
import UccFilingDetailView from "../../UccFilingDetail/UccFilingDetailView";
import {
    getIndividualFilingDetails
} from "../../../../../../../../actions/applicants";
import {joinAttributes} from "../../../../../../../../tools/formatters";
import UccCard from "../UccCard/UccCard";

const UccAttachedFilingsCard = ({ filing, applicationId, indexDate}) => {

    const [openFilingDetails, setOpenFilingDetails] = useState(false)
    const [filingDetailsPayload, setFilingDetailsPayload] = useState(filing?.uuid ? filing : null)
    const filingDetailsUuid = filing?.uuid || filing?.filingDetailUuid

    const arrayOfFilings = filing?.response?.Results || filing?.Group
    const entityName = filing?.uccGroupInformation?.entityName || filing?.EntityName
    const address = filing?.uccGroupInformation?.address ||
        joinAttributes({Address: filing?.Address, City: filing?.City, State: filing?.State})

    const tableData = filing?.Group || filing?.response?.Results?.map(result => ({
        ...result,
        State: filing?.uccGroupInformation?.state
    }))

    function openFilingDetailsDrawer(event) {
        event.stopPropagation()

        if (filingDetailsPayload){
            setOpenFilingDetails(true)
        } else {
            getIndividualFilingDetails(applicationId, filingDetailsUuid)().then((res) => {
                setFilingDetailsPayload(res)
                setOpenFilingDetails(true)
            })
        }
    }

    const button = <Button
        variant="outlined"
        color="primary"
        onClick={(event) => openFilingDetailsDrawer(event)}
    >
        {strings.APPLICANT.UCC.VIEW_DETAILS}
    </Button>;


    return (
        <>
            <UccCard
                arrayOfFilings={arrayOfFilings}
                entityName={entityName}
                address={address}
                indexDate={indexDate || filing?.response?.Header?.IndexDate}
                button={button}
                tableData={tableData}
                column={uccAttachedFilingColumns}
            />
            {openFilingDetails &&
                <UccFilingDetailView
                    open={openFilingDetails}
                    onClose={() => {
                        setOpenFilingDetails(false)
                    }}
                    applicationId={applicationId}
                    data={filingDetailsPayload}
                    id='open-summary-filing-details-drawer'
                />
            }
        </>
    )
}

export default UccAttachedFilingsCard