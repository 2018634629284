import React from 'react'
import strings from 'strings'
import TableComponent from 'components/shared/TableComponent'
import { sosRecordFilingHistoryColumns } from 'constants/application'

const sosStrings = strings.APPLICANT.SOS

const FilingHistory = (props) => {

    const { data } = props

    return (
        <div className='cardStyle mb-3'>
            <h5 className='mb-0'>{sosStrings.FILING_HISTORY}</h5>
            { (data?.documents) ?
            <TableComponent
                    keyField='url'
                    columns={sosRecordFilingHistoryColumns}
                    data={data?.documents}
                    showPagination={false}
                />    
            :
                <p>No Documents available.</p> 
            }
            

        </div>
    )
}

export default FilingHistory