import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux';
import ApartmentIcon from '@material-ui/icons/Apartment'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import HelpIcon from '@material-ui/icons/Help';
import { Alert } from '@material-ui/lab'
import Checkbox from 'components/forms/Checkbox'
import { DownloadUccEmptyReport } from 'components/PdfTemplates'
import Input from 'components/forms/Input'
import Drawer from 'components/shared/Drawer'
import TableComponent from 'components/shared/TableComponent'
import { ReportColums } from 'constants/application'
import { Col, Row } from 'react-bootstrap'
import { AttachmentsColumn } from "constants/application"
import { InputField, DropdownField } from 'components/forms/reduxForm'
import Loader from 'components/shared/Loader'
import { Toast } from 'components/shared/Toaster'
import { Dialog } from 'components/shared/Dialog'
import Button from 'components/shared/Button'
import PrepareTooltip from 'components/shared/Tooltip';
import { RenderFields } from 'components/Applicants/partial/Drawer/Preview';
import { DrawerAction } from 'components/shared/Drawer';
import { Guide } from './guide'
import { statesOptions, getStateCode } from 'constants/constantVariables'
import { Icon } from 'styles'
import strings from 'strings'
import './report.scss'

const t = strings.APPLICANT.UCC
const Report = (props) => {
    const {
        open,
        onClose,
        performUccSearch,
        handleSavedDetail,
        loading,
        getFilingDetails,
        isQuickSearch,
        saveFiling,
        detailLoading,
        savedReport,
        deleteReport,
        handleSubmit,
        entity,
        data,
        terminationPreviewData,
        type = 'applicant',
        saveLoading,
        reportLoading,
        applicationId,
        interactionId,
        downLoadAttachment,
        getTerminationPreview,
        terminateUCC,
        getJurisdictionList,
        getListOfDirectAmendments,
        getFilingReportsAttachments,
        change,
        selectedEntity,
        attachmentList,
        totalPages,
        totalRecords,
        updateSummaryCard
    } = props
    const filterParams = {
        search: {
            includeInactiveFilings: true,
            date: moment().subtract(6, 'years').format('YYYY-MM-DD')
        },
    }

    const clientState = useSelector(state => state.user.userDetails.securityContext.client);
    const CLIENT_NAME = clientState.clientName;
    const clientReferenceRequired = clientState.clientReferenceRequired;
    const fcsClientReference = useSelector(state => state?.applicant?.applicantById?.business?.fcsClientReference);
    const hasFcsClientReference = fcsClientReference !== null && fcsClientReference !== "";
    const [searchFilter, setSearchFilter] = useState(filterParams)
    const [selectedIndex, setSelectedIndex] = useState()
    const [openTooltip, setOpen] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [showAlertDialog, setShowAlertDialog] = useState({ open: false, rowData: {} })
    const [openPreview, setOpenPreview] = useState(false)
    const [loader, setLoader] = useState({ pdf: false, termination: false, submitTermination: false, params: false })
    const [selectedValue, setSelectedValue] = useState({ value: 0, error: '' });
    const [formvalues, setformValues] = useState({})
    const [options, setOptions] = useState([])
    const [selectedReports, setSelectedReports] = useState([])
    const [selectedFilings, setSelectedFilings] = useState([])
    const [attachmentLoading, setAttachmentLoading] = useState(false)
    const [state, setState] = useState('');
    const formValues = useSelector(state => state.form?.editForm?.values) || {}
    const uccSearchIndexDate = useSelector(state => state.applicant.ucc.uccSearchIndexDate)
    const [searchState, setSearchState] = useState('')

    useEffect(() => {
        const filingNumbers = []
        selectedReports.forEach(item => {
            filingNumbers.push(...item?.groups?.map(item => item.FilingNumber))
        })
        setSelectedFilings(filingNumbers)
    }, [selectedReports])

    useEffect(() => {
        if (open) {
            cleanFields()
            setSearchFilter(filterParams)
            const securedParties = terminationPreviewData.securedParties && terminationPreviewData.securedParties[0]
            if (securedParties) {
                const {
                    firstName,
                    middleName,
                    lastName,
                    organizationName,
                    address
                } = securedParties
                const { streetAddress, city, state, postalCode } = address
                change('organizationName', organizationName)
                change('firstName', firstName)
                change('middleName', middleName)
                change('lastName', lastName)
                change('address.streetAddress', streetAddress)
                change('address.city', city)
                change('address.state', state)
                change('address.postalCode', postalCode)
            }
        }
        // eslint-disable-next-line
    }, [open, terminationPreviewData])

    useEffect(() => {
        if (savedReport && selectedEntity) {
            setAttachmentLoading(true)
            const query = {
                type: selectedEntity?.entityType,
                uuid: selectedEntity?.entityUuid
            }
            getFilingReportsAttachments(applicationId, {}, query).finally(() => {
                setAttachmentLoading(false)
            })
        }
    }, [savedReport, selectedEntity, applicationId, getFilingReportsAttachments])

    const cleanFields = () => {
        change('jurisdictionId', '')
        change('clientReference', '')
        change('filerReference', '')
        change('securedPartyMasking', false)
    }

    const handlePagination = (pagination) => {
        setAttachmentLoading(true)
        const query = {
            type: selectedEntity?.entityType,
            uuid: selectedEntity?.entityUuid
        }
        getFilingReportsAttachments(applicationId, pagination, query).finally(() => {
            setAttachmentLoading(false)
        })
    }

    const handleFilter = (e, key) => {
        setSearchFilter({
            ...searchFilter, search: { ...searchFilter.search, [key]: e }
        })
    }

    const handleDetail = (detail, i) => {
        const filingIds = detail.Group.map(g => g.FilingNumber)
        setSelectedIndex(i)
        getFilingDetails(filingIds, searchState)
    }

    const getDetailedReports = () => {
        getFilingDetails(selectedFilings, searchState, false)
    }

    const handleSave = (detail, i) => {
        setSelectedIndex(i)
        const filingIds = detail.Group.map(g => g.FilingNumber)
        saveFiling(filingIds, detail.State)
    }

    const handleDelete = row => {
        const payLoad = {
            [row.entityType === 'BUSINESS' ? 'businessId' : 'ownerId']: row.entityUUID,
            filingDetailsId: row.uuid
        }
        setShowAlertDialog({ open: false, rowData: {} })
        setDeleteLoading(true)
        deleteReport(applicationId, payLoad)
            .then(() => {
                handleSavedDetail()
                Toast({ type: 'success', message: t.UCC_DELTE_MSG })
            })
            .finally(() => {
                setDeleteLoading(false)
                updateSummaryCard()
            })
    }


    const handleOnClick = ({ FilingNumber, uuid }) => {
        setOpenPreview(true)
        setformValues({ filingNumber: FilingNumber, filingDetails: uuid })
        setLoader(prev => ({ ...prev, termination: true }))
        getTerminationPreview(applicationId, uuid, FilingNumber)
            .then(res => {
                if (res?.stateCode && res.stateCode !== null && res.stateCode !== "") {
                    handleStateFromFCS(res.stateCode)
                } else {
                    setState('-')
                    change('state', '')
                    setOptions([])
                }
            })
            .catch(() => setOpenPreview(false))
            .finally(() => setLoader(prev => ({ ...prev, termination: false })))
    }

    const handleStateFromFCS = state => {
        const stateCode = getStateCode(state, 'USA')
        if (stateCode) {
            setState(stateCode)
            change('state', stateCode)
            getJurisdictionList({ state: stateCode, countryCode: 'USA' })
                .then(res => setOptions(res.map(({ name, id }) => ({ label: name, value: id }))))
        } else {
            setState('-')
            change('state', '')
            setOptions([])
        }
    }

    const handleStateChange = state => {
        setState(state)
        change('state', state)
        getJurisdictionList({ state, countryCode: 'USA' })
            .then(res => setOptions(res.map(({ name, id }) => ({ label: name, value: id }))))
    }

    const isFormValid = values => {
        if (selectedValue.value === null) {
            setSelectedValue({ value: null, error: 'error' })
            return false
        }
        setformValues(prev => ({ ...prev, ...values }))
        setShowAlertDialog({ open: true, rowData: {} })
    }

    const warningClientReferenceQS = () => (
        <Alert severity="info">
            {CLIENT_NAME} {strings.INFO_ENTER_CLIENT_REFERENCE}
        </Alert>
    )

    const submitTermination = () => {
        setLoader(prev => ({ ...prev, submitTermination: true }))
        const payLoad = {
            authorizingParty: {
                address: formvalues.address,
                organizationName: formValues.organizationName,
                firstName: formValues.firstName,
                middleName: formValues.middleName,
                lastName: formValues.lastName
            },
            directAmendment: true,
            applicationId,
            ...formvalues
        }
        delete payLoad.searchOrganizationName;
        delete payLoad.stateCode;
        delete payLoad.organizationName
        delete payLoad.firstName
        delete payLoad.middleName
        delete payLoad.address
        delete payLoad.state

        terminateUCC(payLoad)
            .then(() => {
                Toast({ type: 'success', message: strings.SUCCESS })
                getListOfDirectAmendments()
            })
            .finally(() => {
                setShowAlertDialog({ open: false, rowData: {} })
                setLoader(prev => ({ ...prev, submitTermination: false }))
                setOpenPreview(false)
                cleanFields()
                onClose()
            })
    }

    const action = {
        formatter: (cell, row) => {
            return <Icon
                className='icons red d-flex opacity-100'
                title='Termination'
                folder='icons'
                fileName='stop.svg'
                onClick={() => handleOnClick(row)}
            />
        }
    }

    const reportColumns = savedReport ? ReportColums.map(cols => {
        if (cols.dataField === 'action')
            cols = { ...cols, ...action }
        return cols
    }) : ReportColums

    const SelectedFilterComponent = () => {
        return (
            <>
                {
                    data?.length > 0 && (<>
                        <div className='selectionContainer'>
                                <p>{`(${selectedFilings?.length})`} {t.FILING_SELECTED}</p>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    className="mt14 actionButton"
                                    loading={detailLoading}
                                    disabled={selectedFilings?.length === 0}
                                    onClick={getDetailedReports}
                                >
                                    {t.GET_REPORT_DETAILS}
                                </Button>
                                <DownloadUccEmptyReport
                                    applicationId={applicationId}
                                    interactionId={interactionId}
                                    disabled={selectedFilings?.length > 0}
                                    buttonText={t.GET_SEARCH_REPORT}
                                    isQuickSearch={isQuickSearch}
                                    variant='outlined'
                                />
                        </div>
                        <div className="selectionContainer right-align-text-index-date">
                                    {uccSearchIndexDate &&
                                            <p>{t.INDEX_DATE} {moment(uccSearchIndexDate).format("MM-DD-YYYY")}</p>
                                    }
                        </div>
                        </>
                    )
                }
            </>
        )
    }

    const filters = (
        <>
            {type === 'quick search' && clientReferenceRequired && !(searchFilter?.search?.clientReference) && warningClientReferenceQS()}
            <div className='d-flex align-items-center filter-container'>
                {entity === 'BUSINESS' ?
                    <InputField name='searchOrganizationName' label={strings.LABEL.ORGANIZATION_NAME} className='w-180' /> :
                    <>
                        <InputField name='searchFirstName' label={strings.LABEL.FIRST_NAME} className='w-107' />
                        <InputField name='searchLastName' label={strings.LABEL.LAST_NAME} className='w-107' />
                    </>
                }
                <DropdownField
                    className='w-93'
                    name='stateCode'
                    options={statesOptions.USA}
                    label={strings.LABEL.STATE}
                    getOptionLabel={opt => opt.value}
                />
                <Input
                    name='date'
                    label={strings.LABEL.UCC_FILED_DATE}
                    type='date'
                    className='date'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={searchFilter.search.date}
                    onChange={(e) => handleFilter(e.target.value, 'date')}
                />
                {type === 'quick search' &&
                    <InputField
                        name='clientReference'
                        label={strings.APPLICANT.UCCFILING.CLIENT_REFRENCE}
                        className='w-155'
                        value={searchFilter.search.clientReference}
                        onChange={(e) => handleFilter(e.target.value, 'clientReference')}
                    />
                }
                <Checkbox
                    name='includeInactiveFilings'
                    label={strings.APPLICANT.INACTIVE_FILINGS}
                    className='filing'
                    checked={searchFilter.search.includeInactiveFilings}
                    onChange={(e) => handleFilter(e, 'includeInactiveFilings')}
                />
                <Button
                    variant='contained'
                    color='primary'
                    className="mt14"
                    onClick={handleSubmit(data => {
                        setSelectedReports([])
                        setSelectedFilings([])
                        performUccSearch(searchFilter, data)
                        setSearchState(data.stateCode)
                    })}
                    loading={loading}
                    disabled={type === 'quick search' ? (
                        clientReferenceRequired && !(searchFilter?.search?.clientReference)
                    ) : (
                        clientReferenceRequired && !hasFcsClientReference
                    )}
                >
                    {strings.SEARCH}
                </Button>
            </div>
            <SelectedFilterComponent />
        </>
    )

    const prepareActions = (d, i) => {
        const detailButton = <Button className="mr-2" loading={detailLoading && selectedIndex === i} onClick={() => handleDetail(d, i)}>
            {strings.APPLICANT.DETAIL_REPORT}
        </Button>

        if (savedReport)
            return <>
                {detailButton}
                <Button color='secondary' loading={deleteLoading && selectedIndex === i} onClick={() => {
                    setShowAlertDialog({ open: true, rowData: d })
                    setSelectedIndex(i)
                }}>
                    {strings.DELETE}
                </Button>
            </>
        if (type === 'applicant')
            return <>
                <PrepareTooltip placement='top' theme='white' title={d.Saved ? t.ALREADY_SAVED : ''}>
                    <Button onClick={() => handleSave(d, i)} loading={saveLoading && selectedIndex === i} disabled={d.Saved}>
                        {d.Saved ? strings.SAVED : strings.Save}
                    </Button>
                </PrepareTooltip>
            </>
    }

    const buildSelectedReports = (index, groups) => {
        setSelectedReports(prev => [
            ...prev.filter(item => item.index !== index),
            {
                index,
                groups: groups
            }
        ])
    }

    const handleReportSelect = (row, isSelect, index) => {
        if (isSelect) {
            const temp = []
            const entityGroups = data?.filter((item, i) => i === index)
            // maintaing the order of list with selected ones
            entityGroups[0].Group.forEach(list => {
                const selectedEntityReports = selectedReports.filter(item => item?.index === index)
                const element = [...selectedEntityReports[0]?.groups || [], row].find(t => list.FilingNumber === t.FilingNumber)
                if (element)
                    temp.push(element)
            })
            buildSelectedReports(index, temp)
        }
        else {
            const entityGroups = selectedReports?.filter(item => item.index === index)
            const filteredGroups = entityGroups[0]?.groups?.filter(t => t.FilingNumber !== row.FilingNumber)
            buildSelectedReports(index, filteredGroups)
        }
    }

    const handleReportSelectAll = (isSelect, rows, index) => {
        if (isSelect && rows?.length) {
            buildSelectedReports(index, rows)
        }
        else {
            buildSelectedReports(index, [])
        }
    }

    const fileAction = {
        formatter: (cell, row, rowIndex) => {
            return (
                <div className='download-file' onClick={() => downLoadAttachment(applicationId, row.uuid, row.fileName)}>{cell}</div>
            )
        }
    }

    let columns = []
    AttachmentsColumn.map((item, index) => {
        if (item.dataField === 'fileName')
            AttachmentsColumn[index] = { ...item, ...fileAction }

        if (['purpose', 'action'].includes(item.dataField))
            return item

        return columns.push(item)
    })

    const content = (
        <>
            {
                savedReport && (
                    <div className='box'>
                        <div className='header'>
                            <h5>{strings.SAVED_SEARCH_REPORTS}</h5>
                        </div>
                        <TableComponent
                            data={attachmentList}
                            keyField='uuid'
                            columns={columns}
                            totalRecords={totalRecords}
                            totalPages={totalPages}
                            showLimitOptions={false}
                            onChangePagination={(pagination) => handlePagination(pagination)}
                            selectRow={undefined}
                            className={'custom-table-checkbox'}
                            paginationLimit={5}
                            loading={attachmentLoading}
                        />
                    </div>
                )
            }
            {!data ?
                <div className='emptyIcon h-75'>
                    <Icon folder='icons' fileName='emptyState.svg' />
                    <div className='mt-2'>{strings.APPLICANT.UCCFILING.PERFORM_SEARCH}</div>
                </div>
                :
                data.length ?
                    <div className={`pb-5 scrollableList ${!savedReport ? 'reduce-height-250' : 'reduce-height-100'}`}>
                        {data.map((d, i) => {
                            const currentEntityReports = selectedReports?.filter(item => item?.index === i)
                            const currentSelectedFillings = currentEntityReports[0]?.groups?.map(item => item?.FilingNumber)
                            return (
                                <div className="box" key={i}>
                                    <div className='header'>
                                        <div className='d-flex align-items-center'>
                                            <ApartmentIcon htmlColor="#0000009c" />
                                            <span className='text pl-1'>Entity: </span>
                                            <span className='pl-1 pr-2 lessText' title={d.EntityName}>{d.EntityName}</span>

                                            <LocationOnOutlinedIcon htmlColor="#0000009c" />
                                            <span className='text pl-1'>Address:</span>
                                            <span className='pl-1 lessText' title={d.Address}>{d.Address}</span>
                                        </div>

                                        <div>
                                            {prepareActions(d, i)}
                                        </div>
                                    </div>


                                    <TableComponent
                                        keyField='FilingNumber'
                                        columns={reportColumns}
                                        data={d.Group?.map(group => ({ ...group, uuid: d.uuid })) || []}
                                        showPagination={false}
                                        className="customTable"
                                        selectRow={!savedReport ? {
                                            mode: 'checkbox',
                                            onSelect: (row, isSelect) => handleReportSelect(row, isSelect, i),
                                            onSelectAll: (isSelect, row) => handleReportSelectAll(isSelect, row, i),
                                            selected: currentSelectedFillings || [],
                                        } : undefined}
                                    />
                                </div>)
                        })}
                    </div> :
                    <div className='emptyIcon h-75'>
                        <Icon folder='root' fileName='emptyUCC.svg' />
                        <div>{strings.NO_RECORDS_FOUND}</div>
                        {!savedReport &&
                            <DownloadUccEmptyReport
                                applicationId={applicationId}
                                interactionId={interactionId}
                                buttonText={t.PDF_REPORT}
                                isQuickSearch={isQuickSearch}
                            />}
                    </div>}
        </>
    )

    const warningClientReference = () => (
        <Alert severity="info">
            {CLIENT_NAME} {strings.INFO_NO_CLIENT_REFERENCE}
        </Alert>
    )

    if (type === 'quick search')
        return (
            <>
                {filters}
                {content}
            </>
        )

    let title = `${strings.PERFORM} ${strings.UCC} ${strings.SEARCH}`;
    if (savedReport) title = 'Saved UCC Filings'

    return (
        <>
            <Drawer
                open={open}
                onClose={() => {
                    setSelectedReports([])
                    setSelectedFilings([])
                    cleanFields()
                    onClose()
                }}
                title={title}
                drawerClass='d-flex align-items-end'
                actions={!savedReport && <HelpIcon className='ml-1 cursor-pointer' color='primary' onClick={() => setOpen(true)} />}
            >
                <>
                    {clientReferenceRequired && !hasFcsClientReference && (warningClientReference())}
                    {!savedReport && filters}
                    {reportLoading ? <Loader /> : content}
                </>
            </Drawer>
            <Drawer
                open={openPreview}
                onClose={() => setOpenPreview(false)}
                title={<div className='ml-2'>
                    <h4 className='mb-0 d-block'>{strings.APPLICANT.CLIENTS.TERMINATION}</h4>
                    {formvalues.filingNumber && <div>
                        <span>{strings.APPLICANT.UCCFILING.FILING_NUMBER} :  </span>
                        <span className='font-weight-bold'>{formvalues.filingNumber}</span>
                    </div>}
                </div>}
            >
                {loader.termination ? <Loader /> :
                    <>
                        <div className='p-4 pb-100 editContainer'>
                            <div className='hint'>{strings.APPLICANT.CLIENTS.AUTHORIZING_PARTY}</div>
                            <RenderFields formValues={formValues} change={change} type='direct-termination' />
                            <div className='hint'>{strings.APPLICANT.CLIENTS.JURISDICTION}</div>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <DropdownField
                                            name="state"
                                            label={"State"}
                                            menuPlacement='top'
                                            options={statesOptions.USA}
                                            onChange={state => handleStateChange(state)}
                                            isSearchable
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <div className='mb-3'>
                                        <DropdownField
                                            name="jurisdictionId"
                                            label={strings.APPLICANT.UCCFILING.JURISDICTION}
                                            placeholder={`Jurisdiction for ${state}`}
                                            menuPlacement='top'
                                            options={options}
                                            isSearchable
                                            required
                                        />
                                    </div>
                                </Col>
                            </Row>

                        </div>
                        <DrawerAction>
                            <Button className='submit' color='secondary' onClick={handleSubmit(isFormValid)} loading={loading.submitTermination}>
                                {strings.APPLICANT.CLIENTS.SUBMIT_TERMINATION}
                            </Button>
                        </DrawerAction>
                    </>}
            </Drawer>

            <Guide open={openTooltip} onClose={setOpen} />

            <Dialog
                fullWidth
                maxWidth='sm'
                type={openPreview ? 'termination' : 'delete'}
                open={showAlertDialog.open}
                isloading={loader.submitTermination}
                confirmButton={{ text: openPreview ? strings.CONFIRM : strings.DELETE, color: 'secondary' }}
                alertTiltle={openPreview ? strings.APPLICANT.CLIENTS.CONFIRM_TERMINATION : strings.CONFIRM_DELETE}
                onSubmit={() => openPreview ? submitTermination() : handleDelete(showAlertDialog.rowData)}
                onClose={() => setShowAlertDialog({ open: false, rowData: {} })}
            />
        </>
    )
}

export default Report