import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ClientAdminMenuBarList, RoxAdminMenuBarList } from 'constants/constantVariables'
import ClientRouters from 'routes'
import RoxAdminRouters from 'routes/RoxAdminRoutes'
import Notification from 'components/shared/Notification'
import MenuComponent from './Menu'
import Auth from 'tools/AuthService'
import strings from 'strings'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    position: 'relative',
  }
}))

const Home = (props) => {
  const classes = useStyles()
  const admin = Auth.hasRole(strings.ROX_ADMIN) // checks whether user has ROX_ADMIN Role
  const [routes] = useState(admin ? <RoxAdminRouters /> : <ClientRouters />)
  const defaultMenuBarList = admin ? RoxAdminMenuBarList : ClientAdminMenuBarList
  const menuBarList = Auth.buildMenubarList(defaultMenuBarList)

  return (
    <div className={'home-container'}>
      <div className={`${classes.root}`} >
        <MenuComponent
          menuItems={menuBarList}
          {...props}
        />
        <main className={classes.content}>
          {routes}
          <Notification />
        </main>
      </div>
    </div>
  )
}

export default Home
