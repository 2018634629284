import { connect } from 'react-redux'
import Profile from 'components/Profile'
import { dispatchFormConfig, updateProfile, updatePassword } from 'actions/user'
import strings from 'strings'
import { getUserDetailByToken } from 'actions/root'

function mapStateToProps(stateProps, ownProps) {
    const { userDetails, formConfig } = stateProps?.user
    let intialValue = formConfig.type === strings.PASSWORD_CHANGE_FORM ? {} : userDetails
    return {
        initialValues: intialValue
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        dispatchFormConfig, // action to change redux form config
        updateProfile,
        updatePassword,
        getUserDetailByToken
    },
    mergeProps
)(Profile)