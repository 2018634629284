import React from "react";
import * as PropTypes from "prop-types";
import strings from "../../../../../strings";
import CheckBox from 'components/forms/Checkbox'

class StateOfFormationCheckBox extends React.Component {
    render() {
        return <CheckBox
            className="state-of-formation-checkbox"
            checked={this.props.checked}
            label={this.props.label ? strings.APPLICANT.USE_ADDRESS_STATE_AS_STATE_OF_FORMATION : null}
            onChange={this.props.onChange}
        />;
    }
}

StateOfFormationCheckBox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func
};

export default StateOfFormationCheckBox;