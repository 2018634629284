import React, { useEffect, useState } from 'react'
import SummaryHeader from '../Components/SummaryHeader/SummaryHeader'
import { getAvailableSummary, getBusinessReportSummary, getOwnerReportSummary, requestReports, getSingleAvailableSummary } from 'actions/applicants'
import Loader from 'components/shared/Loader'
import './SummaryComponent.scss'
import DataSummary from '../Components/DataSummary/DataSummary'
import SolveBgResults from '../Components/SolveBgResults/SolveBgResults'
import TableSummary from '../Components/TableSummary/TableSummary'
import VerificationSummary from '../Components/VerificationSummary/VerificationSummary'
import { Alert } from '@material-ui/lab'
import { getDataId, reportIndicatesProcessStarted } from 'constants/utilityFunctions'
import SosFilings from '../Components/SosFilings/SosFilings'
import SolveSosSearch from '../Components/SolveSosSearch/SolveSosSearch'
import strings from 'strings'
import { MAX_RETRIES_FOR_SOS_SEARCH } from 'constants/application'

const sosStrings = strings.APPLICANT.SOS

const SummaryComponent = ({ summaryTabChange, showReportDrawer, report, applicantId, handleGetAllData, isGetAllData,
                              updateSummaryCard, setDisplayGAD,
                              getRetrieveDocuments,
                              getFilingReportsAttachments, dispatchProcessing}) => {

    const [summaryInfo, setSummaryInfo] = useState([])
    const [loadingSummaryInfo, setLoadingSummaryInfo] = useState(false)
    const [loadingDecision, setLoadingDecision] = useState(false)
    const [reportInfo, setReportInfo] = useState(report)
    const [alertMsg, setAlertMsg] = useState('')

    const { status, type, entityType, entity, entityId } = reportInfo || {}
    const { lastUpdate, risk } = summaryInfo || {}

    const reportId = getDataId(type, entity);

    useEffect(() => {
        if (status?.value === 'FOUND') {
            getSummaryInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applicantId, reportInfo]);

    useEffect(() => {
        if (status?.value === 'WAITING_RESPONSE' && type === 'SOS'){
            getSosSummaryInfo()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportInfo])

    useEffect(() => {
        setReportInfo(report);
    }, [report]);

    //functions
    const updateReportInfo = () => {
        setSummaryInfo([]);
        getAvailableSummary(applicantId)()
            .then((res) => {
                const report = res.find(item => item?.type === type && item?.entityId === entityId) || {};
                setReportInfo(report);
                setLoadingDecision(false);
                if (reportIndicatesProcessStarted(report)) setDisplayGAD(false)
            })
            .catch(() => { setLoadingDecision(false) })
        updateSummaryCard()
    }

    // function to request decision when clicking get data
    const requestDecision = () => {
        setLoadingDecision(true)
        const params = getEndpointParams()
        requestReports(applicantId, params)()
            .then(() => {
                updateReportInfo();
            })
            .catch(() => { setLoadingDecision(false) })
    }

    /* function to get sos summary info when status is waiting response
    for 4 minutes this function will be called every 10 seconds
    if the status is still waiting response after 4 minutes, an alert will be shown */
    const getSosSummaryInfo = (tryNumber = 0) => {
        if (tryNumber >= MAX_RETRIES_FOR_SOS_SEARCH) {
            setAlertMsg(sosStrings.TIMEOUT)
            return
        }
        const params = getEndpointParams()
        setTimeout(() => {
            getSingleAvailableSummary(applicantId, params)()
                .then((res) => {
                    const reportData = res?.[0]
                    const reportStatus = reportData?.status?.value
                    if (reportData && reportStatus !== 'WAITING_RESPONSE') {
                        setReportInfo(reportData)
                        updateSummaryCard()
                    } else {
                        getSosSummaryInfo(tryNumber + 1)
                    }
                })
        }, 10000)
    }

    // function to get summary info when status is found
    const getSummaryInfo = () => {
        setLoadingSummaryInfo(true)
        if (entityType === 'OWNER') {
            return getOwnerReportSummary(applicantId, entityId, type)()
                .then((res) => {
                    setSummaryInfo(res || []);
                })
                .finally(() => { setLoadingSummaryInfo(false) })
        } else {
            return getBusinessReportSummary(applicantId, type)()
                .then((res) => {
                    setSummaryInfo(res || []);
                })
                .finally(() => { setLoadingSummaryInfo(false) })
        }
    };

    const getEndpointParams = () => {
        const params = { reportType: type }
        if (entityType === 'OWNER') { params.ownerId = entityId }
        return params
    }

    const backgroundCheckRequiresResolutionForBusiness = () => {
        return status?.value === 'REQUIRES_RESOLUTION' && entityType === 'BUSINESS' && type === 'LEXIS_BACKGROUND';
    }

    const sosSearchRequiresResolution = () => {
        return status?.value === 'REQUIRES_RESOLUTION' && type === 'SOS';
    }

    return (
        <div id={'summary-report-' + reportId}>
            <SummaryHeader
                lastUpdate={lastUpdate}
                risk={risk}
                summaryTabChange={summaryTabChange}
                showReportDrawer={showReportDrawer}
                applicantId={applicantId}
                reportId={reportId}
                loading={loadingSummaryInfo || loadingDecision}
                requestDecision={requestDecision}
                reportInfo={reportInfo}
                getDataDisabled={status?.value === 'WAITING_RESPONSE'}
            />
            {loadingSummaryInfo || loadingDecision ?
                <div className='summaryLoader'>
                    <Loader />
                </div>
                :
                <>
                    {
                        status?.value !== 'FOUND' && 
                        <Alert severity={status?.alert?.toLowerCase()}> {alertMsg || status?.message} </Alert>
                    }
                    {
                        status?.value === 'WAITING_RESPONSE' && !alertMsg &&
                        <div className='summaryLoader'>
                            <h5 className='pt-5 pb-3'> {strings.LOADING}</h5>
                            <Loader />
                        </div>
                    }
                    {
                        backgroundCheckRequiresResolutionForBusiness() &&
                        <SolveBgResults
                            applicationId={applicantId}
                            requestDecision={requestDecision}
                            handleGetAllData={handleGetAllData}
                            isGetAllData={isGetAllData}
                        />
                    }
                    {
                        sosSearchRequiresResolution() &&
                        <SolveSosSearch
                            applicationId={applicantId}
                            requestDecision={requestDecision}
                        />
                    }
                    {
                        summaryInfo?.customData?.dataLists?.length > 0 &&
                        <VerificationSummary info={summaryInfo?.customData} />
                    }
                    {
                        summaryInfo?.data &&
                        <DataSummary data={summaryInfo?.data} />
                    }
                    {
                        summaryInfo?.tableData?.rows &&
                        <TableSummary rows={summaryInfo?.tableData?.rows}
                                      applicantId={applicantId}
                                      entityId={entityId}
                                      entityType={entityType}
                                      getRetrieveDocuments={getRetrieveDocuments}//Ucc Filing Details Drawer
                                      getFilingReportsAttachments={getFilingReportsAttachments}
                                      dispatchProcessing={dispatchProcessing}
                        />
                    }
                    {
                        summaryInfo?.tabsData &&
                        <SosFilings tabsData={summaryInfo?.tabsData} />
                    }
                </>
            }
        </div>
    )
}

export default SummaryComponent