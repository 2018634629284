import React, { useEffect, useState } from 'react'
import PreviousSearchesColumns from './PreviousSearchesColumns'
import PreviousSearchCard from './PreviousSearchCard'
import SimplePagination from 'components/shared/SimplePagination.js/index'
import { getPaginationHeaders } from 'constants/utilityFunctions'
import { getSosQuickSearches, getSosSearches } from 'actions/applicants'
import strings from 'strings'
import { Alert } from '@material-ui/lab'
import Loader from 'components/shared/Loader'

const sosStrings = strings.APPLICANT.SOS

const PreviousSearches = (props) => {

    const { update, viewResult, search, searchLoading, isQuickSearch, applicantId } = props
    const getPreviousSearches = isQuickSearch ? getSosQuickSearches : getSosSearches

    const [previousQuickSearches, setPreviousQuickSearches] = useState([])

    const previousQuickSearchesData = previousQuickSearches?.response || []
    const previousQuickSearchesPagination = getPaginationHeaders(previousQuickSearches)

    const [paginationParams, setPaginationParams] = useState({})
    const [areSearchesPending, setAreSearchesPending] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        getPreviuosQuickSearches()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update, paginationParams])

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (areSearchesPending) getPreviuosQuickSearches()
        }, 5000);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [areSearchesPending])

    // get sos searches by applicant id
    const getPreviuosQuickSearches = () => {
        let params = { pagination: paginationParams }
        if (!isQuickSearch) params.applicantId = applicantId
        getPreviousSearches(params)()
            .then(res => {
                setPreviousQuickSearches(res)
                checkForPendingSearches(res)
                setLoading(false)
            })
    }

    const checkForPendingSearches = (res) => {
        const arePendings = res?.response?.some(search => search.status === 'Search requested, waiting for response...');
        setAreSearchesPending(arePendings)
    }

    return (
        <div className='cardStyle'>
            <div className='uccTitleContainer mt-3'><h5 >{sosStrings.SEARCH_HISTORY}</h5> </div>
            {loading ?
                <Loader />
                :
                (previousQuickSearchesData?.length > 0 ?
                    <>
                        <PreviousSearchesColumns />
                        {(previousQuickSearchesData.map((item) => (
                            <PreviousSearchCard
                                key={item.searchId}
                                previousSearch={item}
                                viewResult={viewResult}
                                search={search}
                                searchLoading={searchLoading}
                                applicantId={applicantId}
                                isQuickSearch={isQuickSearch}
                            />
                        )))}
                    </>
                    :
                    <Alert severity="warning">{sosStrings.NO_PREVIOUS_SEARCHES}</Alert>
                )
            }
            {previousQuickSearchesPagination?.totalRecords > 0 &&
                <SimplePagination
                    onChangePagination={setPaginationParams}
                    totalRecords={previousQuickSearchesPagination?.totalRecords}
                    totalPages={previousQuickSearchesPagination?.totalPages}
                />
            }
        </div >
    )
}

export default PreviousSearches