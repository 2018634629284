import React from 'react'
import strings from 'strings'
import { Col, Row } from "react-bootstrap"
import { mapBusinessInformation } from 'constants/application'

const sosStrings = strings.APPLICANT.SOS

const BusinessInformation = (props) => {

    const { data } = props

    return (
        <div className='cardStyle mb-3'>
            <h5 className='mb-3'>{sosStrings.BUSINESS_INFORMATION}</h5>
                {
                    mapBusinessInformation(data).map((item, index) => {
                        return (
                                <Row key={index} >
                                    <Col>
                                        <p className='fieldValue'>
                                            {item.title}
                                        </p>
                                    </Col>
                                    <Col className='text-right'> 
                                        {item.value()}
                                    </Col>
                                </Row>
                        )
                    })
                }
        </div>
    )
}

export default BusinessInformation