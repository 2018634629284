import React from 'react'
import strings from 'strings'

const sosStrings = strings.APPLICANT.SOS

const SavedRecordsColumns = () => {
    return (
        <div className='savedRecordGrid mb-3 pl-3 pr-3'>
            <div className='bold-font'> {strings.TITLE} </div>
            <div className='bold-font'> {sosStrings.FILING_DATE} </div>
            <div className='bold-font'> {sosStrings.STATUS} </div>
            <div className='bold-font'> {sosStrings.SOS_REGISTRATION} </div>
        </div>
    )
}

export default SavedRecordsColumns