import React, { useState, useEffect } from 'react'
import {
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core'
import Button from 'components/shared/Button'
import { useSelector } from 'react-redux'
import { DropdownField, InputField } from 'components/forms/reduxForm'
import { Dialog } from 'components/shared/Dialog'
import { countryOptions, statesOptions } from "constants/constantVariables"
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import strings from 'strings'
import { getOwnerFormName } from 'constants/utilityFunctions'
import {
    maxLength9,
    minLength9,
    phoneNumberValidation,
    validateEmail
} from 'tools/forms/validators'
import './owner.scss'

const OwnerModal = (props) => {
    const {
        open,
        onClose,
        onSubmit,
        modalMode,
        change,
        dirty,
        loading
    } = props

    const formName = getOwnerFormName(modalMode);
    const countryCode = useSelector(state => state?.form?.editForm?.values?.address?.countryCode);

    const [stateOptions, setStateOptions] = useState(statesOptions.USA);

    const handleCountryChange = (selectedCountry) => {
        change(`address.state`, '')
        setStateOptions(statesOptions[selectedCountry]);
    };

    // eslint-disable-next-line
    useEffect(() => {
        setStateOptions(statesOptions[countryCode]);
    })

    return (
        <>
            {formName &&
                <Dialog
                    open={open}
                    fullWidth={true}
                    maxWidth='md'
                >
                    <DialogTitle>
                        {formName}
                    </DialogTitle>
                    <DialogContent>
                        <Row>
                            <Col>
                                <InputField
                                    name='firstName'
                                    label='First Name*'
                                    required
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='lastName'
                                    label='Last Name*'
                                    required
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='identifications[0].identification'
                                    label='SSN'
                                    validate={[minLength9, maxLength9]}
                                    otherProps={{ maxLength: 9 }}
                                    onFocus={event => event.target.select()}
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='dateOfBirth'
                                    label='DOB'
                                    type='date'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    otherProps={{ max: moment().format("YYYY-MM-DD") }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputField
                                    name='address.address1'
                                    label='Address 1*'
                                    required
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='address.address2'
                                    label='Address 2'
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <DropdownField
                                    name='address.countryCode'
                                    label='Country*'
                                    options={countryOptions}
                                    onChange={handleCountryChange}
                                    isSearchable
                                    id='ownerModalCountry'
                                />
                            </Col>
                            <Col>
                                <DropdownField
                                    name='address.state'
                                    label='State'
                                    options={stateOptions}
                                    required
                                    isSearchable
                                    id='ownerModalState'
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='address.city'
                                    label='City*'
                                    required
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='address.zip5'
                                    label='Zipcode*'
                                    required
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='phones[0].number'
                                    label='Phone*'
                                    validate={phoneNumberValidation}
                                    required
                                    isphoneNumber
                                />
                            </Col>
                            <Col>
                                <InputField
                                    name='emails[0].email'
                                    label='Email*'
                                    validate={validateEmail}
                                    required
                                />
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color='primary'
                            onClick={onClose}
                        >
                            {strings.CANCEL}
                        </Button>
                        <Button
                            variant="contained"
                            color='primary'
                            onClick={onSubmit}
                            disabled={!dirty}
                            loading={loading}
                            style={{ minWidth: '140px' }}
                        >
                            {formName}
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}
export default OwnerModal