import React, {useEffect, useState} from 'react'
import { cloneDeep } from 'lodash'
import Button from 'components/shared/Button'
import VisibilityIcon from '@material-ui/icons/Visibility'
import FmcsaDrawer from '../Summary/Summaries/SummaryDrawers/FmcsaDrawer'
import { fmcsaDetailColumn } from 'constants/fmcsa'
import TableComponent from 'components/shared/TableComponent'
import { DownloadFMCSAReport } from 'components/PdfTemplates'
import styles from './fmcsa.module.scss'
import strings from 'strings'
import Loader from "../../../../shared/Loader";
import AuthService from 'tools/AuthService'
import {Alert} from "@material-ui/lab";
import Moment from "react-moment";
import { getFmcsaPdfInfo } from 'constants/utilityFunctions'

const FmcsaVerification = (props) => {
    const {
        getFmcsaDataByApplication,
        requestFmcsaDataByApplication,
        applicationId,
        uploadRoxFiles,
        dot,
        updateSummaryCard
    } = props
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(true)
    const [fmcsaSearchData, setFmcsaSearchData] = useState([])
    const [fmcsaExtraContent, setFmcsaExtraContent] = useState([])
    const [fmcsaRequestDate, setFmcsaRequestDate] = useState([])
    const fmcsaData = { content: { carrier: fmcsaSearchData }, extraContent: fmcsaExtraContent, retrievalDate: fmcsaRequestDate }
    const pdfInfo = getFmcsaPdfInfo(fmcsaSearchData, fmcsaExtraContent, fmcsaRequestDate)
    /*
     * Deep cloning for fmcsa columns
     * Adding action to columns
     */
    const columns = cloneDeep(fmcsaDetailColumn)
    const action = {
        formatter: (cell, row) => {
            return (
                <>
                    <VisibilityIcon color='primary' onClick={() => {
                        setShow(true);
                    }} />
                    {row?.dot &&
                        <DownloadFMCSAReport
                            data={pdfInfo}
                            onSubmit={uploadRoxFiles}
                            applicationId={applicationId}
                            mode='icon'
                        />
                    }
                </>

            )
        }
    }

    useEffect(() => {
        if (applicationId) {
            getFMCSAData()
        }
        // eslint-disable-next-line
    }, [applicationId])

    columns.map((item, index) => {
        if (item.dataField === 'action') {
            columns[index] = { ...item, ...action }
        }
        return item
    })

    /* Building Table Row Data with basic fmcsa Detail */
    const fmcsaDetailData = [
        {
            businessName: fmcsaSearchData?.legalName,
            address: fmcsaSearchData?.phyStreet,
            city: fmcsaSearchData?.phyCity,
            state: fmcsaSearchData?.phyState,
            zip: fmcsaSearchData?.phyZipcode,
            ein: fmcsaSearchData?.ein,
            mc: fmcsaExtraContent?.mcNumbers?.join(', '),
            dot: fmcsaSearchData?.dotNumber
        }
    ]

    const requestFMCSADataButton = () => {
        return (
        <div className={styles.fmcsaVerificationButton}>
                <Button
                    onClick={() => requestFMCSAData()}
                    variant='contained'
                    color='primary'>
                    {strings.REFRESH_DATA_FROM_FMCSA}
                </Button>
            </div>
        )
    }

    const requestFMCSAData = () => {
        setLoading(true)
        requestFmcsaDataByApplication(applicationId)
            .then(res => {
                setFmcsaSearchData(res?.content[0]?.carrier)
                setFmcsaExtraContent(res?.extraContent)
                setFmcsaRequestDate(res?.retrievalDate)
            })
            .finally(() => {
                setLoading(false)
                updateSummaryCard()
            })
    }

    const getFMCSAData = () => {
        if (loading){
            setLoading(true)
            getFmcsaDataByApplication(applicationId).then(res => {
                setFmcsaSearchData(res?.content?.carrier)
                setFmcsaExtraContent(res?.extraContent)
                setFmcsaRequestDate(res?.retrievalDate)}).finally(() => {
                    setLoading(false)
                }
            )
        }
    }

    const noDotWarning = <Alert severity="info">{strings.NO_DOT_WARNING}</Alert>;

    return (
        <div className={styles.fmcsaVerificationContainer}>
            <div>
                <h5>{strings.FMCSA_VERIFICATION}
                    {fmcsaRequestDate && !loading &&
                        <span className={styles.fmcsaRequestDateField}> {strings.LAST_DATA_RETRIEVAL} {
                            <Moment format="MMM DD YYYY">{fmcsaRequestDate}</Moment>}
                        </span>
                    }
                </h5>
            </div>
            {!AuthService.isReadOnlyUser() &&
                <div>
                    {!dot && noDotWarning}
                </div>
            }
            <div>
                {loading? <Loader/> :
                    <div>
                        <TableComponent
                            keyField='businessName'
                            columns={columns}
                            data={fmcsaDetailData}
                            showPagination={false}
                            className={styles.customTable}
                        />
                        <FmcsaDrawer
                            open={show}
                            onClose={() => setShow(false)}
                            applicantId={applicationId}
                            data={fmcsaData}
                        />
                    </div>
                }
                {!AuthService.isReadOnlyUser() &&
                    <div>
                        {dot && requestFMCSADataButton()}
                    </div>
                }
            </div>
        </div>
    )
}

export default FmcsaVerification