import React, { useEffect, useMemo, useState } from 'react'
import history from 'tools/history'
import { withRouter } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Drawer,
  List,
  CssBaseline,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { dispatchUccFilters } from 'actions/applicants'
import PrepareTooltip from 'components/shared/Tooltip'
import PopupMenu from 'components/shared/PopupMenu'
import Auth from 'tools/AuthService'
import strings from 'strings'
import './MenuStyles.scss'

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    'z-index': '1'
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'initial'
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'initial',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  }
}))


const MenuComponent = (props) => {
  const {
    location: { pathname },
    menuItems
  } = props
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [selectedItem, setSelectedItem] = useState('dashboard')
  const [popover, setPopover] = useState(null)
  const user = useSelector((state) => state.user.userDetails)
  const dispatch = useDispatch()
  let isfocussed = false

  useEffect(() => {
    if (pathname === '/create-applicant')
      setSelectedItem(menuItems?.find(ele => ele.id === 'applicants')?.id)
    else {
      const findItem = menuItems.find(ele => {
        return pathname.includes(ele.id) ? ele : null
      })
      if (findItem) {
        setSelectedItem(findItem.id)
      }
    }
    setOpen(pathname.includes('applicant') ? false : true)
  }, [pathname, menuItems])

  const handleDrawerOpen = () => {
    setOpen(!open)
  }

  const openText = () => {
    setTimeout(() => {
      return 'open-text'
    }, 5000)
  }

  const menuClickAction = (e, item) => {
    if (item.id === 'profile') {
      setPopover(e.currentTarget)
    } else if (item.id === 'help') {
      window.open(item.link, '_blank');
    }
    else {
      dispatch(dispatchUccFilters({}, ''))
      history.push(item.link, 'menu')
    }
  }

  const autoClose = {
    onMouseOver: () => isfocussed = true,
    onMouseLeave: () => {
      setTimeout(() => {
        if (!isfocussed) {
          setPopover(null)
          isfocussed = false
        }
      }, 800)
    },
    onMouseOut: () => isfocussed = false
  }

  // eslint-disable-next-line
  const menus = useMemo(() => {
    const parentItems = [], childItems = [];

    menuItems.length && menuItems.forEach((ele, index) => {
      const { id, label, icon } = ele
      let clickevent = { onClick: (e) => menuClickAction(e, ele) }
      if (id === "profile")
        clickevent = {
          onClick: () => {
            if (history.location.pathname !== '/profile')
              history.push(ele.link)
          }, onMouseEnter: (e) => menuClickAction(e, ele)
        }

      const node = (
        <div {...clickevent} key={index} className={clsx('rox-menu-item', selectedItem === id && 'selected', open ? 'wth-90' : `openMenuItem`)}>
          <ListItem >
            <ListItemIcon>
              <PrepareTooltip title={ele.label}>
                <img className={`${!open ? 'minimize-menu-icon' : 'maximize-menu-icon'}`} src={require(`styles/images/menuIcons/${icon}.svg`)} alt='menu icons' />
              </PrepareTooltip>
            </ListItemIcon>
            <ListItemText id={id} primary={id === "profile" && user.firstName ? `${user.firstName} ${user.lastName}` : label} className={`menu-title ${open ? openText : 'close-text'}`} />
          </ListItem>
        </div>
      )

      if (ele.type === 'parent')
        parentItems.push(node)
      else childItems.push(node)
    })

    return { parentItems, childItems }
  })

  return (
    <div className={'rox-menu-container'}>
      <div className={`${classes.root}`} >
        <CssBaseline />
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >

          <div className={'rox-menu-item-container'}>
            <div className={'rox-menu-header'}>
              <img className={`collapse-rox-icon`} onClick={handleDrawerOpen} src={require(`styles/images/menuIcons/${open ? 'logo' : 'iconLogo'}.svg`)} alt='menu-icons' />
            </div>
            <List className='d-flex flex-column justify-content-between'>
              <div>
                {menus.parentItems}
              </div>
              <div>
                {menus.childItems}
              </div>
            </List>
          </div>
        </Drawer>
      </div>
      <PopupMenu
        open={popover}
        handleClose={() => setPopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div {...autoClose} >
          <div className='popup-container' onClick={() => {
            setPopover(null)
            if (history.location.pathname !== '/profile')
              history.push('/profile')
          }}>
            <div className='popperHead'>{strings.PROFILE}</div>
            <div className="poperButton" role='button'>
              {user.firstName ? `${user.firstName} ${user.lastName}` : strings.PROFILE_REDIRECT}
            </div>
          </div>


          <div className='seperator' />

          <div className='popup-container' style={{ cursor: "default" }}>
            <div className='popperHead'>{strings.CUSTOMER}</div>
            <div className="poperButton">
              {user.securityContext.client.clientName}
            </div>
          </div>

          <div className='seperator' />

          <div className='popup-container' onClick={() => Auth.logout()}>
            <div className="poperButton" role='button'>
              {strings.LOG_OUT}
            </div>
          </div>
        </div>
      </PopupMenu>
    </div>
  )
}

export default React.memo(withRouter(MenuComponent))
