import React, {useEffect, useRef, useState} from 'react'
import {
    DialogTitle,
    DialogContent, Button, CircularProgress
} from '@material-ui/core'
import { Dialog } from 'components/shared/Dialog'
import PdfViewer from "components/pdf/PdfViewer";
import styles from './pdfModal.module.scss'
import CloseIcon from '@material-ui/icons/Close';
import strings from 'strings'
import {downloadFileByPayload, getAttachmentPDF} from "../../../../../actions/applicants";

const Pdf = (props) => {
    const {
        open,
        onClose,
        pdf
    } = props

    const [loading, setLoading] = useState(false)
    const [pdfFile, setPdfFile] = useState();

    const payloadAttachmentDownload = {
        applicationId: pdf.applicationId,
        fileId: pdf.uuid,
        fileName: pdf.fileName
    }

    const payloadAttachmentDownloadRef = useRef(payloadAttachmentDownload)

    useEffect(() => {
        if (pdf && pdf.uuid) {
            setLoading(true)
            getAttachmentPDF({fileId: pdf.uuid, applicationId: pdf.applicationId})()
                .then(blob => {
                    setPdfFile(blob)
                    setLoading(false)
                })
        }
        }, [pdf, payloadAttachmentDownloadRef])

    return (

        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='lg'
            onClose={onClose}
        >

            <div>
                <div className={styles.drawerTitle}>

                    <div className={styles.titleContainer}>
                        <DialogTitle>{strings.DOCUMENT_VIEW}</DialogTitle>
                    </div>

                    <div className={styles.actionContainer}>
                        <Button color='primary' variant='contained' onClick={() => downloadFileByPayload(payloadAttachmentDownload)()} className='mr-2'>
                            {strings.DOWNLOAD_PDF}
                        </Button>
                        <CloseIcon onClick={onClose} />
                    </div>

                </div>

                <DialogContent>
                    {loading ?
                        <div className={styles.loading}><CircularProgress size={24} color='inherit' /></div>
                        :
                        <PdfViewer pdf={pdfFile} />
                    }
                </DialogContent>
            </div>
        </Dialog >
    )
}

export default Pdf