import React from 'react'
import { Button, CircularProgress, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'
import { Icon } from 'styles';
import strings from 'strings';


const useStyles = makeStyles(() => ({
    paper: {
        borderRadius: "13px"
    }
}));

const icons = {
    'delete': 'confirmDelete.svg',
    'warning': 'warningAlert.svg',
    'confirm': 'confirmAlert.svg',
    'info': 'infoAlert.svg',
    termination: 'confirmAlert.svg'
}
export function Dialog(props) {
    const {
        children,
        open,
        fullWidth,
        maxWidth,
        onClose,
        type,
        alertTiltle,
        onSubmit,
        isloading,
        contentMessage, // the message could be a string or strings separated by \n
        confirmButton = {}
    } = props
    const classes = useStyles();

    const alert = (
        <>
            <DialogTitle>
                {alertTiltle}
            </DialogTitle>
            <DialogContent>
                <div className='text-center'>
                    <Icon folder='icons' fileName={icons[type]} />
                    <div className='mt-2'>
                        {contentMessage ? (
                            contentMessage.split('\n').map((string, index) => (
                                <div key={index}>{string}</div>
                            ))
                        ) : (
                            strings.APPLICANT.CLIENTS.ALERT_HEADING
                        )}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}
                >
                    {strings.CANCEL}
                </Button>
                <Button
                    variant='contained'
                    color={confirmButton.color || 'primary'}
                    onClick={onSubmit}
                    disabled={isloading}
                >
                    {isloading ? <CircularProgress size={24} color='inherit' /> : confirmButton.text || strings.CONFIRM}
                </Button>
            </DialogActions>
        </>
    )

    return <MuiDialog
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        classes={{ paper: classes.paper }}
        onClose={!isloading && onClose}
    >
        {children}
        {type && alert}
    </MuiDialog>
}

Dialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs', false]),
    onSubmit: PropTypes.func
}

Dialog.defaultProps = {
    maxWidth: 'md',
    onClose: () => { },
    onSubmit: () => { }
}