import React from 'react';
import {Icon} from "../../../../styles";
import strings from "../../../../strings";

const t = strings.PLAID

const PlaidError = () => {
    return (
        <div className='text-center p-5 plaid-wrapper'>
            <Icon className='d-block mt-3' folder='icons' fileName='warningAlert.svg' />
            <h5 className='mt-3 mb-0'>{t.OAUTH_BAD_REQUEST}</h5>
            <h5 className='mt-3 mb-0'>{t.PLEASE_TRY_AGAIN}</h5>
        </div>)
}

export default PlaidError;