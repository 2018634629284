// isNaN says that "" is a number, but parseFloat returns NaN for ""
export const isNumber = val => !isNaN(parseFloat(val)) && !isNaN(val - 0)

export const parseNumber = (val, type = 'int') => {
  if (type !== 'int' && type !== 'float') {
    throw new Error('unsupported type to parse, type must be "int" or "float"')
  }
  if (!isNumber(val)) {
    return null
  }
  return type === 'int' ? parseInt(val, 10) : parseFloat(val, 10)
}

export const isObject = val => typeof val === 'object' && !!val

export const isEmpty = val => {
  if (typeof val === 'string') {
    val = val.trim()
  }
  if (Array.isArray(val)) {
    return val.length === 0
  }
  if (isObject(val)) {
    return Object.keys(val).length === 0
  }
  return val === '' || typeof val === 'undefined' || val === null
}

export const formatArrayOfCodeDescObjects = collection => {
  if (Array.isArray(collection)) {
    const objects = collection.map(object => {
      return `${object.code} - ${object.description}`
    })

    return objects.filter(o => !!o).join('\n')
  }
}

// convert all values in an object toString except null or undefined
// (doesn't work on nested objects)
export const valuesToString = obj => {
  if (!obj) return obj
  let result = {}
  Object.keys(obj).forEach(k => {
    if (obj[k] === null || typeof obj[k] === 'undefined') {
      result[k] = obj[k]
    } else {
      result[k] = String(obj[k])
    }
  })
  return result
}
