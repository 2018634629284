// env vars has to be in this format for the build tool to pick it up

//export const API_URL = "http://localhost:8080";
//export const API_URL = "https://api.demo.roxwrite.com";
export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://api.dev.roxwrite.com'
export const ROX_ENV = process.env.ROX_ENV ? process.env.ROX_ENV : 'TO-SET' 

export const ENV = getEnvVar('_{REACT_APP_ENV}_')

export const VERSION = JSON.stringify(new Date().toLocaleString())

/** When in develop mode, read env vars normally, when in production output a
 * special string that will be parsed by our CI
 * @param {string} envVar the env var name
 * @return {string} the env var value
 */
function getEnvVar(envVarStr) {
  // is true when running: npm run build
  const isProd = process.env.NODE_ENV === 'production'
  const envVar = envVarStr.replace('_{', '').replace('}_', '')
  const useEnvVars = !!process.env.REACT_APP_USE_ENV_VARS_ON_BUILD
  return isProd && !useEnvVars ? envVarStr : process.env[envVar]
}
