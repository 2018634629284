import React from 'react'
import Drawer from 'components/shared/Drawer';
import strings from 'strings'
import SosSearch from 'components/QuickSearch/SOS/SosSearch';

const sosStrings = strings.APPLICANT.SOS

const SosSearchDrawer = (props) => {

    const { open, onClose, requestDecision, applicantId } = props

    return (
        <Drawer
            open={open}
            title={sosStrings.SOS_SEARCH}
            onClose={onClose}
        >
            <SosSearch applicantId={applicantId} requestDecision={requestDecision} closeDrawer={onClose} />
        </Drawer>
    )
}

export default SosSearchDrawer