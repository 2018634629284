import React from 'react'
import Dropdown from 'components/forms/Dropdown'
import { statusOptions} from 'constants/constantVariables'
import './applicantDetail.scss'
import chroma from 'chroma-js';


const ApplicationStatusDropdown = (props) => {
    const {
        status,
        dispatchProcessing,
        updateApplicationStatus,
        applicantUUID
    } = props
    const disabledState = status === 'Declined'

    const updateStatus = (value) => {
        if (value) {
            dispatchProcessing(true)
            updateApplicationStatus(applicantUUID, value).finally(() => {
                dispatchProcessing(false)
            })
        }
    }

    const dot = (color = '#1f807d') => ({
        alignItems: 'center',
        display: 'flex',

        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });

    const colourStyles = {
        control: styles => ({ ...styles,
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 0,
            display: 'flex',
            width: '158px',
            borderRadius: '27px'}),
        option: (styles, { data, isDisabled, isFocused, isSelected}) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                        ? data.color
                        : isFocused
                            ? color.alpha(0.1).css()
                            : null,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? chroma.contrast(color, 'white') > 2
                            ? 'white'
                            : 'black'
                        : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
                },
            };
        },
        input: styles => ({ ...styles, ...dot() }),
        placeholder: styles => ({ ...styles, ...dot() }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    };


    return (<>
            <Dropdown
                    className={'application-status-dropdown'}
                    placeholder={status}
                    options={statusOptions}
                    onChange={(e) => updateStatus(e?.value)}
                    value={status}
                    isClearable={false}
                    disabled={disabledState}
                    propStyles={colourStyles}
                    customSelect={true}
            />
        </>
        )
}

export default ApplicationStatusDropdown