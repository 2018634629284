import React from "react";
import { Typography} from "@material-ui/core";
import strings from "../../../../../../../../strings";
import TableComponent from 'components/shared/TableComponent'
import {Col, Row} from "react-bootstrap";
import {AmendmentDetailsColumns, DebtorsColumn, FilingDetailsColumns} from 'constants/application'
import FilingDetailsContainerHeader from "./FilingDetailsContainerHeader";
import RoxAccordion from "../../../../../../../shared/RoxAccordion";

const FilingDetailsContainerAccordion = ({selectedFilings, item, handleReportSelect, nonSelectableRows}) => {

    function getEntityRow(name, data) {
        return <Row className="mb-3">
                <Typography className='bold-font'>{name}</Typography>
                <TableComponent
                    title={name}
                    columns={DebtorsColumn}
                    showPagination={false}
                    data={data || []}
                    keyField='Name'
                />
        </Row>;
    }

    return  (
            <RoxAccordion
                expanded={false}
                summary={<FilingDetailsContainerHeader item={item} />}
                details={<Col>
                            <Row className="mb-3">
                                <TableComponent
                                    keyField={'FilingNumber'}
                                    columns={FilingDetailsColumns}
                                    data={[item]}
                                    showPagination={false}
                                    selectRow={{
                                        mode: 'checkbox',
                                        hideSelectAll: true,
                                        onSelect: (row, isSelect) => handleReportSelect(row, isSelect),
                                        selected: selectedFilings || [],
                                        nonSelectable: nonSelectableRows
                                    }}
                                />
                            </Row>
                            {getEntityRow(strings.APPLICANT.UCC.DEBTORS, item?.Debtors)}
                            {getEntityRow(strings.APPLICANT.UCC.SECUREDS, item?.Secureds)}
                            <Row className="mb-3">
                                <Typography className='bold-font'>{strings.APPLICANT.UCC.AMENDMENTS}</Typography>
                                <TableComponent
                                    keyField='FilingNumber'
                                    title='Amendments'
                                    columns={AmendmentDetailsColumns}
                                    showPagination={false}
                                    data={item?.Amendments || []}
                                    selectRow={{
                                        mode: 'checkbox',
                                        hideSelectAll: true,
                                        onSelect: (row, isSelect) => handleReportSelect(row, isSelect),
                                        selected: selectedFilings || [],
                                        nonSelectable: nonSelectableRows
                                    }}
                                />
                            </Row>
                        </Col>}
                
                />
    )
}

export default FilingDetailsContainerAccordion;