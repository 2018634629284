import { connect } from 'react-redux'
import PlatformPartner from 'components/RoxAdmin/PlatformPartners'
import {
    getPlatformPartners,
    createPlatformPartner,
    togglePlatformPartner,
    dispatchPlatformPartnerFormValues,
    getAuthorizations,
    createAuthorization,
    toggleAuthorizations,
    resetPassword
} from 'actions/platfromPartner'
import { getRolesList } from 'actions/root'
import { getClientsList } from 'actions/client'

function mapStateToPlatformPartner(stateProps) {
    const { plaformPartnerList, plaformPartnerHeaders, formValues, authorizationList } = stateProps?.plaformPartner
    const { clients } = stateProps?.client
    const { headers, clientsList } = clients
    const userRoles = stateProps.root.rolesList.map(data => ({ label: data.name, value: data.systemName }))

    const totalRecords = plaformPartnerHeaders['x-pagination-total-records']
    const totalPages = plaformPartnerHeaders['x-pagination-total-pages']
    const currentPage = plaformPartnerHeaders['x-pagination-current-page-num']
    const clientsTotalRecords = headers['x-pagination-total-records']
    const clientsTotalPages = headers['x-pagination-total-pages']

    return {
        initialValues: formValues,
        userRoles,
        plaformPartnerList,
        totalRecords,
        totalPages,
        currentPage,
        clientsList,
        clientsTotalRecords,
        clientsTotalPages,
        authorizationList
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToPlatformPartner,
    {
        getPlatformPartners,
        createPlatformPartner,
        togglePlatformPartner,
        dispatchPlatformPartnerFormValues,
        getClientsList,
        getRolesList,
        createAuthorization,
        getAuthorizations,
        toggleAuthorizations,
        resetPassword
    },
    mergeProps
)(PlatformPartner)