import React, { useState } from "react";
import moment from 'moment'
import Button from 'components/shared/Button'
import { Toast } from "components/shared/Toaster"
import { PDFDownloadLink, BlobProvider, Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import strings from "strings";
import {uploadRoxFiles} from "../../../actions/applicants";

const bgvString = strings.APPLICANT.BGV

const styles = StyleSheet.create({
    page: {
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 30,
        paddingRight: 30
    },
    container: {
        backgroundColor: 'white',
    },
    imageContainer: {
        width: 100,
    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headLine3: {
        fontSize: 24,
        marginBottom: 5,
        textAlign: 'end',
        fontFamily: 'Helvetica'
    },
    headerLine5: {
        fontSize: 18,
        marginBottom: 5,
        fontFamily: 'Helvetica'
    },
    headerLine6: {
        fontSize: 16,
        fontFamily: 'Helvetica'
    },
    borderLineContainer: {
        fontSize: 18,
        flexDirection: 'row',
        borderBottomWidth: 2,
        borderBottomColor: '#112131',
        borderBottomStyle: 'solid',
        alignItems: 'stretch',
        fontFamily: 'Helvetica'
    },
    section1: {
        marginTop: 16,
        marginBottom: 16
    },
    resultText: {
        fontSize: 10,
        marginTop: 16,
        fontFamily: 'Helvetica'
    },

    // font sizes
    fs10: {
        fontSize: 10,
        fontFamily: 'Helvetica'
    },
    fs12: {
        fontSize: 11,
        fontWeight: 300,
        fontFamily: 'Helvetica'
    },
    fs14: {
        fontSize: 14,
        fontFamily: 'Helvetica'
    },

    //width
    w150: {
        width: 150
    },

    // bootstrap classes
    mb5: {
        marginBottom: 5 * 3
    },
    mt5: {
        marginTop: 5 * 3,
    },
    mt2: {
        marginTop: 2 * 3
    },
    ml6: {
        marginLeft: 6 * 3
    },
    mr1: {
        marginRight: 6
    },
    mt1: {
        marginTop: 10
    },
    mr4: {
        marginRight: 4 * 3
    },
    dFlex: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    dFlexStart: {
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    boldFont: {
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'Helvetica'
    },
    label: {
        width: 150,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    addressContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    textCenter: {
        textAlign: 'center'
    },
    textEnd: {
        textAlign: 'end'
    },
    endReportMargin: {
        marginTop: 60,
        marginBottom: 45
    }
});


const ComponentToPrint = (props) => {
    const { data, stateUccSearch } = props || {}
    const searchDate = moment().format('MM-DD-YYYY')
    return (
        <Document>
            <Page size="A4" style={styles.page} title="rox write ucc search report">
                <View>
                    <View style={styles.container}>
                        <Text style={[styles.fs10, styles.textEnd, styles.mb5]}>Search Date: {searchDate}</Text>
                        <View style={styles.headerContainer}>
                            <View>
                                <Image style={styles.imageContainer} src={`${window.location.origin}/roxLogo.jpg`} />
                            </View>
                            <View>
                                <Text style={styles.headerLine3}>SEARCH REPORT</Text>
                            </View>
                        </View>
                        <View style={styles.bodyContainer}>
                            <View style={styles.section1}>
                                <Text style={styles.borderLineContainer}>Results</Text>
                                <Text style={styles.resultText}>
                                    {`Based on a search of the indices of the Uniform Commercial Code Division of the Secretary of State of ${stateUccSearch}, there are no active liens of record other than those set out below. Liens reflected in this report were based on the searcher’s individual search parameters, the search key entered, as well as the searcher’s choice of the liens ultimately included or excluded herein. Certification can only be obtained through the office of the ${stateUccSearch} Secretary of State.`}
                                </Text>
                            </View>
                            <View>
                                {
                                    data && data?.map((d, index) => {
                                        return (
                                            <View style={[styles.mb5, styles.mt5]} key={index}>
                                                <Text style={styles.headerLine6}>{index + 1}. {d?.FilingType}</Text>
                                                <View style={[styles.dFlex, styles.mt1]}>
                                                    <View style={[styles.dFlex, styles.mr4]}>
                                                        <View style={styles.label}>
                                                            <Text style={[styles.boldFont, styles.mr1]}>Filing Number:</Text>
                                                        </View>
                                                        <Text style={styles.fs12}>{d.FilingNumber}</Text>
                                                    </View>
                                                    <View style={[styles.dFlex]}>
                                                        <Text style={[styles.boldFont, styles.mr1]}>Lapses:</Text>
                                                        <Text style={styles.fs12}>{moment(d?.LapseDate).format('MM-DD-YYYY')}</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.dFlex]}>
                                                    <View style={styles.label}>
                                                        <Text style={[styles.boldFont, styles.mr1]}>Filed:</Text>
                                                    </View>
                                                    <Text style={styles.fs12}>{moment(d?.FilingDate).format('MM-DD-YYYY')}</Text>
                                                </View>
                                                {
                                                    d?.Debtors?.length > 0 && d?.Debtors?.map((item, index) => (
                                                        <View style={[styles.dFlexStart, styles.mt1]} key={index} >
                                                            <View style={styles.label}>
                                                                <Text style={[styles.boldFont, styles.mr1]}>{item?.Type}:</Text>
                                                            </View>
                                                            <View style={styles.addressContainer}>
                                                                <Text style={[styles.fs12]}>{item?.Name}{"\n"}{item?.Address}{"\n"}{item?.City} {item?.State} {item?.Zip}</Text>
                                                            </View>
                                                        </View>
                                                    ))
                                                }
                                                {
                                                    d?.Secureds?.length > 0 && d?.Secureds?.map((item, index) => (
                                                        <View style={[styles.dFlexStart, styles.mt1]} key={index}>
                                                            <View style={styles.label}>
                                                                <Text style={[styles.boldFont, styles.mr1]}>{item?.Type}:</Text>
                                                            </View>
                                                            <View style={styles.addressContainer}>
                                                                <Text style={[styles.fs12]}>{item?.Name}{"\n"}{item?.Address}{"\n"}{item?.City} {item?.State} {item?.Zip}</Text>
                                                            </View>
                                                        </View>
                                                    ))
                                                }
                                                {
                                                    d?.Amendments?.length > 0 && d?.Amendments?.map((item, index) => (
                                                        <View style={[styles.mt1]} key={index}>
                                                            <View style={[styles.dFlex]}>
                                                                <View style={styles.label}>
                                                                    <Text style={[styles.boldFont, styles.mr1]}>Amendment Type:</Text>

                                                                </View>
                                                                <Text style={styles.fs12}>{item?.FilingType}</Text>
                                                            </View>
                                                            <View style={[styles.dFlex]}>
                                                                <View style={styles.label}>
                                                                    <Text style={[styles.boldFont, styles.mr1]}>Filing Number:</Text>

                                                                </View>
                                                                <Text style={styles.fs12}>{item?.FilingNumber}</Text>
                                                            </View>
                                                            <View style={[styles.dFlex]}>
                                                                <View style={styles.label}>
                                                                    <Text style={[styles.boldFont, styles.mr1]}>Filing Date:</Text>

                                                                </View>
                                                                <Text style={styles.fs12}>{moment(item?.FilingDate).format('MM-DD-YYYY hh:mm A')}</Text>
                                                            </View>
                                                        </View>
                                                    ))
                                                }
                                                <View>

                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                            <Text style={[styles.mt5, styles.fs10]}>
                                We assume no liability with respect to the identity of any party named or referred to in this report, nor with respect to the validity,
                                legal effect or priority of any matter shown herein; nor, due to our inability to independently verify the accuracy of this data as
                                provided by government and other sources, do we make any guaranty or representation as to its accuracy.
                            </Text>
                            <Text style={[styles.endReportMargin, styles.textCenter, styles.fs14]}>
                                ---------- END OF REPORT ----------
                            </Text>
                            <Text style={[styles.mt5, styles.borderLineContainer]}>Report Parameters</Text>
                            <Text style={[styles.mt4, styles.fs10, styles.mt5]}>
                                The UCC Revised Article 9 Model Administrative Rules (MARS) provide state filing offices with a set of guidelines for producing a
                                legally compliant UCC lien search report. The search tool used to create this search report was designed to satisfy the requirements
                                under MARS while providing the searcher with increased flexibility.
                            </Text>
                            <Text style={[styles.mt4, styles.fs10]}>
                                Flexible search logic generates a more inclusive search report and addresses the inconsistencies in searches performed within
                                states that did not effectively adopt the MARS guidelines. Further, these specially designed broad-based searching features aid in
                                the location of involuntary liens such as Federal and State Tax Liens and Judgment Liens and liens that may not be located in state
                                databases limited to the MARS guidelines for the reporting of UCCs.
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}

const SearchReport = (props) => {
    const { data, applicationId, interactionId, getFilingReportsAttachments, selectedEntity, stateUccSearch, type } = props
    const [attachLoader, setAttachLoader] = useState(false)
    const [file, setFile] = useState()

    const handleAttachment = (blob) => {
        setAttachLoader(true)
        const fd = new FormData();
        if (file){
            fd.append("file", file, 'rox-write-ucc-report.pdf')
        } else {
            setFile(blob)
            fd.append("file", blob, 'rox-write-ucc-report.pdf')
        }
        fd.append("purpose", 'ucc-search-report')
        fd.append("filingDetailsId", interactionId)
        fd.append("applicationId", applicationId)
        fd.append("roxFileType", "UCC_FILING_DETAILS_ROX_REPORT")
        uploadRoxFiles(fd)().then(() => {
            return getFilingReportsAttachments(applicationId, {}, {
                type: selectedEntity?.entityType,
                uuid: selectedEntity?.entityUuid
            })
        }).then((res) => {
            Toast({ type: "success", message: bgvString.ATTACH_TO_SAVED_REPORTS })
        }).finally(() => {
            setAttachLoader(false)
        })
    }

    return (
        <div className="d-flex align-items-center">
            <PDFDownloadLink document={<ComponentToPrint data={data} stateUccSearch={stateUccSearch} />} fileName="rox-write-ucc-report.pdf">
                {({ blob, url, loading, error }) =>
                    <div className='d-flex align-items-center'>
                        <Button loading={loading} onClick={() => setFile(blob)}>{strings.DOWNLOAD_REPORT}</Button>
                    </div>
                }
            </PDFDownloadLink>
            {type !== 'quick search' && <BlobProvider document={<ComponentToPrint data={data} stateUccSearch={stateUccSearch} />} fileName="rox-write-ucc-report.pdf">
                {({ blob, url, loading, error }) => {
                    return (
                        <Button variant="outlined" className="ml-2" loading={loading || attachLoader} disabled={!blob} onClick={() => handleAttachment(blob)}>{strings.ATTACH_REPORT}</Button>
                    )
                }}
            </BlobProvider>}
        </div>
    );
}

export default SearchReport;
