import request from "tools/request"
export const SET_CSV_LIST = 'SET_CSV_LIST'

const dispatchCsvList = (data, headers) => ({
    type: SET_CSV_LIST,
    data,
    headers
})

export const getCsvList = (pagination = {}) => async (dispatch) => {
    const res = await request(
        `ingestion-management/`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10
        }
    )
    dispatch(dispatchCsvList(res.response, res.headers))
    return res
}

export const submitFile = payLoad => async () => {
    const res = await request(`import/submit-file`, 'POST', payLoad, true, { 'Content-Type': 'multipart/form-data' })
    return res
}