import React from 'react';
import { Toast } from 'components/shared/Toaster'
import { getBackendUrl } from './url'
import Auth from './AuthService'
import history from './history'
import strings from 'strings'

const throwException = error => {
  if (error.status === 403) {
    history.push('/login')
  }

  if (!navigator.onLine) {
    Toast({ type: 'error', message: strings.LOGIN_STRING.CHECK_INTERNET_CONNECTION })
    return;
  }
  if (Array.isArray(error)) {
    const errMsg = error.reduce((accum, current) => accum + ' ' + current.message, ' ')
    Toast({ type: 'error', message: errMsg })
  }
  if(Array.isArray(error?.details)) {
    const errMsg = error.details.map(item => <p>{item}</p>)
    Toast({ type: 'error', message: <><h6>{error?.message}</h6> {errMsg}</>, autoClose: false })
  }
  else Toast({ type: 'error', message: error.message })
}

/**
 * Makes an HTTP request.
 *
 * @param {string} path - The URL path for the request.
 * @param {string} method - The HTTP method for the request (e.g. GET, POST, PUT, DELETE).
 * @param {object} body - The request body data.
 * @param {boolean} [withHeaders=false] - Whether to include response headers in the result.
 * @param {object} [headers={}] - Additional headers for the request.
 * @param {object} params - Query parameters for the request.
 * @returns {Promise} A promise that resolves with the parsed response or rejects with an error.
 */
const request = (
  path, // The URL path for the request
  method, // The HTTP method for the request (e.g. GET, POST, PUT, DELETE)
  body, // The request body data
  withHeaders = false, // Whether to include response headers in the result
  headers = {}, // Additional headers for the request
  params // Query parameters for the request
) => {
  return new Promise((resolve, reject) => {
    const responseHeaders = {}
    return baseRequest(path, method, body, headers, params, true)
      .then(response => {
        if (response.status >= 200 && response.status < 400) {
          return response
        }

        const error = new Error(response.statusText)
        error.response = response

        throw error
      })
      .then(response => {
        if (response.headers.entries) {
          for (var pair of response.headers.entries()) {
            responseHeaders[pair[0]] = pair[1]
          }
        }

        return response.status !== 204 && response.json()
      })
      .then(parsedResponse => {
        if (withHeaders) {
          resolve({
            response: parsedResponse,
            headers: responseHeaders
          })

          return
        }
        resolve(parsedResponse)
      })
      .catch(async err => {
        if (err.response) {
          try {
            if (err.response.status !== 404) {
              try {
                const parsedError = await err.response?.json()
                throwException(parsedError)
              } catch (error) {
                Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG})
              }
            } else {
              history.push('/404')
            }
            return reject(err.response)
          } catch (e) {
            Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG })
            // Will throw if the backend responed and the response has no data (responses like 404)
            return reject(e)
          }
        }
        // happens for timeout exceptions
        throwException([{ message: err }])
        reject()
      })
  })
}

export default request

let isFetching = false
/**
 * if the response is 401/403 will refresh the token, if refresh token fails will redirect to login
 * if refresh token succeed, then get that token and save it as the new
 * Authorization token that will be used in future requests
 * After that finishes recall the same request that returned 401/403 at the beginning with the new Authorization token
 * If that failed again, then skip log the user out
 */
const validateAuthResponse = async (
  res,
  path,
  method,
  body,
  headers,
  params,
  acceptJson,
  skipRefresh
) => {
  if (res.status !== 401 && res.status !== 403) {
    return res
  }
  // this is getting called after we got a new authentication token from the refresh token
  // and response is still returning 401/403
  if (skipRefresh) {
    Auth.logout()
    history.push('/login')
    return Promise.reject(res)
  }
  try {
    // avoid calling refresh api multiple times
    if (!isFetching) {
      isFetching = true
      await Auth.refreshToken()
      isFetching = false
    }
    return baseRequest(path, method, body, headers, params, acceptJson, true)
  } catch (e) {
    Auth.logout()
    history.push('/login')
    return Promise.reject(res)
  }
}

export const baseRequest = async (
  path,
  method,
  body,
  headers,
  params,
  acceptJson,
  skipRefresh
) => {
  if (Auth.isTokenExpired()) {
    try {
      await Auth.refreshToken()
    } catch (e) {
      Auth.logout()
      history.push('/login')
    }
  }

  const defaultHeaders = {
    Authorization: Auth.getToken(),
    'Auth-SystemKey': '1',
    'Auth-ApiKey': '!@#ROXCLAPP!@#',
    'x-client-Id': Auth.selectedClient,
  }

  const reqHeaders = {
    Accept: acceptJson ? 'application/json' : '*/*',
    'Content-Type': 'application/json',
    ...headers, ...defaultHeaders
  }

  const fileHeaders = {
    Accept: '*/*',
    ContentType: 'multipart/form-data',
    ...defaultHeaders
  }

  const isMultiPart = (headers && (headers['Content-Type'] || headers.ContentType)) === 'multipart/form-data'

  return fetch(getBackendUrl(path, params), {
    method: method || 'GET',
    headers: isMultiPart ? fileHeaders : reqHeaders,
    body: body ? isMultiPart ? body : JSON.stringify(body) : undefined
  }).then(res =>
    validateAuthResponse(
      res,
      path,
      method,
      body,
      headers,
      params,
      acceptJson,
      skipRefresh
    )
  )
}

/**
 * This is an ajax request function that expect/prompt a download file
 * it is expecting the backend to send a 'content-disposition' with this format:
 * "attachment; filename=fileName.type"
 * will extract the fileName and defaults the prompt to that, if its not
 * available will use the fallbackFileName instead
 * @param {string} path url to the backend
 * @param {(POST|PUT|GET|DELETE)} method the method type
 * @param {object} body the body that will be sent to the backend
 * @param {string} fallbackFileName the file name for the prompt incase it
 * wasn't sent by the backend
 */
export const downloadFile = (path, method, body, fallbackFileName) => {
  let fileName = '', status;
  return baseRequest(path, method, body)
    .then(res => {
      const disposition = res.headers.get('content-disposition')
      const matches = /filename=(.+)/.exec(disposition)
      fileName = matches != null && matches[1] ? matches[1] : null
      return res
    })
    .then(resp => {
      status = resp.status;
      return resp.blob();
    })
    .then(blob => {
      if (!blob.size) {
        throw new Error('Unexpected error when downloading. Please retry. If the issue persists, please contact Support.')
      }
      if (status === 404 || status === 500) return Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG })

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = fileName || fallbackFileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch(async err => {
      if (err.response) {
        try {
          if (err.response.status !== 404) {
            const parsedError = await err.response?.json()
            throwException(parsedError)
          } else {
            Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG })
          }
          throw new Error('Unexpected error when downloading. Please retry. If the issue persists, please contact Support.')
        } catch (e) {
          Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG })
          throw new Error('Unexpected error when downloading. Please retry. If the issue persists, please contact Support.')
        }
      }
      // happens for timeout exceptions
      throwException([{ message: err }])
    })
}

export const getPDF = (path) => {
  let status;
  return baseRequest(path, 'GET', null)
    .then(res => {
      status = res.status;
      return res.blob();
    })
    .then(blob => {
      if (!blob.size) {
        throw new Error('Unexpected error when getting the PDF. Please retry. If the issue persists, please contact Support.')
      }
      if (status === 404) return Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG })

      return blob;
    })
    .catch(async err => {
      if (err.response) {
        try {
          if (err.response.status !== 404) {
            const parsedError = await err.response?.json()
            throwException(parsedError)
          } else {
            Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG })
          }
          throw new Error('Unexpected error when getting the PDF. Please retry. If the issue persists, please contact Support.')
        } catch (e) {
          Toast({ type: 'error', message: strings.SOMETHING_WENT_WRONG })
          throw new Error('Unexpected error when getting the PDF. Please retry. If the issue persists, please contact Support.')
        }
      }
      // happens for timeout exceptions
      throwException([{ message: err }])
    })
}

