import React, { useState, useEffect, Fragment } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import './PdfViewer.scss'
import { Button } from '@material-ui/core'

const SCALE_VALUES = [0.9, 1.4, 1.65, 1.9, 2.15, 2.4];

export default function PdfViewer(props) {
    const { pdf } = props;

    const [numPages, setNumPages] = useState(null);
    const [currentScaleIndex, setCurrentScaleIndex] = useState(1);

    useEffect(() => { pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; });

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function onZoomClickHandler(isZoomIn) {
        setCurrentScaleIndex((prevState) => {
            let newScalePDF = isZoomIn ? prevState + 1 : prevState - 1;
            newScalePDF = newScalePDF < 0 ? 0 : newScalePDF >= SCALE_VALUES.length ? SCALE_VALUES.length - 1 : newScalePDF;
            return newScalePDF;
        });
    }

    return (

        <div className='documentPdf'>
            <p className='text-center mt-2'>Total Pages {numPages}</p>
            <Document
                className={'all-page-container'}
                file={pdf}
                options={{ workerSrc: '/pdf.worker.js' }}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(new Array(numPages), (el, index) => (
                    <Fragment key={`page_${index + 1}`}>
                        <Page
                            pageNumber={index + 1}
                            scale={SCALE_VALUES[currentScaleIndex]}
                        />
                        <p className='text-center mt-2'>Page {index + 1} of {numPages}</p>
                    </Fragment>
                ))}
            </Document>

            <div className='buttonsContainer'>
                <Button
                    color='primary'
                    onClick={() => onZoomClickHandler(true)}
                    variant='contained'
                    className='mr-2'
                    disabled={currentScaleIndex >= SCALE_VALUES.length - 1} 
                >
                    +
                </Button>
                <Button
                    color='primary'
                    onClick={() => onZoomClickHandler(false)}
                    variant='contained'
                    disabled={currentScaleIndex <= 0}
                >
                    -
                </Button>
            </div>
        </div>
    );
}