import React from 'react'
import PropTypes from 'prop-types'
import ErrorIcon from '@material-ui/icons/Error';

import styles from './ValidationError.module.scss'

const ValidationError = props => {
  const { error, switchIcon, className } = props

  const descriptionStyle = switchIcon ? styles.switchError : styles.inputError

  return error ? (
    <span className={`${styles.description} ${descriptionStyle} ${className}`}>
      <ErrorIcon />
      {error}
    </span>
  ) : null
}

ValidationError.propTypes = {
  error: PropTypes.string,
  switchIcon: PropTypes.bool,
  className: PropTypes.string,
  iconClass: PropTypes.string
}

ValidationError.defaultProps = {
  className: '',
  iconClass: ''
}

export default ValidationError
