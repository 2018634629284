import { connect } from 'react-redux'
import Applicants from 'components/Applicants/Applicants'
import {
    getApplicantsList,
    getFmcsaData,
    getFmcsaDataByDOT,
    archiveApplication,
    requestFmcsaDataByApplication
} from 'actions/applicants'

function mapStateToProps(stateProps) {
    const { globalAccountSelected } = stateProps?.root
    const { applications, fmcsaData, fmcsaParam } = stateProps?.applicant
    const hideFmcsa = stateProps?.user?.userDetails?.securityContext?.client?.hideFmcsa || false;
    const headers = applications.headers
    const pageLimit = headers['x-pagination-limi']
    const totalRecords = headers['x-pagination-total-records']
    const totalPages = headers['x-pagination-total-pages']
    return {
        ...stateProps,
        applicationList: applications.applicationList || [],
        globalAccountSelected,
        fmcsaData,
        fmcsaParam,
        pageLimit,
        totalRecords,
        totalPages,
        hideFmcsa
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        getApplicantsList, // Action to get the applicants list
        getFmcsaData, // action to get fmcsa data
        requestFmcsaDataByApplication, // action to get fmcsa data by applicationId
        getFmcsaDataByDOT, // action to get fmcsa data by dot
        archiveApplication // action to archive application
    },
    mergeProps
  )(Applicants)