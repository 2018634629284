import React, { Fragment, useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Chart from "react-apexcharts"
import { Button, CircularProgress, Grid } from "@material-ui/core"
import moment from "moment"
import VisibilityIcon from '@material-ui/icons/Visibility'
import ReactJson from 'react-json-view'
import {
    CreditCheckColumn,
    creditReportColumns,
    mapCompanyInformation,
    mapCurrentCreditScore,
    mapDataToTable,
    mapPreviousCreditScore
} from "constants/application"
import TableComponent from "components/shared/TableComponent"
import { Toast } from "components/shared/Toaster"
import Drawer from 'components/shared/Drawer'
import {
    creditHistoryAreaChart,
    creditRatingRadialChart,
    DBTLineChart,
    MonthlyBalanceChart
} from "constants/chartData"
import AuthService from "tools/AuthService"
import strings from "strings"
import '../UCC/ucc.scss'

export function CreditCheck(props) {
    const {
        getCreditList,
        applicationId,
        performCreditSearch,
        getCompanyReport,
        saveReport
    } = props
    const { searchResult = {}, reportResult = {}, creditList } = useSelector(state => state.applicant.creditCheck);
    const [selectedRow, setSelectedRow] = useState([])
    const [loading, setLoading] = useState({ list: false, search: false, report: false, save: false })
    const [open, setOpen] = useState(false)
    const [openReport, setOpenReport] = useState(false)
    const [nonSelectable, setNonSelectable] = useState([])
    const [list, setList] = useState([])
    const [enabledIds, setEnabledIds] = useState([])
    const [data, setData] = useState({})
    const {
        negativeInformation = {},
        additionalInformation = {},
        companyIdentification = {},
        companySummary = {},
        creditScore = {},
        paymentData = {},
    } = reportResult
    const { extendedPaymentData = {}, creditRatingHistory = [] } = additionalInformation
    const { currentCreditRating, previousCreditRating } = creditScore || {}
    const isReadOnlyUser = AuthService.isReadOnlyUser()

    const getList = useCallback(() => {
        setLoading(prev => ({ ...prev, list: true }))
        setSelectedRow([])
        getCreditList(applicationId).finally(() => {
            setLoading(prev => ({ ...prev, list: false }))
        })
    }, [getCreditList, applicationId])

    useEffect(() => {
        getList()
    }, [getList])

    useEffect(() => {
        let disableAll = false
        setNonSelectable(creditList?.map(item => {
            if (item.status === 'Hit & Completed' || disableAll) {
                disableAll = true
                return item.entityUUID
            }
            return undefined
        }))
    }, [creditList])

    useEffect(() => {
        if (searchResult?.response?.companies) {
            setList(searchResult.response.companies.map((result => {
                return { ...result, disabled: true }
            })));
        }
    }, [searchResult])

    useEffect(() => setList(prev => prev.map(l => {
        if (enabledIds.includes(l.id))
            return { ...l, disabled: false }
        return l
    })), [enabledIds])

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            let selected = []
            selected = [row.entityUUID]
            setSelectedRow(selected)
        }
    }

    const handleSearch = () => {
        setLoading(prev => ({ ...prev, search: true }))
        setEnabledIds([])
        performCreditSearch(applicationId).then((res) => {
            if (res) {
                setOpen(true)
            }
        }).finally(() => {
            setLoading(prev => ({ ...prev, search: false }))
        })
    }

    const handleCompanyReport = (row) => {
        if (row.creditReportUUID)
            setLoading(prev => ({ ...prev, list: true }))
        else
            setLoading(prev => ({ ...prev, report: true }))

        getCompanyReport(applicationId, row.id, row.creditReportUUID).then((res) => {
            setOpenReport(true)
            setData(res?.response?.report)
            setEnabledIds(prev => ([...prev, row.id]))
        }).finally(() => {
            setLoading(prev => ({ ...prev, report: false, list: false }))
        })
    }

    const handleSave = (row) => {
        setLoading(prev => ({ ...prev, save: true }))
        saveReport(applicationId, row.id).then(() => {
            Toast({ type: 'success', message: strings.SUCCESS });
            getList()
            setOpen(false);
            setOpenReport(false);
        }).finally(() => {
            setLoading(prev => ({ ...prev, save: false }));
        })
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        selected: selectedRow,
        onSelect: handleOnSelect,
        nonSelectable,
        hideSelectAll: true
    }

    const action = {
        formatter: (cell, row, rowIndex) => {
            if (row.status === 'Hit & Completed')
                return (
                    <VisibilityIcon color='primary' onClick={() => handleCompanyReport(row)} />
                )
        }
    }
    CreditCheckColumn.map((item, index) => {
        if (item.dataField === 'action') {
            CreditCheckColumn[index] = { ...item, ...action }
        }
        return item
    })


    const actionForCredit = {
        formatter: (cell, row, rowIndex) => {
            return <>
                <Button className="mr-2" variant="outlined" color="primary" size="small" onClick={() => handleCompanyReport(row)}>
                    {`${strings.APPLICANT.DETAIL_REPORT}`}
                </Button>
                <Button variant="contained" color="primary" size="small" disabled={row.disabled} onClick={() => handleSave(row)}>
                    {`${strings.Save}`}
                </Button>
            </>
        }
    }

    creditReportColumns.map((item, index) => {
        if (item.dataField === 'action') {
            creditReportColumns[index] = { ...item, ...actionForCredit }
        }
        return item
    })

    const getData = (source, x, y) =>
        source?.map(data => {
            const xvalue = (data[x] !== null && data[x] !== undefined) ? data[x] : null
            const yvalue = (data[y] !== null && data[y] !== undefined) ? data[y] : null
            return { x: xvalue && moment(xvalue).format('MMM YY'), y: yvalue || 0 }
        })


    return (
        <div className="uccContainer">
            {
                loading.list && (
                    <div className='tableLoader'>
                        <CircularProgress />
                    </div>
                )
            }
            <div className='uccTitleContainer'>
                <h5>{strings.APPLICANT.CREDIT_CHECK.CREDIT_CHECK}</h5>
            </div>
            <TableComponent
                keyField='entityUUID'
                columns={CreditCheckColumn}
                data={creditList?.filter(data => data?.entityType === 'BUSINESS')}
                showPagination={false}
                selectRow={!isReadOnlyUser ? selectRow : undefined}
            />
            <div className='filterContainer'>
                <div className='uccBtnContainer'>
                    {!isReadOnlyUser && <Button
                        variant='contained'
                        color='primary'
                        disabled={selectedRow?.length === 0 || loading.search}
                        onClick={handleSearch}
                    >
                        {
                            loading.search ? <CircularProgress size={24} color='inherit' /> : strings.SEARCH
                        }
                    </Button>}
                </div>
            </div>

            {searchResult.response &&
                <Drawer
                    open={open}
                    onClose={() => setOpen(false)}
                    title={`${strings.SEARCH} ${strings.DETAILS}`}
                >
                    <div className="box mb-5">
                        <TableComponent
                            keyField='id'
                            columns={creditReportColumns}
                            data={list}
                            showPagination={false}
                            className="customTable"
                            loading={loading.report}
                        />
                    </div>
                </Drawer>}

            {Object.entries(reportResult).length ?
                <Drawer
                    open={openReport}
                    onClose={() => setOpenReport(false)}
                    title='Detailed Report'
                >
                    <div className="pb-5 overflow-hidden">
                        <div className="box">
                            <h5 className='mb-4'>{strings.APPLICANT.CREDIT_CHECK.COMPANY_INFORMATION}</h5>
                            <Grid container spacing={2}>
                                {mapCompanyInformation({ ...companySummary, ...companyIdentification })
                                    .map(data =>
                                        <Fragment key={data.title}>
                                            <Grid item sm={3}>{data.title}</Grid>
                                            <Grid item sm={9}>{data.value}</Grid>
                                        </Fragment>
                                    )}
                            </Grid>
                        </div>

                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <div className="box mr-0 mt-4">
                                    <h5 className='mb-0'>{strings.APPLICANT.CREDIT_CHECK.CURRENT_CREDIT_SCORE}</h5>

                                    <Grid container spacing={2}>
                                        <Grid item sm={12}>
                                            <Chart
                                                type='radialBar'
                                                height='200px'
                                                options={creditRatingRadialChart(currentCreditRating?.providerDescription)}
                                                series={[currentCreditRating?.providerValue?.value]}
                                            />
                                        </Grid>

                                        {mapCurrentCreditScore(creditScore)
                                            .map(data =>
                                                <Fragment key={data.title}>
                                                    <Grid item sm={6}>{data.title}</Grid>
                                                    <Grid item sm={6}>{data.value}</Grid>
                                                </Fragment>
                                            )}
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item sm={6}>
                                <div className="box ml-0 mt-4">
                                    <h5 className='mb-0'>{strings.APPLICANT.CREDIT_CHECK.PREVIOUS_CREDIT_SCORE}</h5>

                                    <Grid container spacing={2}>
                                        <Grid item sm={12}>
                                            <Chart
                                                type='radialBar'
                                                height='200px'
                                                options={creditRatingRadialChart(previousCreditRating?.providerDescription)}
                                                series={[previousCreditRating?.providerValue?.value]}
                                            />
                                        </Grid>

                                        {mapPreviousCreditScore(creditScore)
                                            .map(data =>
                                                <Fragment key={data.title}>
                                                    <Grid item sm={6}>{data.title}</Grid>
                                                    <Grid item sm={6}>{data.value}</Grid>
                                                </Fragment>
                                            )}
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>

                        {mapDataToTable({ additionalInformation }, true).map((d, i) =>
                            <div className="box mt-4" key={d.title}>
                                <h5>{d.title}</h5>
                                <TableComponent
                                    keyField='safeNumber'
                                    columns={d.columns}
                                    data={d.data}
                                    className="customTable"
                                    useLocalPagination={d.data.length > 5}
                                    showPagination={d.data.length > 5}
                                    paginationLimit={5}
                                />
                            </div>
                        )}

                        <div className="box mt-4">
                            <h5 className='mb-0'>{strings.APPLICANT.CREDIT_CHECK.CREDIT_RATING_HISTORY}</h5>
                            <Chart
                                type='area'
                                height='300px'
                                options={creditHistoryAreaChart}
                                series={[
                                    {
                                        name: 'Company Value',
                                        data: getData(creditRatingHistory, 'date', 'companyValue')
                                    }, {
                                        name: 'Industry Value',
                                        data: getData(creditRatingHistory, 'date', 'industryValue')
                                    }
                                ]}
                            />
                        </div>

                        <div className="box mt-4">
                            <h5 className='mb-0'>{strings.APPLICANT.CREDIT_CHECK.MONTHLY_BALANCE_HISTORY}</h5>
                            <Chart
                                type='bar'
                                height='300px'
                                options={MonthlyBalanceChart}
                                series={[
                                    {
                                        name: 'Current Balance',
                                        data: getData(extendedPaymentData?.tpdMonthlyBalanceHistories, 'date', 'balanceCurrent')
                                    }, {
                                        name: 'Total balance',
                                        data: getData(extendedPaymentData?.tpdMonthlyBalanceHistories, 'date', 'totalBalance')
                                    }
                                ]}
                            />
                        </div>

                        <div className="box mt-4">
                            <h5 className='mb-0'>{strings.APPLICANT.CREDIT_CHECK.DBT_HISTORY}</h5>
                            <Chart
                                type='line'
                                height='300px'
                                options={DBTLineChart}
                                series={[
                                    {
                                        name: 'Industry DBT',
                                        data: getData(paymentData?.dbtHistory, 'date', 'industryDBT')
                                    }
                                ]}
                            />
                        </div>

                        {mapDataToTable({
                            negativeInformation,
                            additionalInformation,
                            paymentData,
                            extendedPaymentData,
                        }).map((d, i) =>
                            <div className="box mt-4" key={d.title}>
                                <h5>{d.title}</h5>
                                {
                                    d.title === strings.APPLICANT.CREDIT_CHECK.RAW_DATA ?
                                        <ReactJson
                                            src={data}
                                            name={false}
                                            collapsed={1}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                            enableClipboard={false}
                                        />
                                        :
                                        <TableComponent
                                            columns={d.columns}
                                            data={d.data}
                                            showPagination={false}
                                            className="customTable"
                                        />
                                }
                            </div>
                        )}

                    </div>
                </Drawer> : null}
        </div>
    )
}
