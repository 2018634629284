import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import { required as requiredValidation } from 'tools/forms/validators'
import { number } from 'tools/forms/parsers'

import Input from 'components/forms/Input'
import useGetErrorMessage from 'components/forms/reduxForm/useGetErrorMessage'

const InputFieldDetailed = ({ input, meta, ...rest }) => {
  const error = useGetErrorMessage(meta)
  return <Input name={input.name} error={error} {...rest} {...input} />
}

// this component does very minor things, so we don't have to repeat ourselves
// - return a react-redux <Field> and passing the detail component to it
// - when the user passes required as a prop also passes it to validate
// - when type is number add a parser so the value of the input would be a
// number
const InputField = ({
  required,
  requiredIcon,
  type,
  parse,
  validate = [],
  indicateRequired,
  label,
  ...rest
}) => {
  if (typeof validate === 'function') {
    validate = [validate]
  }

  // if the user passes required prop and he doesn't have required in the
  // validation then add it
  if (required && !validate.some(v => v === requiredValidation)) {
    validate.unshift(requiredValidation)
  }
  // this will make the value of the input a number not a string
  if (type === 'number') {
    parse = parse || number
  }

  const disableScroll = () => {
    document.addEventListener('mouseWheel', preventDefault, {
      passive: false
    })
    if (document.activeElement.type === 'number') {
      document.activeElement.blur()
    }
  }

  const preventDefault = e => {
    if (e.preventDefault) {
      e.preventDefault()
    }
    e.returnValue = false
  }

  let parsedLabel = label
  if (indicateRequired)
    parsedLabel += ' *'

  return (
    <Field
      required={requiredIcon || required}
      component={InputFieldDetailed}
      type={type}
      parse={parse}
      validate={validate}
      label={parsedLabel}
      {...rest}
      onWheel={disableScroll}
    />
  )
}

// This will accept same propTypes as 'InputFieldDetailed' and whatever you
// would pass to <Field> in redux-form
InputField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  prefixIcon: PropTypes.node,
  suffixIcon: PropTypes.node,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  requiredIcon: PropTypes.bool,
  shadowMode: PropTypes.bool,
  tooltip: PropTypes.object,
  inputProps: PropTypes.object,
  horizontalLabel: PropTypes.bool,
  hideValidationText: PropTypes.bool,
  className: PropTypes.string,
  // possibile width options for the input, can be passed as a prop
  inputWidth: PropTypes.oneOf(['227', '150']) //default is 227
}

export default InputField
