import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer, { DrawerAction } from 'components/shared/Drawer';
import { Tabs, Tab } from 'components/shared/Tabs';
import { SET_FORM_VALUES } from 'actions/applicants';
import { DropdownField } from 'components/forms/reduxForm'
import { Toast } from 'components/shared/Toaster';
import Loader from 'components/shared/Loader';
import { Col, Row } from "react-bootstrap"
import Button from 'components/shared/Button';
import { AutoCompleteField, CheckboxField } from 'components/forms/reduxForm';
import { Preview, RenderFields } from './Preview'
import { AlertDialog } from './AlertDialog';
import { Radio } from 'components/forms/Radio';
import { Attachments } from '../Tabs/Attachments';
import { maxLength2000, minLength10 } from 'tools/forms/validators';
import { actionButtonName, amendmentTitle, buttonName, ucc3DrawerTitle, uccOperationTabs, statesGroupedByCountry } from 'constants/constantVariables';
import { Alert } from '@material-ui/lab'
import strings from 'strings';
import "./UccEdit.scss"
import UccSecuredPartyChangeForm from "../Tabs/NewUCC/Components/UccAmendment/UccSecuredPartyChangeForm";

const clientString = strings.APPLICANT.CLIENTS
let loadOnlyOnFirstRender = true
export const UccOperations = memo((props) => {
    const {
        debtors = [],
        change,
        jurisdictionState,
        securedParties = [],
        collateral = {},
        uccFilingsData: { status, lapseDate },
        jurisdiction,
        jurisdictionId,
        handleSubmit,
        resetForm,
        filingNumber,
        performAmendmentOperation,
        performCollateralOperation,
        terminateUCC,
        continueUCC,
        loading,
        closeDrawer,
        refreshList,
        getJurisdictionList,
        getJurisdictionsSiblings,
        operationDrawer: { open, operation },
        getTemplates,
        sprs,
        // Attachments Props
        onSubmit,
        getFiles,
        tempFileList,
        // downLoadAttachment,
        tempFileTotalRecords,
        tempFileTotalPages,
    } = props
    const [appTabValue, setAppTabValue] = useState(0);
    const [fileUccThreeDrawer, setFileUccThreeDrawer] = useState({ open: false, title: '', type: '', action: '' })
    const [openAlert, setOpenAlert] = useState({ open: false, title: '', type: '' });
    const [selectedValue, setSelectedValue] = useState({ value: 0, error: '' });
    const [selectedFiles, setSelectedFiles] = useState([])
    const [selectedDebtorIndex, setSelectedDebtorIndex] = useState(0)
    const [isloading, setLoading] = useState(false)
    const [assignTo, setAssignTo] = useState('new')
    const [value, setValue] = useState('both');
    const [state, setState] = useState('state');
    const [jurisdictionOptions, setJurisdictionOptions] = useState();
    const [jurisdictionLoad, setJurisdictionLoad] = useState(false);
    const [jurisInactiveMsg, setJurisInactiveMsg] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const dispatch = useDispatch();
    const gs = useSelector(state => state)
    const { values: formValues, initial } = gs?.form?.ClientForm || {}
    const { filerReference, clientReference, securedPartyMasking } = formValues || {}
    const list = gs?.applicant?.ucc?.templateList?.map(d => ({ label: d.description, value: d.templateText }))

    useEffect(() => {
        if (loadOnlyOnFirstRender) {
            getTemplates()
            loadOnlyOnFirstRender = false
        }
    }, [getTemplates])

    useEffect(() => {
        if (fileUccThreeDrawer.open) {
            if (jurisdictionId) fillJurisdictionsField(jurisdictionId);
            setSelectedValue({ value: 0, error: '' })
            setSelectedFiles([])
            setAssignTo('new')

            if (collateral.collateralType)
                setValue(collateral.collateralType)
        }
        else setSelectedDebtorIndex(0)
        // eslint-disable-next-line
    }, [fileUccThreeDrawer]);

    useEffect(() => {
        if (open) {
            setSelectedValue({ value: 0, error: '' })
            setAppTabValue(0)
            resetForm()
            if (jurisdictionId) fillJurisdictionsField(jurisdictionId);
        }
        // eslint-disable-next-line
    }, [open, jurisdictionId])

    const fillJurisdictionsField = (jurisdictionId) => {
        setJurisdictionLoad(true);
        getJurisdictionsSiblings(jurisdictionId).then(res => {
            setJurisdictionOptions(res.map(({ name, id }) => ({ label: name, value: id })));
            //Select the country/state
            change('state', { label: res[0].stateName, value: res[0].state })
            //Check if the jurisdiction is active
            const juris = res.find(({ id }) => id === jurisdictionId);
            if (juris) {
                //Select the jurisdiction
                change('jurisdictionId', jurisdictionId);
            } else {
                setJurisInactiveMsg(`${strings.APPLICANT.UCCFILING.JURISDICTION_INACTIVE}(Current Jurisdiction: ${jurisdiction}) `)
                setShowAlert(true)
            }
        }).finally(() => {setJurisdictionLoad(false)})
    }

    const handleApplicationTabChange = (event, newValue) => {
        setAppTabValue(newValue)
    }

    const handleRadioChange = (value) => {
        setSelectedValue(value)
    }

    const getTitle = (type, action) => {
        if (type === 'debtor')
            return `${action} ${strings.DEBTOR}`
        if (type === 'securedParty')
            return `${action} ${strings.APPLICANT.UCCFILING.SECURED_PARTY}`
        return `${action} ${strings.COLLATERAL}`
    }

    const handleAdd = (title) => {
        const data = {}
        setFileUccThreeDrawer({ open: true, title, action: 'add', type: getData().type })
        if (['Add Debtor', 'Add Collateral'].includes(title)) {
            data['securedPartyMasking'] = data?.securedPartyMasking || sprs
        }
        dispatch({ type: SET_FORM_VALUES, data: data })
    }

    const handleEdit = (data, type, selectedIndex) => {
        if (selectedIndex) setSelectedDebtorIndex(selectedIndex)
        if (['collateral', 'debtor'].includes(type)) {
            data['securedPartyMasking'] = data?.securedPartyMasking || sprs
        }

        setFileUccThreeDrawer({ open: true, title: getTitle(type, 'Edit'), action: 'edit', type })
        dispatch({ type: SET_FORM_VALUES, data: data })
    }

    const handleAssign = () => {
        setFileUccThreeDrawer({ open: true, title: getTitle('collateral', 'Assign'), action: 'assign', type: 'collateral' })
        dispatch({ type: SET_FORM_VALUES, data: collateral })
    }

    const handleDelete = (data, type, selectedIndex) => {
        if (selectedIndex) setSelectedDebtorIndex(selectedIndex)
        setFileUccThreeDrawer({ open: true, title: getTitle(type, 'Delete'), action: 'delete', type })
        dispatch({ type: SET_FORM_VALUES, data })
    }

    const getData = () => {
        if (appTabValue === 0)
            return { data: debtors, type: 'debtor' }
        if (appTabValue === 1)
            return { data: collateral, type: 'collateral' }
        return { data: securedParties, type: 'securedParty' }
    }

    const handleContinuation = () => {
        setLoading(true)
        const payLoad = { filingNumber, filerReference, clientReference, securedPartyMasking, jurisdictionId: formValues.jurisdictionId, authorizingParty: securedParties[selectedValue.value] }
        continueUCC(payLoad).then(() => {
            Toast(({ type: 'success', message: clientString.SUCCESS_MSG }))
            refreshList()
        }).finally(() => {
            setLoading(false)
            setFileUccThreeDrawer({ open: false, title: '' })
            setOpenAlert({ open: false, title: '', type: '' })
            closeDrawer()
        })
    }

    const getActionType = () => {
        if (operation === 'termination')
            return {
                submitApi: terminateUCC
            }
        if (appTabValue === 0) {
            return {
                action: fileUccThreeDrawer.action === 'add' ? 'DebtorAdd' :
                    fileUccThreeDrawer.action === 'edit' ? 'DebtorChange' : 'DebtorDelete',
                submitApi: performAmendmentOperation
            }
        }
        if (appTabValue === 1) {
            return {
                action: fileUccThreeDrawer.action === 'add' ? 'CollateralAdd' :
                    fileUccThreeDrawer.action === 'edit' ? 'CollateralRestate' :
                        fileUccThreeDrawer.action === 'delete' ? 'CollateralDelete' :
                            'CollateralAssign',
                submitApi: performCollateralOperation
            }
        }
        if (appTabValue === 2) {
            return {
                action: fileUccThreeDrawer.action === 'add' ? 'SecuredPartyAdd' :
                    fileUccThreeDrawer.action === 'edit' ? 'SecuredPartyChange' : 'SecuredPartyDelete',
                submitApi: performAmendmentOperation
            }
        }
    }

    const isFormValid = () => {
        if (selectedValue.value === null) {
            setSelectedValue({ value: null, error: 'error' })
            return false
        }
        if (['attachments', 'both'].includes(value) && !selectedFiles.length && fileUccThreeDrawer.type === 'collateral' && fileUccThreeDrawer.action !== 'delete') {
            Toast({ type: 'warning', message: strings.APPLICANT.UCCFILING.MINIMUM_MSG })
            return false
        }

        const type = operation === 'amendment' ? `${fileUccThreeDrawer.action}Amendment` : operation
        
        const title = operation === 'termination' ? clientString.CONFIRM_TERMINATION :
            operation === 'continuation' ? clientString.CONFIRM_CONTINUATION :
                clientString.SUBMIT_AMENDMENT

        setOpenAlert({ open: true, title, type })
        return true
    }

    const submitData = (formData) => {
        if (operation === 'continuation')
            return handleContinuation()

        let fd = { ...formData }, key = 'affectedParty', assignees, currentParty, attachments
        const { data, address, organizationName, firstName, lastName, middleName, securedPartyMasking, jurisdictionId } = formData || {}
        const { submitApi, action } = getActionType()
        delete fd.filerReference
        delete fd.clientReference

        if (fileUccThreeDrawer.type === 'collateral') {
            if (value === 'text' || value === 'both') {
                fd = data.collateral
                key = 'collateral'
                if (value === 'both')
                    attachments = (fileUccThreeDrawer.action === 'delete' ? data.attachments : selectedFiles).map(d => d.uuid)
            }
            else if (value === 'attachments') {
                fd = (fileUccThreeDrawer.action === 'delete' ? data.attachments : selectedFiles).map(d => d.uuid)
                key = 'attachments'
            }

            //NOTE: If it is multiple debtor need to perform in loop
            if (fileUccThreeDrawer.action === 'assign')
                if (assignTo === 'new')
                    assignees = [{ address, organizationName, firstName, lastName, middleName }]
                else assignees = [debtors[selectedDebtorIndex]]
        }

        if (action === 'SecuredPartyChange' || action === 'DebtorChange') {
            currentParty = initial || formValues
            delete currentParty?.clientReference
            delete currentParty?.filerReference
        }
        const payLoad = {
            filerReference,
            clientReference,
            filingNumber,
            [key]: fd,
            authorizingParty: securedParties[selectedValue.value],
            action,
            assignees,
            currentParty,
            securedPartyMasking,
            jurisdictionId
        }
        if (value === 'both')
            payLoad.attachments = attachments

        setLoading(true)
        submitApi(payLoad)
            .then(() => {
                Toast(({ type: 'success', message: strings.SUCCESS }))
                refreshList()
            })
            .finally(() => {
                setLoading(false)
                setFileUccThreeDrawer({ open: false, title: '' })
                setOpenAlert({ open: false })
                closeDrawer()
            })
    }

    const handleStateByCountryChange = (optionSelected) => {
        setJurisdictionLoad(true);
        const countryCode = findCountryCode(optionSelected.label, optionSelected.value);
        const state = optionSelected.value;
        setState(state)
        getJurisdictionList({ countryCode, state }).then((res) =>
            setJurisdictionOptions(res.map(({ name, id }) => ({ label: name, value: id }))),
            change('jurisdictionId', '')
        ).finally(() => setJurisdictionLoad(false));
    };

    const warningJurisdictionInactive = () => (
        <Alert severity="info">
            {jurisInactiveMsg}
        </Alert>
    )

    const findCountryCode = (selectedLabel, selectedValue) => {
        const selectedOption = { label: selectedLabel, value: selectedValue };

        const selectedGroup = statesGroupedByCountry.find(group =>
            group.options.some(option =>
                option.label === selectedOption.label && option.value === selectedOption.value
            )
        );

        return selectedGroup ? selectedGroup.value : null;
    };

    const closeUccEditDrawerAction = () => {
        setFileUccThreeDrawer(prev => ({ ...prev, open: false }))
        closeDrawer()
    }

    const attachmentJsx = (
        <div className='mb-3'>
            <Attachments
                onSubmit={onSubmit}
                getFiles={getFiles}
                attachmentList={tempFileList}
                // need api to downlaod
                // downloadFile={downLoadAttachment}
                downloadFile={() => { }}
                totalPages={tempFileTotalPages}
                totalRecords={tempFileTotalRecords}
                forFiling
                files={(ids) => setSelectedFiles(ids)}
                showAddButton={false}
                selectionLimit={4}
            />
        </div>)

    const autoCompleteFieldJsx = (
        <div className='mb-3'>
            <AutoCompleteField
                name="data.collateral"
                freeSolo
                label={strings.APPLICANT.UCCFILING.LABEL.COLLATERAL_DESCRIPTION}
                options={fileUccThreeDrawer.action === 'add' ? list : []}
                getOptionLabel='value'
                renderOption={(option) => (<div>
                    <div className='optionHeading'>{option.label}</div>
                    <div className='optionValue'>{option.value}</div>
                </div>)}
                multiline
                rows={3}
                rowsMax={15}
                validate={[minLength10, maxLength2000]}
                required
            />
        </div>
    )

    return (
        <>
            <Drawer
                open={open}
                onClose={closeDrawer}
                title={<div className='ml-2'>
                    <h4 className='mb-0 d-block'>{ucc3DrawerTitle[operation]}</h4>
                    {filingNumber &&
                        <div>
                            <span>{strings.APPLICANT.UCCFILING.FILING_NUMBER} :  </span>
                            <span className='font-weight-bold'>{filingNumber}</span>
                        </div>}
                </div>}
            >
                {
                    loading ?
                        <Loader />
                        :
                        operation === 'termination' ?
                            <div className='p-4 pb-100'>
                                <Preview
                                    data={securedParties}
                                    type='securedParty'
                                    selectable
                                    hint
                                    expand
                                    selectedValue={selectedValue}
                                    setSelectedValue={handleRadioChange}
                                    handleEdit={handleEdit}
                                    handleDelete={handleDelete}
                                />
                                <RenderFields field='reference' formValues={formValues} change={change} />
                                <div className='hint'>{strings.APPLICANT.UCCFILING.CONFIRM_JURISDICTION}</div>
                                {showAlert && warningJurisdictionInactive()}
                                <Row>
                                    <Col>
                                        <DropdownField
                                            name="state"
                                            label={strings.LABEL.STATE}
                                            placeholder={`State`}
                                            menuPlacement='top'
                                            getFullValue
                                            options={statesGroupedByCountry}
                                            onChange={handleStateByCountryChange}
                                            isLoading={jurisdictionLoad}
                                            disabled={jurisdictionLoad}
                                            isSearchable
                                            required
                                        />
                                    </Col>
                                    <Col>
                                        <DropdownField
                                            name="jurisdictionId"
                                            label={strings.APPLICANT.UCCFILING.JURISDICTION}
                                            placeholder={`Jurisdiction for ${state}`}
                                            menuPlacement='top'
                                            options={jurisdictionOptions}
                                            isLoading={jurisdictionLoad}
                                            disabled={jurisdictionLoad}
                                            isSearchable
                                            required
                                        />
                                    </Col>
                                </Row>
                                <DrawerAction>
                                    <Button className='submit' onClick={() => isFormValid()} color='secondary' loading={loading.submitTermination}>
                                        {strings.APPLICANT.CLIENTS.SUBMIT_TERMINATION}
                                    </Button>
                                </DrawerAction>
                            </div>
                            : operation === 'continuation' ?
                                <div className='p-4 pb-100'>
                                    <Preview
                                        data={debtors.map(d => ({ ...d, filingNumber, status, lapseDate }))}
                                        type='continuation'
                                        expand
                                    />
                                    <RenderFields field='reference' formValues={formValues} change={change} />
                                    <div className='hint'>{strings.APPLICANT.UCCFILING.CONFIRM_JURISDICTION}</div>
                                    {showAlert && warningJurisdictionInactive()}
                                    <Row>
                                        <Col>
                                            <DropdownField
                                                name="state"
                                                label={strings.LABEL.STATE}
                                                placeholder={`State`}
                                                menuPlacement='top'
                                                getFullValue
                                                options={statesGroupedByCountry}
                                                onChange={handleStateByCountryChange}
                                                isLoading={jurisdictionLoad}
                                                disabled={jurisdictionLoad}
                                                isSearchable
                                                required
                                            />
                                        </Col>
                                        <Col>
                                            <DropdownField
                                                name="jurisdictionId"
                                                label={strings.APPLICANT.UCCFILING.JURISDICTION}
                                                placeholder={`Jurisdiction for ${state}`}
                                                menuPlacement='top'
                                                options={jurisdictionOptions}
                                                isLoading={jurisdictionLoad}
                                                disabled={jurisdictionLoad}
                                                isSearchable
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <CheckboxField
                                        name="securedPartyMasking"
                                        label='This filing is using the Secured Party Masking Service from FCS'
                                        className='custom-text'
                                    />
                                    <Preview
                                        data={securedParties}
                                        type='securedParty'
                                        selectable
                                        hint
                                        selectedValue={selectedValue}
                                        setSelectedValue={handleRadioChange}
                                    />
                                    <DrawerAction>
                                        <Button className='submit' onClick={() => isFormValid()} loading={loading.submitTermination}>
                                            {strings.APPLICANT.CLIENTS.SUBMIT_CONTINUAION}
                                        </Button>
                                    </DrawerAction>
                                </div>
                                :
                                <>
                                    <div className='pl-4 pr-4 pt-2 pb-100'>
                                        <Tabs
                                            data={uccOperationTabs}
                                            value={appTabValue}
                                            onChange={handleApplicationTabChange}
                                        />
                                        <Tab value={appTabValue} index={0} className='pt-3'>
                                            <Preview
                                                data={debtors}
                                                type='debtor'
                                                showActions
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                            />
                                        </Tab>
                                        <Tab value={appTabValue} index={1} className='pt-3'>
                                            <Preview
                                                data={Object.entries(collateral).length ? [collateral] : []}
                                                type='collateral'
                                                showActions
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                            />
                                        </Tab>
                                        <Tab value={appTabValue} index={2} className='pt-3'>
                                            <Preview
                                                data={securedParties}
                                                type='securedParty'
                                                showActions
                                                handleEdit={handleEdit}
                                                handleDelete={handleDelete}
                                            />
                                        </Tab>
                                    </div>

                                    <DrawerAction>
                                        {
                                            appTabValue === 1 &&
                                            <Button onClick={handleAssign}>
                                                {clientString.ASSIGN_COLLATERAL}
                                            </Button>
                                        }
                                        <Button className='ml-3' variant='outlined' onClick={() => handleAdd(actionButtonName[appTabValue])}>
                                            {actionButtonName[appTabValue]}
                                        </Button>
                                    </DrawerAction>
                                </>
                }
            </Drawer>

            <Drawer
                open={fileUccThreeDrawer.open}
                onClose={() => {
                    setFileUccThreeDrawer(prev => ({ ...prev, open: false }))
                    dispatch({ type: SET_FORM_VALUES, data: {} })
                }}
                title={<div className='ml-2'>
                    <h4 className='mb-0 d-block'>{fileUccThreeDrawer.title}</h4>
                    {filingNumber &&
                        <div>
                            <span>{strings.APPLICANT.UCCFILING.FILING_NUMBER} :  </span>
                            <span className='font-weight-bold'>{filingNumber}</span>
                        </div>}
                </div>}
            >
                <div className='editContainer pb-100'>
                    {
                        fileUccThreeDrawer.action === 'delete' ?
                            <>
                                <Preview data={[getData().data[selectedDebtorIndex]]} type={fileUccThreeDrawer.type} />
                                <RenderFields type={fileUccThreeDrawer.type} field='reference' formValues={formValues} change={change} />
                            </>
                            : fileUccThreeDrawer.type === 'collateral' ?
                                <>
                                    <div className='hint'>{amendmentTitle[collateral.collateralType]}</div>
                                    <Radio
                                        checked={value}
                                        value='text'
                                        label={strings.APPLICANT.UCCFILING.ONLY_TEXT}
                                        onChange={setValue}
                                    />
                                    <Radio
                                        checked={value}
                                        value='attachments'
                                        label={strings.APPLICANT.UCCFILING.ONLY_ATTACHMENTS}
                                        onChange={setValue}
                                    />
                                    <Radio
                                        checked={value}
                                        value='both'
                                        label={strings.APPLICANT.UCCFILING.TEXT_ATTACHMENTS}
                                        onChange={setValue}
                                    />
                                    {value === 'text' ? autoCompleteFieldJsx :
                                        value === 'attachments' ? attachmentJsx :
                                            <>{autoCompleteFieldJsx}{attachmentJsx}</>}
                                    {fileUccThreeDrawer.action === 'assign' ? <>
                                        <h5 className='d-inline mr-3'>{clientString.ASSIGN_TO}</h5>
                                        <Radio label={clientString.NEW_DEBTOR} value='new' checked={assignTo} onChange={setAssignTo} />
                                        <Radio label={clientString.EXISTING_DEBTOR} value='existing' checked={assignTo} onChange={setAssignTo} />

                                        {assignTo === 'new' ?
                                            <RenderFields type={fileUccThreeDrawer.type} field='all' formValues={formValues} details={fileUccThreeDrawer} change={change} /> :
                                            <>
                                                <Preview
                                                    data={debtors}
                                                    type='debtor'
                                                    selectable
                                                    isClearable={false}
                                                    selectedValue={{ value: selectedDebtorIndex }}
                                                    setSelectedValue={({ value }) => setSelectedDebtorIndex(value)}
                                                />
                                                <RenderFields type={fileUccThreeDrawer.type} field='reference' formValues={formValues} change={change} />
                                            </>}
                                    </>
                                        :
                                        <RenderFields type={fileUccThreeDrawer.type} field='reference' formValues={formValues} change={change} />}
                                </>
                                :
                            <>
                                {(fileUccThreeDrawer.type === 'securedParty' && fileUccThreeDrawer.action === 'edit') ?
                                    <UccSecuredPartyChangeForm data={securedParties} filingNumber={filingNumber}
                                                               selectedIndex={selectedDebtorIndex}
                                                               jurisdictionId={jurisdictionId}
                                                               jurisdictionState={jurisdictionState}
                                                               action={'SecuredPartyChange'}
                                                               afterSubmit={closeUccEditDrawerAction} // TODO define this function to only close the ucc edit drawer
                                    />
                                    :
                                <RenderFields type={fileUccThreeDrawer.type} field='all' formValues={formValues} details={fileUccThreeDrawer} change={change} />}
                            </>
                    }
                    {!(fileUccThreeDrawer.type === 'securedParty' && fileUccThreeDrawer.action === 'edit') &&
                        <>
                            <div className='hint'>{strings.APPLICANT.UCCFILING.CONFIRM_JURISDICTION}</div>
                            {showAlert && warningJurisdictionInactive()}
                            <Row>
                                <Col>
                                    <DropdownField
                                        name="state"
                                        label={strings.LABEL.STATE}
                                        placeholder={`State`}
                                        menuPlacement='top'
                                        getFullValue
                                        options={statesGroupedByCountry}
                                        onChange={handleStateByCountryChange}
                                        isLoading={jurisdictionLoad}
                                        disabled={jurisdictionLoad}
                                        isSearchable
                                        required
                                    />
                                </Col>
                                <Col>
                                    <DropdownField
                                        name="jurisdictionId"
                                        label={strings.APPLICANT.UCCFILING.JURISDICTION}
                                        placeholder={`Jurisdiction for ${state}`}
                                        menuPlacement='top'
                                        options={jurisdictionOptions}
                                        isLoading={jurisdictionLoad}
                                        disabled={jurisdictionLoad}
                                        isSearchable
                                        required
                                    />
                                </Col>
                            </Row>
                            <Preview
                                data={securedParties}
                                type='securedParty'
                                selectable
                                hint
                                selectedValue={selectedValue}
                                setSelectedValue={handleRadioChange}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                            />
                        </>
                    }
                </div>
                {!(fileUccThreeDrawer.type === 'securedParty' && fileUccThreeDrawer.action === 'edit') &&
                    <DrawerAction>
                        <Button onClick={handleSubmit(() => isFormValid())} className='submit'>
                            {buttonName[fileUccThreeDrawer.action] || strings.SUBMIT}
                        </Button>
                    </DrawerAction>
                }
            </Drawer>

            <AlertDialog
                openAlert={openAlert}
                onClose={() => setOpenAlert(prev => ({ ...prev, open: false }))}
                onSubmit={handleSubmit(formData => submitData(formData))}
                isloading={isloading}
            />
        </>
    )
})