import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Checkbox from 'components/forms/Checkbox'

const CheckBoxComponent = ({ input, meta, ...rest }) => {
  return <Checkbox name={input.name} checked={input.value ? true : false} {...rest} {...input} />
}

// this component does very minor things, so we don't have to repeat ourselves
// - return a react-redux <Field> and passing the checkbox component to it
const CheckboxField = ({
  required,
  label,
  ...rest
}) => {

  return (
    <Field
      component={CheckBoxComponent}
      label={label}
      {...rest}
    />
  )
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  onChange: PropTypes.func,
  isRelativePosition: PropTypes.bool // when the checkbox is beside form inputs
}

export default CheckboxField
