import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Moment from "react-moment"
import { capitalize, startCase } from 'lodash';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import strings from 'strings'
import { Status } from './chartData'
import PrepareTooltip from 'components/shared/Tooltip'
import { Icon } from 'styles';
import { changeTypeLabels } from './constantVariables';
import { formatTooltip, joinAttributes, translateRisk } from 'tools/formatters'
import { isScreenSmall } from 'tools/utils'
import {Risk} from 'constants/chartData'


const EMPTY_FIELD = '---'

const emptyFormatter = (cell) => {
    return (
        cell ? cell : EMPTY_FIELD
    )
}

const numberFormatter = (cell) => {
    return cell ? cell : 0
}

const upperCaseFormatter = (cell) => {
    return (
        cell ? cell.toUpperCase() : EMPTY_FIELD
    )
}

const safeColumns = (listOfColumns) => {

    return listOfColumns.map((col) => {
        const currentFormater = col.formatter
        col.formatter = (x) => safeDisplayCell(x, currentFormater);
        return col
    })
}

const safeDisplayCell = (cell, template) => {
    if (cell) {
        return template(cell);
    } else {
        return EMPTY_FIELD
    }
}

const safeDate = (dateToPrint) => {
    if (dateToPrint && (dateToPrint.Month || dateToPrint.Day || dateToPrint.Year)) {
        return <div>{dateToPrint.Month ? dateToPrint.Month + '/' : ''}{dateToPrint.Day ? dateToPrint.Day + "/" : ""}{dateToPrint.Year}</div>
    }
    return EMPTY_FIELD
}

const getDate = ({ Month, Day, Year }, pick = "all") => {
    let date = ''
    if (Month && (pick === 'all' || pick.includes('month'))) date += Month
    if (Day && (pick === 'all' || pick.includes('day'))) date += `/${Day}`
    if (Year && (pick === 'all' || pick.includes('year'))) date += `/${Year}`
    return date
}

const amountsFormatter = (amount) => {
    const style = amount < 0 ? 'negative-amount' : 'positive-amount'
    return <div className={style}>$ {amount}</div>
}

export const fcsDatesFormatter = (date) => {
    if (!date) {
        return '-'
    }
    return moment.utc(date).format('MM/DD/YYYY')
}

export const uccFilingDebtorNameFormatter = (cell, row) => {
    return <>
                {row.entityType === 'BUSINESS_DBA' &&
                    <Icon className={'dbaLogo'} folder='icons' fileName='DBA.svg'/>}
                <p title={cell}>{cell}</p>
            </>
}


export const uccStatusLabel = (cell) => {
    return <span className={`${cell?.labelColor}`}>
        {cell.status}
    </span>
}

// TODO need to standardize ALL the formaters into the amount of cases needed, why do we have some dates with MMM and others with MM/DD?
// Used for dates that comes as 2024-01-01T00:00:00Z and then the browser applies the timezone and displays the date wrong 
const momentOnlyDateFormatter = (date) => {
    return date ? <Moment format="MM/DD/YYYY" utc>{date}</Moment> : emptyFormatter()
}
const momentDateFormatter = (date) => {
    return date ? <Moment format="MM/DD/YYYY">{date}</Moment> : emptyFormatter()
}
const dateFormatter = (date) => {
    return date ? <Moment format="Do MMM YYYY">{date}</Moment> : emptyFormatter()
}

function fileTypeFormatter(fileType) {
    const fileTypeMap = {
        'application/pdf': 'PDF',
        'image/jpeg': 'JPEG Image',
        'image/png': 'PNG Image',
        'image/gif': 'GIF Image',
        'text/plain': 'Plain Text',
        'text/html': 'HTML Document',
        'application/msword': 'MS Word Document',
        'application/vnd.ms-excel': 'MS Excel Spreadsheet',
        'application/vnd.ms-powerpoint': 'MS PowerPoint Presentation',
        'application/zip': 'ZIP Archive',
        'application/x-rar-compressed': 'RAR Archive',
    };
    if (fileType in fileTypeMap) {
        return fileTypeMap[fileType];
    } else {
        return fileType;
    }
}

const statusFormatter = (cell, row) => {
    const status = row.status === 'Hit & Completed' || row.status === 'In-Progress' ? {
        name: row.status === 'In-Progress' ? 'In-Progress' : 'Completed',
        className: 'completed'
    } : {
        name: 'Not Started',
        className: 'notStarted'
    }
    return (
        <div className={`${status.className} tableStatus`}>{status.name}</div>
    )
}

const emptyColumnFormatter = (cell) => {
    return cell ? cell : '-';
}

const dataWithIndexes = (data) => {
    if (data && data.length) {
        return data.map((item, index) => ({ ...item, index }))
    }
    return data
}

const FmcsaColumns = [
    'Business Name',
    'DBA Name',
    'Address',
    'EIN',
    'DOT',
    'Status'
]

const getValue = key => key ? key : '-'

const getValueFromData = (data, field) => {
    if (data !== null && data !== undefined) {
        if (field === 'categories' && Array.isArray(data[field])) return data[field].join(', ')
        if (data[field] != null && data[field] !== undefined){
            return data[field]
        }else{
            return '-'
        }
    }
    return '-';
}

export function mapCompanyInformation(data) {
    return [
        {
            title: 'Name :',
            value: getValue(data?.basicInformation?.businessName)
        },
        {
            title: 'Address :',
            value: getValue(data?.basicInformation?.contactAddress.simpleValue)
        },
        {
            title: 'Country :',
            value: getValue(data?.basicInformation?.country)
        },
        {
            title: 'Status :',
            value: getValue(data?.basicInformation?.companyStatus?.status)
        },
        {
            title: 'Registration Date :',
            value: moment(getValue(data?.basicInformation?.companyRegistrationDate)).format("MMM Do YY")
        },
        {
            title: 'Classification :',
            value: getValue(data?.mainActivity?.classification)
        },
        {
            title: 'Description :',
            value: getValue(data?.mainActivity?.description)
        },
        {
            title: 'Industry Sector :',
            value: getValue(data?.mainActivity?.industrySector)
        },
        {
            title: 'Code :',
            value: getValue(data?.mainActivity?.code)
        },
    ]
}

export function mapCurrentCreditScore(data) {
    return [
        {
            title: 'Current Credit Limit :',
            value: `${getValue(data?.currentCreditRating?.creditLimit?.value)} ${getValue(data?.currentCreditRating?.creditLimit?.currency)}`
        },
        {
            title: 'Credit Value :',
            value: getValue(data?.currentCreditRating?.providerValue?.value)
        },
        {
            title: 'Latest Rating Change :',
            value: moment(getValue(data?.latestRatingChangeDate)).format("MMM Do YY")
        },
        {
            title: 'Risk :',
            value: getValue(data?.currentCreditRating?.commonDescription)
        },
    ]
}

export function mapPreviousCreditScore(data) {
    return [
        {
            title: 'Current Credit Limit :',
            value: `${getValue(data?.previousCreditRating?.creditLimit?.value)} ${getValue(data?.previousCreditRating?.creditLimit?.currency)}`
        },
        {
            title: 'Credit Value :',
            value: getValue(data?.previousCreditRating?.providerValue?.value)
        },
        {
            title: 'Latest Rating Change :',
            value: moment(getValue(data?.latestRatingChangeDate)).format("MMM Do YY")
        },
        {
            title: 'Risk :',
            value: getValue(data?.previousCreditRating?.commonDescription)
        },
    ]
}

function mapDataToTable(props, showOnlyBranch) {
    const {
        negativeInformation,
        additionalInformation,
        paymentData,
        extendedPaymentData,
    } = props

    const getData = (data) => {
        if (!data)
            return []
        if (typeof data === 'object' && !Object.entries(data).length)
            return []
        return data
    }

    if (showOnlyBranch)
        return [
            {
                title: strings.APPLICANT.CREDIT_CHECK.BRANCHES,
                columns: BranchOfficesColumn,
                data: additionalInformation?.branchOffices || []
            }
        ]

    return [
        {
            title: strings.APPLICANT.CREDIT_CHECK.PAYMENT_SUMMARY,
            columns: TradePaymentSummaryColumns,
            data: [paymentData?.tradePaymentSummary || {}]
        },
        {
            title: strings.APPLICANT.CREDIT_CHECK.FILING_SUMMARY,
            columns: FilingSummaryColumns,
            data: getData(negativeInformation)
        },
        {
            title: strings.APPLICANT.CREDIT_CHECK.TRADE_LINES_INFORMATION,
            columns: TradeLinesInformationColumn,
            data: [extendedPaymentData?.tpdTradeLinesInformation || {}]
        },
        {
            title: strings.APPLICANT.CREDIT_CHECK.TRADE_PAYMENTS_BY_CREDIT,
            columns: tradePaymentsByCreditExtended,
            data: getData(extendedPaymentData?.tradePaymentsByCreditExtended)
        },
        {
            title: strings.APPLICANT.CREDIT_CHECK.LIFE_TIME_HIGH_BALANCE,
            columns: LifetimeHighBalanceChartColumns,
            data: getData(extendedPaymentData?.lifetimeHighBalanceChart)
        },
        {
            title: strings.APPLICANT.CREDIT_CHECK.RAW_DATA
        }
    ]
}

export function mapBGVDebtor({ title, data = {}, entity }) {
    let value = '', subTitle = '', address = data.Address;

    if (data.CompanyName) {
        value = data.CompanyName
        subTitle = 'Company Name :'
    }
    else if (data.Name) {
        value = typeof data.Name === 'string' ? data.Name : `${data.Name?.First} ${data.Name?.Last}`
        subTitle = 'Name :'
    }

    if (entity === 'owner' && title !== strings.APPLICANT.BGV.ABOUT_CREDITOR) {
        value = data.OriginName
        subTitle = 'Origin Name :'
        address = data.Addresses?.Address[0]
    }

    const { StreetNumber, StreetName, StreetSuffix, City, State, Zip5, Zip4 } = address || {}

    if (title === strings.APPLICANT.BGV.ABOUT_DEBTOR || title === strings.APPLICANT.BGV.ABOUT_SECURED)
        return [
            {
                title: subTitle,
                value: getValue(value)
            },
            {
                title: 'Address :',
                value: address ?
                    `${StreetNumber || ''} ${StreetName || ''} ${StreetSuffix || ''},
                     ${City || ''}, ${State || ''} ${(Zip5 || Zip4) || ''}`
                    : '-'
            },
        ]

    return [
        {
            title: subTitle,
            value: getValue(value)
        }
    ]
}

export function mapParsedParty(party) {
    let value = `${party.Name?.First} ${party.Name?.Last}`, title = 'Name :';

    if (party.CompanyName) {
        value = party.CompanyName
        title = 'Company Name :'
    }

    const data = [{ title, value }]
    if (party.SSN)
        return [...data, {
            title: 'SSN :',
            value: party.SSN
        },]

    return data
}

export function mapCriminalDetail({ data: { Court = {}, Arrest = {}, OffenseTown } }) {
    return [
        {
            title: 'Case Number',
            value: getValue(Court?.CaseNumber)
        },
        {
            title: 'Offense Town',
            value: getValue(OffenseTown)
        },
        {
            title: 'Offense Date',
            value: Court?.OffenseDate ?
                `${Court.OffenseDate.Month}/${Court.OffenseDate.Day}/${Court.OffenseDate.Year}` : '-'
        },
        {
            title: 'Sentence Date',
            value: Court?.SentenceDate ?
                `${Court.SentenceDate.Month}/${Court.SentenceDate.Day}/${Court.SentenceDate.Year}` : '-'
        },
        {
            title: 'Description',
            value: getValue(Court?.Description)
        },
        {
            title: 'Disposition',
            value: getValue(Court?.Disposition)
        },
        {
            title: 'Disposition Date',
            value: Court?.DispositionDate ?
                `${Court.DispositionDate.Month}/${Court.DispositionDate.Day}/${Court.DispositionDate.Year}` : '-'
        },
        {
            title: 'Level',
            value: getValue(Court?.Level)
        },
        {
            title: 'Offense',
            value: getValue(Court?.Offense)
        },
        {
            title: 'Fine',
            value: getValue(Court?.Fine)
        },
        {
            title: 'Plea',
            value: getValue(Court?.Plea)
        },
        {
            title: 'Statute',
            value: getValue(Court?.Statute)
        },
        {
            title: 'Arrest Agency',
            value: getValue(Arrest?.Agency)
        },
    ]
}

function mapDataToBgvReportTable(props) {
    const {
        criminalRecords,
        bpsReportAddress,
        imposters,
        bankruptcies,
        uccFiling,
        info,
        liensJudgments,
        verification
    } = props

    return [
        {
            title: 'Personal Information',
            columns: infoColumns,
            data: dataWithIndexes(info)
        },
        {
            title: 'Verified Details',
            columns: verificationColumns,
            data: dataWithIndexes(verification)
        },
        {
            title: 'Criminal Records',
            columns: criminalRecordsColumns,
            data: dataWithIndexes(criminalRecords),
            scrollNeeded: true
        },
        {
            title: 'Liens and Judgements',
            columns: liensJudgmentsColumns,
            data: dataWithIndexes(liensJudgments),
            scrollNeeded: true
        },
        {
            title: 'UCC Filings',
            columns: uccFilingColumns,
            data: dataWithIndexes(uccFiling),
            scrollNeeded: true
        },
        {
            title: 'BPS Report Address',
            columns: bpsReportAddressColumns,
            data: dataWithIndexes(bpsReportAddress)
        },
        {
            title: 'Others Associated With Subjects SSN',
            columns: impostersColumns,
            data: dataWithIndexes(imposters),
            subtext: 'DOES NOT usually indicate any type of fraud or deception'
        },
        {
            title: 'Bankruptcies',
            columns: bankruptciesColumns,
            data: dataWithIndexes(bankruptcies)
        }
    ]
}

function mapBusinessDataToBgvReportTable(props) {
    const {
        buisnessDetails,
        otherCompanyTin,
        finance,
        industry,
        corpFiling,
        currentIndividuals,
        currentExecutives,
        priorExecutives,
        bankruptcy,
        buisnessLiensJudgements,
        ucc,
        connectedBusiness,
        exparian,
        buisnessVerification,
    } = props

    return [
        {
            title: 'Business Information',
            columns: businessInformationColumns,
            data: buisnessDetails
        },
        {
            title: 'Verifications',
            columns: buisnessVerificationColumns,
            data: buisnessVerification
        },
        {
            title: 'Company TIN',
            columns: otherCompanyTinColumns,
            data: otherCompanyTin
        },
        {
            title: 'Finance',
            columns: financeColumns,
            data: finance
        },
        {
            title: 'Industry',
            columns: industryColumns,
            data: industry
        },
        {
            title: 'Corporate Filing',
            columns: incorporationColumns,
            data: corpFiling
        },
        {
            title: 'Current Individuals',
            columns: currentIndividualsColumns,
            data: currentIndividuals
        },
        {
            title: 'Current Executives',
            columns: currentIndividualsColumns,
            data: currentExecutives
        },
        {
            title: 'Prior Executives',
            columns: priorExecutivesColumns,
            data: priorExecutives
        },
        {
            title: 'Bankruptcy',
            columns: bankruptcyColumns,
            data: bankruptcy
        },
        {
            title: 'Liens and Judgements',
            columns: buisnessLiensJudgmentsColumns,
            data: buisnessLiensJudgements,
            scrollNeeded: true
        },
        {
            title: 'UCC Filings',
            columns: buisnessUccFilingColumns,
            data: ucc,
            scrollNeeded: true
        },
        {
            title: 'Connected Business',
            columns: connectedBusinessColumns,
            data: connectedBusiness,
        },
        {
            title: 'Experian',
            columns: experianBusinessColumns,
            data: exparian
        },
        {
            title: 'Raw Data'
        }
    ]
}

export function mapVerificationdata(VerifiedInput = {}, VerificationIndicators = {}) {
    return [
        {
            title: 'Name',
            value: VerifiedInput.CompanyName || '-',
            verified: Boolean(Number(VerificationIndicators.CompanyName))
        },
        {
            title: 'FEIN',
            value: VerifiedInput.FEIN || '-',
            verified: Boolean(Number(VerificationIndicators.FEIN))
        },
        {
            title: 'Phone',
            value: VerifiedInput.Phone || '-',
            verified: Boolean(Number(VerificationIndicators.Phone))
        },
        {
            title: 'Address',
            value: VerifiedInput?.Address?.StreetAddress1 || '-',
            verified: Boolean(Number(VerificationIndicators.StreetAddress))
        },
        {
            title: 'City',
            value: VerifiedInput?.Address?.City || '-',
            verified: Boolean(Number(VerificationIndicators.City))
        },
        {
            title: 'State',
            value: VerifiedInput?.Address?.State || '-',
            verified: Boolean(Number(VerificationIndicators.State))
        },
        {
            title: 'Zip',
            value: VerifiedInput?.Address?.Zip5 || VerifiedInput?.Address?.Zip4 || '-',
            verified: Boolean(Number(VerificationIndicators.Zip))
        },
    ]
}

const ApplicantColumns = [
    {
        dataField: 'applicantName',
        text: 'Application Name',
        sort: true,
        headerStyle: {
            width: '10rem'
        },
        formatter: (cell, row) => {
            return (
                <Link className={'id-hyper-link'}
                    to={`/applicant/${row.uuid}`}
                >
                    {cell}
                </Link>
            )
        }
    },
    {
        dataField: 'createdBy',
        text: 'Created By',
        sort: true,
        formatter: (cell) => {
            return cell.name
        },
        headerStyle: {
            width: '9rem'
        },
    },
    {
        dataField: 'salesRepresentative',
        text: 'Sales Representative',
        sort: true,
        headerStyle: { width: '12rem' },
        formatter: (cell) => {
            if (cell)
                return `${cell.firstName} ${cell.lastName}`
            return emptyFormatter()
        }
    },
    {
        dataField: 'createdAt',
        text: 'Created At',
        sort: true,
        formatter: momentDateFormatter,
        headerStyle: {
            width: '8rem'
        },
    },
    {
        dataField: 'updatedAt',
        text: 'Updated At',
        sort: true,
        formatter: momentDateFormatter,
        headerStyle: {
            width: '8rem'
        },
    },
    {
        dataField: 'duration',
        headerStyle: {
            width: '7rem'
        },
        sort: false,
        text: 'Duration'
    },
    {
        dataField: 'status',
        text: 'Status',
        sort: true,
        headerStyle: {
            textAlign: 'center',
            width: '9rem'
        },
        checkboxDisable: true,
        formatter: (cell) => {
            const status = Status.find(st => st.status === cell)
            return <div className='table-label' style={{ background: status?.code }}>{status?.abbreviation}</div>
        }
    },
    {
        dataField: 'riskScore',
        text: 'Risk Score',
        sort: true,
        headerStyle: {
            textAlign: 'center',
            width: '9rem'
        },
        formatter: (cell) => {
            const risk = translateRisk(cell);
            const colorCodes= Risk.find(st => st.value === risk) || {};
            return (
                <div
                    className='table-label'
                    style={{
                        background: colorCodes.code || '#808080FF',
                        color: colorCodes.textCode || '#ffffff',
                        fontWeight: 'bold'
                    }}>
                    {risk}
                </div>
            )
        }
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden", width: '4rem' },
        sort: false,
        text: ''
    }
]

const UccSearchColums = [
    {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
            width: '15rem'
        },
    },
    {
        dataField: 'state',
        text: 'State',
        formatter: emptyFormatter,
        headerStyle: {
            width: '4rem'
        },
    },
    {
        dataField: 'date',
        text: 'Date',
        formatter: momentDateFormatter,
        headerStyle: {
            width: '8rem'
        },
    },
    {
        dataField: 'status',
        text: 'Status',
        headerStyle: {
            width: '10rem'
        },
        formatter: statusFormatter
    },
    {
        dataField: 'numberOfFilingsSaved',
        text: 'No of Filings',
        headerStyle: {
            width: '5rem',
        },
        formatter: (cell) => cell >= 0 ? <div className='noOfHits'>{cell}</div> : emptyFormatter()
    },
    {
        dataField: 'action',
        headerStyle: {
            width: '7rem',
            cursor: 'pointer'
        },
        text: ''
    }
]

const uccSearchTableColumns = [
    {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
            width: isScreenSmall ? '30%' : '25%'
        },
    },
    {
        dataField: 'state',
        text: 'State',
        formatter: emptyFormatter,
        headerStyle: {
            width: '10%'
        },
    },
    {
        dataField: 'date',
        text: 'Search Date',
        formatter: momentDateFormatter,
        headerStyle: {
            width: '15%'
        },
        hidden: isScreenSmall()
    },
    {
        dataField: 'lastSearchDate',
        text: 'Last Search Date',
        formatter: momentDateFormatter,
        headerStyle: {
            width: '15%'
        },
        hidden: isScreenSmall()
    },
    {
        dataField: 'numberOfFilingsSaved',
        text: 'Filings Attached',
        formatter: numberFormatter,
        headerStyle: {
            width: isScreenSmall ? '20%' : '15%'
        },
    },
    {
        dataField: 'statusLabel',
        text: 'Status',
        formatter: uccStatusLabel,
        headerStyle: {
            width: isScreenSmall ? '25%' : '20%'
        },
    },
    {
        dataField: 'action',
        text: 'Action',
        headerStyle: {
            width: '15%'
        },
    }
]

const uccFilingsTableColumns = [
    {
        dataField: 'debtorName',
        text: 'Name',
        formatter: uccFilingDebtorNameFormatter,
        headerStyle: {
            width: isScreenSmall ? '25%' :'20%'
        },
        style: { display: 'flex' }
    },
    {
        dataField: 'state',
        formatter: emptyColumnFormatter,
        text: 'State',
        headerStyle: {
            width: isScreenSmall ? '10%' : '5%'
        },
    },
    {
        dataField: 'address',
        formatter: emptyColumnFormatter,
        text: 'Address',
        headerStyle: {
            width: '15%'
        },
        hidden: isScreenSmall()
    },
    {
        dataField: 'filingNumber',
        formatter: emptyColumnFormatter,
        text: 'Filing Number',
        headerStyle: {
            width: isScreenSmall ? '20%' : '15%'
        }
    },
    {
        dataField: 'creationDate',
        text: 'Submission Date',
        formatter: fcsDatesFormatter,
        headerStyle: {
            width: isScreenSmall ? '20%' : '15%'
        }
    },
    {
        dataField: 'lapseDate',
        text: 'Lapse Date',
        formatter: fcsDatesFormatter,
        headerStyle: {
            width: '15%'
        },
        hidden: isScreenSmall()
    },
    {
        dataField: 'statusLabel',
        text: 'Status',
        formatter: uccStatusLabel,
        headerStyle: {
            width: isScreenSmall ? '20%' : '15%'
        },
    },
    {
        dataField: 'action',
        headerStyle: { 
            display: 'hidden',
            width: '4rem'
        },
        style: { textAlign: 'center' },
        text: ''
    }
]

const auditColums = [
    {
        dataField: 'user',
        text: 'User',
    },
    {
        dataField: 'action',
        text: 'Action',
    },
    {
        dataField: 'date',
        text: 'Date',
        formatter: fcsDatesFormatter
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: (cell, row) => {
            let statusClassName = ''
            //TODO: Add more cases
            switch (cell) {
                case 'Success':
                    statusClassName = 'greenLabel'
                    break;
                default:
                    statusClassName = 'redLabel'
                    break;
            }
            return (
                <div className={statusClassName}>{row?.message ? row.message : EMPTY_FIELD}</div>
            )
        }
    }
]

const uccSearchResultColumns = [
    {
        dataField: 'FilingNumber',
        text: 'Filing Number'
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type'
    },
    {
        dataField: 'State',
        text: 'State',
    }
]

const uccStatusformatter = {
    formatter: (cell, row) => {
        let status = {
            name: row.status,
            className: 'notStarted'
        }
        if (row.status) {
            if (row.status === 'Recorded')
                status = {
                    name: row.status,
                    className: 'completed'
                }
            else if (row.status === 'Unsuccessful' || row.status === 'Preparation')
                status = {
                    name: row.status,
                    className: 'unSuccess'
                }
            else if (row.status === 'Pending')
                status = {
                    name: row.status,
                    className: 'pending'
                }

            return (
                <div className={`${status.className} tableStatus`}>{status.name}</div>
            )
        }
        return emptyFormatter()
    }
}

const UccFilingColums = [
    {
        dataField: 'debtorName',
        text: 'Name',
        headerStyle: {
            minWidth: '10rem'
        },
        style: { display: 'flex' },
        formatter: (cell, row) => {
            return <>
                {
                    row.entityType === 'BUSINESS_DBA' &&
                    <PrepareTooltip
                        theme='white'
                        title='Doing Business As'
                        placement='top'
                    >
                        <Icon folder='icons' fileName='DBA.svg' className='mr-2' />
                    </PrepareTooltip>
                }
                <div style={row.entityType === 'BUSINESS_DBA' ? { marginTop: '1.5px' } : {}} title={cell}>{cell}</div>
            </>
        }
    },
    {
        dataField: 'address',
        text: 'Address',
        formatter: formatTooltip  
    },
    {
        dataField: 'state',
        text: 'State',
        headerStyle: {
            width: '4rem'
        },
        formatter: emptyFormatter
    },
    {
        dataField: 'filingNumber',
        text: 'Filing Number',
        formatter: emptyFormatter,
        hidden: isScreenSmall()
    },
    {
        dataField: 'creationDate',
        text: 'Submission Date',
        formatter: momentOnlyDateFormatter,
        hidden: isScreenSmall()
    },
    {
        dataField: 'lapseDate',
        text: 'Lapse Date',
        formatter: momentOnlyDateFormatter,
        hidden: isScreenSmall()
    },
    {
        dataField: 'status',
        text: 'Status',
        headerStyle: {
            width: '10rem'
        },
        formatter: uccStatusformatter.formatter
    },
    {
        dataField: 'action',
        headerStyle: { 
            display: 'hidden',
            width: '4rem'
        },
        style: { textAlign: 'center' },
        text: ''
    }
]

const DirectAmendmentColums = [
    {
        dataField: 'debtorName',
        text: 'Name',
        headerStyle: {
            minWidth: '15rem'
        },
    },
    {
        dataField: 'state',
        text: 'State',
        formatter: emptyFormatter
    },
    {
        dataField: 'jurisdiction',
        text: 'Jurisdiction',
        formatter: emptyFormatter
    },
    {
        dataField: 'creationDate',
        text: 'Created Date',
        formatter: momentOnlyDateFormatter
    },
    {
        dataField: 'status',
        text: 'Status',
        headerStyle: {
            width: '11rem'
        },
        formatter: uccStatusformatter.formatter
    },
    {
        dataField: 'action',
        headerStyle: { display: 'hidden' },
        style: { textAlign: 'center' },
        text: ''
    }
]

const AmendmentHistoryColumns = [
    {
        dataField: 'fileNumber',
        text: 'Filing Number',
        formatter: emptyFormatter
    },
    {
        dataField: 'amendmentAction',
        text: 'Amendment Action',
        formatter: (cell, { amendmentType }) => {
            if (cell && amendmentType) {
                const value = changeTypeLabels[cell.toLowerCase().includes('no') ? amendmentType : cell]
                return <div title={value}>{value}</div>
            }
            return emptyFormatter
        }
    },
    {
        dataField: 'fileDate',
        text: 'Filing Date',
        formatter: emptyFormatter
    },
    {
        dataField: 'uccFilingStatus',
        text: 'Filing Status',
        formatter: emptyFormatter
    },
    {
        dataField: 'action',
        text: '',
        headerStyle: { display: 'hidden' },
        style: { textAlign: 'center' }
    }
]

const FilingImageColumns = [
    {
        dataField: 'createdAt',
        text: 'Requested At',
        formatter: (cell) => {
            return dateFormatter(cell)
        },
        headerStyle: {
            width: '11rem'
        },
    },
    {
        dataField: 'fileType',
        text: 'File Type',
        formatter: (cell) => {
            return fileTypeFormatter(cell)
        },
        headerStyle: {
            width: '8rem'
        },
    },
    {
        dataField: 'purpose',
        text: 'Description',
        headerStyle: {
            width: '20rem'
        },
    },
    {
        dataField: 'action',
        headerStyle: { display: 'hidden' },
        style: { textAlign: 'center' },
        text: ''
    }
]

const UccSearchData = [
    {
        name: 'Pink Transportation',
        state: 'CA',
        county: 'Atlanta',
        date: '02-01-2022',
        status: 'Active',
        alert: '-',
        moreData: true
    },
    {
        name: 'Owner 1',
        state: 'CA',
        county: 'Atlanta',
        date: '02-01-2022',
        status: 'Active',
        alert: '-'
    },
    {
        name: 'Owner 2',
        state: 'CA',
        county: 'Atlanta',
        date: '02-01-2022',
        status: 'Active',
        alert: '-'
    }
]

const ApplicantFilters = [
    {
        id: 'name',
        type: 'text',
        label: 'Applicant Name',
        defaultValue: ''
    },
    {
        id: 'status',
        type: 'dropdown',
        label: 'Status',
        options: [
            { value: 'New', label: 'New' },
            { value: 'Under Review', label: 'Under Review' },
            { value: 'Pending', label: 'Pending' },
            { value: 'Approved', label: 'Approved' },
            { value: 'Declined', label: 'Declined' },
            { value: 'Cancelled', label: 'Cancelled' },
            { value: 'Cautionary Approval', label: 'Cautionary Approval' },
        ],
    }
    /** To be used once the API recieves this query param */
    // {
    //     id: 'dateApplied',
    //     type: 'dateRange',
    //     label: 'Date Applied',
]

const clientFilter = [
    {
        id: 'entityOrganization',
        type: 'text',
        label: 'Organization Name'
    },
    {
        id: 'entityFirstName',
        type: 'text',
        label: 'First Name'
    },
    {
        id: 'entityLastName',
        type: 'text',
        label: 'Last Name'
    },
    {
        id: 'entityMiddleName',
        type: 'text',
        label: 'Middle Name'
    }
    /** To be used once the API recieves this query param */
    // {
    //     id: 'jurisdictions',
    //     type: 'text',
    //     label: 'Jurisdiction',
    //     defaultValue: ''
    // },
]

const ownersObj = {
    firstName: '',
    lastName: '',
    ssn: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip5: '',
    number: '',
    email: '',
    dateOfBirth: moment(new Date()).format('YYYY-MM-DD')
}

const OwnerDefaultForm = {
    "address": {
        "addressType": "Home"
    },
    "emails": [
        {
            "emailType": "Work",
            "primary": true
        }
    ],
    "identifications": [
        {
            "identificationType": "SSN",
            "primary": true
        }
    ],
    "phones": [
        {
            "phoneType": "Work",
            "primary": true
        }
    ]
}

const DefaultApplicationForm = {
    "business": {
        "name": "",
        "businessType": "",
        "industryType": "",
        "mcNumber": "",
        "dotNumber": "",
        "website": "",
        "stateOfFormation": "",
        "addresses": [
            {
                "address1": "",
                "address2": "",
                "city": "",
                "state": "AL",
                "zip5": "",
                "addressType": "Work",
                "countryCode": "USA"
            },
        ],
        "identifications": [
            {
                "identification": "",
                "identificationType": "EIN",
                "primary": true
            }
        ],
        "emails": [
            {
                "email": "",
                "emailType": "Work",
                "primary": true
            }
        ],
        "phones": [
            {
                "number": "",
                "phoneType": "Work",
                "primary": true
            }
        ]
    },
    "owners": [
        {
            "firstName": "",
            "lastName": "",
            "address": {
                "address1": "",
                "address2": "",
                "city": "",
                "state": "AL",
                "zip5": "",
                "addressType": "Home",
                "countryCode": "USA"
            },
            "emails": [
                {
                    "email": "",
                    "emailType": "Work",
                    "primary": true
                }
            ],
            "identifications": [
                {
                    "identification": "",
                    "identificationType": "SSN",
                    "primary": true
                }
            ],
            "phones": [
                {
                    "number": "",
                    "phoneType": "Work",
                    "primary": true
                }
            ]
        }
    ],
    "debtor": {
        "name": "",
        "businessType": "",
        "industryType": "",
        "mcNumber": "",
        "dotNumber": "",
        "website": "",
        "addresses": [
            {
                "address1": "",
                "address2": "",
                "city": "",
                "state": "AL",
                "zip5": "",
                "addressType": "Work",
                "countryCode": "USA"
            }
        ],
        "emails": [
            {
                "email": "",
                "emailType": "Work",
                "primary": true
            }
        ],
        "identifications": [
            {
                "identification": "",
                "identificationType": "EIN",
                "primary": true
            }
        ],
        "phones": [
            {
                "number": "",
                "phoneType": "Work",
                "primary": true
            }
        ]
    },
    salesRepresentative: { uuid: '' },
    fmcsaInteractionResponseUuid: null,
    fmcsaAuthorityUuid: null,
    fmcsaBasicUuid: null,
    fmcsaCargoCarriedUuid: null,
    fmcsaMcNumberUuid: null,
    fmcsaOperationClassificationUuid: null
}

const DefaultAddress = {
    "address1": "",
    "address2": "",
    "city": "",
    "state": "AL",
    "zip5": "",
    "addressType": "Work",
    "countryCode": "USA"
}

const ReportColums = [
    {
        dataField: 'FilingNumber',
        text: 'Filing Number'
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type'
    },
    {
        dataField: 'action',
        headerStyle: {
            width: '6rem',
            cursor: 'pointer'
        },
        text: ''
    }
]

const FilingDetailsColumns = [
    {
        dataField: 'FilingNumber',
        text: 'Filing Number'
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type',
        style: { whiteSpace: 'initial' },
    },
    {
        dataField: 'FilingDate',
        text: 'Filling Date',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'NumberOfPages',
        text: 'No of Pages',
        style: { textAlign: 'center'},
        headerStyle: {textAlign: 'center' }
    },
    {
        dataField: 'docAction',
        text: 'Document',
        style: { textAlign: 'center' },
        headerStyle: { width: '6rem' }
    },
    {
        dataField: 'action',
        text: 'Action',
        style: { textAlign: 'center' },
        headerStyle: { width: '8rem' }
    }
]

const AmendmentDetailsColumns = [
    {
        dataField: 'FilingNumber',
        text: 'Filing Number'
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type',
        style: { whiteSpace: 'initial' },
    },
    {
        dataField: 'FilingDate',
        text: 'Filling Date',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'NumberOfPages',
        text: 'Number of Pages',
        style: { textAlign: 'center'}
    },
    {
        dataField: 'docAction',
        text: 'Document',
        style: { textAlign: 'center' },
        headerStyle: { width: '6rem' }
    }
]

const DetailColumn = [
    {
        dataField: 'FilingNumber',
        text: 'Filing Number'
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type',
        style: { whiteSpace: 'initial' },
    },
    {
        dataField: 'FilingDate',
        text: 'Filling Date',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'NumberOfPages',
        text: 'Number of Pages'
    },
    {
        dataField: 'documentStatus.status',
        text: 'Document Status',
        style: { whiteSpace: 'initial' },
        formatter: (cell, row) => {
            if (row?.NumberOfPages > 0) {
                return <div>{cell ? capitalize(startCase(cell)) : 'Ready to Request'}</div>
            } else return '-'
        }
    },
    {
        dataField: 'action',
        text: 'Action',
        headerStyle: { width: '6rem' },
    }
]

const ReceivedDocumentsColumn = [
    {
        dataField: 'filingNumber',
        text: 'Filing Number'
    },
    {
        dataField: 'filename',
        text: 'filename'
    },
    {
        dataField: 'action',
        text: 'Action'
    },
]

const DebtorsColumn = [
    {
        dataField: 'Name',
        text: 'Name',
        headerStyle: {
            width: '15rem'
        }
    },
    {
        dataField: 'Address',
        text: 'Address Line',
        headerStyle: {
            width: '17rem'
        }
    },
    {
        dataField: 'City',
        text: 'City',
        headerStyle: {
            width: '9rem'
        }
    },
    {
        dataField: 'State',
        text: 'State'
    },
    {
        dataField: 'Zip',
        text: 'Zip'
    }
]

const AttachmentsColumn = [
    {
        dataField: 'fileName',
        text: 'File Name',
    },
    {
        dataField: 'purpose',
        text: 'Note',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
    },
    {
        dataField: 'fileType',
        text: 'File Type',
    },
    {
        dataField: 'fileSize',
        text: 'File Size',
        formatter: cell => cell ? `${(cell / 1024 / 1024).toFixed(2)} MB` : emptyFormatter()
    },
    {
        dataField: 'createdBy.name',
        text: 'Created By',
    },
    {
        dataField: 'createdAt',
        text: 'Created At',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden" },
        text: ''
    }
]

const CreditCheckColumn = [
    {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
            minWidth: '15rem'
        },
        style: { display: 'flex' },
        formatter: (cell, row) => {
            return <>
                {
                    row.entityType === 'BUSINESS_DBA' &&
                    <PrepareTooltip
                        theme='white'
                        title='Doing Business As'
                        placement='top'
                    >
                        <Icon folder='icons' fileName='DBA.svg' className='mr-2' />
                    </PrepareTooltip>
                }
                <div style={row.entityType === 'BUSINESS_DBA' ? { marginTop: '1.5px' } : {}} title={cell}>{cell}</div>
            </>
        }
    },
    {
        dataField: 'state',
        text: 'State',
        formatter: emptyFormatter
    },
    {
        dataField: 'saveDate',
        text: 'Saved Date',
        formatter: momentDateFormatter
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: (cell) => cell ? cell === strings.STATUS_NAMES.Hit_Completed ? 'Completed' : cell : emptyFormatter()
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden" },
        text: ''
    }
]

const creditReportColumns = [
    {
        dataField: 'name',
        text: 'Name',
        headerStyle: {
            minWidth: '15rem'
        },
        style: {
            whiteSpace: 'initial'
        }
    },
    {
        dataField: 'address.simpleValue',
        text: 'address',
        headerStyle: {
            minWidth: '15rem'
        },
        style: {
            whiteSpace: 'initial'
        },
        formatter: emptyFormatter
    },
    {
        dataField: 'country',
        text: 'Country',
        headerStyle: {
            width: '4rem'
        },
        formatter: emptyFormatter
    },
    {
        dataField: 'action',
        headerStyle: { display: 'hidden' }
    }
]

const TradePaymentSummaryColumns = [
    {
        dataField: 'totalTradesNumber',
        text: 'Total Trades',
    },
    {
        dataField: 'totalTradesBalance',
        text: 'Balance',
    },
    {
        dataField: 'currency',
        text: 'Currency',
    },
    {
        dataField: 'continuousTradesNumber',
        text: 'Continuous Trades',
    },
    {
        dataField: 'recentHighCredit',
        text: 'Recent High Credit',
    },
]

const FilingSummaryColumns = [
    {
        dataField: 'type',
        text: 'Type',
        headerStyle: { width: '8rem' },
        style: { whiteSpace: 'initial' }
    },
    {
        dataField: 'bankruptcy',
        text: 'Bankruptcy',
    },
    {
        dataField: 'taxLienFilings',
        text: 'Tax Lien Filings',
    },
    {
        dataField: 'judgmentFilings',
        text: 'Judgment Filings',
    },
    {
        dataField: 'sum',
        text: 'Total',
        formatter: (cell, row) => {
            const { value, currency } = row.sum || {}
            return <div>{value} {currency}</div>
        }
    },
    {
        dataField: 'uccFilings',
        text: 'Ucc Filings',
    },
    {
        dataField: 'cautionaryUCCFilings',
        text: 'Cautionary UCC Filings',
    },
    {
        dataField: 'suits',
        text: 'Suits',
    },
]

const TradeLinesInformationColumn = [
    {
        dataField: 'averageCreditAmount',
        text: 'Average Credit Amount',
    },
    {
        dataField: 'industryPerformance',
        text: 'Performance',
    },
    {
        dataField: 'creditUtilization',
        text: 'Credit Utilization',
    },
    {
        dataField: 'lastUpdateDate',
        text: 'Updated Date',
        formatter: (cell) => cell ? <Moment format="MM/DD/YYYY">{cell}</Moment> : ''
    },
    {
        dataField: 'currency',
        text: 'Currency',
    },
]

const BranchOfficesColumn = [
    {
        dataField: 'name',
        text: 'Name',
        style: {
            whiteSpace: 'initial'
        }
    },
    {
        dataField: 'branchType',
        text: 'Branch Type',
    },
    {
        dataField: 'address.simpleValue',
        text: 'Address',
        style: {
            whiteSpace: 'initial'
        }
    },
    {
        dataField: 'address.country',
        text: 'Country',
    },
]

const tradePaymentsByCreditExtended = [
    {
        dataField: 'credit',
        text: 'Credit',
        headerStyle: { minWidth: '5rem' },
        style: {
            whiteSpace: 'initial'
        }
    },
    {
        dataField: 'currency',
        text: 'Currency',
    },
    {
        dataField: 'pastDue',
        text: 'Past Due',
    },
    {
        dataField: 'percentWithinTerms',
        text: 'Percent With In Terms',
    },
    {
        dataField: 'totalAmount',
        text: 'Total Amount',
    },
    {
        dataField: 'tradeLines',
        text: 'Trade Lines',
    }
]

const LifetimeHighBalanceChartColumns = [
    {
        dataField: 'duration',
        text: 'Duration',
    },
    {
        dataField: 'dbt',
        text: 'DBT',
    },
    {
        dataField: 'singleHighBalance',
        text: 'Single High Balance',
    },
    {
        dataField: 'tradeLinesHighBalance',
        text: 'Trade Lines High Balance',
    },
    {
        dataField: 'averageHighBalance',
        text: 'Average High Balance',
    },
    {
        dataField: 'currency',
        text: 'Currency',
    }
]

const criminalRecordsColumns = [
    {
        dataField: 'CaseNumber',
        text: 'Case Number',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'CaseFilingDate',
        headerStyle: { width: '9rem' },
        text: 'Case Filing Date',
        formatter: (cell) => {
            if (cell)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Offenses',
        headerStyle: { width: '17rem' },
        text: 'Offense Description',
        formatter: (cell, row) => {
            if (!cell) {
                return emptyFormatter()
            }
            const offenses = cell.Offense
            if (offenses && offenses.length >= 1) {
                if (offenses.length === 1) {
                    return <div title={offenses[0].Court?.Offense}>{offenses[0].Court?.Offense}</div>
                }
                return (
                    <div className='d-flex'>
                        <div title={offenses[0].Court?.Offense}>{offenses[0].Court?.Offense}</div>
                        <PrepareTooltip
                            theme='white'
                            title={<>
                                {offenses.slice(1).map((entity, i) =>
                                    <div key={i} className='grouped-applications-name'>{entity.Court?.Offense}</div>
                                )}
                            </>}
                        >
                            <span className='grouped-applications'>+{offenses.length - 1}</span>
                        </PrepareTooltip>
                    </div>
                )
            }
            return emptyFormatter()
        },
        style: { whiteSpace: 'nowrap' },
    },
    {
        dataField: 'CaseTypeDescription',
        text: 'Case Type',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'StateOfOrigin',
        text: 'State',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'Action1',
        text: 'Offenses',
    },
    {
        dataField: 'Status',
        text: 'Status',
        formatter: emptyFormatter
    },
]

const uccFilingColumns = [
    {
        dataField: 'OriginFilingNumber',
        text: 'Filing Number',
        headerStyle: { width: '10rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell, row) => {
            return <div>{cell} {`(${row.FilingJurisdiction})`}</div>
        }
    },
    {
        dataField: 'OriginFilingDate',
        text: 'Filing Date',
        headerStyle: { width: '7rem' },
        formatter: (cell) => {
            if (cell && cell.Month && cell.Day && cell.Year)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Filings.Filing',
        text: 'Filing Types',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            if (cell && cell?.length)
                return <div>{cell.map(data => data.Type).join(', ')}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Collaterals.Collateral[0].Description',
        text: 'Collateral',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'Action1',
        text: 'Debtors',
    },
    {
        dataField: 'Action2',
        text: 'Secureds',
    },
]

const liensJudgmentsColumns = [
    {
        dataField: 'Filings.Filing[0].Number',
        text: 'Filing Number',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: (cell, row) => {
            return <div>{cell} {`(${row.FilingJurisdiction})`}</div>
        }
    },
    {
        dataField: 'Filings.Filing[0].Type',
        text: 'Filing Type',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'OriginFilingDate',
        text: 'Filing Date',
        headerStyle: { width: '8rem' },
        formatter: (cell) => {
            if (cell && cell.Month && cell.Day && cell.Year)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'ReleaseDate',
        text: 'Release Date',
        headerStyle: { width: '8rem' },
        formatter: (cell) => {
            if (cell && cell.Month && cell.Day && cell.Year)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Amount',
        text: 'Amount',
        headerStyle: { width: '8rem' },
        formatter: emptyFormatter
    },
    {
        dataField: 'Action1',
        text: 'Debtors',
    },
    {
        dataField: 'Action2',
        text: 'Creditors',
    },
]

const bpsReportAddressColumns = safeColumns([
    {
        dataField: 'SubjectName',
        text: 'Name',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.Prefix} {cell.First} {cell.Middle} {cell.Last} {cell.Suffix}</div>
        }
    },
    {
        dataField: 'Address',
        text: 'Address',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.StreetNumber} {cell.StreetName}, {cell.City}, {cell.State}</div>
        }
    },
    {
        dataField: 'AddressType',
        text: 'Address Type',
        headerStyle: { width: '10rem' },
        formatter: emptyFormatter
    },
    {
        dataField: 'Phones.Phone[0].Phone10',
        text: 'Phone',
        headerStyle: { width: '10rem' },
        formatter: emptyFormatter
    },
]);

const impostersColumns = safeColumns([
    {
        dataField: 'Name',
        text: 'Name',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.Prefix} {cell.First} {cell.Middle} {cell.Last} {cell.Suffix}</div>
        }
    },
    {
        dataField: 'DOB',
        text: 'DOB',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.Year}</div>
        }
    },
    {
        dataField: 'SSNInfo.SSN',
        text: 'SSN',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell}</div>
        }
    },
    {
        dataField: 'SSNInfo.IssuedLocation',
        text: 'Issued in',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell}</div>
        }
    },
    {
        dataField: 'SSNInfo.IssuedStartDate',
        text: 'Start Date',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return safeDate(cell)
        }
    },
    {
        dataField: 'SSNInfo.IssuedEndDate',
        text: 'End Date',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return safeDate(cell)
        }
    }
]);

const bankruptciesColumns = [
    // TODO Add debtor and trustees
    {
        dataField: 'CaseNumber',
        text: 'Case Number',
        style: { whiteSpace: 'initial' },
        formatter: (cell, row) => {
            return <div>{cell} {`(${row.FilingJurisdiction})`}</div>
        }
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type',
        style: { whiteSpace: 'initial' }
    },
    {
        dataField: 'FilerType',
        text: 'Filer Type',
        style: { whiteSpace: 'initial' }
    },
    {
        dataField: 'FilingDate',
        text: 'Filing Date',
        formatter: (cell) => {
            return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
        }
    },
    {
        dataField: 'ClosedDate',
        text: 'ClosedDate',
        formatter: (cell) => {
            if (cell)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'CourtName',
        text: 'Court Name',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' }
    },
    {
        dataField: 'Disposition',
        text: 'Disposition',
    },
]

const infoColumns = [
    {
        dataField: 'Name',
        text: 'Name',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.First} {cell.Middle} {cell.Last}</div>
        }
    },
    {
        dataField: 'Address',
        text: 'Address',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.StreetNumber} {cell.StreetName}, {cell.City}, {cell.State}</div>
        }
    },
    {
        dataField: 'Deceased',
        text: 'Deceased',
    },
    {
        dataField: 'DOB',
        text: 'DOB',
        formatter: (cell) => {
            if (cell)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Phones.Phone[0].Phone10',
        text: 'Phone',
        formatter: emptyFormatter
    },
]

const verifiedAction = (cell) => {
    if (cell)
        return <VerifiedUserIcon titleAccess='Verified' color='primary' />
    return <CancelIcon titleAccess='Not Verified' color='secondary' />
}

const verificationColumns = [
    {
        dataField: 'Name',
        text: 'Name',
        formatter: verifiedAction
    },
    {
        dataField: 'SSN',
        text: 'SSN',
        formatter: verifiedAction
    },
    {
        dataField: 'DOB',
        text: 'DOB',
        formatter: verifiedAction
    },
    {
        dataField: 'CompanyName',
        text: 'Company',
        formatter: verifiedAction
    },
    {
        dataField: 'Address',
        text: 'Address',
        formatter: verifiedAction
    },
    {
        dataField: 'City',
        text: 'City',
        formatter: verifiedAction
    },
    {
        dataField: 'State',
        text: 'State',
        formatter: verifiedAction
    },
    {
        dataField: 'Zip',
        text: 'Zip',
        formatter: verifiedAction
    },
    {
        dataField: 'Phone10',
        text: 'Phone',
        formatter: verifiedAction
    },
]

const continuationColumns = [
    {
        dataField: 'applicationName',
        text: 'Application Name',
        headerStyle: { width: '15rem' },
    },
    {
        dataField: 'debtorName',
        text: 'Debtor Name',
        headerStyle: { width: '15rem' },
    },
    {
        dataField: 'additionalData.filingNumber',
        text: 'Filing Number',
    },
    {
        dataField: 'actionItemType',
        text: 'Status',
    },
    {
        dataField: 'dueDate',
        text: 'Due Date',
        formatter: (cell) => cell ? <Moment format="MM/DD/YYYY">{cell}</Moment> : emptyFormatter()
    },
    {
        dataField: 'action',
        text: 'Actions',
        headerStyle: { width: '10rem' }
    }
]

const amendmentsColumn = [
    {
        dataField: 'debtorName',
        text: 'Debtor Name',
        headerStyle: { width: '15rem' },
        formatter: emptyFormatter
    },
    {
        dataField: 'fileNumber',
        text: 'Filing Number',
        formatter: emptyFormatter
    },
    {
        dataField: 'amendmentType',
        text: 'Type',
    },
    {
        dataField: 'lapseDate',
        text: 'Due Date',
        formatter: (cell) => cell ? <Moment format="MM/DD/YYYY">{cell}</Moment> : emptyFormatter()
    },
    {
        dataField: 'amendmentDate',
        text: 'Amendment Date',
        formatter: (cell) => cell ? <Moment format="MM/DD/YYYY">{cell}</Moment> : emptyFormatter()
    },
    {
        dataField: 'action',
        text: 'Actions',
        headerStyle: { width: '7rem' }
    }
]

const activeColumns = (hideToolTip = false) => [
    {
        dataField: 'debtorName',
        text: 'Debtor Name',
        headerStyle: { width: '15rem' },
        style: { whiteSpace: 'initial' },
        sort: true,
        formatter: (cell, row) => {
            if (!row?.entities?.length)
                return emptyFormatter()

            const debtors = row.entities.filter((entity, i) => entity.entityType === 'DEBTOR')
            if (debtors.length === 1)
                return <div title={cell}>{cell}</div>
            if (debtors.length > 1 && !hideToolTip)
                return (
                    <div className='d-flex'>
                        <div title={cell}>{cell}</div>
                        <PrepareTooltip
                            theme='white'
                            title={<>
                                {debtors.map((entity, i) =>
                                    i ? <div key={i} className='grouped-applications-name'>{entity.name}</div> : null
                                )}
                            </>}
                        >
                            <span className='grouped-applications'>+{debtors.length - 1}</span>
                        </PrepareTooltip>
                    </div>
                )
        }
    },
    {
        dataField: 'filingType',
        text: 'Filing Type',
        formatter: (cell) => cell ? <div title={cell}>{cell}</div> : emptyFormatter(),
        sort: false
    },
    {
        dataField: 'filingNumber',
        text: 'Filing Number',
        formatter: (cell) => cell ? <div title={cell}>{cell}</div> : emptyFormatter(),
        sort: true
    },
    {
        dataField: 'jurisdiction',
        text: 'Jurisdiction',
        formatter: (cell) => cell ? <div title={cell}>{cell}</div> : emptyFormatter(),
        sort: true
    },
    {
        dataField: 'creationDate',
        text: 'Submission Date',
        headerStyle: { width: '12rem' },
        formatter: (cell) => cell ? fcsDatesFormatter(cell) : emptyFormatter(),
        sort: true
    },
    {
        dataField: 'fileDate',
        text: 'Filing Date',
        formatter: (cell) => cell ? fcsDatesFormatter(cell) : emptyFormatter(),
        sort: true
    },
    {
        dataField: 'lapseDate',
        text: 'Lapse Date',
        formatter: (cell) => cell ? fcsDatesFormatter(cell) : emptyFormatter(),
        sort: true
    },
    {
        dataField: 'amendmentAction',
        text: 'Amendment Action',
        formatter: (cell, { amendmentType }) => {
            if (cell && amendmentType) {
                const value = changeTypeLabels[cell.toLowerCase().includes('no') ? amendmentType : cell]
                return <div title={value}>{value}</div>
            }
            return emptyFormatter
        },
        sort: false
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: (cell) => cell ? <div title={cell}>{cell}</div> : emptyFormatter(),
        sort: false
    },
    {
        dataField: 'action',
        text: 'Actions',
        headerStyle: { width: '10rem' },
        sort: false
    }
]

const buisnessBgvColumns = [
    {
        dataField: 'Best.CompanyNameInfo.CompanyName',
        text: 'Company Name',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: emptyFormatter
    },
    {
        dataField: 'Best.AddressInfo.Address',
        text: 'Address',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => cell ? `${cell.StreetNumber || ''} ${cell.StreetSuffix || ''} ${cell.StreetName || ''}, ${cell.City || ''}, ${cell.State || ''}, ${cell.Zip5 || ''}` : emptyFormatter()
    },
    {
        dataField: 'action',
        headerStyle: { display: 'hidden' },
        text: ''
    },
    {
        dataField: 'verified',
        headerStyle: {
            display: 'hidden',
            width: '7.5rem'
        },
        text: '',
        formatter: (cell) => {
            return cell ?
                <span className='globalLabel' style={{ background: '#E1FAEB', color: '#20693D' }}>{strings.APPLICANT.BGV.VERIFIED}</span>
                :
                null
        }
    }
]

const bgvSummaryColums = [
    {
        dataField: 'Best.CompanyNameInfo.CompanyName',
        text: 'Company Name',
        headerStyle: {
            width: isScreenSmall() ? '15rem' : '20rem'
        },
        style: { whiteSpace: 'initial' },
        formatter: emptyFormatter
    },
    {
        dataField: 'Best.AddressInfo.Address',
        text: 'Address',
        headerStyle: { minWidth: '15rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell) => cell ? `${cell.StreetNumber || ''} ${cell.StreetSuffix || ''} ${cell.StreetName || ''}, ${cell.City || ''}, ${cell.State || ''}, ${cell.Zip5 || ''}` : emptyFormatter()
    },
    {
        dataField: 'action',
        headerStyle: { 
            display: 'hidden',
            width: '10rem'
        },
        text: ''
    },
]

const businessInformationColumns = [
    {
        dataField: 'CompanyName',
        text: 'Company Name',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
    },
    {
        dataField: 'Address',
        text: 'Address',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            if (cell)
                return <div>{cell.StreetNumber} {cell.StreetName}, {cell.City}, {cell.State}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'PhoneInfo.Phone10',
        text: 'Phone',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'URL',
        text: 'URL',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'YearStarted',
        text: 'Started Year',
        formatter: emptyFormatter
    },
    {
        dataField: 'IsActive',
        text: 'Active',
        formatter: (cell) => {
            if (cell !== null || cell !== undefined)
                return <div>{cell ? 'Yes' : 'No'}</div>
            return emptyFormatter()
        }
    },
]

const otherCompanyTinColumns = [
    // TODO check whether company name is multiple and display here
    {
        dataField: 'SourceDocs.SourceDoc[0].IdType',
        text: 'Id Type',
        formatter: emptyFormatter
    },
    {
        dataField: 'SourceDocs.SourceDoc[0].IdValue',
        text: 'Id Value',
        formatter: emptyFormatter
    },
    {
        dataField: 'SourceDocs.SourceDoc[0].Source',
        text: 'Source',
        formatter: emptyFormatter
    },
    {
        dataField: 'Tin',
        text: 'Tin',
        formatter: emptyFormatter
    },
]

const financeColumns = [
    {
        dataField: 'SourceDocs.SourceDoc[0].IdType',
        text: 'Id Type',
        formatter: emptyFormatter
    },
    {
        dataField: 'SourceDocs.SourceDoc[0].IdValue',
        text: 'Id Value',
        formatter: emptyFormatter
    },
    {
        dataField: 'SourceDocs.SourceDoc[0].Source',
        text: 'Source',
        formatter: emptyFormatter
    },
    {
        dataField: 'AnnualSales',
        text: 'AnnualSales',
        formatter: emptyFormatter
    },
]

const industryColumns = [
    {
        dataField: 'IndustryDescription',
        text: 'Industry Description',
        formatter: emptyFormatter
    },
    {
        dataField: 'SourceDocs.SourceDoc[0].IdType',
        text: 'Id Type',
        formatter: emptyFormatter
    },
    {
        dataField: 'SourceDocs.SourceDoc[0].IdValue',
        text: 'Id Value',
        formatter: emptyFormatter
    },
    {
        dataField: 'SourceDocs.SourceDoc[0].Source',
        text: 'Source',
        formatter: emptyFormatter
    },
]

const incorporationColumns = [
    {
        dataField: 'CorporationName',
        text: 'Name',
        style: { whiteSpace: 'initial' },
        formatter: emptyFormatter
    },
    {
        dataField: 'FilingNumber',
        text: 'Filing Number',
        formatter: emptyFormatter
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type',
        formatter: emptyFormatter
    },
    {
        dataField: 'FilingDate',
        text: 'Filing Date',
        formatter: (cell) => {
            if (cell)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'TermOfExistence.Years',
        text: 'TOE',
        formatter: (cell) => {
            if (cell !== null || cell !== undefined)
                return <div>{cell} Years</div>
            return emptyFormatter()
        }
    },
]

const currentIndividualsColumns = [
    {
        dataField: 'Name',
        text: 'Name',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.First} {cell.Middle} {cell.Last}</div>
        }
    },
    {
        dataField: 'Address',
        text: 'Address',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            if (cell)
                return <div>{cell.StreetNumber} {cell.StreetName}, {cell.City}, {cell.State}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'BestPosition.CompanyTitle',
        text: 'Position',
        style: { whiteSpace: 'initial' },
        formatter: emptyFormatter
    },
    {
        dataField: 'BestPosition',
        text: 'Period',
        formatter: cell =>
            <>
                <span>{cell?.FromDate ? getDate(cell.FromDate, ['month', 'year']) : emptyFormatter}</span>
                <span className='ml-2 mr-2'>to</span>
                <span>{cell?.ToDate ? getDate(cell.ToDate, ['month', 'year']) : emptyFormatter}</span>
            </>

    },
]

const priorExecutivesColumns = [
    {
        dataField: 'Name',
        text: 'Name',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            return <div>{cell.First} {cell.Middle} {cell.Last}</div>
        }
    },
    {
        dataField: 'Address',
        text: 'Address',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            if (cell)
                return <div>{cell.StreetNumber} {cell.StreetName}, {cell.City}, {cell.State}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'BestPosition.CompanyTitle',
        text: 'Position',
        style: { whiteSpace: 'initial' },
        formatter: emptyFormatter
    },
    {
        dataField: 'BestPosition.FromDate',
        text: 'Expiration Date',
        formatter: (cell) => {
            if (cell)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
]

const bankruptcyColumns = [
    {
        dataField: 'DerogSummaryCntBankruptcy',
        text: 'Derogatory Summary',
    },
    {
        dataField: 'TotalDerogSummaryCntBankruptcy',
        text: 'Total Derogatory Summary',
    },
]

// this is belonging to a lexis report
const buisnessLiensJudgmentsColumns = [
    {
        dataField: 'Filings.Filing[0].FilingNumber',
        text: 'Filing Number',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        // formatter: emptyFormatter
        formatter: (cell, row) => {
            return <div>{cell} {`(${row.FilingJurisdiction})`}</div>
        }
    },
    {
        dataField: 'Filings.Filing[0].FilingType',
        text: 'Filing Type',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: emptyFormatter
    },
    {
        dataField: 'OrigFilingDate',
        text: 'Filing Date',
        headerStyle: { width: '8rem' },
        formatter: (cell) => {
            if (cell && cell.Month && cell.Day && cell.Year)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Amount',
        text: 'Amount',
        headerStyle: { width: '8rem' },
        formatter: emptyFormatter
    },
    {
        dataField: 'Action1',
        text: 'Debtors',
    },
    {
        dataField: 'Action2',
        text: 'Creditors',
    },
]

// this is belonging to a lexis report
const buisnessUccFilingColumns = [
    {
        dataField: 'OriginalFilingNumber',
        text: 'Filing Number',
        headerStyle: { width: '10rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell, row) => {
            return <div>{cell} {`(${row.FilingJurisdiction})`}</div>
        }
    },
    {
        dataField: 'OriginalFilingDate',
        text: 'Filing Date',
        headerStyle: { width: '7rem' },
        formatter: (cell) => {
            if (cell?.Month && cell?.Day && cell?.Year)
                return <div>{cell.Month}/{cell.Day}/{cell.Year}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Filings.Filing',
        text: 'Filing Types',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            if (cell && cell?.length)
                return <div>{cell.map(data => data?.FilingType).join(', ')}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Filings.Filing[0].Collaterals.Collateral',
        text: 'Collateral',
        style: { whiteSpace: 'initial', wordBreak: 'break-all' },
        formatter: (cell) => {
            if (cell && cell?.length)
                return <div>{cell.map(data => data?.CollateralDescription).join(', ')}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'Action1',
        text: 'Debtors',
    },
    {
        dataField: 'Action2',
        text: 'Secureds',
    },
]

const connectedBusinessColumns = [
    {
        dataField: 'CompanyName',
        text: 'Company Name',
        headerStyle: { width: '10rem' },
        style: { whiteSpace: 'initial' },
    },
    {
        dataField: 'Address',
        text: 'Address',
        style: { whiteSpace: 'initial' },
        formatter: (cell) => {
            if (cell)
                return <div>{cell.StreetNumber} {cell.StreetName}, {cell.City}, {cell.State}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'TIN',
        text: 'TIN',
        formatter: emptyFormatter
    },
    {
        dataField: 'action',
        text: 'Action',
    },
]

const experianBusinessColumns = [
    {
        dataField: 'BankruptcyTotals.Filed',
        text: 'Bankruptcy Filed',
    },
    {
        dataField: 'JudgmentTotals.Filed',
        text: 'Judgement Filed',
    },
    {
        dataField: 'LienTotals.Filed',
        text: 'Lien Filed',
    },
    {
        dataField: 'UCCTotals.Filed',
        text: 'UCC Filed',
    },
]

const buisnessVerificationColumns = [
    {
        dataField: 'CompanyName',
        text: 'Company',
        formatter: verifiedAction
    },
    {
        dataField: 'Address',
        text: 'Address',
        formatter: verifiedAction
    },
    {
        dataField: 'City',
        text: 'City',
        formatter: verifiedAction
    },
    {
        dataField: 'State',
        text: 'State',
        formatter: verifiedAction
    },
    {
        dataField: 'Zip',
        text: 'Zip',
        formatter: verifiedAction
    },
    {
        dataField: 'Phone10',
        text: 'Phone',
        formatter: verifiedAction
    },
]

const BTOwnersColumn = [
    {
        dataField: 'name',
        text: 'Owner',
        key: "ownerId"
    },
    {
        dataField: 'status',
        text: 'Status'
    },
    {
        dataField: 'requestedAt',
        text: 'Requested at',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'grantedAt',
        text: 'Granted at',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'emails[0].sentAt',
        text: 'Last Email Sent at',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
]

function mapBankTransactionData(data) {
    return [
        {
            title: 'Name :',
            value: getValueFromData(data, 'name')
        },
        {
            title: 'Original Description',
            value: getValueFromData(data, 'originalDescription')
        },
        {
            title: 'Amount :',
            value: getValueFromData(data, 'amount')
        },
        {
            title: 'ISO Currency Code :',
            value: getValueFromData(data, 'isoCurrencyCode')
        },
        {
            title: 'Categories :',
            value: getValueFromData(data, 'categories')
        },
        {
            title: 'Payment Channel :',
            value: getValueFromData(data, 'paymentChannel')
        },
        {
            title: 'Date :',
            value: getValueFromData(data, 'date')
        },
        {
            title: 'Authorized Date :',
            value: getValueFromData(data, 'authorizedDate')
        }
    ]
}

function mapRecurrentBankTransactionData(data) {
    return [
        {
            title: 'Description :',
            value: getValueFromData(data, 'description')
        },
        {
            title: 'Frequency :',
            value: getValueFromData(data, 'frequency')
        },
        {
            title: 'Categories :',
            value: getValueFromData(data, 'categories')
        },
        {
            title: 'Average Amount :',
            value: getValueFromData(data, 'averageAmount')
        },
        {
            title: 'Last Amount :',
            value: getValueFromData(data, 'lastAmount')
        },
        {
            title: 'First Date :',
            value: getValueFromData(data, 'firstDate')
        },
        {
            title: 'Last Date :',
            value: getValueFromData(data, 'lastDate')
        },
        {
            title: 'Status :',
            value: getValueFromData(data, 'status')
        }
    ]
}

function mapAccountSummary(data) {
    return [
        {
            title: 'Current Balance',
            current: getValueFromData(data.accountBalance, 'currentTotal'),
            diff: getValueFromData(data.accountBalance, 'lastMonthPercentageDifference'),
        },
        {
            title: 'Total Outgoing Payments',
            current: getValueFromData(data.totalOutgoingPayments, 'currentTotal'),
            diff: getValueFromData(data.totalOutgoingPayments, 'lastMonthPercentageDifference'),
        },
        {
            title: 'Total Recurring Transactions',
            current: getValueFromData(data.totalRecurringTransactions, 'currentTotal'),
            diff: getValueFromData(data.totalRecurringTransactions, 'lastMonthPercentageDifference', true),
        },
    ]
}

const transactionColumn = [
    {
        dataField: 'name',
        text: 'Name',
        formatter: (cell) => {
            return <div>{cell}</div>
        }
    },
    {
        dataField: 'categories',
        text: 'Categories',
        formatter: (cell) => {
            if (cell && cell?.length)
                return <div>{cell.join(', ')}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'direction',
        text: 'D/C',
        formatter: (cell) => {
            return <div>{cell}</div>
        }
    },
    {
        dataField: 'date',
        text: 'Date',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'amount',
        text: 'Amount',
        headerStyle: {
            textAlign: 'center',
            width: '11rem'
        },
        formatter: (cell) => {
            return cell ? amountsFormatter(cell) : emptyFormatter()
        }
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden" },
        text: ''
    }
]

const recurrentTransactionColumn = [
    {
        dataField: 'description',
        text: 'Description',
        formatter: formatTooltip
    },
    {
        dataField: 'flow',
        text: 'Direction',
        formatter: (cell) => {
            return <div>{cell}</div>
        }
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: (cell) => {
            return <div>{cell}</div>
        }
    },
    {
        dataField: 'frequency',
        text: 'Frequency',
        formatter: (cell) => {
            if (cell) return <div>{cell}</div>
            return emptyFormatter()
        }
    },
    {
        dataField: 'categories',
        text: 'Categories',
        formatter: formatTooltip
    },
    {
        dataField: 'firstDate',
        text: 'First Date',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'averageAmount',
        text: 'Average Amount',
        headerStyle: {
            textAlign: 'center',
            width: '11rem'
        },
        formatter: (cell) => {
            return cell ? amountsFormatter(cell) : emptyFormatter()
        }
    },
    {
        dataField: 'numberOfTransactions',
        headerStyle: { display: "hidden" },
        text: '',
        formatter: (cell) => {
            return <div>{cell} transactions</div>
        }
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden" },
        text: ''
    }
]

const transRTDataColumn = [
    {
        dataField: 'date',
        text: 'Date',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'amount',
        text: 'Amount',
        headerStyle: {
            textAlign: 'center',
            width: '11rem'
        },
        formatter: (cell) => {
            return cell ? amountsFormatter(cell) : emptyFormatter()
        }
    },
    {
        dataField: 'paymentChannel',
        text: 'Payment Channel',
        formatter: (cell) => {
            return <div>{cell}</div>
        }
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden" }
    }
]

const UccDocumentRequestColumn = [
    {
        dataField: 'initialFilingNumber',
        text: 'Initial Filing',
        headerStyle: {
            width: '10rem',
            backgroundColor: '#3f51b5',
            color: 'white'
        },
    },
    {
        dataField: 'filingNumber',
        text: 'Filing Requested',
        headerStyle: {
            width: '10rem',
            backgroundColor: '#3f51b5',
            color: 'white'
        },
    },
    {
        dataField: 'numberOfPages',
        text: 'Pages',
        headerStyle: {
            width: '10rem',
            backgroundColor: '#3f51b5',
            color: 'white'
        },
    },
]

const DocumentRequestColumn = [
    {
        dataField: 'initialFiling',
        text: 'Initial Filing',
        headerStyle: {
            width: '10rem',
            backgroundColor: '#3f51b5',
            color: 'white'
        },
    },
    {
        dataField: 'filingNumber',
        text: 'File Requested',
        headerStyle: {
            width: '10rem',
            backgroundColor: '#3f51b5',
            color: 'white'
        },
    },
    {
        dataField: 'noOfPages',
        text: 'pages',
        headerStyle: {
            width: '10rem',
            backgroundColor: '#3f51b5',
            color: 'white'
        },
    },
]

const uccSummaryColumns = [
    {
        dataField: 'filingNumber',
        text: 'Filing Number',
    },
    {
        dataField: 'filingType',
        text: 'Filing Type',
        formatter: (cell, row) => {
            if (!cell || !cell.length){
                return emptyFormatter()
            }
            function isSupportedFilingType() {
                return cell === 'UCC' || cell === 'STL' || cell === 'FTL';
            }
            function filingTypeToolTipValue() {
                switch (cell) {
                    case 'UCC':
                        return 'Uniform Commercial Code';
                    case 'STL':
                        return 'State Tax Lien';
                    case 'FTL':
                        return 'Federal Tax Lien';
                    default:
                        return cell;
                }
            }

            if (isSupportedFilingType()){
                return (
                    <div className='d-flex'>
                        <PrepareTooltip theme="white" title={filingTypeToolTipValue(cell)}>
                            {cell}
                        </PrepareTooltip>
                    </div>
                )
            }
            return <div title={cell}>{cell}</div>
        }
    },
    {
        dataField: 'provider',
        text: 'Provider',
    },
    {
        dataField: 'state',
        text: 'State',
    },
    {
        dataField: 'status',
        text: 'Status',
    },
    {
        dataField: 'filingDate',
        text: 'Date Filed',
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden" },
        text: ''
    }
]

const sosSearchesColumnsQS = [
    {
        dataField: 'payload.searchQuery',
        text: 'Search Query',
        headerStyle: {
            minWidth: '15rem'
        },
        style: { display: 'flex' },
        formatter: emptyFormatter
    },
    {
        dataField: 'createdDate',
        text: 'Created Date',
        formatter: (cell) => cell ? <Moment format="MM/DD/YYYY">{cell}</Moment> : ''
    },
    {
        dataField: 'payload.state',
        text: 'State',
        formatter: upperCaseFormatter
    },
    {
        dataField: 'status',
        text: 'Status',
        formatter: emptyFormatter
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden" },
        text: ''
    }
]
// Old Header ================================================================================================================
const BuisnessColumns = [
    {
        dataField: 'businessType',
        text: 'Business Type',
        headerStyle: { width: '10rem' },
    },
    {
        dataField: 'identifications[0].identification',
        text: 'Ein'
    },
    {
        dataField: 'addresses[0].address1',
        text: 'Address',
        style: {
            whiteSpace: 'initial'
        },
        formatter: (cell, row) => {
            if (!row?.addresses?.length)
                return emptyFormatter()

            const addresses = row.addresses

            if (addresses.length === 1)
                return <div title={cell}>{cell}</div>
            if (addresses.length > 1)
                return (
                    <div className='d-flex'>
                        <div title={cell}>{cell}</div>
                        <PrepareTooltip
                            theme='white'
                            title={<>
                                {addresses.map((address, i) =>
                                    i ? <div key={i} className='grouped-applications-name'>{address.address1}</div> : null
                                )}
                            </>}
                        >
                            <span className='grouped-applications'>+{addresses.length - 1}</span>
                        </PrepareTooltip>
                    </div>
                )
        },
        headerStyle: { width: '15rem' },
    },
    {
        dataField: 'salesRepresentative',
        text: 'Sales Representative',
        headerStyle: { width: '13rem' },
        style: { whiteSpace: 'initial' },
        formatter: (cell, row) => {
            if (cell)
                return `${cell.firstName} ${cell.lastName}`
            return emptyFormatter()
        }
    },
    {
        dataField: 'emails[0].email',
        text: 'Email',
        headerStyle: {
            width: '10rem'
        },
        style: {
            whiteSpace: 'initial'
        }
    },
    {
        dataField: 'website',
        text: 'Website',
        style: {
            whiteSpace: 'initial'
        },
        formatter: emptyFormatter
    },
    {
        dataField: 'phones[0].number',
        text: 'Phone'
    },
    {
        dataField: 'addresses[0].state',
        text: 'State'
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden", width: "4rem" },
        text: ''
    }
]

const OwnersColumn = [
    {
        dataField: 'FilingType',
        text: 'Owner',
        headerStyle: {
            width: '15rem'
        },
        formatter: (cell, row) => {
            const { firstName = '', lastName = '' } = row
            return <div title={`${firstName} ${lastName}`}>{firstName} {lastName}</div>
        }
    },
    {
        dataField: 'dateOfBirth',
        text: 'Dob',
        formatter: (cell) => {
            return dateFormatter(cell)
        }
    },
    {
        dataField: 'phones[0].number',
        text: 'Phone'
    },
    {
        dataField: 'identifications[0].identification',
        text: 'SSN'
    },
    {
        dataField: 'address.address1',
        text: 'Address'
    },
    {
        dataField: 'address.state',
        text: 'State'
    },
    {
        dataField: 'address.zip5',
        text: 'Zip'
    },
    {
        dataField: 'action',
        headerStyle: { display: "hidden", width: "4rem" },
        text: ''
    }
]
// ===========================================================================================================================

const mapSearchResultInfo = (searchResult, possibleAlternatives) => {
    const alternatives = possibleAlternatives?.length > 0 ? possibleAlternatives.length : 'None';
    return [
        {
            title: 'Entity Type',
            value: getValueFromData(searchResult, 'entityType')
        },
        {
            title: 'Physical Address',
            value: joinAttributes({ street: searchResult?.physicalAddressStreet, city: searchResult?.physicalAddressCity, state: searchResult?.physicalAddressState, zip: searchResult?.physicalAddressZip })
        },
        {
            title: 'State of SOS Registration',
            value: getValueFromData(searchResult, 'stateOfSosRegistration')
        },
        {
            title: 'Possible Alternatives',
            value: alternatives
        },
        {
            title: 'Filing Date',
            value: getValueFromData(searchResult, 'filingDate')
        },
        {
            title: 'State of Formation',
            value: getValueFromData(searchResult, 'stateOfFormation')
        }
    ]
}

const sosRecordFilingHistoryColumns = [
    {
        text: strings.APPLICANT.SOS.FILING_TYPE,
        dataField: 'name',
        formatter: emptyFormatter
    },
    {
        text: strings.APPLICANT.SOS.FILING_DATE,
        dataField: 'url',
        formatter: (cell, row) => {
            return <p onClick={() => window.open(cell, '_blank')} className='linkDocument'>
                Link Document
            </p>
        }
    },
    {
        text: strings.APPLICANT.SOS.FILING_DATE,
        dataField: 'date',
        formatter: momentOnlyDateFormatter
    },
]

const mapBusinessInformation = (data) => {
    return [
        {
            title: strings.APPLICANT.SOS.BUSINESS_NAME,
            value: () => <p>{getValueFromData(data, 'title')}</p>
        },
        {
            title: strings.APPLICANT.SOS.STATUS,
            value: () => {
                var status = getValueFromData(data, 'normalizedStatus');
                var labelColor = status.includes('Active')? 'greenLabel' : 'redLabel';

             return <div className={labelColor}>{status}</div>
            }

        },
        {
            title: strings.APPLICANT.SOS.FILING_DATE,
            value: () => <p>{getValueFromData(data, 'filingDate')}</p> // TODO: format date, confirm same this is equal to date of formation?
        },
        {
            title: strings.APPLICANT.SOS.STATE_OF_FORMATION,
            value: () => <p>{getValueFromData(data, 'stateOfFormation')}</p>
        },
        {
            title: strings.APPLICANT.SOS.STATE_OF_SOS_REGISTRATION,
            value: () => <p>{getValueFromData(data, 'stateOfSosRegistration')}</p>
        },
        {
            title: strings.APPLICANT.SOS.ENTITY_TYPE,
            value: () => <p>{getValueFromData(data, 'entityType')}</p>
        },
        {
            title: strings.APPLICANT.SOS.PHYSICAL_ADDRESS,
            value: () => <p>{joinAttributes({ street: data?.physicalAddressStreet, city: data?.physicalAddressCity, state: data?.physicalAddressState, zip: data?.physicalAddressZip })}</p>
        },
        {
            title: strings.APPLICANT.SOS.SOS_LINK,
            value: () => {
                if (data?.url) {
                    return (<p onClick={() => window.open(data?.url, '_blank')} className='linkDocument'>
                            {strings.APPLICANT.SOS.OPEN_SOS_WEBSITE}
                        </p>)
                }
                return '-'
            }
        },
        {
            title: strings.APPLICANT.SOS.SCREENSHOT_URL,
            value: () => {
                if (data?.screenshotUrl) {
                    return (<p onClick={() => window.open(data?.screenshotUrl, '_blank')} className='linkDocument'>
                    {strings.APPLICANT.SOS.DOWNLOAD}
                    </p>)
                }
                return '-'
            }
        }
    ]
}

const mapRegisteredAgentInformation = (data) => {

    return [
        {
            title: strings.APPLICANT.SOS.REGISTERED_AGENT_NAME,
            value: () => <p>{getValueFromData(data, 'agentName')}</p>
        },
        {
            title: strings.APPLICANT.SOS.PHYSICAL_ADDRESS,
            value: () => <p>{joinAttributes({ street: data?.physicalAddressStreet, city: data?.physicalAddressCity, state: data?.physicalAddressState, zip: data?.physicalAddressZip })}</p>
        }
    ]
}

const MAX_RETRIES_FOR_SOS_SEARCH = 24

const buildUccDefaultFormValues = (pastParameters, selectedEntity) => {
    const dataToUse = pastParameters ? pastParameters : selectedEntity
    return {
        defaultValues: {
            searchOrganizationName: dataToUse.searchOrganizationName || '',
            searchFirstName: dataToUse.searchFirstName || '',
            searchLastName: dataToUse.searchLastName || '',
            stateCode: dataToUse.stateCode || dataToUse.state || '',
            date: pastParameters ? moment(pastParameters.date).format('YYYY-MM-DD') : moment().subtract(6, 'years').format('YYYY-MM-DD'),
            includeInactiveFilings: pastParameters ? pastParameters.includeInactiveFilings : false
        }
    }
}

const UccAmendmentPartyChange = (data, selectedIndex, jurisdictionState, filingNumber, jurisdictionId, action) => {
    const entity = data[selectedIndex]
    const authorizingParty = data[0]
    return {
        defaultValues: {
            filerReference: '',
            clientReference: '',
            filingNumber: filingNumber || '',
            action: action || '',
            jurisdictionId: jurisdictionId || '',
            affectedParty: {
                organizationName: entity.organizationName || '',
                firstName: entity.firstName || '',
                middleName: entity.middleName || '',
                lastName: entity.lastName || '',
                address: {
                    streetAddress: entity.address.streetAddress || '',
                    city: entity.address.city || '',
                    state: entity.address.state || '',
                    postalCode: entity.address.postalCode || '',
                    country: entity.address.country || ''
                },
                state: {
                    label: '',
                    value: ''
                },
                jurisdictionId: entity.address.state || ''
            },
            authorizingParty: {
                organizationName: authorizingParty.organizationName || '',
                firstName: entity.firstName || '',
                middleName: authorizingParty.middleName || '',
                lastName: authorizingParty.lastName || '',
                address: {
                    streetAddress: authorizingParty.address.streetAddress || '',
                    city: authorizingParty.address.city || '',
                    state: authorizingParty.address.state || '',
                    postalCode: authorizingParty.address.postalCode || '',
                    country: authorizingParty.address.country || ''
                }
            },
            currentParty: {
                organizationName: entity.organizationName || '',
                firstName: entity.firstName || '',
                middleName: entity.middleName || '',
                lastName: entity.lastName || '',
                address: {
                    streetAddress: entity.address.streetAddress || '',
                    city: entity.address.city || '',
                    state: entity.address.state || '',
                    postalCode: entity.address.postalCode || '',
                    country: entity.address.country || ''
                }
            }
        }
    }
}

const uccDirectTerminationForm = (filingToTerminate, applicationId, interactionId, fcsClientReference) => {
    const securedParty = filingToTerminate?.Secureds[0];
    return {
        defaultValues: {
            authorizingParty: {
                organizationName: securedParty?.Name || '',
                firstName: '',
                middleName: '',
                lastName: '',
                taxId: '',
                address: {
                    streetAddress: securedParty?.Address || '',
                    city: securedParty?.City || '',
                    state: securedParty?.State || '',
                    postalCode: securedParty?.Zip || '',
                    country: 'USA'
                }
            },
            directAmendment: true,
            applicationId: applicationId,
            filingNumber: filingToTerminate.FilingNumber || '',
            filingDetails: interactionId,
            securedPartyMasking: false,
            jurisdictionId: '',
            filerReference: '',
            clientReference: fcsClientReference || ''
        }
    }
}

const uccAttachedFilingColumns = [
    {
        dataField: 'FilingNumber',
        text: 'Filing Number'
    },
    {
        dataField: 'FilingType',
        text: 'Filing Type'
    },
    {
        dataField: 'State',
        text: 'State',
    },
    {
        dataField: 'Status',
        text: 'Status',
        formatter: (cell) => {
            let className = '';
            if (cell === 'Active') {
                className = 'greenLabel';
            } else if (cell === 'Inactive') {
                className = 'redLabel';
            }
            return (
                <span className={className}>
                    {cell}
                </span>
            );
        }
    },
    {
        dataField: 'FilingDate',
        text: 'Date Filed',
        formatter: momentOnlyDateFormatter
    }
]

export {
    UccAmendmentPartyChange,
    uccSearchResultColumns,
    uccFilingsTableColumns,
    uccAttachedFilingColumns,
    momentOnlyDateFormatter,
    uccDirectTerminationForm,
    FilingImageColumns,
    UccDocumentRequestColumn,
    DocumentRequestColumn,
    uccSummaryColumns,
    ReportColums,
    FilingDetailsColumns,
    AmendmentDetailsColumns,
    DetailColumn,
    ReceivedDocumentsColumn,
    DebtorsColumn,
    FmcsaColumns,
    ApplicantColumns,
    UccSearchColums,
    uccSearchTableColumns,
    auditColums,
    UccFilingColums,
    DirectAmendmentColums,
    AmendmentHistoryColumns,
    UccSearchData,
    ApplicantFilters,
    ownersObj,
    OwnerDefaultForm,
    DefaultApplicationForm,
    DefaultAddress,
    AttachmentsColumn,
    CreditCheckColumn,
    creditReportColumns,
    BranchOfficesColumn,
    mapDataToTable,
    uccFilingColumns,
    infoColumns,
    verificationColumns,
    mapDataToBgvReportTable,
    buisnessBgvColumns,
    bgvSummaryColums,
    mapBusinessDataToBgvReportTable,
    continuationColumns,
    clientFilter,
    amendmentsColumn,
    activeColumns,
    BTOwnersColumn,
    transactionColumn,
    recurrentTransactionColumn,
    transRTDataColumn,
    mapBankTransactionData,
    mapRecurrentBankTransactionData,
    mapAccountSummary,
    sosSearchesColumnsQS,
    sosRecordFilingHistoryColumns,
    mapRegisteredAgentInformation,
    mapBusinessInformation,
    mapSearchResultInfo,
    MAX_RETRIES_FOR_SOS_SEARCH,
    buildUccDefaultFormValues,
    dateFormatter,
    momentDateFormatter,
    // Old Header======
    BuisnessColumns,
    OwnersColumn,
    // ================
}