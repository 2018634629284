import { currencyFormatter } from 'tools/helper'
import { formatCurrency } from 'tools/formatters'
const Status = [
  {
    status: 'Approved',
    code: '#00e396',
    abbreviation: 'Approved',
  },
  {
    status: 'Pending',
    code: '#feb019',
    abbreviation: 'Pending',
  },
  {
    status: 'New',
    code: '#3F51B5',
    abbreviation: 'New',
  },
  {
    status: 'Declined',
    code: '#ff4560',
    abbreviation: 'Declined',
  },
  {
    status: 'Under Review',
    code: '#775dd0',
    abbreviation: 'Under Review',
  },
  {
    status: 'Cancelled',
    code: '#DE3E44',
    abbreviation: 'Cancelled',
  },
  {
    status: 'Cautionary Approval',
    code: '#00e396',
    abbreviation: 'Cautionary App',
  },
]

const Risk = [
  {
    value: 'Low Risk',
    code: '#E1FAEB',
    textCode: '#20693D',
    abbreviation: 'Low',
  },
  {
    value: 'High Risk',
    code: '#FAF0F0',
    textCode: '#BA0D0D',
    abbreviation: 'High',
  },
  {
    value: 'Medium Risk',
    code: '#FCF2E6',
    textCode: '#8A4D02',
    abbreviation: 'Medium',
  },
  {
    value: 'Needs Review',
    code: '#F0F5FC',
    textCode: '#1D5BBF',
    abbreviation: 'No Value',
  },
]

const detection = [
  {
    status: 'Early Detection',
    code: '#CDDBFF',
  },
  {
    status: 'Mature',
    code: '#3E3679',
  },
  {
    status: 'Tombstoned',
    code: '#8F9DE9',
  },
  {
    status: 'Unknown',
    code: '#775DD0',
  }
]

const DonutChart = {
  plotOptions: {
    pie: {
      startAngle: 0,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        enabled: false,
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: "60%",
        background: "transparent",
        height: '9rem',
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
            offsetX: -10,
            formatter: function (val) {
              return `${val}`;
            },
          },
          value: {
            show: true,
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 700,
            color: undefined,
            offsetY: -10,
            formatter: function (val) {
              return `$ ${val}`;
            },
          },
          total: {
            show: true,
            showAlways: true,
            label: " ",
            fontSize: "30px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: "#373d3f",
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },
  series: [],
  labels: Status.map(s => s.status),
  colors: Status.map(s => s.code)
};

const ApplicationValueChart = {
  plotOptions: {
    pie: {
      startAngle: 0,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        enabled: false,
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: "60%",
        background: "transparent",
        height: '9rem',
        labels: {
          show: true,
          name: {
            show: true,
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: undefined,
            offsetY: -10,
            offsetX: -10,
            formatter: function (val) {
              return `${val}`;
            },
          },
          value: {
            show: true,
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 700,
            color: undefined,
            offsetY: -10,
            formatter: function (val) {
              return `$ ${val}`;
            },
          },
          total: {
            show: true,
            showAlways: true,
            label: " ",
            fontSize: "30px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: "#373d3f",
            formatter: function (w) {
              return '$' + currencyFormatter(w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0));
            },
          },
        },
      },
    },
  },
  series: [],
  labels: Status.map(s => s.status),
  colors: Status.map(s => s.code)
};

const recurringPaymentChart = {
  series: [],
  options: {
    chart: {
      type: 'pie',
      width: 500,
      height: 500,
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 500,
          height: 500
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    labels: detection.map(s => s.status),
    colors: detection.map(s => s.code),
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
        expandOnClick: true,
        offsetX: 0,
        offsetY: 0,
        customScale: 1,
        dataLabels: {
          offset: 10,
          minAngleToShowLabel: 10,
        },
        donut: {
          size: '50%',
          background: 'transparent',
        },
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return `${val}%`;
      },
      style: {
        fontSize: '20px',
        colors: ['#BAB8CF']
      },
      background: {
        enabled: true,
        foreColor: '#202020',
        opacity: 1,
        borderRadius: '10px',
        borderWidth: 0,
        padding: 8,
        dropShadow: {
          enabled: false,
        }
      },
      dropShadow: {
        enabled: false,
      }
    },
  }
}

const monthlyStatementsChart = (months) => {
  return (
    {
      options: {
        chart: {
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        xaxis: {
          categories: months || ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        },
        yaxis: {
          show: true,
          labels: {
            formatter: function (val) {
              return `${val / 1000}K`
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#1F78B4', '#A6CEE3'],
        markers: {
          size: 1,
          colors: ['#1F78B4', '#A6CEE3'],
          strokeWidth: 5,
          strokeOpacity: 1,
          shape: "circle",
          radius: 2,
          strokeColors: ['#1F78B4', '#A6CEE3'],
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          offsetY: -50,
        },
        title: {
          text: 'Income & Expense Data',
          align: 'left',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: '14px',
            fontWeight: 'lighter',
          }
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val) {
              return formatCurrency(val)
            }
          },
        }
      }
    }
  )
}


const BarChart = months => {
  return {
    options: {
      chart: {
        type: 'bar',
        height: '10rem'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: months || ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      }
    }
  }
};

const creditHistoryAreaChart = {
  chart: {
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'straight'
  },
  legend: {
    position: "top",
    horizontalAlign: "right"
  }
}

const MonthlyBalanceChart = {
  chart: {
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '55%',
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },
  markers: {
    radius: 12,
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      colorStops: [
        [
          {
            offset: 0,
            color: "#88BCDE",
            opacity: 1
          },
          {
            offset: 100,
            color: "#9CCAE8",
            opacity: 1
          },
        ],
        [
          {
            offset: 0,
            color: "#7A81CC",
            opacity: 1
          },
          {
            offset: 100,
            color: "#ABB1EE",
            opacity: 1
          },
        ]
      ]
    }
  },
  colors: ['#88BCDE', '#7A81CC'],
  tooltip: {
    y: {
      formatter: function (val) {
        return `${val} USD`
      }
    }
  },
  legend: {
    position: "top",
    horizontalAlign: "right"
  }
}

const DBTLineChart = {
  chart: {
    type: 'line',
    toolbar: {
      show: false
    }
  },
  colors: ['#3F51B5'],
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 1,
    curve: 'straight'
  },
  markers: {
    size: 5,
    colors: '#3F51B5',
    hover: {
      sizeOffset: 2
    }
  },
  legend: {
    showForSingleSeries: true,
    position: "top",
    horizontalAlign: "right"
  }
}

const interpolateColor = (color1, color2, factor) => {
  const hex = (color) => parseInt(color.slice(1), 16);
  const interpolate = (c1, c2, factor) => Math.round(c1 + factor * (c2 - c1));

  const r1 = hex(color1) >> 16;
  const g1 = (hex(color1) >> 8) & 0x00FF;
  const b1 = hex(color1) & 0x0000FF;

  const r2 = hex(color2) >> 16;
  const g2 = (hex(color2) >> 8) & 0x00FF;
  const b2 = hex(color2) & 0x0000FF;

  const interpolatedColor = `#${((interpolate(r1, r2, factor) << 16) | (interpolate(g1, g2, factor) << 8) | interpolate(b1, b2, factor)).toString(16).padStart(6, '0')}`;
  
  return interpolatedColor;
};

const getEndColorForVerificationGauge = (percentage) => {
  if (isNaN(percentage)) return '#3DCA5F';

  const initialColor = '#3DCA5F';
  const finalColor = '#CA5F23';

  if (percentage === 0) return initialColor;
  if (percentage === 100) return finalColor;

  const factor = percentage / 100;
  return interpolateColor(initialColor, finalColor, factor);
};


const verificationRadialChart = (value, customvalue) => {
  return {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -100,
        endAngle: 100,
        hollow: {
          margin: 0,
          size: "80%",
          background: "#fff",
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#E4E4E4",
          strokeWidth: "30%",
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -1,
            left: 0,
            blur: 4,
            opacity: 0.3
          }
        },

        dataLabels: {
          show: true,
          value: {
            offsetY: -12,
            formatter: function (val) {
              if (isNaN(customvalue)) {
                return '-'
              } else {
                return parseInt(customvalue);
              }
            },
            color: "#3F51B5",
            fontSize: "31px",
            show: true,
            fontWeight: 800
          },
          name: {
            offsetY: 17,
            show: true,
            color: "rgba(125, 125, 125, 0.89)",
            fontSize: "12px",
            fontWeight: 600
          },
        }
      }
    },
    fill: {
      colors: ["#3DCA5F"],
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: [getEndColorForVerificationGauge(customvalue)],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: "round"
    },
    labels: [value || 'No Data']
  }
};

const creditRatingRadialChart = (value, customvalue) => {
  return {
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -100,
        endAngle: 100,
        hollow: {
          margin: 0,
          size: "80%",
          background: "#fff",
          dropShadow: {
            enabled: true,
            top: 0,
            left: 0,
            blur: 4,
            opacity: 0.1
          }
        },
        track: {
          background: "#E4E4E4",
          strokeWidth: "30%",
          margin: 0,
          dropShadow: {
            enabled: true,
            top: -1,
            left: 0,
            blur: 4,
            opacity: 0.3
          }
        },

        dataLabels: {
          show: true,
          value: {
            offsetY: -12,
            formatter: function (val) {
              return parseInt(customvalue || val);
            },
            color: "#3F51B5",
            fontSize: "31px",
            show: true,
            fontWeight: 800
          },
          name: {
            offsetY: 17,
            show: true,
            color: "rgba(125, 125, 125, 0.89)",
            fontSize: "12px",
            fontWeight: 600
          },
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#3F51B5"],
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 1,
        stops: [0, 100]
      }
    },
    stroke: {
      lineCap: "round"
    },
    labels: [value]
  }
};

const ApplicationOverview = [
  {
    name: 'Open Applications',
    icon: 'openApplication',
    value: 0
  },
  {
    name: 'No of Open Applications',
    icon: 'noOfApplication',
    value: 0
  },
  {
    name: 'Completed Applications',
    icon: 'completedApplication',
    value: 0
  },
  {
    name: 'No of Completed Applications',
    icon: 'noOfCompletedApplication',
    value: 0
  },
  {
    name: 'Average Duration',
    icon: 'average',
    value: 0
  },
]

const currency = ['Open Applications', 'Completed Applications']

export {
  DonutChart,
  BarChart,
  ApplicationOverview,
  ApplicationValueChart,
  Status,
  Risk,
  recurringPaymentChart,
  monthlyStatementsChart,
  detection,
  currency,
  creditHistoryAreaChart,
  MonthlyBalanceChart,
  DBTLineChart,
  creditRatingRadialChart,
  verificationRadialChart
}