import React, { useState, useEffect } from 'react'
import { Tabs, Tab } from 'components/shared/Tabs'
import { applicationTabs } from 'constants/constantVariables'
import EntityInfo from './Summaries/Components/EntityInfo/EntityInfo'
import AddDebtorAlert from './Summaries/Components/Overview/AddDebtorAlert'
import { getType, getFormModePayload } from 'constants/utilityFunctions'
import BusinessModal from 'components/Applicants/partial/Dialog/BusinessModal'
import OwnerModal from 'components/Applicants/partial/Dialog/OwnerModal'
import strings from 'strings'
import {
    dispatchFormMode,
    createOwner,
    updateOwner,
    updateDebtor,
    updateBusiness,
    getApplicantsById,
    requestApplicationInfo
} from 'actions/applicants'

const Overview = (props) => {

    const {
        applicantId,
        formMode,
        modalMode,
        businessData,
        debtorData,
        hideFmcsa,
        getSalesRepresentatives,
        // redux form props
        reset,
        dispatch,
        handleSubmit,
        dirty,
        change,
    } = props

    const [applicationInfo, setApplicationInfo] = useState({})
    const [tabValue, setTabValue] = useState(0)
    const [modal, setModal] = useState({ business: false, owner: false, debtor: false })
    const [loading, setLoading] = useState(false)

    const type = getType(tabValue)
    const business = applicationInfo.business || false
    const owners = applicationInfo.owners || []
    const debtor = applicationInfo.debtor || false

    // useEffect to get application info
    useEffect(() => {
        getApplicationInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // get application info
    const getApplicationInfo = () => {
        requestApplicationInfo(applicantId)()
            .then((res) => {
                setApplicationInfo(res);
            });
    }

    // function to handle edit/create click
    const dialogControl = (mode, uuid = false) => {
        reset('editForm')
        const formModePayload = getFormModePayload(mode, type, uuid)
        dispatch(dispatchFormMode(formModePayload))
        setModal({ ...modal, [type]: true })
    }

    // fuction to edit business
    const editBusiness = (formData) => {
        // if loading is true, means already a request is in process
        if (loading) return
        setLoading(true)
        if (typeof formData?.contractAmount === "string") {
            formData.contractAmount = Number(formData.contractAmount.split(",").join(""))
        }
        if (formData.hasOwnProperty('identifications') && Array.isArray(formData.identifications)
            && formData.identifications.length > 0) {
            if (typeof formData?.identifications[0].identification === "string") {
                if (formData.identifications[0].identification.length > 0) {
                    formData.identifications[0].identificationType = 'EIN'
                    formData.identifications[0].primary = true
                } else {
                    delete formData.identifications;
                }
            }
        }

        updateBusiness(applicantId, formData)()
            .then(() => {
                getApplicationInfo()
                getApplicantsById(applicantId)(dispatch)
            }).finally(() => {
                setModal({ business: false, owner: false, debtor: false })
                setTimeout(() => { setLoading(false) }, 1000)
            })
    }

    // onClose business, owner or debtor modal
    const onCloseModal = () => {
        setModal({ business: false, owner: false, debtor: false })
        dispatch(dispatchFormMode({
            mode: 'Default',
            type: ''
        }))
    }

    // function to edit debtor
    const editDebtor = (formData) => {
        // if loading is true, means already a request is in process
        if (loading) return
        setLoading(true)
        const type = formMode.mode === 'Create' ? 'POST' : 'PATCH'

        if (!formData.emails || !formData.emails[0]?.email) {
            formData.emails = null
        } else {
            formData.emails[0].emailType = 'Work'
            formData.emails[0].primary = true
        }

        if (!formData.phones[0] || !formData.phones[0]?.number) {
            formData.phones = null;
        } else {
            formData.phones[0].phoneType = 'Work';
            formData.phones[0].primary = true;
        }

        if (!formData.identifications || !formData.identifications[0]?.identification) {
            formData.identifications = null
        } else {
            formData.identifications[0].identificationType = 'EIN'
        }

        updateDebtor(applicantId, formData, type)()
            .then(() => {
                getApplicationInfo()
                getApplicantsById(applicantId)(dispatch)
            }).finally(() => {
                setModal({ business: false, owner: false, debtor: false })
                setTimeout(() => { setLoading(false) }, 1000)
            })
    }

    // function to submit owner
    const ownerSubmit = (formData) => {
        const callApi = formMode.mode === 'Create' ? createOwner : updateOwner

        if (!formData?.identifications[0]?.identification)
            delete formData.identifications

        if (formMode.mode === "Edit" && formData?.identifications?.length)
            formData.identifications = formData.identifications.map(data => ({
                ...data,
                identificationType: 'SSN',
                'primary': true
            }))

        callApi(applicantId, formData)()
            .then(() => {
                getApplicationInfo()
                getApplicantsById(applicantId)(dispatch)
                window.location.reload()
            }).finally(() => {
                setModal({ business: false, owner: false, debtor: false })
            })
    }

    return (
        <div className='m-3'>
            <h5 className='mb-0'> {strings.APPLICANT.SUMMARY.OVERVIEW} </h5>
            <Tabs
                data={applicationTabs}
                value={tabValue}
                onChange={(e, value) => setTabValue(value)}
                className='mb-5'
            />
            <Tab value={tabValue} index={0}>
                {business && <EntityInfo entity={business} entityType='business' dialogControl={dialogControl} />}
            </Tab>
            <Tab value={tabValue} index={1}>
                {
                    owners.map((owner, index) => {
                        return (<div key={owner.uuid}>
                            {index > 0 && <hr />}
                            <EntityInfo entity={owner} entityType='owner' disableAdd={owners.length > 2} dialogControl={dialogControl} />
                        </div>)
                    })
                }
            </Tab>
            <Tab value={tabValue} index={2}>
                {
                    debtor ?
                        <EntityInfo entity={debtor} entityType='debtor' dialogControl={dialogControl} />
                        :
                        <AddDebtorAlert dialogControl={dialogControl} />
                }
            </Tab>

            {/* Modals to edit business, owner and debtor */}

            <BusinessModal
                open={modal.business || modal.debtor}
                onClose={onCloseModal}
                onSubmit={handleSubmit(formData => tabValue ? editDebtor(formData) : editBusiness(formData))}
                dirty={dirty}
                change={change}
                type={type}
                modalMode={modalMode}
                getSalesRepresentatives={getSalesRepresentatives}
                hideFmcsa={hideFmcsa}
                addresses={tabValue ? debtorData?.addresses : businessData?.addresses}
                loading={loading}
            />

            <OwnerModal
                open={modal.owner}
                onClose={onCloseModal}
                onSubmit={handleSubmit(formData => ownerSubmit(formData))}
                modalMode={modalMode}
                change={change}
                dirty={dirty}
                loading={loading}
            />
        </div>
    )
}

export default Overview