import React, { useState } from 'react';
import { Accordion , AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// Implementation guidelines, use font class body-1-semibold if 
//you have only one line of text in the header, custom styles for custom headers...

const accordionTheme = createTheme({
    overrides: {
      MuiAccordionSummary: {
        root: {
          '&$expanded': {
            minHeight: 'inherit', // Removes that weird animation in the header when you open and close it
          },
        }
      },
      MuiAccordionDetails: {
        root: {
          borderTop: '1px solid #C4C4C4', // $Border default
        },
      },
      MuiAccordion: {
          root: {
            border: '1px solid #C4C4C4', // $Border default
            boxShadow: 'none', // Remove the default Material-UI box shadow
          },
          rounded: {
            '&:last-child': {
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            },
            '&:first-child': {
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
            },
          }
        },
      },
    }
  )

const RoxAccordion = ({ summary, details, expanded = false, key }) => {

    // We use this additional state to prevent rendering the details if it is not required.
    const [isOpen, setIsOpen] = useState(expanded);

    const handleToggle = (event, newIsOpen) => {
        setIsOpen(newIsOpen);
    }
      
    return (
        <ThemeProvider theme={accordionTheme}>
            <div className={`mt-4`} key={key}>
                <Accordion defaultExpanded={expanded} onChange={handleToggle}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                    {summary}
                    </AccordionSummary>
                    <AccordionDetails>
                      {isOpen && details}
                    </AccordionDetails>
                </Accordion>
            </div>
        </ThemeProvider>
    );
};

RoxAccordion.propTypes = {
  details: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  summary: PropTypes.node.isRequired,
  key: PropTypes.string
};


export default RoxAccordion;