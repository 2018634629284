import { useContext } from 'react'
import { ValidationContext } from './ShowFormValidation'

/**
 * use in all your custom redux form inputs, this reads the validation context and
 * checks when it should display the error message (on change, submit failed, etc)
 * check `ShowFormValidation` for more info
 * @param {object} inputObj the `meta` object provided by reduxForm when creating a component returns
 * @param {bool} inputObj.touched if the input is touched or not
 * @param {bool} inputObj.submitFailed if the user tried to submit the form
 * @param {string} inputObj.error the validation error message if any
 * @return {string} the error message only if it is supposed to be visible
 * (based on the context settings)
 */
export default function useGetErrorMessage(inputObj) {
  const validationSetting = useContext(ValidationContext) || 'change'

  if (!inputObj || !inputObj.error) return
  const { touched, submitFailed, error } = inputObj

  switch (validationSetting) {
    case 'change':
      return touched || submitFailed ? error : undefined
    case 'submit':
      return submitFailed ? error : undefined
    default:
      throw new Error(
        `Unkown validation settings passed to 'ShowFormValidation': ${validationSetting}`
      )
  }
}
