import { produce } from 'immer'
import {
    SET_PLAID_ACCESS_TOKEN, SET_PLAID_LINK_TOKEN,
} from 'actions/plaid'

const initialState = {
    plaidAccessToken: '',
    plaidLinkToken: ''
}

export const plaidReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_PLAID_ACCESS_TOKEN:
                draft.plaidAccessToken = action.data
                break;
            case SET_PLAID_LINK_TOKEN:
                draft.plaidLinkToken = action.data
                break;
            default:
                break;
        }
    })
}