import {
    AccordionDetails,
    withStyles,
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
} from '@material-ui/core';

export const Accordion = withStyles({
    root: {
        border: '1px solid #C6C6C6',
        borderRadius: 12,
        boxShadow: 'none',
        marginBottom: 20,
        '&:before': {
            display: 'none',
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        height: 51,
        '&$expanded': {
            minHeight: 51
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
            border: 'none'
        },
    },
    expanded: { borderBottom: '1px solid #C6C6C6', },
    expandIcon: { border: 'none' }
})(MuiAccordionSummary);

export { AccordionDetails }