import React, { useEffect, useState } from 'react'
import TableComponent from 'components/shared/TableComponent'
import { auditColums } from 'constants/application'
import { getUccAuditData } from 'actions/applicants'
import { getPaginationHeaders } from 'constants/utilityFunctions'

const UccAuditTab = ({ selectedEntity, applicationId }) => {

    const [loading, setLoading] = useState(false)
    const [auditResponse, setAuditResponse] = useState(null)
    const [pagination, setPagination] = useState({})

    const auditData = auditResponse?.response || []
    const auditPagesAndRecords = getPaginationHeaders(auditResponse)

    const params = {
        uuid: selectedEntity?.uuid,
        type: selectedEntity?.type
    }

    useEffect(() => {
        setLoading(true)
        getUccAuditData({ applicationId, params, pagination })()
            .then(res => {
                setAuditResponse(res)
            })
            .finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [pagination])

    return (
        <TableComponent
            keyField='date'
            data={auditData}
            columns={auditColums}
            totalRecords={auditPagesAndRecords.totalRecords}
            totalPages={auditPagesAndRecords.totalPages}
            isLoading={loading}
            onChangePagination={setPagination}
            paginationLimit={5}
        />
    )
}

export default UccAuditTab