import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import Async from 'react-select/async'

import getCommonSelectOptions from '../getCommonSelectOptions'
import { isEmpty } from 'tools/objectHelper'

import BaseDropdown from '../BaseDropdown'

const AsyncDropdown = props => {
  const {
    getOptions,
    defaultOptions, // load default options onload
    dependentPropKey,
    dependentPropValue,
    queryParamName = 'query',
    value,
    onChange
  } = props

  const [key, setKey] = useState(true)
  // we need to dynamically use "key" (to re-render the dropdown) when the
  // dropdown depends on another dropdown's value
  // scenario: when that other dropdown value changes, we need to update the
  // option list in this dropdown because `dependentPropValue` has updated, in
  // `react-select` there is no manual way to do that, so changing key will do
  // the trick, check issue #1879 (in react-select) for more info
  useEffect(() => {
    setKey(k => !k)
  }, [dependentPropKey, dependentPropValue])

  const handleGetOptions = useCallback(() => {
    debounce((query, cb) => {
      let params = { [queryParamName]: query }
      // if prop key is provided mark it as required, don't fetch data if it is not set
      if (dependentPropKey && isEmpty(dependentPropValue)) {
        return cb([])
      }
      if (dependentPropValue) {
        params[dependentPropKey] = dependentPropValue
      }
      getOptions(params).then(result => cb(result))
    }, 500)
  },
    [getOptions, queryParamName, dependentPropKey, dependentPropValue]
  )

  // We only support value as object in async dropdowns, we need to handle the
  // conversion and initialization to be objects instead of values

  return (
    <BaseDropdown {...props}>
      <Async
        // used for any additional options passed to selected that we don't expect
        {...props}
        key={key}
        defaultOptions={defaultOptions}
        loadOptions={handleGetOptions}
        value={value || null}
        onChange={onChange}
        // common styling options that will be used in both Normal and Async Dropdowns
        {...getCommonSelectOptions(props)}
      />
    </BaseDropdown>
  )
}

AsyncDropdown.propTypes = {
  // a function that returns a promise that is an array with {label, value} objects
  // this function expects an object containing the query param, they key is the value of "queryParamName" (defaults to "query")
  // if dependentPropKey is passed it will also be passed with query in the
  // object
  getOptions: PropTypes.func,
  defaultOptions: PropTypes.bool,
  dependentPropKey: PropTypes.string,
  dependentPropValue: PropTypes.string,
  queryParamName: PropTypes.string,

  value: PropTypes.any,
  onChange: PropTypes.func,

  // common ui options
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  tooltip: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  menuPosition: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge'])
}

export default AsyncDropdown
