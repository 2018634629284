import React, { useEffect, useState } from 'react'
import {
    DialogActions,
    DialogTitle,
    DialogContent,
    Button
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
    InputField,
    DropdownField
} from 'components/forms/reduxForm'
import {
    phoneNumberValidation,
    validateEmail,
    maxLength20
} from 'tools/forms/validators'
import { Dialog } from 'components/shared/Dialog'
import { PasswordValidator } from 'components/PasswordValidation/PasswordValidator'
import strings from 'strings'

const passwordValidation = (values, allValues) => {
    const newPassword = allValues['newPassword']
    if (newPassword === values) {
        return undefined
    } else {
        return 'Passwords dont match'
    }
}

const UserForm = (props) => {
    const {
        open,
        onClose,
        onSubmit,
        mode = 'Create',
        submitting,
        pristine,
        loading
    } = props
    const [openTooltip, setOpenTooltip] = useState(false)
    const [valid, setValid] = useState(false)
    const gs = useSelector(state => state)
    const { newPassword } = gs.form?.profileForm?.values || {}
    const userRoles = gs.root.rolesList.map(data => ({ label: data.name, value: data.roleId, systemName: data.systemName }))

    useEffect(() => {
        if (mode !== 'Create')
            setValid(true)
    }, [mode]);

    return (
        <Dialog
            open={open}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitle>
                {mode} {strings.USER}
            </DialogTitle>
            <DialogContent>
                <InputField
                    name='firstName'
                    label='First Name *'
                    required
                />
                <InputField
                    name='lastName'
                    label='Last Name *'
                    required
                />
                <DropdownField
                    name="roles[0]"
                    label="Role"
                    options={userRoles}
                    getFullValue
                    required
                />
                <InputField
                    name='phone'
                    label='Phone *'
                    validate={phoneNumberValidation}
                    required
                    isphoneNumber
                />
                <InputField
                    name='email'
                    label='Email *'
                    validate={validateEmail}
                    required
                    disabled={mode === 'Edit'}
                />
                {
                    mode === strings.CREATE && (
                        <>
                            <PasswordValidator password={newPassword} isValid={setValid} open={openTooltip} >
                                <InputField
                                    name='newPassword'
                                    type='password'
                                    label='newPassword *'
                                    validate={maxLength20}
                                    required
                                    onFocus={() => setOpenTooltip(true)}
                                    onBlur={() => setOpenTooltip(false)}
                                />
                            </PasswordValidator>
                            <InputField
                                name='password'
                                type='password'
                                label='confirmPassword *'
                                validate={passwordValidation}
                                required
                            />
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={onClose}>
                    {strings.CANCEL}
                </Button>
                <Button
                    variant="contained"
                    color='primary'
                    onClick={valid ? onSubmit : null}
                    disabled={loading || submitting || pristine || !valid}
                >
                    {mode} {strings.USER}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UserForm