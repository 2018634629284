import { produce } from 'immer'
import {
    SET_CLIENTS_LIST,
    SET_CLIENT,
    RESET_FORM
} from 'actions/client'

const initialState = {
    clients: {
        clientsList: [],
        headers: {}
    },
    clientById: {}
}

export const clientReducer = (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_CLIENTS_LIST:
                draft.clients.clientsList = action.data
                draft.clients.headers = action.headers
                break;
            case SET_CLIENT:
                draft.clientById = action.data
                break;
            case RESET_FORM:
                draft.clientById = {}
                break;
            default:
                break;
        }
    })
}