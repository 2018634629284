import React from 'react'
import PropTypes from 'prop-types'
import Switch from '@material-ui/core/Switch'

import { useGetErrorMessage } from 'components/forms/reduxForm'
import ValidationError from 'components/forms/ValidationError'

import styles from './SwitchField.module.scss'

const SwitchField = props => {
  const { input, label, disabled } = props
  const error = useGetErrorMessage(props.meta)

  return (
    <div className="switch-input">
      {label !== undefined && <label className="label-text">{label}</label>}
      <Switch
        checked={input.value}
        onChange={e => input.onChange(e.target.checked)}
        value={input.name}
        disabled={disabled}
        className={disabled ? styles.disabledSwitch : ''}
        color="primary"
      />
      <ValidationError switchIcon={true} error={error} />
    </div>
  )
}

SwitchField.propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string
}

export default SwitchField
