import React from 'react';
import PrepareTooltip from 'components/shared/Tooltip'

// function that takes a string parameter and returns a formatted currency value
// input 16900,4559999999 - output $16,900.45
export function formatCurrency(input) {
    if (input === '-') return '$0.00';
    let formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(Number(input));
    return formattedAmount;
}

// function that takes a string parameter and returns a formatted percentage value
// input 34 - output +34% || input -2 - output -2%
export function formatPercentage(numberString) {
    const number = parseFloat(numberString);
    const formattedNumber = Math.abs(number) + '%';
    return number >= 0 ? '+' + formattedNumber : '-' + formattedNumber;
}

// function that takes a cell value and returns a formatted tooltip
export function formatTooltip(value) {

    let finalValue = ''

    if (typeof value === 'object' && value?.length > 0) {
        finalValue = value.join(', ')
    } else if (value) {
        finalValue = value
    } else {
        finalValue = '---'
    }

    return (
        <PrepareTooltip
            theme='white'
            title={finalValue}
            placement='top'
        >
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>
                {finalValue}
            </div>
        </PrepareTooltip>
    )
}

export function translateRisk(risk) {
    if (risk === null) {
        return 'Needs Review'
    }
    if (risk >= 75) {
        return 'Low Risk'
    }
    if (risk >= 50) {
        return 'Medium Risk'
    }
    return 'High Risk'
}

// function that takes an object and returns a string with the values separated by a comma
export function joinAttributes(obj) {
    const attributes = [];

    for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key]) {
            attributes.push(obj[key]);
        }
    }

    return attributes.join(', ');
}

