import React from 'react'
import { Alert } from '@material-ui/lab'
import strings from 'strings'
import { DownloadUccEmptyReport } from 'components/PdfTemplates'
import { getIndexDateText } from 'constants/utilityFunctions'

const uccStrings = strings.APPLICANT.UCC

const UccSearchNoResults = ({ applicationId, searchUuid, name, indexDate }) => {

    const indexDateText = getIndexDateText(indexDate)

    const alertText = name ?
        `${uccStrings.NO_RESULTS_PREFIX}${name}${uccStrings.NO_RESULTS_SUFFIX}`
        :
        uccStrings.NO_RESULTS

    return (
        <>
            <div className='d-flex justify-content-between align-items-center'>
                <Alert severity='error' className='flex-fill mr-2' >{alertText}</Alert>
                <div className='align-self-center'>
                    <DownloadUccEmptyReport
                        applicationId={applicationId}
                        interactionId={searchUuid}
                        buttonText={strings.DOWNLOAD_REPORT}
                        variant='outlined'
                    />
                </div>
            </div>
            <p className='mb-0 mt-1 text-right'>{indexDateText}</p>
        </>
    )
}

export default UccSearchNoResults