import React from 'react'
import { getIndexDateText } from 'constants/utilityFunctions'
import './UccCard.scss'
import strings from "../../../../../../../../strings";

const UccHeaderCard = ({ button, entityName, address, arrayOfFilings, indexDate }) => {

    const numberOfFilings = arrayOfFilings.length + ' ' + (arrayOfFilings.length > 1 ? strings.FILINGS : strings.FILING)
    const indexDateText = getIndexDateText(indexDate)

    return (
        <>
            <div className='flex-grow-1'>
                <p className='m-0'> <strong>{entityName}</strong></p>
                <p className='m-0'>{address}</p>
            </div>
            <div>
                <div className='d-flex justify-content-end'>
                    <div className='blueLabel mr-3'> {numberOfFilings} </div>
                    {!!button && button}
                </div>
                <p className='mb-0 mt-3'> {indexDateText} </p>
            </div>
        </ >
    )
}

export default UccHeaderCard