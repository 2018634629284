import React from 'react'
import MaterialTabs from '@material-ui/core/Tabs'
import MaterialTab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const AntTabs = withStyles({
    root: {
        // style for root element
    },
    indicator: {
        backgroundColor: 'primary',
    },
})(MaterialTabs);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 50,
        // marginRignt: 20,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(2),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Segoe UI',
            'Roboto',
            'Helvetica Neue',
            'Arial',
            'sans-serif',
            'Apple Color Emoji',
            'Segoe UI Emoji',
            'Segoe UI Symbol',
        ].join(','),
        '&:hover': {
            color: 'primary',
            opacity: 1,
        },
        '&$selected': {
            color: 'primary',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: 'primary',
        },
    },
    selected: {},
}))((props) => <MaterialTab disableRipple {...props} />);


const Tabs = (props) => {
    const {
        value,
        onChange,
        data = [],
        children,
        scrollable,
    } = props
    return (
        <AntTabs
            value={value}
            indicatorColor='primary'
            textColor='primary'
            onChange={onChange}
            aria-label='disabled tabs example'
            variant={scrollable ? "scrollable" : undefined}
            scrollButtons={scrollable ? "auto" : undefined}
        >
            {
                data.map((item, index) => {
                        return <AntTab {...item} key={index} />
                })
            }
            {children}
        </AntTabs>
    )
}

const Tab = (props) => {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (children)}
        </div>
    );
}

Tabs.propTypes = {
    scrollable: PropTypes.bool,
}

export {
    Tabs,
    Tab
}