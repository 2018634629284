import thunk from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import history from 'tools/history'
import createRootReducer from 'reducers';

export default function configureStore(initialState) {
  let middlewares = [thunk, routerMiddleware(history)]
  if (process.env.NODE_ENV === 'development') {
    const loggerMiddleware = createLogger({ collapsed: true })
    middlewares.push(loggerMiddleware)
  }

  const enhancer = composeWithDevTools(applyMiddleware(...middlewares))
  const store = createStore(createRootReducer, initialState, enhancer)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(import('../reducers'))
    )
  }

  return store
}
