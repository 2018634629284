import React, { useState } from 'react'
import Button from 'components/shared/Button'
import { InputField, DropdownField } from 'components/forms/reduxForm'
import { statesOptions } from 'constants/constantVariables'
import strings from 'strings'

const SosSearchFields = (props) => {

    const { loading, handleSosSearch, isQuickSearch } = props

    // form data
    const [businessName, setBusinessName] = useState('')
    const [state, setState] = useState('')

    return (
        <div className='sosFieldContainer'>
            <div className='d-flex'>
                <InputField
                    name='businessName'
                    label='Business Name'
                    required
                    id='businessNameSos'
                    onChange={(e) => setBusinessName(e.target.value)}
                />
            </div>
            <div className='d-flex'>
                <DropdownField
                    name={'state'}
                    label='Select State'
                    options={statesOptions.USA}
                    required
                    isSearchable
                    onChange={(e) => setState(e)}
                    id='stateSos'
                />
            </div>
            <Button
                onClick={() => handleSosSearch({ businessName, state })}
                loading={loading}
                id={`perform-sos-search-${isQuickSearch ? 'qs' : 'app'}`}
                disabled={!state || !businessName}
            >
                {strings.SEARCH}
            </Button>
        </div>
    )
}

export default SosSearchFields