import React from "react"
import {FormProvider, useForm} from 'react-hook-form'
import { TextField } from '@material-ui/core'
import Button from "components/shared/Button/index"
import './UccSearchTab.scss'
import { buildUccDefaultFormValues } from "constants/application"
import strings from "strings"
import { useSelector } from "react-redux"
import DropDownPicker from "../../../../../../../forms/ReactHookForm/DropDown/DropDownPicker";
import {statesOptions} from "../../../../../../../../constants/constantVariables";
import FormCheckBox from "../../../../../../../forms/ReactHookForm/Checkbox/FormCheckBox";
import { Alert } from '@material-ui/lab'

const UccSearchForm = ({ selectedEntity, onSubmit, loading }) => {

    const pastSearchParameters = useSelector(state => state.applicant.ucc.searchParameters)
    const clientState = useSelector(state => state?.user?.userDetails?.securityContext?.client);
    const fcsClientReference = useSelector(state => state?.applicant?.applicantById?.business?.fcsClientReference);

    // react hook form
    const methods = useForm(buildUccDefaultFormValues(pastSearchParameters, selectedEntity))
    const { register, handleSubmit, formState, control} = methods
    const { errors } = formState

    const clientReferenceIsMissing = !fcsClientReference && clientState.clientReferenceRequired;

    // entity fields based on entity type
    const entityFields = (
        selectedEntity.type === 'BUSINESS' ?
            <TextField
                variant="outlined"
                size='small'
                id="searchOrganizationName"
                label="Organization Name"
                {...register('searchOrganizationName', { required: 'Organization Name is required' })}
                error={!!errors.searchOrganizationName}
                helperText={errors.searchOrganizationName?.message}
            />
            :
            <div className="d-flex" style={{ gap: '10px' }}>
                <TextField
                    variant="outlined"
                    size='small'
                    id="searchFirstName"
                    label="First Name"
                    {...register('searchFirstName', { required: 'First Name is required' })}
                    error={!!errors.searchFirstName}
                    helperText={errors.searchFirstName?.message}
                />
                <TextField
                    variant="outlined"
                    size='small'
                    id="searchLastName"
                    label="Last Name"
                    {...register('searchLastName', { required: 'Last Name is required' })}
                    error={!!errors.searchLastName}
                    helperText={errors.searchLastName?.message}
                />
            </div>
    )

    return (
        <>
            {
                clientReferenceIsMissing &&
                <Alert severity="info" className="mb-2">
                    {clientState?.clientName} {strings.INFO_NO_CLIENT_REFERENCE}
                </Alert>
            }
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className="uccSearchForm">
                    {entityFields}
                    <DropDownPicker
                        control={control}
                        id='stateCode'
                        options={statesOptions.USA}
                        label={strings.LABEL.STATE}
                        name={'stateCode'}
                        labelField={'value'}
                        valueField={'value'}
                        error={!!errors?.stateCode}
                        helperText={errors?.stateCode?.message}
                    />
                    <TextField
                        size='small'
                        variant="outlined"
                        id="filedDate"
                        label="UCC Filed Date"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        {...register('date', { required: 'UCC Filed Date is required' })}
                        error={!!errors.date}
                        helperText={errors.date?.message}
                    />
                    <FormCheckBox
                        label="Include Inactive Filings"
                        id="includeInactiveFilings"
                        name='includeInactiveFilings'
                        register={register}
                    />
                    <div>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loading}
                            id="searchButton"
                            className="preventShrink"
                            disabled={clientReferenceIsMissing}
                        >
                            {strings.SEARCH}
                        </Button>
                    </div>
                </div>
            </form>
            </FormProvider>
        </>
    )
}

export default UccSearchForm