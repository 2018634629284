import React, { useEffect, useState } from 'react'
import strings from 'strings'
import { Alert } from '@material-ui/lab'
import { getSosQuickSearch, getSosSearch } from 'actions/applicants'
import SearchResultCard from './SearchResultCard'
import { MAX_RETRIES_FOR_SOS_SEARCH } from 'constants/application'

const sosStrings = strings.APPLICANT.SOS

const CurrentSearch = (props) => {

    const { viewResult, currentSearch, search, isQuickSearch, applicantId } = props
    const sosSearchId = currentSearch?.sosSearchId
    const state = currentSearch?.state

    const getSearchResult = isQuickSearch ? getSosQuickSearch : getSosSearch

    const [searchResult, setSearchResult] = useState(false)
    const [possibleAlternatives, setPossibleAlternatives] = useState([])

    const [alertProp, setAlertProp] = useState({ msg: sosStrings.AWAITING_RESPONSE, severity: 'info' })

    useEffect(() => {
        tryGetSearchResult()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /* function to get search result while the search does not have a status
    this function will be called 24 times, for approximately 4 minutes
    if after 4 minutes the search does not have a status, an alert will be shown */
    const tryGetSearchResult = (tryNumber = 0) => {
        if (tryNumber < MAX_RETRIES_FOR_SOS_SEARCH) {
            let info = { searchId: sosSearchId }
            if (!isQuickSearch) info.applicantId = applicantId
            getSearchResult(info)()
                .then(res => {
                    if (res?.sosSearchStatus) {
                        handleResponseFinished(res)
                    } else {
                        tryGetSearchResult(tryNumber + 1)
                    }
                })

        } else {
            setAlertProp({ msg: sosStrings.TIMEOUT, severity: 'warning' })
        }
    }

    const handleResponseFinished = (res) => {
        if (res.sosSearchStatus === 'COMPLETED') {
            setSearchResult(res?.response?.results[0])
            setPossibleAlternatives(res?.response?.possibleAlternatives)
            return;
        }

        let alertMessage, severityValue;
        if (res.sosSearchStatus === 'NO_RESULTS') {
            alertMessage = sosStrings.NO_RESULTS_PREFIX + res?.searchQuery + sosStrings.NO_RESULTS_SUFFIX
            severityValue = 'warning'
        }
        if (res.sosSearchStatus === 'ERROR') {
            alertMessage = sosStrings.SEARCH_ERROR
            severityValue = 'error'
        }
        setAlertProp({ msg: alertMessage, severity: severityValue })
    }


    const handleAlternativeSearch = (sosId, businessName) => {
        search({ sosId, state, businessName })
    }

    return (
        <div className='mt-5'>
            {
                searchResult ?
                    <SearchResultCard
                        searchResult={{ ...searchResult, sosSearchId }}
                        possibleAlternatives={possibleAlternatives}
                        viewResult={viewResult}
                        searchAlternative={handleAlternativeSearch}
                        state={state}
                        isQuickSearch={isQuickSearch}
                    />
                    :
                    <Alert severity={alertProp.severity}> {alertProp.msg} </Alert>
            }
        </div>
    )
}

export default CurrentSearch