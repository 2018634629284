import React from 'react'

import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import { required as requiredValidation } from 'tools/forms/validators'
import { number } from 'tools/forms/parsers'
import { number as numberFormatter } from 'tools/forms/formatters'

import { useGetErrorMessage } from 'components/forms/reduxForm'
import Dropdown, { AsyncDropdown } from 'components/forms/Dropdown'

const DropdownFieldDetailed = ({ isAsync, input, onChange, meta, getFullValue, ...rest }) => {
  const Component = isAsync ? AsyncDropdown : Dropdown
  const onSelect = val => {
    const value = getFullValue ? val : val?.value
    if (onChange) {
      onChange(value)
    }
    input.onChange(value)
  }

  const error = useGetErrorMessage(meta)
  return (
    <Component
      name={input.name}
      value={input.value}
      onChange={onSelect}
      error={error}
      {...rest}
    />
  )
}

const DropDownField = ({
  required,
  validate = [],
  type,
  parse,
  format,
  className,
  label,
  isClearable = false,
  ...rest
}) => {
  if (typeof validate === 'function') {
    validate = [validate]
  }
  // if the user passes required prop and they don't have required in the
  // validation then add it
  if (required && !validate.some(v => v === requiredValidation)) {
    validate.unshift(requiredValidation)
  }

  if (type === 'number') {
    parse = parse || number
    format = format || numberFormatter
  }

  // only if the label is passed then parse it and pass it to the component
  let parsedLabel = undefined
  if (label) {
    parsedLabel = label + (required ? ' *' : '')
  }

  return (
    <Field
      component={DropdownFieldDetailed}
      isClearable={isClearable}
      validate={validate}
      parse={parse}
      format={format}
      label={parsedLabel}
      className={className}
      {...rest}
    />
  )
}

DropDownField.propTypes = {
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  validate: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
    PropTypes.bool
  ]),
  // only used when `isAsync=false`
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number
      ])
    })
  ),
  getFullValue: PropTypes.bool,
  // the following are only used when `isAsync=true`, to know more read
  // PropTypes for `AsyncDropdown` component
  isAsync: PropTypes.bool,
  getOptions: PropTypes.func,
  defaultOptions: PropTypes.bool,
  dependentPropKey: PropTypes.string,
  dependentPropValue: PropTypes.string,
  queryParamName: PropTypes.string
}

export default DropDownField
