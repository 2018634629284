import React from 'react'
import {Grid, Typography} from '@material-ui/core'
import strings from "../../../../../../../strings";
import moment from "moment/moment";
import RoxAccordion from "../../../../../../shared/RoxAccordion";

const FilingDetailsGroupDataAccordion = ({data}) => {

    const uccSearchParameters = data?.uccSearchParameters
    const uccGroupInformation= data?.uccGroupInformation
    const numberOfFilings=data?.response.Results?.length
    const indexDate= data?.response?.Header?.IndexDate

    function correctNameOrder() {
        const name = uccGroupInformation?.entityName;
        if (uccGroupInformation?.entityName.includes(',')) {
            let parts = name.split(',');
            return parts[1].trim() + ' ' + parts[0].trim();
        } else {
            return name;
        }
    }

    function getEntityType() {
        if (uccSearchParameters?.businessName && uccSearchParameters.businessName.trim() !== '') {
            return 'Business';
        }
        return 'Person';
    }


    const gridElement = (label, value) => {
        return (
            <>
                <Grid className='leftGridElement' item sm={6}>{label + ':'}</Grid>
                <Grid className='rightGridElement' item sm={6}>{value}</Grid>
            </>
        )
    }

    return (
        <RoxAccordion
            expanded={true}
            summary={
                <Typography>{strings.DETAILS}</Typography>
            }
            details={
                <Grid container spacing={2}>
                    {gridElement(strings.APPLICANT.UCC.SEARCH_TYPE, getEntityType())}
                    {gridElement(strings.NAME, correctNameOrder())}
                    {gridElement(strings.APPLICANT.UCC.NUMBER_OF_FILINGS,
                        <span className={`ml-3 blueLabel`}>
                    {numberOfFilings + ' ' + strings.APPLICANT.UCC.FILINGS}
                </span>
                    )}
                    {gridElement(strings.LABEL.ADDRESS,
                        (uccGroupInformation?.address && (uccGroupInformation?.address + ', ')) +
                        (uccGroupInformation?.city && (uccGroupInformation?.city + ', ')) +
                        uccGroupInformation?.state
                    )}

                    {gridElement(strings.APPLICANT.UCC.SEARCH_DATE,
                        moment(uccSearchParameters?.searchDate).format('MM/DD/YYYY')
                    )}

                    {gridElement(strings.APPLICANT.UCC.UCC_FILING_LAST_UPDATED_ON,
                        moment(indexDate).format('MM/DD/YYYY')
                    )}

                    {gridElement(strings.APPLICANT.UCC.INCLUDE_INACTIVE_FILINGS,
                        uccSearchParameters?.includeInactiveFilings ? 'Yes' : 'No'
                    )}

                </Grid>
            }
        />
    )
}

export default FilingDetailsGroupDataAccordion