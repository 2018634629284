export const DATE_FORMAT = 'MM/DD/YYYY'
export const DATE_FORMAT_BACKEND = 'YYYYMMDD'
export const DATE_FORMAT_BACKEND_REQUEST = 'YYYY-MM-DD'

export const DATE_TIME_FORMAT_SELECT = 'MM/DD/YYYY h:mm a'
export const DATE_TIME_FORMAT = 'MM/DD/YYYY (hh:mm a)'
export const DATE_TIME_FORMAT_VERBOSE = 'MM/DD/YYYY [at] hh:mm A'
export const DATE_TIME_FORMAT_BACKEND_REQUEST = `YYYY-MM-DDTHH:mm:ssZ`

export const DATE_BEGINNING = '01/01/0000'
export const DATE_FOREVER = '12/31/9999'

export const TIMEZONE = 'America/New_York' //NOTE: change this to convert all "createdAt" times across application
