import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx'
import { amendmentTitle, countryOptions, mapContinuationPreview, mapDebtorPreview, statesOptions } from 'constants/constantVariables';
import { DropdownField, InputField, CheckboxField } from 'components/forms/reduxForm';
import { Accordion, AccordionSummary, AccordionDetails } from 'components/forms/Accordion';
import { Radio } from 'components/forms/Radio';
import strings from 'strings';
import EditIcon from 'styles/images/icons/modalEdit.svg'
import DeleteIcon from 'styles/images/icons/modalDelete.svg'
import "./UccEdit.scss"
import { isNotBlank } from 'tools/forms/validators';

const clientString = strings.APPLICANT.CLIENTS
export function Preview(props) {
    const {
        data,
        type,
        hint,
        expand,
        selectable,
        handleEdit,
        showActions,
        handleDelete,
        selectedValue,
        setSelectedValue,
        isClearable = true,
        downLoadAttachmentByFileId
    } = props
    let defaultExpanded = expand || !selectable

    if (['debtor', 'securedParty'].includes(type) && data.length > 1 && !expand)
        defaultExpanded = false

    const attachmentsJsx = attachments => attachments.map(({ uuid, fileName }, i) =>
        <div key={uuid} className={clsx(i && 'mt-1', 'download-file')} onClick={() => downLoadAttachmentByFileId(uuid, fileName)}>
            {fileName}
        </div>)

    if (data[0])
        return <>
            {
                hint &&
                <div className={`hint mb-3 ${selectedValue.error}`}>{clientString.SELECT}</div>
            }
            {
                data.map((list, i) => {
                    let title = ''
                    if (type === 'continuation')
                        title = strings.APPLICATION
                    else if (type === 'collateral')
                        title = strings.COLLATERAL
                    else
                        title = list.organizationName ? list.organizationName : `${list.lastName} ${list.firstName}`

                    return (
                        <Fragment key={i}>
                            <Accordion square key={i} defaultExpanded={defaultExpanded}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <div className='action-header'>
                                        <div className='d-flex align-items-center'>
                                            {selectable &&
                                                <Radio
                                                    checked={selectedValue.value}
                                                    value={i}
                                                    onChange={(value) => setSelectedValue({ value, error: '' })}
                                                    isClearable={isClearable}
                                                    size='small'
                                                />
                                            }
                                            <span className='heading-style-1 text-uppercase'>{title}</span>
                                        </div>
                                        {
                                            showActions &&
                                            <div className='action' onClick={(event) => event.stopPropagation()}>
                                                <div className='icon' onClick={() => handleEdit(list, type, i)} >
                                                    <img src={EditIcon} alt='Edit' />
                                                </div>
                                                <div className='icon' onClick={() => handleDelete(list, type, i)} >
                                                    <img src={DeleteIcon} alt='Delete' />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div className='debtor-details'>
                                        {
                                            type === 'collateral' ?
                                                <>
                                                    <div className='preview-label'>
                                                        {list.collateralType === 'both' ?
                                                            <>
                                                                <div>{clientString.DESCRIPTION}</div>
                                                                <div className='mt-1'>{strings.APPLICANT.ATTACHMENTS.ATTACHMENTS}</div>
                                                            </> :
                                                            list.collateralType === 'attachments' ? strings.APPLICANT.ATTACHMENTS.ATTACHMENTS :
                                                                clientString.DESCRIPTION}
                                                    </div>
                                                    <div className='preview-value'>
                                                        {
                                                            list.collateralType === 'both' ?
                                                                <>
                                                                    <div>{list.data.collateral}</div>
                                                                    <div className='mt-1'>{attachmentsJsx(list.data.attachments)}</div>
                                                                </> :
                                                                list.collateralType === 'attachments' ?
                                                                    attachmentsJsx(list.data.attachments) :
                                                                    list.data.collateral
                                                        }
                                                    </div>
                                                </> :
                                                (type === 'continuation' ? mapContinuationPreview(list) : mapDebtorPreview(list))
                                                    .map(d =>
                                                        <Fragment key={d.title}>
                                                            <div className='preview-label'>{d.title}</div>
                                                            <div className='preview-value'>{d.value}</div>
                                                        </Fragment>
                                                    )
                                        }
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Fragment>
                    )
                })
            }
        </>

    return <></>
}


// if field = 'reference' then returns only filerReference, clientReference, securedPartyMasking fields
// if field = 'all' or anything else, it returns a UCC3 section for a person or organization
export function RenderFields({ field = 'all', formValues, details = {}, type, change }) {
    const clientReferenceRequired = useSelector(state => state.user.userDetails.securityContext.client.clientReferenceRequired)

    const [stateOptions, setStateOptions] = useState(statesOptions.USA);

    const handleCountryChange = (selectedCountry) => {
        change(`address.state`, '')
        setStateOptions(statesOptions[selectedCountry]);
    };

    const filerReference = <>
        <div className='hint'>{clientString.ENTER_REFERENCE}</div>
        <InputField
            name='filerReference'
            className='mb-3'
            label={strings.APPLICANT.UCCFILING.LABEL.FILER_REFRENCE}
        />

        <div className='hint'>{clientString.CLIENT_REFERENCE}</div>
        <InputField
            name='clientReference'
            className='mb-3'
            label={strings.APPLICANT.UCCFILING.LABEL.CLIENT_REFERENCE}
            required={clientReferenceRequired}
            validate={isNotBlank}
        />
        {
            ['debtor', 'collateral', 'direct-termination'].includes(type) && (
                <CheckboxField
                    name="securedPartyMasking"
                    label={strings.APPLICANT.UCCFILING.LABEL.SECURED_MASKING}
                    className='custom-text'
                    id='securedPartyMasking'
                />
            )
        }
    </>

    if (field === 'reference')
        return filerReference

    return (
        <>
            <div className='hint'>{amendmentTitle[details?.title]}</div>
            <div className='fields mb-3'>
                <InputField
                    name='organizationName'
                    label={strings.LABEL.ORGANIZATION_NAME}
                    disabled={!!(formValues?.lastName || formValues?.firstName || formValues?.middleName)}
                    required={!formValues.firstName}
                />
                <InputField
                    name='firstName'
                    label={strings.LABEL.FIRST_NAME}
                    disabled={!!formValues.organizationName}
                    required={!formValues.organizationName}
                />
                <InputField
                    name='middleName'
                    label={strings.LABEL.MIDDLE_NAME}
                    disabled={!!formValues.organizationName}
                />
                <InputField
                    name='lastName'
                    label={strings.LABEL.LAST_NAME}
                    disabled={!!formValues.organizationName}
                    required={!formValues.organizationName}
                />
                <InputField
                    name='address.streetAddress'
                    label={strings.LABEL.ADDRESS}
                    required
                />
                <InputField
                    name='address.city'
                    label={strings.LABEL.CITY}
                    required
                />
                <InputField
                    name='address.postalCode'
                    label={strings.LABEL.ZIP}
                    required
                />
                <DropdownField
                    name='address.state'
                    label={strings.LABEL.STATE}
                    options={stateOptions}
                    isSearchable
                    required
                />
                <DropdownField
                    name='address.country'
                    label={strings.LABEL.COUNTRY}
                    options={countryOptions}
                    onChange={handleCountryChange}
                    isSearchable
                    required
                />
                <InputField
                    name='taxId'
                    label={strings.LABEL.TAX_ID}
                />
            </div>
            {filerReference}
        </>
    )

}