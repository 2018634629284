import request from "tools/request"
export const SET_DASHBOARD_OVERVIEW = 'SET_DASHBOARD_OVERVIEW'
export const SET_DASHBOARD_PORTFOLIO_GROWTH = 'SET_DASHBOARD_PORTFOLIO_GROWTH'
export const SET_DASHBOARD_APPLICATIONS = 'GET_DASHBOARD_APPLICATIONS'

export const dispatchDashboardOverview = (data) => ({
    type: SET_DASHBOARD_OVERVIEW,
    data,
})

export const dispatchDashboardPortfolioGrowth = (data) => ({
    type: SET_DASHBOARD_PORTFOLIO_GROWTH,
    data,
})

export const dispatchDashboardApplication = (data) => ({
    type: SET_DASHBOARD_APPLICATIONS,
    data,
})

/* Action to get Dashboard Overview Data */
export const getDashboardOverview = (isPlatformAdmin) => async (dispatch) => {
    let url = "dashboard/overview"
    if (isPlatformAdmin) url = "dashboard/rox/overview"

    const res = await request(url, 'GET')
    dispatch(dispatchDashboardOverview(res))
    return res
}

/* Action to get Dashboard Application Data */
export const getDashboardApplication = (isPlatformAdmin) => async (dispatch) => {
    let url = "dashboard/applications"
    if (isPlatformAdmin) url = "dashboard/rox/applications"

    const res = await request(url, 'GET')
    dispatch(dispatchDashboardApplication(res))
    return res
}

/* Action to get Dashboard Portfolio Data */
export const getDashboardPortfolio = (isPlatformAdmin) => async (dispatch) => {
    let url = "dashboard/portFolioGrowth"
    if (isPlatformAdmin) url = "dashboard/rox/portFolioGrowth"

    const res = await request(url, 'GET')
    dispatch(dispatchDashboardPortfolioGrowth(res))
    return res
}
