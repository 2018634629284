import request, {downloadFile, getPDF} from 'tools/request'
import queryString from 'query-string'

export const SET_APPLICANTS_LIST = 'SET_APPLICANTS_LIST'
export const SET_APPLICANT_BYID = 'SET_APPLICANT_BYID'
export const SET_FMCSA_DATA = 'SET_FMCSA_DATA'
export const FORM_MODE = 'FORM_MODE'
export const RESET_FMCSA = 'RESET_FMCSA'
export const RESET_FMCSA_DATA = 'RESET_FMCSA_DATA'
export const NOTES_LIST = 'NOTES_LIST'
export const SET_VERIFICATION_LIST = 'SET_VERIFICATION_LIST'
export const SET_VERIFICATION_REPORT = 'SET_VERIFICATION_REPORT'
export const UCC_INTERACTION_LIST = 'UCC_INTERACTION_LIST'
export const SET_UCC_SEARCH_DATA = 'SET_UCC_SEARCH_DATA'
export const SET_UCC_RESPONSE = 'SET_UCC_RESPONSE'
export const SET_UCC_SEARCH_PARAMETERS = 'SET_UCC_SEARCH_PARAMETERS'
export const SET_UCC_SEARCH_ID = 'SET_UCC_SEARCH_ID'
export const SET_UCC_SEARCH_INDEX_DATE = 'SET_UCC_SEARCH_INDEX_DATE'
export const SET_UCC_SEARCH_HISTORY_DATA = 'SET_UCC_SEARCH_HISTORY_DATA'
export const SET_DOCUMENT_STATUS = 'SET_DOCUMENT_STATUS'
export const SET_RETRIEVE_DOCUMENTS = 'SET_RETRIEVE_DOCUMENTS'
export const SET_ATTACHMENTS_LIST = 'SET_ATTACHMENTS_LIST'
export const SET_BANK_TRANSACTIONS_LIST = 'SET_BANK_TRANSACTIONS_LIST'
export const SET_RECURRENT_BANK_TRANSACTIONS_LIST = 'SET_RECURRENT_BANK_TRANSACTIONS_LIST'
export const SET_FILING_ATTACHMENTS_LIST = 'SET_FILING_ATTACHMENTS_LIST'
export const SET_CREDIT_LIST = 'SET_CREDIT_LIST'
export const SET_CREDIT_SEARCH_RESULT = 'SET_CREDIT_SEARCH_RESULT'
export const SET_CREDIT_REPORT_RESULT = 'SET_REPORT_CREDIT_RESULT'
export const SET_BGV_LIST = 'SET_BGV_LIST'
export const SET_CONNECTED_BUISNESS_LIST = 'SET_CONNECTED_BUISNESS_LIST'
export const SET_BGV_SEARCH_RESULT = 'SET_BGV_SEARCH_RESULT'
export const SET_DETAILED_BUSINESS_REPORT = 'SET_DETAILED_BUSINESS_REPORT'
export const SET_CONNECTED_BUSINESS_REPORT = 'SET_CONNECTED_BUSINESS_REPORT'
export const SET_UCC_FILING_LIST = 'SET_UCC_FILING_LIST'
export const SET_DIRECT_AMENDMENT_LIST = 'SET_DIRECT_AMENDMENT_LIST'
export const SET_TEMPLATES_LIST = 'SET_TEMPLATES_LIST'
export const SET_UCC_PREVIEW = 'SET_UCC_PREVIEW'
export const SET_UCC3_PREVIEW = 'SET_UCC3_PREVIEW'
export const SET_AMENDMENT_PREVIEW = 'SET_AMENDMENT_PREVIEW'
export const SET_ACTIVE_LIST = 'SET_ACTIVE_LIST'
export const SET_DEBTORS = 'SET_DEBTORS'
export const SET_TEMP_FILES_LIST = 'SET_TEMP_FILES_LIST'
export const SET_FORM_VALUES = 'SET_FORM_VALUES'
export const SET_UCC_FILTERS = 'SET_UCC_FILTERS'
export const SET_SOS_DATA = 'SET_SOS_DATA'
export const UPDATE_SUMMARY_CARD = 'UPDATE_SUMMARY_CARD'
export const UPDATE_DIRECT_AMENDMENTS = 'UPDATE_DIRECT_AMENDMENTS'

export const dispatchApplicantsList = (data, headers) => ({
    type: SET_APPLICANTS_LIST,
    data,
    headers
})

export const dispatchApplicantById = (data) => ({
    type: SET_APPLICANT_BYID,
    data
})

//uuid its the interaction uuid of the search
export const dispatchFmcsaData = (data, param, extraContent, uuid) => ({
    type: SET_FMCSA_DATA,
    data,
    extraContent,
    param,
    uuid
})
export const resetFmcsaData = () => ({
    type: RESET_FMCSA_DATA
})

export const dispatchFormMode = (data) => ({
    type: FORM_MODE,
    data
})

export const resetFmcsa = () => ({
    type: RESET_FMCSA
})

export const dispatchNotesList = (data) => ({
    type: NOTES_LIST,
    data
})

const dispatchVerificationList = data => ({
    type: SET_VERIFICATION_LIST,
    data
})

const dispatchVerificationReport = data => ({
    type: SET_VERIFICATION_REPORT,
    data
})

export const dispatchUCCInteractionList = (data, search) => ({
    type: UCC_INTERACTION_LIST,
    data,
    search
})

export const dispatchUCCSearchData = (data, savedData = false) => ({
    type: SET_UCC_SEARCH_DATA,
    data,
    savedData
})

export const dispatchUccResponse = (data) => ({
    type: SET_UCC_RESPONSE,
    data
})

export const dispatchSearchParameters = (data) => ({
    type: SET_UCC_SEARCH_PARAMETERS,
    data
})

export const dispatchUCCSearchId = (data) => ({
    type: SET_UCC_SEARCH_ID,
    data
})

export const dispatchUCCSearchIndexDate = (data) => ({
    type: SET_UCC_SEARCH_INDEX_DATE,
    data
})

export const dispatchUCCSearchHistory = (data) => ({
    type: SET_UCC_SEARCH_HISTORY_DATA,
    data
})

export const dispatchDocumentStatus = (data, fileNumber) => ({
    type: SET_DOCUMENT_STATUS,
    data,
    fileNumber
})

export const dispatchRetrieveDocuments = (data) => ({
    type: SET_RETRIEVE_DOCUMENTS,
    data,
})

export const dispatchCreditList = (data) => ({
    type: SET_CREDIT_LIST,
    data
})

export const dispatchCreditSearchResult = (data) => ({
    type: SET_CREDIT_SEARCH_RESULT,
    data
})

export const dispatchCreditReportResult = (data) => ({
    type: SET_CREDIT_REPORT_RESULT,
    data
})

export const dispatchBgvList = (data) => ({
    type: SET_BGV_LIST,
    data
})

export const dispatchConnectedBuisnessList = (data) => ({
    type: SET_CONNECTED_BUISNESS_LIST,
    data
})

export const dispatchBgvSearchResult = (data) => ({
    type: SET_BGV_SEARCH_RESULT,
    data
})

export const dispatchDetailedBusinessReport = (data) => ({
    type: SET_DETAILED_BUSINESS_REPORT,
    data
})

export const dispatchConnectedBusinessReport = (data) => ({
    type: SET_CONNECTED_BUSINESS_REPORT,
    data
})

export const dispatchAttachmentList = (data, headers) => ({
    type: SET_ATTACHMENTS_LIST,
    data,
    headers
})

export const dispatchListOfBankTransactions = (data, headers) => ({
    type: SET_BANK_TRANSACTIONS_LIST,
    data,
    headers
})

export const dispatchListOfRecurrentBankTransactions = (data, headers) => ({
    type: SET_RECURRENT_BANK_TRANSACTIONS_LIST,
    data,
    headers
})

export const dispatchFilingList = (data) => ({
    type: SET_UCC_FILING_LIST,
    data
})

export const dispatchDirectAmendmentList = (data) => ({
    type: SET_DIRECT_AMENDMENT_LIST,
    data
})

export const dispatchTemplateList = (data) => ({
    type: SET_TEMPLATES_LIST,
    data
})

export const dispatchUCCPreview = (data) => ({
    type: SET_UCC_PREVIEW,
    data
})

export const dispatchUCC3Preview = (data) => ({
    type: SET_UCC3_PREVIEW,
    data
})

export const dispatchAmendmentPreview = (data) => ({
    type: SET_AMENDMENT_PREVIEW,
    data
})

export const dispatchActiveList = (data, headers) => ({
    type: SET_ACTIVE_LIST,
    data,
    headers,
})

export const dispatchUccFilters = (filters = {}, freeSearch = '') => ({
    type: SET_UCC_FILTERS,
    filters,
    freeSearch
})

export const dispatchDebtors = (data) => ({
    type: SET_DEBTORS,
    data
})

export const dispatchTempFilesList = (data, headers) => ({
    type: SET_TEMP_FILES_LIST,
    data,
    headers
})

export const dispatchFormValues = (data) => ({
    type: SET_FORM_VALUES,
    data
})

export const dispatchSosData = (data) => ({
    type: SET_SOS_DATA,
    data,
})

export const dispatchUpdateSummaryCard = () => ({
    type: UPDATE_SUMMARY_CARD
})

export const dispatchUpdateDirectAmendments = () => ({
    type: UPDATE_DIRECT_AMENDMENTS
})

// get applicants list 
export const getApplicantsList = (pagination = {}, filters = {}) => async (dispatch, getState) => {

    if (pagination?.orderBy?.field) {
        switch (pagination?.orderBy?.field) {
            case 'createdBy':
                pagination.orderBy.field = 'createdBy_firstName'
                break;
            case 'salesRepresentative':
                pagination.orderBy.field = 'salesRepresentative_firstName'
                break;
            case 'applicantName':
                pagination.orderBy.field = 'indexName'
                break;
            default:
                break;
        }

    }

    const res = await request(
        'applications',
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10,
            'x-pagination-sort': `${pagination?.orderBy?.field || 'createdAt'},${pagination?.orderBy?.order || 'desc'}`
        },
        filters
    )
    dispatch(dispatchApplicantsList(res.response, res.headers))
    return res
}

// get applicant detail by id
export const getApplicantsById = (applicantUUID) => async (dispatch) => {
    const res = await request(
        `applications/${applicantUUID}`,
        'GET'
    )
    dispatch(dispatchApplicantById(res))
    return res
}

// archive application
export const archiveApplication = (applicantUUID, archived) => async () => {
    const res = await request(
        `applications/${applicantUUID}/archived`,
        'POST',
        { archived: archived }
    )
    return res
}

// get FMCSA data
// Endpoint called when opening Application Tab
export const getFmcsaData = (params) => async (dispatch) => {
    const query = {
        ...params
    }
    const queryUrl = `?${queryString.stringify(query)}`
    const res = await request(
        'fmcsa/search',
        'GET',
        null,
        true,
        {},
        query
    )
    dispatch(dispatchFmcsaData(res.response.content, queryUrl, res.response.extraContent, res.response.uuid))
    return res
}

// get FMCSA data By DOT
export const getFmcsaDataByDOT = (params) => async () => {
    const query = {
        ...params
    }
    const res = await request(
        'fmcsa/search',
        'GET',
        null,
        true,
        {},
        query
    )
    return res
}

export const requestFmcsaDataByApplication = (applicantId) => async (dispatch) => {
    const res = await request('applications/'+applicantId+'/fmcsa/search', 'GET')
    dispatch(dispatchFmcsaData(res.content, null, res.extraContent, res.uuid))
    return res;
}

export const getFmcsaDataByApplication = (applicantId) => async (dispatch) => {
    const res = await request('applications/'+applicantId+'/fmcsa/data', 'GET')
    dispatch(dispatchFmcsaData(res.content, null, res.extraContent, res.uuid))
    return res;
}



export const downloadFMCSAReport = (applicationId, dotNumber) => async () => {
    const res = await request(`applications/${applicationId}/fmcsa/search/report/${dotNumber}`, 'POST')
    return res
}

/**
 * Application create and update status API
 */
export const createApplication = (payLoad) => async () => {
    const res = await request('applications', 'POST', payLoad)
    return res
}

export const updateApplicationStatus = (uuid, status) => async (dispatch) => {
    const res = await request(`applications/${uuid}/status`, 'PATCH', { status: status })
    dispatch(dispatchApplicantById(res))
    return res
}

export const requestReports = (applicantId, params) => async () => {
    return await request(`applications/${applicantId}/decision`, 'POST', null, true, {}, params)
}

/**
 * Owner creation and update API
 */
export const createOwner = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/owners`, 'POST', payLoad)
    return res
}

export const updateOwner = (applicantId, payLoad) => async () => {
    delete payLoad?.createdBy
    delete payLoad?.updatedBy
    const res = await request(`applications/${applicantId}/owners/${payLoad?.uuid}`, 'PATCH', payLoad)
    return res
}

/**
 * Update Business API
 */
export const updateBusiness = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/business`, 'PATCH', payLoad)
    return res
}

/**
 * Create and Update Debtor API
 */
export const updateDebtor = (applicantId, payLoad, type) => async () => {
    const res = await request(`applications/${applicantId}/debtors`, type, payLoad)
    return res
}

// Verfications API
export const getVerifications = applicantId => async (dispatch) => {
    const res = await request(`applications/${applicantId}/verifications/`, 'GET')
    dispatch(dispatchVerificationList(res))
    return res
}

export const getVerificationData = (applicantId, payLoad, data) => async (dispatch) => {
    if (data)
        return dispatch(dispatchVerificationReport(data))

    const res = await request(`applications/${applicantId}/verifications/verify`, 'POST', payLoad)
    dispatch(dispatchVerificationReport(res))
    return res
}

export const saveVerificationReport = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/verifications/save`, 'POST', payLoad)
    return res
}

export const attachVerificationReport = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/verifications/attachReport`, 'POST', payLoad)
    return res
}

/**
 * Application Notes API
 */
export const createNotes = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/notes`, 'POST', payLoad)
    return res
}

export const getNotes = (applicantId) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/notes`, 'GET')
    dispatch(dispatchNotesList(res?.content))
    return res
}

/**
 * UCC API
 */
export const getUccList = (applicantId, reset) => async (dispatch) => {
    if (reset)
        dispatch(dispatchUCCInteractionList([]))

    const res = await request(`applications/${applicantId}/ucc/search`, 'GET')
    dispatch(dispatchUCCInteractionList(res))
    return res
}

export const getUccSearchTableData = async ({ applicationId }) => {
    return await request(`applications/${applicationId}/ucc/search`, 'GET')
}

//UCC search API for business / owner
export const uccSearch = (applicantId, payLoad, reset) => async (dispatch) => {
    if (reset){
        dispatch(dispatchUCCSearchId(null))
        return dispatch(dispatchUCCSearchData(null))
    }

    const res = await request(`applications/${applicantId}/ucc/search`, 'POST', payLoad)
    dispatch(dispatchUCCSearchData(res?.response?.GroupedResults || []))
    dispatch(dispatchUCCSearchId(res?.uuid || []))
    dispatch(dispatchUCCSearchIndexDate(res?.response?.Header?.IndexDate || []))
    return res
}

export const performUccSearch = (applicantId, payLoad) => async () => {
    return await request(`applications/${applicantId}/ucc/search`, 'POST', payLoad)
}

export const dispatchUpdatedUccResponse = (data, dispatch) => {
    dispatch(dispatchUccResponse(data))
}

export const dispatchUccSearchParameters = (data, dispatch) => {
    dispatch(dispatchSearchParameters(data))
}

export const uccSearchHistory = (applicantId, query, pagination) => async () => {
    const res = await request(`applications/${applicantId}/ucc/search/history`,
    'GET',
    null,
    true,
    {
        'x-pagination-num': pagination.page || 0,
        'x-pagination-limit': pagination.limit || 5,
    },
    query)
    return res;
}

export const finishSearch = (applicantId, payload) => async () => {
    return await request(`applications/${applicantId}/ucc/search/finish`, 'POST', null, true, {}, payload)
}

export const reopenSearch = (applicantId, payLoad) => async () => {
    return await request(`applications/${applicantId}/ucc/search/reopen`, 'POST', payLoad)
}

export const getUccData = (applicantId, payLoad) => async () => {
    return await request(`applications/${applicantId}/ucc/data`, 'POST', payLoad)
}

//UCC get detailed report for business / owner
export const getFilingDetails = (applicantId, payLoad) => async () => {
    return await request(`applications/${applicantId}/ucc/filingDetails`, 'POST', payLoad)
}

export const attachNoResultsReport = ({applicationId, payload}) => async () => {
    const res = await request(`applications/${applicationId}/ucc/report/no-results`, 'POST', payload)
    return res
}

export const attachFilingDetailsReport = ({applicationId, interactionId}) => async () => {
    return await request(`applications/${applicationId}/ucc/report/filing-details/${interactionId}`, 'POST')
}

export const getTerminationPreview = (applicantId, filingDetailsId, filingNumber) => async dispatch => {
    const res = await request(`applications/${applicantId}/ucc/ucc-filings/${filingDetailsId}/${filingNumber}`, 'GET')
    dispatch(dispatchDebtors(res))
    return res
}

export const savefiling = (applicantId, payLoad) => async () => {
    return await request(`applications/${applicantId}/ucc/saveFiling`, 'POST', payLoad)
}

export const attachFiling = ({applicationId, payload}) => async () => {
    return await request(`/applications/${applicationId}/ucc/attachFiling`, 'POST', payload)
}

export const getSavedDetail = (applicantId, type, id, skipDispatch) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/ucc/search/savedData?type=${type}&uuid=${id}`, 'GET')
    if (!skipDispatch) dispatch(dispatchUCCSearchData(res, true))
    return res
}

export const getSavedDetailByApplication = (applicantId) => async () => {
    return await request(`applications/${applicantId}/ucc/search/savedData`, 'GET')
}

export const deleteReport = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/ucc/search/delete`, 'DELETE', payLoad)
    return res
}

/**
 * Requests Documents from FCS
 */
export const retrieveDocument = (payLoad, state) => async () => {
    const { transactionID, filingNumbers, interactionId, offlineRequest, searchId } = payLoad || {}
    const requestPayload = {
        transactionID,
        filingNumbers,
        interactionId,
        offlineRequest,
        searchId,
        state
    }
    return await request(`ucc/documents/retrieve`, 'POST', requestPayload)
}

export const getRetrieveDocuments = (interactionId, reset) => async (dispatch) => {
    if (reset)
        return dispatch(dispatchRetrieveDocuments([]))

    const res = await request(`ucc/documents/retrieve/${interactionId}`, 'GET')
    dispatch(dispatchRetrieveDocuments(res))
    return res
}

/**
 * Endpoint get documents from DB without using dispatch
 */
export const getDocumentsFromDatabase = (interactionId) => async () => {
    return await request(`ucc/documents/retrieve/${interactionId}`, 'GET')
}
/**
 * Endpoint get Individual Filing Details
 */
export const getIndividualFilingDetails = (applicationId, interactionId) => async () => {
    return await request(`applications/${applicationId}/ucc/filingDetails/${interactionId}`, 'GET')
}

export const getDocumentStatus = (applicantId, interactionId, reset) => async (dispatch) => {
    if (reset)
        return dispatch(dispatchDocumentStatus([]))

    const res = await request(`applications/${applicantId}/interactionResponses/${interactionId}/ucc/documents`, 'GET')
    dispatch(dispatchDocumentStatus(res))
    return res
}

export const postFiling = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/ucc/postFiling`, 'POST', payLoad)
    return res
}

// UCC Credit list, search and report api
export const getCreditList = (applicantId) => async (dispatch) => {
    const query = {
        type: 'CREDIT',
        name: 'COM_SEARCH',
        byEntity: true,
        recordForNoData: true
    }
    const res = await request(
        `applications/${applicantId}/interactionResponses`,
        'GET',
        null,
        true,
        {},
        query
    )
    dispatch(dispatchCreditList(res?.response))
    return res
}

export const performCreditSearch = (applicantId) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/creditcheck/companysearch`, 'POST');
    dispatch(dispatchCreditSearchResult(res))
    return res
}

export const getCompanyReport = (applicantId, companyId, reportId) => async (dispatch) => {
    let endPoint = `applications/${applicantId}/creditcheck/companyreport/${companyId}`
    if (reportId)
        endPoint = `applications/${applicantId}/interactionResponses/${reportId}`

    const res = await request(endPoint, 'GET')
    dispatch(dispatchCreditReportResult(res))
    return res
}

export const getSummaryReportByEntity = (applicantId, entityId, type) => async () => {
    if (type === 'LEXIS_BACKGROUND') {
        return await request(`applications/${applicantId}/backgroundVerification/entity`,
            'GET', null, true, {}, {entityId: entityId})
    }
    if (type === 'FMCSA') {
        return await request('applications/' + applicantId + '/fmcsa/data', 'GET', null)
    }
    if (type === 'LEXIS_VERIFICATION') {
        return await request(`applications/${applicantId}/verifications`, 'GET', null)
    }
}

export const saveReport = (applicantId, companyId) => async () => {
    const res = await request(`applications/${applicantId}/creditcheck/companyreport/${companyId}/save`, 'POST')
    return res
}

export const getBgvList = (applicantId, reset) => async (dispatch) => {
    if (reset)
        return dispatch(dispatchBgvList([]))

    const res = await request(`applications/${applicantId}/backgroundVerification`, 'GET')
    dispatch(dispatchBgvList(res))
    return res
}

export const getConnectedBuisnessList = (applicantId, reset) => async (dispatch) => {
    if (reset)
        return dispatch(dispatchConnectedBuisnessList([]))

    const res = await request(`applications/${applicantId}/backgroundVerification/connectedBusiness`, 'GET')
    dispatch(dispatchConnectedBuisnessList(res))
    return res
}

export const performBgvSearch = (applicantId, payLoad) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/backgroundVerification/search`, 'POST', payLoad)
    const isBusiness = Object.keys(payLoad)[0] === 'businessId'
    const verifiedInfo = res?.verifiedInfo
    let data;
    if (isBusiness) {
        if (res?.pdfReportUuid) {
            data = res?.response?.TopBusinessReportResponseEx?.response?.Businesses?.Business[0] || {}
            dispatch(dispatchDetailedBusinessReport(data))
        } else {
            data = res?.response?.TopBusinessSearchResponseEx?.response?.Records?.Record || []
            if (verifiedInfo) data = checkVerifiedData(data, verifiedInfo)
            dispatch(dispatchBgvSearchResult(data))
        }
    } else {
        data = res?.response?.BpsReportResponseEx?.response?.Individual || {}
        dispatch(dispatchBgvSearchResult(data))
    }    
    return res
}

const checkVerifiedData = (data, verifiedInfo) => {
    data.forEach(item => {
        const businessIds = item?.BusinessIds
        const isVerified = Object.entries(businessIds).every(([key, value]) => verifiedInfo[key] === value)
        if (isVerified) {
            item.verified = true
        }
    })
    return data
}

export const performBgvSearchSummary = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/backgroundVerification/search`, 'POST', payLoad)
    return res
}

export const saveBgvReport = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/backgroundVerification/save`, 'POST', payLoad)
    return res
}

export const getDetailedBusinessReport = (applicantId, payLoad) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/backgroundVerification/getReport`, 'POST', payLoad)
    dispatch(dispatchDetailedBusinessReport(res?.response?.TopBusinessReportResponseEx?.response?.Businesses?.Business[0] || {}))
    return res
}

export const saveBuisnessReport = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/backgroundVerification/save`, 'POST', payLoad)
    return res
}

export const attachFile = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/backgroundVerification/attachReport`, 'POST', payLoad)
    return res
}

export const getConnectedBuisnessReport = (applicantId, payLoad, reset) => async (dispatch) => {
    if (reset)
        return dispatch(dispatchConnectedBusinessReport(true))

    const res = await request(`applications/${applicantId}/backgroundVerification/connectedBusinessReport/get`, 'POST', payLoad)
    let data
    if (res?.response?.TopBusinessReportResponseEx?.response?.Businesses?.Business[0]) {
        data = res.response.TopBusinessReportResponseEx.response.Businesses.Business[0]
        data.key = String(payLoad.BusinessIds.OrgID)
    }

    dispatch(dispatchConnectedBusinessReport(data || {}))
    return res
}

export const saveConnectedBuisnessReport = (applicantId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/backgroundVerification/connectedBusinessReport/save`, 'POST', payLoad)
    return res
}

// UCC1 filing API's
export const getUccFilingList = (applicantId, reset) => async (dispatch) => {
    if (reset)
        dispatch(dispatchFilingList([]))

    const res = await request(`applications/${applicantId}/ucc/ucc-filings`, 'GET')
    dispatch(dispatchFilingList(res))
    return res
}

export const getUccFilingsForApplication = async ({ applicationId }) => {
    return await request(`applications/${applicationId}/ucc/ucc-filings`, 'GET')
}

export const getJurisdictionList = (params) => async () => {
    const res = await request(
        'jurisdiction',
        'GET',
        null,
        true,
        {},
        params
    )

    const response = res?.response;
    // find index of Secretary of State option
    const indexSOS = response.findIndex(option => option.name === 'Secretary of State');
    // if an index was found we move it at the first position
    if (indexSOS !== -1) {
        const sos = response.splice(indexSOS, 1);
        response.unshift(sos[0]);
    }
    return response
}

export const getJurisdictionsSiblings = (jurisdictionId) => async () => {
    const res = await request(
        `jurisdiction/${jurisdictionId}/siblings`, 'GET')
    return res
}

export const getdirectAmendmentsList = applicationId => async dispatch => {
    const res = await request(`applications/${applicationId}/ucc/ucc-filings/direct-amendments`, "GET")
    dispatch(dispatchDirectAmendmentList(res))
    return res
}

export const getTemplates = () => async (dispatch) => {
    const res = await request(`templates`, "GET")
    dispatch(dispatchTemplateList(res))
    return res
}

export const filingPreview = (applicantId, payLoad, details) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/ucc/postFiling/preview`, "POST", payLoad)
    dispatch(dispatchUCCPreview({ ...res, ...details }))
    return res
}

// attachments upload,get,delete and download API
export const uploadFile = (applicantId, payLoad) => async () => {
    const res = await request(
        `applications/${applicantId}/files`,
        'POST',
        payLoad,
        true,
        { 'Content-Type': 'multipart/form-data' }
    )
    return res
}

export const uploadRoxFiles = (payLoad) => async () => {
    const res = await request(
        `files`,
        'POST',
        payLoad,
        true,
        { 'Content-Type': 'multipart/form-data' }
    )
    return res
}

export const getFilingReportsAttachments = (applicationUuid, pagination = {}, query = {}) => async (dispatch) => {
    const res = await request(
        `applications/${applicationUuid}/ucc/search/files`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10
        },
        query
    )
    dispatch(dispatchAttachmentList(res.response, res.headers))
    return res
}

export const getBankDataRequests = (applicantId) => async ()=> {
    return await request(
        `applications/${applicantId}/bank-transactions/status`,
        'GET',
        null
    )
}

export const sendEmailToOwnerDueCurrentStatus = (applicantId, ownerId) => async () => {
    return await request(
        `applications/${applicantId}/owners/${ownerId}/bank-transactions/send-email`,
        'POST'
    )
}

export const getFiles = (applicantId, pagination = {}, query = {}) => async (dispatch) => {
    const res = await request(
        `applications/${applicantId}/files`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10
        },
        query
    )
    dispatch(dispatchAttachmentList(res.response, res.headers))
    return res
}

export const deleteFile = (applicantId, fileId) => async () => {
    const res = await request(`applications/${applicantId}/files/${fileId}`, 'DELETE')
    return res
}

export const downLoadAttachment = (applicantId, fileId, fileName) => async () => {
    if (applicantId){
        return  await downloadFile(`applications/${applicantId}/files/${fileId}/download`, 'GET', null, fileName)
    }
    return await downloadFile(`files/${fileId}/download`, 'GET', null, fileName)
}

export const downloadAttachedFiles = (body, zipName) => async () => {

    const res = await downloadFile('files/download', 'POST', body, zipName)

    return res
}

export const getAttachmentPDF = (payload) => async () => {
    if (payload?.applicantionId){
        return await getPDF(`applications/${payload.applicantionId}/files/${payload.fileId}/download`)
    }
    return await getPDF(`files/${payload.fileId}/download`)
}

export const downLoadAttachmentByFileId = (fileId, fileName) => async () => {
    return await downloadFile(`files/${fileId}/download`, 'GET', null, fileName)
}

//Endpoint receives payload with file id, file name and optional application id
export const downloadFileByPayload = (payload) => async () => {
    if (payload?.applicantionId){
        return  await downloadFile(`applications/${payload.applicantionId}/files/${payload.fileId}/download`, 'GET', null, payload.fileName)

    }
    return await downloadFile(`files/${payload.fileId}/download`, 'GET', null, payload.fileName)
}

export const requestUCCForceUpdate = (filingId) => async (dispatch) => {
    const res = await request(`/ucc/ucc-filings/${filingId}/update-status`, 'POST', null, false, {})
    dispatch(dispatchUCCPreview(res))
    return res
}

export const getUccFilingDetailsByEntity = (applicationId, entityUuid, reset, query) => async (dispatch) => {
    if (reset)
        return dispatch(dispatchUCCPreview({}))
    const res = await request(`applications/${applicationId}/ucc/ucc-filings/${entityUuid}`, 'GET', null, false, {}, query)
    dispatch(dispatchUCCPreview(res))
    return res
}

//This endpoint is called when opening ucc from UCCs Tab
export const getUccFilingDetailsByFilingNumber = (filingNumber, type) => async (dispatch) => {
    const res = await request(`ucc/ucc-filings/${filingNumber}`, 'GET')
    if (type === 'amendment')
        dispatch(dispatchAmendmentPreview(res))
    else if (type === 'ucc1')
        dispatch(dispatchUCCPreview(res))
    else
        dispatch(dispatchUCC3Preview(res))
    return res
}

export const requestUccFilingImage = (filingId) => async () => {
    return  await request(`ucc/${filingId}/generate-image`, 'POST')
}
export const getUccFilingDetailsByFilingId = (filingId, type) => async (dispatch) => {
    const res = await request(`ucc/ucc-filings/filing-id/${filingId}`, 'GET')
    if (type === 'amendment')
        dispatch(dispatchAmendmentPreview(res))
    else if (type === 'ucc1')
        dispatch(dispatchUCCPreview(res))
    else
        dispatch(dispatchUCC3Preview(res))
    return res
}

export const continueUCC = payLoad => async () => {
    const res = await request(`ucc/continuation`, 'POST', payLoad)
    return res
}

export const terminateUCC = payLoad => async () => {
    return await request(`ucc/termination`, 'POST', payLoad)
}

export const getActiveList = (pagination = {}, filters = {}) => async (dispatch) => {
    if (pagination?.orderBy?.field) {
        switch (pagination?.orderBy?.field) {
            case 'filingNumber':
                pagination.orderBy.field = 'fileNumber'
                break;
            case 'creationDate':
                pagination.orderBy.field = 'createdAt'
                break;
            case 'debtorName':
                pagination.orderBy.field = 'indexName'
                break;
            case 'lapseDate':
                pagination.orderBy.field = 'currentLapseDate'
                break;
            default:
                break;
        }

    }
    const res = await request(
        `ucc/ucc-filings`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10,
            'x-pagination-sort': `${pagination?.orderBy?.field || 'createdAt'},${pagination?.orderBy?.order || 'desc'}`
        },
        filters
    )
    dispatch(dispatchActiveList(res.response, res.headers))
    return res
}

export const getDebtors = (filingNumber) => async (dispatch) => {
    const res = await request(`ucc/ucc-filings/${filingNumber}`, 'GET')
    dispatch(dispatchDebtors(res))
    return res
}

export const performAmendmentOperation = payLoad => async () => {
    const res = await request(`ucc/party-amendment`, 'POST', payLoad)
    return res
}

export const performCollateralOperation = payLoad => async () => {
    const res = await request(`ucc/collateral-amendment`, 'POST', payLoad)
    return res
}

export const uploadTempFile = (applicantId, payLoad) => async () => {
    const res = await request(
        `ucc/ucc-filings/submit-temp-file`,
        'POST',
        payLoad,
        true,
        { 'Content-Type': 'multipart/form-data' }
    )
    return res
}

export const getTempFiles = (pagination = {}) => async (dispatch) => {
    const res = await request(
        `ucc/temp-attachments`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10
        }
    )
    dispatch(dispatchTempFilesList(res.response, res.headers))
    return res
}

export const requestBankDataAccess = (applicantId, ownerId) => async () => {
    const res = await request(`applications/${applicantId}/owners/${ownerId}/requestBankDataAccess`, 'POST')
    return res
}

//TODO: In the future, we need to request accounts
export const requestAccounts = (applicantId, ownerId) => async () => {
    const res = await request(`applications/${applicantId}/owners/${ownerId}/accounts/request`, 'POST')
    return res
}

export const getListOfAccounts = (applicantId, ownerId, pagination = {}) => async () => {
    const res = await request(`applications/${applicantId}/owners/${ownerId}/accounts`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 10,
            'x-pagination-sort': `${pagination?.orderBy?.field || 'createdAt'},${pagination?.orderBy?.order || 'desc'}`
        }
    )
    return res
}

export const requestBankTransactions = (applicantID, ownerId, payLoad) => async () => {
    const res = await request(`applications/${applicantID}/owners/${ownerId}/transactions/request`, 'POST', payLoad)
    return res
}

export const getListOfBankTransactions = (applicantId, ownerId, params, pagination = {}) => async (dispatch) => {
    const res = await request(
        `applications/${applicantId}/owners/${ownerId}/transactions`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5,
            'x-pagination-sort': `${pagination?.orderBy?.field || 'date'},${pagination?.orderBy?.order || 'asc'}`
        },
        params
    )
    dispatch(dispatchListOfBankTransactions(res.response, res.headers))
    return res
}

export const getListOfFilteredBankTransactions = (applicantId, ownerId, params, pagination = {}) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/owners/${ownerId}/transactions`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5,
            'x-pagination-sort': `${pagination?.orderBy?.field || 'date'},${pagination?.orderBy?.order || 'asc'}`        },
        params
    )
    return res
}

export const requestRecurrentBankTransactions = (applicantId, ownerId, payLoad) => async () => {
    const res = await request(`applications/${applicantId}/owners/${ownerId}/recurrentTransactions/request`, 'POST', payLoad)
    return res
}
export const getListOfRecurrentBankTransactions = (applicantId, ownerId, params, pagination = {}) => async (dispatch) => {
    const res = await request(`applications/${applicantId}/owners/${ownerId}/recurrentTransactions`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5,
            'x-pagination-sort': `${pagination?.orderBy?.field || 'lastDate'},${pagination?.orderBy?.order || 'desc'}`
        },
        params
    )
    dispatch(dispatchListOfRecurrentBankTransactions(res.response, res.headers))
    return res
}

export const getRecurrentTransactionData = (applicantId, ownerId, transactionId) => async () => {
    const res = await request(`applications/${applicantId}/owners/${ownerId}/bank-transactions/recurrent-transactions/${transactionId}`, 'GET')
    return res
}

export const getDataForTransactionsCharts = (applicantId, ownerId, accountId, params) => async () => {
    const res = await request(
        `applications/${applicantId}/owners/${ownerId}/bank-transactions/accounts/${accountId}/charts`,
        'GET',
        null,
        false,
        {},
        params,
    )
    return res
}

export const getDataForInsights = (applicantId, ownerId, accountId) => async () => {
    const res = await request(
        `applications/${applicantId}/owners/${ownerId}/bank-transactions/accounts/${accountId}/insights`,
        'GET',
    )
    return res
}

export const getBusinessReportSummary = (applicantionId, reportType) => async () => {
    const res = await request(`applications/${applicantionId}/summary?reportType=${reportType}`, 'GET')
    return res
}

export const getOwnerReportSummary = (applicantionId, ownerId, reportType) => async () => {
    const res = await request(`applications/${applicantionId}/summary/owners/${ownerId}?reportType=${reportType}`, 'GET')
    return res
}

export const getSummaryInfo = (applicantionId) => async () => {
    const res = await request(`application/${applicantionId}/decision/summary`, 'GET')
    return res
}

export const getAvailableSummary = (applicantionId) => async () => {
    const res = await request(`applications/${applicantionId}/availableSummary`, 'GET')
    return res
}

export const getSingleAvailableSummary = (applicantionId, params) => async () => {
    const res = await request(`applications/${applicantionId}/availableSummary`, 'GET', null, false, {}, params)
    return res
}

export const requestApplicationInfo = (applicantionId) => async () => {
    const res = await request(`applications/${applicantionId}/info`, 'GET')
    return res
}

//SOS

// get sos searches by application id
export const getSosSearches = ({applicantId, pagination = {}}) => async () => {
    const res = await request(
        `applications/${applicantId}/sos/search`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5
        }
    )
    return res
}

// perform sos search
export const requestSosSearch = ({ applicationId, payload }) => async () => {
    const res = await request(`applications/${applicationId}/sos/search`, 'POST', payload)
    return res
}

// get an specific sos search
export const getSosSearch = ({ applicantId, searchId }) => async () => {
    const res = await request(`applications/${applicantId}/sos/search/${searchId}`, 'GET')
    return res
}

// get sos search by autoreport
export const getSosSearchAutoreport = (applicantId) => async () => {
    const res = await request(`applications/${applicantId}/sos/search/autoreport`, 'GET')
    return res
}

// get sos saved records by application id
export const getSosSavedRecords = (applicantionId, pagination = {}) => async () => {
    const res = await request(
        `applications/${applicantionId}/sos/records`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5
        }
    )
    return res
}

// get an specific sos saved record
export const getSavedRecord = (applicantionId, recordId) => async () => {
    const res = await request(`applications/${applicantionId}/sos/record/${recordId}`, 'GET')
    return res
}

// save sos record 
export const saveSosRecord = ({applicationId, payload}) => async () => {
    const res = await request(`applications/${applicationId}/sos/save`, 'POST', payload)
    return res
}

//SOS Quick Search

// perform sos quick search
export const sosQuickSearch = ({payload}) => async () => {
    const res = await request(`quicksearch/sos/search`, 'POST', payload)
    return res
}

// get searches by client
export const getSosQuickSearches = ({pagination = {}}) => async () => {
    const res = await request(
        `quicksearch/sos/search`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5
        }
    )
    return res
}

// get saved records by client
export const getSosSavedRecordsQS = (pagination = {}) => async () => {
    const res = await request(
        `quicksearch/sos/records`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5
        }
    )
    return res
}

// get sos search by id
export const getSosQuickSearch = ({searchId}) => async () => {
    const res = await request(`quicksearch/sos/search/${searchId}`, 'GET')
    return res
}

// get saved record by id
export const getSavedRecordQS = (recordId) => async () => {
    const res = await request(`quicksearch/sos/record/${recordId}`, 'GET')
    return res
}

// save sos record from quick search
export const saveSosRecordQS = (payload) => async () => {
    const res = await request(`quicksearch/sos/save`, 'POST', payload)
    return res
}

export const dispatchSosRecord = (record) => async (dispatch) => {
    dispatch(dispatchSosData(record))
}

export const resetSosData = () => async (dispatch) => {
    dispatch(dispatchSosData({}))
}

// update Summary
export const updateSummary = () => async (dispatch) => {
    dispatch(dispatchUpdateSummaryCard())
}

// update Direct Amendments
export const updateDirectAmendments = () => async (dispatch) => {
    dispatch(dispatchUpdateDirectAmendments())
}

export const getUccAuditData = ({ applicationId, params, pagination = {} }) => async () => {
    return await request(
        `applications/${applicationId}/ucc/audit`,
        'GET',
        null,
        true,
        {
            'x-pagination-num': pagination.page || 0,
            'x-pagination-limit': pagination.limit || 5
        },
        params
    )
}