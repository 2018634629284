import React from "react"
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {Alert} from "@material-ui/lab";
import strings from "../../../../strings";
import {Icon} from "../../../../styles";

const t = strings.PLAID
export function PlaidOAuthSuccess() {

    const customFlag = window.location.href.includes('custom-J8kS2pL5q')

    return <div className='d-flex align-items-center justify-content-center vh-100'>
        <div className='text-center p-5 plaid-wrapper'>
            {customFlag ?
                <>
                    <img width={166} height={63}
                         src={"https://roxwrite-public-assets.s3.amazonaws.com/thrivory-logo.svg"}
                         alt={'Custom SVG'}/>
                </>
                :
                <>
                    <Icon width={166} height={63} folder='menuIcons' fileName='logo.svg' />
                </>
            }
            <Icon className='d-block mt-3' folder='icons' fileName='success.svg' />
            <div className='text-center p-5 '>
                <h5 className='mt-3 mb-0'>{t.BANK_AUTHORIZED}</h5>
                <Alert className='mt-4' icon={<CheckCircleIcon fontSize="inherit"/>} severity="success">
                    {t.GREAT_MSG}
                </Alert>
            </div>
        </div>
    </div>
}

export default PlaidOAuthSuccess;