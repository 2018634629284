import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
//import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter } from "react-router-dom"
import configureStore from 'store/configureStore'
import history from 'tools/history'


const store = configureStore()

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedRouter history={history} forceRefresh={false}>
            <App />
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//serviceWorker.unregister();
