import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, CircularProgress } from '@material-ui/core'
import ApartmentIcon from '@material-ui/icons/Apartment'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Drawer from 'components/shared/Drawer'
import Button from 'components/shared/Button'
import { DocumentRequestModal } from 'components/Applicants/partial/Dialog'
import TableComponent from 'components/shared/TableComponent'
import { Toast } from 'components/shared/Toaster'
import { Icon } from 'styles'
import { DetailColumn, DebtorsColumn } from 'constants/application'
import { SearchReportTemplate } from 'components/PdfTemplates'
import styles from './detailReport.module.scss'
import strings from 'strings'
import '../report/report.scss'

import { downLoadAttachmentByFileId, retrieveDocument } from 'actions/applicants'

const t = strings.APPLICANT.UCC

/**
 * 
 * @param {title} props shows the section title
 * @param {colums} props array will be send to show the table colums
 * @param {data} props array of data will be send to table component
 * @returns section with title and table component
 */
const SectionTable = (props) => {
    const {
        title,
        columns,
        data,
        selectRow,
        keyField,
    } = props
    return (
        <div className={styles.sectionContainer}>
            <Typography variant="body2" className='bold-font'>{title}</Typography>
            <TableComponent
                keyField={keyField}
                columns={columns}
                data={data}
                showPagination={false}
                className={styles.customTable}
                selectRow={selectRow}
            />
        </div>
    )
}

const uccString = strings.APPLICANT.UCC

const DetailReport = (props) => {
    const {
        open, // bool to control the drawer
        onClose, // func to close the drawer
        data: resData,
        stateUccSearch,
        applicationId,
        reportDetails,
        interactionId,
        getFilingReportsAttachments,
        selectedEntity,
        type = 'applicant',
        getRetrieveDocuments,
        dispatchProcessing // dispatch loading state to redux store
    } = props
    const { uccGroupInformation = {}, uccSearchParameters = {}, entityType, entityUUID } = reportDetails
    const [data, setData] = useState(resData)
    const [selectedFilings, setSelectedFilings] = useState([])
    const searchValues = useSelector(state => {
        if(type === 'applicant') {
            return state.form?.editForm?.values
        } else if (type === 'quick search'){
            return state.form?.QuickSearch?.values
        }
    }) || uccGroupInformation?.state || {};
    const searchId = useSelector(state => state?.applicant?.ucc?.uccSearchId);
    const { retrieveDocuments } = useSelector(state => state.applicant.ucc) || {}
    const [selectedReports, setSelectedReports] = useState([])
    const [selectedListFilings, setSelectedListFilings] = useState([])
    const [documentsLoader, setDocumentsLoader] = useState(false)
    const [showDocRequest, setShowDocRequest] = useState(false)
    const [fileDetails, setFileDetails] = useState({ fileId: '', fileName: '' })

    const timerRef = useRef(null); // variable used to clean up the setTimeOut on component unmount

    useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    },[])

    useEffect(() => {
        if (selectedReports) {
            let temp = []
            // building list of filing numbers to request document
            selectedReports.forEach((item) => {
                temp = [...temp, ...item.filingNumbers.map(item => item.filingNumber)]
            })
            setSelectedListFilings(temp)
        }
    }, [selectedReports])


    useEffect(() => {
        if (resData) {
            const allDocStatus = retrieveDocuments?.documents || []

            const updatedData = cloneDeep(resData)?.map(d => {
                d.documentStatus = allDocStatus.find(st => st.filingNumbers[0] === d.FilingNumber)

                if (d.Amendments)
                    d.Amendments = d.Amendments.map(am => ({ ...am, documentStatus: allDocStatus.find(status => status.requestedFilingNumber === am.FilingNumber) }))
                return d
            })
            setData(updatedData)
        }
        // eslint-disable-next-line
    }, [retrieveDocuments, resData])


    const buildLoadingState = (filingNumbers) => {
        const allDocStatus = filingNumbers || []

        const updatedData = data?.map(d => {
            d['loading'] = allDocStatus.find(fil => fil === d.FilingNumber)

            if (d.Amendments)
                d.Amendments = d.Amendments.map(am => ({ ...am, loading: allDocStatus.find(fil => fil === am.FilingNumber) }))
            return d
        })
        setData(updatedData)
    }


    const handleOnClose = () => {
        getRetrieveDocuments && getRetrieveDocuments(null, true) // reset data
        setSelectedListFilings([])
        setSelectedReports([])
        onClose()
    }

    const action = {
        formatter: (col, { FilingNumber, NumberOfPages, documentStatus, loading }) => {
            if (loading) {
                return <CircularProgress size={20} />
            }
            if (documentStatus?.status === 'Ready To Download') {
                const { fileId, fileName } = documentStatus
                return (
                    <Icon
                        folder='icons'
                        fileName='download.svg'
                        title='Download'
                        onClick={() => downLoadAttachmentByFileId(fileId, fileName)()}
                    />
                )
            }

            return NumberOfPages === 0 ? '-' : <Icon
                folder='icons'
                fileName={'document.svg'}
                title={
                    uccString.TOOLTIP1
                }
                onClick={() => {
                    const payload = {
                        [entityType === 'OWNER' ? 'ownerId' : 'businessId']: entityUUID,
                        transactionID: reportDetails.response.Header.TransactionID,
                        filingNumbers: [FilingNumber],
                        interactionId,
                        searchId
                    }

                    const showToast = () => {
                        Toast({
                            type: "success", message: uccString.UCC_DOWNLOAD_MSG
                        })
                        fetchDocuments(interactionId, FilingNumber, 20)
                    }

                    if (NumberOfPages) {
                        setSelectedFilings(prev => ([...prev, FilingNumber]))
                        buildLoadingState([...selectedFilings, FilingNumber])
                        retrieveDocument(payload, searchValues?.stateCode || searchValues)().then(showToast)
                    }
                }}
            />
        }
    }

    const fetchDocuments = (interactionId, filingNumber, maxCount) => {
        return getRetrieveDocuments(interactionId).then((res) => {
            const { fileId, fileName, status } = res?.documents[0] || {};
            if (status !== 'Ready To Download' && maxCount > 1) {
                // Call the function recursively with the same interactionId
                return timerRef.current =  fetchDocuments(interactionId, null, maxCount - 1);
            }
            if (filingNumber && maxCount > 1) {
                setSelectedFilings(prev => ([...prev.filter(item => item !== filingNumber)]))
            } else {
                setFileDetails({ fileId, fileName })
                setDocumentsLoader(false);
            }
            return res;
        });
    }

    DetailColumn.map((item, index) => {
        if (item.dataField === 'action') {
            DetailColumn[index] = { ...item, ...action }
        }
        return item
    })

    const detailReportActions = (
        <SearchReportTemplate
            stateUccSearch={stateUccSearch}
            data={data}
            applicationId={applicationId}
            interactionId={interactionId}
            selectedEntity={selectedEntity}
            getFilingReportsAttachments={getFilingReportsAttachments}
            type={type}
        />
    )

    const buildSelectedReports = (index, filingNumbers) => {
        setSelectedReports(prev => [
            ...prev.filter(item => item.index !== index),
            {
                index,
                filingNumbers: filingNumbers
            }
        ])
    }

    const handleReportSelect = (row, isSelect, index) => {
        if (isSelect) {
            const selectedFilings = selectedReports?.filter(item => item?.index === index)[0]?.filingNumbers || []
            const element = [...selectedFilings || [], { filingNumber: row?.FilingNumber, noOfPages: row?.NumberOfPages, originalFiling: Boolean(row?.LapseDate || 0) }]
            buildSelectedReports(index, element)
        }
        else {
            const selectedFilings = selectedReports?.filter(item => item.index === index)[0]?.filingNumbers || []
            const filteredFilings = selectedFilings?.filter(fn => fn.filingNumber !== row.FilingNumber)
            buildSelectedReports(index, filteredFilings)
        }
    }

    const handleReportSelectAll = (isSelect, rows, index) => {
        if (isSelect && rows?.length) {
            const filteredFilings = rows?.map(item => { return { filingNumber: item?.FilingNumber, noOfPages: item?.NumberOfPages, originalFiling: Boolean(item?.LapseDate || 0) } })
            const amendmentsFilings = rows[0]?.Amendments?.map(item => { return { filingNumber: item?.FilingNumber, noOfPages: item?.NumberOfPages, originalFiling: Boolean(item?.LapseDate || 0) } })
            buildSelectedReports(index, [...amendmentsFilings, ...filteredFilings])
        }
        else {
            buildSelectedReports(index, [])
        }
    }

    return (
        <Drawer
            open={open}
            onClose={handleOnClose}
            title={strings.APPLICANT.DETAIL_FILING_REPORT}
            actions={detailReportActions}
        >
            <div className={styles.detailContainer}>
                <div className='d-flex align-items-center mt-2'>
                    {uccGroupInformation?.entityName && <>
                        <ApartmentIcon htmlColor="#0000009c" />
                        <span className='text pl-1'>Entity: </span>
                        <span className='pl-1 pr-2 lessText' title={uccGroupInformation.entityName}>{uccGroupInformation.entityName}</span>
                    </>}

                    {uccGroupInformation?.address && <>
                        <LocationOnOutlinedIcon htmlColor="#0000009c" />
                        <span className='text pl-1'>Address:</span>
                        <span className='pl-1 boldText' title={uccGroupInformation.address}>
                            {uccGroupInformation.address}, {uccGroupInformation.city}, {uccGroupInformation.state}
                        </span>
                    </>}
                </div>

                {uccSearchParameters?.searchFromDate &&
                    <div className={`${styles.tabPanel} mt-4`}>
                        <Accordion style={{ boxShadow: 'none' }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{strings.APPLICANT.SEARCH_PARAMETERS}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item sm={3}>{strings.NAME}</Grid>
                                    <Grid item sm={9}>{uccSearchParameters.businessName ? uccSearchParameters.businessName : `${uccSearchParameters.firstName} ${uccSearchParameters.lastName}`}</Grid>

                                    <Grid item sm={3}>{strings.APPLICANT.SEARCH_FROM}</Grid>
                                    <Grid item sm={9}>{moment(uccSearchParameters.searchFromDate).format('MM-DD-YYYY')}</Grid>

                                    <Grid item sm={3}>{strings.APPLICANT.INCLUDE_INACTIVE_FILINGS}</Grid>
                                    <Grid item sm={9}>{uccSearchParameters.includeInactiveFilings ? 'Yes' : 'No'}</Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                }
                <div className='selectionContainer'>
                    <p>{`(${selectedListFilings?.length})`} {t.FILING_SELECTED}</p>
                    <Button
                        variant='outlined'
                        color='primary'
                        className="mt14"
                        loading={documentsLoader}
                        disabled={selectedListFilings?.length === 0}
                        onClick={() => setShowDocRequest(true)}
                    >
                        {t.REQUEST_UCC_DOCUMENTS}
                    </Button>
                </div>
                <div className={`pb-5 scrollableList ${uccSearchParameters?.searchFromDate ? 'reduce-height-250' : 'reduce-height-100'}`}>
                    {data && data.map((d, i) => {
                        const currentSelectedFillings = selectedReports?.filter(item => item?.index === i)[0]?.filingNumbers || []
                        const selectedFilingNumbers = currentSelectedFillings?.map(item => item.filingNumber)
                        const isnotPastDate = moment().isBefore(d?.LapseDate)
                        return (
                            <div className={`${styles.tabPanel} mt-4`} key={i}>
                                <Accordion defaultExpanded={true} style={{ boxShadow: 'none' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="detail-accordion"
                                        id="detail-report-header"
                                    >
                                        <Typography className='bold-font' color='primary'>{uccString.FILING_NUMBER}: {d?.FilingNumber}</Typography>
                                        <Typography className='bold-font'>{uccString.FILING_DATE}: {moment(d?.FilingDate).format('MM-DD-YYYY')}</Typography>
                                        <Typography className='bold-font' color={isnotPastDate ? 'initial' : 'error'}>{uccString.LAPSE_DATE}: {moment(d?.LapseDate).format('MM-DD-YYYY')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <SectionTable
                                            keyField='FilingNumber'
                                            title=''
                                            columns={DetailColumn}
                                            data={[d] || []}
                                            selectRow={{
                                                mode: 'checkbox',
                                                onSelect: (row, isSelect) => handleReportSelect(row, isSelect, i),
                                                onSelectAll: (isSelect, row) => handleReportSelectAll(isSelect, row, i),
                                                selected: selectedFilingNumbers || [],
                                            }}
                                        />
                                        <SectionTable
                                            title='Debtors'
                                            columns={DebtorsColumn}
                                            data={d?.Debtors || []}
                                            keyField='Name'
                                        />
                                        <SectionTable
                                            title='Secureds'
                                            columns={DebtorsColumn}
                                            data={d?.Secureds || []}
                                            keyField='Name'
                                        />
                                        <SectionTable
                                            keyField='FilingNumber'
                                            title='Amendments'
                                            columns={DetailColumn}
                                            data={d?.Amendments || []}
                                            selectRow={{
                                                mode: 'checkbox',
                                                hideSelectAll: true,
                                                onSelect: (row, isSelect) => handleReportSelect(row, isSelect, i),
                                                selected: selectedFilingNumbers || [],
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )
                    })}
                </div>
            </div>
            <DocumentRequestModal
                open={showDocRequest}
                onClose={() => setShowDocRequest(false)}
                selectedReports={selectedReports}
                listData={data}
                loading={documentsLoader}
                fileDetails={fileDetails}
                getRetrieveDocuments={getRetrieveDocuments}
                interactionId={interactionId}
                entityType={entityType}
                entityUUID={entityUUID}
                transactionID={reportDetails?.response?.Header?.TransactionID || ''}
                stateCode={searchValues?.stateCode || searchValues}
                dispatchProcessing={dispatchProcessing}
            />
        </Drawer>
    )
}

export default DetailReport
