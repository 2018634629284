import { SET_TEMPLATES_LIST, SET_SALES_REPRESENTATIVE_LIST, SET_ROLES_LIST } from 'actions/root'
import { produce } from 'immer'

export const initialState = {
  user: null,
  refreshApplicant: 0,
  globalAccountSelected: null,
  showNotification: { show: false, title: "", content: "", success: true },
  showProcessing: { show: false, title: "" },
  userList: [],
  rolesList: [],
  salesRepresentative: {
    list: [],
    headers: {}
  },
  templates: {
    list: [],
    headers: {}
  }
}

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_REFRESH_APPLICANT: "SET_REFRESH_APPLICANT",
  SET_GLOBAL_ACCOUNT_SELECTED: "SET_GLOBAL_ACCOUNT_SELECTED",
  SET_SHOW_NOTIFICATION: "SET_SHOW_NOTIFICATION",
  SET_SHOW_PROCESSING: "SET_SHOW_PROCESSING",
  SET_USER_LIST: 'SET_USER_LIST'
}

const rootReducer = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_USER:
        draft.user = action.user
        break;
      case actionTypes.SET_REFRESH_APPLICANT:
        draft.refreshApplicant = action.refreshApplicant
        break;
      case actionTypes.SET_GLOBAL_ACCOUNT_SELECTED:
        draft.globalAccountSelected = action.globalAccountSelected
        break;
      case actionTypes.SET_SHOW_NOTIFICATION:
        draft.showNotification = action.showNotification
        break;
      case actionTypes.SET_SHOW_PROCESSING:
        draft.showProcessing = action.showProcessing
        break;
      case actionTypes.SET_USER_LIST:
        draft.userList = action.data
        break;
      case SET_ROLES_LIST:
        draft.rolesList = action.data
        break;
      case SET_SALES_REPRESENTATIVE_LIST:
        draft.salesRepresentative.list = action.data
        draft.salesRepresentative.headers = action.headers
        break;
      case SET_TEMPLATES_LIST:
        draft.templates.list = action.data
        draft.templates.headers = action.headers
        break;
      default:
        return state
    }
  })
}

export default rootReducer
