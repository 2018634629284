import decode from 'jwt-decode'
import { API_URL } from 'constants/config'
import strings from 'strings'
import history from 'tools/history'

const dashboard = { id: 'dashboard', label: 'Dashboard', link: '/dashboard', icon: 'dashboard', type: 'parent' }
const quickSearch = { id: 'quick-search', label: 'Quick Search', link: '/quick-search', icon: 'quickSearch', type: 'parent' }
const applicants = { id: 'applicants', label: 'Applications', link: '/applicants', icon: 'applications', type: 'parent' }
const ucc = { id: 'ucc', label: 'UCCs', link: '/ucc', icon: 'clients', type: 'parent' }

/** Settings and manageCsv are commented for now, but needed for future use */
// const settings = { id: 'settings', label: 'Settings', link: '/settings', icon: 'settings', type: 'children' }
// const manageCsv = { id: 'manage-csv', label: 'CSV Uploads', link: '/manage-csv', icon: 'csv', type: 'parent' }

const help = { id: 'help', label: 'Help', link: 'https://support.roxwrite.com/hc/en-us', icon: 'help', type: 'children' }
const profile = { id: 'profile', label: 'Profile', link: '/profile', icon: 'profile', type: 'children' }

class AuthService {
  constructor() {
    this.login = this.login.bind(this)
    this.getProfile = this.getProfile.bind(this)
  }

  get selectedClient() {
    return localStorage.getItem('selectedClient')
  }

  set selectedClient(selectedClient) {
    if (selectedClient === null) {
      return localStorage.removeItem('selectedClient')
    }

    localStorage.setItem('selectedClient', selectedClient)
  }

  get loggedInUser() {
    return this.user ? this.user : {}
  }

  set loggedInUser(user) {
    if (user) {
      this.user = user
    }
  }

  /**
   * Returns roleName from the loggedInUser securityContext
   */
  getRoleName() {
    return this.loggedInUser?.securityContext?.roles[0]?.roleName
  }

  /**
   * Check if the logged in user has the given role
   * 
   * @param {*} roleName 
   */
  hasRole(roleName) {
    return this.loggedInUser?.securityContext?.roles?.some(role => role.roleName === roleName)
  }

  /**
   * Check if the logged in user has any of the given roles.
   * 
   * @param  {...any} roleNames 
   */
  hasAnyRole(...roleNames) {
    return !roleNames ? false : (roleNames.some(roleName => this.hasRole(roleName)))
  }

  /**
   * Check if the user cannot do any actions
   */
  isReadOnlyUser() {
    return this.hasRole('READ_ONLY_USER')
  }

  /**
   * Check if the logged in user has any role starts with the role prefix.
   * 
   * @param {*} rolePrefix 
   */
  hasRolePrefix(rolePrefix) {
    return this.loggedInUser?.securityContext?.roles?.some(role => role.roleName.startsWith(rolePrefix))
  }

  /**
   * Check if the logged in user has any of the given role prefixes.
   * 
   * @param  {...any} rolePrefixes 
   */
  hasAnyRolePrefix(...rolePrefixes) {
    return !rolePrefixes ? false : (rolePrefixes.some(rolePrefix => this.hasRolePrefix(rolePrefix)))
  }

  /**
   * Check if the logged in user has the given privilege
   * 
   * @param {*} privilegeName 
   */
  hasPrivilege(privilegeName) {
    return this.loggedInUser?.securityContext?.privileges?.some(privilege => privilege.privilegeName === privilegeName)
  }

  hideDecisioning() {
    return this.loggedInUser?.securityContext?.client?.hideDecisioning
  }

  /**
   * Check if the logged in user has any of the given privileges.
   * 
   * @param  {...any} privilegeNames 
   */
  hasAnyPrivilege(...privilegeNames) {
    return !privilegeNames ? false : (privilegeNames.some(privilegeName => this.hasPrivilege(privilegeName)))
  }

  /**
   * Check if the logged in user has the given privilege prefix
   * 
   * @param {*} privilegePrefix 
   */
  hasPrivilegePrefix(privilegePrefix) {
    return this.loggedInUser?.securityContext?.privileges?.some(privilege => privilege.privilegeName.startsWith(privilegePrefix))
  }

  /**
   * Check if the logged in user has any of the given privilege prefixes.
   * 
   * @param  {...any} privilegePrefixes 
   */
  hasAnyPrivilegePrefix(...privilegePrefixes) {
    return !privilegePrefixes ? false : (privilegePrefixes.some(privilegePrefix => this.hasPrivilegePrefix(privilegePrefix)))
  }

  /**
   * Checks and returns the menubarlist based on the roles
   */
  buildMenubarList(defaultMenuBarList) {
    const roleName = this.getRoleName()
    const roleStrings = strings.ROLES
    let menuList = []
    switch (roleName) {
      case roleStrings.UNDER_WRITER:
        menuList = [dashboard, quickSearch, applicants, ucc, help, profile]
        break;
      default:
        menuList = defaultMenuBarList
        break;
    }
    return menuList
  }

  /** ***** Login *****
   * Gets a token from the API server using the fetch API
   */
  login(username, password) {
    return new Promise((resolve, reject) => {
      fetch(`${API_URL}/login`, {
        method: 'POST',
        body: JSON.stringify({
          username,
          password
        })
      })
        .then(response => {
          if (response.status >= 200 && response.status < 400) {
            return response.json()
          }
          throw response
        })
        .then(parsedResponse => {
          this.setToken(parsedResponse['Authorization'])
          this.setRefreshToken(parsedResponse['Refresh_token'])
          resolve(parsedResponse)
        })
        .catch(err => {
          reject(err)
        })
    })
  }

  refreshToken() {
    const token = this.getRefreshToken()
    if (!token) {
      console.log("Refresh token: couldn't find a saved token")
      return Promise.reject()
    }
    return fetch(`${API_URL}/v1/token/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        Refresh_token: token
      })
    })
      .then(response => {
        if (response.status >= 200 && response.status < 400) {
          return response.json()
        }
        console.log('Refresh token: error response')
        return Promise.reject(response)
      })
      .then(res => {
        if (res.Authorization) {
          this.setToken(res.Authorization)
          return res
        } else {
          console.log('Refresh token: no refresh token found in the response')
          return Promise.reject(res)
        }
      })
  }

  /** ***** Logged In *****
   * Checks if there is a saved token
   */
  isLoggedIn() {
    const token = this.getToken()
    return !!token
  }

  /** ***** Is Token Expired *****
   * TODO: add logic to proactively get the refresh token before we get 401/403
   * Checks if token is expired
   */
  isTokenExpired(token = null) {
    token = token || this.getToken()
    try {
      const decoded = decode(token)
      if (decoded.exp < Date.now() / 1000) {
        return true
      } else {
        return false
      }
    } catch (err) {
      return false
    }
  }

  setToken(idToken) {
    localStorage.setItem('id_token', idToken)
  }
  setRefreshToken(refreshToken) {
    localStorage.setItem('rox_refresh_token', refreshToken)
  }

  getToken() {
    return localStorage.getItem('id_token')
  }
  getRefreshToken() {
    return localStorage.getItem('rox_refresh_token')
  }

  logout() {
    this.selectedClient = null
    localStorage.removeItem('id_token')
    localStorage.removeItem('rox_refresh_token')
    localStorage.removeItem('loggedInUser')
    localStorage.removeItem('rox_plaid_token')
    if (typeof window.Intercom === 'function') {
      window.Intercom('shutdown');
    }
    history.push('/dashboard')
    window.location.reload(true)
    localStorage.removeItem('redirectUrl')
  }

  getProfile() {
    return decode(this.getToken())
  }

  setPlaidLinkToken(token) {
    localStorage.setItem('rox_plaid_link_token', token)
  }

  getPlaidLinkToken() {
    localStorage.getItem('rox_plaid_link_token')
  }
}

export default new AuthService()
