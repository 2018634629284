import React from "react";
import "./Processing.css";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";

function Processing() {
  const {showProcessing} = useSelector(state => state.root)

  return (
    <React.Fragment>
      {showProcessing?.show === true && (
        <div id="processing-wrap">
          <div
            className={`processing-backdrop ${
              showProcessing?.show === true && `show`
            }`}
          ></div>
          <div
            className={`d-flex justify-content-center align-items-center position-fixed h-100 w-100 processing-now-wrap`}
          >
            <div className="processing-now text-center">
              <CircularProgress />
              {/* <div className="processing-text mt-1">{showProcessing?.title}</div> */}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Processing;
