import React, { useEffect, useState } from "react"
import strings from "strings"
import queryString from 'query-string';
import TableComponent from 'components/shared/TableComponent'
import { DirectAmendmentColums } from 'constants/application'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useHistory } from 'react-router-dom'
import { InfoView } from "../../../UCC/views/infoView";
import { useDispatch, useSelector } from "react-redux";
import { DiffView } from "components/Applicants/partial/Drawer/DiffView";
import {
    getdirectAmendmentsList, getUccFilingDetailsByFilingNumber,
    getUccFilingDetailsByFilingId, getUccFilingDetailsByEntity,
    requestUccFilingImage, downloadFileByPayload,
    downLoadAttachment, requestUCCForceUpdate,
    getAttachmentPDF
} from 'actions/applicants'


const uccStrings = strings.APPLICANT.UCC

const UccDirectAmendments = ({ applicationId }) => {

    const dispatch = useDispatch()

    const [loading, setLoading] = useState({ amendmentList: false, viewAmendment: false, viewFiling: false })
    const [directAmendmentList, setDirectAmendmentList] = useState([])
    const update = useSelector(state => state.applicant.ucc.updateDirectAmendments || 0)

    const [filingDrawerParameters, setFilingDrawerParameters] = useState({ open: false, data: null, hideDetails: false, ucc3: false })
    const ucc3Data = useSelector(state => state.applicant.clients.ucc3Preview) || {}
    const { location, replace } = useHistory()
    const tab = queryString.parse(location.search).tab

    useEffect(() => {
        const { drawerMode, filingNumber, filingId } = queryString.parse(location.search);
        if (drawerMode === 'amendment') {
            setLoading(prev => ({ ...prev, viewAmendment: true }))
            setFilingDrawerParameters({ open: true });
            getFilingDataByFilingNumberOrFilingId(filingNumber, filingId)

        }
        // eslint-disable-next-line
    }, [location.search, applicationId, getUccFilingDetailsByFilingNumber, getUccFilingDetailsByFilingId, getUccFilingDetailsByEntity])

    const getFilingDataByFilingNumberOrFilingId = (filingNumber, filingId) => {
        (filingNumber ? dispatch(getUccFilingDetailsByFilingNumber(filingNumber)) : dispatch(getUccFilingDetailsByFilingId(filingId)))
            .then(() => setFilingDrawerParameters({ open: true, hideDetails: !ucc3Data?.amendmentType?.includes('NO'), ucc3: true }))
            .catch(() => setFilingDrawerParameters({ open: false }))
            .finally(() => setLoading(prev => ({ ...prev, viewAmendment: false })))
    }

    useEffect(() => {
        setLoading(true)
        dispatch(getdirectAmendmentsList(applicationId))
            .then(setDirectAmendmentList)
            .finally(() => setLoading(false))
        // eslint-disable-next-line
    }, [update])

    const handleView = (row) => {
        const { filingNumber, filingId } = row;
        const qs = { tab, filingNumber, filingId, drawerMode: 'amendment' };
        if (qs.filingNumber) delete qs.filingId
        replace(`?${queryString.stringify(qs)}`, 'amendment')
    }

    const directAmendmentColums = DirectAmendmentColums.map(item => {
        if (item.dataField === 'action')
            item = {
                ...item, formatter: (cell, row) => {
                    return (
                        <VisibilityIcon color='primary' title='View Filing' onClick={() => handleView(row)} />
                    )
                }
            }
        return item
    })

    return (
        <>
            <h5>{uccStrings.DIRECT_AMENDMENTS}</h5>
            <TableComponent
                className='custom-filing-table mb-5'
                keyField='filingId'
                columns={directAmendmentColums}
                data={directAmendmentList}
                showPagination={false}
                isLoading={loading.amendmentList}
            />

            <InfoView
                open={filingDrawerParameters.open}
                onClose={() => {
                    setFilingDrawerParameters({ open: false })
                    setTimeout(() => replace(`?tab=${tab}`, 'reset'), 100);
                }}
                requestUccFilingImage={(filingId) => dispatch(requestUccFilingImage(filingId))}
                downloadFileByPayload={downloadFileByPayload}
                downLoadAttachment={downLoadAttachment}
                applicationId={applicationId}
                requestUCCForceUpdate={(filingId) => dispatch(requestUCCForceUpdate(filingId))}
                getUccFilingDetailsByFilingId={(filingId) => dispatch(getUccFilingDetailsByFilingId(filingId))}
                getUccFilingDetailsByFilingNumber={(filingNumber) => dispatch(getUccFilingDetailsByFilingNumber(filingNumber))}
                getAttachmentPDF={getAttachmentPDF}
                isUCC3={filingDrawerParameters.ucc3}
                ucc3Data={ucc3Data}
                viewMode={'fromUcc'}
                hideAmendmentHistory
                hideDetails={filingDrawerParameters.hideDetails}
                loading={loading.viewAmendment || loading.viewFiling}
                children={filingDrawerParameters.hideDetails &&
                    <DiffView
                        data={filingDrawerParameters.data}
                        ucc3Preview={ucc3Data}
                    />}
            />
        </>
    )
}

export default UccDirectAmendments