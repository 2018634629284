import { connect } from 'react-redux'
import Csv from 'components/RoxAdmin/CsvManagemet'
import { getCsvList, submitFile } from 'actions/csvManagemet'
import { getClientsList } from 'actions/client'

function mapStateToProps(stateProps, ownProps) {
    const { csvManagement, client: { clients: { clientsList } } } = stateProps
    const { csvList, csvHeaders } = csvManagement
    const csvTotalRecords = csvHeaders['x-pagination-total-records']
    const csvTotalPages = csvHeaders['x-pagination-total-pages']
    return {
        csvList,
        csvTotalRecords,
        csvTotalPages,
        clientsList
    }
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    return {
        ...stateProps,
        ...ownProps,
        ...dispatchProps,
    }
}

export default connect(
    mapStateToProps,
    {
        getCsvList,
        submitFile,
        getClientsList,
    },
    mergeProps
)(Csv)