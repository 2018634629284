import queryString from 'query-string'
import { API_URL } from 'constants/config'
import { matchInArray } from 'tools/utils'
import { isEmpty } from 'tools/objectHelper'
import { excludedVersionPaths } from 'constants/backendUrls'

/** Returns an absolute url for the backend, checks for the url to prepend 'v1`
 * or not depending on the url type
 * @param {string} path the relative backend endpoint
 * @param {object} [queryParams] optional query params will be added in the
 * following format: ?key=value&key2=value2
 * @return {string} returns the absolute backend url after processing it
 */
export const getBackendUrl = (path, queryParams) => {
  let baseUrl = API_URL
  if (!matchInArray(path, excludedVersionPaths)) {
    baseUrl = `${baseUrl}/v1`
  }
  const url = new URL(`${baseUrl}/${path}`)
  const params = !isEmpty(queryParams)
    ? `?${queryString.stringify(queryParams)}`
    : ''

  return url + params
}
