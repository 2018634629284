import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import getCommonSelectOptions from '../getCommonSelectOptions'
import { isObject } from 'tools/objectHelper'

import BaseDropdown from '../BaseDropdown'

const NormalDropdown = props => {
  const {
    options = [],
    value,
    onChange,
    isSearchable = false,
    isClearable = false,
    isMulti = false,
    label,
    placeholder,
    menuPlacement,
    propStyles
  } = props

  // value for `react-select` has to be the whole object not just the value:
  // ex:
  // value={label: 'Foo', value: 1} // correct
  // value=1 //wrong
  let selectedOption = options.find(o => {
    return isObject(o) ? o.value === value : o === value
  })
  // some forms we set initial value to be an object (won't be in the options)
  if (isObject(value)) {
    selectedOption = value
  }

  const customStyles = {
    menuList: (provided, state) => ({
      ...provided,
      maxHeight: 212,
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 'auto',
      zIndex: 20
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 20
    }),
  }

  return (
    <BaseDropdown {...props} selectedOption={selectedOption || null}>
      <Select
        // used for any additional options passed to selected that we don't expect
        {...props}
        options={options}
        value={selectedOption || null}
        onChange={option => {
          onChange(option)
        }}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        // defaultMenuIsOpen={true}
        // common styling options that will be used in both Normal and Async Dropdowns
        {...getCommonSelectOptions(props)}
        placeholder={placeholder || label}
        styles={propStyles ? propStyles : customStyles}
        menuPosition='fixed'
        menuPlacement={menuPlacement}
      />
    </BaseDropdown>
  )
}

const optionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.number
    ])
  })
)

NormalDropdown.propTypes = {
  options: optionsProps,
  value: PropTypes.any,
  onChange: PropTypes.func,

  // common ui options
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  tooltip: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  // menuPlacement: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge'])
}

export default NormalDropdown
