import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  createTheme,
  ThemeProvider,
  TextField,
  Tooltip,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import variables from 'tools/variables'
import ValidationError from 'components/forms/ValidationError'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import styles from './input.module.scss'
import NumberFormat from 'react-number-format'
import { formatPhoneNumber } from 'tools/helper'

// possibile width options for the input, can be passed as a prop
const inputWidthOptions = ['227', '150']

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      thousandsGroupStyle="thousand"
      onValueChange={(values) => {
        onChange(values.formattedValue);
      }}
      decimalSeparator="."
      displayType="input"
      type="text"
      thousandSeparator
      allowNegative={false}
      decimalScale={2}
      fixedDecimalScale
      isNumericString
    />
  );
}

/**
 * A low level Input component
 * to use with redux-form, use InputField instead
 */
const InputFieldDetailed = props => {
  const {
    placeholder,
    disabled,
    label,
    type,
    id,
    name,
    size,
    variant,
    prefixIcon,
    suffixIcon,
    multiline,
    showCopy = false,
    readOnly,
    required,
    shadowMode,
    description,
    horizontalLabel,
    hideValidationText,
    inputProps,
    tooltip = {},
    className,
    inputWidth = '227',
    inputClassName,
    error,
    mono,
    otherProps,
    numberFormat,
    isphoneNumber,
    value,
    rows,
    rowsMax,
    ...rest
  } = props
  const inputRef = useRef()
  const [showPassword, setShowPassword] = useState(false)
  // we can't copy disabled inputs, so when the showCopy is true, we set the
  // input as readonly when the user pass disabled
  const isReadOnly = readOnly || (showCopy && disabled)
  const isDisabled = disabled && !showCopy

  if (!inputWidthOptions.some(w => w === inputWidth)) {
    throw new Error(`Unkown 'inputWidth' value: ${inputWidth}`)
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#3f51b5!important'
      },
    },
  })

  const isPasswordType = type === 'password'

  const inputEl = (
    <ThemeProvider theme={theme}>
      <TextField
        {...rest}
        inputRef={inputRef}
        id={id || name}
        type={showPassword ? 'text' : type}
        placeholder={!readOnly ? placeholder : ''}
        className={`${inputClassName} ${styles.formInput} ${prefixIcon ? styles.withPrefixAdornment : styles.withSuffixAdornment
          } ${mono ? styles.monoInput : ''}`}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        readOnly={isReadOnly}
        disabled={isDisabled}
        InputProps={{
          ...inputProps, endAdornment: isPasswordType &&
            <InputAdornment position="end" className={styles.adornmentIconSuffix}>
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>,
          inputComponent: numberFormat && NumberFormatCustom
        }}
        label={label}
        variant={variant}
        size={size}
        error={!!error}
        inputProps={otherProps}
        value={isphoneNumber ? formatPhoneNumber(value) : value}
        // rows is used to not auto-populate the height for the outer div
        // dynamically based on the textarea content
      />
    </ThemeProvider>
  )

  return (
    <FormControl
      className={`${styles.formInputGroup} ${shadowMode ? styles.shadowMode : ''
        } ${horizontalLabel ? styles.horizontal : ''} ${multiline ? styles.textareaControl : ''
        } ${className ? className : ''}`}
      disabled={disabled}
      readOnly={readOnly}
      error={!!error}
      required={required}
    >
      {tooltip.title ? (
        <Tooltip
          title={tooltip.title}
          placement={tooltip.placement}
          enterDelay={variables.tooltipDelayMs}
          disableFocusListener
          disableTouchListener
        >
          {inputEl}
        </Tooltip>
      ) : (
        inputEl
      )}
      {!error && description && (
        <p className={styles.description}>{description}</p>
      )}
      {!hideValidationText && <ValidationError error={error} />}
    </FormControl>
  )
}

InputFieldDetailed.propTypes = {
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  prefixIcon: PropTypes.node,
  suffixIcon: PropTypes.node,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  // only works when multiline is set to true
  showCopy: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  shadowMode: PropTypes.bool,
  tooltip: PropTypes.object,
  inputProps: PropTypes.object,
  horizontalLabel: PropTypes.bool,
  error: PropTypes.string,
  hideValidationText: PropTypes.bool,
  className: PropTypes.string,
  inputWidth: PropTypes.oneOf(inputWidthOptions), //default is 227
  mono: PropTypes.bool, // display mono font
  otherProps: PropTypes.object,
  numberFormat: PropTypes.bool,
  isphoneNumber: PropTypes.bool
}

InputFieldDetailed.defaultProps = {
  size: 'small',
  variant: 'outlined'
}

export default InputFieldDetailed
