import React, { useState, useEffect } from 'react'
import { reduxForm } from 'redux-form'
import { Col, Row, Card } from 'react-bootstrap'
import {
    Tabs,
    Tab
} from 'components/shared/Tabs'
import { profileTabs } from 'constants/constantVariables'
import ProfileDetailForm from './partial/profileDetail'
import PasswordField from './partial/passwordField'
import strings from 'strings'

const Profile = (props) => {
    const {
        handleSubmit,
        dispatchFormConfig, // action to set form config
        updateProfile,
        updatePassword,
        getUserDetailByToken,
        history
    } = props
    const [tabValue, setTabValue] = useState(0)

    useEffect(() => {
        dispatchFormConfig({
            type: strings.PROFILE_FORM
        })
    }, [dispatchFormConfig])

    const handleTabValue = (val) => {
        if (val === 0) {
            dispatchFormConfig({
                type: strings.PROFILE_FORM,
            })
        } else {
            dispatchFormConfig({
                type: strings.PASSWORD_CHANGE_FORM,
            })
        }
        setTabValue(val)
    }

    const submitProfile = (data) => {
        updateProfile(data).then(() => {
            getUserDetailByToken()
            history.push(`/dashboard`)
        })
    }

    const submitPassword = (data) => {
        updatePassword(data).then(() => history.push(`/dashboard`))
    }

    return (
        <div>
            <Row>
                <Col md={6} className="text-left">
                    <h4>{strings.PROFILE}</h4>
                </Col>
            </Row>
            <Tabs
                data={profileTabs}
                value={tabValue}
                onChange={(e, val) => handleTabValue(val)}
            />
            <Card className='p-4 cardHeight'>
                <Tab value={tabValue} index={0}>
                    <ProfileDetailForm
                        onSubmit={handleSubmit(submitProfile)}
                    />
                </Tab>
                <Tab value={tabValue} index={1}>
                    <PasswordField
                        onSubmit={handleSubmit(submitPassword)}
                    />
                </Tab>
            </Card>
        </div>
    )
}

export default reduxForm({
    form: 'userProfileForm',
    enableReinitialize: true
})(Profile)