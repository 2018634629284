import React from 'react'
import strings from 'strings'
import logo from 'styles/images/roxLogo.jpg'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <div>
            <img src={logo} alt="Procella Logo" />
            <h1>{strings.ERROR_BOUNDRY_HEADER}</h1>
            <p>{strings.ERROR_BOUNDRY_BODY}</p>
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
