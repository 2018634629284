import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { cardMapping } from 'constants/roxAdminSettings';
import { Icon } from 'styles';
import strings from 'strings';
import './index.scss'

export default function Menu() {
    return (
        <>
            <h4 className='mb-4'>{strings.SETTINGS}</h4>
            <div className='d-flex'>
                {cardMapping.map((data, i) => (
                    <Card key={data.url} className={clsx('settings-card', i && 'ml-3')} variant="outlined">
                        <CardContent className='w-100'>
                            <Icon className='text-center d-block' folder='icons' fileName={data.icon} />
                            <div className='text-center mt-2 heading-style-1'>
                                {data.heading}
                            </div>
                            <div className='text-center mt-1 sub-text'>
                                {data.bodyText}
                            </div>
                            <div className='card-action text-center'>
                                <Link className='text-decoration-none' to={data.url}>
                                    <Button color='primary' variant='outlined'>{data.buttonText}</Button>
                                </Link>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </>
    );
};

