import React, { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { getSosQuickSearch, getSosSearch } from 'actions/applicants'
import SearchResultCard from './SearchResultCard';
import Moment from "react-moment"
import { getClassNameForPreviousSearchStatus } from 'constants/utilityFunctions';

const PreviousSearchCard = (props) => {

    const { previousSearch, viewResult, search, searchLoading, isQuickSearch, applicantId } = props
    const sosSearchId = previousSearch?.searchId
    const state = previousSearch?.payload?.state
    const getPreviousSearchResult = isQuickSearch ? getSosQuickSearch : getSosSearch

    const [open, setOpen] = useState(false)
    const [previousSearchData, setPreviousSearchData] = useState(false)

    const previousSearchResult = previousSearchData?.response?.results[0]
    const possibleAlternatives = previousSearchData?.response?.possibleAlternatives
    const applicationId = previousSearchData?.applicationId

    const handleActionClick = () => {
        if (!open) {
            let info = { searchId: sosSearchId }
            if (!isQuickSearch) info.applicantId = applicantId
            getPreviousSearchResult(info)()
                .then(res => {
                    setPreviousSearchData(res)
                    setOpen(true)
                })
        } else {
            setOpen(false)
        }
    }

    const handleAlternativeSearch = (sosId, businessName) => {
        search({ sosId, state, businessName })
    }

    return (
        <div className='cardStyle mt-3 mb-3'>
            <div className='searchCard'>
                <div className='bold-font'> {previousSearch?.payload?.searchQuery} </div>
                <div className='bold-font text-uppercase'> {previousSearch?.payload?.state} </div>
                <Moment className='bold-font' format="MM/DD/YYYY">{previousSearch?.createdDate}</Moment>
                <div className={getClassNameForPreviousSearchStatus(previousSearch?.status)}> {previousSearch?.status} </div>
                {previousSearch?.status === 'Search Completed' &&
                    <div className='align-self-center' onClick={handleActionClick} > {open ? <ExpandLessIcon fontSize='large' /> : <ExpandMoreIcon fontSize='large' />}</div>
                }
            </div>
            <div className={`searchResultContainer ${open ? 'open' : ''}`}>
                {
                    (previousSearchResult &&
                        <>
                            <hr />
                            <SearchResultCard
                                searchResult={{ ...previousSearchResult, sosSearchId, applicationId }}
                                possibleAlternatives={possibleAlternatives}
                                viewResult={viewResult}
                                searchAlternative={handleAlternativeSearch}
                                searchLoading={searchLoading}
                                state={state}
                                isQuickSearch={isQuickSearch}
                            />
                        </>
                    )
                }
            </div>
        </div >
    )
}

export default PreviousSearchCard